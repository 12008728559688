import i18n from '../../i18n';
import {httpGet,httpDelete} from "../../utils/httpHelper";
import store from '../../store';
import {APP_CONST} from "../AppConstants";
import {showToastMessageData} from "../reducers/appReducer";
import {isEmpty} from "../../utils/validations";

export const setAppLanguage = async(lng) => {
    let response = await httpGet('/api/language/displayText/all?language=' + lng,{hideLoader: true});

    if (response && response.success) {
        let val  = {};
        response.data.forEach((e)=>{
            val[e.key] = e.value;
        });
        i18n.addResourceBundle(lng, 'translations', val, true, true);
        // let status = await i18n.changeLanguage(lng);
        store.dispatch({
            type:'SET_ACTIVE_LANGUAGE',
            payload: lng
        });
        return true;
    } else {
        return false;
    }
};

export const showToastMessage = (type,message) => (dispatch) => {
    // type should be one of 'info', 'success', 'warning', 'danger'
    let types=['info', 'success', 'warning', 'danger'];
    let newType = type;
    if(types.includes(type)){
        //
    }
    else {
        newType = 'danger'
    }
    dispatch(
        showToastMessageData({
            type: newType,
            message: message
        })
    )
};

export const setAppLoader = (isVisible) => (dispatch) => {
    dispatch({
        type: 'SET_APP_LOADER',
        payload: {
            type: "screen",
            isVisible:isVisible
        }
    })
};

export const showSystemErrorBlock = (errorObject) => dispatch => {
    dispatch(setAppLoader(false));
    dispatch({
        type: 'SHOW_SYSTEM_ERROR_BLOCK',
        payload: errorObject
    });
};


export const setPrevLocation = (location) => dispatch => {
    dispatch({
        type: "SET_PREV_LOCATION",
        payload: location,
    })
};

export const setPostLoginUrl = (url, urlProps) => {
    return {
        type: APP_CONST.SET_POST_LOGIN_URL,
        payload: {
            postLoginUrl: url,
            postLoginUrlProps: {
                openAsPopUp: true,
                ...urlProps,
            }
        }
    };
};

export const clearPostLoginUrl = () => {
    return {
        type: APP_CONST.CLEAR_POST_LOGIN_URL,
    };
};


export const changeDrawerWidth2 = (drawerWidth) => {
    return {
        type: APP_CONST.CHANGE_DRAWER_WIDTH,
        payload: drawerWidth
    };
}


export const changeDrawerStatus = (isOpen) => {
    return {
        type: APP_CONST.CHANGE_DRAWER_STATUS,
        payload: isOpen
    };
}


export const setCurrentPathname = (pathname) => {
    store.dispatch({
        type: APP_CONST.SET_CURRENT_PATHNAME,
        payload: pathname
    })
}

export const setPrevRoutes = (routes) => dispatch => {
    dispatch({
        type: "SET_PREV_ROUTES",
        payload: routes,
    })
}


export const deleteKYCAccountAttachment = (accountId, accountKYCId,id) => {
    return httpDelete('/service/provisioning/account/kyc/attachments?id='+id+'&accountKYCId='+accountKYCId,'', accountId)
        .then(res => {
            return res;
        })
};

export const searchAllData= (keyword) => {
    if (isEmpty(keyword)){
        return
    }

    let url = '/service/home/search?keyword='+keyword;

    return httpGet(url)
        .then(res=>{
            return res;

        })
};