import {
    httpDelete,
    httpGet,
    httpPost,
    httpPostV2, removeAuthorizationToken,
    setAuthorizationToken
} from "../utils/httpHelper";
import {userTypes} from "./userTypes";
import store from "./../store";
import {showSystemErrorBlock, showToastMessage} from "../core/actions/appActions";
import {onLogoutResetData} from "../fundHouse/FundHouseReducer";
import {resetLoggedOutState} from "./userReducer";
import {userLogout} from "../core/reducers/appReducer";

// let base_api = 'http://localhost:10217'
let base_api = ''

export const registerUser = (obj) => {
    return httpPost('/service/provisioning/signup/local/request', obj)
};

export const getRegisteredUserEmail = (id) => {
    return httpGet('/service/provisioning/signup/request?id=' + id);
};

export const sendSignUpRequest = (code, signup_id) => {
    return httpPost('/service/provisioning/signup/local/confirm', {
        code: code,
        requestId: signup_id
    });
};

export const resendCodeRequest = (requestId) => {
    return httpPost('/service/provisioning/signup/local/resendVerificationCode', {
        requestId: requestId
    });
};

export const userLogin = (data) => {
    return httpPostV2('/service/provisioning/signIn/local/operation', data);
};

export const validateEmail = (email) => {
    return httpPost('/service/provisioning/user/local/password/sendResetLink', email)
};

export const resetPassword = (password, userId, code) => {
    return httpPost('/service/provisioning/user/local/password', {
        password: password,
        userId: userId,
        code: code
    });
};

export const validateResetLink = (id) => {
    return httpPost('/service/provisioning/user/local/password/validateResetLink', {code: id})
};

export const setTokenAfterLogin = async (res, rememberMe) => {
    let authResponse = await setAuthorizationToken(res.token, rememberMe);
    return !!authResponse.status;
};


export const getMyProfile = () => {
        return httpGet(base_api+'/service/user/profile/view');
};

export const setDrawerWidth = (data) => {
    store.dispatch({
        type: userTypes.SET_DRAWER_WIDTH,
        payload: {
            isDrawerWidth: data
        }
    })
};

export const deleteProfile = () => {
    let url = '/service/provisioning/user';

    return httpDelete(url)
        .then((res) => {
            return res;
        }).catch((err) => {
            store.dispatch(showSystemErrorBlock(err));
        })
};

export const clearUserDataOnLogout = () => {
    store.dispatch(onLogoutResetData())
};
export const logoutUser = () => (dispatch) => {
    removeAuthorizationToken();
    dispatch(userLogout());
    dispatch(resetLoggedOutState());
    return true;
};