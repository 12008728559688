import React, {useEffect, useState} from 'react'
import DLLoader from "../../core/components/DLLoader/DLLoader";
import DLText from "dlcomponent/components/DLText/DLText";
import DLButton from "dlcomponent/components/DLButton/DLButton";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IndustryChart from "./IndustryChart";
import IndustryTable from "./IndustryTable";
import CustomTabPanel from "../../component-utils/CustomTabPanel/CustomTabPanel";
import {downloadExcel} from "../../utils/downloadExcel";
import ChartRadioGroup from "../../component-utils/ChartRadioGroup/ChartRadioGroup";
import {getSummaryByIndustry} from "../FundHouseAction";
import {withRouter} from "react-router-dom";
import SummaryCardFundHouse from "../../stocks/SummaryCardFundHouse/SummaryCardFundHouse";
import {getChartData, getDataArray, getFixedValuesAfterDecimal, getIndexArr, getTrimVal} from "../../utils/helper";
import {isEmpty} from "../../utils/validations";
import StocksChart from "../StocksSummary/StocksChart";
import {downloadData} from "../../fund/FundAction";
import FundSummaryChart from "../FundSummary/FundSummaryChart";
import EChartTreemap from "../../component-utils/EChart/EChartTreemap";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function Industry(props) {

    const {fundHouseId} = props;
    const [chartLabelsArray, setChartLabelsArray] = useState([]);
    const [chartDataArray, setChartDataArray] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tabValue, setTabValue] = React.useState(0);
    const [percentage, setPercentage] = React.useState(0);
    const [tableHeader, setTableHeader] = useState([]);
    const [chartType, setChartType] = useState('marketValue');

    useEffect(() => {

        getSummaryByIndustry(VALUATION_DATE, fundHouseId).then((res) => {
            setIsLoading(false);
            if (res.success && !isEmpty(res.data?.data)) {
                setTableData(res.data.data.slice(1, res.data.data.length));
                setTableHeader(res.data.data[0]);
                setIndustryGraph(res.data.data.slice(1, res.data.data.length));
            }
        })

    }, []);

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const deepCopy = (obj) => {
        return JSON.parse(JSON.stringify(obj));
    }

    //order by descending
    const compareByMarketValueChangeMoM = (a, b) => {
        if (a.net_buying_selling < b.net_buying_selling) {
            return 1;
        }
        if (a.net_buying_selling > b.net_buying_selling) {
            return -1;
        }
        return 0;
    }

    const compareByMarketValueChangePercent = (a, b) => {
        if (a.net_buying_selling_percentage < b.net_buying_selling_percentage) {
            return 1;
        }
        if (a.net_buying_selling_percentage > b.net_buying_selling_percentage) {
            return -1;
        }
        return 0;
    }


    const handleRadioChange = (event) => {
        const {isXSView, isLGView, isXLView,isMobileView} = props;
        let chartType =  event ;

        let newChartData = [];
        let newLabelData = [];
        let chartObj = getChartData(chartType, tableData, 'industry', isLGView, isXLView,isMobileView);

        setChartType(chartType);
        setPercentage(chartType === 'changePercent' || chartType === 'changeSellingPercent' );
        setChartLabelsArray(chartObj?.newLabelData);
        setChartDataArray(chartObj?.newChartData);
    };

    const handleClick = (elementIndex) => {
        let indexArr = getIndexArr(chartType, tableData);
            let clickedIndustry = indexArr[elementIndex];
            props.history.push('/sectors/'+clickedIndustry.industry)

    };

    const setIndustryGraph = (data) => {
        const {isLGView, isXLView, isMobileView} = props;

        let length = data.length;
        if(data.length> 10){
            length= 10;
        }

        for (let i = 0; i < length; i++) {

            let industryName = getTrimVal(data[i].industry, isLGView, isXLView, isMobileView);
            let marketValue = getFixedValuesAfterDecimal(data[i].total_market_value,2);

            setChartLabelsArray(industryNames => [...industryNames, industryName]);
            setChartDataArray(marketValueInCrores => [...marketValueInCrores, marketValue]);

        }
    };

    const getResult = (data) =>{

        if (isEmpty(data) || chartType === "marketValue"){
            return
        }

        if (data?.length >= 10){
            return(
                <div className='py-2 mx-3'>
                    Showing top 10 results
                </div>
            )
        }else {
            if (data.length === 1){
                return (
                    <div className='py-2 mx-3'>
                        Showing {data?.length} result
                    </div>
                )
            }else {
                return (
                    <div className='py-2 mx-3'>
                        Showing {data?.length} results
                    </div>
                )

            }
        }
    };

    const [isLoading, setIsLoading] = useState(true);

    const {fileName} = props;

    if (isLoading) {
        return (
            <div className={'row px-2 h-100'}>
                <div className={"col-12 d-flex flex-column justify-content-center align-items-center mb-1"}>
                    <DLLoader type={'inline'} isVisible={true}/>
                </div>
            </div>
        )
    }
    return (
        <div className='panel mb-4'
             id='fund-house-sector'>
            <div className='d-flex justify-content-between px-3 pt-3'>
                <DLText
                    id={'summary-by-country'}
                    type={'normal'}
                    text={'Sectors'}
                    fontWeight={'semi-bold'}
                    fontSize={'lg'}/>
                <div className='ms-1' style={{width: 36, height: 36, minWidth: 'max-content'}}>
                    {/*<DLButton*/}
                    {/*    id={'download-excel'}*/}
                    {/*    styleType={"outlined"}*/}
                    {/*    borderType={'square'} buttonSize={'sm'}*/}
                    {/*    sideMargin={"none"}*/}
                    {/*    reduceWidth={true}*/}
                    {/*    style={{width: 36}}*/}
                    {/*    type={'primary'}*/}
                    {/*    onClick={() => {*/}
                    {/*        // downloadExcel(tableData)*/}
                    {/*        downloadData('/service/media/fundHouse/sectorSummary/download?date='+VALUATION_DATE+'&fundHouseId=' + fundHouseId, fundHouseId, 'Download', fileName)*/}
                    {/*    }}*/}
                    {/*    label={<i className='fas fa-download fs-6'/>}*/}
                    {/*/>*/}
                    <div className='d-flex align-items-center justify-content-center downloadButtonCss cursor'>
                        <i className='fas fa-download fs-6'
                           onClick={() => {
                               // downloadExcel(tableData)
                               downloadData('/service/media/fundHouse/sectorSummary/download?date='+VALUATION_DATE+'&fundHouseId=' + fundHouseId, fundHouseId, 'Download', fileName)
                           }}
                           style={{fontSize: 12}}/>
                    </div>
                </div>
            </div>
            <Box sx={{ width: '100%' }}>
                <Box className='d-flex justify-content-between'
                     sx={{ borderBottom: 1, borderColor: 'divider', marginLeft: '20px' ,marginRight:'20px' }}>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Chart" {...a11yProps(0)} sx={{ textTransform:'capitalize'}}/>
                        <Tab label="Table" {...a11yProps(1)} sx={{ textTransform:'capitalize'}}/>
                    </Tabs>

                </Box>
                <CustomTabPanel value={tabValue} index={0} id="tableTabPanel">
                    {(isEmpty(chartDataArray) || isEmpty(chartLabelsArray)) ?
                        <div className='d-flex flex-column'>

                            <ChartRadioGroup handleRadioChange={handleRadioChange}
                                             chartType={chartType}
                                             isXSView={props.isXSView}
                                             isSMView={props.isSMView}/>
                            <div className='d-flex align-items-center justify-content-center mx-3' style={{minHeight: 500}}>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={'No data found'}
                                fontSize={'md'}/>
                                             </div>
                        </div>
                        :
                        <>
                            <ChartRadioGroup handleRadioChange={handleRadioChange}
                                             chartType={chartType}
                                             isXSView={props.isXSView}
                                             isSMView={props.isSMView}/>
                                {getResult(chartDataArray)}
                            <IndustryChart {...props}
                                           yAxis={chartLabelsArray}
                                           xAxis={chartDataArray}
                                           handleClick = {handleClick}
                                           chartType={chartType}
                                           percentage={percentage}
                                           isMobileView={props.isMobileView}/>

                        </>
                    }
                    </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1} id="tableTabPanel">
                    {(isEmpty(tableData) || isEmpty(tableHeader)) ?
                        <div className='d-flex align-items-center justify-content-center' style={{minHeight: 500}}>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={'No data found'}
                                fontSize={'md'}/>
                        </div>
                        :
                        <IndustryTable data={tableData} {...props} tableHeader={tableHeader}/>
                    }
                </CustomTabPanel>
            </Box>

        </div>
    )

}

export default withRouter(Industry);
