import React, {useEffect, useState} from 'react';
import FundHouse from "./FundHouse/FundHouse";
import Stocks from "./StocksSummary/Stocks";
import Funds from "./Fund/Funds";
import {industryConst} from "./industryConst";
import DLText from "dlcomponent/components/DLText/DLText";
import {getMobileHeight, numberWithCommas} from "../utils/helper";
import LineBarChartMixed from "../component-utils/charts/LineBarChartMixed";
import {isEmpty} from "../utils/validations";
import Industry from "../fundHouse/Industry/Industry";
import DLLoader from "../core/components/DLLoader/DLLoader";
import moment from 'moment'
import PopComponent from "./PopComponent";
const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// TODO use lazy load for each component which is firing the api
function IndustryView(props) {

    const {industry, refresh, isMDView, isLGView, isMobileView, UIState, isXSView,handleOpenModal} = props;
    const [activeSection, setActiveSection] = useState('section1');
    const [value, setValue] = React.useState(0);

    const industryArr = [{industry}];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const addScroll = (id) =>{
        const element = document.getElementById(id);
        if (!isEmpty(element)) {
            element.scrollIntoView({behavior: "smooth", block: "start"});
        }
    };

    const handleScroll = () => {
        const myDiv = document.getElementById('myDiv');
        const pageYOffset = myDiv?.scrollTop + 64;
        let sectionsHeight = 800;

        let section1Height; // to active nextsections when previous section is at end
        let section2Height;//mobile -400
        let section3Height; //mobile -400
        let section4Height;//mobile -400
        let section5Height;//mobile -400

        if (!isMobileView){
            section1Height = sectionsHeight - 300; // to active nextsections when previous section is at end
            section2Height = section1Height + 500;//mobile -400
            section3Height = section2Height + 650; //mobile -400
            section4Height = section3Height + 500;//mobile -400
        }else {
            section1Height = sectionsHeight-150 ; // to active nextsections when previous section is at end
            section2Height = section1Height + 600;//mobile -400
            section3Height = section2Height + 500; //mobile -400
            section4Height = section3Height + 600;//mobile -400
            section5Height = section4Height + 500;//mobile -400
        }

        if (pageYOffset < section1Height) {
            setActiveSection('section1');
        } else if (pageYOffset < section2Height) {
            setActiveSection('section2');
        } else if (pageYOffset < section3Height) {
            setActiveSection('section3');
        } else if (pageYOffset < section4Height) {
            setActiveSection('section4');
        } else if (pageYOffset < section5Height) {
            setActiveSection('section5');
        }
    };

    const renderSummaryUI = () => {
        const {activitiesStateLoaded, yAxisBar, isXSView, isMobileView, yAxisLine, xAxis, activitiesData, fundHouseCount, fundSchemeCount, marketValue} = props;


        return (
            <div className='d-flex flex-lg-row flex-column'
                 id='sector-summary'
                 style={{flex: 1}}>

                <div className='pt-3'
                     data-section id={'section1'}
                     style={{flex: (isMDView) ? 5 : isLGView ? 7 : 7.5}}>
                    <div className='panel px-3 pt-sm-3 pt-0 me-lg-2'>

                    <div className='flex-shrink-1' style={{flex: 1}}>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={'Overview'}
                            marginBottom={'md'}
                            fontWeight={'semi-bold'}
                            fontSize={'lg'}/>
                        <div className='d-flex flex-lg-row flex-column w-100'>
                            <div className='d-flex flex-lg-column flex-row mb-3' style={{minWidth: 'max-content'}}>
                                <div className={isMobileView ? 'border-end py-1 h-100' : 'border-bottom py-3 pb-md-0'}
                                     style={{flex: isMobileView ? 1 : '', height: isMobileView ? '100%' : '30%'}}>
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={'AUM'}
                                        fontSize={'sm'}/>
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={numberWithCommas(marketValue)}
                                        fontWeight={'bold'}
                                        fontSize={'md'}/>
                                </div>
                                <div className={isMobileView ? 'border-end d-flex flex-column justify-content-center py-1 py-md-0 ps-3' : 'border-bottom d-flex flex-column justify-content-center py-3 py-md-0'}
                                    style={{flex: isMobileView ? 1 : '', height: "40%"}}>
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={' Fund Houses'}
                                        fontSize={'sm'}/>
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={fundHouseCount}
                                        fontWeight={'bold'}
                                        fontSize={'md'}/>
                                </div>
                                <div className={isMobileView ? 'd-flex flex-column justify-content-end py-1 ps-3' : 'd-flex flex-column justify-content-end pt-3 pt-md-0'}
                                    style={{flex: isMobileView ? 1 : '', height: "30%"}}>
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={' Funds'}
                                        fontSize={'sm'}/>
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={fundSchemeCount}
                                        fontWeight={'bold'}
                                        fontSize={'md'}/>
                                </div>
                            </div>

                            <div className='w-100'>
                                <LineBarChartMixed {...props} id={"industry-overview"} isBarChartClickable={true} getBarClick={getBarClick} isFrom={'industry'} selectedData={industryArr}/>
                            </div>
                        </div>
                    </div>

                </div>
                </div>
                {isXSView ? <div style={{height: '10px', backgroundColor: '#C5C5C5'}}/> : <></>}


                {/*<div className='panel p-3 mt-sm-3 ' data-section id={(isMobileView  ? 'section2' : '')} style={{flex: (isMDView) ? 5 : isLGView ? 3 : 2.5}}>*/}
                {/*    <DLText*/}
                {/*        id={'revenue-value'}*/}
                {/*        type={'normal'}*/}
                {/*        text={'Activities in '+ moment(VALUATION_DATE).format('MMM-yyyy')}*/}
                {/*        fontWeight={'semi-bold'}*/}
                {/*        marginBottom={'none'}*/}
                {/*        fontSize={'lg'}/>*/}
                {/*    <div className='d-flex flex-lg-column flex-row pt-2'>*/}
                {/*        <div className={isMobileView ? 'py-4' : 'border-bottom py-4'} style={{flex: 1}}>*/}
                {/*            <DLText*/}
                {/*                id={'revenue-value'}*/}
                {/*                type={'normal'}*/}
                {/*                text={'Buying'}*/}
                {/*                fontWeight={'semi-bold'}*/}
                {/*                fontColor={'success'}*/}
                {/*                fontSize={'md'}/>*/}
                {/*            <div className='d-flex'>*/}
                {/*                <div style={{flex: 1}}>*/}
                {/*                    <DLText*/}
                {/*                        id={'revenue-value'}*/}
                {/*                        type={'normal'}*/}
                {/*                        text={'Net Addition'}*/}
                {/*                        fontSize={'sm'}/>*/}
                {/*                    <DLText*/}
                {/*                        id={'revenue-value'}*/}
                {/*                        type={'normal'}*/}
                {/*                        text={numberWithCommas((activitiesData?.buyingFundsMarketValue)?.toFixed(2))}*/}
                {/*                        fontWeight={'bold'}*/}
                {/*                        fontSize={'sm'}/>*/}
                {/*                    <DLText*/}
                {/*                        id={'revenue-value'}*/}
                {/*                        type={'normal'}*/}
                {/*                        text={numberWithCommas(activitiesData?.buyingFundsCount) + ' Funds'}*/}
                {/*                        fontSize={'sm'}/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        {isMobileView &&*/}
                {/*        <div className='border me-3 my-sm-0 my-3'>*/}
                {/*        </div>*/}
                {/*        }*/}
                {/*        <div className='pt-4' style={{flex: 1}}>*/}
                {/*            <DLText*/}
                {/*                id={'revenue-value'}*/}
                {/*                type={'normal'}*/}
                {/*                text={'Selling'}*/}
                {/*                fontWeight={'semi-bold'}*/}
                {/*                fontColor={'danger'}*/}
                {/*                fontSize={'md'}/>*/}
                {/*            <div className='d-flex'>*/}
                {/*                <div style={{flex: 1}}>*/}
                {/*                    <DLText*/}
                {/*                        id={'revenue-value'}*/}
                {/*                        type={'normal'}*/}
                {/*                        text={'Net Reduction'}*/}
                {/*                        fontSize={'sm'}/>*/}
                {/*                    <DLText*/}
                {/*                        id={'revenue-value'}*/}
                {/*                        type={'normal'}*/}
                {/*                        text={isEmpty(activitiesData?.sellingFundsMarketValue) ? '' : numberWithCommas(Math.abs(activitiesData?.sellingFundsMarketValue)?.toFixed(2))}*/}
                {/*                        fontWeight={'bold'}*/}
                {/*                        fontSize={'sm'}/>*/}
                {/*                    <DLText*/}
                {/*                        id={'revenue-value'}*/}
                {/*                        type={'normal'}*/}
                {/*                        text={numberWithCommas(activitiesData?.sellingFundsCount) + ' Funds'}*/}
                {/*                        marginBottom={'none'}*/}
                {/*                        fontSize={'sm'}/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {isXSView ? <div style={{height: '10px', backgroundColor: '#C5C5C5'}}/> : <></>}

            </div>
        )
    };

    const getBarClick =(e)=>{
        const {xAxis, yAxisBar} = props;
        if(isEmpty(e)){
            return;
        }
        let graphMonth = xAxis.find((item, index)=>{
            return index === e;
        })

        handleOpenModal(graphMonth,industry);

    }

    const renderDesktopView = () => {
        const {handleTabChange, tabValue, isSMView, isXSView, isMobileView} = props;
        return (
            <div>
                <div>
                    {renderSummaryUI()}
                </div>
                <div className={isXSView ? 'pt-0' : ' pt-3'} data-section id={'section2'}>
                    <FundHouse industry={industry} {...props}/>
                </div>
                {isXSView ? <div style={{height: '10px', backgroundColor: '#C5C5C5'}}/> : <></>}

                <div className={isXSView ? 'pt-0' : ' pt-3'} data-section id={'section3'}>
                    <Funds industry={industry} {...props}/>
                </div>
                {isXSView ? <div style={{height: '10px', backgroundColor: '#C5C5C5'}}/> : <></>}

                <div className={isXSView ? 'pt-0' : ' pt-3 mb-2'} data-section id={'section4'}>
                    <Stocks industry={industry} {...props} />
                </div>
            </div>
        );
    };

    const renderUI = () => {
        return renderDesktopView();
    };

    if (UIState === industryConst.CONTENT_UNAVAILABLE) {
        return
    }

    if (isEmpty(industry)) {
        return <DLLoader type={'screen'} isVisible={true}/>
    }

    const activeStyle = {
        fontWeight: 500,
        color: '#282828',
        textDecoration: 'underline',
        fontFamily: 'Poppins, sans-serif',

    };
    props.handleChange();

    return (<>
        <div className="bg-white ">
            <div className='bg-white ' style={{
                position: 'sticky',
                top: 0,
                boxShadow: " 0px 1px 1px 0px rgba(0, 0, 0, 0.20)",
                zIndex: 999
            }}>
                <div className={'px-3 py-2 w-100'}>
                    <div className={'d-flex'}>
                        <i className="fa-solid fa-chevron-left cursor pe-2"
                           style={{marginTop: 8}}
                           onClick={() => props.history.goBack()}/>
                        <div className=''>
                            <DLText marginBottom={'none'}
                                    isInline={true}
                                    text={<span className='d-flex flex-wrap text-wrap'><span
                                        className='pe-2'>{industry}</span>
                                                <span className='d-flex align-items-end '
                                                      style={{paddingBottom: 2, minWidth: 'max-content', fontSize: 12}}>
                                                    {'Sector'}
                                                </span>
                                    </span>
                                    }
                                    fontWeight={'semi-bold'} fontSize={'lg'}/>
                        </div>
                    </div>
                </div>

                <nav className='w-100 pt-1 '>
                    <ul className='d-flex ulLine' style={{listStyle: 'none', overflowX: 'auto'}}>
                        <li className={activeSection === 'section1' ? 'active' : ''} style={{margin: '0 10px', paddingLeft: 6, minWidth:'max-content'}}>
                            <div className="custom-underlined-text cursor"
                                 style={activeSection === 'section1' ? activeStyle : {}}
                                 onClick={()=>{addScroll('section1')}}>
                                Overview
                            </div>
                            {activeSection === 'section1' &&
                            <div className='bg-dark mt-1' style={{minHeight:'2px'}}>

                            </div>
                            }
                        </li>
                        {/*{isMobileView &&*/}
                        {/*<li className={activeSection === 'section2' ? 'active' : ''}*/}
                        {/*    style={{margin: '0 12px', minWidth: 'max-content'}}>*/}
                        {/*    <div className="custom-underlined-text cursor"*/}
                        {/*         style={activeSection === 'section2' ? activeStyle : {}}*/}
                        {/*         onClick={()=>{addScroll('section2')}}>*/}
                        {/*        Activities*/}
                        {/*    </div>*/}
                        {/*    {activeSection === 'section2' &&*/}
                        {/*    <div className='bg-dark mt-1' style={{minHeight: '2px'}}>*/}

                        {/*    </div>*/}
                        {/*    }*/}
                        {/*</li>*/}
                        {/*}*/}
                        <li className={activeSection === 'section2' ? 'active' : ''} style={{margin: '0 12px', minWidth:'max-content'}}>
                            <div className="custom-underlined-text cursor"
                                 style={activeSection === 'section2' ? activeStyle : {}}
                                 onClick={()=>{addScroll('section2')}}>
                                Fund Houses
                            </div>
                            {activeSection === 'section2' &&
                            <div className='bg-dark mt-1' style={{minHeight:'2px'}}>

                            </div>
                            }
                        </li>
                        <li className={activeSection === 'section3' ? 'active' : ''} style={{margin: '0 12px', minWidth:'max-content'}}>
                            <div className="custom-underlined-text cursor"
                                 style={activeSection === 'section3' ? activeStyle : {}}
                                 onClick={()=>{addScroll('section3')}}>
                                Funds
                            </div>
                            {activeSection === 'section3' &&
                            <div className='bg-dark mt-1' style={{minHeight:'2px'}}>

                            </div>
                            }
                        </li>
                        <li className={activeSection === 'section4' ? 'active' : ''} style={{margin: '0 12px', width:'max-content'}}>

                            <div className="custom-underlined-text cursor"
                                 style={activeSection === 'section4' ? activeStyle : {}}
                                 onClick={()=>{addScroll('section4')}}>
                                Stocks
                            </div>
                            {activeSection === ('#section4') &&
                            <div className='bg-dark mt-1' style={{minHeight:'2px'}}>

                            </div>
                            }
                            {activeSection === 'section4' &&
                            <div className='bg-dark mt-1' style={{minHeight:'2px'}}>

                            </div>
                            }
                        </li>
                    </ul>
                </nav>
            </div>
            <div id={'myDiv'}
                 onScroll={()=>{
                     handleScroll()
                 }}
                 style={{
                    maxHeight: isMobileView ? getMobileHeight(industry, isMobileView, isXSView) : 'calc(100vh - 165px)',
                    overflowY: 'auto',
                    scrollBehavior: 'smooth'
                }}>

                <div className='px-sm-3'>
                    {renderUI()}
                    {
                        <PopComponent {...props} selectedData={industryArr}/>
                    }
                </div>
            </div>
        </div>
    </>);
}

IndustryView.propTypes = {};

export default IndustryView;
