import {httpGet, httpPost, httpDelete} from "../utils/httpHelper";
import store from "../store";
import {showSystemErrorBlock,showToastMessage} from "../core/actions/appActions";
import {setWatchlistDataToStore,resetWatchlistObjData,setSelectedFundList,setSelectedStockList} from "./WatchlistReducer"
import DLToastMeassage from '../core/components/DLToastMessage/DLToastMessage'
import {setKeyword} from "../stocks/stockReducer";


export const saveCreatedWatchList = (name, type) => {
    return httpPost('/service/watchLists', {
        name: name,
        type: type
    }).then(res=> {
        return res;
    })
    // }).catch((res)=>{
    //         store.dispatch(showToastMessage('warning',res?.error));
    //         return null;
    //     })
};

export const getWatchLists= () => {
    return httpGet('/service/watchLists/')
        .then(res=>{
            return res;
        })
        .catch((res)=>{
            store.dispatch(showToastMessage('warning',res?.error));
            return null;
        })
};

export const getFundsSummary= (fundIds) => {
    return httpPost('/service/watchLists/fund/dimension/summary',{
        fundIds : fundIds
    })
        .then(res=>{
            return res;
        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getStockTrendForFund= (fundIds) => {
    return httpPost('/service/watchLists/fund/monthlyTrend', {
     fundIds : fundIds
    });
};

export const getSectorSummary= (fundIds) => {
    return httpPost('/service/watchLists/fund/sectorSummary', {
        fundIds : fundIds
    });
};

export const getStockSummary= (fundIds) => {
    return httpPost('/service/watchLists/fund/stockSummary', {
        fundIds : fundIds
    });
};


export const getNetBuyingSellingPopUpData= (date,fundHouseId,fundId) => {
    let url = base_url+ '/service/fund/lastMonthSummary?date='+date+'&fundHouseId='+fundHouseId+'&fundId='+fundId;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getStockTrend= (isinList) => {
    return httpPost('/service/watchLists/stock/monthlyTrend', {
        isinList : isinList
    });
};

export const getStockDetails= (isinList) => {
    return httpPost('/service/watchLists/stock/details', {
        isinList: isinList
    });
};

export const getSectorOfStocks= (isinList) => {
    return httpPost('/service/watchLists/stock/sector', {
        isinList: isinList
    });
};



export const getFundcount= (isinList) => {
    return httpPost('/service/watchLists/stock/funds', {
        isinList: isinList
    });
};

export const saveWatchlistData= (watchlist_id,elements) => {
    return httpPost('/service/watchLists/elements', {
        watchlist_id:watchlist_id,
        elements:  elements
    })
};

export const getWatchlistData= (watchlist_id) => {
    return httpGet(`/service/watchLists/elements?watchlist_id=${watchlist_id}`,);
};

export const deleteSelectedWatchlist= (watchlist_id) => {
    return httpDelete(`/service/watchLists?id=${watchlist_id}`,);
};

export const copyWatchlist = ( watchlist_id,name) => {
    return httpPost(`/service/watchLists/copy`,{
        watchlist_id:watchlist_id,
        name:name
    })
};

export const setWatchlistData = (watchlistObj) =>{
    store.dispatch(setWatchlistDataToStore(watchlistObj))
}

export const resetWatchlistObj = (data) =>{
    store.dispatch(resetWatchlistObjData(data))
}

export const setSelectedFunds = (data) =>{
    store.dispatch(setSelectedFundList(data))
}

export const setSelectedStocks = (data) =>{
    store.dispatch(setSelectedStockList(data))
}


