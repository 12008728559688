import {createSlice} from '@reduxjs/toolkit'
import {setAdvanceStockKeyword} from "../AdvanceSearch/AdvanceSearchReducer";

const appSlice = createSlice({
    name: 'ExplorerReducer',
    initialState: {
        drillDownBy: "fundHouse",
        fundsKeyword: '',
        industryKeyword: '',
        fundHouseKeyword: '',
        businessHouseKeyword: '',
        stockKeyword: '',
        marketCapData:[],
        stockListingData:[],
        industryListingData:[],
        businessHouseListingData:[],
        marketCapDataLoaded: false,
        industryListingDataLoaded: false,
        explorerFilter:{
            businessHouses: [],
            fundHouses: [],
            funds: [],
            fundHousesName: [],
            fundsName: [],
            marketCaps: [],
            industries: [],
            stocks: [],
            stocksName: [],
            fundHouseForFund: [],
        }
    },
    reducers: {
        setDrillDownBy(state, action) {
            state.drillDownBy = action.payload;
        },
        setExplorerFundHouseKeyword(state, action) {
            state.fundHouseKeyword = action.payload;
        },
        setExplorerFundsKeyword(state, action) {
            state.fundsKeyword = action.payload;
        },
        setExplorerIndustryKeyword(state, action) {
            state.industryKeyword = action.payload;
        },
        setExplorerBusinessHouseKeyword(state, action) {
            state.businessHouseKeyword = action.payload;
        },
        setExplorerStockKeyword(state, action) {
            state.stockKeyword = action.payload;
        },

        setExplorerIndustryData(state, action) {
            state.industryListingData = action.payload;
            state.industryListingDataLoaded = true
        },

        setExplorerStocksData(state, action) {
            state.stockListingData = action.payload;
            state.stockListingDataLoaded = true
        },

        setExplorerMarketCapData(state, action) {
            state.marketCapData = action.payload;
            state.marketCapDataLoaded = true
        },

        setExplorerBusinessHousesData(state, action) {
            state.businessHouseListingData = action.payload;
            state.businessHouseListingDataLoaded = true
        },

        setExplorerFilterData(state, action) {
            state.explorerFilter = {
                ...state.explorerFilter,
                [action.payload.name] : action.payload.value
            }
        },

        setExplorerFilterDataObj(state, action) {
            state.explorerFilter.businessHouses = action.payload.businessHouses;
            state.explorerFilter.fundHouses = action.payload.fundHouses;
            state.explorerFilter.funds = action.payload.funds;
            state.explorerFilter.fundHousesName = action.payload.fundHousesName;
            state.explorerFilter.fundsName = action.payload.fundsName;
            state.explorerFilter.marketCaps = action.payload.marketCaps;
            state.explorerFilter.industries = action.payload.sectors;
            state.explorerFilter.stocks = action.payload.stocks;
            state.explorerFilter.stocksName = action.payload.stocksName;
            state.explorerFilter.fundHouseForFund = action.payload.fundHousesForFunds;
        },

        resetExplorerMarketCapData(state, action) {
            state.marketCapData = [];
            state.explorerFilter = {
                ...state.explorerFilter,
                marketCaps : []
            };
        },

        resetExplorerIndustryData(state, action) {
            state.industryListingData = [];
            state.explorerFilter = {
                ...state.explorerFilter,
                industries : []
            };
        },

        resetExplorerStocksData(state, action) {
            state.stockListingData = [];
            state.explorerFilter = {
                ...state.explorerFilter,
                stocks : [],
                stocksName: []
            };
        },

        resetExplorerBusinessHousesData(state, action) {
            state.businessHouseListingData = [];
            state.explorerFilter = {
                ...state.explorerFilter,
                businessHouses : []
            };
        },

        resetExplorerAllFilter(state, action) {
            state.drillDownBy = [""];
            state.businessHouseListingData = [];
            state.marketCapData = [],
                state.stockListingData = [],
                state.industryListingData = [],
                state.explorerFilter = {
                    ...state.explorerFilter,
                    businessHouses: [],
                    fundHouses: [],
                    funds: [],
                    fundHousesName: [],
                    fundsName: [],
                    marketCaps: [],
                    industries: [],
                    stocks: [],
                    stocksName: [],
                    fundHouseForFund: [],
                };
        },
    }
});

export const {setDrillDownBy, setExplorerFundHouseKeyword, setExplorerFundsKeyword, setExplorerIndustryData, setExplorerIndustryKeyword, setExplorerStockKeyword,
    setExplorerBusinessHouseKeyword, setExplorerFilterData, setExplorerStocksData, setExplorerMarketCapData, setExplorerBusinessHousesData,setExplorerFilterDataObj,
    resetExplorerMarketCapData, resetExplorerBusinessHousesData, resetExplorerStocksData, resetExplorerIndustryData, resetExplorerAllFilter } = appSlice.actions;
export default appSlice.reducer
