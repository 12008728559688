import {httpPost} from "../utils/httpHelper";
import store from "../store";
import {resetFromAndToDate, setMoneyExplorerFromDate, setMoneyExplorerToData, resetExternalFromAndToDate} from "./MoneyExplorerReducer";
import {isEmpty} from "../utils/validations";

export const getMoneyExplorerDrillDown = (data, fromDate, toDate) => {
    let apiUrl = '/service/moneyFlow/drilldown';
    // if (isEmpty(fromDate) && isEmpty(toDate)){
    //     apiUrl = '/service/moneyFlow/drilldown';
    // }else  if (!isEmpty(fromDate) && !isEmpty(toDate)){
    //     apiUrl = '/service/home/drilldown?fromDate='+fromDate+'&toDate='+toDate;
    // }else if (isEmpty(fromDate)){
    //     apiUrl = '/service/home/drilldown?toDate='+toDate;
    // }else if (isEmpty(toDate)){
    //     apiUrl = '/service/home/drilldown?fromDate='+fromDate;
    // }

    return httpPost(apiUrl, data)
        .then(res=>{
            return res;
        })
};

export const setFromDate = (date) =>{
    store.dispatch(setMoneyExplorerFromDate(date))
};

export const setToDate = (date) =>{
    store.dispatch(setMoneyExplorerToData(date))
};

export const resetFromAndToDateFilter = (date) =>{
    store.dispatch(resetFromAndToDate(date))
};

export const resetExternalFromAndToDateFilter = (date) =>{
    store.dispatch(resetExternalFromAndToDate(date))
};