import React, {useEffect, useState} from 'react';
import DLButton from "dlcomponent/components/DLButton/DLButton";
import {isEmpty} from "../../utils/validations";
import DLText from "dlcomponent/components/DLText/DLText";
import DLCheckbox from "dlcomponent/components/DLCheckbox/DLCheckbox";
import {getMobileHeight, getTrimmedValue} from "../../utils/helper";
import appTheme from "../../assets/appTheme";
import FundListingModal from "../FundModal/FundListingModal";
import Funds from './Funds/Funds'
import StockSearchModal from "../StockModal/StockSearchModal";
import Stocks from "./Stocks/Stocks";
import ConfirmationModal from "./Confirmation-Modal/ConfirmationModal";
import CreateWatchListModal from "../createWatchList-Modal/CreateWatchList-Modal";
import DLMenuOptions from "dlcomponent/components/DLMenuOptions/DLMenuOptions"
import AddIcon from "@mui/icons-material/Add";
import Dropdown from 'react-bootstrap/Dropdown';
import {dataConst} from "./UserWatchListConstant";
import Create_watchlist from "../../assets/img/create_watchlist.png"

const roleError = '';

function UserWatchListView(props) {

    const {
        AddFundsClick,
        checkboxChange,
        handleCheckboxChange,
        selectedWatchListObj,
        handleOnSelect,
        dropDownList,
        handleMenuOptionsSelect,
        UIState,
        handleCreateOpenModal,
        handleExpandClick,
        isExpanded,
        isMobileView,
        isXLView,
        isLGView,
        isMDView,
        isSMView,
        isXSView,
        selectedRole
    } = props;

    const [activeSection, setActiveSection] = useState('section1');
    const[hoveredRole, setHoveredRole] = useState(null);

    const selectedFunds = selectedWatchListObj.fundList && selectedWatchListObj.fundList.length > 0
        ? selectedWatchListObj.fundList
            .filter((fund) => fund.isSelected)
            .map(({fund_id, fund_house_id, fund_name}) => ({
                fund_id,
                fund_house_id,
                fund_name,
            }))
        : [];

    const selectedStocks = selectedWatchListObj.stockList && selectedWatchListObj.stockList.length > 0
        ? selectedWatchListObj.stockList
            .filter((stock) => stock.isSelected)
            .map(({company_name, isin_code}) => ({
                company_name,
                isin_code,
            }))
        : [];


    const handleScroll = () => {
        const myDiv = document.getElementById('myDiv');
        const pageYOffset = myDiv?.scrollTop + 64;
        let sectionsHeight = 600;

        let section1Height; // to active nextsections when previous section is at end
        let section2Height;//mobile -400
        let section3Height; //mobile -400
        let section4Height;//mobile -400

        if (isXLView) {
            section1Height = sectionsHeight; // to active nextsections when previous section is at end
            section2Height = section1Height + 500;//mobile -400
            section3Height = section2Height + 650; //mobile -400
            section4Height = section3Height + 500;//mobile -400
        }
        if (isLGView) {
            section1Height = sectionsHeight; // to active nextsections when previous section is at end
            section2Height = section1Height + 600;//mobile -400
            section3Height = section2Height + 600; //mobile -400
            section4Height = section3Height + 500;//mobile -400
        }

        if (isMDView) {
            section1Height = 800; // to active nextsections when previous section is at end
            section2Height = section1Height + 600;//mobile -400
            section3Height = section2Height + 600; //mobile -400
            section4Height = section3Height + 500;//mobile -400
        }

        if (isSMView || isXSView) {
            section1Height = 1100; // to active nextsections when previous section is at end
            section2Height = section1Height + 600;//mobile -400
            section3Height = section2Height + 300; //mobile -400
            section4Height = section3Height + 500;//mobile -400
        }
        if (pageYOffset < section1Height) {
            setActiveSection('section1');
        } else if (pageYOffset < section2Height) {
            setActiveSection('section2');
        } else if (pageYOffset < section3Height) {
            setActiveSection('section3');
        } else if (pageYOffset < section4Height) {
            setActiveSection('section4');
        }
    };

    const displayFundsAccordianHeader = () => {
        if (selectedWatchListObj.fundCount === null || selectedWatchListObj.fundCount === 0) {
            return 'Funds'
        } else {
            return `Funds (${selectedWatchListObj.fundCount})`
        }
    };


    const displayStocksAccordianHeader = () => {
        if (selectedWatchListObj.stockCount === null || selectedWatchListObj.stockCount === 0) {
            return 'Stocks'
        } else {
            return `Stocks (${selectedWatchListObj.stockCount})`
        }
    };

    const handleHeightofFundList  = (panelExpandedFlag,selectedValues) => {
        if((selectedWatchListObj.fundList.length !== 0) && panelExpandedFlag === true ){
            return 'calc(100vh - 480px)'
        }else{
            if(isEmpty(selectedValues)){
                return 'calc(100vh - 669px)'
            }
            return 'calc(100vh - 661px)'
        }
    };

    const handleHeightofStockList  = (panelExpandedFlag,selectedValues) => {
        if((selectedWatchListObj.stockList.length !==0) && panelExpandedFlag === true ){
            return 'calc(100vh - 480px)'
        }else{
            if(isEmpty(selectedValues)){
                return 'calc(100vh - 669px)'
            }
            return 'calc(100vh - 661px)'
        }
    };

   const renderSelectedFundValues = (selectedValues) =>{
       if (isEmpty(selectedValues)){
           return (
               <div className='d-flex align-items-center px-3 mt-3 justify-content-center' style={{paddingTop: 6}}>
                   <DLText
                       id={'revenue'}
                       type={'normal'}
                       text={'Please add funds to your watchlist'}
                       fontSize={'sm'}/>
               </div>
           )
       }
       else{
           return (
               <div style={{overflowY:"auto",height:250}}>
                   <div className={'me-3'}>
                   {
                       selectedValues.map((item, index) => {
                           return (
                               <div className={'py-1 customCheckBoxStyle'}
                                    style={{borderBottom: "0.5px solid var(--disabled)"}}>
                                   <DLCheckbox
                                       id={'scheduleUpload-weeklyUi-dayCheck-' + index}
                                       label={getTrimmedValue(item?.fund_name, 70)}
                                       isChecked={item.isSelected}
                                       disableHelperMessageHeight={true}
                                       labelColor={appTheme.appColor.black}
                                       onChangeValue={() => handleCheckboxChange(item, 'funds')}
                                       labelPlacement={'end'}
                                       marginBottom={'none'}
                                   />
                               </div>
                           )
                       })
                   }
                   </div>
                   </div>
           )
       }
    };

    const renderSelectedStockValues = (selectedValues) =>{
        if (isEmpty(selectedValues)){
            return (
                <div className='d-flex align-items-center px-3 mt-3 justify-content-center' style={{paddingTop: 6}}>
                    <DLText
                        id={'revenue'}
                        type={'normal'}
                        text={'Please add stocks to your watchlist'}
                        fontSize={'sm'}/>
                </div>
            )
        }
        else{
            return (
                <div style={{overflowY:"auto",height:250}}>
                    <div className={'me-3'}>
                    {selectedValues.map((item, index) => {
                        return (
                            <div className={'py-1 customCheckBoxStyle'}
                                 style={{borderBottom: "0.5px solid var(--disabled)"}}>

                                <DLCheckbox id={'scheduleUpload-weeklyUi-dayCheck-' + index}
                                            label={getTrimmedValue(item?.company_name, 45)}
                                            isChecked={checkboxChange(item, 'stocks')}
                                            disableHelperMessageHeight={true}
                                            labelColor={appTheme.appColor.black}
                                            onChangeValue={() => handleCheckboxChange(item, 'stocks')}
                                            labelPlacement={'end'}
                                            marginBottom={'none'}
                                />
                            </div>
                        )
                    })}
                    </div>
                </div>
            )
        }
    };

    const renderuiAccordian = () => {
        if (selectedWatchListObj.type === "FUNDS") {
            const visibleItems = isExpanded ? selectedWatchListObj.fundList : selectedWatchListObj.fundList.slice(0, 2);
            return (
                <div className={'panel p-3'} style={{border: "1px solid #D2D2D2", borderRadius: "20px 20px 0px 0px", maxHeight:handleHeightofFundList(isExpanded,visibleItems), minHeight:handleHeightofFundList(isExpanded,visibleItems)}}>
                    <div className={'d-flex justify-content-between'} style={{borderBottom: "1px solid #c5C5c5"}}>
                        <div className={'mb-1'}>
                            <DLText id={'revenue-value'}
                                    type={'normal'}
                                    isInline={true}
                                    text={displayFundsAccordianHeader()}
                                    marginBottom={'20px'}
                                    fontWeight={'semi-bold'}
                                    fontSize={'md'}/>
                        </div>
                        <div className={(selectedWatchListObj.fundList.length !== 0) ? 'd-flex gap-4' : 'd-flex'}>
                            <div onClick={() => AddFundsClick('ADD_FUNDS', 'isFundModalOpen')}><i
                                className="fa-solid fa-plus"/></div>

                            <div onClick={() => handleExpandClick()}> {(selectedWatchListObj.fundList.length !== 0) && (isExpanded === false ?
                                <i className="fa-solid fa-angle-up"/> : <i className="fa-solid fa-angle-down"/>)}</div>
                        </div>
                    </div>
                    <div>
                       {renderSelectedFundValues(visibleItems)}
                    </div>
                </div>
            )
        }
        if (selectedWatchListObj.type === "STOCKS") {
            const visibleItems = isExpanded ? selectedWatchListObj.stockList : selectedWatchListObj.stockList.slice(0, 2);
            return (
                <div className={'panel p-3'} style={{border: "1px solid #D2D2D2", borderRadius: "20px 20px 0px 0px",zIndex:999,  maxHeight:handleHeightofStockList(isExpanded,visibleItems), minHeight:handleHeightofStockList(isExpanded,visibleItems)}}>
                    <div className={'d-flex justify-content-between'} style={{borderBottom: "1px solid #C5C5C5"}}>
                        <div className={'mb-1'}>
                            <DLText id={'revenue-value'}
                                    type={'normal'}
                                    isInline={true}
                                    text={displayStocksAccordianHeader()}
                                    marginBottom={'20px'}
                                    fontWeight={'semi-bold'}
                                    fontSize={'md'}/>
                        </div>
                        <div className={(selectedWatchListObj.stockList.length !==0) ? 'd-flex gap-4' : 'd-flex'}>
                            <div onClick={() => AddFundsClick('ADD_FUNDS', 'isStockModalOpen')}><i
                                className="fa-solid fa-plus"/></div>

                            <div onClick={() => handleExpandClick()}> {(selectedWatchListObj.stockList.length !==0) && (isExpanded === false ?
                                <i className="fa-solid fa-angle-up"/> : <i className="fa-solid fa-angle-down"/>)}</div>
                        </div>

                    </div>
                    <div>
                        {renderSelectedStockValues(visibleItems)}
                    </div>
                </div>
            )
        }
    };

    const renderDropDownUI = () => {
        if (isEmpty(dropDownList)) {
            return <></>
        }
        return dropDownList.map((role, index) => {
            return (
                <div key={index}>
                    <Dropdown.Item eventKey={role + index} style={{marginTop: '-4px',backgroundColor: hoveredRole === role.value ? '#f8f9fa' : 'initial'}}
                                 >
                        <div className="" style={{fontSize: "14px", fontWeight: "500", color: "#282828",}}
                             onClick={() => handleOnSelect(role)}
                             onMouseEnter={() => setHoveredRole(role.value)}
                             onMouseLeave={() => setHoveredRole(null)}
                             onTouchStart={() => setHoveredRole(role.value)}
                             onTouchEnd={() => setHoveredRole(null)}>
                            {getTrimmedValue(role.label, 60)}
                            <span style={{fontSize: "10px", marginLeft: '3px', color: "#666666"}}>
                                {role.type && role.type.charAt(0).toUpperCase() + role.type.slice(1).toLowerCase()}
                            </span>
                        </div>
                    </Dropdown.Item>
                </div>
            )
        })
    };

    const renderMenuOptionsForMobileview = () => {
        let menuOptions = [
            {
                label: 'Create a Watchlist',
                value: 'Create'
            },
            {
                label: 'Copy a Watchlist',
                value: 'Copy'
            },
            {
                label: 'Delete a Watchlist',
                value: 'Delete'
            }
        ];

        if (selectedWatchListObj.type === 'FUNDS' && (selectedWatchListObj.fundCount === null || selectedWatchListObj.fundCount === 0)) {
            return menuOptions;
        }

        if (selectedWatchListObj.type === 'STOCKS' && (selectedWatchListObj.stockCount === null || selectedWatchListObj.stockCount === 0)) {
            return menuOptions;
        }

        menuOptions.push(
            {
                label: 'Money Flow Explorer',
                value: 'MFE'
            },
            {
                label: 'Advanced Fund Search',
                value: 'AFS'
            },
            {
                label: 'Advanced Stock Search',
                value: 'ASS'
            }
        );

        return menuOptions;
    }


    const renderToOtherPages = () => {
        if (selectedWatchListObj.type === 'FUNDS') {
            if (selectedWatchListObj.fundCount === null || selectedWatchListObj.fundCount === 0) {
                return (<div/>)
            } else {
                return (
                    <div className={'d-flex justify-content-end mt-3'}>
                        <DLButton
                            id={'moneyFlowExplorerButton'}
                            label={'Money Flow Explorer'}
                            fontSize={'sm'}
                            buttonSize={'xs'}
                            style={{border: "0.5px solid var(--black)", color: "var(--black)"}}
                            styleType={'outlined'}
                            onClick={() => {
                                // props.history.push("/advanced-fund-search",{selectedStock : selectedStock, stockFilter: searched})
                                props.history.push("/money-flow-explorer", {
                                    selectedFund: selectedWatchListObj.type === "FUNDS" ? selectedFunds : "",
                                    selectedStock: selectedWatchListObj.type === "FUNDS" ? "" : selectedStocks,
                                    isFrom: "watchlist"
                                });
                            }}
                        />
                        <DLButton
                            id={'advancedFundSearchButton'}
                            label={'Advanced Fund Search'}
                            fontSize={'sm'}
                            buttonSize={'xs'}
                            style={{border: "0.5px solid var(--black)", color: "var(--black)"}}
                            styleType={'outlined'}
                            onClick={() => {
                                props.history.push("/advanced-fund-search", selectedWatchListObj.type === "FUNDS" ? {selectedFund: selectedFunds} : {selectedStock: selectedStocks});
                            }}
                        />
                        <DLButton
                            id={'advancedStockSearchButton'}
                            label={'Advanced Stock Search'}
                            fontSize={'sm'}
                            buttonSize={'xs'}
                            style={{border: "0.5px solid var(--black)", color: "var(--black)"}}
                            styleType={'outlined'}
                            onClick={() => {
                                props.history.push("/advanced-search", selectedWatchListObj.type === "FUNDS" ? {selectedFund: selectedFunds} : {selectedStock: selectedStocks});
                            }}
                        />

                    </div>
                )
            }
        }
        if (selectedWatchListObj.type === 'STOCKS') {
            if (selectedWatchListObj.stockCount === null || selectedWatchListObj.stockCount === 0) {
                return (<div/>)
            } else {
                return (
                    <div className={'d-flex justify-content-end mt-3'}>
                        <DLButton
                            id={'moneyFlowExplorerButton'}
                            label={'Money Flow Explorer'}
                            fontSize={'sm'}
                            buttonSize={'xs'}
                            style={{border: "0.5px solid var(--black)", color: "var(--black)"}}
                            styleType={'outlined'}
                            onClick={() => {
                                props.history.push("/money-flow-explorer", {
                                    selectedFund: selectedWatchListObj.type === "FUNDS" ? selectedFunds : "",
                                    selectedStock: selectedWatchListObj.type === "FUNDS" ? "" : selectedStocks,
                                    isFrom: "watchlist"
                                });
                            }}
                        />
                        <DLButton
                            id={'advancedFundSearchButton'}
                            label={'Advanced Fund Search'}
                            fontSize={'sm'}
                            buttonSize={'xs'}
                            style={{border: "0.5px solid var(--black)", color: "var(--black)"}}
                            styleType={'outlined'}
                            onClick={() => {
                                props.history.push("/advanced-fund-search", selectedWatchListObj.type === "FUNDS" ? {selectedFund: selectedFunds} : {selectedStock: selectedStocks});
                            }}
                        />
                        <DLButton
                            id={'advancedStockSearchButton'}
                            label={'Advanced Stock Search'}
                            fontSize={'sm'}
                            buttonSize={'xs'}
                            style={{border: "0.5px solid var(--black)", color: "var(--black)"}}
                            styleType={'outlined'}
                            onClick={() => {
                                props.history.push("/advanced-search", selectedWatchListObj.type === "FUNDS" ? {selectedFund: selectedFunds} : {selectedStock: selectedStocks});
                            }}
                        />

                    </div>
                )
            }
        }
    };

    const renderFirstPanelView = () => {
        if (selectedWatchListObj.type === "FUNDS") {
            if (selectedWatchListObj.fundCount === null || selectedWatchListObj.fundCount === 0) {
                return (
                    <div>
                        <div style={{marginTop: '30px', textAlign: 'center'}}>
                            <DLButton
                                id={'download-excel'}
                                borderType={'square'}
                                buttonSize={'md'}
                                fontSize={'xs'}
                                style={{
                                    border: "0.5px solid var(--black)",
                                    width: "95%",
                                    color: "var(--black)"
                                }}
                                styleType={'outlined'}
                                onClick={() => AddFundsClick('ADD_FUNDS', 'isFundModalOpen')}
                                label={'Add Funds'}
                            />
                        </div>
                        <div style={{marginTop: '100px', textAlign: 'center'}}>
                            <DLText
                                marginBottom={'none'}
                                isInline={true}
                                text={'Please add funds to your watchlist'}
                                fontSize={'sm'}
                                customTextStyle={{marginTop: '40px'}}
                            />
                        </div>
                    </div>

                )
            } else {
                return (
                    <div className={''}>
                        <div className={'d-flex align-items-center justify-content-between'}
                             style={{marginBottom: "20px"}}>
                            <DLText text={`Funds (${selectedWatchListObj.fundCount})`}
                                    fontSize={'lg'}
                                    fontWeight={'semi-bold'}
                                    marginBottom={'none'}/>

                            <DLButton
                                id={'download-excel'}
                                borderType={'square'}
                                buttonSize={'xs'}
                                fontSize={'xs'}
                                styleType={"outlined"}
                                style={{
                                    borderColor: "var(--disabled)",
                                    color: "var(--black)"
                                }}
                                onClick={() => AddFundsClick('ADD_FUNDS', 'isFundModalOpen')}
                                label={'Add Funds'}
                            />
                        </div>
                        <div className='d-flex w-100' id={'myDiv'}
                             onScroll={() => {
                                 handleScroll()
                             }}
                             style={{
                                 maxHeight: isMobileView ? getMobileHeight("", isXSView) : 'calc(100vh - 280px)',
                                 overflowY: 'auto',
                                 scrollBehavior: 'smooth'
                             }}>
                            <div style={{flex: 1}}>
                                {selectedWatchListObj.fundList.map((item, index) => {
                                    return (
                                        <div className={'py-1 customCheckBoxStyle'}
                                             style={{borderBottom: index !== selectedWatchListObj.fundList.length - 1 ? "0.5px solid var(--disabled)" : "none"}}>
                                            <DLCheckbox
                                                id={'scheduleUpload-weeklyUi-dayCheck-' + index}
                                                label={getTrimmedValue(item?.fund_name, 70)}
                                                isChecked={item.isSelected}
                                                disableHelperMessageHeight={true}
                                                labelColor={appTheme.appColor.black}
                                                onChangeValue={() => handleCheckboxChange(item, 'funds')}
                                                labelPlacement={'end'}
                                                marginBottom={'none'}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )
            }
        }
        if (selectedWatchListObj.type === "STOCKS") {
            if (selectedWatchListObj.stockCount === null || selectedWatchListObj.stockCount === 0) {
                return (
                    <div>
                        <div style={{marginTop: '30px', textAlign: 'center'}}>
                            <DLButton
                                id={'download-excel'}
                                borderType={'square'}
                                buttonSize={'md'}
                                fontSize={'xs'}
                                style={{
                                    border: "0.5px solid var(--black)",
                                    width: "95%",
                                    color: "var(--black)"
                                }}
                                styleType={'outlined'}
                                onClick={() => AddFundsClick('ADD_FUNDS', 'isStockModalOpen')}
                                label={'Add Stocks'}
                            />
                        </div>
                        <div style={{marginTop: '100px', textAlign: 'center'}}>
                            <DLText
                                marginBottom={'none'}
                                isInline={true}
                                text={'Please add stocks to your watchlist'}
                                fontSize={'sm'}
                                customTextStyle={{marginTop: '40px'}}
                            />
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className={''}>
                        <div className={'d-flex align-items-center justify-content-between'}>
                            <DLText text={`Stocks (${selectedWatchListObj.stockCount})`}
                                    fontSize={'lg'}
                                    fontWeight={'semi-bold'}
                                    marginBottom={'none'}/>
                            <DLButton
                                id={'download-excel'}
                                borderType={'square'}
                                buttonSize={'xs'}
                                fontSize={'xs'}
                                styleType={'outlined'}
                                style={{
                                    borderColor: "var(--disabled)",
                                    color: "var(--black)"
                                }}
                                onClick={() => AddFundsClick('ADD_FUNDS', 'isStockModalOpen')}
                                label={'Add Stocks'}
                            />
                        </div>
                        <div className='d-flex w-100' id={'myDiv'}
                             onScroll={() => {
                                 handleScroll()
                             }}
                             style={{
                                 maxHeight: isMobileView ? getMobileHeight("", isXSView) : 'calc(100vh - 280px)',
                                 overflowY: 'auto',
                                 scrollBehavior: 'smooth'
                             }}>
                            <div style={{flex: 1}}>
                                {selectedWatchListObj.stockList.map((item, index) => {
                                    return (
                                        <div className={'py-1 customCheckBoxStyle'}
                                             style={{borderBottom: index !== selectedWatchListObj.stockList.length - 1 ? "0.5px solid var(--disabled)" : "none"}}>
                                            <DLCheckbox id={'scheduleUpload-weeklyUi-dayCheck-' + index}
                                                        label={getTrimmedValue(item?.company_name, 45)}
                                                        isChecked={checkboxChange(item, 'stocks')}
                                                        disableHelperMessageHeight={true}
                                                        labelColor={appTheme.appColor.black}
                                                        onChangeValue={() => handleCheckboxChange(item, 'stocks')}
                                                        labelPlacement={'end'}
                                                        marginBottom={'none'}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )
            }
        }
    };

    const renderSecondPanelView = () => {

        if (selectedWatchListObj.type === 'FUNDS') {
            if (selectedWatchListObj.fundCount === null || selectedWatchListObj.fundCount === 0) {
                return (
                    <div className={'panel p-3 '} style={{flex: 3}}/>
                )

            } else {
                return (<div style={{flex: 3}}>

                    {selectedFunds && selectedFunds.length > 0 ? (
                        <Funds selectedFundListData={selectedFunds} {...props}/>
                    ) : ''}
                    {/*{selectedStocks && selectedStocks.length > 0 && (*/}
                    {/*    <Stocks selectedStockList={selectedStocks} {...props}/>*/}
                    {/*)}*/}
                </div>)
            }
        }
        if (selectedWatchListObj.type === 'STOCKS') {
            if (selectedWatchListObj.stockCount === null || selectedWatchListObj.stockCount === 0) {
                return (
                    <div className={'panel p-3 '} style={{flex: 3}}/>
                )
            } else {
                return (<div style={{flex: 3}}>
                    {/*{selectedFunds && selectedFunds.length > 0 ? (*/}
                    {/*    <Funds selectedFundList={selectedFunds} {...props}/>*/}
                    {/*) : ''}*/}
                    {selectedStocks && selectedStocks.length > 0 && (
                        <Stocks selectedStockListData={selectedStocks} {...props}/>
                    )}
                </div>)

            }
        }
    };
    const renderSecondPanelMobileView = () => {
        if (selectedWatchListObj.type === 'FUNDS') {
                if (selectedFunds && selectedFunds.length > 0) {
                    return (
                        <div>
                            <Funds selectedFundListData={selectedFunds} {...props}/>
                        </div>
                    )
                }
        }
        if (selectedWatchListObj.type === 'STOCKS') {
                if (selectedStocks && selectedStocks.length > 0) {
                    return (
                        <div>
                            <Stocks selectedStockListData={selectedStocks} {...props}/>
                        </div>
                    )
                }
        }
    };

    const renderUserWatchList = () => {
        return (
            <div style={{height: 'calc(85vh)'}}>
                <div className={'pageContainerWrapper border-bottom'}>
                    <div className={'d-flex justify-content-between'}>
                        <div className={'d-flex justify-content-start gap-2'}>
                            <Dropdown style={{height: '36px', width: '600px'}}>
                                <Dropdown.Toggle
                                    className="d-flex dropdown-toggle align-items-center justify-content-between"
                                    id="dropdown-basic"
                                    style={{
                                        width: "100%",
                                        backgroundColor: 'white',
                                        borderRadius: 7,
                                        margin: 0,
                                        minHeight: '36px',
                                        height: '36px',
                                        paddingLeft: 0,
                                        border: "1px solid #282828"
                                    }}>
                                    <div className={"d-flex justify-content-end"} style={{paddingLeft: 13}}>
                                        <DLText id={''}
                                                text={<div>
                                                    {selectedWatchListObj.name && getTrimmedValue(selectedWatchListObj.name, 60)}
                                                    <span className={""} style={{
                                                        fontSize: "10px",
                                                        marginLeft: '3px',
                                                        color: "#666666"
                                                    }}>
                                                             {selectedWatchListObj.type && selectedWatchListObj.type.charAt(0).toUpperCase() + selectedWatchListObj.type.slice(1).toLowerCase()}  </span>
                                                </div>}
                                                marginBottom={'none'}
                                                fontSize={'sm'}
                                                fontWeight={'semi-bold'}/>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={'w-100'}
                                               style={{maxHeight: 180, overflowY: 'auto', marginTop: '-3px'}}>
                                    {renderDropDownUI()}
                                </Dropdown.Menu>
                                {roleError !== '' &&
                                <div className='ps-3'>
                                    <DLText id={''}
                                            text={'Error'} fontColor={'danger'}
                                            fontSize={'md'}/>
                                </div>
                                }
                            </Dropdown>
                            <DLButton
                                id={'download-excel'}
                                styleType={"outlined"}
                                borderType={'square'}
                                sideMargin={"none"}
                                padding={'none'}
                                reduceWidth={true}
                                type={'primary'}
                                style={{
                                    backgroundColor: 'var(--white)',
                                    color: 'var(--black)',
                                    border: '1px solid #282828',
                                    minWidth: "40px"
                                }}
                                onClick={() => {

                                    handleCreateOpenModal('isActionModalOpen', true)

                                }}
                                label={<i className="fa-solid fa-plus"/>}>
                            </DLButton>
                        </div>
                        <div style={{
                            border: "1px solid #282828",
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            width: "40px",
                            justifyContent: "center"
                        }}
                        className={'customMenuoptionsStyle'}>
                            <DLMenuOptions id={'campaignList-menuOption'}
                                           options={[
                                               {
                                                   label: 'Copy',
                                                   value: 'Copy'
                                               }, {
                                                   label: 'Delete',
                                                   value: 'Delete'
                                               }]}
                                           ellipsisType={'V'}
                                           onSelect={
                                               (e) => {
                                               handleMenuOptionsSelect(e)
                                           }}
                            />

                        </div>

                    </div>

                    {renderToOtherPages()}
                </div>
                <div className={'d-flex flex-grow-1 pageContainerWrapper'} style={{gap: 20, height: 'calc(78vh)'}}>
                    <div className={'panel p-3 '} style={{flex: 1}}>
                        {renderFirstPanelView()}
                    </div>
                    {renderSecondPanelView()}
                </div>
            </div>
        )
    };

    const renderUserWatchListMobile = () => {
        return (
            <div style={{height: 'calc(80vh)'}}>
                <div className={'pageContainer border-bottom mb-2 mt-2'}>
                    <div className={'d-flex justify-content-between mb-2'}>
                        <Dropdown style={{height: '36px', width: '270px'}}>
                            <Dropdown.Toggle
                                className="d-flex dropdown-toggle align-items-center justify-content-between"
                                id="dropdown-basic"
                                style={{
                                    width: "100%",
                                    backgroundColor: 'white',
                                    borderRadius: 7,
                                    margin: 0,
                                    minHeight: ' 36px',
                                    height: '36px',
                                    paddingLeft: 0,
                                    border: roleError !== '' ? "1px solid " + appTheme.appColor.danger : "1px solid  #B2B2B2"
                                }}>
                                <div className={"d-flex justify-content-end"} style={{paddingLeft: 13}}>
                                    <DLText id={''}
                                            text={<div>
                                                {selectedWatchListObj.name && getTrimmedValue(selectedWatchListObj.name, 19)}
                                                <span className={""} style={{fontSize: "10px", marginLeft: '3px'}}>
                                                             {selectedWatchListObj.type && selectedWatchListObj.type.charAt(0).toUpperCase() + selectedWatchListObj.type.slice(1).toLowerCase()}  </span>
                                            </div>}
                                            marginBottom={'none'}
                                            fontSize={'sm'}
                                        // customTextStyle={{
                                        //     color: 'rgb(87, 87, 87,0.5)'
                                        // }}
                                            fontWeight={'semi-bold'}/>
                                </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className={'w-100'}
                                           style={{maxHeight: 180, overflowY: 'auto', marginTop: '-3px'}}>
                                {renderDropDownUI()}
                            </Dropdown.Menu>
                            {roleError !== '' &&
                            <div className='ps-3'>
                                <DLText id={''}
                                        text={'Error'} fontColor={'danger'}
                                        fontSize={'md'}/>
                            </div>
                            }
                        </Dropdown>

                        <div style={{
                            border: "1px solid #282828",
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            width: "40px",
                            justifyContent: "center"
                        }}>
                            <DLMenuOptions id={'campaignList-menuOption'}

                                           options={renderMenuOptionsForMobileview()}
                                           ellipsisType={'V'}
                                           onSelect={(e) => {
                                               handleMenuOptionsSelect(e)
                                           }}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    {renderSecondPanelMobileView()}
                    <div style={{
                        position: "fixed",
                        width: "100%",
                        bottom: "0px",
                        marginBottom: "80px",
                        zIndex:999
                    }}> {renderuiAccordian()} </div>
                </div>
            </div>
        )
    };

    const renderCreateWatchListView = () => {
        return (
            <div className={'pageContainer mt-3'}>
                <DLText
                    id={'revenue'}
                    type={'normal'}
                    isClickable={true}
                    text={'Watchlist'}
                    customTextStyle={{
                        color: appTheme.appColor.black
                    }}
                    fontSize={'lg'}
                    fontWeight={'semi-bold'}/>
                <div className="w-100 pageContainer d-flex align-items-center "
                     style={{height: 'calc(100vh - 240px)'}}>
                    <div className={'d-flex w-100 justify-content-center flex-column'}>
                        <div className={'d-flex justify-content-center mb-4'}>
                            <img id={'PurchaseSuccessful-icon'}
                                 src={Create_watchlist}
                                 alt={'icon'}
                                 className='img-fluid '
                                 style={{maxHeight: '160px'}}/>
                        </div>

                        <div className={'d-flex justify-content-center mb-4 pb-2'}>
                            <DLText
                                id={'revenue'}
                                type={'normal'}
                                isClickable={true}
                                customTextStyle={{
                                    color: appTheme.appColor.black,
                                }}
                                customWrapperStyle={{
                                    textAlign:"center"
                                }}
                                text={'Please create your first watchlist for funds or stocks'}
                                isInline={true}
                                fontSize={'md'}/>
                        </div>

                        <div className={'d-flex justify-content-center'}>
                            <DLButton
                                id={''}
                                fontSize={'md'}
                                buttonSize={'sm'}
                                style={{backgroundColor: 'var(--black)'}}
                                onClick={() => {
                                    handleCreateOpenModal('isActionModalOpen', true)
                                }}
                                label={"Create a Watchlist"}
                            ><AddIcon/></DLButton>
                        </div>
                    </div>
                </div>
                <CreateWatchListModal  {...props}/>
            </div>)
    };

    const renderDesktopView = () => {
        if (UIState === dataConst.CONTENT_AVAILABLE) {
            return renderUserWatchList()
        } else {
            return renderCreateWatchListView()
        }
    };

    const renderMobileView = () => {
        if (UIState === dataConst.CONTENT_AVAILABLE) {
            return renderUserWatchListMobile()
        } else {
            return renderCreateWatchListView()
        }
    };
    const renderUI = () => {
        if (isSMView || isXSView || isMDView) {
            return renderMobileView()
        } else {
            return renderDesktopView()
        }
    };

    return (
        <div>
            <div>  {renderUI()} </div>

            <div>
                <FundListingModal {...props}/>
                <StockSearchModal {...props}/>
                <ConfirmationModal {...props}/>
                <CreateWatchListModal  {...props}/>
            </div>
        </div>
    );
}

export default UserWatchListView;