import React, {useEffect, useState} from 'react';
import AdvanceSearchMarketCapView from "./AdvanceSearchMarketCapView";
import {setFundsKeywordFilter, setMarketCapList} from "../AdvanceSeachAction";
import {isEmpty} from "../../utils/validations";
import {advanceSearchConst} from "../AdvanceSearchConst";
const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function AdvanceSearchMarketCap(props) {
    const [advanceMarkeCapState, setAdvanceMarkeCapState] = useState({
        modalUIState: '',
        sortedData: [],
        previousMarketCapData: []
    });

    useEffect(()=>{
        if (props.isMarketCapModalOpen){
            if (!isEmpty(props.marketCapData)){
                setAdvanceMarkeCapState((prev)=>({
                    ...prev,
                    modalUIState: advanceSearchConst.CONTENT_AVAILABLE,
                    previousMarketCapData: props.marketCapData
                }));
            }else {
                let data = [
                    {
                    name: 'Large Cap',
                    value: 'Large Cap',
                    id: 1,
                    isSelected: false
                    },
                    {
                        name: 'Mid Cap',
                        value: 'Mid Cap',
                        id: 2,
                        isSelected: false
                    },
                    {
                        name: 'Small Cap',
                        value: 'Small Cap',
                        id: 3,
                        isSelected: false
                    },
                    {
                        name: 'International',
                        value: 'International',
                        id: 4,
                        isSelected: false
                    },
                    {
                        name: 'Other',
                        value: 'Others',
                        id: 5,
                        isSelected: false
                    },
                ];
                setMarketCapList(data);
                // props.onChangeValue('marketCapData', data);

                setAdvanceMarkeCapState((prev) => ({
                    ...prev,
                    modalUIState: advanceSearchConst.CONTENT_AVAILABLE
                }));
            }

        }
    },[props.isMarketCapModalOpen]);

    const handleCheckboxChange = (value) => {
        const {marketCapData} = props;

        const updatedArray = marketCapData.map(item => {
            if (item.id === value.id) {
                return { ...item, isSelected: !item.isSelected };
            }

            // Return the original object if the id doesn't match
            return item;
        });

        setMarketCapList(updatedArray);
        // props.onChangeValue('marketCapData', updatedArray);

    };

    const clearSelectedFunds = (value) => {
        const {marketCapData} = props;

        const updatedArray = marketCapData.map(item => {
            return { ...item, isSelected: false };
        });
        setMarketCapList(updatedArray);

        // props.onChangeValue('marketCapData', updatedArray);

    };

    const checkboxChange = (value) => {
        const {marketCapData} = props;
        // console.log('updatedArray[index]', !value)

        // Make a copy of the object at the specified index
        const updatedItem = marketCapData.find(item =>  (item.id === value.id));

        return updatedItem?.isSelected
    };

    const onChangeKeywordFilter = (fundsKeyword) => {
        setFundsKeywordFilter(fundsKeyword)
    };

    const checkSelectedValues = () => {
        const {marketCapData} = props;
        return  marketCapData.filter((item)=>item.isSelected);
    };

    return (
        <AdvanceSearchMarketCapView {...props}
                                    {...advanceMarkeCapState}
                                    onChangeKeywordFilter={onChangeKeywordFilter}
                                    checkboxChange={checkboxChange}
                                    clearSelectedFunds={clearSelectedFunds}
                                    handleCheckboxChange={handleCheckboxChange}
                                    checkSelectedValues={checkSelectedValues}/>
    );
}

AdvanceSearchMarketCap.propTypes = {

};


export default AdvanceSearchMarketCap;