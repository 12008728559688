import DLButton from "dlcomponent/components/DLButton/DLButton";
import React from "react";

export const isEmpty = value =>
  value === undefined ||
  value === 'undefined' ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);


export const DownloadButtonCSS={border: "0.5px solid var(--black)", color: "var(--black)"};