import React, {useEffect, useState} from 'react';
import {debounce} from "../../utils/helper";
import {getExplorerDrillDown} from "../../explorer/ExplorerAction";
import {isEmpty} from "../../utils/validations";
import {advanceSearchConst} from "../../AdvanceSearch/AdvanceSearchConst";
import MoneyFlowExplorerActionModalView from "./MoneyFlowExplorerActionModalView";
import appTheme from "../../assets/appTheme";
const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function MoneyFlowExplorerActionModal(props) {
    const [modalActionState,setModalActionState] = useState({
        netBuyingSelingResult: [],
        isFilterAPIInProgress: false,
        resultCount: 0,
        UIState:'',
        tableHeader:{},
        selectedLineObj:{}
    });

    useEffect(()=>{
        let {fundHouses, businessHouses, funds, marketCaps, industries, stocks, fundHouseForFund} = props.explorerFilter;
        const {drillDownBy, isActionModalOpen, searchResult, selectedLine} = props;
        let selectedLineObj={};

        if (isActionModalOpen) {
            setModalActionState((prev) => ({
                ...prev,
                isFilterAPIInProgress: true,
            }));
            switch (drillDownBy) {
                case 'fundHouse':
                    selectedLineObj = searchResult.find(item=> item.fund_house_name === selectedLine);
                    fundHouses = [selectedLineObj.fund_house_id];
                    break;
                case 'fund':
                    selectedLineObj =   searchResult.find(item=> item.fund_name === selectedLine);
                    fundHouseForFund = [selectedLineObj.fund_house_id];
                    funds = [selectedLineObj.fund_id];
                    break;
                case 'industry':
                    selectedLineObj =   searchResult.find(item=> item.industry === selectedLine);
                    industries = [selectedLineObj.industry];
                    break;
                case 'stock':
                    selectedLineObj =   searchResult.find(item=> item.company_name === selectedLine);
                    stocks = [selectedLineObj.isin];
                    break;
                case 'businessHouse':
                    selectedLineObj =   searchResult.find(item=> item.business_house === selectedLine);
                    businessHouses = [selectedLineObj.business_house];
                    break;
                case 'marketCap':
                    selectedLineObj =   searchResult.find(item=> item.category === selectedLine);
                    marketCaps = [selectedLineObj.category];
                    break;
                default:
            }

            let data = {
                viewBy: drillDownBy,
                businessHouse: businessHouses,
                fundHouse: fundHouses,
                fund: funds,
                marketCap: marketCaps,
                industry: industries,
                stock: stocks,
                fundHouseForFund: fundHouseForFund
            };
            getResult(data, selectedLineObj);
        }
    },[props.isActionModalOpen]);

    const checkLengthOfResult = (searchResult) =>{

        const valuesFromObject = Object.values(searchResult) ;
        return valuesFromObject[valuesFromObject.length - 1]?.length
    };

    const getFormatedValue =(data) =>{
        const {fromDate, toDate} = props;
        if (isEmpty(data)){
            return []
        }

        // Convert the object keys to an array
        const keysArray = Object.keys(data);
        // Find the index of the variable in the keys array
        const variableStartIndex = keysArray.indexOf(fromDate);
        const variableLastIndex = keysArray.indexOf(toDate);

        // Convert the object to an array of key-value pairs
        const arrayOfEntries = Object.entries(data);
        const modifiedArray = arrayOfEntries.slice(variableStartIndex, variableLastIndex + 1);

        // Convert the modified array back to an object
        return Object.fromEntries(modifiedArray);
    };

    const getResult =debounce( (data, selectedLineObj) =>{

        getExplorerDrillDown(data, VALUATION_DATE).then((res)=>{
            if (res.success){
                if (!isEmpty(res.data?.data)){
                    setModalActionState((prev)=>({
                        ...prev,
                        netBuyingSelingResult: getFormatedValue(res.data?.data),
                        isFilterAPIInProgress: false,
                        resultCount: checkLengthOfResult(getFormatedValue(res.data?.data)),
                        selectedLineObj:selectedLineObj
                    }));
                    // getData(res.data?.data, drillDownBy);
                }else {
                    setModalActionState((prev)=>({
                        ...prev,
                        netBuyingSelingResult: [],
                        tableHeader: [],
                        isFilterAPIInProgress: false,
                        selectedLineObj:selectedLineObj
                    }));
                }
            }

            setModalActionState((prev)=>({
                ...prev,
                UIState: advanceSearchConst.CONTENT_AVAILABLE,
                isFilterAPIInProgress: false
            }));
        })
    },300);

    const getDataOnly = (dataObj, valueAttribute) =>{
        if (isEmpty(dataObj) || isEmpty(valueAttribute)){
            return []
        }

        return  dataObj.map((item)=>isEmpty(item) ? 0 : item[valueAttribute].toFixed(2))
    };

    const getIdLabelForDrillDown = (viewBy) =>{
        let name;
        switch (viewBy) {
            case 'fundHouse':
                name = 'fund_house_name';
                break;
            case 'fund':
                name = 'fund_name';
                break;
            case 'industry':
                name = 'industry';
                break;
            case 'stock':
                name = 'company_name';
                break;
            case 'businessHouse':
                name = 'business_house';
                break;
            case 'marketCap':
                name = 'category';
                break;
            default:
        }
        return name
    };

    const arrangeOthersMonthData = (monthsArray, lastMonthArray, viewBy) =>{
        viewBy = getIdLabelForDrillDown(viewBy);
        for (let i=0 ;i < monthsArray.length - 1; i++){
            monthsArray[i].sort((a, b) => {
                let idA = a[viewBy];
                let idB = b[viewBy];
                // Find the index of the id in array 'c'
                let indexA = lastMonthArray.findIndex(item => item[viewBy] === idA);
                let indexB = lastMonthArray.findIndex(item => item[viewBy] === idB);

                return indexA - indexB;
            });
        }
        return monthsArray

    };

    const sortByAum = (monthsArray, drillDownBy) =>{
        //sort last month array by AUM
        let lastMonthArray = monthsArray[monthsArray.length - 1];
        lastMonthArray.sort((a, b) => b.total_market_value_change_due_to_activity - a.total_market_value_change_due_to_activity);

        //arrange other months data as per last month
        return arrangeOthersMonthData(monthsArray, lastMonthArray, drillDownBy)
    };

    const createLineChartDataset = (data) =>{
        const {drillDownBy} = props;

        if (isEmpty(data)){
            return []
        }

        Object.keys(data);
        let values = Object.values(data);
        values.reduce((maxIndex, currentArray, currentIndex, array) => {
            if (currentArray.length > array[maxIndex].length) {
                return currentIndex;
            } else {
                return maxIndex;
            }
        }, 0);
        let array = [];
        values = sortByAum(values, drillDownBy);

        let maxIteration = values[0].length >= 5 ?  5 : values[0].length;
        //Todo - remove bottom part once fixe in backend
        maxIteration = Object.values(data)[0].length < maxIteration ? Object.values(data)[0].length : maxIteration;

        for (let i=0 ;i < maxIteration; i++){
            let filteredValues = values.map(item =>  item[i]);
            array.push(filteredValues)
        }

        return array

    };

    const getBaseLog = (x, y) => {
        return Math.log(y) / Math.log(x);
    };
    const getRoundedNumber = (value) =>{
        if (isEmpty(value)) {
            return
        }
        let log = getBaseLog(10, value);
        let stepSize;
        log = Math.floor(log);

        if (log === 5) {
            return Math.ceil(value / 100000) * 100000
        } else if (log === 4) {
            return  Math.ceil(value / 10000) * 10000
        } else if (log === 3) {
            return Math.ceil(value / 1000) * 1000
        } else if (log === 2) {
            return Math.ceil(value / 100) * 100
        }  else if (log === 1) {
            return  Math.ceil(value / 10) * 10
        }
        return value;
    };

    const getNameOnly = (dataObj) =>{
        const {drillDownBy} = props;

        if (isEmpty(dataObj)){
            return
        }
        let name;

        switch (drillDownBy) {
            case '':
            case 'fundHouse':
                name = dataObj.find((item)=> item?.fund_house_name);
                return name?.fund_house_name;
            case 'fund':
                name = dataObj.find((item)=> item?.fund_name );
                return name?.fund_name;
            case 'industry':
                name = dataObj.find((item)=> item?.industry);
                return name?.industry;
            case 'stock':
                name = dataObj.find((item)=> item?.company_name);
                return name?.company_name;
            case 'businessHouse':
                name = dataObj.find((item)=> item?.business_house);
                return name?.business_house;
            case 'marketCap':
                name = dataObj.find((item)=> item?.category);
                return name?.category
        }

        return dataObj.find((item)=> item?.fund_name )
    };
    const getBarChartValues = (values, formatedData) =>{

        return formatedData.map((_, i) => ({
            name: getNameOnly(formatedData[i]),
            data: getDataOnly(values.map(innerArray => innerArray[i]), 'total_market_value_change_due_to_activity'),
            emphasis: {
                focus: 'series'
            },
            itemStyle: {
                color: (params) => (params.value >= 0 ? appTheme.appColor.success : appTheme.appColor.danger),
            },
            barGap: 0.2,
            barWidth: '5%',
            type: 'bar',
        }));

    };

    return (
        <MoneyFlowExplorerActionModalView {...props}
                                          {...modalActionState}
                                          getRoundedNumber={getRoundedNumber}
                                          getDataOnly={getDataOnly}
                                          getBarChartValues={getBarChartValues}
                                          createLineChartDataset={createLineChartDataset}/>
    );
}

MoneyFlowExplorerActionModal.propTypes = {

};
export default MoneyFlowExplorerActionModal;