import React from 'react'
import {withRouter} from "react-router-dom";
import {Bar} from "react-chartjs-2";
import {isEmpty} from "../../utils/validations";
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import appTheme from "../../assets/appTheme";
import {getFixedValuesAfterDecimal, getNextRoundedNumber, getPreviousRoundedNumber} from "../../utils/helper";
import LineChart from "dlcomponent/components/D3Charts/LineChart";
import DLText from "dlcomponent/components/DLText/DLText";
import {
    filterAumValue,
    getMaxRoundedNumber,
    getMinRoundedNumber,
    getRoundedStepSize,
    getRoundedStepSizeForLineChart
} from "../../utils/stocksHelper";

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);

function LineChartMixed(props) {
    const {yAxisBar, yAxisLine, xAxis, barColor, isFromFund, isMobileView, hideCSS, isLGView, isXLView, isBarChartClickable,getBarClick} = props;
    const includesNegative = yAxisBar.some(number => number < 0);


    const removeDecimalPoints = (inputArray) =>{
        // Convert each string to a number and round it to remove decimal points
        const resultArray = inputArray.map(str =>  filterAumValue(str) );
        return resultArray;
    };


    let maxValueBar = Math.max(...yAxisBar) * 2;
    let maxValueLine = Math.max(...yAxisLine);
    let maxChartValueBar = getNextRoundedNumber(maxValueBar);
    let maxChartValueLine = getNextRoundedNumber(maxValueLine);

    const getMinValue = () => {
        const {xAxis} = props;
        const minValue = Math.min(...yAxisLine);
        return minValue * 0.90
    };


    const getMaxValue = () => {
        const {xAxis} = props;
        const maxValue = Math.max(...yAxisLine);
        return maxValue * 1.1
    };

    const renderLineChartUI = () => {
        const {xAxis, yAxisLine, yAxisBar} = props;

        let minValue = getMinValue();

        if (!isFinite(minValue)) {
            return "";
        }
        minValue = Math.trunc(minValue);//floor
        minValue = getMinRoundedNumber(minValue);
        let maxNumber = getMaxValue();
        maxNumber = getMaxRoundedNumber(Math.trunc(maxNumber));//ceil
        if (!isFinite(maxNumber)) {
            return "";
        }

        maxNumber = maxNumber <= 5 ? maxNumber + 2 : maxNumber;

        let stepSize = (maxNumber - minValue) / 3;
        stepSize = getRoundedStepSizeForLineChart(stepSize);

        let maxValue = minValue +  (stepSize * 3);

        return (
            <div className=''>
                <LineChart className={""}
                           id={"aum-line-chart"}
                           externalOptions={{
                               indexAxis: 'x',
                               responsive: true,
                               maintainAspectRatio: false,
                               plugins:
                                   {
                                       legend: {
                                           position: '',
                                           display: false
                                       },
                                       title: {
                                           display: false,

                                       },
                                       tooltip: {
                                           backgroundColor: appTheme.appColor.white,
                                           bodyColor: appTheme.appColor.black,
                                           borderColor: '#D2D2D2',
                                           borderWidth: 1,
                                           padding: 20,
                                           titleColor: 'black',
                                           titleFont: 'bold',
                                           titleFontSize: 30,
                                           boxPadding: 5
                                       }
                                   },
                               onClick: '',
                               scales: {
                                   x: {
                                       stacked: false,
                                       grid: {
                                           display: false
                                       },
                                   },
                                   y: {
                                       position: "right",
                                       min: minValue,
                                       max: maxValue,
                                       ticks: {
                                           precision: 0,
                                           stepSize: stepSize// stepSize :  max + 10 /10 and round value
                                       },
                                       title: {
                                           display: false,
                                           text: ''
                                       },
                                   },
                               }
                           }}
                           data={[
                               {
                                   label: 'AUM',
                                   data: removeDecimalPoints(yAxisLine),
                                   backgroundColor: ["#0073ff"],
                                   lineTension: 0.3,
                                   fill: false,
                                   pointRadius: 6
                               }
                           ]}
                           height={'180px'}
                           direction={'x'}
                           isLegendsVisible={false}
                           xLabels={xAxis}
                />
            </div>
        )
    };


    const getWidth = () =>{
        if (hideCSS){
            if (isLGView){
                return '50%'
            }
            return '100%'
        }
        return '100%'
    };

    return (
        <div className={hideCSS ? isLGView ?  'd-flex ' : '' : 'd-flex flex-column'}>

            <div style={{minHeight : "190px"}}>

                <div className={isEmpty(yAxisLine) ? "flex-grow-1 h-100 d-flex justify-content-center align-items-center" : "flex-grow-1"}>
                    {
                        isEmpty(yAxisLine)?
                            <div className="h-100">
                                    No data found
                            </div>
                            :
                            renderLineChartUI()
                    }
                </div>

            </div>
        </div>
    )

}

export default withRouter(LineChartMixed);

