import React, {useEffect, useState} from 'react';
import withRouter from "react-router-dom/es/withRouter";
import {setUserAuthStatus} from "../../../user/userReducer";
import {searchAllData} from "../../actions/appActions";
import {connect} from "react-redux";
import ResAppBarView from "./ResAppBarView";
import {isEmpty} from "../../../utils/validations";
import {debounce} from "../../../utils/helper";
import {clearUserDataOnLogout} from "../../../user/userActions";

function ResAppBar(props) {
    const [resAppBarState, setResAppBarState] = useState({
        searchOptions: [],
        searchOptionsFound: false,
        isSearchInProgress: false
    });

    const searchData = (keyword) =>{

        if (isEmpty(keyword)){
            setResAppBarState((prevState)=>({
                ...prevState,
                searchOptions: [],
                searchOptionsFound: false,
            }));
            return
        }

        setResAppBarState((prevState)=>({
            ...prevState,
            isSearchInProgress: true
        }));

        searchAllData(keyword).then((res)=>{
            if (res.success){
                setResAppBarState((prevState)=>({
                    ...prevState,
                    searchOptions: res.data.data,
                    searchOptionsFound: true,
                    isSearchInProgress:false
                }))
            }else {
                setResAppBarState((prevState)=>({
                    ...prevState,
                    searchOptions: [],
                    isSearchInProgress:false,
                    searchOptionsFound: true,

                }))
            }
        })
    };

    const onChangeValue = (value) =>{
        onChange(value);
    };

    const onChange = debounce((value) => {
        searchData(value);
    }, 500);

    const handleClick = (item) =>{
        switch (item.type) {
            case 'Fund':
                props.history.push('/funds/' + item.fund_house_id + '/' + item.fund_id);
                break;

            case 'Stock':
                props.history.push('/stocks/'+item.isin);
                break;

            case 'Industry':
                props.history.push('/sectors/'+item.industry);
                break;
            default:

        }
    };

    return (
        <ResAppBarView
            {...props}
            {...resAppBarState}
            isDeleteDialogOpen={false}
            searchData={searchData}
            handleClick={handleClick}
            onChangeValue={onChangeValue}
        />
    );
}

ResAppBar.propTypes = {

};

const mapStateToProps = (state) => ({
    userAuthStatus: state.userState.userAuthStatus,
    profile:state.userState.profile,
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isDrawerOpen: state.appState.isDrawerOpen,
    currentPathname: state.appState.currentPathname,
    prevLocation: state.appState.prevLocation
});


export default connect(mapStateToProps, {
    setUserAuthStatus, clearUserDataOnLogout
}) (withRouter(ResAppBar))
