import React from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {userConst} from "../../user/userConsts";
import DLPostLoginRedirect from "../components/DLPostLoginRedirect/DLPostLoginRedirect";
import PageNotAccessible from "../components/PageNotAccessible/PageNotAccessible";
import {isEmpty} from "../../utils/helperCommon";


const PostLoginRoute = ({component: Component, profile, userAuthStatus, prevLocation, ...rest}) => (

    <Route
        {...rest}
        render={props => {

            let authStatus = (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED);
            let uiContent;
            if (authStatus) {
                    uiContent = (<Component {...props} prevLocation={prevLocation}/>);
            } else {

                uiContent = (
                    <DLPostLoginRedirect afterLoginUrl={props.location?.pathname}
                                         afterLoginUrlProps={{search: props.location?.search, openAsPopUp: true}}
                                         openDialogOnMount={true}
                    >
                        <Redirect replace to={'/user/login'}/>
                    </DLPostLoginRedirect>
                );
            }
            return uiContent
        }
        }
    />
);

PostLoginRoute.propTypes = {
    userAuthStatus: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    userAuthStatus: state.userState.userAuthStatus,
    profile: state.userState.profile,
    prevLocation: state.appState.prevLocation
});

export default connect(mapStateToProps, {})(withRouter(PostLoginRoute));
