import {combineReducers} from 'redux';
import userReducer from "./user/userReducer"
import appReducer from "../src/core/reducers/appReducer"



export const appRootReducer = combineReducers({
  /* your app’s top-level reducers */
  appState: appReducer,
  userState: userReducer
});


export const rootReducer = (state, action) => {
  return appRootReducer(state, action)
};


