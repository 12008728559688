import React, {useEffect, useState} from 'react';
import DLModal from "dlcomponent/components/DLModal/DLModal";
import DLModalTitle from "dlcomponent/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "dlcomponent/components/DLModal/DLModalContainer/DLModalContainer";
import DLText from "dlcomponent/components/DLText/DLText";
import greenBull from "../assets/img/green bull.png";
import redBear from "../assets/img/red bear.png";
import {isEmpty} from "../utils/validations";
import appTheme from "../assets/appTheme";
import DLLoader from "../core/components/DLLoader/DLLoader";
import {filterNetBuyingSellingValue, numberWithCommas} from "../utils/helper";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function PopComponent(props) {

    const {
        isModalOpen, handleCloseModal, buyingFunds, sellingFunds, netBuying, netSelling,netBuyingSellingDifference,
        requiredMonth, isModalApiInProgress, isXSView, isSMView, selectedData
    } = props;

    const handleSinglularPluralText = (singular, plural, value) => {
        if (value <= 1) {
            return singular;
        }
        return plural;

    }

    const checkIsFullScreenRequired =()=>{
        if(isSMView || isXSView){
            return true;
        }
        return false;
    }

    const getTitleText = () => {
        return 'Buying/Selling in ' + requiredMonth

    }

    const renderUI = () => {
        if(checkIsFullScreenRequired()){
            return (
                <div className="d-flex flex-column h-100" style={{zIndex:999}}>
                    <div className={'d-flex justify-content-end'}>
                        <DLText text={<u>{'Money Flow Explorer'}</u>}
                                fontSize={'md'}
                                isClickable={true}
                                onClick={()=>{props.history.push("/money-flow-explorer", {selectedSector: selectedData, isFrom:'industry', selectedDate:requiredMonth})}}
                        />
                    </div>
                    <div className='my-2  flex-grow-1 d-flex justify-content-center align-items-center h-100'>
                        <div className="d-flex justify-content-evenly align-items-center">
                            <div className="h-100 d-flex flex-column">
                                <div>
                                    <img src={greenBull}
                                         height={isXSView ? 100 : 160}
                                         width={isXSView ? 100 : 160}
                                         style={{paddingBottom: 12}}
                                    />
                                    <DLText id={"netbuying"}
                                            fontSize={"md"}
                                            customWrapperStyle={{
                                                textAlign: 'center'
                                            }}
                                            customTextStyle={{
                                                color: appTheme.appColor.success
                                            }}
                                            fontWeight={"semi-bold"}
                                            text={numberWithCommas(filterNetBuyingSellingValue(netBuying))}
                                    />
                                    <DLText id={"buyingStocks"}
                                            fontSize={"md"}
                                            customWrapperStyle={{
                                                textAlign: 'center'
                                            }}
                                            customTextStyle={{
                                                color: appTheme.appColor.success
                                            }}
                                            fontWeight={"semi-bold"}
                                            text={numberWithCommas(buyingFunds) + " " + (handleSinglularPluralText("Fund", "Funds", buyingFunds))}
                                    />
                                </div>
                                <div style={{height: 200}}></div>
                            </div>
                            <div className="h-100 d-flex flex-column align-items-center">
                            <span className={netBuyingSellingDifference > 0 ? "badge-success badge-sm" : "badge-danger badge-sm"}
                            >{numberWithCommas(filterNetBuyingSellingValue(netBuyingSellingDifference))}

                                </span>
                            </div>
                            <div className="h-100 d-flex flex-column">
                                <div style={{height: 200}}></div>
                                <div>
                                    <DLText id={"netSelling"}
                                            fontSize={"md"}
                                            customWrapperStyle={{
                                                textAlign: 'center'
                                            }}
                                            customTextStyle={{
                                                color: appTheme.appColor.danger
                                            }}
                                            fontWeight={"semi-bold"}
                                            text={numberWithCommas(filterNetBuyingSellingValue(netSelling))}
                                    />
                                    <DLText id={"sellingStocks"}
                                            fontSize={"md"}
                                            marginBottom={"none"}
                                            customWrapperStyle={{
                                                textAlign: 'center'
                                            }}
                                            customTextStyle={{
                                                color: appTheme.appColor.danger
                                            }}
                                            fontWeight={"semi-bold"}
                                            text={numberWithCommas(sellingFunds) + " " + (handleSinglularPluralText("Fund", "Funds", sellingFunds))}
                                    />
                                    <img src={redBear}
                                         height={isXSView ? 100 : 160}
                                         width={isXSView ? 100 : 160}
                                         style={{paddingBottom: 7, paddingTop:12}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div style={{zIndex:999}}>
                <div className='my-2'>
                    <div className={'d-flex justify-content-end'}>
                        <DLText text={<u>{'Money Flow Explorer'}</u>}
                                fontSize={'md'}
                                isClickable={true}
                                onClick={()=>{props.history.push("/money-flow-explorer", {selectedSector: selectedData, isFrom:'industry', selectedDate:requiredMonth})}}
                        />
                    </div>
                    <div className="d-flex justify-content-evenly align-items-center">
                        <div className="h-100 d-flex flex-column">
                            <div>
                                <img src={greenBull}
                                     height={isXSView ? 100 : 160}
                                     width={isXSView ? 100 : 160}
                                     style={{paddingBottom: 12}}
                                />
                                <DLText id={"netbuying"}
                                        fontSize={"md"}
                                        customWrapperStyle={{
                                            textAlign: 'center'
                                        }}
                                        customTextStyle={{
                                            color: appTheme.appColor.success
                                        }}
                                        fontWeight={"semi-bold"}
                                        text={numberWithCommas(filterNetBuyingSellingValue(netBuying))}
                                />
                                <DLText id={"buyingStocks"}
                                        fontSize={"md"}
                                        customWrapperStyle={{
                                            textAlign: 'center'
                                        }}
                                        customTextStyle={{
                                            color: appTheme.appColor.success
                                        }}
                                        fontWeight={"semi-bold"}
                                        text={numberWithCommas(buyingFunds) + " " + (handleSinglularPluralText("Fund", "Funds", buyingFunds))}
                                />
                            </div>
                            <div style={{height: 200}}></div>
                        </div>
                        <div className="h-100 d-flex flex-column align-items-center">
                            <span className={netBuyingSellingDifference > 0 ? "badge-success badge-sm" : "badge-danger badge-sm"}
                            >{numberWithCommas(filterNetBuyingSellingValue(netBuyingSellingDifference))}

                                </span>
                        </div>
                        <div className="h-100 d-flex flex-column">
                            <div style={{height: 200}}></div>
                            <div>
                                <DLText id={"netSelling"}
                                        fontSize={"md"}
                                        customWrapperStyle={{
                                            textAlign: 'center'
                                        }}
                                        customTextStyle={{
                                            color: appTheme.appColor.danger
                                        }}
                                        fontWeight={"semi-bold"}
                                        text={numberWithCommas(filterNetBuyingSellingValue(netSelling))}
                                />
                                <DLText id={"sellingStocks"}
                                        fontSize={"md"}
                                        marginBottom={"none"}
                                        customWrapperStyle={{
                                            textAlign: 'center'
                                        }}
                                        customTextStyle={{
                                            color: appTheme.appColor.danger
                                        }}
                                        fontWeight={"semi-bold"}
                                        text={numberWithCommas(sellingFunds) + " " + (handleSinglularPluralText("Fund", "Funds", sellingFunds))}
                                />
                                <img src={redBear}
                                     height={isXSView ? 100 : 160}
                                     width={isXSView ? 100 : 160}
                                     style={{paddingBottom: 7, paddingTop:12}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const onCloseModal = () => {
        handleCloseModal()
    }

    const renderPlainModal = () => {
        return (
            <DLModal id={'clear-canvas'}
                     history={props.history}
                     maxWidth={"sm"}
                     onClose={() => {
                         onCloseModal()
                     }}
                     open={isModalOpen}
                     isFullScreen={checkIsFullScreenRequired()? true : false}
            >

                <span className={'fa-solid fa-xmark ms-2 cursor '}/>
                <DLModalTitle id={'campaignDetail-sendTestModalTitle'}
                              onClose={() => {
                                  onCloseModal()
                              }}
                              title={getTitleText()}
                              fontColor={"#333333"}
                />
                <DLModalContainer id={'clear-canvas-container'}>
                    <div className="h-100" style={{minHeight: "400px"}}>
                        {isModalApiInProgress ?
                            <div className={'row px-2 h-100'} style={{height: "200px"}}>
                                <div
                                    className={"col-12 d-flex flex-column justify-content-center align-items-center mb-1"}>
                                    <DLLoader type={'inline'} isVisible={true}/>
                                </div>
                            </div>
                            :
                            renderUI()
                        }
                    </div>
                </DLModalContainer>

            </DLModal>
        )
    }


    return (
        renderPlainModal()
    )
}

export default PopComponent;