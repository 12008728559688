import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {isEmpty} from "../../../utils/validations";
import {
    getFixedValuesAfterDecimal,
    numberWithCommas,
    searchData
} from "../../../utils/helper";
import {stocksConst} from "../../../stocks/stocksConst";
import DLText from "dlcomponent/components/DLText/DLText";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
// TODO use lazy load for each component which is firing the api
function MarketCapView(props) {

    const {selectedStock, UIState, sceenerCode, handleOpenModal,
        holdings, isSMView, marketValue, requiredIsin, stockName} = props;

    const getHoldingPercentage =()=>{
        if(isEmpty(holdings) || isEmpty(marketValue)){
            return;
        }
        let percentage = (marketValue/holdings)*100;
        if(percentage < 10){
            return getFixedValuesAfterDecimal(percentage,2);
        }
        return getFixedValuesAfterDecimal(percentage,0);
    }

    const getProgressBarBackgroundColor = (percentage) => {
        let color = '';
        if(percentage <= 5)
        {
            color = "#7D81FF"
        }else if(percentage <= 10){

            color = "#5257FF"
        }else if(percentage <= 20){

            color = "#0007FF"
        } else if(percentage <= 50){

            color = "#1A1FB3"
        } else if(percentage <= 100){

            color = "#1A1FB3"
        }else{

            color = ""
        }
        return color;
    }

    const renderMobileView = () =>{
        const {marketValue} = props;

        return (
            <div className=''>
                <div className="d-flex p-2" style={{minHeight:"40px", backgroundColor: ""}}>
                    <div className='' style={{flex:3}}>
                        <DLText marginBottom={'none'}
                                isInline={true}
                                text={!isEmpty(stockName)? stockName : ""}
                                onClick={() => {
                                    props.history.push("/stocks/" + requiredIsin)
                                }}
                                fontWeight={'semi-bold'}
                                isClickable={true}
                                fontColor={"#0073FF"}
                                fontSize={'xs'}/>
                    </div>
                    <div className='w-100' style={{flex:2}}>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={
                                (holdings === "NaN" || isEmpty(marketValue))?
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={""}
                                        marginBottom={"none"}
                                        fontWeight={"semi-bold"}
                                        fontSize={'sm'}/>
                                    :
                                    <div className="d-flex justify-content-start align-items-center">
                                        <DLText
                                            id={'revenue-value'}
                                            type={'normal'}
                                            text={getHoldingPercentage()+"%"}
                                            marginBottom={"none"}
                                            fontWeight={"semi-bold"}
                                            fontSize={'sm'}/>
                                        <div className={"mx-1"}>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={"of"}
                                                fontWeight={"normal"}
                                                marginBottom={"none"}
                                                fontSize={'sm'}/>
                                        </div>
                                        <DLText
                                            id={'revenue-value'}
                                            type={'normal'}
                                            text={" "+numberWithCommas(holdings)}
                                            fontWeight={"semi-bold"}
                                            marginBottom={"none"}
                                            fontSize={'sm'}/>
                                    </div>
                            }
                            fontSize={'sm'}/>

                            <div className="w-75">
                                <div style={{height: "8px", backgroundColor: "#C5C5C5", borderRadius: "5px"}}>
                                    <div style={{
                                        height: "8px",
                                        width: getHoldingPercentage() + "%",
                                        borderRadius: "5px",
                                        backgroundColor: getProgressBarBackgroundColor(getHoldingPercentage())
                                    }}>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        )
    };

    const getBarClick =(e)=>{
        const {xAxis,yAxisBar} = props;
        if(isEmpty(e)){
            return;
        }
        let graphMonth = xAxis.find((item, index)=>{
            return index === e;
        })
        handleOpenModal(graphMonth)
        // console.log("in bar click",e,xAxis, graphMonth,props)

    }

    const renderUI = () => {
            return renderMobileView();
    };

    if (UIState === stocksConst.CONTENT_UNAVAILABLE) {
        return
    }
    // if (isEmpty(selectedStock?.company_name)) {
    //     return <DLLoader type={'screen'} isVisible={true}/>
    // }

    const activeStyle = {
        fontWeight: 500,
        color: '#282828',
        textDecoration: 'underline',
        fontFamily: 'Poppins, sans-serif',

    };
    props.handleChange();

    return (
        <>
            <div className="bg-white ">
                {/*<div className="text-end">    <i className="fa fa-remove m-2 cursor" onClick={() => {*/}
                {/*    clearOption(buttonIdReference)*/}
                {/*}} style={{color: appTheme.appColor.black}}/>*/}
                {/*</div>*/}

                <div id={'myDiv'}>
                    <div className=''>
                        {renderUI()}
                    </div>
                </div>
            </div>
        </>
    );
}

MarketCapView.propTypes = {};

export default MarketCapView;
