import React, {useEffect, useState} from 'react'
import DLLoader from "../../core/components/DLLoader/DLLoader";
import DLText from "dlcomponent/components/DLText/DLText";
import DLButton from "dlcomponent/components/DLButton/DLButton";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import StocksChart from "./StocksChart";
import StocksTable from "./StocksTable";
import {
    getAllStocks,
    getStocksSummary, resetStockPage, resetStockRowsPerPage,
    resetStockTableByOrder,resetStockTabValue,
    resetStockTableByOrderBy,setStockTabValue,
    setKeywordFilter
} from '../StocksActions';
import {withRouter} from "react-router-dom";
import CustomTabPanel from "../../component-utils/CustomTabPanel/CustomTabPanel";
import {downloadExcel} from "../../utils/downloadExcel";
import ChartRadioGroup from "../../component-utils/ChartRadioGroup/ChartRadioGroup";
import DLSearchAutoComplete from "dlcomponent/components/DLSearchAutoComplete/DLSearchAutoComplete";
import {
    getChartData,
    getFixedValuesAfterDecimal,
    getIndexArr,
    getTrimVal,
    searchOnWordBoundary
} from "../../utils/helper";
import {isEmpty} from "../../utils/validations";
import DLInputTextField from "dlcomponent/components/DLInputTextField/DLInputTextField";
import {connect} from "react-redux";
import moment from "moment";
import {downloadData} from "../../fund/FundAction";
import appTheme from "../../assets/appTheme";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function StocksListing(props) {

    const [stockListingState, setStockListing] = useState({
        allStockData: [],
        percentage: false,
        isSearchApply: false,
        sortedData: [],
        chartType: 'marketValue',
        searchResultNotAvailable: false
    });
    const [chartLabelsArray, setChartLabelsArray] = useState([]);
    const [chartDataArray, setChartDataArray] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tableHeader, setTableHeader] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isTableLoading, setIsTableLoading] = useState(true);

    const{tabValue} = props ;

    const getData = (data) => {
        let dataObj = [];
        if (isEmpty(data)) {
            return dataObj
        }

        data.forEach((item) => {
            dataObj.push({...item, label: item?.company_name, value: item?.isin_code})
        });
        if (isEmpty(dataObj)) {
            return []
        }
        return dataObj.sort((a, b) => {
            let A = a?.label?.trim();
            let B = b?.label?.trim();

            return A?.localeCompare(B)
        });
    };

    useEffect(() => {
        if (props.history.action === "POP" || props.history.action === "REPLACE") {
            //backward navigation
        } else {
            // resetListingData()
            resetStockTableByOrder();
            resetStockTableByOrderBy();
            resetStockPage();
            resetStockRowsPerPage();
            resetStockTabValue();
        }
            getStocksSummary(VALUATION_DATE).then((res) => {
                if (res.success && !isEmpty(res.data?.data)) {
                    const copiedArray = res.data.data.slice(1, res.data.data.length);
                    setTableData(copiedArray);
                    setStocksGraph(copiedArray);
                    setTableHeader(res.data.data[0]);
                    setIsLoading(false);
                    setIsTableLoading(false)
                }
            })
    }, []);

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false)
        }, 100)
    }, [props.isLGView, props.isMDView, props.isXLView, props.isSMView, props.isXSView]);

    useEffect(() => {
        const {isLGView, isXLView, isMobileView} = props;
        const {chartType} = stockListingState;

        if (isEmpty(tableData)) {
            return
        }

        if (isEmpty(props.keyword)) {

            setStockListing((prev) => ({
                ...prev,
                isSearchApply: true,
                sortedData: []
            }));

            let chartObj = getChartData(chartType, tableData, 'company_name', isLGView, isXLView,isMobileView);

            setChartLabelsArray(chartObj?.newLabelData);
            setChartDataArray(chartObj?.newChartData);

            setTimeout(() => {
                setStockListing((prev) => ({
                    ...prev,
                    isSearchApply: false,
                    searchResultNotAvailable: false
                }))
            }, 300)
        } else {
            let sortedData = [];
            setStockListing((prev) => ({
                ...prev,
                isSearchApply: true
            }));

            let options = tableData.map((item) => {
                return {...item, label: item.company_name, value: item.company_name}
            });

            sortedData = searchOnWordBoundary(props.keyword, JSON.parse(JSON.stringify(options)));

            if (!isEmpty(sortedData)) {
                let data = searchOnWordBoundary(props.keyword, options);

                let chartObj = getChartData(chartType, data, 'company_name', isLGView, isXLView,isMobileView);

                setStockListing((prev) => ({
                    ...prev,
                    sortedData: sortedData,
                    searchResultNotAvailable: false
                }));
                setChartLabelsArray(chartObj?.newLabelData);
                setChartDataArray(chartObj?.newChartData);
            } else {
                setStockListing((prev) => ({
                    ...prev,
                    sortedData: [],
                    searchResultNotAvailable: true
                }));
            }

            setTimeout(() => {
                setStockListing((prev) => ({
                    ...prev,
                    isSearchApply: false
                }))
            }, 300)

        }

    }, [props.keyword, tableData]);

    const getFileName = (blockName) =>{
        let date = new Date();
        return 'Stocks-'+blockName+moment(date).format('YYYY-MM-DD')
    };


    const handleClick = (elementIndex) => {
        const {chartType, sortedData} = stockListingState;
        let tableDataArr = !isEmpty(sortedData) ? sortedData : tableData;
        let indexArr = getIndexArr(chartType, tableDataArr);

            let clickedStock = indexArr[elementIndex];
            props.history.push('/stocks/' + clickedStock.isin_code)

    };

    const handleTabChange = (event, newValue) => {
        setStockTabValue(newValue);
    };

    const deepCopy = (obj) => {
        return JSON.parse(JSON.stringify(obj));
    };

    //order by descending
    const compareByMarketValueChangeMoM = (a, b) => {
        if (a.net_buying_selling < b.net_buying_selling) {
            return 1;
        }
        if (a.net_buying_selling > b.net_buying_selling) {
            return -1;
        }
        return 0;
    };

    const compareByMarketValueChangePercent = (a, b) => {
        if (a.net_buying_selling_percentage < b.net_buying_selling_percentage) {
            return 1;
        }
        if (a.net_buying_selling_percentage > b.net_buying_selling_percentage) {
            return -1;
        }
        return 0;
    };


    const handleRadioChange = (event) => {
        const {isXSView, isLGView, isXLView, isMobileView} = props;
        let chartType = event;

        const {sortedData} = stockListingState;
        let tableDataArr = !isEmpty(sortedData) ? sortedData : tableData;

        let chartObj = getChartData(chartType, tableDataArr, 'company_name', isLGView, isXLView,isMobileView);

        setStockListing((prevState)=>({
            ...prevState,
            percentage: (chartType === 'changePercent' || chartType ===  'changeSellingPercent'),
            chartType: chartType
        }));

        setChartLabelsArray(chartObj?.newLabelData);
        setChartDataArray(chartObj?.newChartData);
    };


    const setStocksGraph = (data) => {
        const {isLGView, isXLView, isMobileView} = props;
        if (isEmpty(data)) {
            return
        }
        let length = data?.length > 10 ? 10 : data?.length;
        for (let i = 0; i < (length); i++) {
            let stockName = getTrimVal(data[i].company_name, isLGView, isXLView, isMobileView);
            let marketValue = getFixedValuesAfterDecimal(data[i].total_market_value,2);

            setChartLabelsArray(fundHouseNames => [...fundHouseNames, stockName]);
            setChartDataArray(marketValueInCrores => [...marketValueInCrores, marketValue]);

        }
    };

    const onChangeKeywordFilter = (keyword) => {
        setKeywordFilter(keyword)
    };

    if (isLoading) {
        return (
            <div>
                <DLLoader type={'inline'} isVisible={true}/>
            </div>
        )
    }


    const renderFilterUI = () => {
        const {t, isXSView, keyword} = props;

        return (
            <div className='px-3' style={{marginBottom:  isXSView ? 8 :14}}>
                <div style={{width: isXSView ? '100%' : '50%'}}>
                    <DLInputTextField
                        id={'businessList-keywordSearch'}
                        label={''}
                        rows={1}
                        fullWidth
                        styleType={"search"}
                        placeholder={'Search...'}
                        value={keyword}
                        onChangeValue={(e) => onChangeKeywordFilter(e)}
                        isClearable={true}
                        inputHeight={'sm'}
                        marginBottom={"none"}
                        InputProps={{
                            endAdornment: (
                                keyword ?
                                <i className="fa fa-remove m-2 cursor" onClick={()=>{onChangeKeywordFilter("")}} style={{color: appTheme.appColor.black}}/>
                                :
                                <i className={'fas fa-search pr-1'} style={{color: appTheme.appColor.black}}/>)
                        }}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {

                                document?.activeElement?.blur()
                            }
                        }}
                        disableHelperMessageHeight={true}
                    />
                </div>
            </div>
        )
    };

    const getResult = (data) =>{
        if (isEmpty(data)){
            return
        }

        if (data?.length >= 10){
            return(
                <div className='py-2 mx-3'>
                    Showing top 10 results
                </div>
            )
        }else {
            if (data.length === 1){
                return (
                    <div className='py-2 mx-3'>
                        Showing {data?.length} result
                    </div>
                )
            }else {
                return (
                    <div className='py-2 mx-3'>
                        Showing {data?.length} results
                    </div>
                )

            }
        }
    }

    const renderDataListingUI = () => {
        const {percentage, isSearchApply, sortedData, searchResultNotAvailable, chartType} = stockListingState;
        const {isXSView} = props;
        let tableDataArr = !isEmpty(sortedData) ? sortedData : tableData;

        if (isTableLoading) {
            return
        }

        return (
            <div className='panel mx-sm-3'
                 id='stocks-listing'>

                <Box sx={{width: '100%'}}>
                    <Box className={isXSView ? 'd-flex justify-content-between mx-3' : 'd-flex justify-content-between mx-4 pt-3'} sx={{borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab sx={{paddingLeft: 1}} label="Chart" {...a11yProps(0)} sx={{ textTransform:'capitalize'}} />
                            <Tab label="Table" {...a11yProps(1)} sx={{ textTransform:'capitalize'}}/>
                        </Tabs>
                        <div style={{width: 36, height: 36, minWidth: 'max-content', paddingTop: 5}}>
                            <div className={'downloadButtonCss cursor'}>
                                <i className='fas fa-download fs-6'
                                   onClick={() => {
                                       downloadData('/service/media/home/stockSummary/download?date=' + VALUATION_DATE, '', 'Download', getFileName('Summary-'))
                                   }}
                                   style={{fontSize: 12}}/>
                            </div>
                        </div>
                    </Box>
                    <CustomTabPanel id={'tableTabPanel'}  value={tabValue} index={0}>
                        {(isEmpty(chartDataArray) || isEmpty(chartLabelsArray)) ?
                            <div>
                                <ChartRadioGroup handleRadioChange={handleRadioChange}
                                                 chartType={chartType}
                                                 isXSView={props.isXSView}
                                                 isSMView={props.isSMView}/>
                            <div className='d-flex align-items-center justify-content-center mx-3' style={{minHeight: 300}}>
                                <DLText
                                    id={'revenue-value'}
                                    type={'normal'}
                                    text={'No data found'}
                                    fontSize={'md'}/>
                            </div>
                            </div>
                            :
                            <>
                                {isSearchApply ?
                                    <>
                                    </>
                                    : <>
                                        {searchResultNotAvailable ?
                                            <div className='m-3' style={{minHeight: 'calc(100vh - 400px)'}}>
                                                <DLText
                                                    id={'revenue-value'}
                                                    type={'normal'}
                                                    text={'No matching stock found.'}
                                                    fontSize={'lg'}/>
                                            </div>
                                            :
                                            <>
                                                <ChartRadioGroup handleRadioChange={handleRadioChange}
                                                                 chartType={chartType}
                                                                 isXSView={props.isXSView}
                                                                 isSMView={props.isSMView}/>
                                                    {getResult(chartDataArray)}
                                                <StocksChart yAxis={chartLabelsArray}
                                                             xAxis={chartDataArray}
                                                             chartType={chartType}
                                                             handleClick={handleClick}
                                                             percentage={percentage}
                                                             isMobileView={props.isMobileView}/>
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1} id={'tableTabPanel'}>
                        {isSearchApply ?
                            <>
                            </>
                            :
                            <>
                                {searchResultNotAvailable ?
                                    <div style={{minHeight: 'calc(100vh - 400px)'}}>
                                        <div className= "p-3">
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={'No matching stock found.'}
                                                fontSize={'lg'}/>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <StocksTable data={tableDataArr} {...props} tableHeader={tableHeader}/>
                                    </>
                                }
                            </>
                        }
                    </CustomTabPanel>
                </Box>
            </div>
        )
    };

    return (
        <div className='pb-2'>
            <div className='fixedHeader d-flex justify-content-between align-items-center bg-white px-3 py-2'>
                <div>
                    <DLText
                        id={'summary-by-country'}
                        type={'normal'}
                        text={'Stocks'}
                        fontWeight={'semi-bold'}
                        marginBottom={'none'}
                        fontSize={'xl'}/>
                </div>
                <DLButton
                    id={'download-excel'}
                    styleType={"outlined"}
                    borderType={'square'}
                    buttonSize={'xs'}
                    style={{border: "0.5px solid var(--black)", color: "var(--black)"}}
                    fontSize={"sm"}
                    onClick={() =>{props.history.push("/compare-stocks/")}}
                    label={"Compare Stocks"}
                />

            </div>
            <div className='pb-2'>
                {renderFilterUI()}
                {renderDataListingUI()}
            </div>
        </div>
    )

}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    keyword: state.stockState.keyword,
    order: state.stockState.order,
    orderBy: state.stockState.orderBy,
    page: state.stockState.page,
    rowsPerPage: state.stockState.rowsPerPage,
    tabValue: state.stockState.tabValue
});

export default connect(mapStateToProps, {})(StocksListing);
