import React from 'react';
import DLText from "dlcomponent/components/DLText/DLText";
import {isEmpty} from "../utils/validations";
import DLLoader from "../core/components/DLLoader/DLLoader";
import appTheme from "../assets/appTheme";
import {
    filterNetBuyingSellingValue,
    getFixedValuesAfterDecimal,
    numberWithCommas
} from "../utils/helper";
import ExplorerChipsView from "../explorer/ExplorerChipsView";
import ExplorerFundHouseListingModal from "../explorer/explorerModal/ExplorerFundHouseListingModal";
import ExplorerFundListingModal from "../explorer/explorerModal/ExplorerFundListingModal";
import ExplorerMarketCap from "../explorer/explorerModal/ExplorerMarketCap";
import ExplorerIndustrySearchModal from "../explorer/explorerModal/ExplorerIndustrySearchModal";
import ExplorerStocksSearchModal from "../explorer/explorerModal/ExplorerStocksSearchModal";
import ExplorerBusinessHouseSearchModal from "../explorer/explorerModal/ExplorerBusinessHouseSearchModal";
import DLDropDown from 'dlcomponent/components/DLDropdown/DLDropDown'
import EChartTreemap from "../component-utils/EChart/EChartTreemap";
import {greenColourShades, redColourShades} from "./MoneyFlowExplorerConst";
import MoneyFlowExplorerActionModal from "./moneyFlowExplorerModals/MoneyFlowExplorerActionModal";
import {filterAumValue, getMarketValue} from "../utils/stocksHelper";
import {downloadDataByObject} from "../fund/FundAction";

function MoneyFlowExplorerView(props) {

    const {
        addLineFilter, searchResult, getFileName, storedDataForDownload, handleChartClick, getNameOnly, getDataOnly, toDate,
        resetListingData, isXSView, isSMView, isFilterAPIInProgress, isMobileView,isMDView,
        explorerFilter, drillDownBy, buying, selling
    } = props;

    const notAllValuesEmpty = () => {
        return isEmpty(explorerFilter.fundHouses) && isEmpty(explorerFilter.businessHouses)  && isEmpty(explorerFilter.funds)
            && isEmpty(explorerFilter.marketCaps) && isEmpty(explorerFilter.industries) && isEmpty(explorerFilter.stocks) && drillDownBy === 'fundHouse' ;
    };

    const getDataArray = (valuesArr, name, valueAttribute, allValuesNegative) => {
        let finalData = [];
        let otherMarketValues = 0;
        let count = 0;
        name = props.getIdLabelForDrillDown(name);

        if (isEmpty(valuesArr)) {
            return []
        }

        valuesArr.forEach((item, index) => {
            if (index >= 49) {
                if (allValuesNegative) {

                    otherMarketValues = otherMarketValues + (-item[valueAttribute]);
                } else {
                    otherMarketValues = otherMarketValues + item[valueAttribute];
                }
            } else {
                finalData.push({
                    id:'block-'+item[name],
                    name: item[name],
                    value: allValuesNegative ? getFixedValuesAfterDecimal(-item[valueAttribute], 2) : getFixedValuesAfterDecimal(item[valueAttribute], 2)
                })
            }
        });

        if (otherMarketValues !== 0) {
            finalData.push({
                id:'block-others',
                name: 'Others',
                value: getFixedValuesAfterDecimal(otherMarketValues, 2)
            });
        }

        return finalData
    };

    const getWidth = (block) =>{
        const {totalBuying, totalSelling, isLGView, isXLView} = props;

        if (isLGView || isXLView){
            let totalBuyingSelling = totalBuying + (-totalSelling);
            const percentageBuying = (totalBuying / totalBuyingSelling) * 100;
            const percentageSelling = (totalSelling / totalBuyingSelling) * 100;

            if (block === 'buying'){
                if (Math.round(percentageBuying) >= 85){
                    return  '85%'
                }else if(Math.round(percentageBuying) >= 15){
                    return Math.round(percentageBuying) + '%'
                }else {
                    return '15%'
                }
            }
            if (block === 'selling'){
                if (Math.round(Math.abs(percentageSelling)) >= 85){
                    return  '85%'
                }else if (Math.round(Math.abs(percentageSelling)) >= 15){
                    return Math.round(Math.abs(percentageSelling)) + '%'
                }else{
                    return '15%'
                }
            }
        }else {
            return '100%'
        }
    };

    const renderTreeMapUI = () => {
        const {buying, selling, totalBuying, totalSelling} = props;

        if (isFilterAPIInProgress){
            return (
                <div style={{minHeight:400, maxHeight:300, width:'800px'}}>
                    <DLLoader type={"inline"} isVisible={true}/>
                </div>
            );
        }

        if (isEmpty(selling) && isEmpty(buying)) {
            return (
                <div className='d-flex align-items-center justify-content-center w-100'  style={{minHeight: 430}}>
                    <DLText marginBottom={'none'}
                            isInline={true}
                            text={'No data found'}
                            fontSize={'sm'}/>
                </div>
            )
        }

        if (!isEmpty(selling) && !isEmpty(buying)) {
            let buyingValue = totalBuying;
            let sellingValue = totalSelling;
            return (
                <div className='w-100 d-flex flex-column flex-lg-row px-3'>
                    <div className='' style={{width:  getWidth('buying')}}>
                        <div className='d-flex flex-wrap pt-lg-2 '>
                            <div style={{paddingTop:2}}>
                            <DLText id={'revenue-value'}
                                    type={'normal'}
                                    text={'Buying: '}
                                    marginBottom={'none'}
                                    fontWeight={'semi-bold'}
                                    fontSize={'sm'}/>
                            </div>
                            <div className='ps-1' >
                                <DLText id={'revenue-value'}
                                        type={'normal'}
                                        text={numberWithCommas(filterAumValue(buyingValue))}
                                        marginBottom={'none'}
                                        fontColor={'success'}
                                        fontSize={'md'}/>
                            </div>
                        </div>
                        <div className=' w-100 pe-lg-3 pt-2'>
                            <EChartTreemap {...props}
                                           handleChartClick={handleChartClick}
                                           requestedHeight={"430px"}
                                           externalTooltip={
                                               {trigger: 'item',
                                                   formatter: function (params) {
                                                       if(isEmpty(params.name)){
                                                           return;
                                                       }

                                                       return `<div id="${params.data.id}"><span style="font-size: 16px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif">${params.name}</span> <br/>
                                                                <span style="font-size: 14px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif""> ${numberWithCommas(params.value)}
                                                                <span style="font-size: 10px; font-weight: 500; color: #282828 ; font-family: 'Poppins', sans-serif""> Net buying </span> 
                                                                </div></span><span style="font-size: 14px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif""> ${params.data.percent > 100 ? 100 : params.data.percent}% 
                                                                 </span><span style="font-size: 10px; font-weight: 500; color: #282828 ; font-family: 'Poppins', sans-serif""> of Total </span> </div>`;},
                                               }}
                                           data={getDataArray(buying, drillDownBy, 'net_buying_selling')}
                                           color={greenColourShades}
                            />

                        </div>
                    </div>
                    <div className='' style={{width: getWidth('selling')}}>
                        <div className='d-flex flex-wrap align-items-end pt-2'>
                            <div style={{minWidth: 'max-content'}}>
                            <DLText id={'revenue-value'}
                                    type={'normal'}
                                    text={'Selling:'}
                                    marginBottom={'none'}
                                    fontWeight={'semi-bold'}
                                    fontSize={'sm'}/>
                            </div>
                            <div className='ps-1' >
                                <DLText id={'revenue-value'}
                                        type={'normal'}
                                        text={'('+numberWithCommas(-sellingValue)+')'}
                                        marginBottom={'none'}
                                        fontColor={'danger'}
                                        fontSize={'md'}/>
                            </div>
                        </div>
                        <div className=' pt-2 w-100 '>
                            <EChartTreemap {...props}
                                           handleChartClick={handleChartClick}
                                           requestedHeight={"430px"}
                                           externalTooltip={
                                               {trigger: 'item',
                                                   formatter: function (params) {

                                                       return `<div id="${params.data.id}"><span style="font-size: 16px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif">${params.name}</span> <br/>
                                                                <span style="font-size: 14px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif""> ${numberWithCommas(params.value)}
                                                                <span style="font-size: 10px; font-weight: 500; color: #282828 ; font-family: 'Poppins', sans-serif""> Net selling </span> 
                                                                </div></span><span style="font-size: 14px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif""> ${params.data.percent > 100 ? 100 : params.data.percent}% 
                                                                 </span><span style="font-size: 10px; font-weight: 500; color: #282828 ; font-family: 'Poppins', sans-serif""> of Total </span> </div>`;
                                                       },
                                               }}
                                           data={getDataArray(selling, drillDownBy, 'net_buying_selling', true)}
                                           color={redColourShades}/>

                        </div>
                    </div>
                </div>
            )
        }

        if (!isEmpty(selling)) {
            let sellingValue = totalSelling;
            return (
                <div className='w-100 px-3'>
                    <div className='d-flex flex-wrap align-items-end pt-lg-2'>
                        <DLText id={'revenue-value'}
                                type={'normal'}
                                isInline={true}
                                text={'Selling: '}
                                marginBottom={'none'}
                                fontWeight={'semi-bold'}
                                fontSize={'sm'}/>
                        <div className='ps-1' style={{paddingTop:4}}>
                            <DLText id={'revenue-value'}
                                    type={'normal'}
                                    text={'('+numberWithCommas(-sellingValue)+')'}
                                    marginBottom={'none'}
                                    fontColor={'danger'}
                                    fontSize={'md'}/>
                        </div>
                    </div>
                    <div className='w-100  pt-2'>
                        <EChartTreemap {...props}
                                       handleChartClick={handleChartClick}
                                       requestedHeight={"430px"}
                                       externalTooltip={
                                           {trigger: 'item',
                                               formatter: function (params) {
                                                   return `<div id="${params.data.id}"><span style="font-size: 16px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif">${params.name}</span> <br/>
                                                                <span style="font-size: 14px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif""> ${numberWithCommas(params.value)}
                                                                <span style="font-size: 10px; font-weight: 500; color: #282828 ; font-family: 'Poppins', sans-serif""> Net selling </span> 
                                                                </div></span><span style="font-size: 14px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif""> ${params.data.percent > 100 ? 100 : params.data.percent}% 
                                                                 </span><span style="font-size: 10px; font-weight: 500; color: #282828 ; font-family: 'Poppins', sans-serif""> of Total </span> </div>`;
                                               },
                                           }}
                                       data={getDataArray(selling, drillDownBy, 'net_buying_selling', true)}
                                       color={redColourShades}/>

                    </div>

                </div>
            )
        }

        if (!isEmpty(buying)) {
            let buyingValue = totalBuying;
            return (
                <div className='w-100 px-3'>
                    <div className='d-flex flex-wrap pt-lg-2'>
                        <div style={{paddingTop: 2}}>
                            <DLText id={'revenue-value'}
                                    type={'normal'}
                                    text={'Buying: '}
                                    marginBottom={'none'}
                                    fontWeight={'semi-bold'}
                                    fontSize={'sm'}/>
                        </div>
                        <div className='ps-1' >
                            <DLText id={'revenue-value'}
                                    type={'normal'}
                                    text={numberWithCommas(filterAumValue(buyingValue))}
                                    marginBottom={'none'}
                                    fontColor={'success'}
                                    fontSize={'md'}/>
                        </div>
                    </div>
                    <div className='pt-2 w-100 '>
                    <EChartTreemap {...props}
                                   requestedHeight={"430px"}
                                   handleChartClick={handleChartClick}
                                   externalTooltip={
                                       {trigger: 'item',
                                           formatter: function (params) {
                                               return `<div id="${params.data.id}"><span style="font-size: 16px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif">${params.name}</span> <br/>
                                                                <span style="font-size: 14px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif""> ${numberWithCommas(params.value)}
                                                                <span style="font-size: 10px; font-weight: 500; color: #282828 ; font-family: 'Poppins', sans-serif""> Net buying </span> 
                                                                </div></span><span style="font-size: 14px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif""> ${params.data.percent > 100 ? 100 : params.data.percent}% 
                                                                 </span><span style="font-size: 10px; font-weight: 500; color: #282828 ; font-family: 'Poppins', sans-serif""> of Total </span> </div>`;
                                           },
                                       }}
                                   data={getDataArray(buying, drillDownBy, 'net_buying_selling')}
                                   color={greenColourShades}/>
                    </div>

                </div>
            )
        }

    };

    const renderGraphsUI = () => {
        const {selling, buying, totalBuying, totalSelling} = props;
        let values = Object.values(searchResult);
        let netBuyingSelling = totalBuying - (Math.abs(totalSelling));

        return (
            <div className='w-100 d-flex flex-lg-row flex-column mt-2 mt-lg-0'>
                <div className={'w-100 pb-3'}>

                    {((!isEmpty(selling) || !isEmpty(buying)) && isMobileView) &&
                    <div className='d-flex px-3 mt-2 mt-lg-0' style={{minWidth: 'max-content'}}>
                        <div style={{paddingTop:2}}>
                            <DLText marginBottom={'none'}
                                    text={'Net Buying/Selling:'}
                                    fontWeight={'semi-bold'}
                                    fontSize={'sm'}/>
                        </div>
                        <div className='ps-1'>
                            <DLText marginBottom={'none'}
                                    text={netBuyingSelling < 0 ? '(' + numberWithCommas(-netBuyingSelling) + ')' : numberWithCommas(netBuyingSelling)}
                                    fontWeight={'semi-bold'}
                                    fontColor={netBuyingSelling < 0 ? 'danger' : 'success'}
                                    fontSize={'md'}/>
                        </div>
                    </div>
                    }
                    <div className='d-flex'>
                        {renderTreeMapUI()}
                    </div>

                </div>
            </div>
        )
    };

    const renderChipsFilterUI = () => {

        if (isXSView || isSMView) {
            return (
                <div className='ulLine w-100 pt-2' style={{overflowX: 'auto'}}>
                    <ExplorerChipsView {...props} externalHeight={'32px'}/>
                </div>
            )
        }
        return (
            <div className='d-flex justify-content-between align-items-center'>
                <div className='ps-3'>
                    <ExplorerChipsView {...props} externalHeight={'32px'}/>
                </div>
            </div>
        )
    };

    const renderAdvanceSearchModal = () => {
        return (
            <>
                <ExplorerFundHouseListingModal {...props}/>
                <ExplorerFundListingModal {...props}/>
                <ExplorerIndustrySearchModal {...props}/>
                <ExplorerStocksSearchModal {...props}/>
                <ExplorerMarketCap {...props}/>
                <ExplorerBusinessHouseSearchModal {...props}/>
                <MoneyFlowExplorerActionModal {...props}/>
            </>
        )
    };

    const renderDrillDownByUI = () => {
        const {drillDownBy, handleViewChange, totalSelling, totalBuying, selling, buying, storedDataForDownload, getFileName} = props;
        let netBuyingSelling = totalBuying - (Math.abs(totalSelling));

        return (
            <div className='px-3 d-flex flex-column flex-lg-row justify-content-between pt-sm-3 w-100'>
                <div className='d-flex justify-content-between justify-content-lg-start align-items-end w-100'>
                    <div className={isMobileView ? 'pe-3 mb-2' : 'pe-3'}>
                        <DLText marginBottom={'none'}
                                text={'View'}
                                fontWeight={'semi-bold'}
                                fontSize={'lg'}/>
                    </div>
                    {(isMobileView) ?
                        (!isEmpty(buying) || !isEmpty(selling)) && <div className='d-flex align-items-center justify-content-center downloadButtonCss cursor mb-2'>
                            <i className='fas fa-download fs-6'
                               onClick={() => {
                                   downloadDataByObject('/service/moneyFlow/download', "",'Download', getFileName("Money-flow-explorer-"),storedDataForDownload)
                               }}
                               style={{fontSize: 12}}/>
                        </div>
                        :
                        <div className='d-flex justify-content-between w-100'>
                            <div>
                            <nav className='w-100  ulLine border-bottom' style={{overflowX: 'auto'}}>
                                <ul className='d-flex ulLine ' style={{listStyle: 'none'}}>
                                    <li className={drillDownBy === 'fundHouse' ? 'active' : ''}
                                        style={{minWidth: 'max-content'}}>
                                        <div className="custom-underlined-text cursor"
                                             style={drillDownBy === 'fundHouse' ? {
                                                 fontWeight: 600,
                                                 color: '#282828',
                                                 fontSize: 12,
                                                 textDecoration: 'underline',
                                                 fontFamily: 'Poppins, sans-serif',
                                                 margin: '0 10px',
                                             } : {margin: '0 10px', fontSize: 12}}
                                             onClick={() => {
                                                 handleViewChange('fundHouse')
                                             }}>
                                            Fund Houses
                                        </div>

                                        {drillDownBy === 'fundHouse' &&
                                        <div className='bg-dark ' style={{minHeight: '2px'}}>

                                        </div>
                                        }
                                    </li>
                                    <li className={drillDownBy === 'fund' ? 'active' : ''}
                                        style={{minWidth: 'max-content'}}>
                                        <div className="custom-underlined-text cursor"
                                             style={drillDownBy === 'fund' ? {
                                                 fontWeight: 600,
                                                 color: '#282828',
                                                 fontSize: 12,
                                                 textDecoration: 'underline',
                                                 fontFamily: 'Poppins, sans-serif',
                                                 margin: '0 10px',
                                             } : {margin: '0 10px', fontSize: 12}}
                                             onClick={() => {
                                                 handleViewChange('fund')
                                             }}>
                                            Funds
                                        </div>

                                        {drillDownBy === 'fund' &&
                                        <div className='bg-dark ' style={{minHeight: '2px'}}>

                                        </div>
                                        }
                                    </li>
                                    <li className={drillDownBy === 'industry' ? 'active' : ''}
                                        style={{minWidth: 'max-content'}}>
                                        <div className="custom-underlined-text cursor"
                                             style={drillDownBy === 'industry' ? {
                                                 fontWeight: 600,
                                                 color: '#282828',
                                                 fontSize: 12,
                                                 textDecoration: 'underline',
                                                 fontFamily: 'Poppins, sans-serif',
                                                 margin: '0 10px',
                                             } : {margin: '0 10px', fontSize: 12}}
                                             onClick={() => {
                                                 handleViewChange('industry')
                                             }}>
                                            Sectors
                                        </div>

                                        {drillDownBy === 'industry' &&
                                        <div className='bg-dark ' style={{minHeight: '2px'}}>

                                        </div>
                                        }
                                    </li>
                                    <li className={drillDownBy === 'stock' ? 'active' : ''}
                                        style={{minWidth: 'max-content'}}>
                                        <div className="custom-underlined-text cursor"
                                             style={drillDownBy === 'stock' ? {
                                                 fontWeight: 600,
                                                 color: '#282828',
                                                 fontSize: 12,
                                                 textDecoration: 'underline',
                                                 fontFamily: 'Poppins, sans-serif',
                                                 margin: '0 10px',
                                             } : {margin: '0 10px', fontSize: 12}}
                                             onClick={() => {
                                                 handleViewChange('stock')
                                             }}>
                                            Stocks
                                        </div>

                                        {drillDownBy === 'stock' &&
                                        <div className='bg-dark ' style={{minHeight: '2px'}}>

                                        </div>
                                        }
                                    </li>
                                    <li className={drillDownBy === 'marketCap' ? 'active' : ''}
                                        style={{minWidth: 'max-content'}}>
                                        <div className="custom-underlined-text cursor"
                                             style={drillDownBy === 'marketCap' ? {
                                                 fontWeight: 600,
                                                 color: '#282828',
                                                 fontSize: 12,
                                                 textDecoration: 'underline',
                                                 fontFamily: 'Poppins, sans-serif',
                                                 margin: '0 10px',
                                             } : {margin: '0 10px', fontSize: 12}}
                                             onClick={() => {
                                                 handleViewChange('marketCap')
                                             }}>
                                            Market Caps
                                        </div>

                                        {drillDownBy === 'marketCap' &&
                                        <div className='bg-dark ' style={{minHeight: '2px'}}>

                                        </div>
                                        }
                                    </li>
                                    <li className={drillDownBy === 'businessHouse' ? 'active' : ''}
                                        style={{minWidth: 'max-content'}}>
                                        <div className="custom-underlined-text cursor"
                                             style={drillDownBy === 'businessHouse' ? {
                                                 fontWeight: 600,
                                                 color: '#282828',
                                                 fontSize: 12,
                                                 textDecoration: 'underline',
                                                 fontFamily: 'Poppins, sans-serif',
                                                 margin: '0 10px',
                                             } : {margin: '0 10px', fontSize: 12}}
                                             onClick={() => {
                                                 handleViewChange('businessHouse')
                                             }}>
                                            Business Houses
                                        </div>

                                        {drillDownBy === 'businessHouse' &&
                                        <div className='bg-dark ' style={{minHeight: '2px'}}>

                                        </div>
                                        }
                                    </li>
                                </ul>
                            </nav>
                            </div>
                            {(!isEmpty(buying) || !isEmpty(selling)) &&
                            <div className='d-flex ps-3' style={{minWidth: 'max-content'}}>
                                <div style={{paddingTop:2}}>
                                    <DLText marginBottom={'none'}
                                            text={'Net Buying/Selling:'}
                                            fontWeight={'semi-bold'}
                                            fontSize={'sm'}/>
                                </div>
                                <div className='ps-1'>
                                    <DLText marginBottom={'none'}
                                            text={netBuyingSelling < 0 ? '(' + numberWithCommas(-netBuyingSelling) + ')' : numberWithCommas(netBuyingSelling)}
                                            fontWeight={'semi-bold'}
                                            fontColor={netBuyingSelling < 0 ? 'danger' : 'success'}
                                            fontSize={'md'}/>
                                </div>
                            </div>
                            }
                        </div>

                    }
                </div>
                {isMobileView &&
                <div>
                    <nav className='w-100  ulLine border-bottom' style={{overflowX: 'auto'}}>
                        <ul className='d-flex ulLine ' style={{listStyle: 'none'}}>
                            <li className={drillDownBy === 'fundHouse' ? 'active' : ''}
                                style={{minWidth: 'max-content'}}>
                                <div className="custom-underlined-text cursor"
                                     style={drillDownBy === 'fundHouse' ? {
                                         fontWeight: 600,
                                         color: '#282828',
                                         fontSize: 12,
                                         textDecoration: 'underline',
                                         fontFamily: 'Poppins, sans-serif',
                                         margin: '0 10px',
                                     } : {margin: '0 10px', fontSize: 12}}
                                     onClick={() => {
                                         handleViewChange('fundHouse')
                                     }}>
                                    Fund Houses
                                </div>

                                {drillDownBy === 'fundHouse' &&
                                <div className='bg-dark ' style={{minHeight: '2px'}}>

                                </div>
                                }
                            </li>
                            <li className={drillDownBy === 'fund' ? 'active' : ''} style={{minWidth: 'max-content'}}>
                                <div className="custom-underlined-text cursor"
                                     style={drillDownBy === 'fund' ? {
                                         fontWeight: 600,
                                         color: '#282828',
                                         fontSize: 12,
                                         textDecoration: 'underline',
                                         fontFamily: 'Poppins, sans-serif',
                                         margin: '0 10px',
                                     } : {margin: '0 10px', fontSize: 12}}
                                     onClick={() => {
                                         handleViewChange('fund')
                                     }}>
                                    Funds
                                </div>

                                {drillDownBy === 'fund' &&
                                <div className='bg-dark ' style={{minHeight: '2px'}}>

                                </div>
                                }
                            </li>
                            <li className={drillDownBy === 'industry' ? 'active' : ''}
                                style={{minWidth: 'max-content'}}>
                                <div className="custom-underlined-text cursor"
                                     style={drillDownBy === 'industry' ? {
                                         fontWeight: 600,
                                         color: '#282828',
                                         fontSize: 12,
                                         textDecoration: 'underline',
                                         fontFamily: 'Poppins, sans-serif',
                                         margin: '0 10px',
                                     } : {margin: '0 10px', fontSize: 12}}
                                     onClick={() => {
                                         handleViewChange('industry')
                                     }}>
                                    Sectors
                                </div>

                                {drillDownBy === 'industry' &&
                                <div className='bg-dark ' style={{minHeight: '2px'}}>

                                </div>
                                }
                            </li>
                            <li className={drillDownBy === 'stock' ? 'active' : ''} style={{minWidth: 'max-content'}}>
                                <div className="custom-underlined-text cursor"
                                     style={drillDownBy === 'stock' ? {
                                         fontWeight: 600,
                                         color: '#282828',
                                         fontSize: 12,
                                         textDecoration: 'underline',
                                         fontFamily: 'Poppins, sans-serif',
                                         margin: '0 10px',
                                     } : {margin: '0 10px', fontSize: 12}}
                                     onClick={() => {
                                         handleViewChange('stock')
                                     }}>
                                    Stocks
                                </div>

                                {drillDownBy === 'stock' &&
                                <div className='bg-dark ' style={{minHeight: '2px'}}>

                                </div>
                                }
                            </li>
                            <li className={drillDownBy === 'marketCap' ? 'active' : ''}
                                style={{minWidth: 'max-content'}}>
                                <div className="custom-underlined-text cursor"
                                     style={drillDownBy === 'marketCap' ? {
                                         fontWeight: 600,
                                         color: '#282828',
                                         fontSize: 12,
                                         textDecoration: 'underline',
                                         fontFamily: 'Poppins, sans-serif',
                                         margin: '0 10px',
                                     } : {margin: '0 10px', fontSize: 12}}
                                     onClick={() => {
                                         handleViewChange('marketCap')
                                     }}>
                                    Market Caps
                                </div>

                                {drillDownBy === 'marketCap' &&
                                <div className='bg-dark ' style={{minHeight: '2px'}}>

                                </div>
                                }
                            </li>
                            <li className={drillDownBy === 'businessHouse' ? 'active' : ''}
                                style={{minWidth: 'max-content'}}>
                                <div className="custom-underlined-text cursor"
                                     style={drillDownBy === 'businessHouse' ? {
                                         fontWeight: 600,
                                         color: '#282828',
                                         fontSize: 12,
                                         textDecoration: 'underline',
                                         fontFamily: 'Poppins, sans-serif',
                                         margin: '0 10px',
                                     } : {margin: '0 10px', fontSize: 12}}
                                     onClick={() => {
                                         handleViewChange('businessHouse')
                                     }}>
                                    Business Houses
                                </div>

                                {drillDownBy === 'businessHouse' &&
                                <div className='bg-dark ' style={{minHeight: '2px'}}>

                                </div>
                                }
                            </li>
                        </ul>
                    </nav>
                </div>
                }
            </div>
        );

    };

    const checkExplorerDataAvailable = () => {
        let isFilterEmpty = Object.values(explorerFilter).every(value => {

            return value === '' || value === null || isEmpty(value);
        });

        if (isFilterEmpty && isEmpty(searchResult) && drillDownBy.length <= 1) {
            return true
        } else {
            return false
        }
    };

    const renderClearAll = () =>{

        if (notAllValuesEmpty()){
            return
        }
        if (isXSView || isSMView){
            return(
                <div>
                    <i className="fa fa-times cursor ms-3"
                       style={{paddingTop: 6}}
                       onClick={() => resetListingData()}
                    />
                </div>
            )
        }else {
            return (
                <div className='ps-3' style={{minWidth: 'max-content'}}>
                    <DLText
                        id={'clear'}
                        marginBottom={'none'}
                        text={'Clear All'}
                        customTextStyle={{
                            color: appTheme.linkStyle.color
                        }}
                        isClickable={true}
                        onClick={() => resetListingData()}
                        fontSize={'sm'}/>
                </div>
            )
        }
    }

    return (
        <div className=' bg-white pb-3'
             style={{minHeight: isMobileView ? 'calc(100vh - 164px)' : 'calc(100vh - 106px)'}}>
            <div className='fixedHeader bg-white px-3 pt-2 d-flex justify-content-between align-items-center'>
                <div className={'d-flex pb-2 w-100'}>
                    <i className="fa-solid fa-chevron-left cursor pe-2"
                       style={{marginTop: isMobileView ? 8 : 11}}
                       onClick={() => props.history.goBack()}/>
                    <div className='d-flex justify-content-between align-items-center w-100'>
                        <div>
                            <DLText marginBottom={'none'}
                                    text={'Money Flow Explorer'}
                                    fontWeight={'semi-bold'}
                                    fontSize={'lg'}/>
                        </div>
                        <div className='d-flex align-items-center'>

                            <div className={isXSView || isSMView ?'':'me-3'}>{renderClearAll()} </div>
                            {!isMobileView && (!isEmpty(selling) || !isEmpty(buying)) &&
                            <div className='ms-3 d-flex align-items-center justify-content-center downloadButtonCss cursor'>
                                <i className='fas fa-download fs-6'
                                   onClick={() => {
                                       downloadDataByObject('/service/moneyFlow/download', "",'Download', getFileName("Money-flow-explorer-"),storedDataForDownload)
                                   }}
                                   style={{fontSize: 12}}/>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className='pb-2'>
                <div className={'px-3 pb-2'}>
                    <div className='d-flex flex-md-row flex-column align-items-md-center pb-1 w-100 ulLine' style={{overflowX: 'auto'}}>
                        <div className='d-flex pe-lg-2'>
                            <div className='d-flex align-items-center w-50'>
                                <div style={{minWidth: 'max-content'}}>
                                    <DLText marginBottom={'none'}
                                            isInline={true}
                                            text={'From:'}
                                            fontSize={'sm'}/>
                                </div>
                                <div className='ps-2 w-100'>
                                    <DLDropDown id={''}
                                                label={""}
                                                noOptionsMessage={'No options found'}
                                                placeholder={''}
                                                value={props.fromDate}
                                                onSelect={(value) => props.setFromDate(value)}
                                                t={props.t}
                                                inputHeight={'xs'}
                                                fontSize={'xs'}
                                                minWidth={'100%'}
                                                marginBottom={"sm"}
                                                options={props.fromDateOptions}
                                    />
                                </div>
                            </div>
                            <div className='d-flex align-items-center ps-3 w-50'>
                                <div style={{minWidth: 'max-content'}}>
                                    <DLText marginBottom={'none'}
                                            isInline={true}
                                            text={'To:'}
                                            fontSize={'sm'}/>
                                </div>
                                <div className='ps-2 w-100'>
                                    <DLDropDown id={''}
                                                label={""}
                                                noOptionsMessage={'No options found'}
                                                placeholder={''}
                                                value={props.toDate}
                                                onSelect={(value) => props.setToDate(value)}
                                                t={props.t}
                                                inputHeight={'xs'}
                                                fontSize={'xs'}
                                                minWidth={'100%'}
                                                marginBottom={"sm"}
                                                options={props.toDateOptions}
                                    />
                                </div>
                            </div>
                        </div>
                        {renderChipsFilterUI()}
                    </div>
                </div>
                {checkExplorerDataAvailable() ?
                    <div className='d-flex align-items-center justify-content-center' style={{minHeight: 300}}>
                        <DLText marginBottom={'none'}
                                isInline={true}
                                text={'Apply a filter'}
                                fontSize={'sm'}/>
                    </div>
                    :
                    <div className={isMDView ? 'mx-sm-3  panel mb-2' :'mx-sm-3  panel'}>
                        {renderDrillDownByUI()}
                        {renderGraphsUI()}
                    </div>
                }
            </div>
            {renderAdvanceSearchModal()}
        </div>
    )
}

MoneyFlowExplorerView.propTypes = {};

export default MoneyFlowExplorerView;
