import { showSystemErrorBlock , showToastMessage} from "../../core/actions/appActions";
import store from "../../store";
import {httpGet} from "../../utils/httpHelper";
import {setKeyword} from "../../stocks/stockReducer";
import {isEmpty} from "../../utils/validations";

let base_url = '';
// let base_url = ''
export const getMonthlyActivitySummaryForFundHouses= (isin,date) => {
    let url = base_url+'/service/stocks/monthlyActivitySummaryForFundHouses?date='+date+'&isin='+isin;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};
export const getNetBuyingByFund= (isin,date) => {
    let url = base_url+'/service/stocks/netBuyingByFund?date='+date+'&isin='+isin;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getNetSellingByFund= () => {
    let url = base_url + '/service/stocks/netSellingByFund?date=2023-08-31&isin=INE040A01034' ;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getSummaryByFundHouse= (date,isin,fundHouses, funds, fundHouseForFund) => {
    let url = "";
    if(isEmpty(fundHouses) && isEmpty(funds) ){
        url = base_url+ '/service/stocks/fundHouseSummary?date='+date+'&isin='+isin ;
    }else{
        url = base_url+ '/service/stocks/fundHouseSummary?date='+date+'&isin='+isin+'&fundHouseId='+encodeURIComponent(JSON.stringify(fundHouses))+'&fundId='+encodeURIComponent(JSON.stringify(funds))+'&fundHouseForFund='+encodeURIComponent(JSON.stringify(fundHouseForFund)) ;
    }
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getNetActivityByFundHouse= (date,isin) => {
    let url = base_url+ '/service/stocks/netActivityByFundHouse?date='+date+'&isin='+isin ;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getNetActivityByFund= (date,isin) => {
    let url = base_url+'/service/stocks/netActivityByFund?date='+date+'&isin='+isin ;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};


export const getSummaryByFund= (date,isin,fundHouses,funds, fundHouseForFund) => {
    let url = "";
    if(isEmpty(fundHouses) && isEmpty(funds)){
        url = base_url + '/service/stocks/fundSummary?date='+date+'&isin='+isin;
    }else{
        url = base_url + '/service/stocks/fundSummary?date='+date+'&isin='+isin+'&fundHouseId='+encodeURIComponent(JSON.stringify(fundHouses))+'&fundId='+encodeURIComponent(JSON.stringify(funds))+'&fundHouseForFund='+encodeURIComponent(JSON.stringify(fundHouseForFund)) ;
    }
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getAllStocks= () => {
    let url = base_url+ '/service/stocks/all' ;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getStocksSummary= (date, skipCount, limitCount) => {

    return httpGet('/service/home/stocksSummary?date='+date)
        .then(res=>{

            return res;

        })
};


export const getStockActivities= (date,isin) => {
    let url = base_url+ '/service/stocks/lastMonthSummary?date='+date+'&isin='+isin ;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};
export const getIndustryActivities= (date,industry) => {
    let url = base_url+ '/service/sector/lastMonthSummary?date='+date+'&industry='+industry ;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getStockTrend= (date,isin,fundHouses,funds, fundHouseForFund) => {
    let url = "";
    if(isEmpty(fundHouses) && isEmpty(funds) ){
        url = base_url+ '/service/stocks/monthlyTrend?date='+date+'&isin='+isin ;
    }else{
        url = base_url+ '/service/stocks/monthlyTrend?date='+date+'&isin='+isin+'&fundHouseId='+encodeURIComponent(JSON.stringify(fundHouses))+'&fundId='+encodeURIComponent(JSON.stringify(funds))+'&fundHouseForFund='+encodeURIComponent(JSON.stringify(fundHouseForFund)) ;
    }
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getStockDetails= (isin, fundHouses, funds) => {

    let url = "";
    if(isEmpty(fundHouses) && isEmpty(funds) ){
        url = base_url+ '/service/stocks/details?isin='+isin;
    }else{
        url = base_url+ '/service/stocks/details?isin='+isin+'&fundHouseId='+encodeURIComponent(JSON.stringify(fundHouses))+'&fundId='+encodeURIComponent(JSON.stringify(funds)) ;
    }

    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getStockTrendForFundHouse= (date,fundHouseId) => {
    let url = base_url+ '/service/fundHouse/monthlyTrend?date='+date+'&fundHouseId='+fundHouseId ;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};
export const getStockTrendForIndustry= (date,industry) => {
    let url = base_url+ '/service/sector/monthlyTrend?date='+date+'&industry='+industry ;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getStockTrendForFund= (date,fundHouseId, fundId) => {
    let url = base_url+ '/service/fund/monthlyTrend?date='+date+'&fundHouseId='+fundHouseId+'&fundId='+fundId;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};
export const getSceenrCode= (date,isin) => {
    let url = base_url+ '/service/stocks/screenerData?isin='+isin;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getNetBuyingSellingPopUpData= (date,isin,fundHouses,funds,fundHouseForFund) => {

    let url = "";
    if(isEmpty(fundHouses) && isEmpty(funds) ){
        url = base_url+ '/service/stocks/lastMonthSummary?date='+date+'&isin='+isin;
    }else{
        url = base_url+ '/service/stocks/lastMonthSummary?date='+date+'&isin='+isin+'&fundHouseId='+encodeURIComponent(JSON.stringify(fundHouses))+'&fundId='+encodeURIComponent(JSON.stringify(funds))+'&fundHouseForFund='+encodeURIComponent(JSON.stringify(fundHouseForFund)) ;
    }

    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const setKeywordFilter = (keyword) =>{
    store.dispatch(setKeyword(keyword))
};