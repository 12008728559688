import React, {useState} from 'react';
import {isEmpty} from "../../utils/helperCommon";
import DLAccordion from 'dlcomponent/components/DLAccordion/DLAccordion';
import {NavLink} from 'react-router-dom';
import {connect} from "react-redux";
import DLText from "dlcomponent/components/DLText/DLText";

const AppDrawerView = (props) => {

    const {profile, closeDrawer} = props;
    const [menuExpansions, setMenuExpansions] = useState({});
    const expand = (section) => {
        setMenuExpansions({
            ...menuExpansions,
            [section + 'Expanded']: !menuExpansions[section + 'Expanded']
        })
    };


    const getMenuOptions = () => {
        let role = '';
        let menuOption = [];

        if (!isEmpty(profile)) {
            role = profile?.SystemUser?.role
        }


        menuOption.push({
            label: 'Home',
            icon: (<i className={'fa-solid fa-table'} style={{width: 20}}/>),
            section: 'Home',
            route: '/'
        },{
            label: 'Stock Search',
            icon: (<i className={'fa-solid fa-table'} style={{width: 20}}/>),
            section: 'Stock Search',
            route: '/stocks'
        },{
            label: 'Fund House View',
            icon: (<i className={'fa-solid fa-table'} style={{width: 20}}/>),
            section: 'Fund House View',
            route: '/fund-houses'
        },{
            label: 'Funds',
            icon: (<i className={'fa-solid fa-table'} style={{width: 20}}/>),
            section: 'Fund View',
            route: '/funds'
        },{
            label: 'Industry Search',
            icon: (<i className={'fa-solid fa-table'} style={{width: 20}}/>),
            section: 'Industry Search',
            route: '/sectors'
        });


        return menuOption
    };

    const renderMenuItemUi = (item, isSubItem) => {
        return (
            <div className="w-100">
                <div className={' menu-item' + (isSubItem ? '   ' : '')}
                     style={{paddingLeft: 25}}>
                    <DLText id={'menu-item-' + item?.label}
                            marginBottom={"none"}
                            fontSize={"sm"}
                            fontWeight={"normal"}
                            text={
                                <div className="d-flex align-items-center py-2">
                                    <div className="menu-icon" style={{paddingRight: '10px'}}>{item?.icon}</div>
                                    <div>{" " + item?.label}</div>
                                </div>
                            }
                    />
                </div>
            </div>
        );


    };

    return (
        <div className='drawer'>
            {
                getMenuOptions().map((item, index) => {

                    if (isEmpty(item?.subSections)) {
                        return (
                            <div key={'section-' + index}
                                 onClick={closeDrawer}>
                                <NavLink to={item?.route}
                                         exact activeClassName="active-link">
                                    <div onClick={() => (item?.onClick ? item?.onClick() : null)}>
                                        {renderMenuItemUi(item)}
                                    </div>
                                </NavLink>
                            </div>
                        );
                    }

                    return (
                        <div key={'section-' + index}
                             className="left-panel-submenus">
                            <DLAccordion
                                id={'about-drawer-opt3'}
                                containerPadding={"none"}
                                showIcon={true}
                                iconSize={'sm'}
                                color={'#c5c5c5'}
                                title={(
                                    <>
                                        {renderMenuItemUi(item)}
                                    </>
                                )}
                                accordionTitleMinHeight={'37px'}
                                isExpanded={menuExpansions[item?.section + 'Expanded']}
                                headerPadding={'none'}
                                onChange={() => expand(item?.section)}
                                background={'none'}>

                                {item?.subSections.map((subItem, subindex) => {
                                    return (
                                        <div key={'subSection-' + subindex}
                                             onClick={closeDrawer}>
                                            <NavLink to={subItem?.route}
                                                     exact activeClassName="active-link">
                                                {renderMenuItemUi(subItem, true)}
                                            </NavLink>
                                        </div>
                                    );
                                })}
                            </DLAccordion>
                        </div>
                    );


                })}

        </div>
    );

};

const mapStateToProps = (state) => ({
    currentPathname: state.appState.currentPathname,
    prevLocation: state.appState.prevLocation,
});
export default connect(mapStateToProps, {
    // resetInboxFilter,
    // refreshFilterMails, resetListingMails,
    // setTestDataFilter, resetAccountlist
})(AppDrawerView);
