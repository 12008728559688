import * as React from 'react';
import PropTypes from 'prop-types';
import {alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import {visuallyHidden} from '@mui/utils';
import {Link} from "react-router-dom";
import {getTrimVal, numberWithCommas} from "../utils/helper";
import {isEmpty} from "../utils/validations";
import {getFundsTrimVal, getMarketValue} from "../utils/stocksHelper";
import {setStocksTablePage} from "./AdvanceSeachAction";
import {setAdvanceStockTableByOrderBy,setAdvanceStockTableByOrder,resetAdvanceStockTableByOrder , resetAdvanceStockTableByOrderBy, setAdvanceStockPage, setAdvanceStockRowsPerPage,resetAdvanceStockPage, resetAdvanceStockRowsPerPage} from "./AdvanceSeachAction"
import {connect} from "react-redux";
import {useEffect} from "react";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
    const {headingData, order, orderBy, onRequestSort, tableHeader, isXSView, isMobileView} = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const getLabel = (tableHeader, key) =>{
        const keys = Object.keys(tableHeader); // Get an array of keys
        const values = Object.values(tableHeader); // Get an array of values
        let keyIndex = keys.indexOf(key);

        if (!isEmpty(keyIndex)){
            return values[keyIndex]
        }
    };
    let minimumWidth;

    if (isXSView){
        minimumWidth = 240
    }else if (isMobileView){
        minimumWidth = 300
    }else {
        minimumWidth = 450
    }

    return (
        <TableHead>
            <TableRow>
                {headingData.map((heading, index) => (
                    <TableCell
                        key={heading.id}
                        sx={{width: 10, minWidth: index === 0 ? minimumWidth : 'max-content'}}
                        align={heading.numeric ? 'right' : 'left'}
                        padding={'normal'}
                        style={{position:index === 0 ? 'sticky' : '', left:0, zIndex:index === 0 ? 101 : 100}}
                        sortDirection={orderBy === heading.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === heading.id}
                            direction={orderBy === heading.id ? order : 'asc'}
                            onClick={createSortHandler(heading.id)}
                        >
                            <div className='flex-nowrap text-nowrap' style={{width:'max-content', textTransform: 'capitalize'}}>
                                {getLabel(tableHeader, heading.label)}
                            </div>

                            {orderBy === heading.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    headingData: PropTypes.array.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const extractColumnHeadings = (rowData) => {
    let headingData = [];
    Object.keys(rowData).forEach(function (key, index) {
        let numeric = true;
        if (isNaN(rowData[key])) {
            numeric = false
        }

        if (key !== 'isin_code'){
            let head = {
                id: key,
                label: key,
                numeric: numeric
            };
            switch (key) {
                case 'company_name':
                    headingData[0] = head;
                    break;
                case 'fund_count':
                    headingData[1] = head;
                    break;
                case 'total_market_value':
                    headingData[2] = head;
                    break;
                case 'quantity':
                    headingData[3] = head;
                    break;
                case 'percentage_to_market_cap':
                    headingData[4] = head;
                    break;
                case 'market_value_change_mom':
                    headingData[5] = head;
                    break;
                case 'percentage_market_value_change':
                    headingData[6] = head;
                    break;
                case 'net_buying_selling':
                    headingData[7] = head;
                    break;
                case 'net_buying_selling_percentage':
                    headingData[8] = head;
                    break;
                default:
            }
        }
    });
    return headingData;
};


const AdvanceTable = (props) => {
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(true);
    const isNavigatingBackRef = React.useRef(false);


    const {data,rowsPerPage,page,order,orderBy} = props;
    let headingData = extractColumnHeadings(data[0]);
    let rows = data;

    useEffect(()=>{
        if (props.history.action === "POP" || props.history.action === "REPLACE"){
            isNavigatingBackRef.current = true;
            //backward navigation

        }else {
            resetAdvanceStockTableByOrder();
            resetAdvanceStockTableByOrderBy();
            resetAdvanceStockPage();
            resetAdvanceStockRowsPerPage();
        }
    },[]);

    useEffect(()=>{
        if (!isNavigatingBackRef.current) {
                resetAdvanceStockPage();
                resetAdvanceStockRowsPerPage();
            } else {
            isNavigatingBackRef.current = false;
            }

    },[props.advanceSearchFilter]);



    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        const sortOrder = isAsc ? 'desc' : 'asc';
        setAdvanceStockTableByOrder(sortOrder);
        setAdvanceStockTableByOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setStocksTablePage(newPage);
        setAdvanceStockPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setAdvanceStockPage(0);
        setAdvanceStockRowsPerPage(parseInt(event.target.value, 10));
    };


    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    const renderRows = (row) => {
        Object.keys(row).forEach(function (key, index) {
            return (
                <div>
                    hello
                    <TableCell align="left">{row[key]}</TableCell>
                </div>
            );
        })
    };

    const {history, isLGView, isXLView, isMobileView, isXSView, tableHeader, storedPage} = props;
    return (
        <div className="w-100 pagination-container">
            <Box sx={{width: '100%'}}>
                <Paper sx={{width: '100%', mb: 0, boxShadow: "none"}}>
                    <TableContainer style={{maxHeight: isMobileView ? 'calc(100vh - 370px)' : 400}}>
                        <Table className=""
                               sx={{minWidth: 750}}
                               aria-labelledby="tableTitle"
                               size={dense ? 'small' : 'medium'}
                               stickyHeader>
                            <EnhancedTableHead
                                headingData={headingData}
                                order={order}
                                isXSView={isXSView}
                                orderBy={orderBy}
                                tableHeader={tableHeader}
                                isMobileView={isMobileView}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}/>
                            <TableBody>
                                {visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.fund_house_id + row.fund_id}
                                            selected={isItemSelected}
                                            sx={{cursor: 'pointer', border:'none', backgroundColor: (index % 2) === 0 ? '#F8F8FC': 'white'}}
                                            onClick={() => props.history.push('/stocks/'+row.isin_code)}>
                                            <TableCell  align="left" sx={{ border:'none', width: isXSView ? '150px' : '', fontSize: '12px !important', color: '#0073ff'}} style={{position: 'sticky', left:0, backgroundColor:(index % 2) === 0 ? '#F8F8FC': 'white'}}>
                                                     {getFundsTrimVal(row.company_name, isLGView, isXLView, isMobileView, isXSView)}
                                             </TableCell>
                                            <TableCell  sx={{ border:'none', fontSize: '12px !important'}}
                                                        align="right">{numberWithCommas((row.fund_count))}</TableCell>

                                            <TableCell  sx={{ border:'none', fontSize: '12px !important'}}
                                                        align="right">{numberWithCommas(getMarketValue(row.total_market_value))}</TableCell>
                                            <TableCell  sx={{ border:'none', fontSize: '12px !important'}}
                                                        align="right">{numberWithCommas(Math.trunc(row.quantity))}</TableCell>
                                            <TableCell  sx={{ border:'none', fontSize: '12px !important'}}
                                                        align="right">{row.percentage_to_market_cap.toFixed(2) + "%"}</TableCell>
                                            <TableCell  sx={{ border:'none', fontSize: '12px !important'}}
                                                        align="right">{numberWithCommas((row.market_value_change_mom)?.toFixed(2))}</TableCell>
                                            <TableCell  sx={{ border:'none', fontSize: '12px !important'}}
                                                        align="right">{row.percentage_market_value_change?.toFixed(2) + "%"}</TableCell>
                                            <TableCell  sx={{ border:'none', fontSize: '12px !important'}}
                                                        align="right">{numberWithCommas((row.net_buying_selling)?.toFixed(2))}</TableCell>
                                            <TableCell  sx={{ border:'none', fontSize: '12px !important'}}
                                                        align="right">{row.net_buying_selling_percentage?.toFixed(2) + "%"}</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}>
                                        <TableCell colSpan={6}/>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        </div>
    );
};

const mapStateToProps = (state) => ({
    order: state.AdvanceSearchState.order,
    orderBy: state.AdvanceSearchState.orderBy,
    page: state.AdvanceSearchState.page,
    rowsPerPage: state.AdvanceSearchState.rowsPerPage
});

export default connect(mapStateToProps, {})(AdvanceTable);

