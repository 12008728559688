import React, {useEffect, useState} from 'react'
import DLLoader from "../../core/components/DLLoader/DLLoader";
import DLText from "dlcomponent/components/DLText/DLText";
import DLButton from "dlcomponent/components/DLButton/DLButton";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import StocksChart from "./StocksChart";
import StocksTable from "./StocksTable";
import {withRouter} from "react-router-dom";
import CustomTabPanel from "../../component-utils/CustomTabPanel/CustomTabPanel";
import ChartRadioGroup from "../../component-utils/ChartRadioGroup/ChartRadioGroup";
import {downloadData, downloadDataByObject} from "../FundAction";
import {
    getChartData,
    getFixedValuesAfterDecimal,
    getIndexArr,
    getTrimVal,
    searchOnWordBoundaryOverview
} from "../../utils/helper";
import {isEmpty} from "../../utils/validations";
import moment from "moment";
import appTheme from "../../assets/appTheme";
import DLInputTextField from "dlcomponent/components/DLInputTextField/DLInputTextField";
import searchIcon from "../../assets/img/Advanced Search Icon.png";
import {downloadExcel} from "../../utils/downloadExcel";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function Stocks(props) {

    const {fundHouseId, fundId, selectedFund, isMobileView, isMDView} = props;
    const [chartLabelsArray, setChartLabelsArray] = useState([]);
    const [chartDataArray, setChartDataArray] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tabValue, setTabValue] = React.useState(0);
    const [percentage, setPercentage] = React.useState(0);
    const [chartType, setChartType] = useState('marketValue');
    const [tableHeader, setTableHeader] = useState([]);


    const [searched, setSearched] = useState("");
    const [isShowFilter, setIsShowFilter] = useState(false);
    const [filteredArray, setFilteredArray] = useState([]);

    useEffect(() => {
        if (props.tableData) {
            if (!isEmpty(props.tableData)) {
                const copiedArray = props.tableData.slice(1, props.tableData.length);
                setTableData(copiedArray);
                setTableHeader(props.tableData[0]);
                setStocksGraph(copiedArray);
            }
            setIsLoading(false);
        }
    }, [props.tableData]);

    const handleClick = (elementId) => {
        let indexArr = getIndexArr(chartType, filteredArray);

            let clickedStock = indexArr[elementId];
            props.history.push('/stocks/' + clickedStock.isin)
    };

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };


    const deepCopy = (obj) => {
        return JSON.parse(JSON.stringify(obj));
    }

    //order by descending
    const compareByMarketValueChangeMoM = (a, b) => {
        if (a.net_buying_selling < b.net_buying_selling) {
            return 1;
        }
        if (a.net_buying_selling > b.net_buying_selling) {
            return -1;
        }
        return 0;
    }

    const compareByMarketValueChangePercent = (a, b) => {
        if (a.net_buying_selling_percentage < b.net_buying_selling_percentage) {
            return 1;
        }
        if (a.net_buying_selling_percentage > b.net_buying_selling_percentage) {
            return -1;
        }
        return 0;
    }


    const handleRadioChange = (event) => {
        const {isXSView, isLGView, isXLView, isMobileView} = props;
        let chartType = event;
        let newChartData = [];
        let newLabelData = [];

        const filteredRows = filteredArray.filter((row) => {
            return row?.instrument_name.toLowerCase().includes(searched.toLowerCase());
        });

        let chartObj = getChartData(chartType, filteredRows, 'instrument_name', isLGView, isXLView, isMobileView);

        setChartType(chartType);
        setPercentage((chartType === 'changePercent' || chartType === 'changeSellingPercent'));
        setChartLabelsArray(chartObj?.newLabelData);
        setChartDataArray(chartObj?.newChartData);
    };


    const setStocksGraph = (data) => {
        const {isLGView, isXLView, isMobileView} = props;
        let chartLabels = [];
        let chartData = [];
        let filteredData = []
        let length = data.length;

        for (let i = 0; i < length; i++) {
            let stockName = getTrimVal(data[i].instrument_name, isLGView, isXLView, isMobileView);
            let marketValue = getFixedValuesAfterDecimal(data[i].total_market_value, 2);

            chartLabels.push(stockName);
            chartData.push(marketValue);
            filteredData.push(data[i])

        }
        let chartObj = getChartData(chartType, filteredData, 'instrument_name', isLGView, isXLView, isMobileView);
        setFilteredArray(filteredData)
        setChartLabelsArray(chartObj.newLabelData);
        setChartDataArray(chartObj.newChartData);
    };

    const getResult = (data) => {
        if (isEmpty(data)) {
            return
        }

        if (data?.length >= 10) {
            return (
                <div className='py-2 mx-3'>
                    Showing top 10 results
                </div>
            )
        } else {
            if (data.length === 1) {
                return (
                    <div className='py-2 mx-3'>
                        Showing {data?.length} result
                    </div>
                )
            } else {
                return (
                    <div className='py-2 mx-3'>
                        Showing {data?.length} results
                    </div>
                )

            }
        }
    };

    const requestSearch = (searchedVal) => {
        if (isEmpty(searchedVal)) {
            setSearched('');
            setStocksGraph(tableData)
        } else {
            let data = getFilteredGraphData(tableData, searchedVal)
            setSearched(searchedVal);
            setStocksGraph(data);
        }

    };

    const getFilteredGraphData = (data, searchedVal) => {
        const filteredRows = searchOnWordBoundaryOverview(searchedVal,data,"instrument_name")
        // const filteredRows = data.filter((row) => {
        //     return row.instrument_name.toLowerCase().includes(searchedVal.toLowerCase());
        // });

        return filteredRows;

    }

    const [isLoading, setIsLoading] = useState(true);

    const getFileName = (blockName) => {
        let date = new Date();
        return selectedFund?.fund_name + '-' + blockName + moment(date).format('YYYY-MM-DD')
    };

    const handleOnBlur = () => {
        if (isEmpty(searched)) {
            setIsShowFilter(false);
        }

    }

    const emptySearch = () => {
        requestSearch("")
        document.getElementById("businessList-keywordSearch-input").focus();
    }


    if (isLoading) {
        return (
            <div className={'row px-2 h-100'}>
                <div className={"col-12 d-flex flex-column justify-content-center align-items-center mb-1"}>
                    <DLLoader type={'inline'} isVisible={true}/>
                </div>
            </div>
        )
    }
    return (
        <div className='panel'
             id='fund-stocks'>
            <div className='d-flex justify-content-between px-3 pt-3'>
                <div className='d-flex  justify-content-center align-items-center'>
                    <DLText
                        id={'summary-by-country'}
                        type={'normal'}
                        text={'Stocks'}
                        fontWeight={'semi-bold'}
                        fontSize={'lg'}/>
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div className='ps-3 table-search'>
                            {(isShowFilter) ?
                                <DLInputTextField
                                    id={'businessList-keywordSearch'}
                                    label={''}
                                    rows={1}
                                    fullWidth
                                    styleType={"search"}
                                    placeholder={'Search...'}
                                    value={searched}
                                    onChangeValue={(searchVal) => requestSearch(searchVal)}
                                    isClearable={true}
                                    inputHeight={'sm'}
                                    autoFocus={true}
                                    onBlur={() => {
                                        handleOnBlur()
                                    }}
                                    marginBottom={"none"}
                                    InputProps={{
                                        endAdornment: (

                                            !isEmpty(searched) ?
                                                <i className="fa fa-remove m-2 cursor" onClick={() => {
                                                    emptySearch()
                                                }} style={{color: appTheme.appColor.black}}/>
                                                :
                                                <i className={'fas fa-search pr-1'}
                                                   style={{color: appTheme.appColor.black}}/>
                                        )
                                    }}
                                    disableHelperMessageHeight={true}
                                />
                                :
                                <i className="fa-solid fa-magnifying-glass cursor" onClick={() => {
                                    setIsShowFilter(true)
                                }}/>}
                        </div>
                    </div>
                </div>

                <div className="d-flex">
                    {
                        !isMobileView || isMDView ?
                            <DLButton id={'somethingWentWrong-refresh-button'}
                                      label={'Advanced Stock Search'}
                                      fontSize={'sm'}
                                      buttonSize={'xs'}
                                      style={{border: "0.5px solid var(--black)", color: "var(--black)"}}
                                      styleType={'outlined'}
                                      onClick={() => {
                                          props.history.push("/advanced-search", {selectedFund: selectedFund, stockFilter: searched})
                                      }}/>
                            :
                            <div className="mx-1">
                                <div className='d-flex justify-content-center align-items-center downloadButtonCss cursor p-2'
                                     onClick={() => {
                                         props.history.push("/advanced-search", {selectedFund: selectedFund, stockFilter: searched})
                                     }}
                                >
                                    <img src={searchIcon}
                                         height={19}
                                         width={18}
                                    />
                                </div>
                            </div>
                    }
                    <div className='ms-1' style={{width: 36, height: 36, minWidth: 'max-content'}}>
                        {/*<DLButton*/}
                        {/*    id={'download-excel'}*/}
                        {/*    styleType={"outlined"}*/}
                        {/*    borderType={'square'} buttonSize={'sm'}*/}
                        {/*    sideMargin={"none"}*/}
                        {/*    reduceWidth={true}*/}
                        {/*    style={{width: 36}}*/}
                        {/*    type={'primary'}*/}
                        {/*    onClick={() => {*/}
                        {/*        downloadData('/service/media/fund/stockSummary/download?date=' + VALUATION_DATE + '&fundHouseId=' + fundHouseId + '&fundId=' + encodeURIComponent(fundId), fundHouseId, 'Download', getFileName("Stocks Summary-"))*/}
                        {/*    }}*/}
                        {/*    label={<i className='fas fa-download fs-6'/>}*/}
                        {/*/>*/}
                        <div className='d-flex align-items-center justify-content-center downloadButtonCss cursor'>
                            <i className='fas fa-download fs-6'
                               onClick={() => {
                                   downloadData('/service/media/fund/stockSummary/download?date=' + VALUATION_DATE + '&fundHouseId=' + fundHouseId + '&fundId=' + encodeURIComponent(fundId), fundHouseId, 'Download', getFileName("Stocks Summary-"))
                               }}
                               style={{fontSize: 12}}/>
                        </div>
                    </div>
                </div>
            </div>
            <Box sx={{width: '100%'}}>
                <Box className='d-flex justify-content-between '
                     sx={{borderBottom: 1, borderColor: 'divider', marginLeft: '20px', marginRight: '20px'}}>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Chart" {...a11yProps(0)} sx={{textTransform: 'capitalize'}}/>
                        <Tab label="Table" {...a11yProps(1)} sx={{textTransform: 'capitalize'}}/>
                    </Tabs>

                </Box>
                <CustomTabPanel value={tabValue} index={0} id="tableTabPanel">
                    {(isEmpty(chartDataArray) || isEmpty(chartLabelsArray)) ?
                        <div className='d-flex flex-column' style={{minHeight: 300}}>
                            <ChartRadioGroup handleRadioChange={handleRadioChange} chartType={chartType}
                                             isXSView={props.isXSView} isSMView={props.isSMView}/>
                            <div className='d-flex align-items-center justify-content-center mx-3'
                                 style={{minHeight: 300}}>
                                <DLText
                                    id={'revenue-value'}
                                    type={'normal'}
                                    text={'No data found'}
                                    fontSize={'md'}/>
                            </div>
                        </div>
                        :
                        <>
                            <ChartRadioGroup handleRadioChange={handleRadioChange} chartType={chartType}
                                             isXSView={props.isXSView} isSMView={props.isSMView}/>
                            {getResult(chartDataArray)}
                            <StocksChart yAxis={chartLabelsArray} chartType={chartType} xAxis={chartDataArray}
                                         handleClick={handleClick} percentage={percentage}
                                         isMobileView={props.isMobileView}/>
                        </>
                    }
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1} id="tableTabPanel">
                    {(isEmpty(tableData) || isEmpty(tableHeader)) ?
                        <div className='d-flex align-items-center justify-content-center' style={{minHeight: 500}}>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={'No data found'}
                                fontSize={'md'}/>
                        </div>
                        :
                        <StocksTable data={tableData} {...props} tableHeader={tableHeader} searched={searched}/>
                    }
                </CustomTabPanel>
            </Box>

        </div>
    )

}

export default withRouter(Stocks);
