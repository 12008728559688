import React from 'react';
import DLModal from "dlcomponent/components/DLModal/DLModal";
import DLModalTitle from "dlcomponent/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "dlcomponent/components/DLModal/DLModalContainer/DLModalContainer";
import DLText from "dlcomponent/components/DLText/DLText";
import DLModalActions from "dlcomponent/components/DLModal/DLModalActions/DLModalActions";
import DLButton from "dlcomponent/components/DLButton/DLButton";
import {isEmpty} from "../../utils/validations";
import DLLoader from "../../core/components/DLLoader/DLLoader";
import {advanceSearchConst} from "../AdvanceSearchConst";
import {getTrimmedValue, getTrimVal, numberWithCommas} from "../../utils/helper";
import DLInputTextField from "dlcomponent/components/DLInputTextField/DLInputTextField";
import DLCheckbox from "dlcomponent/components/DLCheckbox/DLCheckbox";
import appTheme from "../../assets/appTheme";
import {setFundList} from "../AdvanceSeachAction";
import {getShowingResultCountTextForFundModal} from "../../utils/stocksHelper";

function AdvanceFundListingModalView(props) {
    const {fundsKeyword, setFilter, searchResultNotAvailable, isSMView, previousFundListing, isFundModalOpen, clearSelectedFunds, checkSelectedValues, sortedData, checkboxChange, onChangeValue, handleCheckboxChange, modalUIState, fundListingData, isLGView, isXLView, isMobileView, isXSView, onChangeKeywordFilter} = props;
    let data = isEmpty(sortedData) ? fundListingData : sortedData;

    const renderFundListingUI = () => {
        if (searchResultNotAvailable){
            return(
                <div className='d-flex align-items-center px-3' style={{paddingTop: 35}}>
                    <DLText
                        id={'revenue'}
                        type={'normal'}
                        text={'No data found.'}
                        fontColor={'lightGray'}
                        fontSize={'xs'}/>
                </div>
            )
        }
        return data.slice(0, 20).map((item, index) => {

            return (
                <div className='col-xl-6  col-12 py-1'>
                    <DLCheckbox id={'scheduleUpload-weeklyUi-dayCheck-' + index}
                                label={getTrimmedValue(item?.fund_name, isXLView ? 45 : 70)}
                                isChecked={checkboxChange(item)}
                                disableHelperMessageHeight={true}
                                labelColor={appTheme.appColor.black}
                                onChangeValue={() => handleCheckboxChange(item)}
                                labelPlacement={'end'}
                                marginBottom={'none'}
                    />
                </div>
            )

        })
    };

    const renderSelectedValues = (selectedValues) =>{
        const {} = props;

        if (isEmpty(selectedValues)){
            return (
                <div className='d-flex align-items-center px-3' style={{paddingTop: 6}}>
                    <DLText
                        id={'revenue'}
                        type={'normal'}
                        text={'Nothing is selected yet.'}
                        fontColor={'lightGray'}
                        fontSize={'xs'}/>
                </div>
            )
        }

        return selectedValues.map((item, index)=>{
            return (
                <div className='px-3 py-1 d-flex justify-content-between align-items-center'>
                    <div className=''>
                        <DLText
                            id={'revenue'}
                            type={'normal'}
                            text={getTrimmedValue(item?.fund_name, isLGView ? 45 :28)}
                            fontSize={'xs'}/>
                    </div>
                    <i className="fa fa-times cursor ms-3"
                       onClick={() => {
                           handleCheckboxChange(item)
                       }}/>
                </div>
            )
        })
    };

    const renderValuesSelectedUI = () => {
        let selectedValues = checkSelectedValues();

        return (
            <div>
                <div className='px-3 d-flex justify-content-between'>
                    <DLText
                        id={'revenue'}
                        type={'normal'}
                        text={'Selected'}
                        fontColor={'lightGray'}
                        fontWeight={'semi-bold'}
                        fontSize={'xs'}/>
                    {!isEmpty(selectedValues) &&

                    <DLText
                        id={'revenue'}
                        type={'normal'}
                        isClickable={true}
                        text={'Clear'}
                        customTextStyle={{
                            color: appTheme.appColor.lightGray
                        }}
                        onClick={()=> clearSelectedFunds()}
                        fontSize={'xs'}/>
                    }

                </div>
                <div style={{
                    maxHeight: isMobileView ? 'calc(100vh - 338px)' : 'calc(100vh - 240px)',
                    minHeight: isMobileView ? 'calc(100vh - 338px)' :  'calc(100vh - 240px)',
                    overflowY: 'auto'
                }}>
                    {renderSelectedValues(selectedValues)}
                </div>
            </div>
        )
    };

    const renderFundsModalMobileUI = () => {
        let selectedValues = checkSelectedValues();

        if (isEmpty(modalUIState)) {
            return (
                <div style={{minHeight: 300}}>
                    <DLLoader type={"inline"} isVisible={true}/>
                </div>
            )
        }

        if (modalUIState === advanceSearchConst.CONTENT_UNAVAILABLE) {
            return (
                <div>
                    <DLText
                        id={'revenue'}
                        type={'normal'}
                        text={'No data found.'}
                        fontColor={'lightGray'}
                        fontSize={'sm'}/>
                </div>
            )
        }

        return (
            <div>
                    <div className='w-100'>
                        <div className='px-3'>
                            <DLInputTextField
                                id={'businessList-keywordSearch'}
                                label={''}
                                rows={1}
                                fullWidth
                                styleType={"search"}
                                placeholder={'Search...'}
                                value={fundsKeyword}
                                onChangeValue={(e) => onChangeKeywordFilter(e)}
                                isClearable={true}
                                autoFocus={true}
                                inputHeight={'sm'}
                                marginBottom={"none"}
                                InputProps={{
                                    endAdornment: (
                                        fundsKeyword ?
                                            <i className="fa fa-remove m-2 cursor" onClick={() => {
                                                onChangeKeywordFilter("")
                                            }} style={{color: appTheme.appColor.black}}/>
                                            :
                                            <i className={'fas fa-search pr-1'} style={{color: appTheme.appColor.black}}/>
                                    )
                                }}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        document?.activeElement?.blur()
                                    }
                                }}
                                disableHelperMessageHeight={true}
                            />
                        </div>
                        <div className=''>
                            <div className='px-3 d-flex justify-content-between' style={{paddingTop: 4}}>
                                {!searchResultNotAvailable &&
                                <DLText
                                    id={'revenue-value'}
                                    type={'normal'}
                                    fontColor={'lightGray'}
                                    text={getShowingResultCountTextForFundModal(data.slice(0, data.length))}
                                    fontSize={'xs'}/>
                                }
                                {!isEmpty(selectedValues) &&
                                <i className="fa fa-times cursor ms-3"
                                   onClick={() => {
                                       clearSelectedFunds()
                                   }}/>
                                }
                            </div>
                            <div className='row mx-0 d-block'  style={{
                                maxHeight: 'calc(100vh - 190px)',
                                minHeight: 'calc(100vh - 190px)',
                                overflowY: 'auto'
                            }}>
                            {renderFundListingUI()}
                            </div>

                            <div  className='d-flex w-100 bg-white'
                                  style={{position: 'fixed', bottom:0}}>
                                <div className='w-100 d-flex align-items-center'
                                     style={{ boxShadow: '1px 0px 4px 0px rgba(0, 0, 0, 0.20)', height: 54}}>
                                <div className='d-flex justify-content-center border-end' style={{flex:1}}>
                                    <DLText
                                        id={'revenue'}
                                        type={'normal'}
                                        text={'Close'}
                                        isClickable={true}
                                        customTextStyle={{
                                            color: appTheme.appColor.black
                                        }}
                                        onClick={() => {
                                            onChangeKeywordFilter("");
                                            setTimeout(() => {
                                                onChangeValue("isFundModalOpen", false);
                                                setFundList(previousFundListing);
                                            }, 50)
                                        }}
                                        marginBottom={'none'}
                                        fontSize={'sm'}/>
                                </div>
                                <div className='d-flex justify-content-center' style={{flex:1}}>
                                    <DLText
                                        id={'revenue'}
                                        type={'normal'}
                                        text={'Apply'}
                                        isClickable={true}
                                        customTextStyle={{
                                            color: appTheme.appColor.black
                                        }}
                                        onClick={() => {
                                            onChangeKeywordFilter("");
                                            setTimeout(() => {
                                                onChangeValue("isFundModalOpen", false);
                                                setFilter('funds', selectedValues, 'fund_id')
                                            }, 50)
                                        }}
                                        marginBottom={'none'}
                                        fontSize={'sm'}/>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
            </div>
        )
    };


    const renderFundsModalDesktopUI = () => {

        if (isEmpty(modalUIState)) {
            return (
                <div style={{minHeight: 300}}>
                    <DLLoader type={"inline"} isVisible={true}/>
                </div>
            )
        }

        if (modalUIState === advanceSearchConst.CONTENT_UNAVAILABLE) {
            return (
                <div>
                    <DLText
                        id={'revenue'}
                        type={'normal'}
                        text={'No data found.'}
                        fontColor={'lightGray'}
                        fontSize={'sm'}/>
                </div>
            )
        }

        return (
            <div>
                <div className='d-flex'>
                    <div className=''
                         style={{
                             minWidth: isLGView ? 350 : 260,
                             maxWidth: isLGView ? 350 : 260,
                             maxHeight: isMobileView ? 'calc(100vh - 316px)' : 'calc(100vh - 220px)',
                             minHeight: isMobileView ? 'calc(100vh - 316px)' :  'calc(100vh - 220px)',
                             borderRight: '1px dashed ',
                             marginTop: 44,
                             marginBottom: 16
                         }}>
                        {renderValuesSelectedUI()}
                    </div>
                    <div className='w-100'>
                        <div className='px-3'>
                            <DLInputTextField
                                id={'businessList-keywordSearch'}
                                label={''}
                                rows={1}
                                fullWidth
                                styleType={"search"}
                                placeholder={'Search...'}
                                value={fundsKeyword}
                                onChangeValue={(e) => onChangeKeywordFilter(e)}
                                isClearable={true}
                                inputHeight={'sm'}
                                autoFocus={true}
                                marginBottom={"none"}
                                InputProps={{
                                    endAdornment: (
                                        fundsKeyword ?
                                            <i className="fa fa-remove m-2 cursor" onClick={() => {
                                                onChangeKeywordFilter("")
                                            }} style={{color: appTheme.appColor.black}}/>
                                            :
                                            <i className={'fas fa-search pr-1'} style={{color: appTheme.appColor.black}}/>
                                    )
                                }}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        document?.activeElement?.blur()
                                    }
                                }}
                                disableHelperMessageHeight={true}
                            />
                        </div>
                        <div className=''>
                            {!searchResultNotAvailable &&
                            <div className='px-3' style={{paddingTop: 4}}>
                                <DLText
                                    id={'revenue-value'}
                                    type={'normal'}
                                    fontColor={'lightGray'}
                                    text={getShowingResultCountTextForFundModal(data.slice(0, data.length))}
                                    fontSize={'xs'}/>
                            </div>
                            }
                            <div className='row mx-0'  style={{
                                maxHeight: isMobileView ? 'calc(100vh - 340px)' :  'calc(100vh - 240px)',
                                overflowY: 'auto'
                            }}>
                            {renderFundListingUI()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    if (isFundModalOpen){
        let selectedValues = checkSelectedValues();

        return (
            <div className='advanceSearchContainer w-100' style={{
                position: (isXSView || isSMView) ? 'fixed' :  'absolute',
                top:(isXSView || isSMView) ? 0 :isMobileView ? 110 : 70,
                zIndex:9999
            }}>
                <div className='bg-white advanceSearchModal' style={{
                    width: (isXSView || isSMView) ? '100%' : 'calc(100vw - 180px)',
                    borderRadius: '7px',
                    minHeight: (isXSView || isSMView) ? '100vh' : isMobileView ? 'calc(100vh - 200px)' : 'calc(100vh - 100px)',
                    maxHeight: (isXSView || isSMView) ? '100vh' : isMobileView ? 'calc(100vh - 200px)' :  'calc(100vh - 100px)',
                    boxShadow: '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)'
                }}>
                    <div className='d-flex justify-content-between p-3 align-items-center'>
                        <div className='d-flex align-items-center'>
                            <DLText
                                id={'revenue'}
                                type={'normal'}
                                text={'Funds'}
                                marginBottom={'none'}
                                fontSize={'lg'}/>
                            <div className='ps-2'>
                                <DLText
                                    id={'revenue'}
                                    type={'normal'}
                                    marginBottom={'none'}
                                    text={"(" + numberWithCommas(selectedValues?.length) + '/' + numberWithCommas(fundListingData.length) + ")"}
                                    fontSize={'xxs'}/>
                            </div>
                        </div>
                        {(isXSView || isSMView) ?
                            <></>
                            :
                            <div className=''>
                                <DLButton id={'dataImport-automaticImport-modalActions-save'}
                                          label={'Apply'}
                                          buttonSize={"sm"}
                                          fontSize={"sm"}
                                          styleType={'outlined'}
                                          sideMargin={"sm"}
                                          onClick={() => {
                                              onChangeKeywordFilter("");
                                              setTimeout(() => {
                                                  onChangeValue("isFundModalOpen", false);
                                                  setFilter('funds', selectedValues, 'fund_id')
                                              }, 50)
                                          }}/>
                                <i className="fa fa-times cursor ms-3"
                                   onClick={() => {
                                       onChangeKeywordFilter("");
                                       setTimeout(() => {
                                           onChangeValue("isFundModalOpen", false);
                                           setFundList(previousFundListing);
                                       }, 50)

                                   }}/>
                            </div>
                        }
                    </div>
                    {(isXSView || isSMView) ? renderFundsModalMobileUI() : renderFundsModalDesktopUI()}
                </div>
            </div>
        );
    }
}

AdvanceFundListingModalView.propTypes = {};

export default AdvanceFundListingModalView;