import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {stocksConst} from "../../stocks/stocksConst";
import DLText from "dlcomponent/components/DLText/DLText";
import {isEmpty} from "../../utils/validations";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// TODO use lazy load for each component which is firing the api
function CompareHeadingView(props) {

    const {isMDView, UIState, advanceSearchFilter, firstStockName, secondStockName, thirdStockName, isLGView} = props;

    const renderDesktopView = () => {

        return (
            <div className='mb-3'>
                <div className="d-flex justify-content-start align-items-center p-2" style={{minHeight: isMDView? "62px" : "50px", backgroundColor: "#F8F8FC"}}>
                    <DLText
                        id={'revenue-value'}
                        type={'normal'}
                        fontWeight={"semi-bold"} t
                        text={"Fund Houses"}
                        fontSize={'sm'}/>
                </div>
                <div className="d-flex justify-content-start align-items-center p-2" style={{minHeight: "50px", backgroundColor: ""}}>
                    <DLText
                        id={'revenue-value'}
                        type={'normal'}
                        fontWeight={"semi-bold"} t
                        text={"Funds"}
                        fontSize={'sm'}/>
                </div>
                <div className="d-flex justify-content-start align-items-center p-2" style={{minHeight: "50px", backgroundColor: "#F8F8FC"}}>
                    <DLText
                        id={'revenue-value'}
                        type={'normal'}
                        fontWeight={"semi-bold"} t
                        text={"Market Cap"}
                        fontSize={'sm'}/>
                </div>
                <div className="d-flex justify-content-start align-items-center p-2" style={{minHeight: "62px", backgroundColor: ""}}>
                    <DLText
                        id={'revenue-value'}
                        type={'normal'}
                        fontWeight={"semi-bold"} t
                        text={"Market Cap Category"}
                        fontSize={'sm'}/>
                </div>
                <div className="d-flex justify-content-start align-items-center p-2" style={{minHeight: "260px", backgroundColor: "#F8F8FC"}}>
                    <DLText
                        id={'revenue-value'}
                        type={'normal'}
                        fontWeight={"semi-bold"} t
                        text={"AUM"}
                        fontSize={'sm'}/>
                </div>
                <div className="d-flex justify-content-start align-items-center p-2" style={{minHeight: "260px", backgroundColor: ""}}>
                    <DLText
                        id={'revenue-value'}
                        type={'normal'}
                        fontWeight={"semi-bold"} t
                        text={"Net Buying Selling"}
                        fontSize={'sm'}/>
                </div>
                <div className="d-flex justify-content-start align-items-center p-2" style={{minHeight: "400px", backgroundColor: "#F8F8FC"}}>
                    <div className='w-100'>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={isEmpty(advanceSearchFilter?.fundHouses)? "Top 10 Fund Houses" : "Fund Houses"}
                            fontWeight={'semi-bold'}
                            fontSize={'sm'}/>
                    </div>
                </div>
                <div className="d-flex justify-content-start align-items-center p-2" style={{minHeight: "400px", backgroundColor: ""}}>
                    <div className='w-100'>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={isEmpty(advanceSearchFilter?.funds)? "Top 10 Funds" : "Funds"}
                            fontWeight={'semi-bold'}
                            fontSize={'sm'}/>
                    </div>
                </div>
            </div>
        )
    };

    const getHeadingHeight = () => {
        if(isMDView || isLGView){
            if (firstStockName.length > 25 || secondStockName.length > 25 || thirdStockName.length > 25) {
                return "80px";
            } else {
                return "54px";
            }

        }else{
            return "54px";
        }
    }


    const renderUI = () => {
        return renderDesktopView();
    };

    if (UIState === stocksConst.CONTENT_UNAVAILABLE) {
        return
    }

    return (
        <>
            <div className="bg-white " style={{ borderRight: "1px solid #CDCDCD"}}>
                <div style={{ position: 'sticky', top: 0, backgroundColor: 'white',zIndex:1}}>
                    <div className="d-flex justify-content-start align-items-start p-2 w-100"
                         style={{minHeight: getHeadingHeight()}}>

                    </div>
                </div>
                <div id={'myDiv'}>
                    <div className=''>
                        {renderUI()}
                    </div>
                </div>
            </div>
        </>
    );
}

CompareHeadingView.propTypes = {};

export default CompareHeadingView;