import React from 'react';

import {isEmpty} from "../../../utils/validations";
import {
    getDataArray,
    getFixedValuesAfterDecimal,
   getTrimmedValue,
    numberWithCommas,
} from "../../../utils/helper";
import {stocksConst} from "./stocksConst";
import DLText from "dlcomponent/components/DLText/DLText";
import PopComponent from "../../../stocks/PopComponent";
import {filterAumValue, getColor} from "../../../utils/stocksHelper";
import EChartDougnute from "../../../component-utils/EChart/EChartDougnute";
import EChartPieChart from "../../../component-utils/EChart/EChartPieChart";
import Sector from "../Stocks/Sector/Sector";
import LineBarChartMixed from "../../../component-utils/charts/LineBarChartMixed";


// TODO use lazy load for each component which is firing the api
function StocksView(props) {

    const {
        isXLView,
        isLGView,
        isXSView,
        isMDView,
        UIState,
        handleOpenModal,
        isSMView,
        isinList,
        isMobileView
    } = props;


     const getDataArrayForStocks = (valuesArr, name, valueAttribute) =>{
        let finalData = [];
        let otherMarketValues = 0;
        let count = 0;
        if (isEmpty(valuesArr)){
            return []
        }

        valuesArr.forEach((item)=>{
            if (isEmpty(item[name])){
                otherMarketValues = otherMarketValues + item[valueAttribute];
                count = count + item?.count
            }else {
                if (name === 'industry'){
                    finalData.push({
                        id:'block-'+ item[name],
                        name: item[name],
                        value: getFixedValuesAfterDecimal(item[valueAttribute], 2),
                         count: item?.count
                    })
                }else {
                    finalData.push({
                        name: item[name],
                        value: getFixedValuesAfterDecimal(item[valueAttribute], 2),
                           count: item?.count
                    })
                }
            }
        });

        if (otherMarketValues !== 0){
            finalData.push({id:'block-others',name: 'Others', value: getFixedValuesAfterDecimal(otherMarketValues,2), count: count});
        }

        return finalData
    };
    const getPieData = (data) => {
        let listingData = data.slice(0, 9);
        let isListingIncludeOther = listingData.find((item) => item.name === 'Others');
        if (isEmpty(isListingIncludeOther)) {
            let others = data.find(item => item?.name === 'Others');
            listingData.push(others);
        }
        return listingData
    };

    const getLegendsForPieChart = (data, sliceMin, sliceMax) => {
        let listingData = getPieData(data);

        return listingData.slice(sliceMin, sliceMax).map((item, index) => {
            return (
                <div className='d-flex align-items-center' style={{minWidth: 'max-content'}}>
                    <div style={{paddingTop: 3}}>
                        <div style={{
                            width: '8px',
                            height: '8px',
                            borderRadius: 7,
                            backgroundColor: getColor(sliceMin + index), // You can set the background color as needed
                        }}/>
                    </div>
                    <div className='ps-1'>
                        <DLText text={getTrimmedValue(item?.name, 12)}
                                marginBottom={"none"}
                                id={'page-not-found-oops'}
                                type={"normal"}
                                fontSize={"xxs"}/>
                    </div>
                </div>
            )
        })
    };
    const getLegends = (data) => {
        return data.map((item, index) => {
            return (
                <div className=' d-flex align-items-center'>
                    <div style={{paddingTop: 3}}>
                        <div style={{
                            width: '8px',
                            height: '8px',
                            borderRadius: 7,
                            backgroundColor: getColor(index), // You can set the background color as needed
                        }}/>
                    </div>
                    <div className='ps-1' style={{minWidth: 'max-content'}}>
                        <DLText text={item.name}
                                marginBottom={"none"}
                                id={'page-not-found-oops'}
                                type={"normal"}
                                fontSize={"xxs"}/>
                    </div>
                </div>
            )
        })
    };




    const getBarClick = (e) => {
        const {xAxis} = props;
        if (isEmpty(e)) {
            return;
        }
        let graphMonth = xAxis.find((item, index) => {
            return index === e;
        });
        handleOpenModal(graphMonth)

    };


    const renderPieChartUI = () => {
        if (isSMView || isXSView) {
            return (
                <div className='d-flex flex-column align-items-center mb-3'>
                    <EChartPieChart {...props}
                                    data={getPieData(getDataArrayForStocks(props.businessHouse, 'business_house', 'market_value'))}/>
                    <div className='d-flex flex-wrap text-wrap gap-2 justify-content-center'>
                        {getLegendsForPieChart(getDataArray(props.businessHouse, 'business_house', 'market_value'), 0, 10)}
                    </div>
                </div>
            )
        }

        return (
            <div className='d-flex flex-column align-items-center'>
                <EChartPieChart {...props}
                                data={getPieData(getDataArrayForStocks(props.businessHouse, 'business_house', 'market_value'))}/>
                <div className='d-flex flex-wrap text-wrap gap-2 justify-content-center'>
                    {getLegendsForPieChart(getDataArray(props.businessHouse, 'business_house', 'market_value'), 0, 10)}
                </div>
            </div>
        )
    };
    const renderDesktopTreemapView = () => {

        return (
            <div className="d-flex" style={{gap: 20, marginTop: 20, marginBottom: 20}}>
                <div className="panel p-3" style={{flex: 1}}>
                    <div className="d-flex flex-column h-100">
                        <DLText text={'Market Cap'} fontSize={'lg'} fontWeight={'semi-bold'} marginBottom={'sm'}/>

                        <div className="flex-grow-1 d-flex justify-content-center align-items-center">
                            {/*{isEmpty(props.marketCapCategory) ? (*/}
                            {/*    'No data found'*/}
                            {/*) : (*/}
                            {/*    <div className="d-flex flex-column align-items-center">*/}
                            {/*        <EChartDougnute {...props}*/}
                            {/*                        data={getDataArrayForStocks(props.marketCapCategory, 'category', 'market_value')}/>*/}

                            {/*        <div className="d-flex justify-content-center flex-wrap gap-2">*/}
                            {/*            {getLegends(getDataArrayForStocks(props.marketCapCategory, 'category', 'market_value'))}*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                            {
                                isEmpty(props.marketCapCategory) ?
                                    <div className='d-flex justify-content-center align-items-center h-100'>
                                        No data found
                                    </div>
                                    :
                                    <div className='d-flex  flex-column align-items-center'>
                                            <EChartDougnute {...props}
                                                            data={getDataArray(props.marketCapCategory, 'category', 'market_value')}/>

                                        <div className='d-flex justify-content-center flex-wrap gap-2'>
                                            {getLegends(getDataArray(props.marketCapCategory, 'category', 'market_value'))}
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="panel p-3" style={{flex: 1}}>
                    <div className="d-flex flex-column h-100">
                        <DLText text={'Business Houses'} fontSize={'lg'} fontWeight={'semi-bold'}
                                marginBottom={'sm'}/>
                        <div className="flex-grow-1 d-flex justify-content-center align-items-center">
                            {isEmpty(props.businessHouse) ? (
                                'No data found'
                            ) : (
                                renderPieChartUI()
                            )}
                        </div>
                    </div>
                </div>

            </div>

        );
    };


    const renderMobileTreemapView = () => {
        return (
            <div className='d-flex flex-column justify-content-between'
                 style={{flex: 5, alignItems: isXSView && 'center'}}>

                {/*<div className='panel px-3 pt-sm-3 pb-3 w-100 mb-sm-3' >*/}
                {/*    <DLText text={'Sectors'}*/}
                {/*            fontSize={'md'}*/}
                {/*            fontWeight={'semi-bold'}*/}
                {/*            marginBottom={'md'}/>*/}
                {/*    <EChartTreemap {...props} data={getDataArray(props.industry, 'industry', 'market_value')}/>*/}
                {/*</div>*/}
                <div className={'d-flex flex-md-row flex-column w-100'}>
                    <div className={(isXSView) ? 'p-3 w-100' : 'panel p-3 w-100 me-3 mb-3 mb-md-0'}>
                        <div className="d-flex flex-column h-100">
                            <DLText text={'Market Cap'}
                                    fontSize={'lg'}
                                    fontWeight={'semi-bold'}
                                    marginBottom={'sm'}/>
                            <div className="flex-grow-1">
                                {
                                    isEmpty(props.marketCapCategory) ?
                                        <div className='d-flex justify-content-center align-items-center h-100'>
                                            No data found
                                        </div>
                                        :
                                        <div className='d-flex  flex-column align-items-center'>

                                            <div className='d-flex justify-content-center'>
                                                <EChartDougnute {...props}
                                                                data={getDataArray(props.marketCapCategory, 'category', 'market_value')}/>
                                            </div>

                                            <div className='d-flex justify-content-center gap-2'>
                                                {getLegends(getDataArray(props.marketCapCategory, 'category', 'market_value'))}
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    {isXSView ? <div style={{height: '10px', backgroundColor: '#C5C5C5'}}/> : <></>}
                    <div className={(isXSView) ? 'p-3 w-100' : 'panel p-3 w-100'}
                         style={{paddingLeft: (isXSView) ? 0 : 26}}>
                        <div className="d-flex flex-column h-100">
                            <DLText text={'Business Houses'}
                                    fontSize={'lg'}
                                    fontWeight={'semi-bold'}
                                    marginBottom={'sm'}/>
                            <div className="flex-grow-1">
                                {
                                    isEmpty(props.businessHouse) ?
                                        <div className='d-flex justify-content-center align-items-center h-100'>
                                            No data found
                                        </div>
                                        :
                                        renderPieChartUI()
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderSummaryUI = () => {
        const {
            isMobileView,
            isXSView,
            fundSchemeCount,
            marketValue
        } = props;

        return (

            <div className='d-flex flex-column w-100'  id={'section1'}>
                <div className={ "d-flex" }>
                    <div className='panel px-3 pt-sm-3 pt-0 w-100' style={{width: '60%'}}>
                        <div className='flex-shrink-1 w-100'>

                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={'Overview'}
                                fontWeight={'semi-bold'}
                                fontSize={'lg'}
                                marginBottom={'md'}
                            />
                            <div className='d-flex flex-column w-100 '>
                                <div className={isXLView ? 'd-flex flex-row' : 'd-flex flex-column'}
                                     style={{width: '100%'}}>

                                    <div className={isXLView ? 'd-flex flex-column mb-3' : 'd-flex flex-row mb-3' } style={{width:(isLGView || isMobileView) ? '100%' : '15%'}}>
                                        <div className={(isLGView || isMobileView) ? 'border-end py-1 h-100' : 'border-bottom py-3 pb-md-0'}
                                             style={{flex: (isLGView || isMobileView) ? 1 : '', height: isMobileView ? '100%' : '30%'}}>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={'AUM'}
                                                fontSize={'sm'}/>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={numberWithCommas(filterAumValue(marketValue))}
                                                fontWeight={'bold'}
                                                fontSize={'md'}/>
                                        </div>
                                        <div className={(isLGView || isMobileView) ? 'd-flex flex-column justify-content-end py-1 ps-3' : 'd-flex flex-column justify-content-end pt-3 pt-md-0'}
                                             style={{flex: (isLGView || isMobileView) ? 1 : '', height: "30%"}}>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={'Funds'}
                                                fontSize={'sm'}/>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={fundSchemeCount}
                                                fontWeight={'bold'}
                                                fontSize={'md'}/>
                                        </div>
                                    </div>
                                    <div style={{
                                        marginBottom: "20px",
                                        width: (isLGView || isMobileView) ? '100%' : '95%',
                                        paddingTop: (isLGView || isMobileView) ? '10px' : '0px'
                                    }}>
                                        <LineBarChartMixed {...props} isBarChartClickable={false}
                                                           hideMoneyflowExplorer={true}
                                                           getBarClick={getBarClick} isFromFund={true} hideCSS={true}/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {isXSView ? <div style={{height: '10px', backgroundColor: '#C5C5C5'}}/> : <></>}
                <div className={isXLView ? "pt-0" : 'pt-3'}
                     style={{width: (isLGView || isMobileView) ? '100%' : '100%'}}>
                    {(isLGView || isMobileView) ? renderMobileTreemapView() : renderDesktopTreemapView()}
                </div>

                {isXSView ? <div style={{height: '10px', backgroundColor: '#C5C5C5'}}/> : <></>}

            </div>

    )
    };


    const renderDesktopView = () => {


        return (
            <div>
                <div className={isMDView ? 'mt-3' : ' ' }>
                    {renderSummaryUI()}
                </div>
                <div  id={'section3'} className={ isMDView ? 'pb-4 pt-3' : 'pb-4'} style={{marginBottom: (isMobileView )? "100px" : 0}}>
                    <Sector isinList={isinList} {...props}
                           />
                </div>

            </div>


        );
    };

    const renderUI = () => {
        return renderDesktopView();
    };

    if (UIState === stocksConst.CONTENT_UNAVAILABLE) {
        return
    }
    // if (isEmpty(selectedStock?.company_name)) {
    //     return <DLLoader type={'screen'} isVisible={true}/>
    // }


   // props.handleChange();

    return (
        <>
            <div className="bg-white ">
                <div id={'myDiv'} >
                    <div className='px-sm-3'>
                        {renderUI()}
                        {
                            <PopComponent {...props}/>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

StocksView.propTypes = {};

export default StocksView;
