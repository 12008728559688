import BarChart from "../../component-utils/charts/BarChart";
import React from "react";
import DLText from "dlcomponent/components/DLText/DLText";
import {isEmpty} from "../../utils/validations";
import appTheme from "../../assets/appTheme";
import EChartTreemap from "../../component-utils/EChart/EChartTreemap";
import {getDataArray} from "../../utils/helper";
import HorizontalBarChart from "../../component-utils/charts/HorizontalBarChart";

const IndustryChart = (props) => {

    const {xAxis, yAxis, handleClick, percentage, isMobileView, chartType} = props;


    const getBarChartValues = () => {
        let data = {
            name: yAxis.slice(0, 10).reverse(),
            data: xAxis.slice(0, 10).reverse(),
            emphasis: {
                focus: 'series'
            },
            barGap: 0.2,
            barWidth: '18px',
            itemStyle: {
                color: (params) => (params.value >= 0 ? '#029C48' : '#D72F2F'),
            },
            // color: getBorderColorForBar(getDataOnly(values.map(innerArray => innerArray[i]), 'total_market_value_change_due_to_activity')),
            type: 'bar',
        };
        return data;
    };

    const handleChartClick = (params) => {
        const clickedLine = params.name;
        if (clickedLine === 'Others'){
            return;
        }
        props.history.push('/sectors/' + clickedLine)
    };

    if(chartType === "marketValue"){
        return(
            <div className='mx-3 mx-0 mb-3' style={{maxWidth: window.innerWidth > 900 ? '900px' : '100%'}}>
                <EChartTreemap {...props}
                               handleChartClick = {handleChartClick}
                               requestedHeight={"350px"} data={getDataArray(props.industry, 'industry', 'market_value')}/>
            </div>
        );
    }

    return (
        <div className='mx-sm-3 mb-2 mx-0' style={{maxWidth: window.innerWidth > 800 ? '800px' : '100%'}}>
            <HorizontalBarChart {...props}
                                id={"fund-houses-industries-summary-echart"}
                                series={getBarChartValues(xAxis)}
                                yAxislabels={yAxis.slice(0, 10).reverse()}
                                showDownloadIcon={false}
                                yAxisPosition={"left"}
                                isBarChartClickable={true}
                                onClick={handleClick}
                                percentage={percentage}
            />
        </div>

    )
}

export default IndustryChart;