import React, {useEffect, useState} from 'react'
import DLLoader from "../../core/components/DLLoader/DLLoader";
import DLButton from "dlcomponent/components/DLButton/DLButton";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SummaryByFundHouseChart from "./SummaryByFundHouseChart";
import SummaryByFundHouseTable from "./SummaryByFundHouseTable";
import CustomTabPanel from "../../component-utils/CustomTabPanel/CustomTabPanel";
import ChartRadioGroup from "../../component-utils/ChartRadioGroup/ChartRadioGroup";
import {withRouter} from "react-router-dom";
import {
    getChartData,
    getFixedValuesAfterDecimal,
    getIndexArr,
    getTrimVal,
    searchOnWordBoundaryOverview
} from "../../utils/helper";
import {downloadData} from "../../fund/FundAction";
import moment from "moment";
import {isEmpty} from "../../utils/validations";
import DLText from "dlcomponent/components/DLText/DLText";
import appTheme from "../../assets/appTheme";
import DLInputTextField from "dlcomponent/components/DLInputTextField/DLInputTextField";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function SummaryByFundHouse(props) {

    const{isin, isXSView} = props;
    const [chartLabelsArray, setChartLabelsArray] = useState([]);
    const [chartDataArray, setChartDataArray] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tabValue, setTabValue] = React.useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [percentage, setPercentage] = useState(0);
    const [tableHeader, setTableHeader] = useState([]);
    const [chartType, setChartType] = useState('marketValue');


    const [searched, setSearched] = useState("");
    const[isShowFilter,setIsShowFilter] = useState(false);
    const[filteredArray,setFilteredArray] = useState([]);

    useEffect(() => {
        if (props.tableData){
            setIsLoading(false);
            setTableData(props.tableData.slice(1, props.tableData.length));
            setTableHeader(props.tableData[0]);
            setFundHouseSummaryGraph(props.tableData.slice(1, props.tableData.length));
        }

    }, [props.tableData]);

    const handleClick = (elementId) => {
        let indexArr = getIndexArr(chartType, filteredArray);

            let clickedFundHouse = indexArr[elementId];
            props.history.push('/fund-houses/'+clickedFundHouse.fund_house_id)
    };

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const setFundHouseSummaryGraph = (data) => {
        const {isLGView, isXLView,isMobileView} = props;
        let  chartLabels = [];
        let  chartData = [];
        let filteredData= []
        let length = data.length;

        for (let i = 0; i < length; i++) {

            let fundHouseName = getTrimVal(data[i].fund_house_name,isLGView, isXLView,isMobileView);
            let marketValue = getFixedValuesAfterDecimal(data[i].total_market_value,2);

            chartLabels.push(fundHouseName);
            chartData.push(marketValue);
            filteredData.push(data[i])

        }
        let chartObj = getChartData(chartType, filteredData, 'fund_house_name', isLGView, isXLView,isMobileView);
        setFilteredArray(filteredData)
        setChartLabelsArray(chartObj.newLabelData);
        setChartDataArray(chartObj.newChartData);
    };

    const deepCopy = (obj) => {
        return JSON.parse(JSON.stringify(obj));
    };

    //order by descending
    const compareByMarketValueChangeMoM = (a, b) => {
        if (a.net_buying_selling < b.net_buying_selling) {
            return 1;
        }
        if (a.net_buying_selling > b.net_buying_selling) {
            return -1;
        }
        return 0;
    };

    const compareByMarketValueChangePercent = (a, b) => {
        if (a.net_buying_selling_percentage < b.net_buying_selling_percentage) {
            return 1;
        }
        if (a.net_buying_selling_percentage > b.net_buying_selling_percentage) {
            return -1;
        }
        return 0;
    };


    const handleRadioChange = (event) => {
        const {isXSView, isLGView, isXLView,isMobileView} = props;
        let chartType =  event ;

        const filteredRows = filteredArray.filter((row) => {
            return row?.fund_house_name.toLowerCase().includes(searched.toLowerCase());
        });

        let chartObj = getChartData(chartType, filteredRows, 'fund_house_name', isLGView, isXLView,isMobileView);

        setChartType(chartType);
        setPercentage((chartType === 'changePercent' || chartType === 'changeSellingPercent'));
        setChartLabelsArray(chartObj?.newLabelData);
        setChartDataArray(chartObj?.newChartData);
    };


    const getResult = (data) =>{
        if (isEmpty(data)){
            return
        }

        if (data?.length >= 10){
            return(
                <div className='py-2 mx-3'>
                    Showing top 10 results
                </div>
            )
        }else {
            if (data.length === 1){
                return (
                    <div className='py-2 mx-3'>
                        Showing {data?.length} result
                    </div>
                )
            }else {
                return (
                    <div className='py-2 mx-3'>
                        Showing {data?.length} results
                    </div>
                )

            }
        }
    };

    const requestSearch = (searchedVal) => {
        if(isEmpty(searchedVal)){
            setSearched('');
            setFundHouseSummaryGraph(tableData)
        }else{
            let data = getFilteredGraphData(tableData, searchedVal)
            setSearched(searchedVal);
            setFundHouseSummaryGraph(data);
        }

    };

    const getFilteredGraphData =(data, searchedVal)=>{
        const filteredRows = searchOnWordBoundaryOverview(searchedVal,data,"fund_house_name")
        // const filteredRows = data.filter((row) => {
        //     return row.fund_house_name.toLowerCase().includes(searchedVal.toLowerCase());
        // });

        return filteredRows;

    }

    const getFileName = (blockName) =>{
        const {selectedStock} = props;
        let date = new Date();
        return selectedStock?.company_name+'-'+blockName+moment(date).format('YYYY-MM-DD')
    };

    const handleOnBlur =()=>{
        if(isEmpty(searched)){
            setIsShowFilter(false);
        }

    }

    const emptySearch =()=>{
        requestSearch("")
        document.getElementById("businessList-keywordSearch-input").focus();
    }

    if (isLoading) {
        return (
            <div className={'row px-2 h-100'}>
                <div className={"col-12 d-flex flex-column justify-content-center align-items-center mb-1"}>
                    <DLLoader type={'inline'} isVisible={true}/>
                </div>
            </div>
        )
    }


    return (
        <div className='panel'
             id='stocks-fund-house'>
            <div className='d-flex justify-content-between px-3 pt-3'>
                <div className='d-flex  justify-content-center align-items-center'>
                    <DLText
                        id={'summary-by-country'}
                        type={'normal'}
                        text={'Fund Houses'}
                        fontWeight={'semi-bold'}
                        fontSize={'lg'}/>
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div className='ps-3 table-search'>
                            {(isShowFilter) ?
                                <DLInputTextField
                                    id={'businessList-keywordSearch'}
                                    label={''}
                                    rows={1}
                                    fullWidth
                                    styleType={"search"}
                                    placeholder={'Search...'}
                                    value={searched}
                                    onChangeValue={(searchVal) => requestSearch(searchVal)}
                                    isClearable={true}
                                    autoFocus={true}
                                    onBlur={()=>{handleOnBlur()}}
                                    inputHeight={'sm'}
                                    marginBottom={"none"}
                                    InputProps={{
                                        endAdornment: (

                                            !isEmpty(searched) ?
                                                <i className="fa fa-remove m-2 cursor" onClick={()=>{emptySearch()}} style={{color: appTheme.appColor.black}}/>
                                                :
                                                <i className={'fas fa-search pr-1'} style={{color: appTheme.appColor.black}}/>
                                        )
                                    }}
                                    disableHelperMessageHeight={true}
                                />
                            :
                                <i className="fa-solid fa-magnifying-glass cursor" onClick={()=>{
                                setIsShowFilter(true)
                            }}/>}
                        </div>
                    </div>
                </div>
                <div className='ms-1' style={{width: 36, height: 36, minWidth: 'max-content'}}>
                    {/*<DLButton*/}
                    {/*    id={'download-excel'}*/}
                    {/*    styleType={"outlined"}*/}
                    {/*    borderType={'square'} buttonSize={'sm'}*/}
                    {/*    style={{width: 36}}*/}
                    {/*    sideMargin={"none"}*/}
                    {/*    reduceWidth={true}*/}
                    {/*    type={'primary'}*/}
                    {/*    onClick={() =>{*/}
                    {/*        downloadData('/service/media/stock/fundHouseSummary/download?date='+VALUATION_DATE+'&isin='+isin,'', 'Download', getFileName('Fund Houses Summary-'))*/}
                    {/*    }}*/}
                    {/*    label={<i className='fas fa-download fs-6'/>}*/}
                    {/*/>*/}
                    <div className='d-flex align-items-center justify-content-center downloadButtonCss cursor'>
                        <i className='fas fa-download fs-6'
                           onClick={() =>{
                               downloadData('/service/media/stock/fundHouseSummary/download?date='+VALUATION_DATE+'&isin='+isin,'', 'Download', getFileName('Fund Houses Summary-'))
                           }}
                           style={{fontSize: 12}}/>
                    </div>
                </div>
            </div>

            <Box sx={{ width: '100%' }}>
                <Box className='d-flex justify-content-between'
                     sx={{ borderBottom: 1, borderColor: 'divider', marginLeft: '20px' ,marginRight: '20px' }}>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Chart" {...a11yProps(0)} sx={{ textTransform:'capitalize'}}/>
                        <Tab label="Table" {...a11yProps(1)} sx={{ textTransform:'capitalize'}}/>
                    </Tabs>

                </Box>

                <CustomTabPanel value={tabValue} index={0} id="tableTabPanel" >
                    {(isEmpty(chartDataArray) || isEmpty(chartLabelsArray)) ?
                        <div className='d-flex flex-column'>
                            <ChartRadioGroup handleRadioChange={handleRadioChange}
                                             chartType={chartType}
                                             isXSView={props.isXSView}
                                             isSMView={props.isSMView}/>
                            <div className='d-flex align-items-center justify-content-center mx-3' style={{minHeight: 500}}>
                                <DLText
                                    id={'revenue-value'}
                                    type={'normal'}
                                    text={'No data found'}
                                    fontSize={'md'}/>
                            </div>
                        </div>
                        :
                        <>
                            <ChartRadioGroup handleRadioChange={handleRadioChange} chartType={chartType}
                                             isXSView={props.isXSView} isSMView={props.isSMView}/>
                            {getResult(chartDataArray)}
                            <SummaryByFundHouseChart yAxis={chartLabelsArray} chartType={chartType}
                                                     xAxis={chartDataArray} handleClick={handleClick}
                                                     percentage={percentage} isMobileView={props.isMobileView}/>
                        </>
                    }
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1} id="tableTabPanel">
                    {(isEmpty(tableData) || isEmpty(tableHeader)) ?
                        <div className='d-flex align-items-center justify-content-center mb-3' style={{minHeight: 500}}>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={'No data found'}
                                fontSize={'md'}/>
                        </div>
                        :
                        <SummaryByFundHouseTable data={tableData} {...props} tableHeader={tableHeader} searched={searched}/>
                    }
                </CustomTabPanel>

            </Box>

        </div>
    )

}

export default withRouter(SummaryByFundHouse);
