import { showSystemErrorBlock , showToastMessage} from "../core/actions/appActions";
import store from "../store";
import {httpGet} from "../utils/httpHelper";

// let base_url = 'http://localhost:10217'
let base_url = ''
export const getFundHouseSummary= (date) => {
    let url =base_url+'/service/home/fundHouseSummary?date='+date ;
    return httpGet(url)
        .then(res=>{
            return res;
        })
};

export const getFundSummary= (date) => {
    let url = base_url + '/service/home/fundSummary?date='+date ;
    return httpGet(url)
        .then(res=>{
            return res;

        })
};

export const getIndustrySummary= (date) => {
    let url = base_url + '/service/home/sectorSummary?date='+date ;
    return httpGet(url)
        .then(res=>{
            return res;
        })
};

export const getStocksSummary= (date) => {
    let url = base_url + '/service/home/stocksSummary?date='+date;
    return httpGet(url)
        .then(res=>{

            return res;

        })
};

export const getSummaryData= () => {
    let url = base_url + '/service/home/dashboard';
    return httpGet(url)
        .then(res=>{
            return res;
        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getMonthlyTrendData= () => {
    let url = base_url + '/service/home/monthlyTrend';
    return httpGet(url)
        .then(res=>{
            return res;
        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getIndustryMarketCapData= () => {
    let url = base_url + '/service/home/dimension/summary';
    return httpGet(url)
        .then(res=>{
            return res;
        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};
