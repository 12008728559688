import React, {useEffect, useRef} from 'react';
import { render } from 'react-dom';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { TreemapChart } from 'echarts/charts';
import {
    GridComponent,
    LegendComponent,
    TooltipComponent,
    TitleComponent,

} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import '../../component-utils/EChart/eChart.css';
import withRouter from "react-router-dom/es/withRouter";
import {isEmpty} from "../../utils/validations";

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    TreemapChart,
    CanvasRenderer,
]);

interface AppProps {}
function FirstViewEchartBarChart(props) {
    const {series, xAxislabels, yAxisMaxValue, isXSView, yAxisMinValue, leftGap, rightGap, yAxisPosition, showDownloadIcon,
        splitNumber,stepSize,isBarChartClickable, getBarClick, gridLeft,gridRight, gridTop, gridBottom, id} = props;

    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            show: false
        },
        toolbox: {
            show: true,
            orient: 'vertical',
            right: 0,
            top: 0,
            feature: {
                // mark: { show: true },
                // dataView: { show: true, readOnly: false },
                // magicType: { show: true, type: ['line', 'bar', 'stack'] },
                // restore: { show: true },
                saveAsImage: { show: showDownloadIcon }
            }
        },
        xAxis: [
            {
                type: 'category',
                axisTick: { show: false },
                data: xAxislabels
            }
        ],
        yAxis: [
            {
                min: yAxisMinValue,
                max: isEmpty(yAxisMaxValue)? "" : yAxisMaxValue,
                type: 'value',
                splitNumber: splitNumber,
                boundaryGap: false,
                interval: isEmpty(stepSize)? null : stepSize,
                position: isEmpty(yAxisPosition)? 'left' : yAxisPosition
            }
        ],
        grid:{
            top: isEmpty(gridTop)? (showDownloadIcon ? 40 : 20) : gridTop,
            left: isEmpty(gridLeft)? 70 : gridLeft,
            right: isEmpty(gridRight)? 10 : gridRight,
            bottom: isEmpty(gridBottom)? 50 : gridBottom
        },
        series: series,
        roam: false,
        zoom: null,
    };

    const getHeight = () =>{
        const {isMobileView, isSMView, isXSView, height} = props;

        if (height){
            return height
        }

        if (isXSView || isSMView)
            return '400px';

        return '420px'
    };

    useEffect(()=>{
        var myChart = echarts.init(document.getElementById(id));

        myChart.setOption(option);

        myChart.on('click', function (params) {
            if(isBarChartClickable){
                getBarClick(params?.dataIndex)
                // console.log('params', params, params.name);
            }
        });
    },[series.data])



    return (
        <div id={id} className='' style={{width:'100%', height : getHeight()}}>
            <ReactEChartsCore
                echarts={echarts}
                option={option}
                theme={'theme_name'}
                opts={{}}
            />
        </div>
    );
}

export default withRouter(FirstViewEchartBarChart);