import { showSystemErrorBlock , showToastMessage} from "../core/actions/appActions";
import store from "../store";
import {httpGet} from "../utils/httpHelper";
import {setKeyword} from "../stocks/stockReducer";
import {resetTabValue,setTabValue, setFundHouseKeyword,  resetOrder, resetOrderBy, resetPage, resetRowsPerPage, setOrder, setOrderBy, setPage, setRowsPerPage} from "./FundHouseReducer";
import {isEmpty} from "../utils/validations";

// let base_url = 'http://localhost:10217'
let base_url = ''

export const getSummaryByStocks= (date,fundHouseId) => {
    let url = base_url+ '/service/fundHouse/stocksSummary?date='+date+'&fundHouseId='+fundHouseId ;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};


export const getSummaryByFunds = (date,fundHouseId) => {
    let url = base_url+ '/service/fundHouse/fundSummary?date='+date+'&fundHouseId='+fundHouseId ;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};



export const getSummaryByIndustry= (date,fundHouseId) => {
    let url = base_url+ '/service/fundHouse/sectorSummary?date='+date+'&fundHouseId='+fundHouseId ;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};


export const getAllFundHouses= () => {
    let url = base_url+ '/service/fundHouse/all' ;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};
export const getFundHouseSummary= (date, skipCount, limitCount) => {

    let url;
    if (true){
        url = base_url + '/service/home/fundHouseSummary?date='+date;
    }else {
        url = base_url + '/service/home/fundHouseSummary?date='+date+'&skipCount='+skipCount+'&limitCount='+limitCount;
    }

    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getFundHouseSummaryData= (date, fundHouseId) => {

    let url =base_url+'/service/fundHouse/fundHousesSummary?date='+date+'&fundHouseId='+fundHouseId;

    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};


export const setFundHouseKeywordFilter = (keyword) =>{
    store.dispatch(setFundHouseKeyword(keyword))
};

export const getFundHouseDimension= (date,fundHouseId) => {
    let url = base_url+ '/service/fundHouse/dimension/summary?fundHouseId='+fundHouseId+'&date='+date;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getNetBuyingSellingPopUpData= (date,fundHouseId) => {
    let url = base_url+ '/service/fundHouse/lastMonthSummary?date='+date+'&fundHouseId='+fundHouseId;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const setFundHouseTableByOrder = (data) =>{
    console.log("data",data)
    store.dispatch(setOrder(data))
};

export const setFundHouseTableByOrderBy = (data) =>{
    store.dispatch(setOrderBy(data))
};

export const setFundHousePage = (data) =>{
    store.dispatch(setPage(data))
};

export const setFundHouseRowsPerPage = (data) =>{
    store.dispatch(setRowsPerPage(data))
};

export const setFundHouseTabValue = (data) =>{
    store.dispatch(setTabValue(data))
};

export const resetFundHouseTabValue = (data) =>{
    store.dispatch(resetTabValue(data))
};

export const resetFundHousePage = (data) =>{
    store.dispatch(resetPage(data))
};

export const resetFundHouseRowsPerPage = (data) =>{
    store.dispatch(resetRowsPerPage(data))
};

export const resetFundHouseTableByOrder = (name, value) =>{
    store.dispatch(resetOrder({name, value}))
};
export const resetFundHouseTableByOrderBy = (name, value) =>{
    store.dispatch(resetOrderBy({name, value}))
};