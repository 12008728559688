import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {isEmpty} from "../../../utils/validations";
import {
    numberWithCommas,
    searchData
} from "../../../utils/helper";
import {stocksConst} from "../../../stocks/stocksConst";
import DLText from "dlcomponent/components/DLText/DLText";
import {filterAumValue} from "../../../utils/stocksHelper";
import LineChartMixed from "./../../LineChart/LineChartMixed";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
// TODO use lazy load for each component which is firing the api
function AUMLineChartView(props) {

    const {selectedStock, UIState, sceenerCode, handleOpenModal, marketValue, requiredIsin, stockName} = props;
    const [activeSection, setActiveSection] = useState('section1');

    const renderMobileView = () =>{
        return (
            <div className=''>
                <div className="p-2" style={{backgroundColor: ""}}>
                    <div className='w-100'>
                        <LineChartMixed {...props} isBarChartClickable={true} getBarClick={getBarClick} />
                    </div>
                </div>
            </div>
        )
    };

    const getBarClick =(e)=>{
        const {xAxis,yAxisBar} = props;
        if(isEmpty(e)){
            return;
        }
        let graphMonth = xAxis.find((item, index)=>{
            return index === e;
        })
        handleOpenModal(graphMonth)
        // console.log("in bar click",e,xAxis, graphMonth,props)

    }

    const renderUI = () => {
            return renderMobileView();
    };

    if (UIState === stocksConst.CONTENT_UNAVAILABLE) {
        return
    }
    // if (isEmpty(selectedStock?.company_name)) {
    //     return <DLLoader type={'screen'} isVisible={true}/>
    // }

    const activeStyle = {
        fontWeight: 500,
        color: '#282828',
        textDecoration: 'underline',
        fontFamily: 'Poppins, sans-serif',

    };
    props.handleChange();

    return (
        <>
            <div className="bg-white ">
                {/*<div className="text-end">    <i className="fa fa-remove m-2 cursor" onClick={() => {*/}
                {/*    clearOption(buttonIdReference)*/}
                {/*}} style={{color: appTheme.appColor.black}}/>*/}
                {/*</div>*/}
                <div className="d-flex justify-content-between align-items-start p-2 w-100">
                    <DLText marginBottom={'none'}
                            isInline={true}
                            text={!isEmpty(stockName)? stockName : ""}
                            onClick={() => {
                                props.history.push("/stocks/" + requiredIsin)
                            }}
                            fontWeight={'semi-bold'}
                            isClickable={true}
                            fontColor={"#0073FF"}
                            fontSize={'xs'}/>

                    <div className={"me-5"}>
                        <DLText marginBottom={'none'}
                                isInline={true}
                                text={numberWithCommas(filterAumValue(marketValue))}
                                fontWeight={'semi-bold'}
                                fontSize={'sm'}/>
                    </div>

                </div>
                <div id={'myDiv'}>
                    <div className=''>
                        {renderUI()}
                    </div>
                </div>
            </div>
        </>
    );
}

AUMLineChartView.propTypes = {};

export default AUMLineChartView;
