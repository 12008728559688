import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {isEmpty} from "../../utils/validations";
import {
    filterNetBuyingSellingValue,
    getDataArray, getDataArrayForFundHouse,
    getFixedValuesAfterDecimal,
    getMobileHeight,
    getNextRoundedNumber, getTrimmedValue,
    getTrimVal,
    numberWithCommas,
    searchData
} from "../../utils/helper";
import SummaryByFundHouse from "../../stocks/SummaryByFundHouse/SummaryByFundHouse";
import SummaryByFund from "../../stocks/SummaryByFund/SummaryByFund";
import {stocksConst} from "../../stocks/stocksConst";
import DLText from "dlcomponent/components/DLText/DLText";
import appTheme from "../../assets/appTheme";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CustomTabPanel from "../../component-utils/CustomTabPanel/CustomTabPanel";
import DLLoader from "../../core/components/DLLoader/DLLoader";
import moment from 'moment'
import {filterAumValue} from "../../utils/stocksHelper";
import LineChartMixed from "../LineChart/LineChartMixed";
import BarChart from "../BarChart/FirstViewBarChart";
import greenBull from "../../assets/img/green bull.png";
import redBear from "../../assets/img/red bear.png";
import DLButton from "dlcomponent/components/DLButton/DLButton";
import EChartTreeMap from "../EchartTreeMap/EChartTreemap";
import PopComponent from "../../stocks/PopComponent";
import LinearProgress from "@mui/material/LinearProgress";
import FirstViewBarChart from "../BarChart/FirstViewBarChart";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// TODO use lazy load for each component which is firing the api
function FirstStockView(props) {

    const {
        isin, refresh, isMDView, isLGView, isMobileView, firstStockName, secondStockName, thirdStockName, stocks, isXSView, firstStock, getFirstPopUp, handleClick, isXLView, selectedStock, UIState, sceenerCode, handleOpenModal,
        holdings, marketCapCategory, businessHouse, industry, isSMView, netBuyingSellingSum, marketValue, isInternationalStock,
        exits, entries, buyingFunds, sellingFunds, netBuying, netSelling, netBuyingSellingDifference, clearOption, buttonIdReference
    } = props;
    const [activeSection, setActiveSection] = useState('section1');

    const addScroll = (id) => {
        const element = document.getElementById(id);
        if (!isEmpty(element)) {
            element.scrollIntoView({behavior: "smooth", block: "start"});
        }
    };

    const getNetBuyingSellingTextColor = (value) => {
        if (value > 0) {
            return (
                <DLText
                    id={'revenue-value'}
                    type={'normal'}
                    text={numberWithCommas(value)}
                    fontColor={"success"}
                    fontSize={'sm'}/>
            );
        } else if (value < 0) {
            return (
                <DLText
                    id={'revenue-value'}
                    type={'normal'}
                    text={numberWithCommas(value)}
                    fontColor={"danger"}
                    fontSize={'sm'}/>
            );
        } else {
            return (
                <DLText
                    id={'revenue-value'}
                    type={'normal'}
                    text={numberWithCommas(value)}
                    fontSize={'sm'}/>
            );
        }
    };

    const getHoldingPercentage = () => {
        if (isEmpty(holdings) || isEmpty(marketValue)) {
            return;
        }
        let percentage = (marketValue / holdings) * 100;
        if (percentage < 10) {
            return getFixedValuesAfterDecimal(percentage, 2);
        }
        return getFixedValuesAfterDecimal(percentage, 0);
    }

    const getProgressBarBackgroundColor = (percentage) => {
        let color = '';
        if (percentage <= 5) {
            color = "#7D81FF"
        } else if (percentage <= 10) {

            color = "#5257FF"
        } else if (percentage <= 20) {

            color = "#0007FF"
        } else if (percentage <= 50) {

            color = "#1A1FB3"
        } else if (percentage <= 100) {

            color = "#1A1FB3"
        } else {

            color = ""
        }
        return color;
    }

    const addStockButton = () => {
        return (
            <div className={"w-100 h-100"} hidden={!isEmpty(firstStock)}>
                <div className="d-flex justify-content-center align-items-center">
                    <div className="w-50">
                        <div className='d-flex justify-content-center align-items-center cursor p-2'
                             style={{
                                 height: 36,
                                 minWidth: 'max-content',
                                 border: "1px solid",
                                 borderRadius: "9px",
                                 borderColor: "#C5C5C5"
                             }}
                             onClick={(e) => {
                                 handleClick(e, "First_Button")
                             }}
                        >
                            <div className="d-flex justify-content-center align-items-center w-100">

                                <div className="d-flex justify-content-center align-items-center w-100">
                                    <i className="fa fa-plus m-2 cursor"
                                       style={{color: appTheme.appColor.black}}/>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {getFirstPopUp("First_PopUp")}

            </div>
        )
    }

    const renderDesktopView = () => {
        const {activitiesStateLoaded, isXSView, yAxisBar, barColor, yAxisLine, xAxis, isLGView, activitiesData, fundHouseCount, fundSchemeCount, marketValue} = props;

        return (
            <div className='mb-3'>
                <div className="d-flex justify-content-center align-items-center p-2"
                     style={{minHeight: isMDView? "62px" : "50px", backgroundColor: "#F8F8FC"}}>
                    <div className="text-center">
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={fundHouseCount}
                            marginBottom={"none"}
                            fontSize={'sm'}/>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center p-2"
                     style={{minHeight: "50px", backgroundColor: ""}}>
                    <div className="text-center">
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={fundSchemeCount}
                            marginBottom={"none"}
                            fontSize={'sm'}/>
                    </div>
                </div>
                <div className="p-2"
                     style={{minHeight: "50px", backgroundColor: "#F8F8FC"}}>
                    <div className="text-center">
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={
                                (holdings === "NaN" || isEmpty(marketValue)) ?
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={""}
                                        marginBottom={"none"}
                                        fontSize={'sm'}/>
                                    :
                                    <div className="d-flex justify-content-center align-items-center">
                                        <DLText
                                            id={'revenue-value'}
                                            type={'normal'}
                                            text={getHoldingPercentage() + "%"}
                                            marginBottom={"none"}
                                            fontSize={'sm'}/>
                                        <div className={"mx-1"}>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={"of"}
                                                fontWeight={"normal"}
                                                marginBottom={"none"}
                                                fontSize={'sm'}/>
                                        </div>
                                        <DLText
                                            id={'revenue-value'}
                                            type={'normal'}
                                            text={" " + numberWithCommas(holdings)}
                                            marginBottom={"none"}
                                            fontSize={'sm'}/>
                                    </div>
                            }
                            fontSize={'sm'}/>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="w-50">
                            <div style={{height: "8px", backgroundColor: "#C5C5C5", borderRadius: "5px"}}>
                                <div style={{
                                    height: "8px",
                                    width: getHoldingPercentage() + "%",
                                    borderRadius: "5px",
                                    backgroundColor: getProgressBarBackgroundColor(getHoldingPercentage())
                                }}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center p-2"
                     style={{minHeight: "62px", backgroundColor: ""}}>
                    <div className="text-center">
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={marketCapCategory}
                            marginBottom={"none"}
                            fontSize={'sm'}/>
                    </div>
                </div>
                <div className="p-2"
                     style={{minHeight: "260px", backgroundColor: "#F8F8FC"}}>
                    <div>
                        <div className="text-center">
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={numberWithCommas(filterAumValue(marketValue))}
                                fontSize={'sm'}/>
                        </div>
                        <div className='w-100'>
                            <LineChartMixed {...props} isBarChartClickable={true} getBarClick={getBarClick}/>
                        </div>
                    </div>
                </div>
                <div className="p-2"
                     style={{minHeight: "260px", backgroundColor: ""}}>
                    <div>
                        <div className="text-center">
                            {getNetBuyingSellingTextColor(filterNetBuyingSellingValue(netBuyingSellingSum))}
                        </div>
                        <div className='w-100'>
                            <FirstViewBarChart {...props} id={"first-view-netbuyingselling-barchart"} isBarChartClickable={true} getBarClick={getBarClick}/>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center p-2"
                     style={{minHeight: "400px", backgroundColor: "#F8F8FC"}}>
                    <div className='w-100'>
                        {
                            isEmpty(props.tableData) ?
                                <div className="h-100 my-4 text-center">
                                    No data found
                                </div>
                                :
                                <EChartTreeMap {...props} requestedHeight={"350px"}
                                               data={getDataArrayForFundHouse(props.tableData, 'fund_house_name', 'total_market_value')}/>
                        }
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center p-2"
                     style={{minHeight: "400px", backgroundColor: ""}}>
                    <div className='w-100'>
                        {
                            isEmpty(props.fundsData) ?
                                <div className="h-100 my-4 text-center">
                                    No data found
                                </div>
                                :
                                <EChartTreeMap {...props} requestedHeight={"350px"}
                                               data={getDataArrayForFundHouse(props.fundsData, 'fund_name', 'total_market_value')}/>
                        }
                    </div>
                </div>
            </div>
        )
    };

    const renderEmptyView = () => {
        const {activitiesStateLoaded, isXSView, yAxisBar, barColor, yAxisLine, xAxis, isLGView, activitiesData, fundHouseCount, fundSchemeCount, marketValue} = props;

        return (
            <div className='mb-3' style={{borderRight: "1px solid #CDCDCD"}}>
                <div className="d-flex justify-content-center align-items-center p-2" style={{
                    minHeight: getHeadingHeight(),
                    position: 'sticky',
                    top: 0,
                    backgroundColor: 'white',
                    zIndex: 1
                }}>
                    {addStockButton()}

                </div>
                <div className="p-2" style={{minHeight: isMDView? "62px" : "50px", backgroundColor: "#F8F8FC"}}>
                </div>
                <div className="p-2" style={{minHeight: "50px", backgroundColor: ""}}>

                </div>
                <div className="p-2" style={{minHeight: "50px", backgroundColor: "#F8F8FC"}}>

                </div>
                <div className="p-2" style={{minHeight: "62px", backgroundColor: ""}}>

                </div>
                <div className="p-2" style={{minHeight: "260px", backgroundColor: "#F8F8FC"}}>

                </div>
                <div className="p-2" style={{minHeight: "260px", backgroundColor: ""}}>

                </div>
                <div className="p-2" style={{minHeight: "400px", backgroundColor: "#F8F8FC"}}>

                </div>
                <div className="p-2" style={{minHeight: "400px", backgroundColor: ""}}>

                </div>
            </div>
        )
    };

    const getBarClick = (e) => {
        const {xAxis, yAxisBar, xAxisWithYear} = props;
        if (isEmpty(e)) {
            return;
        }
        let graphMonth = xAxisWithYear.find((item, index) => {
            return index === e;
        })
        handleOpenModal(graphMonth)
        // console.log("in bar click",e,xAxis, graphMonth,props)

    }

    const getHeadingHeight = () => {
        if(isMDView || isLGView){
            if (firstStockName.length > 25 || secondStockName.length > 25 || thirdStockName.length > 25) {
                return "80px";
            } else {
                return "54px";
            }

        }else{
            return "54px";
        }
    }

    const renderUI = () => {
        return renderDesktopView();
    };

    if (UIState === stocksConst.CONTENT_UNAVAILABLE) {
        return
    }
    if (isEmpty(isin)) {
        return renderEmptyView();
    }

    const activeStyle = {
        fontWeight: 500,
        color: '#282828',
        textDecoration: 'underline',
        fontFamily: 'Poppins, sans-serif',

    };
    props.handleChange();

    return (
        <>
            <div className="bg-white " style={{borderRight: "1px solid #CDCDCD"}}>
                <div style={{position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div
                            className={isXLView ? "d-flex justify-content-center align-items-center p-2 w-100" : "d-flex justify-content-start align-items-center p-2 w-100"}
                            style={{minHeight: getHeadingHeight()}}>
                            <DLText id={""}
                                    marginBottom={'none'}
                                    isInline={true}
                                    text={getTrimmedValue(selectedStock?.company_name, 35)}
                                    fontWeight={'semi-bold'}
                                    onClick={() => {
                                        props.history.push("/stocks/" + isin)
                                    }}
                                    isClickable={true}
                                    fontSize={'sm'}/>
                        </div>
                        <div className="text-end"><i className="fa fa-remove m-2 cursor" onClick={() => {
                            clearOption("First_Button")
                        }} style={{color: appTheme.appColor.black}}/>
                        </div>
                    </div>
                </div>
                <div id={'myDiv'}>
                    <div className=''>
                        {renderUI()}
                        {
                            <PopComponent {...props}/>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

FirstStockView.propTypes = {};

export default FirstStockView;
