import React from 'react';
import {isEmpty} from "../utils/validations";
import DLText from "dlcomponent/components/DLText/DLText";
import DLInputTextField from "dlcomponent/components/DLInputTextField/DLInputTextField";
import DLButton from "dlcomponent/components/DLButton/DLButton";
import AdvanceSearchChipsView from "../AdvanceSearch/AdvanceSearchChipsView";
import AdvanceFundTabel from "./AdvanceFundTabel";
import AdvanceFundListingModal from "../AdvanceSearch/AdvanceSearchModal/AdvanceFundListingModal";
import AdvanceSearchMarketCap from "../AdvanceSearch/AdvanceSearchModal/AdvanceSearchMarketCap";
import AdvanceFundHouseListingModal from "../AdvanceSearch/AdvanceSearchModal/AdvanceFundHouseListingModal";
import AdvanceIndustrySearchModal from "../AdvanceSearch/AdvanceSearchModal/AdvanceIndustrySearchModal";
import AdvanceBusinessHouseSearchModal from "../AdvanceSearch/AdvanceSearchModal/AdvanceBusinessHouseSearchModal";
import AdvanceStocksSearchModal from "../AdvanceSearch/AdvanceSearchModal/AdvanceStocksSearchModal";
import appTheme from "../assets/appTheme";
import DLLoader from "../core/components/DLLoader/DLLoader";
import moment from "moment";
import {downloadDataByObject} from "../fund/FundAction";
import {DLTooltip} from "dlcomponent";
import moneyFlow from "../assets/img/money flow.png";

function AdvanceFundSearchView(props) {

    const {searchResult, isXSView, isSMView,  isFilterAPIInProgress, isMobileView, resetListingData, tableHeader, extractData,
        advanceSearchFilter, keyword, isExternalClick, storedDataForDownload,isMDView} = props;

    const {businessHouses, marketCaps,fundHousesData,stocksData,fundsData,sectorsData} = props.advanceSearchFilter;

    const areAllValuesNotEmpty = (obj) => {
        return isEmpty(advanceSearchFilter.fundHousesData) && isEmpty(advanceSearchFilter.businessHouses)  && isEmpty(advanceSearchFilter.fundsData)
            && isEmpty(advanceSearchFilter.marketCaps) && isEmpty(advanceSearchFilter.sectorsData) && isEmpty(advanceSearchFilter.stocksData) && isEmpty(keyword) ;
    };

    const areAllModalFilterValuesNotEmpty = (obj) => {
        return isEmpty(advanceSearchFilter.fundHousesData) && isEmpty(advanceSearchFilter.businessHouses)  && isEmpty(advanceSearchFilter.fundsData)
            && isEmpty(advanceSearchFilter.marketCaps) && isEmpty(advanceSearchFilter.sectorsData) && isEmpty(advanceSearchFilter.stocksData) ;
    };

    const selectedFilterObj = {
        stocks : extractData(stocksData,'isin_code'),
        fundHouses : extractData(fundHousesData,'fund_house_id'),
        funds :  extractData(fundsData,'fund_id'),
        sectors : extractData(sectorsData,'industry'),
        businessHouses : businessHouses,
        marketCaps : marketCaps,
        fundHouseForFund : extractData(fundsData,'fund_house_id'),
        fundsName: extractData(fundsData,'fund_name'),
        stocksName: extractData(stocksData,'company_name'),
        fundHousesName: extractData(fundHousesData,'fund_house_name'),
        fundHousesData:fundHousesData,
        stocksData:stocksData,
        fundsData:fundsData,
        sectorsData:sectorsData
    };

    const renderSearchResult = () =>{

        let isNotFilterApplied = areAllValuesNotEmpty(advanceSearchFilter);

        if (isFilterAPIInProgress){
            return <div style={{minHeight: 300, maxHeight: 300}}>
                <DLLoader type={"inline"} isVisible={true}/>
            </div>
        }

        if (isEmpty(searchResult)){
            if(!isEmpty(props?.history?.location?.state)){
                return(
                    <div className='d-flex align-items-center justify-content-center px-3' style={{minHeight:300}}>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            fontColor={'lightGray'}
                            customWrapperStyle={{
                                textAlign:'center'
                            }}
                            text={""}
                            fontSize={'md'}/>
                    </div>
                )
            }
            return(
                <div className='d-flex align-items-center justify-content-center px-3' style={{minHeight:300}}>
                    <DLText
                        id={'revenue-value'}
                        type={'normal'}
                        fontColor={'black'}
                        customWrapperStyle={{
                            textAlign:'center'
                        }}
                        text={isNotFilterApplied ? 'Apply a filter or search a fund to access the data.' : 'No data found.'}
                        fontSize={'md'}/>
                </div>
            )
        }

        return(
            <div className='pt-1'>
                {isFilterAPIInProgress ?
                    <div style={{minHeight:300, maxHeight:300}}>
                        <DLLoader type={"inline"} isVisible={true}/>
                    </div>
                    :
                    <AdvanceFundTabel data={searchResult} {...props} tableHeader={tableHeader}/>
                }
            </div>
        )
    };

    const renderSearchBar = () => {
        const {t, isXSView, isSMView, keyword, onChangeKeywordFilter, onChangeValue} = props;

        return (
            <div className='px-3 d-flex justify-content-between align-items-center mt-1 w-100' style={{marginBottom: isXSView ? 8 : 14}}>
                <div style={{minWidth:isXSView ? '220px' : '450px', maxWidth:isXSView ? '220px' : '450px'}}>
                    <DLInputTextField
                        id={'businessList-keywordSearch'}
                        label={''}
                        rows={1}
                        fullWidth
                        styleType={"search"}
                        placeholder={'Search...'}
                        value={keyword}
                        onChangeValue={(e) => onChangeKeywordFilter(e)}
                        debounceTimer={800}
                        isClearable={true}
                        inputHeight={'sm'}
                        marginBottom={"none"}
                        InputProps={{
                            endAdornment: (
                                keyword ?
                                    <i className="fa fa-remove m-2 cursor" onClick={(e)=>{onChangeKeywordFilter('')}} style={{color: appTheme.appColor.black}}/>
                                    :
                                    <i className={'fas fa-search pr-1'} style={{color: appTheme.appColor.black}}/>)
                        }}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {

                                document?.activeElement?.blur()
                            }
                        }}
                        disableHelperMessageHeight={true}
                    />
                </div>
            </div>
        )
    };

    const renderChipsFilterUI = () => {
        return <AdvanceSearchChipsView {...props}/>
    };

    const getFileName = (blockName) =>{
        let date = new Date();
        return blockName+moment(date).format('YYYY-MM-DD')
    };

    const renderAdvanceSearchModal = () =>{
        return(
            <>
                <AdvanceFundListingModal {...props}/>
                <AdvanceFundHouseListingModal {...props}/>
                <AdvanceSearchMarketCap {...props}/>
                <AdvanceIndustrySearchModal {...props}/>
                <AdvanceBusinessHouseSearchModal {...props}/>
                <AdvanceStocksSearchModal {...props}/>
            </>
        )
    };

    return (
        <div className=' bg-white' style={{minHeight: isMobileView ?  'calc(100vh - 164px)' : 'calc(100vh - 106px)'}}>
            <div className='fixedHeader bg-white px-3 py-2 d-flex justify-content-between align-items-center'>
                <DLText
                    id={'summary-by-country'}
                    type={'normal'}
                    text={'Advanced Fund Search'}
                    fontWeight={'semi-bold'}
                    marginBottom={'none'}
                    fontSize={'xl'}/>
                {((isXSView || isSMView) && !areAllValuesNotEmpty(advanceSearchFilter)) &&
                <i className="fa fa-times cursor ms-3"
                   style={{paddingTop: 6}}
                   onClick={() => resetListingData()}
                />
                }
            </div>
            <div className='pb-2'>
                <div className="d-flex justify-content-between">
                    <div>  {renderSearchBar()}</div>
                    <div className={'d-flex'}>
                        <div style={{width:'100%'}}>
                            {(!isXSView && !isSMView && !areAllValuesNotEmpty(advanceSearchFilter)) &&
                            <div className='mt-3 mt-sm-0 pt-2 me-3'>
                                <DLText
                                    id={'clear'}
                                    marginBottom={'none'}
                                    text={'Clear All'}
                                    customTextStyle={{
                                        color: appTheme.linkStyle.color
                                    }}
                                    isClickable={true}
                                    onClick={() => resetListingData()}
                                    fontSize={'sm'}/>
                            </div>
                            }
                        </div>
                        <div>
                            {(isXSView || isSMView || isMDView) && (!areAllModalFilterValuesNotEmpty(advanceSearchFilter)) &&
                            <div
                                className='d-flex justify-content-center align-items-center downloadButtonCss cursor p-2 mt-1 me-3'
                                onClick={() => {
                                    props.history.push("/money-flow-explorer", {selectedFilters: selectedFilterObj})
                                }}
                            >
                                <DLTooltip tooltipPlacement={'right'}
                                           tooltipLabel={<img src={moneyFlow}
                                                              height={20}
                                                              width={20}
                                           />}
                                           customUi={true}
                                           tooltipContent={<DLText fontSize={'xs'}
                                                                   text={'Money Flow Explorer'}
                                                                   marginBottom={'none'}/>}
                                />

                            </div>
                            }
                        </div>
                        <div>
                            {!areAllValuesNotEmpty(advanceSearchFilter) && !isEmpty(searchResult) &&
                            <div className='me-3 mt-1' style={{
                                width: 36,
                                height: 36,
                                minWidth: 'max-content',
                                marginBottom: isXSView ? 8 : 14
                            }}>
                                <div className={'downloadButtonCss cursor'}>
                                    <i className='fas fa-download fs-6'
                                       onClick={() => {
                                           downloadDataByObject('/service/media/advanceFundSearch/download', "", 'Download', getFileName("Advanced-fund-Search-Result-"), storedDataForDownload)
                                       }}
                                       style={{fontSize: 12}}/>
                                </div>
                            </div>
                            }</div>
                    </div>
                </div>

                <div className={isMobileView ? 'px-3' : 'px-3 d-flex justify-content-between'}>
                    {renderChipsFilterUI()}
                    {((!isMobileView) && !areAllModalFilterValuesNotEmpty(advanceSearchFilter)) &&
                    <DLText text={<u>{'Money Flow Explorer'}</u>}
                            fontSize={'sm'}
                            isClickable={true}
                            onClick={() => {
                                props.history.push("/money-flow-explorer", {selectedFilters: selectedFilterObj})
                            }}
                    />
                    }
                </div>
                <div className='px-sm-3'>
                    <div className='panel '>
                        {renderSearchResult()}
                    </div>
                </div>
            </div>
            {renderAdvanceSearchModal()}
        </div>
    );
}

AdvanceFundSearchView.propTypes = {

};

export default AdvanceFundSearchView;