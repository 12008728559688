import {createSlice} from '@reduxjs/toolkit'
import {setAdvanceStockKeyword} from "../AdvanceSearch/AdvanceSearchReducer";

const appSlice = createSlice({
    name: 'MoneyExplorerReducer',
    initialState: {
        fromDate:"Jul-2023",
        toDate: "Aug-2024",
        drillDownBy: "fundHouse",
        fundsKeyword: '',
        industryKeyword: '',
        fundHouseKeyword: '',
        businessHouseKeyword: '',
        stockKeyword: '',
        marketCapData:[],
        stockListingData:[],
        industryListingData:[],
        businessHouseListingData:[],
        marketCapDataLoaded: false,
        industryListingDataLoaded: false,
        explorerFilter:{
            businessHouses: [],
            fundHouses: [],
            funds: [],
            marketCaps: [],
            industries: [],
            stocks: [],
            fundHouseForFund: [],
        }
    },
    reducers: {
        setDrillDownBy(state, action) {
            state.drillDownBy = action.payload;
        },
        setExplorerFundHouseKeyword(state, action) {
            state.fundHouseKeyword = action.payload;
        },
        setExplorerFundsKeyword(state, action) {
            state.fundsKeyword = action.payload;
        },
        setExplorerIndustryKeyword(state, action) {
            state.industryKeyword = action.payload;
        },
        setExplorerBusinessHouseKeyword(state, action) {
            state.businessHouseKeyword = action.payload;
        },
        setExplorerStockKeyword(state, action) {
            state.stockKeyword = action.payload;
        },

        setExplorerIndustryData(state, action) {
            state.industryListingData = action.payload;
            state.industryListingDataLoaded = true
        },

        setExplorerStocksData(state, action) {
            state.stockListingData = action.payload;
            state.stockListingDataLoaded = true
        },

        setExplorerMarketCapData(state, action) {
            state.marketCapData = action.payload;
            state.marketCapDataLoaded = true
        },

        setExplorerBusinessHousesData(state, action) {
            state.businessHouseListingData = action.payload;
            state.businessHouseListingDataLoaded = true
        },

        setExplorerFilterData(state, action) {
            state.explorerFilter = {
                ...state.explorerFilter,
                [action.payload.name] : action.payload.value
            }
        },

        resetExplorerMarketCapData(state, action) {
            state.marketCapData = [];
            state.explorerFilter = {
                ...state.explorerFilter,
                marketCaps : []
            };
        },

        resetExplorerIndustryData(state, action) {
            state.industryListingData = [];
            state.explorerFilter = {
                ...state.explorerFilter,
                industries : []
            };
        },

        resetExplorerStocksData(state, action) {
            state.stockListingData = [];
            state.explorerFilter = {
                ...state.explorerFilter,
                stocks : []
            };
        },

        resetExplorerBusinessHousesData(state, action) {
            state.businessHouseListingData = [];
            state.explorerFilter = {
                ...state.explorerFilter,
                businessHouses : []
            };
        },

        resetExplorerAllFilter(state, action) {
            state.drillDownBy = [""];
            state.businessHouseListingData = [];
            state.marketCapData = [],
                state.stockListingData = [],
                state.industryListingData = [],
                state.explorerFilter = {
                    ...state.explorerFilter,
                    businessHouses: [],
                    fundHouses: [],
                    funds: [],
                    marketCaps: [],
                    industries: [],
                    stocks: [],
                    fundHouseForFund: [],
                };
        },

        setMoneyExplorerFromDate(state, action) {
            state.fromDate = action.payload
        },

        setMoneyExplorerToData(state, action) {
            state.toDate = action.payload
        },

        resetFromAndToDate(state, action) {
            state.toDate = "Aug-2024",
            state.fromDate = 'Jul-2023'
        },
        resetExternalFromAndToDate(state, action) {
            console.log("action.date",action.payload)
            state.toDate = action.payload,
            state.fromDate = action.payload
        },
    }
});

export const {resetExternalFromAndToDate,resetFromAndToDate, setMoneyExplorerToData, setMoneyExplorerFromDate, setDrillDownBy, setExplorerFundHouseKeyword, setExplorerFundsKeyword, setExplorerIndustryData, setExplorerIndustryKeyword, setExplorerStockKeyword,
    setExplorerBusinessHouseKeyword, setExplorerFilterData, setExplorerStocksData, setExplorerMarketCapData, setExplorerBusinessHousesData,
    resetExplorerMarketCapData, resetExplorerBusinessHousesData, resetExplorerStocksData, resetExplorerIndustryData, resetExplorerAllFilter } = appSlice.actions;
export default appSlice.reducer
