import React, {useEffect, useState} from 'react';
import AdvanceFundSearchView from "./AdvanceFundSearchView";
import {isEmpty} from "../utils/validations";
import DLLoader from "../core/components/DLLoader/DLLoader";
import {advanceFundSearchConst} from "./AdvanceFundSearchConst";
import {connect} from "react-redux";
import {
    resetAllFilterData,
    resetBusinessHouses,
    resetIndustry,
    resetMarketCap,
    resetStocks,
    setAdvanceKeywordFilter,
    setFundHouseKeywordFilter,
    setIndustryKeywordFilter,
    setAdvanceSearchFilter, setBusinessHouseKeywordFilter,
    setFundsKeywordFilter, setStocksKeywordFilter,
    resetFund, resetFundHouse
} from "../AdvanceSearch/AdvanceSeachAction";
import {debounce} from "../utils/helper";
// import {resetFund, resetFundHouse} from "../fund/FundAction";
import {getFundSearchResult} from "./AdvanceFundSearchAction";
const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function AdvanceFundSearch(props) {
    const [advanceFundSearchState, setAdvanceFundSearchState] = useState({
        UIState: '',
        searchResult: [],
        tableHeader: [],
        businessHouses: [],
        funds: [],
        fund_fundHouse : [],
        industries: [],
        stocks: [],
        marketCaps: [],
        keyword: '',
        urlEndPoint: '',
        isDataUpdated:false,
        isFundModalOpen:false,
        isMarketCapModalOpen:false,
        isFundHouseModalOpen:false,
        isBusinessHouseModalOpen:false,
        isIndustryModalOpen:false,
        isStockFundModalOpen:false,

        isExternalFundHouseSelected: props.location?.state?.selectedFundHouse? true : false,
        externalFundHouseData: props.location?.state?.selectedFundHouse,

        isExternalFundSelected: props.location?.state?.selectedFund? true : false,
        externalFundData: props.location?.state?.selectedFund,

        isExternalSectorSelected: props.location?.state?.selectedSector? true : false,
        externalSectorData: props.location?.state?.selectedSector,

        isExternalStockSelected: props.location?.state?.selectedStock? true : false,
        externalStockData: props.location?.state?.selectedStock,

        isExternalClick :false,
        storedDataForDownload: []
    });

    useEffect(()=>{
        if (props.history.action === "POP" || props.history.action === "REPLACE"){
            //backward navigation
        }else {
            resetListingData()
        }

        setTimeout(()=>{
            setAdvanceFundSearchState((prev) => ({
                ...prev,
                isDataUpdated: true,
                isExternalClick : checkIsExternalClick()
            }));

        },0);



        return () => {
            setFundsKeywordFilter('');
            setFundHouseKeywordFilter('');
            setIndustryKeywordFilter('');
            setBusinessHouseKeywordFilter('');
            setStocksKeywordFilter('')
        };
    },[]);

    const extractData = (data,type) =>{
        if(isEmpty(data)){
            return [];
        }
        return data.map(item => item[type])
    };

    useEffect(()=>{
        const {businessHouses, marketCaps, fundsData, fundHousesData, stocksData, sectorsData} = props.advanceSearchFilter;
        if (advanceFundSearchState.isDataUpdated) {

            let data = {
                keyword: props.keyword,
                businessHouse: businessHouses,
                fundHouse: extractData(fundHousesData,"fund_house_id"),
                fund:extractData(fundsData,"fund_id"),
                marketCap: marketCaps,
                industry: extractData(sectorsData,"industry"),
                stock: extractData(stocksData,"isin_code"),
                fundHouseForFund: extractData(fundsData,"fund_house_id"),
                date: VALUATION_DATE
            };

            setAdvanceFundSearchState((prev) => ({
                ...prev,
                isFilterAPIInProgress: true,
                storedDataForDownload: data
            }));
            getResult(data);
        }
    },[props.advanceSearchFilter, props?.keyword, advanceFundSearchState.isDataUpdated]);

    const getResult =debounce( (data) =>{
        getFundSearchResult(data, VALUATION_DATE).then((res)=>{
            if (res.success){
                if (!isEmpty(res.data?.data)){
                    setAdvanceFundSearchState((prev)=>({
                        ...prev,
                        searchResult: res.data.data.slice(1, res.data.data.length),
                        tableHeader: res.data.data[0],
                        isFilterAPIInProgress: false
                    }));
                }else {
                    setAdvanceFundSearchState((prev)=>({
                        ...prev,
                        searchResult: [],
                        tableHeader: [],
                        isFilterAPIInProgress: false
                    }));
                }
            }

            setAdvanceFundSearchState((prev)=>({
                ...prev,
                UIState: advanceFundSearchConst.CONTENT_AVAILABLE,
                isFilterAPIInProgress: false
            }));
        })
    },300);

    const onChangeKeywordFilter = (keyword) => {
        setAdvanceKeywordFilter(keyword)
    };

    const checkIsExternalClick = ()=>{
        const {isExternalSectorSelected, isExternalFundHouseSelected, isExternalFundSelected}= advanceFundSearchState;
        if(isExternalSectorSelected || isExternalFundHouseSelected || isExternalFundSelected){
            return true;
        }else{
            return false;
        }
    };

    const handleChipClick = (action, type, listingArr) =>{
        if (action === 'apply_filter'){
            setAdvanceFundSearchState((prev)=>({
                ...prev,
                [type]: true
            }));
        }

        if (action === 'remove_filter'){
            switch (type) {
                case 'funds':
                    resetFund();
                    break;
                case 'fundHouses':
                    resetFundHouse();
                    break;
                case 'marketCaps':
                    resetMarketCap();
                    break;
                case 'industries':
                    resetIndustry();
                    break;
                case 'stocks':
                    resetStocks();
                    break;
                case 'businessHouses':
                    resetBusinessHouses();
                    break;
                default:
            }
            setAdvanceFundSearchState((prev)=>({
                ...prev,
                [type]: [],
                [listingArr]: []
            }));
        }
    };

    const onChangeValue = (name, value) =>{
        setAdvanceFundSearchState((prev)=>({
            ...prev,
            [name]: value
        }));
    };

    const setFilter = (name, selectedValues, idVar) => {
        const {isExternalClick} = advanceFundSearchState;
        let values = [];

        switch(name){
            case 'funds' :
                 values = selectedValues.map(item => ({
                    fund_house_id: item.fund_house_id,
                    fund_id: item.fund_id,
                    fund_name: item.fund_name
                }));
                setAdvanceSearchFilter('fundsData', values);
                break;
            case 'stocks' :
                values = selectedValues.map(item => ({
                                isin_code: item.isin_code,
                                company_name: item.company_name
                            }));
                setAdvanceSearchFilter('stocksData', values);
                break;
            case 'fundHouses' :
                values = selectedValues.map(item => ({
                                fund_house_id: item.fund_house_id,
                                fund_house_name: item.fund_house_name
                            }));

                setAdvanceSearchFilter('fundHousesData', values);
                break;
            case 'industries' :
                values = selectedValues.map(item => ({
                            industry: item.industry,
                        }));

                   setAdvanceSearchFilter('sectorsData', values);
                break;
            case 'businessHouses' :
                    selectedValues.forEach((item) => {
                        values.push(item[idVar])
                    });
                    setAdvanceSearchFilter(name, values);
                break;
            case 'marketCaps' :
                selectedValues.forEach((item) => {
                    values.push(item[idVar])
                });
                setAdvanceSearchFilter(name, values);
                 break;
            default:
                break;
        }

        if (isExternalClick) {
            setAdvanceFundSearchState((prev) => ({
                ...prev,
                //isExternalSectorSelected: false,
                externalSectorData: [],
                externalFundData: [],
                externalFundHouseData: []
            }));
        }
    };

    const resetListingData = () =>{
        resetAllFilterData();
        resetFund();
        resetFundHouse()
    };

    if (isEmpty(advanceFundSearchState?.UIState)){
        return <DLLoader type={"screen"} isVisible={true}/>
    }

    return (
        <AdvanceFundSearchView {...advanceFundSearchState}
                           {...props}
                           handleChipClick={handleChipClick}
                           onChangeValue={onChangeValue}
                           setFilter={setFilter}
                           resetListingData={resetListingData}
                           onChangeKeywordFilter={onChangeKeywordFilter}
                           extractData={extractData}
        />
    );
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    keyword: state.AdvanceSearchState.keyword,
    advanceSearchFilter: state.AdvanceSearchState.advanceSearchFilter,
    fundHouseListingData: state.AdvanceSearchState.fundHouseListingData,
    fundListingData: state.AdvanceSearchState.fundListingData,
    marketCapData: state.AdvanceSearchState.marketCapData,
    industryListingData: state.AdvanceSearchState.industryListingData,
    stockListingData: state.AdvanceSearchState.stockListingData,
    businessHouseListingData: state.AdvanceSearchState.businessHouseListingData,
    storedPage : state.AdvanceFundSearchState.storedPage
});

export default connect(mapStateToProps, {})(AdvanceFundSearch);