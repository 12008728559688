import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {getFundSummary, setFundList} from "../../fund/FundAction";
import {advanceSearchConst} from "../AdvanceSearchConst";
import {
    setAdvanceKeywordFilter,
    setFundsKeywordFilter,
    setStocksKeywordFilter,
    setStocksList
} from "../AdvanceSeachAction";
import {isEmpty} from "../../utils/validations";
import {getChartData, searchOnWordBoundary} from "../../utils/helper";
import {getListingData} from "../../utils/stocksHelper";
import AdvanceStocksSearchModalView from "./AdvanceStocksSearchModalView";
import {getStocksSummary} from "../../stocks/StocksActions";
const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function AdvanceStocksSearchModal(props) {
    const{isExternalStockSelected, externalStockData, setFilter}=props;
    const [advanceStockListingModalState, setAdvanceStockListingModalState] = useState({
        modalUIState: '',
        sortedData: [],
        previousStockListing: [],
        skipCount: 0,
        limitCount: 60
    });

    console.log("externalStockData",externalStockData);
    useEffect(()=>{
        const {skipCount, limitCount} = advanceStockListingModalState;
        if (props.isStockFundModalOpen || isExternalStockSelected){
            if (!isEmpty(props.stockListingData)){
                setAdvanceStockListingModalState((prev)=>({
                    ...prev,
                    modalUIState: advanceSearchConst.CONTENT_AVAILABLE,
                    previousStockListing: props.stockListingData
                }));
            }else {
                getStocksSummary(VALUATION_DATE, skipCount, limitCount).then((res) => {
                    if (res.success && !isEmpty(res.data?.data)) {
                        let data = getListingData(res.data.data, 'company_name');
                        // store.dispatch(setFunds(data));
                        data = handleExternalCheckChange(externalStockData, data)
                        setStocksList(data);
                        applyExternalFilter(data)

                        setAdvanceStockListingModalState((prev)=>({
                            ...prev,
                            modalUIState: advanceSearchConst.CONTENT_AVAILABLE
                        }));

                    }else {
                        setAdvanceStockListingModalState((prev)=>({
                            ...prev,
                            modalUIState: advanceSearchConst.CONTENT_UNAVAILABLE
                        }));
                    }
                })
            }
        }
    },[props.isStockFundModalOpen, isExternalStockSelected]);

    useEffect(()=>{
        const {stockKeyword, isMobileView, isXSView} = props;
        const {stockListingData} = props;

        if (isEmpty(stockListingData)){
            return
        }

        if (isEmpty(stockKeyword)){
            setStocksList(stockListingData);

            setAdvanceStockListingModalState((prev)=>({
                ...prev,
                sortedData: [],
                searchResultNotAvailable: false
            }));
        }else {
            let sortedData = [];
            setAdvanceStockListingModalState((prev)=>({
                ...prev,
                isSearchApply: true
            }));

            let options =  stockListingData.map((item)=>{
                return {...item, label:item.company_name, value: item.company_name}
            });

            sortedData = searchOnWordBoundary(props.stockKeyword, JSON.parse(JSON.stringify(options)));

            if (!isEmpty(sortedData)){

                setAdvanceStockListingModalState((prev)=>({
                    ...prev,
                    sortedData: sortedData,
                    searchResultNotAvailable: false
                }));
            }else {
                setAdvanceStockListingModalState((prev)=>({
                    ...prev,
                    sortedData: [],
                    searchResultNotAvailable: true
                }));
            }

            setTimeout(()=>{
                setAdvanceStockListingModalState((prev)=>({
                    ...prev,
                    isSearchApply: false
                }))
            },300)

        }

    },[props.stockKeyword]);

    const handleCheckboxChange = (value) => {
        const {stockListingData} = props;

        const updatedArray = stockListingData.map(item => {
            if (item.isin_code === value.isin_code) {
                return { ...item, isSelected: !item.isSelected };
            }

            // Return the original object if the id doesn't match
            return item;
        });
        setStocksList(updatedArray)

    };

    const clearSelectedFunds = (value) => {
        const {stockListingData} = props;

        const updatedArray = stockListingData.map(item => {
            return { ...item, isSelected: false };
        });
        setStocksList(updatedArray)

    };

    const handleExternalCheckChange = (value, data) => {
        //const {fundHouses} = props.advanceSearchFilter;
        let updatedArray = [];
        if (isEmpty(value)) {
            return data;
        }
        // if(!isEmpty(externalFundStocksKeyword)){
        //     setAdvanceKeywordFilter(externalFundStocksKeyword)
        // }
        console.log('stock value', value);
        if (Array.isArray(value)) {
            updatedArray = data.map(item => {
                const isMatch = value.some(val => val.isin_code === item.isin_code); // Corrected this line
                if (isMatch) {
                    return { ...item, isSelected: !item.isSelected };
                }
                return item;
            });
        } else if (typeof value === 'object' && value !== null) {
            updatedArray = data.map(item => {
                if (item.isin_code === value.isin_code) {
                    return { ...item, isSelected: !item.isSelected };
                }
                return item;
            });
        } else {
            console.error('Invalid value type');
        }

        return updatedArray;

    };

    const checkExternalSelectedValues = (data) => {
        return  data.filter((item)=>item.isSelected);
    };

    const applyExternalFilter =(data)=>{
        let selectedValues = checkExternalSelectedValues(data);
        setFilter('stocks', selectedValues, 'isin_code')
        props.history.replace();
    }

    const checkboxChange = (value) => {
        const {stockListingData} = props;
        // console.log('updatedArray[index]', !value)

        // Make a copy of the object at the specified index
        const updatedItem = stockListingData.find(item =>  (item.isin_code === value.isin_code));

        return updatedItem?.isSelected
    };

    const onChangeKeywordFilter = (stockKeyword) => {
        setStocksKeywordFilter(stockKeyword)
    };

    const checkSelectedValues = () => {
        const {stockListingData} = props;
        return  stockListingData.filter((item)=>item.isSelected);
    };

    return (
        <AdvanceStocksSearchModalView {...advanceStockListingModalState}
                                     {...props}
                                     handleCheckboxChange={handleCheckboxChange}
                                     checkboxChange={checkboxChange}
                                     checkSelectedValues={checkSelectedValues}
                                     clearSelectedFunds={clearSelectedFunds}
                                     onChangeKeywordFilter={onChangeKeywordFilter}/>
    )
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    fundsData: state.fundState.fundsData,
    fundsLoaded: state.fundState.fundsLoaded,
    stockKeyword: state.AdvanceSearchState.stockKeyword
});

export default connect(mapStateToProps, {})(AdvanceStocksSearchModal);
