import * as React from 'react';
import PropTypes from 'prop-types';
import {alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import Paper from '@mui/material/Paper';
import {visuallyHidden} from '@mui/utils';
import {Link, withRouter} from "react-router-dom";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
    const {headingData, order, orderBy, onRequestSort} =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headingData.map((heading) => (
                    <TableCell
                        key={heading.id}
                        sx={{width: 10}}
                        align={heading.numeric ? 'right' : 'left'}
                        padding={'normal'}
                        sortDirection={orderBy === heading.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === heading.id}
                            direction={orderBy === heading.id ? order : 'asc'}
                            onClick={createSortHandler(heading.id)}>
                            {heading.label}
                            {orderBy === heading.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    headingData: PropTypes.array.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const extractColumnHeadings = (rowData) => {
    let headingData = []
    Object.keys(rowData).forEach(function (key, index) {
        let numeric = true;
        if (isNaN(rowData[key])) {
            numeric = false
        }
        if (key !== 'isin_code') {
            let head = {
                id: key,
                label: key,
                numeric: numeric
            }
            headingData.push(head);
        }
    });
    return headingData;
}


const StocksTable = (props) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const {data} = props;
    let headingData = extractColumnHeadings(data[0]);
    let rows = data;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    const renderRows = (row) => {
        Object.keys(row).forEach(function (key, index) {
            return (
                <div>
                    hello
                    <TableCell align="left">{row[key]}</TableCell>
                </div>
            );
        })
    };

    return (
        <div className="w-100 pagination-container">
            <Box sx={{width: '100%'}}>
                <Paper sx={{width: '100%', mb: 2}}>
                    <TableContainer style={{maxHeight: 400}}>
                        <Table className="mx-3"
                            sx={{minWidth: 750}}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                            stickyHeader>
                            <EnhancedTableHead
                                headingData={headingData}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.isin_code}
                                            selected={isItemSelected}
                                            sx={{cursor: 'pointer', border:'none', backgroundColor: (index % 2) === 0 ? '#F8F8FC': 'white'}}
                                        >
                                            <TableCell align="left">
                                                <Link id='stock-link' to={'/stocks/' + row.isin_code}>
                                                    {row.company_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell
                                                align="right">{(row.total_market_value).toFixed(2)}</TableCell>
                                            <TableCell align="right">{row.quantity.toFixed(2)}</TableCell>
                                            <TableCell
                                                align="right">{(row.market_value_change_mom).toFixed(2)}</TableCell>
                                            <TableCell
                                                align="right">{row.percentage_market_value_change.toFixed(2) + "%"}</TableCell>
                                            <TableCell align="right">{row.quantity_change_mom.toFixed(2)}</TableCell>
                                            <TableCell
                                                align="right">{row.percentage_quantity_change.toFixed(2) + "%"}</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows,
                                        }}>
                                        <TableCell colSpan={6}/>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        </div>
    );
};

export default withRouter(StocksTable);
