import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {isEmpty} from "../../../utils/validations";
import {stocksConst} from "../../../stocks/stocksConst";
import DLText from "dlcomponent/components/DLText/DLText";
import BarChart from "../../BarChart/FirstViewBarChart";
import PopComponent from "../../../stocks/PopComponent";
import {filterNetBuyingSellingValue, numberWithCommas} from "../../../utils/helper";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
// TODO use lazy load for each component which is firing the api
function NetBuyingSellingBarChartView(props) {

    const {selectedStock, UIState, sceenerCode, netBuyingSellingSum, handleOpenModal,requiredIsin, id, stockName} = props;
    const [activeSection, setActiveSection] = useState('section1');

    const getNetBuyingSellingTextColor = (value)=>{
        if (value > 0) {
            return(
                <DLText
                    id={'revenue-value'}
                    type={'normal'}
                    text={numberWithCommas(value)}
                    fontColor={"success"}
                    fontSize={'md'}/>
            );
        }else if (value < 0) {
            return(
                <DLText
                    id={'revenue-value'}
                    type={'normal'}
                    text={numberWithCommas(value)}
                    fontColor={"danger"}
                    fontSize={'md'}/>
            );
        }else{
            return(
                <DLText
                    id={'revenue-value'}
                    type={'normal'}
                    text={numberWithCommas(value)}
                    fontSize={'md'}/>
            );
        }
    };

    const renderMobileView = () =>{
        const {activitiesStateLoaded, isXSView, yAxisBar, barColor, yAxisLine, xAxis, isLGView, activitiesData, fundHouseCount, fundSchemeCount, marketValue} = props;

        return (
            <div className=''>
                <div className="p-2" style={{backgroundColor: ""}}>
                    <div className='w-100'>
                        <BarChart {...props} id={id} isBarChartClickable={true} getBarClick={getBarClick} />
                    </div>
                </div>
            </div>
        )
    };

    const getBarClick =(e)=>{
        const {xAxis,yAxisBar, xAxisWithYear} = props;
        if(isEmpty(e)){
            return;
        }
        let graphMonth = xAxisWithYear.find((item, index)=>{
            return index === e;
        })
        handleOpenModal(graphMonth)
        // console.log("in bar click",e,xAxis, graphMonth,props)

    }

    const renderUI = () => {
        return renderMobileView();
    };

    if (UIState === stocksConst.CONTENT_UNAVAILABLE) {
        return
    }
    // if (isEmpty(selectedStock?.company_name)) {
    //     return <DLLoader type={'screen'} isVisible={true}/>
    // }

    const activeStyle = {
        fontWeight: 500,
        color: '#282828',
        textDecoration: 'underline',
        fontFamily: 'Poppins, sans-serif',

    };
    props.handleChange();

    return (
        <>
            <div className="bg-white ">
                {/*<div className="text-end">    <i className="fa fa-remove m-2 cursor" onClick={() => {*/}
                {/*    clearOption(buttonIdReference)*/}
                {/*}} style={{color: appTheme.appColor.black}}/>*/}
                {/*</div>*/}
                <div className="d-flex justify-content-between align-items-start p-2 w-100">
                    <DLText marginBottom={'none'}
                            isInline={true}
                            text={!isEmpty(stockName)? stockName : ""}
                            onClick={() => {
                                props.history.push("/stocks/" + requiredIsin)
                            }}
                            fontWeight={'semi-bold'}
                            isClickable={true}
                            fontColor={"#0073FF"}
                            fontSize={'xs'}/>
                    <div className="text-center me-5">
                        {getNetBuyingSellingTextColor(filterNetBuyingSellingValue(netBuyingSellingSum))}
                    </div>

                </div>
                <div id={'myDiv'}>
                    <div className=''>
                        {renderUI()}
                        {
                            <PopComponent {...props}/>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

NetBuyingSellingBarChartView.propTypes = {};

export default NetBuyingSellingBarChartView;
