import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {explorerConst} from "../ExplorerConsts";
import {getBusinessHouses} from "../../AdvanceSearch/AdvanceSeachAction";
import {setBusinessHouseKeywordFilter, setBusinessHousesList} from "../ExplorerAction";
import {isEmpty} from "../../utils/validations";
import {searchOnWordBoundary} from "../../utils/helper";
import ExplorerBusinessHouseSearchModalView from "./ExplorerBusinessHouseSearchModalView";
const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function ExplorerBusinessHouseSearchModal(props) {
    const [businessHouseListingModalState, setBusinessHouseListingModalState] = useState({
        modalUIState: '',
        sortedData: [],
        previousBusinessHouseListingData: []
    });
    const{ setFilter} = props ;
    const getListingData = (lisitingData, sortingValues) =>{
        let listing = lisitingData.slice(0, lisitingData.length);

        listing.sort((a, b) => {
            const nameA = a[sortingValues].toLowerCase();
            const nameB = b[sortingValues].toLowerCase();

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        return listing.map((item)=>{
            return {...item, isSelected: false}
        })
    };
    const handleExternalCheckChange = (value, data) => {
        if (isEmpty(value)){
            return data
        }

        return data.map(item => ({
            ...item,
            isSelected: value.some(obj2 => obj2.business_house === item.business_house),
        }));

    };

    const updateBusinessHouseData = (data) => {
        const {businessHouses} = props.explorerFilter;

        let updatedArray = data.map(item => {
            if (businessHouses.includes(item.business_house)) {
                return { ...item, isSelected: true };
            }
            return item;
        });
        return updatedArray
    };

    const applyExternalFilter =(data)=>{
        let selectedValues = checkExternalSelectedValues(data);
        setFilter('businessHouses', selectedValues, 'business_house');
        props.history.replace();
    };

    const checkExternalSelectedValues = (data) => {
        return  data.filter((item)=>item.isSelected);
    };

    useEffect(()=>{
        const {isExternalBusinessHouseSelected} = props;
        if (isExternalBusinessHouseSelected){
            getBusinessHouses(VALUATION_DATE).then((res) => {
                if (res?.statusCode === 200 || res?.statusCode === 201) {
                    let data = getListingData(res.data.data, 'business_house');
                    data = handleExternalCheckChange(props.selectedBusinessHouse, data);
                    setBusinessHousesList(data);
                    setBusinessHouseListingModalState((prev)=>({
                        ...prev,
                        modalUIState: explorerConst.CONTENT_AVAILABLE
                    }));

                }else {
                    setBusinessHouseListingModalState((prev)=>({
                        ...prev,
                        modalUIState: explorerConst.CONTENT_UNAVAILABLE
                    }));
                }
            })
        }

        if (props.isBusinessHouseModalOpen){
            if (!isEmpty(props.businessHouseListingData)){
                setBusinessHouseListingModalState((prev)=>({
                    ...prev,
                    modalUIState: explorerConst.CONTENT_AVAILABLE,
                    previousBusinessHouseListingData: props.businessHouseListingData
                }));
            }else {
                getBusinessHouses(VALUATION_DATE).then((res) => {
                    if (res?.statusCode === 200 || res?.statusCode === 201) {
                        let data = getListingData(res.data.data, 'business_house');
                        // data = handleExternalCheckChange(props.selectedBusinessHouse, data);
                        data =  updateBusinessHouseData(data);
                        setBusinessHousesList(data);
                        applyExternalFilter(data);

                        setBusinessHouseListingModalState((prev)=>({
                            ...prev,
                            modalUIState: explorerConst.CONTENT_AVAILABLE
                        }));

                    }else {
                        setBusinessHouseListingModalState((prev)=>({
                            ...prev,
                            modalUIState: explorerConst.CONTENT_UNAVAILABLE
                        }));
                    }
                })
            }
        }
    },[props.isBusinessHouseModalOpen, props.isExternalBusinessHouseSelected]);

    useEffect(()=>{
        const {businessHouseKeyword, isMobileView, isXSView} = props;
        const {businessHouseListingData} = props;

        if (isEmpty(businessHouseListingData)){
            return
        }

        if (isEmpty(businessHouseKeyword)){
            props.onChangeValue('businessHouseListingData', businessHouseListingData);

            setBusinessHouseListingModalState((prev)=>({
                ...prev,
                sortedData: [],
                searchResultNotAvailable: false
            }));
        }else {
            let sortedData = [];
            setBusinessHouseListingModalState((prev)=>({
                ...prev,
                isSearchApply: true
            }));

            let options =  businessHouseListingData.map((item)=>{
                return {...item, label:item.business_house, value: item.business_house}
            });

            sortedData = searchOnWordBoundary(props.businessHouseKeyword, JSON.parse(JSON.stringify(options)));

            if (!isEmpty(sortedData)){

                setBusinessHouseListingModalState((prev)=>({
                    ...prev,
                    sortedData: sortedData,
                    searchResultNotAvailable: false
                }));
            }else {
                setBusinessHouseListingModalState((prev)=>({
                    ...prev,
                    sortedData: [],
                    searchResultNotAvailable: true
                }));
            }

            setTimeout(()=>{
                setBusinessHouseListingModalState((prev)=>({
                    ...prev,
                    isSearchApply: false
                }))
            },300)

        }

    },[props.businessHouseKeyword]);

    const handleCheckboxChange = (value) => {
        const {businessHouseListingData} = props;
        const updatedArray = businessHouseListingData.map((item, index) => {
            if (item.business_house === value.business_house) {
                console.log('values',item.business_house, value.business_house, index)

                return { ...item, isSelected: !item.isSelected };
            }

            // Return the original object if the id doesn't match
            return item;
        });
        setBusinessHousesList(updatedArray)

        // props.onChangeValue('businessHouseListingData', updatedArray);

    };

    const clearSelectedFunds = (value) => {
        const {businessHouseListingData} = props;

        const updatedArray = businessHouseListingData.map(item => {
            return { ...item, isSelected: false };
        });
        setBusinessHousesList(updatedArray)

        // props.onChangeValue('businessHouseListingData', updatedArray);

    };

    const checkboxChange = (value) => {
        const {businessHouseListingData} = props;
        // console.log('updatedArray[index]', !value)

        // Make a copy of the object at the specified index
        const updatedItem = businessHouseListingData.find(item =>  (item.id === value.id));

        return updatedItem?.isSelected
    };

    const onChangeKeywordFilter = (businessHouseKeyword) => {
        setBusinessHouseKeywordFilter(businessHouseKeyword)
    };

    const checkSelectedValues = () => {
        const {businessHouseListingData} = props;
        return  businessHouseListingData?.filter((item)=>item.isSelected);
    };

    return (
        <ExplorerBusinessHouseSearchModalView {...businessHouseListingModalState}
                                              {...props}
                                              handleCheckboxChange={handleCheckboxChange}
                                              checkboxChange={checkboxChange}
                                              checkSelectedValues={checkSelectedValues}
                                              clearSelectedFunds={clearSelectedFunds}
                                              onChangeKeywordFilter={onChangeKeywordFilter}/>
    )
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    businessHouseKeyword: state.ExplorerState.businessHouseKeyword
});

export default connect(mapStateToProps, {})(ExplorerBusinessHouseSearchModal);