import React, {useEffect, useState} from 'react'
import DLLoader from "../../core/components/DLLoader/DLLoader";
import DLText from "dlcomponent/components/DLText/DLText";
import DLButton from "dlcomponent/components/DLButton/DLButton";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FundSummaryChart from "./FundSummaryChart";
import FundSummaryTable from "./FundSummaryTable";
import CustomTabPanel from "../../component-utils/CustomTabPanel/CustomTabPanel";
import {downloadExcel} from "../../utils/downloadExcel";
import ChartRadioGroup from "../../component-utils/ChartRadioGroup/ChartRadioGroup";
import {getSummaryByFunds} from "../FundHouseAction";
import {withRouter} from "react-router-dom";
import SummaryCardFundHouse from "../../stocks/SummaryCardFundHouse/SummaryCardFundHouse";
import {isEmpty} from "../../utils/validations";
import IndustryChart from "../../industry/IndustryListing/IndustryChart";
import {
    getChartData,
    getFixedValuesAfterDecimal,
    getIndexArr,
    getTrimVal,
    searchOnWordBoundaryOverview
} from "../../utils/helper";
import {downloadData} from "../../fund/FundAction";
import SummaryByFundHouseChart from "../../stocks/SummaryByFundHouse/SummaryByFundHouseChart";
import {getFundsTrimVal} from "../../utils/stocksHelper";
import appTheme from "../../assets/appTheme";
import DLInputTextField from "dlcomponent/components/DLInputTextField/DLInputTextField";
import searchIcon from "../../assets/img/Advanced Search Icon.png";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



function FundSummary(props) {

    const {fundHouseId, isMobileView, selectedFundHouse, isMDView} = props;
    const [chartLabelsArray, setChartLabelsArray] = useState([]);
    const [chartDataArray, setChartDataArray] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [tableHeader, setTableHeader] = useState([]);
    const [chartType, setChartType] = useState('marketValue');
    const [searched, setSearched] = useState("");
    const[isShowFilter,setIsShowFilter] = useState(false);
    const[filteredArray,setFilteredArray] = useState([]);




    useEffect(() => {

        getSummaryByFunds(VALUATION_DATE, fundHouseId).then((res) => {
            setIsLoading(false);
            if (res.success && !isEmpty(res.data?.data)) {
                setTableData(res.data.data.slice(1, res.data.data.length));
                setTableHeader(res.data.data[0]);
                setFundSummaryGraph(res.data.data.slice(1, res.data.data.length));
            }
        })

    }, []);

    const handleClick = (elementIndex) => {
        let indexArr = getIndexArr(chartType, filteredArray);

            let clickedFund = indexArr[elementIndex];
            props.history.push('/funds/' + clickedFund.fund_house_id + "/" + clickedFund.fund_id)

    };

    const requestSearch = (searchedVal) => {
        if(isEmpty(searchedVal)){
            setSearched('');
            setFundSummaryGraph(tableData)
        }else{
            let data = getFilteredGraphData(tableData, searchedVal)
            setSearched(searchedVal);
            setFundSummaryGraph(data);
        }

    };

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };



    const setFundSummaryGraph = (data) => {
        const {isLGView, isXLView, isMobileView, isXSView} = props;
        let  chartLabels = [];
        let  chartData = [];
        let filteredData= []
        let length = data.length;

        // if (data.length > 10) {
        //     length = 10;
        // }

        for (let i = 0; i < length; i++) {

            let fundName = getFundsTrimVal((data[i].fund_name)?.trim(), isLGView, isXLView, isMobileView, isXSView);
            let marketValue = getFixedValuesAfterDecimal(data[i].total_market_value,2);

            filteredData.push(data[i])
            chartLabels.push(fundName);
            chartData.push(marketValue);
        }

            let chartObj = getChartData(chartType, filteredData, 'fund_name', isLGView, isXLView,isMobileView);
        setFilteredArray(filteredData)
        setChartLabelsArray(chartObj.newLabelData);
        setChartDataArray(chartObj.newChartData);
    };

    const [isLoading, setIsLoading] = useState(true);

    const deepCopy = (obj) => {
        return JSON.parse(JSON.stringify(obj));
    }

    //order by descending
    const compareByMarketValueChangeMoM = (a, b) => {
        if (a.net_buying_selling < b.net_buying_selling) {
            return 1;
        }
        if (a.net_buying_selling > b.net_buying_selling) {
            return -1;
        }
        return 0;
    }

    const compareByMarketValueChangePercent = (a, b) => {
        if (a.net_buying_selling_percentage < b.net_buying_selling_percentage) {
            return 1;
        }
        if (a.net_buying_selling_percentage > b.net_buying_selling_percentage) {
            return -1;
        }
        return 0;
    }


    const handleRadioChange = (event) => {
        const {isLGView, isXLView,isMobileView, isXSView} = props;
        let chartType =  event ;
        let newChartData = [];
        let newLabelData = [];
        const filteredRows = filteredArray.filter((row) => {
            return row?.fund_name.toLowerCase().includes(searched.toLowerCase());
        });
        let chartObj = getChartData(chartType, filteredRows, 'fund_name', isLGView, isXLView,isMobileView, isXSView);

        setPercentage(chartType === 'changePercent' || chartType === 'changeSellingPercent');
        setChartType(chartType);
        setChartLabelsArray(chartObj?.newLabelData);
        setChartDataArray(chartObj?.newChartData);
    };

    const getResult = (data) =>{
        if (isEmpty(data)){
            return
        }

        if (data?.length >= 10){
            return(
                <div className='py-2 mx-3'>
                    Showing top 10 results
                </div>
            )
        }else {
            if (data.length === 1){
                return (
                    <div className='py-2 mx-3'>
                        Showing {data?.length} result
                    </div>
                )
            }else {
                return (
                    <div className='py-2 mx-3'>
                        Showing {data?.length} results
                    </div>
                )

            }
        }
    };

    const getFilteredGraphData =(data, searchedVal)=>{
        const filteredRows = searchOnWordBoundaryOverview(searchedVal,data,"fund_name")
        // const filteredRows = data.filter((row) => {
        //     return row.fund_name.toLowerCase().includes(searchedVal.toLowerCase());
        // });

        return filteredRows;

    }

    const handleOnBlur =()=>{
        if(isEmpty(searched)){
            setIsShowFilter(false);
        }

    }

    const emptySearch =()=>{
        requestSearch("")
        document.getElementById("businessList-keywordSearch-input").focus();
    }


    const {fileName} = props;
    if (isLoading) {
        return (
            <div className={'row px-2 h-100'}>
                <div className={"col-12 d-flex flex-column justify-content-center align-items-center mb-1"}>
                    <DLLoader type={'inline'} isVisible={true}/>
                </div>
            </div>
        )
    }
    return (
        <div className='panel' id='fund-summary'>
            <div className='d-flex justify-content-between px-3 pt-3'>
                <div className='d-flex justify-content-center align-items-center'>
                    <DLText
                        id={'summary-by-country'}
                        type={'normal'}
                        text={'Funds'}
                        marginBottom={"none"}
                        fontWeight={'semi-bold'}
                        fontSize={"lg"}
                    />
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div className='ps-3 table-search'>
                            {(isShowFilter) ?
                               <>

                                <DLInputTextField
                                    id={'businessList-keywordSearch'}
                                    label={''}
                                    rows={1}
                                    fullWidth
                                    styleType={"search"}
                                    placeholder={'Search...'}
                                    value={searched}
                                    onChangeValue={(searchVal) => requestSearch(searchVal)}
                                    isClearable={true}
                                    inputHeight={'sm'}
                                    marginBottom={"none"}
                                    autoFocus={true}
                                    onBlur={()=>{handleOnBlur()}}
                                    InputProps={{
                                        endAdornment: (

                                            !isEmpty(searched) ?
                                                <i className="fa fa-remove m-2 cursor" onClick={()=>{emptySearch()}} style={{color: appTheme.appColor.black}}/>
                                                :
                                                <i className={'fas fa-search pr-1'} style={{color: appTheme.appColor.black}}/>
                                        )
                                    }}
                                    disableHelperMessageHeight={true}
                                />
                                </>


                            :
                                <i className="fa-solid fa-magnifying-glass cursor" onClick={()=>{
                                setIsShowFilter(true)
                            }}/>}
                        </div>
                    </div>
                </div>
                <div className="d-flex">
                    { !isMobileView || isMDView ?
                        <DLButton id={'somethingWentWrong-refresh-button'}
                                  label={'Advanced Fund Search'}
                                  fontSize={'sm'}
                                  buttonSize={'xs'}
                                  style={{border: "0.5px solid var(--black)", color: "var(--black)"}}
                                  styleType={'outlined'}
                                  onClick={() => {
                                      props.history.push("/advanced-fund-search",{selectedFundHouse : selectedFundHouse, stockFilter: searched})
                                  }}/>

                        :
                        <div className="mx-1">
                            <div className='d-flex justify-content-center align-items-center downloadButtonCss cursor p-2'
                                 onClick={() => {
                                     props.history.push("/advanced-fund-search", {selectedFundHouse: selectedFundHouse,stockFilter: searched})
                                 }}
                            >
                                <img src={searchIcon}
                                     height={19}
                                     width={18}
                                />
                            </div>
                        </div>
                    }
                <div className='ms-1' style={{width: 36, height: 36, minWidth: 'max-content'}}>
                    <div className='border-button'>
                        {/*<DLButton*/}
                        {/*    id={'download-excel'}*/}
                        {/*    styleType={"outlined"}*/}
                        {/*    borderType={'square'} buttonSize={'sm'}*/}
                        {/*    sideMargin={"none"}*/}
                        {/*    style={{width:36}}*/}
                        {/*    reduceWidth={true}*/}
                        {/*    type={'primary'}*/}
                        {/*    onClick={() => {*/}
                        {/*        downloadData('/service/media/fundHouse/fundScheme/download?date='+VALUATION_DATE+'&fundHouseId=' + fundHouseId, fundHouseId, 'Download', fileName)*/}
                        {/*    }}*/}
                        {/*    label={<i className='fas fa-download fs-6'/>}*/}
                        {/*/>*/}
                        <div className='d-flex align-items-center justify-content-center downloadButtonCss cursor'>
                            <i className='fas fa-download fs-6'
                               onClick={() => {
                                   downloadData('/service/media/fundHouse/fundScheme/download?date='+VALUATION_DATE+'&fundHouseId=' + fundHouseId, fundHouseId, 'Download', fileName)
                               }}
                               style={{fontSize: 12}}/>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <Box sx={{width: '100%'}}>
                <Box className='d-flex justify-content-between'
                     sx={{ borderBottom: 1, borderColor: 'divider', marginLeft: '20px' ,marginRight:'20px' }}>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Chart" {...a11yProps(0)} sx={{ textTransform:'capitalize'}}/>
                        <Tab label="Table" {...a11yProps(1)} sx={{ textTransform:'capitalize'}}/>
                    </Tabs>

                </Box>
                <CustomTabPanel value={tabValue}
                                index={0}
                                id="tableTabPanel">
                    {(isEmpty(chartDataArray) || isEmpty(chartLabelsArray)) ?
                        <div className='d-flex flex-column' >
                            <ChartRadioGroup handleRadioChange={handleRadioChange}
                                             chartType={chartType}
                                             isXSView={props.isXSView}
                                             isSMView={props.isSMView}/>
                            <div className='d-flex align-items-center justify-content-center mx-3' style={{minHeight: 500}}>
                                <DLText
                                    id={'revenue-value'}
                                    type={'normal'}
                                    text={'No data found'}
                                    fontSize={'md'}/>
                            </div>
                        </div>
                        :
                        <>
                            <ChartRadioGroup handleRadioChange={handleRadioChange}
                                             chartType={chartType}
                                             isXSView={props.isXSView}
                                             isSMView={props.isSMView}/>
                                {getResult(chartDataArray)}
                            <FundSummaryChart yAxis={chartLabelsArray}
                                              xAxis={chartDataArray}
                                              percentage={percentage}
                                              chartType={chartType}
                                              isMobileView={props.isMobileView}
                                              handleClick={handleClick}/>
                        </>
                    }
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1} id="tableTabPanel">
                    {(isEmpty(tableData) || isEmpty(tableHeader)) ?
                        <div className='d-flex align-items-center justify-content-center' style={{minHeight: 500}}>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={'No data found'}
                                fontSize={'md'}/>
                        </div>
                        :
                        <FundSummaryTable data={tableData} {...props} tableHeader={tableHeader} searched={searched}/>
                    }
                </CustomTabPanel>
            </Box>

        </div>
    )

}

export default withRouter(FundSummary);
