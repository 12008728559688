import React, {useEffect, useState} from 'react';
import DLModal from "dlcomponent/components/DLModal/DLModal";
import DLModalTitle from "dlcomponent/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "dlcomponent/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "dlcomponent/components/DLModal/DLModalActions/DLModalActions";
import DLButton from "dlcomponent/components/DLButton/DLButton";
import DLText from "dlcomponent/components/DLText/DLText";
import greenBull from "../assets/img/green bull.png";
import redBear from "../assets/img/red bear.png";
import {isEmpty} from "../utils/validations";
import appTheme from "../assets/appTheme";
import DLLoader from "../core/components/DLLoader/DLLoader";
import {filterNetBuyingSellingValue, getRandomId, numberWithCommas} from "../utils/helper";
import {getFundSummary} from "../fund/FundAction";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function PopComponent(props){

    const{isModalOpen,handleCloseModal,exits,entries,buyingFunds, sellingFunds, netBuying, netSelling, netBuyingSellingDifference,
        requiredMonth, isModalApiInProgress,isXSView, isSMView, selectedData }=props;
    const[clickedButton, setClickedButton] = useState("OVERVIEW")

    useEffect(() => {
        setClickedButton("OVERVIEW")
    }, [isModalOpen]);

    const checkIsFullScreenRequired =()=>{
        if(isSMView || isXSView){
            return true;
        }
        return false;
    }

    const handleSinglularPluralText = (singular,plural,value)=>{
        if(value <= 1){
            return singular;
        }
        return plural;

    }

    const handleStockClick = (fundDetails)=>{
        if(!isEmpty(fundDetails)){
            props.history.push('/funds/'+fundDetails.fundHouseId +'/'+fundDetails.fundId)
        }
    }

    const getTitleText =()=>{
        if(clickedButton === "EXITS"){
            return(
                <div className='d-flex'>
                        <div className='cursor'
                             onClick={() =>{getClick("BACK_BUTTON")}}>
                            <i className='fas fa-chevron-left me-3 icon-size'
                               //style={{color: appTheme.appColor.primary}}
                            />
                        </div>
                    {numberWithCommas(exits?.length)+" "} {handleSinglularPluralText("Exit","Exits",exits?.length)}
                    </div>
            );
        }
        if(clickedButton === "ENTRIES"){
            return(
                    <div className='d-flex'>
                        <div className='cursor'
                             onClick={() =>{getClick("BACK_BUTTON")}}>
                            <i className='fas fa-chevron-left me-3 icon-size'
                               //style={{color: appTheme.appColor.primary}}
                            />
                        </div>
                        {numberWithCommas(entries?.length)+" "}{handleSinglularPluralText("Entry","Entries",entries?.length)}
                    </div>
            );
        }
        if(clickedButton === "OVERVIEW"){
            return 'Buying/Selling in '+ requiredMonth
        }
    }

    const renderUI =()=>{
        if(clickedButton === "EXITS"){
            if(isEmpty(exits)){
                return;
            }
            return exits.map((item, index)=>{
                return(
                    <div className="p-2 ps-3 cursor" key={getRandomId()}
                         style={{backgroundColor: index % 2 === 0 ?  "#F1F1F1" : " "}}
                         onClick={()=>{handleStockClick(item)}}
                    >
                        <DLText id={"clear-canvas-modalMsg"}
                                fontSize={"md"}
                                text={item?.fundName}
                                isClickable={true}
                                customWrapperStyle={{paddingLeft: "4px"}}
                        />
                    </div>
                );
            })
        }
        if(clickedButton === "ENTRIES"){
            if(isEmpty(entries)){
                return;
            }
            return entries.map((item, index)=>{
                return(
                    <div className="p-2 ps-3 cursor" key={getRandomId()}
                         style={{backgroundColor: index % 2 === 0 ? "#F1F1F1" : " "}}
                         onClick={()=>{handleStockClick(item)}}
                    >
                        <DLText id={"clear-canvas-modalMsg"}
                                fontSize={"md"}
                                text={item?.fundName}
                                isClickable={true}
                                customWrapperStyle={{paddingLeft: "4px"}}
                        />
                    </div>
                );
            })
        }
        if(clickedButton === "OVERVIEW"){
            if(checkIsFullScreenRequired()){
                return(
                    <div className="d-flex flex-column h-100" style={{zIndex:999}}>
                        <div className={'d-flex justify-content-end'}>
                            <DLText text={<u>{'Money Flow Explorer'}</u>}
                                    fontSize={'md'}
                                    isClickable={true}
                                    onClick={()=>{props.history.push("/money-flow-explorer", {selectedStock: selectedData, isFrom:'stock',  selectedDate:requiredMonth})}}
                            />
                        </div>
                        <div className='my-2 flex-grow-1 d-flex justify-content-center align-items-center h-100'  style={{height:"95%"}}>
                            <div className="d-flex justify-content-evenly align-items-center">
                                <div className="h-100 d-flex flex-column">
                                    <div>
                                        <img src={greenBull}
                                             height={isXSView ? 100 : 160}
                                             width={isXSView ? 100 : 160}
                                             style={{paddingBottom: 12}}
                                        />
                                        <DLText id={"netbuying"}
                                                fontSize={"md"}
                                                customWrapperStyle={{
                                                    textAlign: 'center'
                                                }}
                                                customTextStyle={{
                                                    color: appTheme.appColor.success
                                                }}
                                                fontWeight={"semi-bold"}
                                                text={numberWithCommas(filterNetBuyingSellingValue(netBuying))}
                                        />
                                        <DLText id={"buyingStocks"}
                                                fontSize={"md"}
                                                customWrapperStyle={{
                                                    textAlign: 'center'
                                                }}
                                                customTextStyle={{
                                                    color: appTheme.appColor.success
                                                }}
                                                fontWeight={"semi-bold"}
                                                text={numberWithCommas(buyingFunds) +" "+(handleSinglularPluralText("Fund","Funds",buyingFunds))}
                                        />
                                    </div>
                                    <div style={{height:200}}></div>
                                </div>
                                <div className="h-100 d-flex flex-column align-items-center">
                                <span className={netBuyingSellingDifference > 0 ? "badge-success badge-sm" : "badge-danger badge-sm"}
                                >{numberWithCommas(filterNetBuyingSellingValue(netBuyingSellingDifference))}

                                </span>
                                </div>
                                <div className="h-100 d-flex flex-column">
                                    <div style={{height:200}}></div>
                                    <div>
                                        <DLText id={"netSelling"}
                                                fontSize={"md"}
                                                customWrapperStyle={{
                                                    textAlign: 'center'
                                                }}
                                                customTextStyle={{
                                                    color: appTheme.appColor.danger
                                                }}
                                                fontWeight={"semi-bold"}
                                                text={numberWithCommas(filterNetBuyingSellingValue(netSelling))}
                                        />
                                        <DLText id={"sellingStocks"}
                                                fontSize={"md"}
                                                marginBottom={"none"}
                                                customWrapperStyle={{
                                                    textAlign: 'center'
                                                }}
                                                customTextStyle={{
                                                    color: appTheme.appColor.danger
                                                }}
                                                fontWeight={"semi-bold"}
                                                text={numberWithCommas(sellingFunds) +" "+ (handleSinglularPluralText("Fund","Funds",sellingFunds))}
                                        />
                                        <img src={redBear}
                                             height={isXSView ? 100 : 160}
                                             width={isXSView ? 100 : 160}
                                             style={{paddingBottom: 7,paddingTop:12}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="border-top pt-2"  style={{height:"5%"}}>

                            <div className="d-flex justify-content-evenly align-items-center">
                                <div><DLButton id={''}
                                               history={''}
                                               buttonSize={'sm'}
                                               styleType={"outlined"}
                                               fontSize={"md"}
                                               sideMargin={"sm"}
                                               type={'primary'}
                                               onClick={() => {getClick("ENTRIES")}}
                                               style={{border:"none",color:(entries.length === 0? "#B2B2B2":"#0073FF")}}
                                               isDisabled={entries.length === 0}
                                               label={
                                                   <u>{numberWithCommas(entries?.length)+" "+ (handleSinglularPluralText("Entry","Entries",entries?.length))}</u>
                                               }
                                /></div>

                                <div className="h-100 d-flex flex-column align-items-center" style={{width:"36px"}}>
                                </div>

                                <div>
                                    <DLButton id={''}
                                              history={''}
                                              buttonSize={'sm'}
                                              styleType={"outlined"}
                                              fontSize={"md"}
                                              sideMargin={"sm"}
                                              type={'primary'}
                                              onClick={() => {getClick("EXITS")}}
                                              style={{border:"none",color:(exits.length === 0? "#B2B2B2":"#0073FF")}}
                                              isDisabled={exits.length === 0}
                                              label={
                                                  <u>{numberWithCommas(exits?.length)+" "+ (handleSinglularPluralText("Exit","Exits",exits?.length))}</u>
                                              }
                                    /></div>
                            </div>
                        </div>
                    </div>
                );
            }
            return(
                <div style={{zIndex:999}}>
                    <div className='my-2'>
                        <div className={'d-flex justify-content-end'}>
                            <DLText text={<u>{'Money Flow Explorer'}</u>}
                                    fontSize={'md'}
                                    isClickable={true}
                                    onClick={()=>{props.history.push("/money-flow-explorer", {selectedStock: selectedData, isFrom:'stock',  selectedDate:requiredMonth})}}
                            />
                        </div>
                        <div className="d-flex justify-content-evenly align-items-center">
                            <div className="h-100 d-flex flex-column">
                                <div>
                                    <img src={greenBull}
                                         height={isXSView ? 100 : 160}
                                         width={isXSView ? 100 : 160}
                                         style={{paddingBottom: 12}}
                                    />
                                    <DLText id={"netbuying"}
                                            fontSize={"md"}
                                            customWrapperStyle={{
                                                textAlign: 'center'
                                            }}
                                            customTextStyle={{
                                                color: appTheme.appColor.success
                                            }}
                                            fontWeight={"semi-bold"}
                                            text={numberWithCommas(filterNetBuyingSellingValue(netBuying))}
                                    />
                                    <DLText id={"buyingStocks"}
                                            fontSize={"md"}
                                            customWrapperStyle={{
                                                textAlign: 'center'
                                            }}
                                            customTextStyle={{
                                                color: appTheme.appColor.success
                                            }}
                                            fontWeight={"semi-bold"}
                                            text={numberWithCommas(buyingFunds) +" "+(handleSinglularPluralText("Fund","Funds",buyingFunds))}
                                    />
                                </div>
                                <div style={{height:200}}></div>
                            </div>
                            <div className="h-100 d-flex flex-column align-items-center">
                                <span className={netBuyingSellingDifference > 0 ? "badge-success badge-sm" : "badge-danger badge-sm"}
                                >{numberWithCommas(filterNetBuyingSellingValue(netBuyingSellingDifference))}

                                </span>
                            </div>
                            <div className="h-100 d-flex flex-column">
                                <div style={{height:200}}></div>
                                <div>
                                    <DLText id={"netSelling"}
                                            fontSize={"md"}
                                            customWrapperStyle={{
                                                textAlign: 'center'
                                            }}
                                            customTextStyle={{
                                                color: appTheme.appColor.danger
                                            }}
                                            fontWeight={"semi-bold"}
                                            text={numberWithCommas(filterNetBuyingSellingValue(netSelling))}
                                    />
                                    <DLText id={"sellingStocks"}
                                            fontSize={"md"}
                                            marginBottom={"none"}
                                            customWrapperStyle={{
                                                textAlign: 'center'
                                            }}
                                            customTextStyle={{
                                                color: appTheme.appColor.danger
                                            }}
                                            fontWeight={"semi-bold"}
                                            text={numberWithCommas(sellingFunds) +" "+ (handleSinglularPluralText("Fund","Funds",sellingFunds))}
                                    />
                                    <img src={redBear}
                                         height={isXSView ? 100 : 160}
                                         width={isXSView ? 100 : 160}
                                         style={{paddingBottom: 7,paddingTop:12}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-top">

                        <div className="d-flex justify-content-evenly align-items-center">
                            <div><DLButton id={''}
                                           history={''}
                                           buttonSize={'sm'}
                                           styleType={"outlined"}
                                           fontSize={"md"}
                                           sideMargin={"sm"}
                                           type={'primary'}
                                           onClick={() => {getClick("ENTRIES")}}
                                           style={{border:"none",color:(entries.length === 0? "#B2B2B2":"#0073FF")}}
                                           isDisabled={entries.length === 0}
                                           label={
                                               <u>{numberWithCommas(entries?.length)+" "+ (handleSinglularPluralText("Entry","Entries",entries?.length))}</u>
                                           }
                            /></div>

                            <div className="h-100 d-flex flex-column align-items-center" style={{width:"36px"}}>
                            </div>

                            <div>
                                <DLButton id={''}
                                          history={''}
                                          buttonSize={'sm'}
                                          styleType={"outlined"}
                                          fontSize={"md"}
                                          sideMargin={"sm"}
                                          type={'primary'}
                                          onClick={() => {getClick("EXITS")}}
                                          style={{border:"none",color:(exits.length === 0? "#B2B2B2":"#0073FF")}}
                                          isDisabled={exits.length === 0}
                                          label={
                                              <u>{numberWithCommas(exits?.length)+" "+ (handleSinglularPluralText("Exit","Exits",exits?.length))}</u>
                                          }
                                /></div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    const getClick =(clickedText)=>{
        console.log("clickedText",clickedText)
        if(clickedText === "EXITS"){
            setClickedButton("EXITS")
        }
        if(clickedText === "ENTRIES"){
            setClickedButton("ENTRIES")
        }
        if(clickedText === "BACK_BUTTON"){
            setClickedButton("OVERVIEW")
        }
    }

    const onCloseModal =()=>{
        handleCloseModal();
    }

    const renderPlainModal =()=>{
        return (
            <DLModal id={'clear-canvas'}
                     history={props.history}
                     maxWidth={"sm"}
                     onClose={() => {onCloseModal()}}
                     open={isModalOpen}
                     isFullScreen={checkIsFullScreenRequired()? true : false}
            >

                <span className={'fa-solid fa-xmark ms-2 cursor '}/>
                <DLModalTitle id={'campaignDetail-sendTestModalTitle'}
                              onClose={() => {onCloseModal()}}
                              title={getTitleText()}
                              fontColor={"#333333"}
                />
                <DLModalContainer id={'clear-canvas-container'} padding={clickedButton !== "OVERVIEW"? "none" : ""}>
                    <div className="h-100" style={{minHeight:"400px"}}>
                        {isModalApiInProgress ?
                            <div className={'row px-2 h-100'} style={{height:"200px"}}>
                                <div className={"col-12 d-flex flex-column justify-content-center align-items-center mb-1"}>
                                    <DLLoader type={'inline'} isVisible={true}/>
                                </div>
                            </div>
                            :
                            renderUI()
                        }
                    </div>
                </DLModalContainer>

            </DLModal>
        )
    }


    return(
        renderPlainModal()
    )
}

export default PopComponent;