import React, {Component} from 'react';
import {userConst} from "../../../user/userConsts";
import {isEmpty} from "../../../utils/helperCommon";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import {getRandomColors, getTrimmedValue} from "../../../utils/helper";
import {Link, withRouter, NavLink} from "react-router-dom";
import DLText from "dlcomponent/components/DLText/DLText";
import appTheme from "../../../assets/appTheme";
import logo from '../../../assets/img/simplicity_logo.jpg';
import {AppBar, Toolbar} from "@mui/material";
import homeSelected from "../../../assets/img/homeSelected.png";
import home from "../../../assets/img/homeNotSelected.png";
import fundHousesSelected from "../../../assets/img/fundHousesSelected.png";
import fundHouses from "../../../assets/img/fundHousesNotSelected.png";
import fundsSelected from "../../../assets/img/fundsSelected.png";
import funds from "../../../assets/img/fundsNotSelected.png";
import stocksSelected from "../../../assets/img/stocksSelected.png";
import stocks from "../../../assets/img/stocksNotSelected.png";
import industriesSelected from "../../../assets/img/industriesSelected.png";
import industries from "../../../assets/img/industriesNotSelected.png";
import Dropdown from 'react-bootstrap/Dropdown';
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import {Visibility} from "@mui/icons-material";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import Paper from "@mui/material/Paper";
import DLLoader from "../../components/DLLoader/DLLoader";
import SearchModal from "../../../searchUi/SearchModal";
import {clearUserDataOnLogout} from "../../../user/userActions";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import Tooltip from "@mui/material/Tooltip";
import {DLTooltip} from "dlcomponent";

class ResAppBarView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        }
    }

    isLoggedIn = () => {
        const {userAuthStatus} = this.props;
        return (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED)
    };
    getFormattedRole = (role) => {

        if (role === "MANAGEMENT") {
            return "Management"
        } else if (role === "CRM") {
            return "CRM"
        } else if (role === "SUPPORT") {
            return "Support"
        }
    };
    renderBackButton = () => {
        const {isMobileView} = this.props;

        let currentPathname = this.props.currentPathname;
        let isHomePage = ((currentPathname === '/accounts') || (currentPathname === '') || (currentPathname === '/dashboard'));


        if (isMobileView && !isEmpty(this.props.prevLocation) && !isHomePage) {
            return (
                <div className='cursor'
                     onClick={() => this.props.history.goBack()}>
                    <i className='fas fa-chevron-left me-3 icon-size'
                       style={{color: appTheme.appColor.primary}}
                    />
                </div>
            )
        }
    };


    renderAppLogo = () => {

        const {isMobileView} = this.props;
        return (
            <div className='d-flex align-items-center'>
                <img className='rounded-2 me-1'
                     src={logo}
                     alt={'logo'}
                     width={isMobileView ? '28px' : '30px'}
                     height={isMobileView ? '28px' : '30px'}/>
                <DLText id={"logo"}
                        isClickable={true}
                        fontWeight={"semi-bold"}
                        text={'Research'}
                        marginBottom={'none'}
                        customTextStyle={{
                            color: appTheme.appColor.black,
                            fontSize: 28
                        }}
                        onClick={() => {
                            this.props.history.push('/')
                        }}
                />
            </div>
        );
    };


    renderMobileDrawerBtn = () => {

        const {isMobileView, isXSView, openDrawer, location} = this.props;

        if (isMobileView) {
            // if (isDrawerAvailable) {
            return (
                <div className="position-fixed end-0 bottom-0 bg-white border-top w-100 px-3 "
                     style={{height: 54}}>
                    <nav id='app-header-nav-mobile'
                         className="d-flex align-items-center h-100"
                         style={{gap: isXSView ? 0 : 16}}>
                        <NavLink exact to="/">
                            <div className="horizontal-line"/>
                            <div className="d-flex flex-column align-items-center justify-content-center h-100">
                                <img src={(location.pathname === '/') ? homeSelected : home}
                                     height={24}
                                     width={16}
                                     style={{paddingBottom: 7}}
                                />
                                Home
                            </div>
                        </NavLink>
                        <NavLink to="/fund-houses">
                            <div className="horizontal-line"/>
                            <div className="d-flex flex-column align-items-center justify-content-center h-100">
                                <img
                                    src={(location.pathname.includes('/fund-houses')) ? fundHousesSelected : fundHouses}
                                    height={24}
                                    width={16}
                                    style={{paddingBottom: 7}}
                                />
                                Fund Houses
                            </div>
                        </NavLink>
                        <NavLink to="/funds">
                            <div className="horizontal-line"/>
                            <div className="d-flex flex-column align-items-center justify-content-center h-100">
                                <img src={(location.pathname.includes('/funds')) ? fundsSelected : funds}
                                     height={24}
                                     width={18}
                                     style={{paddingBottom: 7}}
                                />
                                Funds
                            </div>
                        </NavLink>
                        <NavLink to="/stocks">
                            <div className="horizontal-line"/>
                            <div className="d-flex flex-column align-items-center justify-content-center h-100">
                                <img src={(location.pathname.includes('/stocks')) ? stocksSelected : stocks}
                                     height={24}
                                     width={20}
                                     style={{paddingBottom: 7}}
                                />
                                Stocks
                            </div>
                        </NavLink>
                        <NavLink to="/sectors">
                            <div className="horizontal-line"/>
                            <div className="d-flex flex-column align-items-center justify-content-center h-100">
                                <img src={(location.pathname.includes('/sectors')) ? industriesSelected : industries}
                                     height={24}
                                     width={18}
                                     style={{paddingBottom: 7}}
                                />
                                Sectors
                            </div>
                        </NavLink>
                    </nav>
                </div>
            );
            // }
        }

    };

    renderNameAndIcon = () => {
        const {profile} = this.props;

        let profileImage;
        if (!isEmpty(profile)) {
            profileImage = profile?.firstName?.slice(0, 1) + profile?.lastName?.slice(0, 1);

        }
        return (
            <div className='d-flex px-2 align-items-center'>
                <Avatar className='avatar-img-lg'
                        style={{backgroundColor: getRandomColors(profile?.firstName)}}>
                    {isEmpty(profileImage) ? <></> :
                        profileImage.toUpperCase()
                    }
                </Avatar>
                <div className='ms-2 flex-grow-1'>
                    <div className="d-flex text-break"
                         style={{marginTopL: -9, lineHeight: 1}}>
                        <div className='pe-1'>
                            <DLText id={''}
                                    text={profile?.firstName}
                                    fontWeight={"semi-bold"}
                                    customTextStyle={{lineHeight: 1}}
                                    marginBottom={'none'}
                                    isInline={true}
                                    fontSize={'xs'}
                            />
                        </div>
                        {!isEmpty(profile?.lastName) &&
                        <div>
                            <DLText id={''}
                                    text={profile?.lastName}
                                    fontWeight={"semi-bold"}
                                    marginBottom={'none'}
                                    customTextStyle={{lineHeight: 1}}
                                    isInline={true}
                                    fontSize={'xs'}
                            />
                        </div>
                        }
                    </div>
                    <div style={{marginBottom: 3}}>
                        <DLText id={''}
                                text={getTrimmedValue(profile?.email, 30)}
                                fontColor={'gray'}
                                marginBottom={'none'}
                                customTextStyle={{lineHeight: 1}}
                                fontSize={'xs'}
                        />
                    </div>
                    {!isEmpty(profile?.SystemUser?.role) &&
                    <span className="role-badge p-1">
                        {this.getFormattedRole(profile?.SystemUser.role)}
                    </span>
                    }
                </div>
            </div>
        )
    };


    renderUnAuthorisedUi = () => {
        return (
            <div className="d-flex w-100 h-100"
                 style={{
                     background: appTheme.appColor.white,
                     boxShadow: " 0px 1px 1px 0px rgba(0, 0, 0, 0.20)"
                 }}>
                <div className="d-flex justify-content-between w-100 page-container">
                    <div className='d-flex align-items-center'>
                        {this.renderBackButton()}
                        {this.renderAppLogo()}
                    </div>
                    <div className="d-flex align-items-center"
                         key={''}>
                        <li className="d-inline">
                            <Link to="/user/login">
                                <div className={"link-primary"}>
                                    Sign in
                                </div>
                            </Link>
                        </li>
                    </div>
                </div>
            </div>
        );
    };

    handleItemOpen = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleItemClose = () => {
        this.setState({anchorEl: null});
    };

    renderProfileMenu = () => {
        const {profile, setUserAuthStatus} = this.props;
        let profileImage;
        if (!isEmpty(profile)) {
            profileImage = profile?.firstName?.slice(0, 1) + profile?.lastName?.slice(0, 1);
        }

        const {anchorEl} = this.state;
        let menuOptions = [
            {
                label: 'Logout',
                onClick: () => {
                    setUserAuthStatus(userConst.AUTH_STATUS_UNAUTHORIZED);
                    clearUserDataOnLogout()
                },
                isVisible: true
            },

        ];

        let isIconVisible = false;

        menuOptions.forEach(item => {
            if (item?.isVisible) {
                isIconVisible = true
            }
        });

        if (!isIconVisible) {
            return;
        }

        let icon = (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center me-2 pe-3" style={{flex: 1}}>
                    <div className="muiIconBtn  text-end mt-2" style={{flex: 1}}>
                        <IconButton onClick={() => {
                            this.props.history.push("/fund-search")
                        }}
                                    className='muiIconBtn iconButtonCustom'
                                    type="button" sx={{p: '0px'}}
                                    aria-label="search"
                                    style={{
                                        border: '1px solid #282828',
                                        borderRadius: '50%',
                                        width: ' 34px',
                                        height: '34px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#282828'
                                    }}>
                            <SearchIcon style={{width: '19px', height: '19px'}}/>
                        </IconButton>

                    </div>
                </div>
                <div className="d-flex align-items-center me-1 pe-3" style={{flex: 1}}>
                    <div className="muiIconBtn  text-end mt-2" style={{flex: 1}}>
                        <DLTooltip tooltipPlacement={'right'} tooltipLabel={<IconButton onClick={() => {
                            this.props.history.push("/watchlist")
                        }} className='muiIconBtn iconButtonCustom'
                           type="button" sx={{p: '0px'}}
                           aria-label="search"
                           style={{
                               border: '1px solid #282828',
                               borderRadius: '50%',
                               width: ' 34px',
                               height: '34px',
                               display: 'flex',
                               justifyContent: 'center',
                               alignItems: 'center',
                               color: '#282828'
                      }}>
                            <ListAltOutlinedIcon style={{width: '19px', height: '19px'}}/>
                        </IconButton>} id={''}
                                   customUi={true}
                                   tooltipContent={<DLText fontSize={'xs'}
                                                           text={'Watchlist'}
                                                           marginBottom={'none'}/>}
                        />
                    </div>
                </div>
                <div className='cursor'
                     onClick={this.handleItemOpen}>
                    <Avatar className='avatar-img'
                            style={{backgroundColor: getRandomColors(profile?.firstName)}}>
                        {isEmpty(profileImage) ? <></> :
                            profileImage.toUpperCase()
                        }
                    </Avatar>
                </div>
            </div>
        );


        let menu = (
            <Menu id="items-menu"
                  anchorEl={anchorEl}
                  getcontentanchorel={null}
                  anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                  transformOrigin={{vertical: "top", horizontal: "right"}}
                  open={Boolean(anchorEl)}
                  onClose={this.handleItemClose}
                  className="p-2 w-100"
                  disableAutoFocusItem
                  PaperProps={{
                      style: {
                          marginTop: '10px',
                      }
                  }}
                  MenuListProps={{
                      style: {
                          color: '#0B407D'
                      },
                  }}>
                <div>
                    {this.renderNameAndIcon()}
                </div>
                {menuOptions.map((item, index) => {

                    if (item?.isVisible) {
                        return (
                            <MenuItem key={'menuItem-' + index}
                                      className={'cursor dropdownMenu mx-2 py-0'}
                                      style={{width: 230}}
                                      onClick={() => {
                                          this.handleItemClose();
                                          item.onClick();
                                      }}>
                                {item?.label}
                            </MenuItem>
                        )
                    }

                    return <span/>

                })}
            </Menu>
        );
        return (
            <span>
                {icon}
                {menu}
            </span>
        );
    };


    getOptionsItem = () => {
        const {searchOptions, searchOptionsFound, handleClick} = this.props;
        if (isEmpty(searchOptions)) {
            return (
                <Dropdown.Item>
                    <div className="w-100">
                        <DLText id={""}
                                text={'No options found'}
                                fontColor={"lightGray"}
                                marginBottom={"none"}
                        />
                    </div>
                </Dropdown.Item>
            )
        }

        return searchOptions.map((item) => {
            return (
                <Dropdown.Item onClick={() => handleClick(item)}>
                    <div className="py-2">

                        <DLText id={""}
                                text={<span className='text-truncate'>{item?.name}</span>}
                                fontWeight={"semi-bold"}
                                fontSize={"md"}
                                marginBottom={"none"}
                        />
                        <DLText id={""}
                                text={item?.type}
                                fontColor={"lightGray"}
                                marginBottom={"none"}
                        />
                    </div>
                </Dropdown.Item>
            )
        })
    };

    renderAuthorisedUi = () => {
        const {isMobileView, searchData, searchOptions, searchOptionsFound, isSearchInProgress, onChangeValue} = this.props;

        return (
            <div className="d-flex w-100 h-100"
                 style={{
                     background: appTheme.appColor.white,
                     // boxShadow: " 0px 1px 1px 0px rgba(0, 0, 0, 0.20)"
                 }}>
                <div className='d-flex justify-content-between align-items-center w-100 h-100 page-container'>
                    {this.renderBackButton()}
                    {this.renderMobileDrawerBtn()}
                    <div className="d-flex align-items-center" style={{gap: 60, flex: 1}}>
                        {this.renderAppLogo()}
                        {
                            isMobileView ? <></> :
                                <>
                                    <nav id='app-header-nav' className="d-flex align-items-center" style={{gap: 40}}>
                                        <NavLink exact to="/">
                                            Home
                                        </NavLink>
                                        <NavLink to="/fund-houses">
                                            Fund Houses
                                        </NavLink>
                                        <NavLink to="/funds">
                                            Funds
                                        </NavLink>
                                        <NavLink to="/stocks">
                                            Stocks
                                        </NavLink>
                                        <NavLink to="/sectors">
                                            Sectors
                                        </NavLink>
                                    </nav>
                                </>
                        }
                    </div>

                    {this.renderProfileMenu()}
                </div>
            </div>
        );
    };


    render() {
        if (this.props.location.pathname === '/session-expired') {
            return <></>
        }

        return (
            <div className="w-100">
                <AppBar drawerwidth={0}
                        position="fixed"
                        open={this.props.isDrawerOpen}
                        className='text-white shadow-none bg-white px-0'
                        style={{borderRadius: '0px'}}>
                    <Toolbar className="px-0"
                             style={{height: '54px', minHeight: '54px'}}>
                        {this.isLoggedIn() ? this.renderAuthorisedUi() : this.renderUnAuthorisedUi()}
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

ResAppBarView.propTypes = {};
export default ResAppBarView;
