import {createSlice} from '@reduxjs/toolkit'

const appSlice = createSlice({
    name: 'stockReducer',
    initialState: {
        keyword: '',
        firstStock: '',
        secondStock: '',
        thirdStock: '',
        firstStockName: '',
        secondStockName: '',
        thirdStockName: '',
        orderBy : '',
        order : '' ,
        page:0,
        rowsPerPage:50,
        tabValue:0
    },
    reducers: {
        setKeyword(state, action) {
            state.keyword = action.payload;
        },
        setCompareStockOptions(state, action) {
            state[action.payload.name] = action.payload.value;
        },
        setOrderBy(state, action) {
            state.orderBy = action.payload;
        },
        setOrder(state, action) {
            state.order = action.payload;
        },
        setPage(state, action) {
            state.page = action.payload;
        },
        setRowsPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        setTabValue(state, action) {
            state.tabValue = action.payload;
        },
        resetTabValue(state, action) {
            state.tabValue = 0;
        },
        resetOrderBy(state, action) {
            state.orderBy = ''
        },
        resetOrder(state, action) {
            state.order = ''
        },
        resetPage(state, action) {
            state.page = 0
        },
        resetRowsPerPage(state, action) {
            state.rowsPerPage = 50
        },
        resetCompareStocksOptions(state, action) {
            state.firstStock = '';
            state.secondStock = '';
            state.thirdStock = '';
            state.firstStockName = '';
            state.secondStockName = '';
            state.thirdStockName = '';
        },
    }
});

export const {setTabValue, resetTabValue, setKeyword, setCompareStockOptions, resetCompareStocksOptions , setOrder,setOrderBy,setPage,setRowsPerPage,resetOrder,resetOrderBy,resetPage,resetRowsPerPage} = appSlice.actions;
export default appSlice.reducer
