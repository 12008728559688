import React, {Component, useEffect, useRef, useState} from 'react';
import { render } from 'react-dom';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { TreemapChart } from 'echarts/charts';
import {
    GridComponent,
    LegendComponent,
    TooltipComponent,
    TitleComponent,
    DatasetComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import './eChart.css';
import withRouter from "react-router-dom/es/withRouter";
import appTheme from "../../assets/appTheme";
import {getFixedValuesAfterDecimal, numberWithCommas} from "../../utils/helper";
import {isEmpty} from "../../utils/validations";

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    TreemapChart,
    CanvasRenderer,
]);

interface AppProps {}
interface AppState {
    name: string;
}

function EChartTreemap(props) {
    const {data, externalTooltip, color, isXSView, isSMView, handleChartClick} = props;

    const chartContainerRef = useRef(null);
    const myChartRef = useRef(null);

    let totalSum = 0;
    data.forEach((item) =>{
        let value =  item.value >= 1 ?  parseInt(item.value) : item.value;
        totalSum = totalSum + value
    });

    // Calculate the percentage for each item
    const formattedData = data.map(item => ({
        id:item?.id,
        name: item.name,
        value: item.value >=1 ? getFixedValuesAfterDecimal(item.value,0) : getFixedValuesAfterDecimal(item.value,3),
        // Calculate the percentage and round it to two decimal places
        percent: getFixedValuesAfterDecimal((item.value / totalSum) * 100, 2),
    }));

    const getTooltip = () =>{
        if (externalTooltip){
            return externalTooltip
        }else {
            return {
                trigger: 'item',
                formatter: function (params) {
                    // Customize the tooltip content
                    let stocksCount = getCount(params.name);
                    if(isEmpty(params.name)){
                        return;
                    }
                    return `<div id="${params.data?.id}" style="z-index:99"><span style="font-size: 16px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif">${params.name}</span> 
                        <br/><span style="font-size: 14px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif""> ${stocksCount}</span> 
                        <span style="font-size: 10px; font-weight: 500; color: #282828 ; font-family: 'Poppins', sans-serif""> Stocks </span><br/>
                        <span style="font-size: 14px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif""> ${numberWithCommas(params.value)}</span> 
                        <span style="font-size: 10px; font-weight: 500; color: #282828 ; font-family: 'Poppins', sans-serif""> AUM </span>
                         <br/><span style="font-size: 14px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif""> ${params.data.percent}% 
                         </span><span style="font-size: 10px; font-weight: 500; color: #282828 ; font-family: 'Poppins', sans-serif""> of Total </span> </div>`;
                },
            }
        }
    };

    useEffect(() => {
        // Get the container DOM element
        const containerDom = chartContainerRef.current;

        // Initialize ECharts instance
        myChartRef.current  = echarts.init(containerDom, null, { renderer: 'svg' });

        // Set chart options
        let option = {
            tooltip: getTooltip(),
            title: {
                show: false
            },
            series: [
                {
                    roam: false,
                    nodeClick: true,
                    type: 'treemap',
                    visibleMin: 500,
                    label: {
                        show: true,
                        formatter: function (params) {
                            const threshold = (isXSView || isSMView ) ? 4 :1.50; // Set your threshold size here

                            if (params.data.percent < threshold) {
                                return `...`;
                            } else {
                                return `${params.name} \n \n ${params.data.percent > 100 ? 100 : params.data.percent}% \n ${params.value >=1 ? numberWithCommas(Math.trunc(params.value)) : params.value}`;
                            }
                        },
                        padding: 5,
                        position: 'inside',
                        overflow: 'truncate'
                    },
                    data: formattedData,
                    breadcrumb: {
                        show: false
                    },
                    width: '100%',
                    height: '100%',
                    itemStyle: {
                        gapWidth: 1
                    }
                }
            ],
            roam: false,
            legend: null,
            zoom: null,
        };

        if (!isEmpty(color)){
            option={...option,color: color}
        }
        // Set the options for the chart
        myChartRef.current.setOption(option);

        // Add onclick event to the chart
        myChartRef.current.on('click', (params) => {
            // Handle click event here
            if (handleChartClick){
                 handleChartClick(params);
            }
        });

        // Clean up when the component is unmounted
        return () => {
            if (myChartRef.current) {
                myChartRef.current.dispose();
            }
        };
    }, [formattedData]);

    const getCount = (name) =>{
        let capObj = data.find((item)=>{
            return item.name === name
        });

        return capObj?.count
    };




    const getHeight = () =>{
        const {isMobileView, isSMView, isXSView, requestedHeight} = props;
        if(!isEmpty(requestedHeight)){
           return requestedHeight
        }
        if (isXSView || isSMView)
            return '500px';
        if (isMobileView){
            return '400px'
        }
        return '472px'
    };


    return (
        <div ref={chartContainerRef} style={{ width: '100%', height: getHeight(), zIndex:99}} />
    );
}

export default withRouter(EChartTreemap);

