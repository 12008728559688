import React, {useEffect, useState} from 'react';
import DLSearchAutoComplete from "dlcomponent/components/DLSearchAutoComplete/DLSearchAutoComplete";
import DLButton from "dlcomponent/components/DLButton/DLButton";
import {isEmpty} from "../utils/validations";
import {getDataArray, getMobileHeight, getTrimmedValue, numberWithCommas, searchData} from "../utils/helper";
import Stocks from "./StocksSummary/Stocks";
import FundSummary from "./FundSummary/FundSummary";
import {fundConst} from "./fundConst";
import SummaryCardFundHouse from "../stocks/SummaryCardFundHouse/SummaryCardFundHouse";
import DLText from "dlcomponent/components/DLText/DLText";
import LineBarChartMixed from "../component-utils/charts/LineBarChartMixed";
import appTheme from "../assets/appTheme";
import DLLoader from "../core/components/DLLoader/DLLoader";
import EChartTreemap from "../component-utils/EChart/EChartTreemap";
import EChartDougnute from "../component-utils/EChart/EChartDougnute";
import EChartPieChart from "../component-utils/EChart/EChartPieChart";
import {filterAumValue, getColor} from "../utils/stocksHelper";
import Industry from "../fund/Industry/Industry";
import moment from "moment";
import PopComponent from "../../src/component-utils/BarChartPopUp/PopComponent";


// TODO use lazy load for each component which is firing the api
function FundView(props) {

    const {
        fundHouseId, fundId, selectedFund, isSMView, isXSView, UIState, isXLView, isLGView, isMobileView, isMDView,
        getDataForPopUp, handleOpenModal, isModalApiInProgress
    } = props;
    const [activeSection, setActiveSection] = useState('section1');

    const addScroll = (id) => {
        const element = document.getElementById(id);
        if (!isEmpty(element)) {
            element.scrollIntoView({behavior: "smooth", block: "start"});
        }
    };

    const handleScroll = () => {
        const myDiv = document.getElementById('myDiv');
        const pageYOffset = myDiv?.scrollTop + 64;
        let sectionsHeight = 600;

        let section1Height; // to active nextsections when previous section is at end
        let section2Height;//mobile -400
        let section3Height; //mobile -400
        let section4Height;//mobile -400

        if (isXLView) {
            section1Height = sectionsHeight; // to active nextsections when previous section is at end
            section2Height = section1Height + 500;//mobile -400
            section3Height = section2Height + 650; //mobile -400
            section4Height = section3Height + 500;//mobile -400
        }
        if (isLGView) {
            section1Height = sectionsHeight; // to active nextsections when previous section is at end
            section2Height = section1Height + 600;//mobile -400
            section3Height = section2Height + 600; //mobile -400
            section4Height = section3Height + 500;//mobile -400
        }

        if (isMDView) {
            section1Height = 800; // to active nextsections when previous section is at end
            section2Height = section1Height + 600;//mobile -400
            section3Height = section2Height + 600; //mobile -400
            section4Height = section3Height + 500;//mobile -400
        }

        if (isSMView || isXSView) {
            section1Height = 1100; // to active nextsections when previous section is at end
            section2Height = section1Height + 600;//mobile -400
            section3Height = section2Height + 300; //mobile -400
            section4Height = section3Height + 500;//mobile -400
        }
        if (pageYOffset < section1Height) {
            setActiveSection('section1');
        } else if (pageYOffset < section2Height) {
            setActiveSection('section2');
        } else if (pageYOffset < section3Height) {
            setActiveSection('section3');
        } else if (pageYOffset < section4Height) {
            setActiveSection('section4');
        }
    };

    const activeStyle = {
        fontWeight: 500,
        color: '#282828',
        textDecoration: 'underline',
        fontFamily: 'Poppins, sans-serif',

    };

    const getFileName = (blockName) => {
        let date = new Date();
        return selectedFund?.fund_name + '-' + blockName + moment(date).format('YYYY-MM-DD')
    };

    const getPieData = (data) => {
        let listingData = data.slice(0, 9);
        let isListingIncludeOther = listingData.find((item) => item.name === 'Others');
        if (isEmpty(isListingIncludeOther)) {
            let others = data.find(item => item?.name === 'Others');
            listingData.push(others);
        }
        return listingData
    };

    const getLegendsForPieChart = (data, sliceMin, sliceMax) => {
        let listingData = getPieData(data);

        return listingData.slice(sliceMin, sliceMax).map((item, index) => {
            return (
                <div className='d-flex align-items-center' style={{minWidth: 'max-content'}}>
                    <div style={{paddingTop: 3}}>
                        <div style={{
                            width: '8px',
                            height: '8px',
                            borderRadius: 7,
                            backgroundColor: getColor(sliceMin + index), // You can set the background color as needed
                        }}/>
                    </div>
                    <div className='ps-1'>
                        <DLText text={getTrimmedValue(item?.name, 12)}
                                marginBottom={"none"}
                                id={'page-not-found-oops'}
                                type={"normal"}
                                fontSize={"xxs"}/>
                    </div>
                </div>
            )
        })
    };

    const getLegends = (data) => {
        return data.map((item, index) => {
            return (
                <div className=' d-flex align-items-center'>
                    <div style={{paddingTop: 3}}>
                        <div style={{
                            width: '8px',
                            height: '8px',
                            borderRadius: 7,
                            backgroundColor: getColor(index), // You can set the background color as needed
                        }}/>
                    </div>
                    <div className='ps-1' style={{minWidth: 'max-content'}}>
                        <DLText text={item.name}
                                marginBottom={"none"}
                                id={'page-not-found-oops'}
                                type={"normal"}
                                fontSize={"xxs"}/>
                    </div>
                </div>
            )
        })
    };

    const renderPieChartUI = () => {
        if (isSMView || isXSView) {
            return (
                <div className='d-flex flex-column align-items-center mb-3'>
                    <EChartPieChart {...props}
                                    data={getPieData(getDataArray(props.businessHouse, 'business_house', 'market_value'))}/>
                    <div className='d-flex flex-wrap text-wrap gap-2 justify-content-center'>
                        {getLegendsForPieChart(getDataArray(props.businessHouse, 'business_house', 'market_value'), 0, 10)}
                    </div>
                </div>
            )
        }

        return (
            <div className='d-flex flex-column align-items-center'>
                <EChartPieChart {...props}
                                data={getPieData(getDataArray(props.businessHouse, 'business_house', 'market_value'))}/>
                <div className='d-flex flex-wrap text-wrap gap-2 justify-content-center'>
                    {getLegendsForPieChart(getDataArray(props.businessHouse, 'business_house', 'market_value'), 0, 10)}
                </div>
            </div>
        )
    }

    const renderMobileTreemapView = () => {
        return (
            <div className='d-flex flex-column justify-content-between'
                 style={{flex: 5, alignItems: isXSView && 'center'}}>

                {/*<div className='panel px-3 pt-sm-3 pb-3 w-100 mb-sm-3' >*/}
                {/*    <DLText text={'Sectors'}*/}
                {/*            fontSize={'md'}*/}
                {/*            fontWeight={'semi-bold'}*/}
                {/*            marginBottom={'md'}/>*/}
                {/*    <EChartTreemap {...props} data={getDataArray(props.industry, 'industry', 'market_value')}/>*/}
                {/*</div>*/}
                <div className='d-flex flex-md-row flex-column w-100'>
                    {isXSView ? <div style={{height: '10px', backgroundColor: appTheme.appColor.grey}}/> : <></>}
                    <div className={(isXSView) ? 'p-3 w-100' : 'panel p-3 w-100 me-3 mb-3 mb-md-0'}>
                        <div className="d-flex flex-column h-100">
                            <DLText text={'Market Cap'}
                                    fontSize={'lg'}
                                    fontWeight={'semi-bold'}
                                    marginBottom={'sm'}/>
                            <div className="flex-grow-1">
                                {
                                    isEmpty(props.category) ?
                                        <div className='d-flex justify-content-center align-items-center h-100'>
                                            No data found
                                        </div>
                                        :
                                        <div className='d-flex  flex-column align-items-center'>

                                            <div className='d-flex justify-content-center'>
                                                <EChartDougnute {...props}
                                                                data={getDataArray(props.category, 'category', 'market_value')}/>
                                            </div>

                                            <div className='d-flex justify-content-center gap-2'>
                                                {getLegends(getDataArray(props.category, 'category', 'market_value'))}
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    {isXSView ? <div style={{height: '10px', backgroundColor:appTheme.appColor.grey}}/> : <></>}
                    <div className={(isXSView) ? 'p-3 w-100' : 'panel p-3 w-100'}
                         style={{paddingLeft: (isXSView) ? 0 : 26}}>
                        <div className="d-flex flex-column h-100">
                        <DLText text={'Business Houses'}
                                fontSize={'lg'}
                                fontWeight={'semi-bold'}
                                marginBottom={'sm'}/>
                            <div className="flex-grow-1">
                                {
                                    isEmpty(props.businessHouse) ?
                                        <div className='d-flex justify-content-center align-items-center h-100'>
                                            No data found
                                        </div>
                                        :
                                        renderPieChartUI()
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderDesktopTreemapView = () => {

        return (
            <div className=' d-flex flex-row w-100 h-100'>

                {/*<div className='panel p-3' style={{width: '70%'}}>*/}
                {/*    <DLText text={'Sectors'}*/}
                {/*            fontSize={'md'}*/}
                {/*            fontWeight={'semi-bold'}*/}
                {/*            marginBottom={'sm'}/>*/}
                {/*    <EChartTreemap {...props} data={getDataArray(props.industry, 'industry', 'market_value')}/>*/}
                {/*</div>*/}
                <div className='d-flex flex-column justify-content-between ms-3 w-100'>
                    <div className='panel p-3 mb-2 h-100'>
                        <div className="d-flex flex-column h-100">
                            <DLText text={'Market Cap'}
                                    fontSize={'lg'}
                                    fontWeight={'semi-bold'}
                                    marginBottom={'sm'}/>

                            <div className="flex-grow-1">
                                {
                                    isEmpty(props.category) ?
                                        <div className='d-flex justify-content-center align-items-center h-100'>
                                            No data found
                                        </div>
                                        :
                                        <div className='d-flex flex-column align-items-center'>
                                            <EChartDougnute {...props}
                                                            data={getDataArray(props.category, 'category', 'market_value')}/>


                                            <div className='d-flex justify-content-center flex-wrap gap-2'>
                                                {getLegends(getDataArray(props.category, 'category', 'market_value'))}
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='panel p-3 h-100'>
                        <div className="d-flex flex-column h-100">
                        <DLText text={'Business Houses'}
                                fontSize={'lg'}
                                fontWeight={'semi-bold'}
                                marginBottom={'sm'}/>
                            <div className="flex-grow-1">
                                {
                                    isEmpty(props.businessHouse) ?
                                        <div className='d-flex justify-content-center align-items-center h-100'>
                                            No data found
                                        </div>
                                        :
                                        renderPieChartUI()
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderSummaryUI = () => {
        const {activitiesStateLoaded, yAxisBar, isXSView, isMobileView, stocksCount, marketValue} = props;

        return (
            <div className='d-flex flex-column w-100 pt-3' data-section id={'section1'}>
                <div className={isXLView ? "d-flex" : ""}>
                    <div className='panel px-3 pt-sm-3 pt-0 w-100' style={{width: '60%'}}>
                        <div className='flex-shrink-1 w-100'>

                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={'Overview'}
                                fontWeight={'semi-bold'}
                                fontSize={'lg'}
                                marginBottom={'md'}
                            />
                            <div className='d-flex flex-column w-100 '>
                                <div className={isXLView ? 'd-flex flex-row' : 'd-flex flex-column'}
                                     style={{width: '100%'}}>
                                    <div className={isXLView ? 'd-flex flex-column mb-3' : 'd-flex flex-row mb-3' } style={{width:(isLGView || isMobileView) ? '100%' : '15%'}}>
                                        <div className={(isLGView || isMobileView) ? 'border-end py-1 h-100' : 'border-bottom py-3 pb-md-0'}
                                             style={{flex: (isLGView || isMobileView) ? 1 : '', height: isMobileView ? '100%' : '30%'}}>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={'AUM'}
                                                fontSize={'sm'}/>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={numberWithCommas(filterAumValue(marketValue))}
                                                fontWeight={'bold'}
                                                fontSize={'md'}/>
                                        </div>
                                        <div className={(isLGView || isMobileView) ? 'd-flex flex-column justify-content-center py-1 py-md-0 ps-3' : 'd-flex flex-column justify-content-center py-3 py-md-0'}
                                             style={{flex: (isLGView || isMobileView) ? 1 : '', height: "40%"}}>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={'Stocks'}
                                                fontSize={'sm'}/>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={stocksCount}
                                                fontWeight={'bold'}
                                                fontSize={'md'}/>
                                        </div>
                                    </div>

                                    <div className='ps-xl-3' style={{
                                        width: (isLGView || isMobileView) ? '100%' : '95%',
                                        paddingTop: (isLGView || isMobileView) ? '10px' : '0px'
                                    }}>
                                        <LineBarChartMixed {...props} id={"fund-overview"} isBarChartClickable={true} isFrom={'fund'} selectedData={selectedFund}
                                                           getBarClick={getBarClick} isFromFund={true} hideCSS={true}/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={isXLView ? "pt-0" : 'pt-3'}
                         style={{width: (isLGView || isMobileView) ? '100%' : '40%'}}>
                        {(isLGView || isMobileView) ? renderMobileTreemapView() : renderDesktopTreemapView()}
                    </div>
                </div>

                {isXSView ? <div style={{height: '10px', backgroundColor: '#C5C5C5'}}/> : <></>}

            </div>
        )
    };
    const getBarClick = (e) => {
        const {xAxis, yAxisBar} = props;
        if (isEmpty(e)) {
            return;
        }
        let graphMonth = xAxis.find((item, index) => {
            return index === e;
        })

        handleOpenModal(graphMonth);

    }

    const renderDesktopView = () => {
        return (
            <div>
                {renderSummaryUI()}
                <div className={isXSView ? 'pt-0 pb-3' : ' pt-3'} id={'section2'}>
                    <Stocks fundHouseId={fundHouseId} fundId={fundId} {...props}/>
                </div>

                {isXSView ? <div style={{height: '10px', backgroundColor: '#C5C5C5'}}/> : <></>}

                <div className={isXSView ? 'pt-0' : ' pt-3'} data-section id={'section3'}>
                    <Industry fundHouseId={fundHouseId} fundId={fundId} {...props}
                              fileName={getFileName('Sectors Summary-')}/>
                </div>
            </div>

        );
    };

    const renderUI = () => {
        return renderDesktopView();
    };

    if (UIState === fundConst.CONTENT_UNAVAILABLE) {
        return
    }

    if (isEmpty(selectedFund?.fund_name)) {
        return <DLLoader type={'screen'} isVisible={true}/>
    }

    return (<>
        <div>

            <div className='bg-white ' style={{
                position: 'sticky',
                top: 0,
                boxShadow: " 0px 1px 1px 0px rgba(0, 0, 0, 0.20)",
                zIndex: 999
            }}>
                <div className={'px-3 py-2 w-100'}>
                    <div className={'d-flex'}>
                        <i className="fa-solid fa-chevron-left cursor pe-2"
                           style={{marginTop: 8}}
                           onClick={() => props.history.goBack()}/>
                        <div className=''>
                            <DLText marginBottom={'none'}
                                    isInline={true}
                                    text={
                                        <span className='pe-2'>{selectedFund?.fund_name + ' '}
                                            <span style={{paddingBottom: 2, minWidth: 'max-content', fontSize: 12}}>
                                            {'Fund'}
                                        </span>
                                    </span>
                                    }
                                    fontWeight={'semi-bold'}
                                    fontSize={'lg'}/>
                        </div>
                    </div>
                </div>
                <nav className='w-100 pt-1 '>
                    <ul className='d-flex ulLine' style={{listStyle: 'none'}}>
                        <li className={activeSection === 'section1' ? 'active' : ''}
                            style={{margin: '0 10px', paddingLeft: 6}}>
                            <div className='custom-underlined-text cursor'
                                 style={activeSection === 'section1' ? activeStyle : {}}
                                 onClick={() => {
                                     addScroll("section1")
                                 }}>
                                Overview
                            </div>
                            {activeSection === 'section1' &&
                            <div className='bg-dark mt-1' style={{minHeight: '2px'}}>

                            </div>
                            }
                        </li>
                        <li className={activeSection === 'section2' ? 'active' : ''} style={{margin: '0 20px'}}>
                            <div className='custom-underlined-text cursor'
                                 style={activeSection === 'section2' ? activeStyle : {}}
                                 onClick={() => {
                                     addScroll("section2")
                                 }}>
                                Stocks
                            </div>
                            {activeSection === 'section2' &&
                            <div className='bg-dark mt-1' style={{minHeight: '2px'}}>

                            </div>
                            }
                        </li>
                        <li className={activeSection === 'section3' ? 'active' : ''} style={{margin: '0 20px'}}>
                            <div className='custom-underlined-text cursor'
                                 style={activeSection === 'section3' ? activeStyle : {}}
                                 onClick={() => {
                                     addScroll("section3")
                                 }}>
                                Sectors
                            </div>
                            {activeSection === 'section3' &&
                            <div className='bg-dark mt-1' style={{minHeight: '2px'}}>

                            </div>
                            }
                        </li>
                    </ul>
                </nav>
            </div>

            <div id={'myDiv'}
                 onScroll={() => {
                     handleScroll()
                 }}
                 style={{
                     maxHeight: isMobileView ? getMobileHeight("", isMobileView, isXSView) : 'calc(100vh - 165px)',
                     overflowY: 'auto',
                     scrollBehavior: 'smooth'
                 }}>

                <div className='px-sm-3'>
                    {renderUI()}
                    {
                        <PopComponent {...props} selectedData={selectedFund} isFrom={'fund'}/>
                    }
                </div>
            </div>
        </div>
    </>);
}

FundView.propTypes = {};

export default FundView;
