import {userConst} from "./userConsts";
import {createSlice} from '@reduxjs/toolkit'

const appSlice = createSlice({
    name: 'userReducer',
    initialState: {
        userAuthStatus: userConst.AUTH_STATUS_PENDING,
        profile: null,
        isDrawerWidth: false,
        userStatus: ''
    },
    reducers: {
        setUserAuthStatus(state, action) {
            state.userAuthStatus = action.payload;
        },
        setUserProfile(state, action) {
            state.profile = action.payload;
        },
        logoutUser(state) {
            state.userAuthStatus = userConst.AUTH_STATUS_UNAUTHORIZED
        },
        setDrawerWidthData(state, action) {
            state.isDrawerWidth = action.payload
        },
        setNotAccessible(state) {
            state.userStatus= userConst.ACCESS_DENIED
        },
        userLoggedOut(state) {
            state.userStatus= userConst.AUTH_STATUS_LOGGED_OUT;
            state.isLoggedOutScreen = true
        },
        resetLoggedOutState(state){
            state.userStatus = ''
        }
    }
});

export const {userLoggedOut, setUserAuthStatus, setUserProfile, resetLoggedOutState, setNotAccessible, setDrawerWidthData, logoutUser} = appSlice.actions;
export default appSlice.reducer
