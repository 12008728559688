import React, {Component, useState} from 'react';
import {render} from 'react-dom';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import {PieChart} from 'echarts/charts';
import {
    GridComponent,
    LegendComponent,
    TooltipComponent,
    TitleComponent,
    DatasetComponent,
} from 'echarts/components';
import {CanvasRenderer} from 'echarts/renderers';
import './eChart.css';
import withRouter from "react-router-dom/es/withRouter";
import DLText from "dlcomponent/components/DLText/DLText";
import {getTrimmedValue, numberWithCommas} from "../../utils/helper";
import {filterAumValue} from "../../utils/stocksHelper";

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
]);

interface AppProps {
}

interface AppState {
    name: string;
}

function EChartDougnute(props) {
    const {data} = props;

    const getCount = (name) =>{
        let capObj = data.find((item)=>{
            return item.name === name
        });

        return capObj?.count
    };

    const option = {
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
                // Customize the tooltip content

                let stocksCount = getCount(params.name);

                return `<span style="font-size: 16px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif">${params.name}</span> 
                        <br/><span style="font-size: 14px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif""> ${stocksCount}</span> 
                        <span style="font-size: 10px; font-weight: 500; color: #282828 ; font-family: 'Poppins', sans-serif""> Stocks </span><br/>
                        <span style="font-size: 14px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif""> ${numberWithCommas(filterAumValue(params.value))}</span> 
                        <span style="font-size: 10px; font-weight: 500; color: #282828 ; font-family: 'Poppins', sans-serif""> AUM </span>
                         <br/><span style="font-size: 14px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif""> ${params.percent}% 
                         </span><span style="font-size: 10px; font-weight: 500; color: #282828 ; font-family: 'Poppins', sans-serif""> of Total </span> `;
            },
            position: function (point, params, dom, rect, size) {
                // Adjust the tooltip position to stay within the screen bounds
                const top = Math.max(10, point[1]);
                const left = Math.min(window.innerWidth - size.contentSize[0] - 10, point[0]);
                return [left, top];
            },
            // formatter: '{b} {d}% <br/> <span>{getCount({b})}</span> Stocks <br/> {c}', // Customize the tooltip content
        },
        legend: {
            show: false
        },
        series: [
            {
                type: 'pie',
                radius: [40, 70],
                left: 'center',
                itemStyle: {
                    borderColor: '#fff',
                    borderWidth: 1,
                },
                data: data,
                label: {
                    show: false,
                    position: 'center'
                },
                labelLine: {
                    show: false
                },
            },
        ],
    };

    return (
            <div id="main" style={{width: '160px', height: '160px',zIndex:99}}>

                <ReactEChartsCore
                    echarts={echarts}
                    option={option}
                    notMerge={true}
                    lazyUpdate={true}
                />
            </div>
    );
}

export default withRouter(EChartDougnute);

