import React from 'react';
import CreateWatchListModalView from "./CreateWatchList-ModalView";
import {copyWatchlist} from "../WatchListAction";
import {isEmpty} from "../../utils/validations";
import {resetFund} from "../../fund/FundAction";
import {resetStocks, setAdvanceSearchFilter} from "../../AdvanceSearch/AdvanceSeachAction";

function CreateWatchListModal(props) {
    const {selectedWatchListObj,setUserWatchListState, getDropDownData, onChangeValue} = props;

    const onChangeKeywordFilter = (e) => {
                  setUserWatchListState((prev) => ({
                    ...prev,
                     selectedWatchListObj: {
                         ...prev.selectedWatchListObj,
                        createModalName:e
                     },
                     nameErrorMeassage:""
                 }))
    };

    const handleRadioButtonChange = (e) => {
        setUserWatchListState((prev) => ({
            ...prev,
            selectedWatchListObj: {
                ...prev.selectedWatchListObj,
                createModalType:e
            }
        }))

    };

    const copyWatchlistData = (watchlist_id, name) => {
        if (isEmpty(name)) {
            setUserWatchListState((prev) => ({
                ...prev,
                nameErrorMeassage: "Enter Name"
            }))
        } else {
            return copyWatchlist(watchlist_id, name)
                .then((res) => {
                    if (res?.success && !isEmpty(res.data?.data)) {
                        getDropDownData();
                        setUserWatchListState((prev) => ({
                            ...prev,
                            isCreateButtonClicked: false,
                            isCopyButtonClicked: false,
                            selectedWatchListObj: {
                                ...prev.selectedWatchListObj,

                            },
                        }));
                        onChangeValue('isActionModalOpen', false);
                        resetFund();
                        setAdvanceSearchFilter('funds', []);
                        resetStocks('stocks', []);
                    } else {
                        setUserWatchListState((prev) => ({
                            ...prev,
                            isCopyButtonClicked: false,
                        }));
                    }
                })
        }
    };

    return (
        <div>
            <CreateWatchListModalView {...props}
                                      onChangeKeywordFilter={onChangeKeywordFilter}
                                      handleRadioButtonChange={handleRadioButtonChange}
                                      copyWatchlistData={copyWatchlistData}
            />
        </div>
    );
}

export default CreateWatchListModal;



