import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {fundHouseConst} from "./fundHouseConst";
import DLLoader from "../core/components/DLLoader/DLLoader";
import FundHouseView from "./FundHouseView";
import {useParams} from 'react-router-dom'
import {getAllFundHouses, getFundHouseDimension, getFundHouseSummaryData} from "./FundHouseAction";
import {isEmpty} from "../utils/validations";
import {getStockTrendForFundHouse} from "../stocks/StocksActions";
import appTheme from "../assets/appTheme";
import {stocksConst} from "../stocks/stocksConst";
import moment from "moment";
import {getNetBuyingSellingPopUpData} from "./FundHouseAction";
import {filterNetBuyingSellingValue, getFixedValuesAfterDecimal} from "../utils/helper";


function FundHouse(props) {
    const [fundHouseState,setFundHouseState] = useState({
        activitiesStateLoaded: false,
        activitiesData: {},
        fundHouseCount : '',
        fundSchemeCount : '',
        stockCount : '',
        marketValueChangeMoM: '',
        xAxis: [],
        yAxisBar: [],
        yAxisLine: [],
        barColor: [],
        tabValue: 0,
        isDomLoaded: false,
        category: [],
        businessHouse: [],
        industry: [],
        isDimensionDataLoaded: false

    });
    const {fundHouseId} = useParams();
    const [UIState, setUIState] = useState();
    const [fundHouses, setFundHouses] = useState([]);
    const [selectedFundHouse, setSelectedFundHouse] = useState();
    const [activeSection, setActiveSection] = useState('section1');
    const sections = useRef([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [popUpModalData,setPopUpModalData] = useState({
        requiredMonth : '',
        entries:[],
        exits:[],
        netBuying:0,
        netSelling:0,
        buyingStocks:0,
        sellingStocks:0,
        netBuyingSellingDifference:0,
        isModalApiInProgress: true
    });

    const handleOpenModal =(graphMonth)=>{
        setIsModalOpen(true);
        getDataForPopUp(graphMonth)
    };
    const handleCloseModal =()=>{
        setIsModalOpen(false);
        setTimeout(()=>{},200);
        initializeZeroCondition()
    };

    const initializeZeroCondition =()=>{

        setPopUpModalData((prevState)=>({
            ...prevState,
            requiredMonth : "",
            exits: "",
            entries: "",
            buyingStocks: "",
            sellingStocks: "",
            netBuying: "",
            netSelling: "",
            netBuyingSellingDifference: "",
            isModalApiInProgress: true
        }))
    };

    const getDataForPopUp =(date)=>{
        let formattedDate=moment(date,"MMM-YYYY").endOf('month').format("YYYY-MM-DD");
        getNetBuyingSellingPopUpData(formattedDate,fundHouseId).then((res) => {
            if (res?.success) {
                if (!isEmpty(res?.data?.data)){
                    setPopUpModalData((prevState)=>({
                        ...prevState,
                        requiredMonth : date,
                        exits: res?.data?.data?.exitedStocks,
                        entries: res?.data?.data?.addedStocks,
                        buyingStocks: res?.data?.data?.buyingStocksCount,
                        sellingStocks: res?.data?.data?.sellingStocksCount,
                        netBuying: getFixedValuesAfterDecimal(res?.data?.data?.buyingStocksMarketValue,2),
                        netSelling: getFixedValuesAfterDecimal(res?.data?.data?.sellingStocksMarketValue,2),
                        netBuyingSellingDifference: getFixedValuesAfterDecimal(res?.data?.data?.marketValueChange,2),
                        isModalApiInProgress: false
                    }))
                }
            }
        });
    };

    useEffect(() => {

        const date = process.env.REACT_APP_VALUATION_DATE;
        getAllFundHouses()
            .then((res) => {
                if (res.success) {
                    if (!isEmpty(res.data?.data)){
                        setFundHouses(res.data.data);
                        getFundHouseName(fundHouseId, res.data.data);
                    }
                    setUIState(fundHouseConst.CONTENT_AVAILABLE);
                }
            });

        getFundHouseSummaryData(date, encodeURIComponent(fundHouseId)).then((res) => {
            if (res?.success) {
                if (!isEmpty(res.data?.data)){
                    setFundHouseState((prevState)=>({
                        ...prevState,
                        fundSchemeCount: res.data?.data?.funds?.length,
                        marketValue : setFundHouseData(res.data.data)
                    }));
                }
            }
        });

        getStockTrendForFundHouse(date, encodeURIComponent(fundHouseId)).then((res) => {
            if (res?.success) {
                setBarLineChartData(res.data);
             }
        });

        getFundHouseDimension(date, encodeURIComponent(fundHouseId) ).then((res) => {
            if (res?.success) {
                setFundHouseState((prev)=>({
                    ...prev,
                    category: res.data?.data?.category,
                    businessHouse: res.data?.data?.businessHouse,
                    industry: res.data?.data?.industry,
                    isDimensionDataLoaded: true
                }));
             }
        })

    }, []);

    useEffect(() => {
        setUIState('');

        setTimeout(()=>{
            setUIState(stocksConst.CONTENT_AVAILABLE);
        },200)
    }, [props.isLGView,props.isXLView, props.isMDView]);

    const setFundHouseData = (data) => {

        if (isEmpty(data.funds)){
            return 0
        }

        let numberOfFundsIncreasingValue = 0;
        let marketValueOfFundsIncreasingValue = 0;
        let numberOfFundsDecreasingValue = 0;
        let marketValueFundsDecreasingValue = 0;

        let numberOfIndustriesIncreasingValue = 0;
        let marketValueOfIndustriesIncreasingValue = 0;
        let numberOfIndustriesDecreasingValue = 0;
        let marketValueIndustriesDecreasingValue = 0;

        let numberOfStocksIncreasingValue = 0;
        let marketValueOfStocksIncreasingValue = 0;
        let numberOfStocksDecreasingValue = 0;
        let marketValueStocksDecreasingValue = 0;

        let totalAUM = 0;
        let totalAUMChange = 0;


        for (let i = 0; i < data.funds.length; i++) {
            let marketValue = data.funds[i].market_value_change_mom;
            totalAUM += data.funds[i].market_value;
            totalAUMChange += data.funds[i].market_change;
        }
        return getFixedValuesAfterDecimal(totalAUM,0)

    };

    const setBarLineChartData = (chartData) =>{
        let xAxis = [];
        let yAxisBar = [];
        let yAxisLine = [];
        let barColor = [];
        if (isEmpty(chartData)){
            return
        }

        chartData?.data.forEach((item)=>{
            xAxis.push(item?.month);
            yAxisBar.push(getFixedValuesAfterDecimal(filterNetBuyingSellingValue(item?.total_market_value_change_due_to_activity)));
            yAxisLine.push(getFixedValuesAfterDecimal(item?.total_market_value, 2));
            if (item?.total_market_value_change_due_to_activity >= 0){
                barColor.push(appTheme.appColor.success)
            }else {
                barColor.push(appTheme.appColor.danger)
            }
        });

        setFundHouseState((prevState)=>({
            ...prevState,
            xAxis: xAxis,
            yAxisBar: yAxisBar,
            yAxisLine: yAxisLine,
            barColor: barColor
        }))
    };

    const getFundHouseName = (fundHouseId, fundHouses) => {
        let fundHouseFound = fundHouses.find((fundHouse) => fundHouse.fund_house_id === fundHouseId);
        setSelectedFundHouse(fundHouseFound);
    };

    const setStockNames = (data) => {

        for (let i = 0; i < data.length; i++) {

            let stockName = data[i].isin_code;

            setFundHouses(stockNames => [...stockNames, stockName]);

        }
    };

    const onChangeValue = (name, value) => {
        setSelectedFundHouse(value);
    };

    const handleChange = (name, value) => {

        if (name === 'stockCount'){
            setFundHouseState((prev)=>({
                ...prev,
                stockCount: value
            }));
            return
        }

        if (!fundHouseState?.isDomLoaded){
            setFundHouseState((prev)=>({
                ...prev,
                isDomLoaded:true
            }))
        }
    };

    const handleTabChange = (name, value) => {
        setFundHouseState((prev)=>({
            ...prev,
            tabValue: value
        }));
    };

    const refresh = () => {
        window.location.reload(true);
    };

    if (isEmpty(UIState)) {
        return (
            <DLLoader type={'screen'} isVisible={true}/>
        );
    }

    return (
        <FundHouseView
            {...props}
            fundHouseId={fundHouseId}
            fundHouses={fundHouses}
            selectedFundHouse={selectedFundHouse}
            refresh={refresh}
            onChangeValue={onChangeValue}
            {...fundHouseState}
            handleTabChange={handleTabChange}
            handleChange={handleChange}

            {...popUpModalData}
            isModalOpen={isModalOpen}
            handleOpenModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
            getDataForPopUp={getDataForPopUp}
        />
    );
}


FundHouse.propTypes = {};
const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView
});

export default connect(mapStateToProps, {})(FundHouse);
