import React, {Component} from 'react';
import {
    Chart,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Legend, BarElement, ArcElement,
    Tooltip,
} from 'chart.js'

import { Bar } from 'react-chartjs-2';
import {isEmpty} from "../../utils/helperCommon";
import appTheme from "../../assets/appTheme";
import {numberWithCommas} from "../../utils/helper";
import {connect} from "react-redux";
import {getMaxRoundedNumber, getMinRoundedNumber, getRoundedStepSize} from "../../utils/stocksHelper";
Chart.register(CategoryScale, LinearScale, BarElement, Title, LineElement, PointElement, Tooltip, Legend, ArcElement);

class BarChartView extends Component {
    getOptions = () =>{
        const {externalOptions, onClick, direction, position, xStacked, yStacked, maxAxisValue, percentage, isMobileView, data, isXSView} = this.props;
        const numericNumbers = data[0]?.data.map(Number);
        let maxValue = Math.max(...numericNumbers);
        let minValue = Math.min(...numericNumbers);
        let stepSize;
        let steps = isXSView? 2 : 4;

        // For all positive values in the array

        if (minValue >= 0 && maxValue > 0) {
            minValue = 0;
            if (minValue >= 0 && maxValue > 0 && maxValue < 10) {
                maxValue = 10;
            } else {
                maxValue = getMaxRoundedNumber(Math.trunc(maxValue));
            }

            stepSize = maxValue / steps;
            stepSize = getRoundedStepSize(stepSize);
            let difference = maxValue - (stepSize * steps);
            maxValue = stepSize * (steps + Math.ceil(difference / stepSize));
            stepSize = maxValue / steps;
        }

        // For all negative values in the array
        if (minValue < 0 && maxValue <= 0) {

            let newMaxValue = minValue * (-1)
            let newMinValue = Math.round(maxValue * (-1))

            if (newMinValue >= 0 && newMaxValue > 0 && newMaxValue < 10) {
                newMaxValue = 10;
            } else {
                newMaxValue = Math.ceil(getMaxRoundedNumber(newMaxValue));
            }

            stepSize = newMaxValue / steps;
            stepSize = getRoundedStepSize(stepSize);
            let difference = newMaxValue - (stepSize * steps);
            newMaxValue = stepSize * (steps + Math.ceil(difference / stepSize));
            stepSize = newMaxValue / steps;
            minValue = newMaxValue * (-1);
            maxValue = 0

        }

        let defaultOptions = {
            indexAxis: direction,
            maintainAspectRatio: false,
            elements: {
                bar: {
                    borderWidth: 2,
                },
            },
            responsive: true,
            plugins:
                {
                    datalabels: {
                        display: true,
                        color: 'black',
                        formatter: Math.round,
                        anchor: 'end',
                        offset: -20,
                        align: 'end'
                    },
                    legend: {
                        position: position,
                    },
                    title: {
                        display: false,
                        text: '',
                    },
                    tooltip:{
                        backgroundColor: appTheme.appColor.white,
                        bodyColor: appTheme.appColor.black,
                        borderColor:'#D2D2D2',
                        borderWidth:1,
                        padding: 20,
                        titleColor:'black',
                        titleFont:'bold',
                        titleFontSize: 30,
                        yAlign: 'bottom',
                        boxPadding: 5,
                        callbacks: {
                            label: function(context) {
                                let label = context.dataset.label || '';

                                if (label) {
                                    label += ': ';
                                }
                                if (percentage){
                                    label += context.parsed.x + '%';
                                }else {
                                    label += context.parsed.x ;

                                }
                                // if (context.parsed.y !== null) {
                                //     label += context.parsed.x + '%';
                                // }
                                return numberWithCommas(label);
                            }
                        }
                    }
                },
            scales: {
                x: {
                    stacked: xStacked,
                    display: true,
                    beginAtZero: true,
                    max: maxValue,
                    min: minValue,
                    ticks: {
                        precision: 0,
                        callback: function(value, index, values) {
                            if (percentage){
                                return value === 0 ? '0%' : numberWithCommas(value) + '%';
                            }
                            return value === 0 ? '0' :numberWithCommas(value);
                        },
                        font: {
                            family: 'Poppins !important',
                            size: isMobileView ? 10 : 12,
                            color: '#282828'
                        },
                        stepSize: stepSize,
                    }
                },
                y: {
                    display: true,
                    stacked: yStacked,
                    beginAtZero: true,
                    min:0,
                    max: 9,
                    ticks: {
                        precision: 0,
                        font: {
                            family: 'Poppins !important',
                            size: isMobileView ? 10 : 12,
                            color: '#282828'
                        },
                        stepSize: stepSize,
                    },
                    grid: {
                        display: false,
                       // <-- this removes y-axis line
                        lineWidth: 0.5,
                    }
                },
            },
            onHover: (event, elements) => {
                // Customize the cursor or hover effect here
                if (event.nativeEvent && event.nativeEvent.target) {
                    if (elements.length > 0) {
                        event.nativeEvent.target.style.cursor = 'pointer';
                    } else {
                        event.nativeEvent.target.style.cursor = 'default';
                    }
                }
            },
            onClick: onClick,
        };

        if (isEmpty(externalOptions)){
            return defaultOptions
        }else {
            return externalOptions
        }

    };

    getHeight= () =>{
        const {isXSView, isMobileView, isLGView, data} = this.props;
        return '350px';
    };

    render() {
        const {xLabels, data, className, height, width} = this.props;

        let barData = {
            labels: xLabels,
            datasets: data,
        };


        return (
                <Bar className={className}
                     options={this.getOptions()}
                     width={'100%'}
                     height={this.getHeight()}
                     data={barData}
                     type={"bar"}
                />
        );
    }
}
const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isSMView: state.appState.deviceInfo.isSMView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXSView: state.appState.deviceInfo.isXSView
});
export default connect(mapStateToProps, {})(BarChartView);
