import React, {Component} from 'react';
import BarChartView from "./BarChartView";
import PropTypes from "prop-types";

class BarChart extends Component {
    render() {
        return (
            <BarChartView {...this.props} />
        );
    }
}

BarChart.propTypes = {
    data: PropTypes.array.isRequired,  // array of object,  syntax [{label: dataset-1, data=[1,2,3], backgroundColor: '#0033ff', borderColor: 'green'}],
    className: PropTypes.string,
    direction: PropTypes.string,
    width:PropTypes.string,
    height:PropTypes.string,
    position:PropTypes.string,//top,left,right,bottom & empty position -> would hide the label
    yStacked:PropTypes.bool,
    xStacked:PropTypes.bool,
    percentage:PropTypes.bool,
    maxAxisValue:PropTypes.number,
    onClick: PropTypes.func,
    xLabels: PropTypes.array.isRequired,
    externalOptions: PropTypes.object,
};


BarChart.defaultProps = {
    direction:'y',
    position:'',
    xStacked:false,
    percentage:false,
    yStacked:false
};

export default BarChart;
