import React, {Suspense, useEffect, useState} from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import ResAppBar from "../../core/layout/drawerContainer/ResAppBar";
import {getAuthorizationToken, removeAuthorizationToken} from "../../utils/httpHelper";
import {getMyProfile} from "../../user/userActions";
import {
    setCurrentPathname,
    setPrevLocation,
    showSystemErrorBlock,
    showToastMessage
} from "../../core/actions/appActions";
import {connect} from "react-redux";
import PostLoginRoute from "../../core/webRouteWrapper/PostLoginRoute";
import Drawer from '@mui/material/Drawer';
import DLToastMessage from "../../core/components/DLToastMessage/DLToastMessage";
import PreLoginRoute from "../../core/webRouteWrapper/PreLoginRoute";
import Login from "../../user/logIn/components/Login";
import PageNotFound from "../PageNotFound/pageNotFound";
import AppDrawer from "../appDrawer/AppDrawer";
import {userConst} from "../../user/userConsts";
import store from "../../store.native";
import {resetLoggedOutState, setUserAuthStatus, setUserProfile} from "../../user/userReducer";
import DLLoader from "../../core/components/DLLoader/DLLoader";
import LoggedOutUser from "../../core/components/LoggedOutUser/LoggedOutUser";
import PageNotAccessible from "../../core/components/PageNotAccessible/PageNotAccessible";
import Stocks from "../../stocks/Stocks";
import Homepage from "../../homepage/Homepage";
import FundHouse from "../../fundHouse/FundHouse";
import Fund from "../../fund/Fund";
import Industry from "../../industry/Industry";
import FundHouseListing from "../../fundHouse/FundHouseListing/FundHouseListing";
import FundListing from "../../fund/FundListing/FundListing";
import StocksListing from "../../stocks/StocksListing/StocksListing";
import IndustryListing from "../../industry/IndustryListing/IndustryListing";
import DLText from "dlcomponent/components/DLText/DLText";
import moment from "moment";
import SearchModal from "../../searchUi/SearchModal";
import AdvanceSearch from "../../AdvanceSearch/AdvanceSearch";
import Explorer from "../../explorer/Explorer";
import MoneyFlowExplorer from "../../moneyFIleExplorer/MoneyFlowExplorer";
import AdvanceFundSearch from "../../advanceFundSearch/AdvanceFundSearch";
import PrePostLoginRoute from "../../core/webRouteWrapper/PrePostLoginRoute";
import CompareStocks from "../../compareStocks/CompareStocks/CompareStocks";
import UserWatchList from "../../watchList/userWatchList/UserWatchList";
import StockHolding from "../../stockHolding/StockHolding";



const Navigator = (props) => {

    const {isMobileView, userAuthStatus, setUserProfile, setUserAuthStatus, profile} = props;


    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    useEffect(() => {
        setCurrentPathname(props.location.pathname);

        let authResponse = getAuthorizationToken();
        if (authResponse.status) {
            setUserAuthStatus(userConst.AUTH_STATUS_AUTHORIZED);
        } else {
            setUserAuthStatus(userConst.AUTH_STATUS_UNAUTHORIZED);
        }
    }, []);

    useEffect(() => {
        if (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED) {
            getMyProfile()
                .then((res) => {
                    switch (res?.statusCode) {
                        case 204:
                        case 201:
                        case 200:
                            setUserProfile(res.data.data);
                            break;
                        case 400:
                            setUserAuthStatus(userConst.AUTH_STATUS_UNAUTHORIZED);
                            break;
                        case 401:
                            store.dispatch(showToastMessage('warning', res.__error));
                            break;
                        default:
                            store.dispatch(showSystemErrorBlock());
                            break;
                    }
                });
        }

        if (userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED) {
            setUserProfile(null);
            removeAuthorizationToken();
        }
    }, [userAuthStatus]);

    useEffect(() => {
        if (props.userStatus === userConst.AUTH_STATUS_LOGGED_OUT) {
            props.history.replace('/session-expired');
            store.dispatch(resetLoggedOutState());
        }
    }, [props.userStatus]);

    if (userAuthStatus === userConst.AUTH_STATUS_PENDING) {
        return (<DLLoader type={"screen"} isVisible={true}/>);
    }


    const renderDrawer = () => {
        if (userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED) {
            return null;
        }
        let contentUi = <AppDrawer
            profile={profile}
            isMobileView={isMobileView}
            closeDrawer={() => setIsDrawerOpen(false)}
        />;
        let mobileDrawerUi = (
            <Drawer
                anchor={'left'}
                open={isDrawerOpen}
                sx={{
                    width: '300px',
                    '& .MuiDrawer-paper': {
                        width: '300px',
                    },
                }}
                onClose={() => setIsDrawerOpen(false)}>
                <div style={{
                    background: '#E7ECF2',
                    height: '100vh'
                }}>
                    {contentUi}
                </div>
            </Drawer>
        );

        let desktopDrawerUi = (
            <div style={{
                width: 240,
                height: '100%',
                position: "fixed",
                background: '#E7ECF2'
            }}
                 className={"drawer-height"}>
                {contentUi}
            </div>
        );

        return (
            <div>
                {isMobileView ? mobileDrawerUi : desktopDrawerUi}
            </div>
        );


    };

    const renderAppBar = () => {
        if (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED) {
            return (
                <ResAppBar openDrawer={() => setIsDrawerOpen(true)}/>

            );
        }
    };

    const renderContent = () => {
        const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;
        return (
            <div className="w-100 "
                 style={{
                     minHeight: (props.location.pathname === "/fund-search" || userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED)? '100vh' :isMobileView ? 'calc(100vh - 129px)' : 'calc(100vh - 75px)',
                     maxHeight: (props.location.pathname === "/fund-search" || userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED) ? '100vh' : isMobileView ? 'calc(100vh - 129px)' : 'calc(100vh - 75px)',
                     overflowY:'auto'
                 }}>

                <Suspense fallback={(<DLLoader type={"screen"} isVisible={true}/>)}>
                    <Switch>
                        <PreLoginRoute exact path="/user/login" component={Login}/>
                        <PostLoginRoute exact path="/watchlist" component={UserWatchList}/>
                        <PostLoginRoute exact path="/stocks" component={StocksListing}/>
                        <PostLoginRoute exact path="/stocks/:isin" component={Stocks}/>
                        <PostLoginRoute exact path="/fund-houses" component={FundHouseListing}/>
                        <PostLoginRoute exact path="/explorer" component={Explorer}/>
                        <PostLoginRoute exact path="/fund-houses/:fundHouseId" component={FundHouse}/>
                        <PostLoginRoute exact path="/funds/" component={FundListing}/>
                        <PostLoginRoute exact path="/funds/:fundHouseId/:fundId" component={Fund}/>
                        <PostLoginRoute exact path="/sectors" component={IndustryListing}/>
                        <PostLoginRoute exact path="/sectors/:sector" component={Industry}/>
                        <PostLoginRoute exact path={"/fund-search"} component={SearchModal}/>
                        <PostLoginRoute exact path={"/advanced-search"} component={AdvanceSearch}/>
                        <PostLoginRoute exact path={"/advanced-fund-search"} component={AdvanceFundSearch}/>
                        <PostLoginRoute exact path={"/money-flow-explorer"} component={MoneyFlowExplorer}/>
                        <PostLoginRoute exact path={"/compare-stocks/"} component={CompareStocks}/>
                        <PostLoginRoute exact path="/" component={Homepage}/>
                        <PostLoginRoute exact path={"/stock-holding"} component={StockHolding}/>
                        <PrePostLoginRoute exact path="/session-expired" component={LoggedOutUser}/>
                        <Route exact path={"/access-denied"} component={PageNotAccessible}/>
                        <Route exact path="*" component={PageNotFound}/>

                    </Switch>
                </Suspense>
                <DLToastMessage/>
                {((userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED) && (props.location.pathname !== "/fund-search") && (props.location.pathname !== "/session-expired")) &&
                <div className=" position-fixed end-0 w-100 px-3 py-2 "
                     style={{backgroundColor: 'rgba(249, 249, 251, 1)', bottom: isMobileView ? 54 : 0, zIndex:1000}}>
                    <div className='d-flex justify-content-between'>
                        <div className="me-2">
                            <DLText
                                id={'revenue'}
                                type={'normal'}
                                text={'As of ' + moment(VALUATION_DATE).format('DD-MMM-yyyy')}
                                customWrapperStyle={{
                                    textAlign: 'end'
                                }}
                                marginBottom={'none'}
                                fontSize={isMobileView ? 'xs' : 'sm'}/>
                        </div>
                        <div className="me-2">
                            <DLText
                                id={'revenue'}
                                type={'normal'}
                                text={'All Financial Amounts in crores'}
                                customWrapperStyle={{
                                    textAlign: 'end'
                                }}
                                marginBottom={'none'}
                                fontSize={isMobileView ? 'xs' : 'sm'}/>
                        </div>
                    </div>
                </div>
                }

            </div>
        );
    };
    return (
        <div>
            { (props.location.pathname !== "/fund-search") && renderAppBar()}
            <div className=""
                 style={{
                     marginTop: (userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED || props.location.pathname === "/fund-search" ) ? 0 : '54px',
                     marginBottom: (userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED || props.location.pathname === "/fund-search" ) ? 0 : isMobileView ? "54px": ''
                 }}>
                {isDrawerOpen && renderDrawer()}
                {renderContent()}
            </div>
        </div>
    );
};
Navigator.propTypes = {};

const mapStateToProps = (state) => ({
    userAuthStatus: state.userState.userAuthStatus,
    profile: state.userState.profile,
    isDrawerOpen: state.appState.isDrawerOpen,
    isMobileView: state.appState.deviceInfo.isMobileView,
    userStatus: state.userState.userStatus
});

export default connect(mapStateToProps, {
    setUserProfile,
    setPrevLocation,
    setUserAuthStatus,

})(withRouter(Navigator));
