import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {stocksConst} from "../../stocks/stocksConst";
import DLLoader from "../../core/components/DLLoader/DLLoader";
import {useParams} from 'react-router-dom'
import {
    getAllStocks,
    getSceenrCode,
    getStockActivities, getStockDetails,
    getStockTrend,
    getSummaryByFund,
    getSummaryByFundHouse
} from "./StocksActions";
import {isEmpty} from "../../utils/validations";
import appTheme from "../../assets/appTheme";
import moment from "moment";
import {getNetBuyingSellingPopUpData} from "./StocksActions";
import {filterNetBuyingSellingValue, getFixedValuesAfterDecimal} from "../../utils/helper";
import ThirdStockView from "./ThirdStockView";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;
function ThirdStock(props) {
    const{requiredIsin,buttonId,advanceSearchFilter}= props;
    const [StocksState,setStocksState] = useState({
        activitiesStateLoaded: false,
        activitiesData: {},
        fundHouseCount : '',
        fundSchemeCount : '',
        marketValueChangeMoM: '',
        xAxis: [],
        xAxisWithYear: [],
        yAxisBar: [],
        yAxisLine: [],
        barColor: [],
        sceenerCode:'',
        tabValue:0,
        isDomLoaded: false,
        netBuyingSellingSum: '',

        holdings: "",
        marketCapCategory:"",
        businessHouse:"",
        industry:"",
        isInternationalStock: true,
        buttonIdReference: buttonId
    });
    const isin = requiredIsin;
    const [UIState, setUIState] = useState();
    const [stocks, setStocks] = useState([]);
    const [selectedStock, setSelectedStock] = useState();
    const [tableData, setTableData] = useState([]);
    const [fundsData, setFundsData] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [popUpModalData,setPopUpModalData] = useState({
        requiredMonth : '',
        entries:[],
        exits:[],
        netBuying:0,
        netSelling:0,
        buyingFunds:0,
        sellingFunds:0,
        netBuyingSellingDifference:0,
        isModalApiInProgress: true
    });

    const handleOpenModal =(graphMonth)=>{
        setIsModalOpen(true);
        getDataForPopUp(graphMonth);
    };
    const handleCloseModal =()=>{
        setIsModalOpen(false);
        setTimeout(()=>{},200);
        initializeZeroCondition()
    };

    const initializeZeroCondition =()=>{

        setPopUpModalData((prevState)=>({
            ...prevState,
            requiredMonth : "",
            exits: "",
            entries: "",
            buyingFunds: 0,
            sellingFunds: 0,
            netBuying: 0,
            netSelling: 0,
            netBuyingSellingDifference: 0,
            isModalApiInProgress: true
        }))
    };

    const getDataForPopUp =(date)=>{
        let formattedDate=moment(date,"MMM-YYYY").endOf('month').format("YYYY-MM-DD");
        getNetBuyingSellingPopUpData(formattedDate,isin, advanceSearchFilter.fundHouses, advanceSearchFilter.funds, advanceSearchFilter.fundHouseForFund).then((res) => {
            if (res.success) {
                if (!isEmpty(res?.data?.data)){
                    setPopUpModalData((prevState)=>({
                        ...prevState,
                        requiredMonth : date,
                        exits: res?.data?.data?.exitedFunds,
                        entries: res?.data?.data?.addedFunds,
                        buyingFunds: res?.data?.data?.buyingFundsCount,
                        sellingFunds: res?.data?.data?.sellingFundsCount,
                        netBuying: getFixedValuesAfterDecimal(res?.data?.data?.buyingFundsMarketValue,2),
                        netSelling: getFixedValuesAfterDecimal(res?.data?.data?.sellingFundsMarketValue,2),
                        netBuyingSellingDifference: getFixedValuesAfterDecimal(res?.data?.data?.marketValueChange,2),
                        isModalApiInProgress: false
                    }))
                }
            }
        });
    };

    useEffect(() => {
        if(isEmpty(isin)){
            return;
        }

        setUIState('');

        const date = process.env.REACT_APP_VALUATION_DATE;

        getAllStocks()
            .then((res) => {
                // setIsLoading(false);
                if (res.success) {
                    // setStocks(res.data.data);
                    getStockName(isin, res.data.data);


                    setUIState(stocksConst.CONTENT_AVAILABLE);
                }else {
                    setUIState(stocksConst.CONTENT_UNAVAILABLE);
                }

            });
        getSceenrCode(date, isin).then((res)=>{
            if (res.success){
                setStocksState((prevState)=>({
                    ...prevState,
                    sceenerCode: res.data?.data
                }))
            }
        });

        getStockActivities(date, isin).then((res)=>{
            if (res.success){
                setStocksState((prevState)=>({
                    ...prevState,
                    activitiesData:res.data.data,
                    activitiesStateLoaded: true
                }))
            }else {
                setStocksState((prevState)=>({
                    ...prevState,
                    activitiesStateLoaded: true
                }))
            }
        });


        getSummaryByFundHouse(date, isin, advanceSearchFilter.fundHouses, advanceSearchFilter.funds, advanceSearchFilter.fundHouseForFund).then((res) => {
            if (res?.success) {
                if (isEmpty(res.data?.data)){
                    setStocksState((prevState)=>({
                        ...prevState,
                        fundHouseCount:  [],
                        marketValue : ""
                    }));
                    setTableData([]);

                }else {
                    setStocksState((prevState)=>({
                        ...prevState,
                        fundHouseCount:  getFundHousesCount(res.data?.data),
                        marketValue : setAUMData(res.data?.data.slice(1, res.data?.data.length))
                    }));
                    setTableData(res.data?.data.slice(1, res.data?.data.length));

                }
            }
        });

        getSummaryByFund(date,isin, advanceSearchFilter.fundHouses, advanceSearchFilter.funds, advanceSearchFilter.fundHouseForFund).then((res) => {
            if (res.success && !isEmpty(res.data?.data)) {
                setStocksState((prevState)=>({
                    ...prevState,
                    fundSchemeCount: !isEmpty(res.data.data) ? getFundsCount(res.data.data) : [],
                }));
                setFundsData(res.data?.data.slice(1, res.data?.data.length))
            }else{
                setStocksState((prevState)=>({
                    ...prevState,
                    fundSchemeCount: "",
                }));
                setFundsData([])
            }
        });

        getStockTrend(date, isin, advanceSearchFilter.fundHouses, advanceSearchFilter.funds, advanceSearchFilter.fundHouseForFund).then((res) => {
            if (res?.success && !isEmpty(res.data)) {
                setBarLineChartData(res.data)
            }else{
                setStocksState((prevState)=>({
                    ...prevState,
                    xAxis: [],
                    xAxisWithYear: [],
                    yAxisBar: [],
                    barColor: [],
                    yAxisLine: [],
                    netBuyingSellingSum: '',
                }))
            }
        })

        getStockDetails(isin).then((res) => {
            if (res?.success) {
                if(!isEmpty(res.data?.data)){
                    setStocksState((prevState)=>({
                        ...prevState,
                        holdings: getFixedValuesAfterDecimal(res.data?.data[0]?.market_cap, 0),
                        marketCapCategory: res.data?.data[0]?.category,
                        businessHouse: res.data?.data[0]?.business_house,
                        industry: res.data?.data[0]?.industry,
                        isInternationalStock: res.data?.data[0]?.category === "International"
                    }))
                }
                // setBarLineChartData(res.data)
            }
        })

    }, [isin,advanceSearchFilter]);

    const setBarLineChartData = (chartData) =>{
        let xAxis = [];
        let xAxisWithYear = [];
        let yAxisBar = [];
        let yAxisLine = [];
        let barColor = [];
        if (isEmpty(chartData)){
            return
        }

        chartData?.data.forEach((item)=>{
            xAxis.push(moment(item?.month,"MMM-YYYY").format("MMM"));
            xAxisWithYear.push(moment(item?.month,"MMM-YYYY").format("MMM-YYYY"));
            yAxisBar.push(filterNetBuyingSellingValue(item?.total_market_value_change_due_to_activity));
            yAxisLine.push(getFixedValuesAfterDecimal(item?.total_market_value,2));
            if (item?.total_market_value_change_due_to_activity >=0){
                barColor.push(appTheme.appColor.success)
            }else {
                barColor.push(appTheme.appColor.danger)
            }

        });

        let sum = getNetBuyingSellingTotal(yAxisBar)

        setStocksState((prevState)=>({
            ...prevState,
            xAxis: xAxis,
            xAxisWithYear:xAxisWithYear,
            yAxisBar: yAxisBar,
            barColor: barColor,
            yAxisLine: yAxisLine,
            netBuyingSellingSum: sum
        }))
    };

    useEffect(() => {
        setUIState('');

        setTimeout(()=>{
            setUIState(stocksConst.CONTENT_AVAILABLE);
        },200)
    }, [props.isLGView,props.isXLView, props.isMDView]);

    const getFundHousesCount = (data) =>{
        let count= 0;
        if(isEmpty(data)){
            return count;
        }
        data.map((item)=>{
            if(item?.total_market_value === 0 || isNaN(item?.total_market_value)){

            }else{
                count++;
            }
        })
        return count;

    }

    const getFundsCount = (data) =>{
        let count= 0;
        if(isEmpty(data)){
            return count;
        }
        data.map((item)=>{
            if(item?.total_market_value === 0 || isNaN(item?.total_market_value)){

            }else{
                count++;
            }
        })
        return count;

    }

    const setAUMData = (data) => {

        let totalAUM = 0;
        let totalChangeMoM = 0;
        let percentChange = 0;

        for (let i = 0; i < data.length; i++) {

            let marketValue = data[i].total_market_value ;
            let marketValueChangeMoM = data[i].market_value_change_mom ;

            totalAUM += marketValue;
            totalChangeMoM += marketValueChangeMoM;

        }

        return  getFixedValuesAfterDecimal(totalAUM,2)

    };

    const getNetBuyingSellingTotal = (arr) =>{
        let sum = 0
        arr.forEach(x => {
            sum += x;
        });
        return sum;
    }

    const getStockName = (isin, stocks) => {
        let stockFound = stocks.find((stock) => stock.isin_code === isin);
        setSelectedStock(stockFound);
    };

    const setStockNames = (data) => {

        for (let i = 0; i < data.length; i++) {

            let stockName = data[i].isin_code;

            setStocks(stockNames => [...stockNames, stockName]);

        }
    };

    const onChangeValue = (name, value) => {
        setSelectedStock(value);
    };

    const handleTabChange = (name, value) => {
        setStocksState((prev)=>({
            ...prev,
            tabValue: value
        }));
    };

    const handleChange = () => {
        if (!StocksState?.isDomLoaded){
            setStocksState((prev)=>({
                ...prev,
                isDomLoaded:true
            }))

        }
    };
    const refresh = () => {
        window.location.reload(true);
    };

    if (isEmpty(UIState)) {
        return (
            <DLLoader type={'screen'} isVisible={true}/>
        );
    }

    return (
        <ThirdStockView
            {...props}
            isin={isin}
            selectedStocks={stocks}
            selectedStock={selectedStock}
            refresh={refresh}
            onChangeValue={onChangeValue}
            handleTabChange={handleTabChange}
            tableData={tableData}
            fundsData={fundsData}
            handleChange={handleChange}
            {...StocksState}

            {...popUpModalData}
            isModalOpen={isModalOpen}
            handleOpenModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
            getDataForPopUp={getDataForPopUp}
        />
    );
}


ThirdStock.propTypes = {};
const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView
});

export default connect(mapStateToProps, {})(ThirdStock);