import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {isEmpty} from "../../utils/validations";
import {
    getFixedValuesAfterDecimal,
    getMobileHeight,
    getNextRoundedNumber, getRandomId, getTrimmedValue,
    getTrimVal,
    numberWithCommas,
    searchData
} from "../../utils/helper";
import {stocksConst} from "../../stocks/stocksConst";
import DLText from "dlcomponent/components/DLText/DLText";
import DLLoader from "../../core/components/DLLoader/DLLoader";
import LineBarChartMixed from "../../component-utils/charts/LineBarChartMixed";
import DLDropDown from "dlcomponent/components/DLDropdown/DLDropDown";
import DLInputTextField from "dlcomponent/components/DLInputTextField/DLInputTextField";
import CompareHeadingView from "./CompareHeadingView";
import Stocks from "../Stock/FirstStock";
import DLButton from "dlcomponent/components/DLButton/DLButton";
import appTheme from "../../assets/appTheme";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import AdvanceFundHouseListingModal from "../FundAndFundHousesModal/AdvanceFundHouseListingModal";
import AdvanceFundListingModal from "../FundAndFundHousesModal/AdvanceFundListingModal";
import AdvanceSearchChipsView from "../chipsView/AdvanceSearchChipsView";
import FirstStock from "../Stock/FirstStock";
import SecondStock from "../Stock/SecondStock";
import ThirdStock from "../Stock/ThirdStock"
import FundsEchart from "../CompareStocksMobileUi/fundsEchart/FundsEchart";
import NetBuyingSellingBarChart from "../CompareStocksMobileUi/netBuyingSelling/NetBuyingSellingBarChart";
import AUMLineChart from "../CompareStocksMobileUi/aumLineChart/AUMLineChart";
import FundHousesEchart from "../CompareStocksMobileUi/funHousesEchart/FundHousesEchart";
import CountsUi from "../CompareStocksMobileUi/countsUi/CountsUi";
import DLModal from "dlcomponent/components/DLModal/DLModal";
import DLModalTitle from "dlcomponent/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "dlcomponent/components/DLModal/DLModalContainer/DLModalContainer";
import MarketCap from "../CompareStocksMobileUi/marketCap/MarketCap";
import MarketCapCategory from "../CompareStocksMobileUi/marketCapCategory/MarketCapCategory";
import {downloadDataByObject} from "../../fund/FundAction";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// TODO use lazy load for each component which is firing the api
function CompareStocksView(props) {

    const {
        isin, refresh, isMDView, isLGView, isMobileView, stocks, clearAllFilters, isXSView, selectedStock, closeDropDown, UIState, stockListing,
        firstOption, secondOption, thirdOption, setDropDownOption, handleClick, openFirst, openSecond, openThird, funds , fundHouses , fundHouseForFund
        , anchorEl, getDropDownOptions, firstStock, secondStock, thirdStock, firstOptionName, secondOptionName, thirdOptionName, checkdata,areAllStockValuesNotEmpty,
        clearOption, filteredOption, firstSearched, secondSearched, thirdSearched, firstStockName, secondStockName, thirdStockName, areAllValuesNotEmpty, getFileName, selectedStocks
    } = props;

    let downloadDataObject = {
        stocks:  [firstStock, secondStock, thirdStock],
        fundHouseId: fundHouses,
        fundId: funds,
        fundHouseForFund:fundHouseForFund
    };

    const emptyCount = selectedStocks.filter(item => item === '').length;

    const getFirstPopUp = (popUpId) => {
        let data = isEmpty(firstSearched) ? stockListing : filteredOption;

        return (
            <div>
                <Popper
                    // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
                    sx={{paddingTop: "5px", zIndex: 2}}
                    open={openFirst}
                    anchorEl={anchorEl}
                    //placement={placement}
                    //transition
                >
                    <Paper sx={{borderRadius: 2, height: "222px"}}>
                        <Typography sx={{px: 2, py: 1}}>
                            <div>
                                <div className="d-flex justify-content-between">
                                    <DLText id={"stock-first-popup"}
                                            text={"Stock"}
                                            fontSize={"md"}
                                            fontWeight={"semi-bold"}
                                    />
                                    <div onClick={() => {
                                        closeDropDown("First_PopUp")
                                    }}><i className={"fa fa-remove mx-2 my-1 cursor"}
                                          style={{color: appTheme.appColor.black}}/></div>
                                </div>
                                <div className={"mt-1"}>
                                    <DLInputTextField
                                        id={'businessList-keywordSearch'}
                                        label={''}
                                        rows={1}
                                        fullWidth
                                        styleType={"search"}
                                        placeholder={'Search...'}
                                        value={firstSearched}
                                        onChangeValue={(searchVal) => props.requestSearch(searchVal, "First_Search")}
                                        isClearable={true}
                                        inputHeight={'sm'}
                                        marginBottom={"none"}
                                        autoFocus={true}
                                        // onBlur={()=>{handleOnBlur()}}
                                        InputProps={{
                                            endAdornment: (

                                                !isEmpty(firstSearched) ?
                                                    <i className="fa fa-remove m-2 cursor" onClick={() => {
                                                        props.requestSearch("", "First_Search")
                                                    }} style={{color: appTheme.appColor.black}}/>
                                                    :
                                                    <i className={'fas fa-search pr-1'}
                                                       style={{color: appTheme.appColor.black}}/>
                                            )
                                        }}
                                        disableHelperMessageHeight={true}
                                    />
                                </div>
                                <div className="m-3 me-0 cursor"
                                     style={{height: "108px", width: isXSView ? "250px" : "420px", overflowY: "auto"}}>
                                    {!isEmpty(firstSearched) && data.map((item) => {
                                        if (item?.isin_code !== firstStock && item?.isin_code !== secondStock && item?.isin_code !== thirdStock) {
                                            return (
                                                <div className={"list-item-hover cursor"} key={getRandomId()}
                                                     onClick={() => {
                                                         setDropDownOption(popUpId, item?.isin_code, item?.company_name)
                                                     }}
                                                >
                                                    <DLText text={item?.company_name}
                                                            fontSize={"sm"}
                                                            id={""}
                                                    />
                                                </div>
                                            )
                                        }
                                    })
                                    }
                                    {
                                        isEmpty(data) &&
                                        <DLText
                                            id={'revenue'}
                                            type={'normal'}
                                            text={'No data found.'}
                                            fontColor={'lightGray'}
                                            fontSize={'md'}/>
                                    }
                                </div>
                            </div>
                        </Typography>
                    </Paper>
                </Popper>
            </div>
        )
    }

    const getSecondPopUp = (popUpId) => {
        let data = isEmpty(secondSearched) ? stockListing : filteredOption
        return (
            <div>
                <Popper
                    // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
                    sx={{paddingTop: "5px", zIndex: 2}}
                    open={openSecond}
                    anchorEl={anchorEl}
                    //placement={placement}
                    //transition
                >
                    <Paper sx={{borderRadius: 2, height: "222px"}}>
                        <Typography sx={{px: 2, py: 1}}>
                            <div>
                                <div className="d-flex justify-content-between">
                                    <DLText id={"stock-second-popup"}
                                            text={"Stock"}
                                            fontSize={"md"}
                                            fontWeight={"semi-bold"}
                                    />
                                    <div onClick={() => {
                                        closeDropDown("Second_PopUp")
                                    }}><i className={"fa fa-remove mx-2 my-1 cursor"}
                                          style={{color: appTheme.appColor.black}}/></div>
                                </div>
                                <div className={"mt-1"}>
                                    <DLInputTextField
                                        id={'businessList-keywordSearch'}
                                        label={''}
                                        rows={1}
                                        fullWidth
                                        styleType={"search"}
                                        placeholder={'Search...'}
                                        value={secondSearched}
                                        onChangeValue={(searchVal) => props.requestSearch(searchVal, "Second_Search")}
                                        isClearable={true}
                                        inputHeight={'sm'}
                                        marginBottom={"none"}
                                        autoFocus={true}
                                        // onBlur={()=>{handleOnBlur()}}
                                        InputProps={{
                                            endAdornment: (

                                                !isEmpty(secondSearched) ?
                                                    <i className="fa fa-remove m-2 cursor" onClick={() => {
                                                        props.requestSearch("", "Second_Search")
                                                    }} style={{color: appTheme.appColor.black}}/>
                                                    :
                                                    <i className={'fas fa-search pr-1'}
                                                       style={{color: appTheme.appColor.black}}/>
                                            )
                                        }}
                                        disableHelperMessageHeight={true}
                                    />
                                </div>
                                <div className="m-3 me-0"
                                     style={{height: "108px", width: isXSView ? "250px" : "420px", overflowY: "auto"}}>
                                    {!isEmpty(secondSearched) && data.map((item) => {
                                        if (item?.isin_code !== firstStock && item?.isin_code !== secondStock && item?.isin_code !== thirdStock) {
                                            return (
                                                <div className={"list-item-hover cursor"} key={getRandomId()}
                                                     onClick={() => {
                                                         setDropDownOption(popUpId, item?.isin_code, item?.company_name)
                                                     }}
                                                >
                                                    <DLText text={item?.company_name}
                                                            fontSize={"sm"}
                                                            id={""}
                                                    />
                                                </div>
                                            )
                                        }
                                    })
                                    }
                                    {
                                        isEmpty(data) &&
                                        <DLText
                                            id={'revenue'}
                                            type={'normal'}
                                            text={'No data found.'}
                                            fontColor={'lightGray'}
                                            fontSize={'md'}/>
                                    }
                                </div>
                            </div>
                        </Typography>
                    </Paper>
                </Popper>
            </div>
        )
    }

    const getThirdPopUp = (popUpId) => {
        let data = isEmpty(thirdSearched) ? stockListing : filteredOption
        return (
            <div>
                <Popper
                    // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
                    sx={{paddingTop: "5px", zIndex: 2}}
                    open={openThird}
                    anchorEl={anchorEl}
                    //placement={placement}
                    //transition
                >
                    <Paper sx={{borderRadius: 2, height: "222px"}}>
                        <Typography sx={{px: 2, py: 1}}>
                            <div>
                                <div className="d-flex justify-content-between">
                                    <DLText id={"stock-third-popup"}
                                            text={"Stock"}
                                            fontSize={"md"}
                                            fontWeight={"semi-bold"}
                                    />
                                    <div><i className={"fa fa-remove mx-2 my-1 cursor"} onClick={() => {
                                        closeDropDown("Third_PopUp")
                                    }}
                                            style={{color: appTheme.appColor.black}}/></div>
                                </div>
                                <div className={"mt-1"}>
                                    <DLInputTextField
                                        id={'businessList-keywordSearch'}
                                        label={''}
                                        rows={1}
                                        fullWidth
                                        styleType={"search"}
                                        placeholder={'Search...'}
                                        value={thirdSearched}
                                        onChangeValue={(searchVal) => props.requestSearch(searchVal, "Third_Search")}
                                        isClearable={true}
                                        inputHeight={'sm'}
                                        marginBottom={"none"}
                                        autoFocus={true}
                                        // onBlur={()=>{handleOnBlur()}}
                                        InputProps={{
                                            endAdornment: (

                                                !isEmpty(thirdSearched) ?
                                                    <i className="fa fa-remove m-2 cursor" onClick={() => {
                                                        props.requestSearch("", "Third_Search")
                                                    }} style={{color: appTheme.appColor.black}}/>
                                                    :
                                                    <i className={'fas fa-search pr-1'}
                                                       style={{color: appTheme.appColor.black}}/>
                                            )
                                        }}
                                        disableHelperMessageHeight={true}
                                    />
                                </div>
                                <div className="m-3 me-0"
                                     style={{height: "108px", width: isXSView ? "250px" : "420px", overflowY: "auto"}}>
                                    {!isEmpty(thirdSearched) && data.map((item) => {
                                        if (item?.isin_code !== firstStock && item?.isin_code !== secondStock && item?.isin_code !== thirdStock) {
                                            return (
                                                <div className={"list-item-hover cursor"} key={getRandomId()}
                                                     onClick={() => {
                                                         setDropDownOption(popUpId, item?.isin_code, item?.company_name)
                                                     }}
                                                >
                                                    <DLText text={item?.company_name}
                                                            fontSize={"sm"}
                                                            id={""}
                                                    />
                                                </div>
                                            )
                                        }
                                    })
                                    }
                                    {
                                        isEmpty(data) &&
                                        <DLText
                                            id={'revenue'}
                                            type={'normal'}
                                            text={'No data found.'}
                                            fontColor={'lightGray'}
                                            fontSize={'md'}/>
                                    }
                                </div>
                            </div>
                        </Typography>
                    </Paper>
                </Popper>
            </div>
        )
    }

    const renderFirstOptionModal = (popUpId) => {
        let data = isEmpty(firstSearched) ? stockListing : filteredOption
        return (
            <DLModal id={'clear-canvas'}
                     history={props.history}
                     maxWidth={"sm"}
                     onClose={() => {
                         closeDropDown("First_PopUp")
                     }}
                     open={openFirst}
                     isFullScreen={true}
            >

                <span className={'fa-solid fa-xmark ms-2 cursor '}/>
                <DLModalTitle id={'campaignDetail-sendTestModalTitle'}
                              onClose={() => {
                                  closeDropDown("First_PopUp")
                              }}
                              title={"Stocks"}
                              fontColor={"#333333"}
                />
                <DLModalContainer id={'clear-canvas-container'}>
                    <div className="h-100" style={{minHeight: "400px"}}>
                        <div>
                            <div>
                                <DLInputTextField
                                    id={'businessList-keywordSearch'}
                                    label={''}
                                    rows={1}
                                    fullWidth
                                    styleType={"search"}
                                    placeholder={'Search...'}
                                    value={firstSearched}
                                    onChangeValue={(searchVal) => props.requestSearch(searchVal, "First_Search")}
                                    isClearable={true}
                                    inputHeight={'sm'}
                                    marginBottom={"none"}
                                    autoFocus={true}
                                    // onBlur={()=>{handleOnBlur()}}
                                    InputProps={{
                                        endAdornment: (

                                            !isEmpty(firstSearched) ?
                                                <i className="fa fa-remove m-2 cursor" onClick={() => {
                                                    props.requestSearch("", "First_Search")
                                                }} style={{color: appTheme.appColor.black}}/>
                                                :
                                                <i className={'fas fa-search pr-1'}
                                                   style={{color: appTheme.appColor.black}}/>
                                        )
                                    }}
                                    disableHelperMessageHeight={true}
                                />
                            </div>
                            <div className="m-3 me-0 cursor"
                                 style={{overflowY: "auto"}}>
                                {!isEmpty(firstSearched) && data.map((item) => {
                                    if (item?.isin_code !== firstStock && item?.isin_code !== secondStock && item?.isin_code !== thirdStock) {
                                        return (
                                            <div className={"list-item-hover cursor"} key={getRandomId()}
                                                 onClick={() => {
                                                     setDropDownOption(popUpId, item?.isin_code, item?.company_name)
                                                 }}
                                            >
                                                <DLText text={item?.company_name}
                                                        fontSize={"sm"}
                                                        id={""}
                                                />
                                            </div>
                                        )
                                    }
                                })
                                }
                                {
                                    isEmpty(data) &&
                                    <DLText
                                        id={'revenue'}
                                        type={'normal'}
                                        text={'No data found.'}
                                        fontColor={'lightGray'}
                                        fontSize={'md'}/>
                                }
                            </div>
                        </div>
                    </div>
                </DLModalContainer>

            </DLModal>
        )
    }

    const renderSecondOptionModal = (popUpId) => {
        let data = isEmpty(secondSearched) ? stockListing : filteredOption
        return (
            <DLModal id={'clear-canvas'}
                     history={props.history}
                     maxWidth={"sm"}
                     onClose={() => {
                         closeDropDown("Second_PopUp")
                     }}
                     open={openSecond}
                     isFullScreen={true}
            >

                <span className={'fa-solid fa-xmark ms-2 cursor '}/>
                <DLModalTitle id={'campaignDetail-sendTestModalTitle'}
                              onClose={() => {
                                  closeDropDown("Second_PopUp")
                              }}
                              title={"Stocks"}
                              fontColor={"#333333"}
                />
                <DLModalContainer id={'clear-canvas-container'}>
                    <div className="h-100" style={{minHeight: "400px"}}>
                        <div>
                            <div>
                                <DLInputTextField
                                    id={'businessList-keywordSearch'}
                                    label={''}
                                    rows={1}
                                    fullWidth
                                    styleType={"search"}
                                    placeholder={'Search...'}
                                    value={secondSearched}
                                    onChangeValue={(searchVal) => props.requestSearch(searchVal, "Second_Search")}
                                    isClearable={true}
                                    inputHeight={'sm'}
                                    marginBottom={"none"}
                                    autoFocus={true}
                                    // onBlur={()=>{handleOnBlur()}}
                                    InputProps={{
                                        endAdornment: (

                                            !isEmpty(secondSearched) ?
                                                <i className="fa fa-remove m-2 cursor" onClick={() => {
                                                    props.requestSearch("", "Second_Search")
                                                }} style={{color: appTheme.appColor.black}}/>
                                                :
                                                <i className={'fas fa-search pr-1'}
                                                   style={{color: appTheme.appColor.black}}/>
                                        )
                                    }}
                                    disableHelperMessageHeight={true}
                                />
                            </div>
                            <div className="m-3 me-0 cursor"
                                 style={{overflowY: "auto"}}>
                                {!isEmpty(secondSearched) && data.map((item) => {
                                    if (item?.isin_code !== firstStock && item?.isin_code !== secondStock && item?.isin_code !== thirdStock) {
                                        return (
                                            <div className={"list-item-hover cursor"} key={getRandomId()}
                                                 onClick={() => {
                                                     setDropDownOption(popUpId, item?.isin_code, item?.company_name)
                                                 }}
                                            >
                                                <DLText text={item?.company_name}
                                                        fontSize={"sm"}
                                                        id={""}
                                                />
                                            </div>
                                        )
                                    }
                                })
                                }
                                {
                                    isEmpty(data) &&
                                    <DLText
                                        id={'revenue'}
                                        type={'normal'}
                                        text={'No data found.'}
                                        fontColor={'lightGray'}
                                        fontSize={'md'}/>
                                }
                            </div>
                        </div>

                    </div>
                </DLModalContainer>

            </DLModal>
        )
    }

    const renderThirdOptionModal = (popUpId) => {
        let data = isEmpty(thirdSearched) ? stockListing : filteredOption
        return (
            <DLModal id={'clear-canvas'}
                     history={props.history}
                     maxWidth={"sm"}
                     onClose={() => {
                         closeDropDown("Third_PopUp")
                     }}
                     open={openThird}
                     isFullScreen={true}
            >

                <span className={'fa-solid fa-xmark ms-2 cursor '}/>
                <DLModalTitle id={'campaignDetail-sendTestModalTitle'}
                              onClose={() => {
                                  closeDropDown("Third_PopUp")
                              }}
                              title={"Stocks"}
                              fontColor={"#333333"}
                />
                <DLModalContainer id={'clear-canvas-container'}>
                    <div className="h-100" style={{minHeight: "400px"}}>
                        <div>
                            <div>
                                <DLInputTextField
                                    id={'businessList-keywordSearch'}
                                    label={''}
                                    rows={1}
                                    fullWidth
                                    styleType={"search"}
                                    placeholder={'Search...'}
                                    value={thirdSearched}
                                    onChangeValue={(searchVal) => props.requestSearch(searchVal, "Third_Search")}
                                    isClearable={true}
                                    inputHeight={'sm'}
                                    marginBottom={"none"}
                                    autoFocus={true}
                                    // onBlur={()=>{handleOnBlur()}}
                                    InputProps={{
                                        endAdornment: (

                                            !isEmpty(thirdSearched) ?
                                                <i className="fa fa-remove m-2 cursor" onClick={() => {
                                                    props.requestSearch("", "Third_Search")
                                                }} style={{color: appTheme.appColor.black}}/>
                                                :
                                                <i className={'fas fa-search pr-1'}
                                                   style={{color: appTheme.appColor.black}}/>
                                        )
                                    }}
                                    disableHelperMessageHeight={true}
                                />
                            </div>
                            <div className="m-3 me-0 cursor"
                                 style={{overflowY: "auto"}}>
                                {!isEmpty(thirdSearched) && data.map((item) => {
                                    if (item?.isin_code !== firstStock && item?.isin_code !== secondStock && item?.isin_code !== thirdStock) {
                                        return (
                                            <div className={"list-item-hover cursor"} key={getRandomId()}
                                                 onClick={() => {
                                                     setDropDownOption(popUpId, item?.isin_code, item?.company_name)
                                                 }}
                                            >
                                                <DLText text={item?.company_name}
                                                        fontSize={"sm"}
                                                        id={""}
                                                />
                                            </div>
                                        )
                                    }
                                })
                                }
                                {
                                    isEmpty(data) &&
                                    <DLText
                                        id={'revenue'}
                                        type={'normal'}
                                        text={'No data found.'}
                                        fontColor={'lightGray'}
                                        fontSize={'md'}/>
                                }
                            </div>
                        </div>

                    </div>
                </DLModalContainer>

            </DLModal>
        )
    }

    const addScroll = (id) => {
        const element = document.getElementById(id);
        if (!isEmpty(element)) {
            element.scrollIntoView({behavior: "smooth", block: "start"});
        }
    };

    const renderContent = () => {
        if (isEmpty(firstStock) && isEmpty(secondStock) && isEmpty(thirdStock)) {
            return (
                <div>
                    <div className="p-2">
                        {renderDropDownUi()}
                    </div>
                    <div className="d-flex justify-content-center align-items-center" style={{height: "400px"}}>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={'Please select stocks to compare'}
                            marginBottom={'md'}
                            fontSize={'md'}
                            fontColor={'lightGray'}/>
                    </div>
                </div>
            );
        }
        return (
            <div className="d-flex w-100 h-100">
                <div className={"w-100 h-100"}
                     style={{flex: 1, minWidth: "100px"}}>
                    <CompareHeadingView {...props}/>
                </div>
                <div className={"w-100 h-100"} style={{flex: 2, minWidth: "180px"}}>
                    <FirstStock {...props} requiredIsin={firstStock} getFirstPopUp={getFirstPopUp}/>
                </div>
                <div className={"w-100 h-100"} style={{flex: 2, minWidth: "180px"}}>
                    <SecondStock {...props} requiredIsin={secondStock} getSecondPopUp={getSecondPopUp}/>
                </div>
                <div className={"w-100 h-100"} style={{flex: 2, minWidth: "180px"}}>
                    <ThirdStock {...props} requiredIsin={thirdStock} getThirdPopUp={getThirdPopUp}/>
                </div>
            </div>
        );
    };

    const renderMobileContent = () => {
        if (isEmpty(firstStock) && isEmpty(secondStock) && isEmpty(thirdStock)) {
            return (
                <div className="d-flex justify-content-center align-items-center" style={{height: "400px"}}>
                    <DLText
                        id={'revenue-value'}
                        type={'normal'}
                        text={'Please select stocks to compare'}
                        marginBottom={'md'}
                        fontSize={'md'}
                        fontColor={'lightGray'}/>
                </div>
            );
        }
        return (
            <div className="w-100 h-100">
                <div className={"h-100"} style={{flex: 2}}>
                    <div className="p-2" style={{minHeight: "40px", backgroundColor: "#F8F8FC"}}>
                        <div className="d-flex px-3">
                            <div className='' style={{flex: 3}}>

                            </div>
                            <div className='' style={{flex: 1}}>
                                <DLText marginBottom={'none'}
                                        isInline={true}
                                        text={"Fund Houses"}
                                        fontWeight={'bold'}
                                        fontSize={'sm'}/>
                            </div>
                            <div className='' style={{flex: 1}}>
                                <DLText marginBottom={'none'}
                                        isInline={true}
                                        text={"Funds"}
                                        fontWeight={'bold'}
                                        fontSize={'sm'}/>
                            </div>
                        </div>
                    </div>
                    <div className={"px-3 my-4"}>
                        <CountsUi {...props} requiredIsin={firstStock} stockName={firstStockName}/>
                        {!isEmpty(secondOption) && <div style={{borderBottom: "1px solid #C5C5C5"}}></div>}
                        <CountsUi {...props} requiredIsin={secondStock} stockName={secondStockName}/>
                        {!isEmpty(thirdOption) && <div style={{borderBottom: "1px solid #C5C5C5"}}></div>}
                        <CountsUi {...props} requiredIsin={thirdStock} stockName={thirdStockName}/>
                    </div>
                </div>

                <div className={"h-100"} style={{flex: 2}}>
                    <div className="p-2" style={{minHeight: "40px", backgroundColor: "#F8F8FC"}}>
                        <div className="d-flex px-3">
                            <div className='' style={{flex: 3}}>

                            </div>
                            <div className='' style={{flex: 2}}>
                                <DLText marginBottom={'none'}
                                        isInline={true}
                                        text={"Market Cap"}
                                        fontWeight={'bold'}
                                        fontSize={'sm'}/>
                            </div>
                        </div>
                    </div>
                    <div className={"px-3 my-4"}>
                        <MarketCap {...props} requiredIsin={firstStock} stockName={firstStockName}/>
                        {!isEmpty(secondOption) && <div style={{borderBottom: "1px solid #C5C5C5"}}></div>}
                        <MarketCap {...props} requiredIsin={secondStock} stockName={secondStockName}/>
                        {!isEmpty(thirdOption) && <div style={{borderBottom: "1px solid #C5C5C5"}}></div>}
                        <MarketCap {...props} requiredIsin={thirdStock} stockName={thirdStockName}/>
                    </div>
                </div>

                <div className={"h-100"} style={{flex: 2}}>
                    <div className="p-2" style={{minHeight: "40px", backgroundColor: "#F8F8FC"}}>
                        <div className="d-flex px-3">
                            <div className='' style={{flex: 3}}>

                            </div>
                            <div className='' style={{flex: 2}}>
                                <DLText marginBottom={'none'}
                                        isInline={true}
                                        text={"Market Cap Category"}
                                        fontWeight={'bold'}
                                        fontSize={'sm'}/>
                            </div>
                        </div>
                    </div>
                    <div className={"px-3 my-4"}>
                        <MarketCapCategory {...props} requiredIsin={firstStock} stockName={firstStockName}/>
                        {!isEmpty(secondOption) && <div style={{borderBottom: "1px solid #C5C5C5"}}></div>}
                        <MarketCapCategory {...props} requiredIsin={secondStock} stockName={secondStockName}/>
                        {!isEmpty(thirdOption) && <div style={{borderBottom: "1px solid #C5C5C5"}}></div>}
                        <MarketCapCategory {...props} requiredIsin={thirdStock} stockName={thirdStockName}/>
                    </div>
                </div>

                <div className={"h-100"} style={{flex: 2}}>
                    <div className="text-center p-2" style={{minHeight: "40px", backgroundColor: "#F8F8FC"}}>
                        <DLText id={""}
                                text={"AUM"}
                                fontSize={"sm"}
                                fontWeight={"bold"}
                        />
                    </div>
                    <div className="px-3">
                        <AUMLineChart {...props} requiredIsin={firstStock} stockName={firstStockName}/>
                        {!isEmpty(secondOption) && <div style={{borderBottom: "1px solid #C5C5C5"}}></div>}
                        <AUMLineChart {...props} requiredIsin={secondStock} stockName={secondStockName}/>
                        {!isEmpty(thirdOption) && <div style={{borderBottom: "1px solid #C5C5C5"}}></div>}
                        <AUMLineChart {...props} requiredIsin={thirdStock} stockName={thirdStockName}/>
                    </div>
                </div>
                <div className={"h-100"} style={{flex: 2}}>
                    <div className="text-center p-2" style={{minHeight: "40px", backgroundColor: "#F8F8FC"}}>
                        <DLText id={""}
                                text={"Net Buying Selling"}
                                fontSize={"sm"}
                                fontWeight={"bold"}
                        />
                    </div>
                    <div className="px-3">
                        <NetBuyingSellingBarChart {...props} id={"first-view-netbuyingselling-barchart"}
                                                  requiredIsin={firstStock} stockName={firstStockName}/>
                        {!isEmpty(secondOption) && <div style={{borderBottom: "1px solid #C5C5C5"}}></div>}
                        <NetBuyingSellingBarChart {...props} id={"second-view-netbuyingselling-barchart"}
                                                  requiredIsin={secondStock} stockName={secondStockName}/>
                        {!isEmpty(thirdOption) && <div style={{borderBottom: "1px solid #C5C5C5"}}></div>}
                        <NetBuyingSellingBarChart {...props} id={"third-view-netbuyingselling-barchart"}
                                                  requiredIsin={thirdStock} stockName={thirdStockName}/>
                    </div>
                </div>
                <div className={"h-100"} style={{flex: 2}}>
                    <div className="text-center p-2" style={{minHeight: "40px", backgroundColor: "#F8F8FC"}}>
                        <DLText id={""}
                                text={"Top 10 Fund Houses"}
                                fontSize={"sm"}
                                fontWeight={"bold"}
                        />
                    </div>
                    <div className="px-3">
                        <FundHousesEchart {...props} requiredIsin={firstStock} stockName={firstStockName}/>
                        {!isEmpty(secondOption) && <div style={{borderBottom: "1px solid #C5C5C5"}}></div>}
                        <FundHousesEchart {...props} requiredIsin={secondStock} stockName={secondStockName}/>
                        {!isEmpty(thirdOption) && <div style={{borderBottom: "1px solid #C5C5C5"}}></div>}
                        <FundHousesEchart {...props} requiredIsin={thirdStock} stockName={thirdStockName}/>
                    </div>
                </div>
                <div className={"h-100"} style={{flex: 2}}>
                    <div className="text-center p-2" style={{minHeight: "40px", backgroundColor: "#F8F8FC"}}>
                        <DLText id={""}
                                text={"Top 10 Funds"}
                                fontSize={"sm"}
                                fontWeight={"bold"}
                        />
                    </div>
                    <div className="px-3">
                        <FundsEchart {...props} requiredIsin={firstStock} stockName={firstStockName}/>
                        {!isEmpty(secondOption) && <div style={{borderBottom: "1px solid #C5C5C5"}}></div>}
                        <FundsEchart {...props} requiredIsin={secondStock} stockName={secondStockName}/>
                        {!isEmpty(thirdOption) && <div style={{borderBottom: "1px solid #C5C5C5"}}></div>}
                        <FundsEchart {...props} requiredIsin={thirdStock} stockName={thirdStockName}/>
                    </div>
                </div>
            </div>
        );
    };

    const renderDropDownUi = () => {
        return (
            <div className="d-flex">
                <div className={"h-100"} style={{flex: 1, minWidth: "100px"}}>

                </div>
                <div className={"d-flex justify-content-center align-items-center h-100"}
                     style={{flex: 2, minWidth: "180px"}}>
                    <div className={"w-100 h-100"} hidden={!isEmpty(firstStock)}>
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="w-50">
                                <div className='d-flex justify-content-center align-items-center cursor p-2'
                                     style={{
                                         height: 36,
                                         minWidth: 'max-content',
                                         border: "1px solid",
                                         borderRadius: "9px",
                                         borderColor: "#C5C5C5"
                                     }}
                                     onClick={(e) => {
                                         handleClick(e, "First_Button")
                                     }}
                                >
                                    <div className="d-flex justify-content-center align-items-center w-100">

                                        <div className="d-flex justify-content-center align-items-center w-100">
                                            <i className="fa fa-plus m-2 cursor"
                                               style={{color: appTheme.appColor.black}}/>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {getFirstPopUp("First_PopUp")}

                    </div>
                </div>
                <div className={"d-flex justify-content-center align-items-center h-100"}
                     style={{flex: 2, minWidth: "180px"}}>
                    <div className={"w-100 h-100"} hidden={!isEmpty(secondStock)}>
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="w-50">
                                <div className='d-flex justify-content-center align-items-center cursor p-2'
                                     style={{
                                         height: 36,
                                         minWidth: 'max-content',
                                         border: "1px solid",
                                         borderRadius: "9px",
                                         borderColor: "#C5C5C5"
                                     }}
                                     onClick={(e) => {
                                         handleClick(e, "Second_Button")
                                     }}
                                >
                                    <div className="d-flex justify-content-center align-items-center w-100">

                                        <div className="d-flex justify-content-center align-items-center w-100">
                                            <i className="fa fa-plus m-2 cursor"
                                               style={{color: appTheme.appColor.black}}/>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {getSecondPopUp("Second_PopUp")}

                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center"
                     style={{flex: 2, minWidth: "180px"}}>
                    <div className={"w-100 h-100"} hidden={!isEmpty(thirdStock)}>
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="w-50">
                                <div className='d-flex justify-content-center align-items-center cursor p-2'
                                     style={{
                                         height: 36,
                                         minWidth: 'max-content',
                                         border: "1px solid",
                                         borderRadius: "9px",
                                         borderColor: "#C5C5C5"
                                     }}
                                     onClick={(e) => {
                                         handleClick(e, "Third_Button")
                                     }}
                                >
                                    <div className="d-flex justify-content-center align-items-center w-100">

                                        <div className="d-flex justify-content-center align-items-center w-100">
                                            <i className="fa fa-plus m-2 cursor"
                                               style={{color: appTheme.appColor.black}}/>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {getThirdPopUp("Third_PopUp")}

                    </div>
                </div>
            </div>
        );
    };

    const renderDropDownMobileUi = () => {
        return (
            <div className="my-2">
                <div className={""}>
                    {
                        isEmpty(firstStock) ?

                            <div className='d-flex justify-content-center align-items-center cursor p-2'
                                 style={{
                                     height: 36,
                                     width: '48px',
                                     border: "1px solid",
                                     borderRadius: "9px",
                                     borderColor: "#C5C5C5"
                                 }}
                                 onClick={(e) => {
                                     handleClick(e, "First_Button")
                                 }}
                            >
                                <div>


                                    <div className="">
                                        <i className="fa fa-plus m-2 cursor"
                                           style={{color: appTheme.appColor.black}}/>

                                    </div>

                                </div>
                            </div>
                            :
                            <div className={"d-flex justify-content-between align-items-center"}>
                                <div>
                                    <DLText
                                        text={getTrimmedValue(firstStockName, 40)}
                                        marginBottom={"none"}
                                        fontSize={"xs"}
                                        fontWeight={"semi-bold"}
                                    />
                                </div>

                                <i className="fa fa-remove m-2 cursor" onClick={() => {
                                    clearOption("First_Button")
                                }} style={{color: appTheme.appColor.black}}/>
                            </div>
                    }

                    {renderFirstOptionModal("First_PopUp")}
                </div>
                <div className={""}>
                    {
                        isEmpty(secondStock) ?

                            <div hidden={isEmpty(firstStock)}>
                                <div className='d-flex justify-content-center align-items-center cursor p-2'
                                     style={{
                                         height: 36,
                                         width: '48px',
                                         border: "1px solid",
                                         borderRadius: "9px",
                                         borderColor: "#C5C5C5"
                                     }}
                                     onClick={(e) => {
                                         handleClick(e, "Second_Button")
                                     }}
                                >
                                    <div>

                                        <div className="d-flex justify-content-center align-items-center">
                                            <i className="fa fa-plus m-2 cursor"
                                               style={{color: appTheme.appColor.black}}/>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className={"d-flex justify-content-between align-items-center"}>
                                <div>
                                    <DLText
                                        text={getTrimmedValue(secondStockName, 40)}
                                        marginBottom={"none"}
                                        fontSize={"xs"}
                                        fontWeight={"semi-bold"}
                                    />
                                </div>

                                <i className="fa fa-remove m-2 cursor" onClick={() => {
                                    clearOption("Second_Button")
                                }} style={{color: appTheme.appColor.black}}/>
                            </div>
                    }

                    {renderSecondOptionModal("Second_PopUp")}
                </div>
                <div className="">
                    {
                        isEmpty(thirdStock) ?

                            <div hidden={isEmpty(secondStock)}>
                                <div className='d-flex justify-content-center align-items-center cursor p-2'
                                     style={{
                                         height: 36,
                                         width: '48px',
                                         border: "1px solid",
                                         borderRadius: "9px",
                                         borderColor: "#C5C5C5"
                                     }}
                                     onClick={(e) => {
                                         handleClick(e, "Third_Button")
                                     }}
                                >
                                    <div>

                                        <div className="d-flex justify-content-center align-items-center">
                                            <i className="fa fa-plus m-2 cursor"
                                               style={{color: appTheme.appColor.black}}/>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            :
                            <div className={"d-flex justify-content-between align-items-center"}>
                                <div>
                                    <DLText
                                        text={getTrimmedValue(thirdStockName, 40)}
                                        marginBottom={"none"}
                                        fontSize={"xs"}
                                        fontWeight={"semi-bold"}
                                    />
                                </div>

                                <i className="fa fa-remove m-2 cursor" onClick={() => {
                                    clearOption("Third_Button")
                                }} style={{color: appTheme.appColor.black}}/>
                            </div>
                    }

                    {renderThirdOptionModal("Third_PopUp")}
                </div>
            </div>
        );
    };

    const renderChipsFilterUI = () => {
        return <AdvanceSearchChipsView {...props}/>
    };

    const renderAdvanceSearchModal = () => {
        return (
            <>
                <AdvanceFundHouseListingModal {...props}/>
                <AdvanceFundListingModal {...props}/>
            </>
        )
    };

    const renderDownloadButton = () =>{
        let selectedStocks = [firstStock, secondStock, thirdStock];

        let nonEmptyStocks = selectedStocks?.filter(item => !isEmpty(item));

        return nonEmptyStocks?.length > 1
    };

    const renderDesktopView = () => {

        return (
            <div className='h-100'>
                <div className='py-0 px-3'>
                    <div className="d-flex justify-content-between align-items-start">
                        {renderChipsFilterUI()}

                        <div className={'d-flex align-items-center'}>
                            {!areAllValuesNotEmpty() &&
                            <div className={(emptyCount <= 1 ) && (selectedStocks.length !== 0) && "me-3"}>
                                <DLText
                                    id={'clear'}
                                    marginBottom={'none'}
                                    text={'Clear All'}
                                    customTextStyle={{
                                        color: appTheme.linkStyle.color
                                    }}
                                    isClickable={true}
                                    onClick={() => {
                                        clearAllFilters()
                                    }}
                                    fontSize={'sm'}/>
                            </div>
                            }
                            {(renderDownloadButton(selectedStocks)) &&//selectedStock length > 1 show
                            <div
                                className='ms-3 d-flex align-items-center justify-content-center downloadButtonCss cursor'>
                                <i className='fas fa-download fs-6'
                                   onClick={() => {
                                       downloadDataByObject('/service/stocks/compareStock/download', "", 'Download', getFileName("Compare-stocks-"), downloadDataObject)
                                   }}
                                   style={{fontSize: 12}}/>
                            </div>
                            }
                        </div>
                    </div>
                    {/*{renderDropDownUi()}*/}
                </div>
                <div className="px-3">
                    <div className='panel pt-0 mb-3'
                         style={{height: isMDView ? 'calc(100vh - 235px)' : 'calc(100vh - 190px)', overflowY: 'auto'}}>
                        {renderContent()}
                    </div>
                </div>
            </div>

        );
    };

    const renderMobileView = () => {

        return (
            <div className='h-100'>
                <div className=''>
                    <div className={"px-3 my-2"}>
                        <div className="d-flex justify-content-between align-items-start">
                            {renderChipsFilterUI()}
                            <div className={'d-flex align-items-center'}>
                                {!areAllValuesNotEmpty() &&
                                <div className="">
                                    <DLText
                                        id={'clear'}
                                        marginBottom={'none'}
                                        text={'Clear All'}
                                        customTextStyle={{
                                            color: appTheme.linkStyle.color
                                        }}
                                        isClickable={true}
                                        onClick={() => {
                                            clearAllFilters()
                                        }}
                                        fontSize={'sm'}/>
                                </div>}
                                {(renderDownloadButton(selectedStocks)) &&//selectedStock length > 1 show
                                <div
                                    className='ms-3 d-flex align-items-center justify-content-center downloadButtonCss cursor'>
                                    <i className='fas fa-download fs-6'
                                       onClick={() => {
                                           downloadDataByObject('/service/stocks/compareStock/download', "", 'Download', getFileName("Compare-stocks-"), downloadDataObject)
                                       }}
                                       style={{fontSize: 12}}/>
                                </div>
                                }
                                </div>
                        </div>
                        {renderDropDownMobileUi()}
                    </div>
                </div>
                <div className='pt-sm-3 pt-0 mb-3' style={{minHeight: 'calc(100vh - 285px)'}}>
                    <div className="h-100">
                        {renderMobileContent()}
                    </div>
                </div>
            </div>

        );
    };


    const renderUI = () => {
        if (isMobileView && !isMDView) {
            return renderMobileView();
        } else {
            return renderDesktopView();
        }
    };

    if (UIState === stocksConst.CONTENT_UNAVAILABLE) {
        return
    }

    return (
        <>
            <div className="bg-white ">
                <div className='bg-white ' style={{
                    position: 'sticky',
                    top: 0,
                    // boxShadow: " 0px 1px 1px 0px rgba(0, 0, 0, 0.20)",
                    zIndex: 999
                }}>

                    <div className='px-3 py-2 w-100 d-flex justify-content-between'>
                        <div className={'d-flex'}>
                            <i className="fa-solid fa-chevron-left cursor pe-2"
                               style={{marginTop: 8}}
                               onClick={() => props.history.goBack()}/>
                            <div className=''>
                                <DLText marginBottom={'none'}
                                        id={""}
                                        isInline={true}
                                        text={"Compare Stocks"}
                                        fontWeight={'semi-bold'}
                                        fontSize={'lg'}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div id={'myDiv'}
                     style={{
                         overflowY: 'auto',
                         scrollBehavior: 'smooth'
                     }}>
                    <div className='h-100'>
                        {renderUI()}
                    </div>
                    {renderAdvanceSearchModal()}
                </div>
            </div>
        </>
    );
}

CompareStocksView.propTypes = {};

export default CompareStocksView;
