import React, {useEffect, useState} from 'react';
import AdvanceFundListingModalView from "./AdvanceFundListingModalView";
import {connect} from "react-redux";
import {getFundSummary, setFundHouseList, setFundList} from "../../fund/FundAction";
import {advanceSearchConst} from "../../AdvanceSearch/AdvanceSearchConst";
import store from "../../store";
import {setAllFundsData, setFunds} from "../../fund/FundReducer";
import {setKeywordFilter} from "../../stocks/StocksActions";
import {setAdvanceKeywordFilter, setFundsKeywordFilter} from "../../AdvanceSearch/AdvanceSeachAction";
import {isEmpty} from "../../utils/validations";
import {getChartData, searchOnWordBoundary} from "../../utils/helper";
import {getListingData} from "../../utils/stocksHelper";
const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function AdvanceFundListingModal(props) {

    const{isExternalFundSelected, externalFundData, setFilter, fundListingData, isFundModalOpen, externalFundStocksKeyword} = props;
    const [advanceFundListingModalState, setAdvanceFundListingModalState] = useState({
        modalUIState: '',
        sortedData: [],
        previousFundListing: [],
        skipCount: 0,
        limitCount : 60
    });

    useEffect(()=>{
        const {skipCount, limitCount} = advanceFundListingModalState;
        if (isFundModalOpen || isExternalFundSelected) {
            if (!isEmpty(props.fundListingData)) {
                handleListingOptions()
                setAdvanceFundListingModalState((prev) => ({
                    ...prev,
                    modalUIState: advanceSearchConst.CONTENT_AVAILABLE,
                    previousFundListing: props.fundListingData
                }));
            }else {
                getFundSummary(VALUATION_DATE, skipCount, limitCount).then((res) => {
                    if (res.success && !isEmpty(res.data?.data)) {
                        let data = getListingData(res.data.data, 'fund_name');
                        //store.dispatch(setFunds(data));

                        // props.onChangeValue('fundListingData', data);
                        props.setAllFundsData(data)
                        data = handleExternalCheckChange(externalFundData, data)
                        setFundList(data);
                        applyExternalFilter(data)

                        setAdvanceFundListingModalState((prev)=>({
                            ...prev,
                             modalUIState: advanceSearchConst.CONTENT_AVAILABLE
                        }));

                    }else {
                        setAdvanceFundListingModalState((prev)=>({
                            ...prev,
                            modalUIState: advanceSearchConst.CONTENT_UNAVAILABLE
                        }));
                    }
                })
            }
        }
    },[isFundModalOpen, isExternalFundSelected]);

    useEffect(()=>{
        const {fundsKeyword, isMobileView, isXSView} = props;
        const {fundListingData} = props;

        if (isEmpty(fundListingData)){
            return
        }

        if (isEmpty(fundsKeyword)){
            setFundList(fundListingData);

            // props.onChangeValue('fundListingData', fundListingData);

            setAdvanceFundListingModalState((prev)=>({
                ...prev,
                sortedData: [],
                searchResultNotAvailable: false
            }));
        }else {
            let sortedData = [];
            setAdvanceFundListingModalState((prev)=>({
                ...prev,
                isSearchApply: true
            }));

            let options =  fundListingData.map((item)=>{
                return {...item, label:item.fund_name, value: item.fund_name}
            });

            sortedData = searchOnWordBoundary(props.fundsKeyword, JSON.parse(JSON.stringify(options)));

            if (!isEmpty(sortedData)){

                setAdvanceFundListingModalState((prev)=>({
                    ...prev,
                    sortedData: sortedData,
                    searchResultNotAvailable: false
                }));
            }else {
                setAdvanceFundListingModalState((prev)=>({
                    ...prev,
                    sortedData: [],
                    searchResultNotAvailable: true
                }));
            }

            setTimeout(()=>{
                setAdvanceFundListingModalState((prev)=>({
                    ...prev,
                    isSearchApply: false
                }))
            },300)

        }

    },[props.fundsKeyword]);

    const handleListingOptions = () => {
        const {fundHouses} = props.advanceSearchFilter;
        const {allFundsData} = props;
        let selectedValues = checkSelectedValues();

        let filteredFunds = [];
        if (!isEmpty(fundHouses)) {
            fundHouses.forEach((item) => {
                allFundsData.forEach((obj) => {
                    if (item === obj?.fund_house_id) {
                        filteredFunds.push(obj)
                    }
                })
            })
        }else{
            filteredFunds = allFundsData
        }
        let addedArray = [...filteredFunds, ...selectedValues]

        selectedValues.forEach((item) => {
            addedArray.forEach((obj, index) => {
                if ((item?.fund_id === obj?.fund_id) && (item?.fund_house_id === obj?.fund_house_id) && (!obj?.isSelected)) {
                    addedArray.splice(index,1)
                }
            })
        })
        setFundList(addedArray);
        applyExternalFilter(addedArray)
    };

    const handleExternalCheckChange = (value, data) => {
        const {fundHouses} = props.advanceSearchFilter;
        if (isEmpty(value)) {
            if (!isEmpty(fundHouses)) {
                let filteredFunds = [];
                fundHouses.forEach((item) => {
                    data.forEach((obj) => {
                        if (item === obj.fund_house_id) {
                            filteredFunds.push(obj)
                        }
                    })
                })

                return filteredFunds;
            } else {
                return data
            }
        }
        if(!isEmpty(externalFundStocksKeyword)){
            setAdvanceKeywordFilter(externalFundStocksKeyword)
        }
        const updatedArray = data.map(item => {
            if (item.fund_house_id === value.fund_house_id && item.fund_id === value.fund_id) {
                return { ...item, isSelected: !item.isSelected };
            }

            // Return the original object if the id doesn't match
            return item;
        });
        return updatedArray
        //setFundList(updatedArray);
    };

    const applyExternalFilter =(data)=>{
        let selectedValues = checkExternalSelectedValues(data);
        setFilter('funds', selectedValues, 'fund_id')
        props.history.replace();
    }

    const handleCheckboxChange = (value) => {
        const {fundListingData} = props;

        const updatedArray = fundListingData.map(item => {
            if (item.fund_house_id === value.fund_house_id && item.fund_id === value.fund_id) {
                return { ...item, isSelected: !item.isSelected };
            }

            // Return the original object if the id doesn't match
            return item;
        });
        setFundList(updatedArray);

        // props.onChangeValue('fundListingData', updatedArray);

    };

    const clearSelectedFunds = (value) => {
        const {fundListingData} = props;

        const updatedArray = fundListingData.map(item => {
            return { ...item, isSelected: false };
        });
        setFundList(updatedArray);

        // props.onChangeValue('fundListingData', updatedArray);

    };

    const checkboxChange = (value) => {
        const {fundListingData} = props;
        // console.log('updatedArray[index]', !value)

        // Make a copy of the object at the specified index
        const updatedItem = fundListingData.find(item =>  (item.fund_house_id === value.fund_house_id && item.fund_id === value.fund_id));

        return updatedItem?.isSelected
    };

    const onChangeKeywordFilter = (fundsKeyword) => {
        setFundsKeywordFilter(fundsKeyword)
    };

    const checkExternalSelectedValues = (data) => {
        const {fundListingData} = props;
        return  data.filter((item)=>item.isSelected);
    };

    const checkSelectedValues = () => {
        const {fundListingData} = props;
        return  fundListingData.filter((item)=>item.isSelected);
    };

    return (
        <AdvanceFundListingModalView {...advanceFundListingModalState}
                                     {...props}
                                     handleCheckboxChange={handleCheckboxChange}
                                     checkboxChange={checkboxChange}
                                     checkSelectedValues={checkSelectedValues}
                                     clearSelectedFunds={clearSelectedFunds}
                                     onChangeKeywordFilter={onChangeKeywordFilter}/>
    )
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    fundsData: state.fundState.fundsData,
    fundsLoaded: state.fundState.fundsLoaded,
    fundsKeyword: state.AdvanceSearchState.fundsKeyword,
    allFundsData: state.fundState.allFundsData,
    fundListingData: state.fundState.fundListingData,
});

export default connect(mapStateToProps, {setAllFundsData})(AdvanceFundListingModal);