import { showSystemErrorBlock , showToastMessage} from "../core/actions/appActions";
import store from "../store";
import {httpGet} from "../utils/httpHelper";
import {resetCompareStocksOptions, setCompareStockOptions, setKeyword, resetOrder, resetOrderBy,
    resetPage, resetRowsPerPage, setOrder, setOrderBy, setPage, setRowsPerPage, resetTabValue, setTabValue} from "./stockReducer";
import {isEmpty} from "../utils/validations";

let base_url = '';
// let base_url = ''
export const getMonthlyActivitySummaryForFundHouses= (isin,date) => {
    let url = base_url+'/service/stocks/monthlyActivitySummaryForFundHouses?date='+date+'&isin='+isin;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};
export const getNetBuyingByFund= (isin,date) => {
    let url = base_url+'/service/stocks/netBuyingByFund?date='+date+'&isin='+isin;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getNetSellingByFund= () => {
    let url = base_url + '/service/stocks/netSellingByFund?date=2023-08-31&isin=INE040A01034' ;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getSummaryByFundHouse= (date,isin) => {
    let url = base_url+ '/service/stocks/fundHouseSummary?date='+date+'&isin='+isin ;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getNetActivityByFundHouse= (date,isin) => {
    let url = base_url+ '/service/stocks/netActivityByFundHouse?date='+date+'&isin='+isin ;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getNetActivityByFund= (date,isin) => {
    let url = base_url+'/service/stocks/netActivityByFund?date='+date+'&isin='+isin ;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};


export const getSummaryByFund= (date,isin) => {
    let url = base_url + '/service/stocks/fundSummary?date='+date+'&isin='+isin;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getAllStocks= () => {
    let url = base_url+ '/service/stocks/all' ;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getStocksSummary= (date, skipCount, limitCount) => {

    return httpGet('/service/home/stocksSummary?date='+date)
        .then(res=>{

            return res;

        })
};


export const getStockActivities= (date,isin) => {
    let url = base_url+ '/service/stocks/lastMonthSummary?date='+date+'&isin='+isin ;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};
export const getIndustryActivities= (date,industry) => {
    let url = base_url+ '/service/sector/lastMonthSummary?date='+date+'&industry='+industry ;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getStockTrend= (date,isin) => {
    let url = base_url+ '/service/stocks/monthlyTrend?date='+date+'&isin='+isin ;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getStockDetails= (isin) => {
    let url = base_url+ '/service/stocks/details?isin='+isin ;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getStockTrendForFundHouse= (date,fundHouseId) => {
    let url = base_url+ '/service/fundHouse/monthlyTrend?date='+date+'&fundHouseId='+fundHouseId ;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};
export const getStockTrendForIndustry= (date,industry) => {
    let url = base_url+ '/service/sector/monthlyTrend?date='+date+'&industry='+industry ;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getStockTrendForFund= (date,fundHouseId, fundId) => {
    let url = base_url+ '/service/fund/monthlyTrend?date='+date+'&fundHouseId='+fundHouseId+'&fundId='+fundId;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};
export const getSceenrCode= (date,isin) => {
    let url = base_url+ '/service/stocks/screenerData?isin='+isin;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getNetBuyingSellingPopUpData= (date,isin) => {
    let url = base_url+ '/service/stocks/lastMonthSummary?date='+date+'&isin='+isin;

    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const setKeywordFilter = (keyword) =>{
    store.dispatch(setKeyword(keyword))
};

export const setCompareStock = (keyword) =>{
    store.dispatch(setCompareStockOptions(keyword))
};

export const resetCompareStock = (keyword) =>{
    store.dispatch(resetCompareStocksOptions(keyword))
};

export const setStockTableByOrder = (data) =>{
    store.dispatch(setOrder(data))
};

export const setStockTableByOrderBy = (data) =>{
    store.dispatch(setOrderBy(data))
};

export const setStockPage = (data) =>{
    store.dispatch(setPage(data))
};

export const setStockRowsPerPage = (data) =>{
    store.dispatch(setRowsPerPage(data))
};

export const setStockTabValue = (data) =>{
    store.dispatch(setTabValue(data))
};

export const resetStockTabValue = (data) =>{
    store.dispatch(resetTabValue(data))
};

export const resetStockPage = (data) =>{
    store.dispatch(resetPage(data))
};

export const resetStockRowsPerPage = (data) =>{
    store.dispatch(resetRowsPerPage(data))
};

export const resetStockTableByOrder = (name, value) =>{
    store.dispatch(resetOrder({name, value}))
};

export const resetStockTableByOrderBy = (name, value) =>{
    store.dispatch(resetOrderBy({name, value}))
};