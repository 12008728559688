import React, {useEffect, useState} from 'react';
import withRouter from "react-router-dom/es/withRouter";
import {searchAllData} from "../core/actions/appActions";
import {connect} from "react-redux";
import {isEmpty} from "../utils/validations";
import SearchUiViewView from "./SearchUiView";
import {debounce} from "../utils/helper";

function SearchUi(props) {
    const {onCloseModal, isMobileView, isMDView} = props;

    const [searchUiState, setSearchUiState] = useState({
        searchOptions: [],
        searchOptionsFound: false,
        isSearchInProgress: false,
        isKeywordPresent: false,
        keyword: '',

        stocks: [],
        fundHouses: [],
        funds: [],
        industry: [],

        activeTab: "all",

        smallView: false
    });

    useEffect(()=>{
        setSearchUiState((prevState) => ({
            ...prevState,
            smallView: checkSmallView()
        }));
    },[isMobileView,isMDView])

    const checkSmallView = () => {

        let smallView = false
        if (isMobileView && !isMDView) {
            smallView = true;
        }
        return smallView;
    }

    const searchData = (keyword) => {

        if (isEmpty(keyword)) {
            setSearchUiState((prevState) => ({
                ...prevState,
                searchOptions: [],
                searchOptionsFound: false,
            }));
            return
        }

        setSearchUiState((prevState) => ({
            ...prevState,
            isSearchInProgress: true
        }));
        keyword = encodeURIComponent(keyword)

        searchAllData(keyword).then((res) => {
            if (res.success) {
                setSearchUiState((prevState) => ({
                    ...prevState,
                    searchOptions: getSortedData(res.data.data),
                    searchOptionsFound: true,
                    isSearchInProgress: false
                }))
            } else {
                setSearchUiState((prevState) => ({
                    ...prevState,
                    searchOptions: [],
                    isSearchInProgress: false,
                    searchOptionsFound: true,

                }))
            }
        })
    };

    const getSortedData = (searchData) => {
        let fundHouses = [];
        let funds = [];
        let stocks = [];
        let industry = [];

        if (!isEmpty(searchData)) {
            searchData.forEach((item) => {
                if (item?.type === "Fund") {
                    funds.push(item)
                } else if (item?.type === "Industry") {
                    industry.push(item)
                } else if (item?.type === "Stock") {
                    stocks.push(item)
                } else if (item?.type === "Fund House") {
                    fundHouses.push(item)
                }
            })
        }

        setSearchUiState((prevState) => ({
            ...prevState,
            funds: funds,
            fundHouses: fundHouses,
            industry: industry,
            stocks: stocks

        }))
        return searchData;
    }

    const onChangeValue = (value) => {
        if (isEmpty(value)) {
            setSearchUiState((prevState) => ({
                ...prevState,
                isKeywordPresent: false,
                keyword: value
            }));
        } else {
            setSearchUiState((prevState) => ({
                ...prevState,
                isKeywordPresent: true,
                keyword: value
            }));
        }
        onChange(value);
    };

    const onChange = debounce((value) => {
        searchData(value);
    }, 100);

    const changeTab = (tab) => {
        setSearchUiState((prevState) => ({
            ...prevState,
            activeTab: tab

        }))
    }

    return (
        <SearchUiViewView
            {...props}
            {...searchUiState}
            isDeleteDialogOpen={false}
            searchData={searchData}
            onChangeValue={onChangeValue}
            getSortedData={getSortedData}
            changeTab={changeTab}

        />
    );
}

const mapStateToProps = (state) => ({
    userAuthStatus: state.userState.userAuthStatus,
    profile: state.userState.profile,
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMDView: state.appState.deviceInfo.isMDView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    isDrawerOpen: state.appState.isDrawerOpen,
    currentPathname: state.appState.currentPathname,
    prevLocation: state.appState.prevLocation
});


export default connect(mapStateToProps, {})(withRouter(SearchUi))