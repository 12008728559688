import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import {visuallyHidden} from '@mui/utils';
import {
    getFixedValuesAfterDecimal, getTrimVal, numberWithCommas,
} from ".././utils/helper";
import {filterAumValue} from "../utils/stocksHelper";
import {connect} from "react-redux";
import {resetStockHoldingPage, resetStockHoldingRowsPerPage, resetStockHoldingTableByOrder, resetStockHoldingTableByOrderBy, setStockHoldingPage, setStockHoldingRowsPerPage, setStockHoldingTableByOrder, setStockHoldingTableByOrderBy} from "./StockAction";
import {useEffect} from "react";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)



function EnhancedTableHead(props) {
    const {headingData, order, orderBy, onRequestSort, isMobileView, isXSView} = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headingData.map((heading, index) => (
                    <TableCell
                        key={heading.id}
                        sx={{width: 10,  minWidth: index === 0 ? isXSView ? 200 : 250 : 'max-content'}}
                        align={heading.numeric ? 'right' : 'left'}
                        padding={'normal'}
                        style={{position:index === 0 ? 'sticky' : '', left:0, zIndex:index === 0 ? 101 : 100}}
                        sortDirection={orderBy === heading.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === heading.id}
                            direction={orderBy === heading.id ? order : 'asc'}
                            style={{paddingTop: 10}}
                            onClick={createSortHandler(heading.id)}>

                            <div className='flex-nowrap text-nowrap'
                                 style={{width:'max-content', textTransform: 'capitalize', paddingLeft:(index === 0)? 0 : 0, paddingRight: (index ===  headingData.length - 1) ? 0 : 0 }}>
                                {heading.label}
                            </div>
                            {orderBy === heading.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}

                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    headingData: PropTypes.array.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const extractColumnHeadings = (rowData) => {
    let headingData = [];
    let arr = [];
    Object.keys(rowData).forEach(function (key, index) {
        if (key === 'label' || key === 'value'){

        }else {
            let numeric = true;
            if (isNaN(rowData[key])) {
                numeric = false
            }
            if(key === 'company_name'){
              numeric = false
            }
            else{
                numeric = true
            }
             let head = {
                    id: key,
                    label: rowData[key],
                    numeric: numeric
                };
            headingData.push(head);

        }
    });
    return headingData;
};



const StockTable = (props) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const {searchResult} = props;
    let headingData = extractColumnHeadings(searchResult[0]);
    let rows = searchResult;

    useEffect(()=>{
        if (props.history.action === "POP" || props.history.action === "REPLACE"){
            //backward navigation
            const {stockHoldingSearchOrder, stockHoldingSearchOrderBy, stockHoldingSearchPage, stockHoldingSearchRowsPerPage} = props;
            setOrder(stockHoldingSearchOrder);
            setOrderBy(stockHoldingSearchOrderBy);
            setPage(stockHoldingSearchPage);
            setRowsPerPage(stockHoldingSearchRowsPerPage);
        }else {
            // resetListingData()
            resetStockHoldingTableByOrder();
            resetStockHoldingTableByOrderBy();
            resetStockHoldingPage();
            resetStockHoldingRowsPerPage();
        }
    },[]);


    function stableSort(rows) {
        let sortedArr = rows.slice(1).sort((a, b) => {
            let avalue, bvalue;
            if (orderBy === "company_name") {
                avalue = a.company_name.toLowerCase();
                bvalue = b.company_name.toLowerCase();
            } else { // Sorting by 'name'
                avalue = a.data.find(item => item.date === orderBy)?.value;
                bvalue = b.data.find(item => item.date === orderBy)?.value;
                if(!avalue){
                    avalue = 0
                }
                if(!bvalue){
                    bvalue = 0
                }
            }
            if (order === "asc") {
                if (typeof avalue === "string") {
                    return avalue.localeCompare(bvalue);
                }
                return avalue - bvalue;
            } else {
                if (typeof bvalue === "string") {
                    return bvalue.localeCompare(avalue);
                }
                return bvalue - avalue;
            }
        });
        return sortedArr;
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        const sortOrder = isAsc ? 'desc' : 'asc';
        setOrder(sortOrder);
        setOrderBy(property);
        setStockHoldingTableByOrder(sortOrder);
        setStockHoldingTableByOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setStockHoldingPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setStockHoldingPage(0);
        setStockHoldingRowsPerPage(parseInt(event.target.value, 10));
    };


    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    const displayValue = (cellValue) => {
        if( drillDownBy === "HOLDING"){
             return getFixedValuesAfterDecimal(cellValue , 2,true)+"%"
        }
        if( drillDownBy === "AUM"){
            return numberWithCommas(filterAumValue(cellValue , 2));
        }
        return cellValue;
    }

    const {isXLView, isXSView, tableHeader, drillDownBy ,  isLGView, isMobileView} = props;

    return (
        <div className="w-100 pagination-container" style={{paddingLeft:isXSView ? '' :  24, paddingRight: isXSView ? '' : 24}}>
            <Box sx={{width: '100%'}}>
                <Paper sx={{width: '100%', mb: 0, boxShadow: "none"}}>
                    <TableContainer style={{maxHeight: 360}}>
                        <Table className=""
                               sx={{minWidth: 750}}
                               aria-labelledby="tableTitle"
                               size={dense ? 'small' : 'medium'}
                               stickyHeader>
                            <EnhancedTableHead
                                headingData={headingData}
                                order={order}
                                tableHeader={tableHeader}
                                orderBy={orderBy}
                                isXLView={isXLView}
                                isMobileView={props.isMobileView}
                                isXSView={props.isXSView}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(row.name);

                                    return (
                                        <TableRow
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.isin}
                                            selected={isItemSelected}
                                            sx={{cursor: 'pointer', border:'none', backgroundColor: (index % 2) === 0 ? '#F8F8FC': 'white'}}>

                                            <TableCell align="left" sx={{ border:'none', width: isXSView ? '150px' : '', fontSize: '12px !important', color: '#0073ff'}} style={{position: 'sticky', left:0, backgroundColor:(index % 2) === 0 ? '#F8F8FC': 'white'}}>
                                                <a href={'/stocks/' + row.isin} target="_self" rel="noopener noreferrer" onClick={()=>{}}>
                                                    {getTrimVal(row.company_name, isLGView, isXLView, isMobileView, isXSView)}
                                                </a>
                                            </TableCell>

                                            {headingData.slice(1).map(header => {
                                                const cellValue = row.data.find(d => d.date  === header.label)?.value || 0;
                                                return <TableCell align='right' sx={{ border:'none', fontSize: '12px !important'}} key={header.id}>{displayValue(cellValue)}</TableCell>;
                                            })}

                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows,
                                        }}>
                                        <TableCell colSpan={6}/>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={rows.length-1}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        </div>
    );
};

const mapStateToProps = (state) => ({
    stockHoldingSearchOrder: state.StockHoldingReducer.order,
    stockHoldingSearchOrderBy: state.StockHoldingReducer.orderBy,
    stockHoldingSearchPage: state.StockHoldingReducer.page,
    stockHoldingSearchRowsPerPage: state.StockHoldingReducer.rowsPerPage
});

export default connect(mapStateToProps, {})(StockTable);