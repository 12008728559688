import {useEffect, useState} from "react";
import SummaryCard from "../../component-utils/SummaryCard/SummaryCard";
import {getFundSummaryData} from "../FundAction";
import {isEmpty} from "../../utils/validations";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function SummaryCardFundHouse(props) {

    const {fundHouseId, fundId} = props;

    const [fundSummary, setFundSummary] = useState(
        {
            stocksIncreasingExposureSummary: {marketValue: 0, count: 0},
            stocksDecreasingExposureSummary: {marketValue: 0, count: 0},
            netFundSummary: {marketValue: 0, count: 0},
            totalAUM:0,
            totalAUMChange:0,
            count:0
        }
    );


    useEffect(() => {

        getFundSummaryData(VALUATION_DATE,fundHouseId, encodeURIComponent(fundId)).then((res) => {
            if (res.success && !isEmpty(res.data?.data)) {
                setIndustryData(res.data.data);
            }
        })

    }, []);



    const setIndustryData = (data) => {


        let numberOfStocksIncreasingValue = 0;
        let marketValueOfStocksIncreasingValue = 0;
        let numberOfStocksDecreasingValue = 0;
        let marketValueStocksDecreasingValue = 0;


        let totalAUM = 0;
        let totalAUMChange = 0;


        for (let i = 0; i < data.length; i++) {
            let marketValue = data[i].market_value_change_mom;
            totalAUM += data[i].total_market_value;
            totalAUMChange += data[i].market_value_change;

            if (marketValue > 0) {
                numberOfStocksIncreasingValue++;
                marketValueOfStocksIncreasingValue += marketValue;
            } else {
                numberOfStocksDecreasingValue++;
                marketValueStocksDecreasingValue += marketValue;
            }

        }


        setFundSummary({
            stocksIncreasingExposureSummary: {
                marketValue: marketValueOfStocksIncreasingValue.toFixed(2),
                count: numberOfStocksIncreasingValue
            },
            stocksDecreasingExposureSummary: {
                marketValue: marketValueStocksDecreasingValue.toFixed(2),
                count: numberOfStocksDecreasingValue
            },
            netFundSummary: {
                marketValue: (marketValueOfStocksIncreasingValue + marketValueStocksDecreasingValue).toFixed(2),
                count: numberOfStocksIncreasingValue + numberOfStocksDecreasingValue
            },
            totalAUM: totalAUM.toFixed(2),
            totalAUMChange : totalAUMChange.toFixed(2),
            count: numberOfStocksIncreasingValue + numberOfStocksDecreasingValue
        });

    };


    return (
        <div>
            <div className="row m-3">
                <div className='col-3'>
                    <SummaryCard primaryValue={'Rs.' + fundSummary.totalAUM}
                                 primaryValueTitle={'Total AUM'}
                                 secondaryValue={'MoM Change Rs.'+ fundSummary.totalAUMChange}
                                 secondaryValueTitle={'('+(fundSummary.totalAUMChange/fundSummary.totalAUM * 100).toFixed(2)+'%)'}
                    />
                </div>
                <div className='col-3'>
                    <SummaryCard primaryValue={fundSummary.count}
                                 primaryValueTitle={'Number of Stocks'}
                    />
                </div>
            </div>
            <div className="row m-3">
                <div className='col-3'>
                    <SummaryCard primaryValue={'Rs.' + fundSummary.stocksIncreasingExposureSummary.marketValue}
                                 primaryValueTitle={'Stocks Increasing Exposure'}
                                 secondaryValue={fundSummary.stocksIncreasingExposureSummary.count}
                                 secondaryValueTitle={'Stocks'}/>
                </div>
                <div className='col-3'>
                    <SummaryCard primaryValue={'Rs.' + fundSummary.stocksDecreasingExposureSummary.marketValue}
                                 primaryValueTitle={'Stocks Decreasing Exposure'}
                                 secondaryValue={fundSummary.stocksDecreasingExposureSummary.count}
                                 secondaryValueTitle={'Stocks'}/>
                </div>
                <div className='col-3'>
                    <SummaryCard primaryValue={'Rs.' + fundSummary.netFundSummary.marketValue}
                                 primaryValueTitle={'Net Stocks Exposure'}
                                 secondaryValue={fundSummary.netFundSummary.count}
                                 secondaryValueTitle={'Stocks'}/>
                </div>
            </div>
        </div>
    )
}

export default SummaryCardFundHouse;