import {isEmpty} from "./validations";
import {getFixedValuesAfterDecimal} from "./helper";

export const getColor = (index) =>{
    switch (index) {
        case 0: return '#5470c6';
        case 1: return '#91cc75';
        case 2: return '#fac858';
        case 3: return '#ee6666';
        case 4: return '#73c0de';
        case 5: return '#3ba272';
        case 6: return '#fc8452';
        case 7: return '#9a60b4';
        case 8: return '#ea7ccc';
        case 9: return '#CC7575';
        default :
            return '#5470c6'
    }
};

export const getFundsTrimVal = (name, isLGView, isXLView, isMobileView, isXSView) => {

    let value = name;
    if (isEmpty(name)) {
        return ''
    }

    if (isXSView){
        if (name.length > 25) {
            value = name.slice(0, 25) + '...'
        }
        return value
    }

    if (isMobileView){
        if (name.length > 35) {
            value = name.slice(0, 35) + '...'
        }
        return value
    }

    if (name.length > 70) {
        value = name.slice(0, 70) + '...'
    }

    return value
};


const getBaseLog = (x, y) => {
    return Math.log(y) / Math.log(x);
};

export const getRoundedStepSize = (value) =>{
    if (isEmpty(value)) {
        return
    }
    let log = getBaseLog(10, value);
    let stepSize = value;
    log = Math.floor(log);

    if (log === 5) {
        stepSize = ((stepSize / 100000).toFixed(0)) * 100000
    } else if (log === 4) {
        stepSize = ((stepSize / 10000).toFixed(0)) * 10000
    } else if (log === 3) {
        stepSize = ((stepSize / 1000).toFixed(0)) * 1000
    } else if (log === 2) {
        stepSize = ((stepSize / 100).toFixed(0)) * 100
    } else if (log === 2) {
        stepSize = ((stepSize / 100).toFixed(0)) * 100
    } else if (log === 1) {
        stepSize = ((stepSize / 10).toFixed(0)) * 10
    }

    return stepSize;

};

export const getRoundedStepSizeForLineChart = (value) =>{
    if (isEmpty(value)) {
        return
    }
    let log = getBaseLog(10, value);
    let stepSize = value;
    log = Math.floor(log);
    if (log === 6) {
        stepSize = (Math.ceil(stepSize / 1000000).toFixed(0)) * 1000000
    }else if (log === 5) {
        stepSize = (Math.ceil(stepSize / 100000).toFixed(0)) * 100000
    } else if (log === 4) {
        stepSize = (Math.ceil(stepSize / 10000).toFixed(0)) * 10000
    } else if (log === 3) {
        stepSize = (Math.ceil(stepSize / 1000).toFixed(0)) * 1000
    } else if (log === 2) {
        stepSize = (Math.ceil(stepSize / 100).toFixed(0)) * 100
    } else if (log === 2) {
        stepSize = (Math.ceil(stepSize / 100).toFixed(0)) * 100
    } else if (log === 1) {
        stepSize = (Math.ceil(stepSize / 10).toFixed(0)) * 10
    }

    return stepSize;

};

export const getMinRoundedNumber = (value) => {
    if (isEmpty(value)) {
        return
    }
    let log = getBaseLog(10, value);
    let minValue = value;
    log = Math.floor(log);

    if (log === 6) {
        minValue = Math.floor(value / 10000) * 10000;
        minValue = Math.round(minValue / 100000) * 100000;
    }else if (log === 5) {
        minValue = Math.floor(value / 1000) * 1000;
        minValue = Math.round(minValue / 10000) * 10000;
    } else if (log === 4) {
        minValue = Math.floor(value / 100) * 100;
        minValue = Math.round(minValue / 1000) * 1000;
    } else if (log === 3) {
        minValue = Math.floor(value / 10) * 10;
        minValue = Math.round(minValue / 100) * 100;
    } else if (log === 2 || log === 1) {
        minValue = Math.round(minValue / 10) * 10;
    }

    return minValue
};

export const getMaxRoundedNumber = (value) => {
    if (isEmpty(value)) {
        return
    }
    let log = getBaseLog(10, value);
    let maxValue = value;
    log = Math.floor(log);


    if (log === 6) {
        maxValue = (Math.ceil(value / 100000) * 100000) + 100000;
    }else if (log === 5) {
        maxValue = (Math.ceil(value / 10000) * 10000) + 10000;
    } else if (log === 4) {
        maxValue = (Math.ceil(value / 1000) * 1000) + 1000;
    } else if (log === 3) {
        maxValue = (Math.ceil(value / 100) * 100) + 100
    }else if (log === 2) {
        maxValue = (Math.ceil(value / 10) * 10) + 10
    }else if (log === 1) {
        maxValue = (Math.ceil(value / 1) * 1) + 1
    }

    return maxValue
};


export const getListingData = (lisitingData, sortingValues) =>{
    // let heading = lisitingData[0];
    let listing = lisitingData.slice(1, lisitingData.length);

    listing.sort((a, b) => {
        const nameA = a[sortingValues].toLowerCase();
        const nameB = b[sortingValues].toLowerCase();

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    // listing.unshift(heading);
    return listing.map((item)=>{
        return {...item, isSelected: false}
    })
};


export const getMarketValue = (value) =>{
    if (value <= 100){
        if (value === 0){
            return 0
        }
        return  value.toFixed(2)
    }
    return Math.trunc(value)
};

export const getShowingResultCountText = (data, text) =>{
    if (isEmpty(data)){
        return ''
    }

    if (data.length > 30){
        return 'Showing first 30 records'
    }

    if (data.length === 1){
        return 'Showing 1 record'
    }
    return 'Showing ' + data.length + ' records'
}

export const getShowingResultCountTextForFundModal = (data, text) =>{
    if (isEmpty(data)){
        return ''
    }

    if (data.length > 20){
        return 'Showing first 20 records'
    }


    if (data.length === 1){
        return 'Showing 1 record'
    }
    return 'Showing ' + data.length + ' records'
}

export const getMinForBar = (axisValue) => {

    let numericNumbers = axisValue.map(Number);

    let minValue = Math.min(...numericNumbers);
    let maxValue = Math.max(...numericNumbers);

    if (minValue >= 0) {
        return 0
    }

    if (minValue <= 0 && maxValue >= 0) {
        let min = Math.max(maxValue, Math.abs(minValue));
        return '-' + Math.trunc(min)
    }
    return Math.trunc(minValue)
};

export const getMaxForBar = (axisValue) => {

    let numericNumbers = axisValue.map(Number);
    let minValue = Math.min(...numericNumbers);
    let maxValue = Math.max(...numericNumbers);
    if ((maxValue < 1 && maxValue > -1) && (minValue < 1 && minValue > -1)) {
        return 4
    }

    if (maxValue <= 0) {
        return 0
    }

    if (minValue <= 0 && maxValue >= 0) {
        let max = Math.max(maxValue, Math.abs(minValue));
        return Math.trunc(max)
    }
    return Math.trunc(maxValue)
};


export const filterAumValue = (value) =>{
    if (isEmpty(value)){
        return
    }
    if (value >=100 ){
       return  getFixedValuesAfterDecimal(value,0)
    }
    return getFixedValuesAfterDecimal(value,2)
};