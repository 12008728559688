import React from 'react';
import AppDrawerView from "./AppDrawerView";

const AppDrawer = (props) => {
  return (
      <AppDrawerView
          {...props}
      />
  );
}

export default AppDrawer

