import DLLoader from "../core/components/DLLoader/DLLoader";
import DLText from "dlcomponent/components/DLText/DLText";
import DLInputTextField from 'dlcomponent/components/DLInputTextField/DLInputTextField'
import MoneyFlowExplorerView from "../moneyFIleExplorer/MoneyFlowExplorerView";
import ExplorerChipsView from "../explorer/ExplorerChipsView";
import {isEmpty} from "../utils/validations";
import StockTable from "./StockTable";
import ExplorerFundHouseListingModal from "../explorer/explorerModal/ExplorerFundHouseListingModal";
import ExplorerFundListingModal from "../explorer/explorerModal/ExplorerFundListingModal";
import ExplorerIndustrySearchModal from "../explorer/explorerModal/ExplorerIndustrySearchModal";
import ExplorerStocksSearchModal from "../explorer/explorerModal/ExplorerStocksSearchModal";
import ExplorerMarketCap from "../explorer/explorerModal/ExplorerMarketCap";
import ExplorerBusinessHouseSearchModal from "../explorer/explorerModal/ExplorerBusinessHouseSearchModal";
import appTheme from "../assets/appTheme";
import MoneyFlowExplorerActionModal from "../moneyFIleExplorer/moneyFlowExplorerModals/MoneyFlowExplorerActionModal";
import React from "react";
import DLButton from "dlcomponent/components/DLButton/DLButton";
import {downloadDataByObject} from "../fund/FundAction";


function StockHoldingView(props) {
    const {isMobileView, drillDownBy, searchResult, onChangeKeywordFilter, isXSView, isSMView, resetListingData, explorerFilter, keyword, storedDataForDownload, getFileName, isXLView} = props;

    const notAllValuesEmpty = () => {
        return isEmpty(explorerFilter.fundHouses) && isEmpty(explorerFilter.businessHouses) && isEmpty(explorerFilter.funds)
            && isEmpty(explorerFilter.marketCaps) && isEmpty(explorerFilter.industries) && isEmpty(explorerFilter.stocks) && drillDownBy === 'HOLDING' && isEmpty(keyword);
    };

    const renderClearAll = () => {

        if (notAllValuesEmpty()) {
            return
        }
        if (isXSView || isSMView) {
            return (
                <div>
                    <i className="fa fa-times cursor ms-3"
                       style={{paddingTop: 6}}
                       onClick={() => resetListingData()}
                    />
                </div>
            )
        } else {
            return (
                <div className='ps-3' style={{minWidth: 'max-content'}}>
                    <DLText
                        id={'clear'}
                        marginBottom={'none'}
                        text={'Clear All'}
                        customTextStyle={{
                            color: appTheme.appColor.black
                        }}
                        isClickable={true}
                        onClick={() => resetListingData()}
                        fontSize={'sm'}/>
                </div>
            )
        }
    }

    const renderHeader = () => {
        return (
            <div className={'w-100 px-3 py-2 bg-white d-flex justify-content-between'}>
                <DLText marginBottom={'none'}
                        text={'Stock Holding'}
                        fontWeight={'semi-bold'}
                        fontSize={'lg'}/>
                {((isXSView || isSMView) && !notAllValuesEmpty(explorerFilter)) &&
                <i className="fa fa-times cursor ms-3"
                   style={{paddingTop: 6}}
                   onClick={() => resetListingData()}
                />
                }
            </div>
        )
    };

    const renderChipUI = () => {
        if (isXSView || isSMView) {
            return (
                <div className='ulLine w-100 pt-2' style={{overflowX: 'auto'}}>
                    <ExplorerChipsView {...props} externalHeight={'32px'}/>
                </div>
            )
        }
        return (
            <div className='d-flex justify-content-between align-items-center'>
                <div className=''>
                    <ExplorerChipsView {...props} externalHeight={'32px'}/>
                </div>
            </div>
        )
    };


    const renderFilterUI = () => {
        const {isMDView, keyword, isXSView, isSMView} = props;
        return (
            <div className={'d-flex justify-content-between'}>
                <div
                    className='d-flex flex-sm-row flex-column justify-content-sm-between align-items-center mt-1 w-100 me-3'
                    style={{marginBottom: isXSView ? 8 : 14}}>
                    <div style={{width: isXSView ? '100%' : '50%'}}>
                        <DLInputTextField
                            id={'businessList-keywordSearch'}
                            label={''}
                            rows={1}
                            fullWidth
                            styleType={"search"}
                            placeholder={'Search...'}
                            value={keyword}
                            onChangeValue={(e) => onChangeKeywordFilter(e)}
                            isClearable={true}
                            inputHeight={'sm'}
                            marginBottom={"none"}
                            InputProps={{
                                endAdornment: (
                                    keyword ?
                                        <i className="fa fa-remove m-2 cursor" onClick={(e) => {
                                            onChangeKeywordFilter('')
                                        }} style={{color: appTheme.appColor.black}}/>
                                        :
                                        <i className={'fas fa-search pr-1'} style={{color: appTheme.appColor.black}}/>)
                            }}
                            debounceTimer={400}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {

                                    document?.activeElement?.blur()
                                }
                            }}
                            disableHelperMessageHeight={true}
                        />
                    </div>
                    <div style={{width: isXSView ? '100%' : '50%'}}>
                        {(!isXSView && !isSMView && !notAllValuesEmpty(explorerFilter)) &&
                        <div className='d-flex justify-content-end w-100 mt-3 mt-sm-0'>
                            <DLText
                                id={'clear'}
                                marginBottom={'none'}
                                text={'Clear All'}
                                customTextStyle={{
                                    color: appTheme.appColor.black
                                }}
                                isClickable={true}
                                onClick={() => resetListingData()}
                                fontSize={'sm'}/>
                        </div>
                        }
                    </div>

                </div>
                {!isMobileView && !notAllValuesEmpty(explorerFilter) && !isEmpty(searchResult) && <div className='ps-3 mt-1' style={{
                    width: 36,
                    height: 36,
                    minWidth: 'max-content',
                    marginBottom: isXSView ? 8 : 14
                }}>
                    {/*<DLButton*/}
                    {/*    id={'download-excel'}*/}
                    {/*    styleType={"outlined"}*/}
                    {/*    borderType={'square'} buttonSize={'sm'}*/}
                    {/*    sideMargin={"none"}*/}
                    {/*    reduceWidth={true}*/}
                    {/*    style={{width: 36}}*/}
                    {/*    type={'primary'}*/}
                    {/*    onClick={() => {*/}
                    {/*          downloadDataByObject('/service/stocks/getMonthwiseHoldings/download', "",'Download', getFileName("Stock-Holding-"),storedDataForDownload)*/}
                    {/*    }}*/}
                    {/*    label={<i className='fas fa-download fs-6'/>}*/}
                    {/*/>*/}
                    <div className='ms-3 d-flex align-items-center justify-content-center downloadButtonCss cursor'>
                        <i className='fas fa-download fs-6'
                           onClick={() => {
                               downloadDataByObject('/service/stocks/getMonthwiseHoldings/download', "",'Download', getFileName("Stock-Holding-"),storedDataForDownload)
                           }}
                           style={{fontSize: 12}}/>
                    </div>
                </div>}
            </div>
        )
    };

    const renderTableUI = () => {
        const {isFilterAPIInProgress} = props;
        if (isFilterAPIInProgress) {
            return <DLLoader type={"inline"} isVisible={true}/>
        }
        if (isEmpty(searchResult.slice(1))) {
            return (
                <div className='d-flex align-items-center justify-content-center w-100' style={{minHeight: '400px'}}>
                    <DLText
                        id={'revenue-value'}
                        type={'normal'}
                        text={'No matching data found.'}
                        fontSize={'lg'}/>
                </div>
            )
        }
        return (
            searchResult ? <StockTable {...props}/> : <div/>
        )
    };

    const renderViewPanel = () => {
        const {handleViewChange} = props;
        // let drillDownBy = 'fundHouse';
        return (
            <div className={'mt-3 panel'}>
                <div
                    className={`${isXSView ? '' : 'px-4'} pt-sm-4 d-flex justify-content-between justify-content-lg-start align-items-end w-100`}>
                    <div className={'pe-3'}>
                        <DLText marginBottom={'none'}
                                text={'View'}
                                fontWeight={'semi-bold'}
                                fontSize={'lg'}/>
                    </div>
                    {(isMobileView) ?
                        <div className='d-flex align-items-center justify-content-center downloadButtonCss cursor'>
                            <i className='fas fa-download fs-6'
                               onClick={() => {
                                   downloadDataByObject('/service/stocks/getMonthwiseHoldings/download', "",'Download', getFileName("Stock-Holding-"),storedDataForDownload)
                               }}
                               style={{fontSize: 12}}/>
                        </div> :
                        <div className='d-flex justify-content-between w-100'>
                            <div>
                                <nav className='w-100  ulLine border-bottom' style={{overflowX: 'auto'}}>
                                    <ul className='d-flex ulLine ' style={{listStyle: 'none'}}>
                                        <li className={drillDownBy === 'HOLDING' ? 'active' : ''}
                                            style={{minWidth: 'max-content'}}>
                                            <div className="custom-underlined-text cursor"
                                                 style={drillDownBy === 'HOLDING' ? {
                                                     fontWeight: 600,
                                                     color: '#282828',
                                                     fontSize: 12,
                                                     textDecoration: 'underline',
                                                     fontFamily: 'Poppins, sans-serif',
                                                     margin: '0 10px',
                                                 } : {margin: '0 10px', fontSize: 12}}
                                                 onClick={() => {
                                                     handleViewChange('HOLDING')
                                                 }}
                                            >
                                                % Holding
                                            </div>

                                            {drillDownBy === 'HOLDING' &&
                                            <div className='bg-dark ' style={{minHeight: '2px'}}>

                                            </div>
                                            }
                                        </li>
                                        <li className={drillDownBy === 'COUNT' ? 'active' : ''}
                                            style={{minWidth: 'max-content'}}>
                                            <div className="custom-underlined-text cursor"
                                                 style={drillDownBy === 'COUNT' ? {
                                                     fontWeight: 600,
                                                     color: '#282828',
                                                     fontSize: 12,
                                                     textDecoration: 'underline',
                                                     fontFamily: 'Poppins, sans-serif',
                                                     margin: '0 10px',
                                                 } : {margin: '0 10px', fontSize: 12}}
                                                 onClick={() => {
                                                     handleViewChange('COUNT')
                                                 }}
                                            >
                                                Count
                                            </div>

                                            {drillDownBy === 'COUNT' &&
                                            <div className='bg-dark ' style={{minHeight: '2px'}}>

                                            </div>
                                            }
                                        </li>
                                        <li className={drillDownBy === 'AUM' ? 'active' : ''}
                                            style={{minWidth: 'max-content'}}>
                                            <div className="custom-underlined-text cursor"
                                                 style={drillDownBy === 'AUM' ? {
                                                     fontWeight: 600,
                                                     color: '#282828',
                                                     fontSize: 12,
                                                     textDecoration: 'underline',
                                                     fontFamily: 'Poppins, sans-serif',
                                                     margin: '0 10px',
                                                 } : {margin: '0 10px', fontSize: 12}}
                                                 onClick={() => {
                                                     handleViewChange('AUM')
                                                 }}
                                            >
                                                AUM
                                            </div>

                                            {drillDownBy === 'AUM' &&
                                            <div className='bg-dark ' style={{minHeight: '2px'}}>

                                            </div>
                                            }
                                        </li>

                                    </ul>
                                </nav>
                            </div>
                        </div>}
                </div>
                {isMobileView &&
                <div className={isXSView ? ' ' : 'px-4'}>
                    <nav className='w-100  ulLine border-bottom' style={{overflowX: 'auto'}}>
                        <ul className='d-flex ulLine ' style={{listStyle: 'none'}}>
                            <li className={drillDownBy === 'HOLDING' ? 'active' : ''}
                                style={{minWidth: 'max-content'}}>
                                <div className="custom-underlined-text cursor"
                                     style={drillDownBy === 'HOLDING' ? {
                                         fontWeight: 600,
                                         color: '#282828',
                                         fontSize: 12,
                                         textDecoration: 'underline',
                                         fontFamily: 'Poppins, sans-serif',
                                         margin: '0 10px',
                                     } : {margin: '0 10px', fontSize: 12}}
                                     onClick={() => {
                                         handleViewChange('HOLDING')
                                     }}
                                >
                                    % Holding
                                </div>

                                {drillDownBy === 'HOLDING' &&
                                <div className='bg-dark ' style={{minHeight: '2px'}}>

                                </div>
                                }
                            </li>
                            <li className={drillDownBy === 'COUNT' ? 'active' : ''}
                                style={{minWidth: 'max-content'}}>
                                <div className="custom-underlined-text cursor"
                                     style={drillDownBy === 'COUNT' ? {
                                         fontWeight: 600,
                                         color: '#282828',
                                         fontSize: 12,
                                         textDecoration: 'underline',
                                         fontFamily: 'Poppins, sans-serif',
                                         margin: '0 10px',
                                     } : {margin: '0 10px', fontSize: 12}}
                                     onClick={() => {
                                         handleViewChange('COUNT')
                                     }}
                                >
                                    Count
                                </div>

                                {drillDownBy === 'COUNT' &&
                                <div className='bg-dark ' style={{minHeight: '2px'}}>

                                </div>
                                }
                            </li>
                            <li className={drillDownBy === 'AUM' ? 'active' : ''}
                                style={{minWidth: 'max-content'}}>
                                <div className="custom-underlined-text cursor"
                                     style={drillDownBy === 'AUM' ? {
                                         fontWeight: 600,
                                         color: '#282828',
                                         fontSize: 12,
                                         textDecoration: 'underline',
                                         fontFamily: 'Poppins, sans-serif',
                                         margin: '0 10px',
                                     } : {margin: '0 10px', fontSize: 12}}
                                     onClick={() => {
                                         handleViewChange('AUM')
                                     }}
                                >
                                    AUM
                                </div>

                                {drillDownBy === 'AUM' &&
                                <div className='bg-dark ' style={{minHeight: '2px'}}>

                                </div>
                                }
                            </li>

                        </ul>
                    </nav>
                </div>}

                {renderTableUI()}
            </div>

        )
    };

    const renderAdvanceSearchModal = () => {
        return (
            <>
                <ExplorerFundHouseListingModal {...props}/>
                <ExplorerFundListingModal {...props}/>
                <ExplorerIndustrySearchModal {...props}/>
                <ExplorerStocksSearchModal {...props}/>
                <ExplorerMarketCap {...props}/>
                <ExplorerBusinessHouseSearchModal {...props}/>
                <MoneyFlowExplorerActionModal {...props}/>
            </>
        )
    };


    const renderPageContent = () => {
        return (
            <div className={isMobileView ? 'px-3' : 'px-sm-3'}>
                <div>
                    {renderFilterUI()}
                    {renderChipUI()}
                </div>
                {renderViewPanel()}
            </div>
        )
    };

    const renderDesktopUI = () => {
        return (
            <div>
                {renderHeader()}
                {renderPageContent()}
            </div>
        )
    };

    const renderMobileUI = () => {
        return (
            <div>
                {renderHeader()}
                {renderPageContent()}
            </div>
        )
    };
    const renderUI = () => {
        return (
            <div>
                {(isMobileView) ? renderMobileUI() : renderDesktopUI()}
                {renderAdvanceSearchModal()}
            </div>
        )
    };

    return (
        <div>
            {renderUI()}
        </div>
    )
}

MoneyFlowExplorerView.propTypes = {};

export default StockHoldingView;