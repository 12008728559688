import React from 'react'
import {withRouter} from "react-router-dom";
import {Bar} from "react-chartjs-2";
import {isEmpty} from "../../utils/validations";
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import appTheme from "../../assets/appTheme";
import {getFixedValuesAfterDecimal, getNextRoundedNumber, getPreviousRoundedNumber} from "../../utils/helper";
import EchartBarChart from "../../component-utils/EChart/EchartBarChart";
import SecondViewEchartBarChart from "../netBuyingSellingBarChart/SecondViewEchartBarChart";

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);

function SecondViewBarChart(props) {
    const {yAxisBar, yAxisLine, xAxis, barColor, isFromFund, isMobileView, isXSView, hideCSS, isLGView, isXLView, isBarChartClickable,getBarClick} = props;
    const includesNegative = yAxisBar.some(number => number < 0);


    let maxValueBar = Math.max(...yAxisBar) * 2;
    let maxValueLine = Math.max(...yAxisLine);
    let maxChartValueBar = getNextRoundedNumber(maxValueBar);
    let maxChartValueLine = getNextRoundedNumber(maxValueLine);


    const getMinForBar = (yAxisBar) => {

        let numericNumbers = yAxisBar.map(Number);
        let minValue = Math.min(...numericNumbers);
        let maxValue = Math.max(...numericNumbers);

        if (minValue >= 0) {
            return 0
        }

        if (minValue <= 0 && maxValue >= 0) {
            let min = Math.max(maxValue, Math.abs(minValue));
            return '-' + Math.trunc(min)
        }
        return Math.trunc(minValue)
    };

    const getMaxForBar = (yAxisBar) => {
        let numericNumbers = yAxisBar.map(Number);
        let minValue = Math.min(...numericNumbers);
        let maxValue = Math.max(...numericNumbers);
        if ((maxValue < 1 && maxValue > -1) && (minValue < 1 && minValue > -1)) {
            return 4
        }

        if (maxValue <= 0) {
            return 0
        }

        if (minValue <= 0 && maxValue >= 0) {
            let max = Math.max(maxValue, Math.abs(minValue));
            return Math.trunc(max)
        }
        return Math.trunc(maxValue)
    };

    const getStepSize = (maxValue, minValue) => {
        if (minValue >= 0) {
            // All positive or zero
            return maxValue / 2;
        }

        if (maxValue <= 0) {
            // All negative
            return -minValue / 2;
        }

        // Mixed positive and negative

        return Math.max(maxValue, Math.abs(minValue));


    };

    const getTabWidth=()=>{
        if(!isMobileView){
            return "200px";
        }
    }

    const getBarChartValues = (initialData) =>{
        let data={
            name: xAxis,
            data: yAxisBar,
            emphasis: {
                focus: 'series'
            },
            barGap: 0.2,
            barWidth: '20%',
            itemStyle: {
                color: (params) => (params.value >= 0 ? '#029C48' : '#D72F2F'),
            },
            // color: getBorderColorForBar(getDataOnly(values.map(innerArray => innerArray[i]), 'total_market_value_change_due_to_activity')),
            type: 'bar',
        };
        return data;
    };

    const renderBarChart = () =>{

        let minValue = getMinForBar(yAxisBar);
        let maxValue = getMaxForBar(yAxisBar);
        maxValue = getNextRoundedNumber(maxValue);
        minValue = minValue <= 0 ? -getNextRoundedNumber(Math.abs(minValue)) : getNextRoundedNumber(minValue);
        let stepSize = getStepSize(maxValue, minValue);

        const labels = isEmpty(xAxis) ? [] : xAxis;

        return (
            <div className=''>
                <SecondViewEchartBarChart {...props}
                                series={getBarChartValues(yAxisBar)}
                                yAxisMaxValue={maxValue}
                                yAxisMinValue={minValue}
                                showDownloadIcon={false}
                                stepSize={stepSize}
                                height={"200px"}
                                yAxisPosition={"right"}
                                gridLeft={10}
                                gridRight={isXSView? 50 : 70}
                                xAxislabels={xAxis}/>

            </div>
        )
    };

    return (
        <div>

            <div style={{minHeight : "150px"}}>

                <div className={isEmpty(yAxisBar) ? "flex-grow-1 h-100 d-flex justify-content-center align-items-center" : "flex-grow-1"}>
                    {
                        isEmpty(yAxisBar)?
                            <div className="h-100">
                                No data found
                            </div>
                            :
                            renderBarChart()
                    }
                </div>

            </div>
        </div>
    )

}

export default withRouter(SecondViewBarChart);

