import React from 'react';
import DLText from "dlcomponent/components/DLText/DLText";
import {isEmpty} from "../utils/validations";
import DLLoader from "../core/components/DLLoader/DLLoader";
import appTheme from "../assets/appTheme";
import LineChart from "dlcomponent/components/D3Charts/LineChart";
import {getNextRoundedNumber, getTrimmedValue, numberWithCommas, priceWithCommas} from "../utils/helper";
import {Bar} from "react-chartjs-2";
import ExplorerChipsView from "./ExplorerChipsView";
import ExplorerFundHouseListingModal from "./explorerModal/ExplorerFundHouseListingModal";
import ExplorerFundListingModal from "./explorerModal/ExplorerFundListingModal";
import ExplorerMarketCap from "./explorerModal/ExplorerMarketCap";
import ExplorerIndustrySearchModal from "./explorerModal/ExplorerIndustrySearchModal";
import ExplorerStocksSearchModal from "./explorerModal/ExplorerStocksSearchModal";
import ExplorerBusinessHouseSearchModal from "./explorerModal/ExplorerBusinessHouseSearchModal";
import ReactECharts from 'echarts-for-react';
import EchartBarChart from "../component-utils/EChart/EchartBarChart";
import DLDropDown from 'dlcomponent/components/DLDropdown/DLDropDown'

function ExplorerView(props) {

    const {searchResult, onChangeValue, createLineChartDataset, selectedDatasetIndex, getNameOnly, getDataOnly, getLineChartValues
        , resetListingData, isXSView, isSMView,  isFilterAPIInProgress, isMobileView, handleLegendClick,
        explorerFilter, drillDownBy} = props;

    const getBorderColorForBar = (data) =>{
        return data.map(item=> item < 0 ? '#D72F2F' : '#029C48')
    };

    const getBarChartValues = (initialData) =>{
        let formatedData = createLineChartDataset(initialData);

        let values = Object.values(initialData);
        return formatedData.map((_, i) => ({
            name: getNameOnly(formatedData[i]),
            data: getDataOnly(values.map(innerArray => innerArray[i]), 'total_market_value_change_due_to_activity'),
            emphasis: {
                focus: 'series'
            },
            barGap: 0.2,
            barWidth: '5%',
            // itemStyle: {
            //     color: (params) => (params.value >= 0 ? '#029C48' : '#D72F2F'),
            // },
            // color: getBorderColorForBar(getDataOnly(values.map(innerArray => innerArray[i]), 'total_market_value_change_due_to_activity')),
            type: 'bar',
        }));

    };

    const getFormattedCounts=(inputValue)=>{

        let formattedValue = inputValue;

        if (inputValue === '0') {
            return '0'
        }

        if (inputValue >= 1000000) {
            let simulatedValue = (inputValue / 1000000).toFixed(2); // Divide by 1 million for 'M'
            formattedValue = parseFloat(priceWithCommas(simulatedValue)) + 'M';
        } else {
            formattedValue = numberWithCommas(inputValue);
        }
        return formattedValue

    };

    const getMinForBar = (searchResult, valueAttribute) => {
        let formatedData = createLineChartDataset(searchResult);

        let values = Object.values(searchResult);

        let yAxisBar= formatedData.map((_, i) => {
            return getDataOnly(values.map(innerArray => innerArray[i]), valueAttribute)
          });

        yAxisBar = yAxisBar.reduce((acc, currentArray) => acc.concat(currentArray), []);

        let numericNumbers = yAxisBar.map(Number);

        let minValue = Math.min(...numericNumbers);
        let maxValue = Math.max(...numericNumbers);

        if (minValue >= 0) {
            return 0
        }

        if (minValue <= 0 && maxValue >= 0) {
            let min = Math.max(maxValue, Math.abs(minValue));
            return '-' + Math.trunc(min)
        }
        return Math.trunc(minValue)
    };

    const getMaxForBar = (searchResult, valueAttribute) => {
        let formatedData = createLineChartDataset(searchResult);

        let values = Object.values(searchResult);

        let yAxisBar= formatedData.slice(0,5).map((_, i) => {
            return  getDataOnly(values.map(innerArray => innerArray[i]), valueAttribute)
        });

        yAxisBar = yAxisBar.reduce((acc, currentArray) => acc.concat(currentArray), []);

        let numericNumbers = yAxisBar.map(Number);
        let minValue = Math.min(...numericNumbers);
        let maxValue = Math.max(...numericNumbers);
        if ((maxValue < 1 && maxValue > -1) && (minValue < 1 && minValue > -1)) {
            return 4
        }

        if (maxValue <= 0) {
            return 0
        }

        if (minValue <= 0 && maxValue >= 0) {
            let max = Math.max(maxValue, Math.abs(minValue));
            return Math.trunc(max)
        }
        return Math.trunc(maxValue)
    };

    const getStepSize = (maxValue, minValue, steps) => {
        if (minValue >= 0) {
            // All positive or zero
            return maxValue / steps;
        }

        if (maxValue <= 0) {
            // All negative
            return -minValue / steps;
        }

        // Mixed positive and negative

        return Math.max(maxValue, Math.abs(minValue)) / steps;


    };

    const trimLegendText = (params) =>{
        return getTrimmedValue(params, 70)
    };

    const getBottom = () =>{
        const {isLGView, isXLView, isMDView, isXSView, isSMView} = props;
        const maxIteration = props.getMaxIterationValue(drillDownBy, Object.values(searchResult)[0].length);

        if (isXLView){
            return maxIteration < 6 ? 70 : (maxIteration / 3) * 30
        }else if (isLGView){
            return maxIteration < 6 ? 80 : (maxIteration / 3) * 35

        }else if (isMDView){
            return maxIteration < 6 ? 70 : (maxIteration / 4) * 30

        }else if (isSMView){
            return maxIteration < 6 ? 100 : (maxIteration / 3) * 30

        }else{
            return maxIteration < 6 ? 100 : (maxIteration / 3) * 40

        }

    };

    const events = {
        click: props.handleLineClick,
        legendselectchanged: props.handleLegendSelectChanged,
    };
    const renderLineChartUI = () =>{
        const {menuVisible, addLineFilter} = props;

        const maxIteration = props.getMaxIterationValue(drillDownBy, Object.values(searchResult)[0].length)

        let minValue = getMinForBar(searchResult, 'total_market_value');
        let maxValue = getMaxForBar(searchResult, 'total_market_value');

        const stepSize = Math.ceil((maxValue - minValue) / 3);
        const bottom =  getBottom();
        const option = {
            title: {
                display:false,
                text: ''
            },
            toolbox: {
                show: true,
                orient: 'vertical',
                right: 0,
                top: 0,
                feature: {
                    saveAsImage: { show: true }
                }
            },
            tooltip: {
                trigger: 'item',
                position: function (point, params, dom, rect, size) {
                    // Adjust the tooltip position to stay within the screen bounds
                    const top = Math.max(10, point[1]);
                    const left = Math.min(window.innerWidth - size.contentSize[0], point[0]);
                    return [left + 20, 10];
                },
            },
            legend: {
                orient: "horizontal",
                bottom: 0,
                left: 0,
                right: 0,
                itemHeight: 8,
                width: '100%',
                textStyle: {
                    fontSize: 10
                },
                formatter: trimLegendText,
            },
            xAxis: {
                boundaryGap: false,
                data: isEmpty(searchResult) ? [] :Object.keys(searchResult),

            },
            yAxis: {
                type: 'value',
                position: "left",
                min: 0,
                axisLabel: {
                    formatter: (value) => getFormattedCounts(value),
                },
                splitNumber: 3,
                boundaryGap: false
            },
            grid: {
                top:40,
                left: 55, // Set left padding to 0
                right: 20, // Set right padding to 0
                bottom:bottom
             },
            series: getLineChartValues(searchResult),
        };

        if (isFilterAPIInProgress){
            return (
                <div style={{minHeight:400, maxHeight:300, width:'800px'}}>
                    <DLLoader type={"inline"} isVisible={true}/>
                </div>
            );
        }

        if (isEmpty(searchResult)){
            return (
                <div className='d-flex align-items-center justify-content-center' style={{minHeight: 300, width:'800px'}}>
                    <DLText marginBottom={'none'}
                            isInline={true}
                            text={'No data found'}
                            fontSize={'sm'}/>
                </div>
            )
        }

        return (
            <div className='px-3' style={{width:'100%'}}>
            <div className=' ' style={{width:'100%'}}>
                <ReactECharts option={option}
                              onEvents={events}
                              lazyUpdate={true}
                              style={{ height: props.isXSView ? '450px' : '420px', width: '100%' }} />
                {menuVisible &&

                <div style={{
                    position: 'absolute',
                    width: '100vw',
                    height:'100vh',
                    top: 0,
                    left: 0,
                    right: 0,
                    minHeight: 'calc(100vh - 300px)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 999
                }}>
                    <div className='bg-white' style={{
                        minWidth: 350,
                        maxWidth: 350,
                        borderRadius: '7px',
                        boxShadow: '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)'
                    }}>
                        <div className='px-3 py-2 border-bottom d-flex justify-content-between'>
                            <div>
                                <DLText id={'email-dialog-confirmation'}
                                        fontSize={"md"}
                                        fontWeight={'semi-bold'}
                                        marginBottom={'none'}
                                        text={props.selectedLine}/>
                            </div>
                            <div style={{marginTop: '-4px'}}>
                                <i className='fa-sharp fa-solid fa-xmark'
                                   onClick={() => {
                                       onChangeValue('menuVisible', false)
                                   }}
                                   style={{
                                       fontSize: "16px",
                                       cursor: 'pointer',
                                       color: appTheme.appColor.darkGray
                                   }}/>
                            </div>

                        </div>
                        <div>
                            <div className='py-2 p-3 list-item-hover'>
                                <DLText marginBottom={'none'}
                                        isInline={true}
                                        text={'Add as a filter'}
                                        isClickable={true}
                                        onClick={()=>addLineFilter()}
                                        fontSize={'sm'}/>

                            </div>
                            {/*<div className='py-2 p-3 list-item-hover'>*/}
                            {/*    <DLText marginBottom={'none'}*/}
                            {/*            isInline={true}*/}
                            {/*            isClickable={true}*/}
                            {/*            text={'Navigate to that page'}*/}
                            {/*            fontSize={'sm'}/>*/}

                            {/*</div>*/}
                            {/*<div className='py-2 p-3 list-item-hover'>*/}
                            {/*    <DLText marginBottom={'none'}*/}
                            {/*            isInline={true}*/}
                            {/*            isClickable={true}*/}
                            {/*            text={'Advanced stock search'}*/}
                            {/*            onClick={()=>addLineFilter()}*/}
                            {/*            fontSize={'sm'}/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                }
            </div>
            </div>
            )
    };

    const getBaseLog = (x, y) => {
        return Math.log(y) / Math.log(x);
    };

    const getRoundedNumber = (value) =>{
        if (isEmpty(value)) {
            return
        }
        let log = getBaseLog(10, value);
        let stepSize;
        log = Math.floor(log);

        if (log === 5) {
            return Math.ceil(value / 100000) * 100000
        } else if (log === 4) {
            return  Math.ceil(value / 10000) * 10000
        } else if (log === 3) {
            return Math.ceil(value / 1000) * 1000
        } else if (log === 2) {
            return Math.ceil(value / 100) * 100
        }  else if (log === 1) {
            return  Math.ceil(value / 10) * 10
        }
         return value;
     };

    const renderBarchartUi = () =>{

        if (isFilterAPIInProgress){
            return (
                <div style={{minHeight:300, maxHeight:300}}>
                    <DLLoader type={"inline"} isVisible={true}/>
                </div>
            );
        }
        if (isEmpty(searchResult)){
            return (
                <div className='d-flex align-items-center justify-content-center' style={{minHeight: 300}}>
                    <DLText marginBottom={'none'}
                            isInline={true}
                            text={'No data found'}
                            fontSize={'sm'}/>
                </div>
            )
        }
        let minValue = getMinForBar(searchResult, 'total_market_value_change_due_to_activity');
        let maxValue = getMaxForBar(searchResult, 'total_market_value_change_due_to_activity');

        maxValue = getRoundedNumber(maxValue);
        if (minValue <= 0 && maxValue <= 0){
            minValue= -getRoundedNumber(-minValue)
        }else {
            minValue = minValue < 0 ? -maxValue : getNextRoundedNumber(minValue);
        }

        const labels = isEmpty(searchResult) ? [] :Object.keys(searchResult);

        let data = {
            labels,
            datasets: getBarChartValues(searchResult)
        };

        return (
            <div className='px-3'>
                <EchartBarChart {...props}
                                series={getBarChartValues(searchResult)}
                                yAxisMaxValue={maxValue}
                                yAxisMinValue={minValue}
                                showDownloadIcon={true}
                                xAxislabels={labels}/>

            </div>
        )
    };

    const getLabelForDrillDownValue = (viewBy, length) =>{
        const {fundHouses, funds, industries, stocks, businessHouses, marketCaps} = props.explorerFilter;

        switch (viewBy) {
            case 'fundHouse':
                return ((isEmpty(fundHouses) && length >= 5) ? 'Top ' : '') + length +' Fund House';
            case 'fund':
                return ((isEmpty(funds) && length >= 5) ? 'Top ' : '') +  length +' Fund';
            case 'stock':
                return ((isEmpty(stocks) && length >= 5) ? 'Top ' : '') +  length +' Stock';
            case 'marketCap':
                if (length > 1){
                    return ((isEmpty(marketCaps) && length >= 5) ? 'Top ' : '') + length +' Market-Cap Categorie';
                }else {
                    return ((isEmpty(marketCaps) && length >= 5) ? 'Top ' : '') +  length +' Market-Cap Category';
                }
            case 'industry':
                return ((isEmpty(industries) && length >= 5) ? 'Top ' : '') +  length +' Sector';
            case 'businessHouse':
                return ((isEmpty(businessHouses) && length >= 5) ? 'Top ' : '') + length +' Business House';
            default:
        }
    };

    const getResultCount = () =>{
        const {getMaxIterationValue} = props;
        let values = Object.values(searchResult);

        let length = getMaxIterationValue(drillDownBy, values[0].length);
        if (isEmpty(values[0])){
            return
        }
        if (length === 1){
            return   getLabelForDrillDownValue(drillDownBy, length)
        }
        return  getLabelForDrillDownValue(drillDownBy, length) + 's'

    };

    const renderGraphsUI = () =>{
        let values = Object.values(searchResult);

        return(
            <div className='w-100 d-flex flex-lg-row flex-column '>
                <div className={isMobileView ? 'w-100 border-bottom pb-3' : 'w-100 pb-3'}>
                    <div className='flex-shrink-1 w-100 px-3 pt-3'>
                        <DLText id={'revenue-value'}
                                type={'normal'}
                                isInline={true}
                                text={'Monthly AUM '}
                                marginBottom={'none'}
                                fontWeight={'semi-bold'}
                                fontSize={'md'}/>
                        {isEmpty(searchResult) ? <></>:
                            <DLText id={'revenue-value'}
                                    type={'normal'}
                                    text={': Showing  '+ getResultCount()}
                                    marginBottom={'none'}
                                    isInline={true}
                                    fontWeight={'semi-bold'}
                                    fontSize={'md'}/>
                        }
                    </div>
                    <div className='d-flex  '>
                    {renderLineChartUI()}
                    </div>

                </div>
                {/*<div className='border my-3'>*/}
                {/*</div>*/}
                <div className='w-100 pb-3'>
                    <div className='flex-shrink-1 w-100 px-3 pt-3'>
                        <DLText id={'revenue-value'}
                                type={'normal'}
                                isInline={true}
                                text={'Net Buying/Selling'}
                                marginBottom={'none'}
                                fontWeight={'semi-bold'}
                                fontSize={'md'}/>
                    </div>
                    <div>
                    {renderBarchartUi()}
                    </div>
                </div>
            </div>
        )
    };

    const renderChipsFilterUI = () => {

        if ( isXSView || isSMView){
          return (
               <div className='px-3'>
                <ExplorerChipsView {...props}/>
            </div>
          )
        }
        return (
            <div className='d-flex justify-content-between mb-2 align-items-center'>
                <div className='px-3'>
                    <ExplorerChipsView {...props}/>
                </div>
                {(!isXSView && !isSMView) &&

                <div className='pe-3' style={{minWidth: 'max-content'}}>
                    <DLText
                        id={'clear'}
                        marginBottom={'none'}
                        text={'Clear All'}
                        customTextStyle={{
                            color: appTheme.appColor.black
                        }}
                        isClickable={true}
                        onClick={() => resetListingData()}
                        fontSize={'sm'}/>
                </div>
                }
            </div>
        )
    };

    const renderAdvanceSearchModal = () =>{
        return(
            <>
                <ExplorerFundHouseListingModal {...props}/>
                <ExplorerFundListingModal {...props}/>
                <ExplorerIndustrySearchModal {...props}/>
                <ExplorerStocksSearchModal {...props}/>
                <ExplorerMarketCap {...props}/>
                <ExplorerBusinessHouseSearchModal {...props}/>
            </>
        )
    };

    const renderDrillDownByUI = () => {
        const {drillDownBy, handleViewChange, chartType} = props;
        const activeStyle = {
            fontWeight: 600,
            color: '#282828',
            textDecoration: 'underline',
            fontFamily: 'Poppins, sans-serif',
        };

            return (
                <div className='px-3'>
                    <div className='pt-3'>
                        <DLText marginBottom={'sm'}
                                text={'View'}
                                fontWeight={'semi-bold'}
                                fontSize={'lg'}/>
                    </div>
                    <nav className='w-100 border-bottom'>

                        <ul className='d-flex ulLine' style={{listStyle: 'none', overflowX:'auto'}}>
                            <li className={drillDownBy === 'fundHouse' ? 'active' : ''} style={{minWidth:'max-content'}}>
                                <div className="custom-underlined-text cursor"
                                     style={drillDownBy === 'fundHouse' ? {
                                         fontWeight: 600,
                                         color: '#282828',
                                         textDecoration: 'underline',
                                         fontFamily: 'Poppins, sans-serif',
                                         margin: '0 10px',
                                     } : {margin: '0 10px'}}
                                     onClick={() => {
                                         handleViewChange('fundHouse')
                                     }}>
                                    Fund Houses
                                </div>

                                {drillDownBy === 'fundHouse' &&
                                <div className='bg-dark mt-1' style={{minHeight: '2px'}}>

                                </div>
                                }
                            </li>
                            <li className={drillDownBy === 'fund' ? 'active' : ''} style={{margin: '0 12px', minWidth:'max-content'}}>
                                <div className="custom-underlined-text cursor"
                                     style={drillDownBy === 'fund' ? activeStyle : {}}
                                     onClick={() => {
                                         handleViewChange('fund')
                                     }}>
                                    Funds
                                </div>

                                {drillDownBy === 'fund' &&
                                <div className='bg-dark mt-1' style={{minHeight: '2px'}}>

                                </div>
                                }
                            </li>
                            <li className={drillDownBy === 'industry' ? 'active' : ''} style={{margin: '0 12px', minWidth:'max-content'}}>
                                <div className="custom-underlined-text cursor"
                                     style={drillDownBy === 'industry' ? activeStyle : {}}
                                     onClick={() => {
                                         handleViewChange('industry')
                                     }}>
                                    Sectors
                                </div>

                                {drillDownBy === 'industry' &&
                                <div className='bg-dark mt-1' style={{minHeight: '2px'}}>

                                </div>
                                }
                            </li>
                            <li className={drillDownBy === 'stock' ? 'active' : ''} style={{margin: '0 12px', minWidth:'max-content'}}>
                                <div className="custom-underlined-text cursor"
                                     style={drillDownBy === 'stock' ? activeStyle : {}}
                                     onClick={() => {
                                         handleViewChange('stock')
                                     }}>
                                    Stocks
                                </div>

                                {drillDownBy === 'stock' &&
                                <div className='bg-dark mt-1' style={{minHeight: '2px'}}>

                                </div>
                                }
                            </li>
                            <li className={drillDownBy === 'marketCap' ? 'active' : ''} style={{margin: '0 12px', minWidth:'max-content'}}>
                                <div className="custom-underlined-text cursor"
                                     style={drillDownBy === 'marketCap' ? activeStyle : {}}
                                     onClick={() => {
                                         handleViewChange('marketCap')
                                     }}>
                                    Market Caps
                                </div>

                                {drillDownBy === 'marketCap' &&
                                <div className='bg-dark mt-1' style={{minHeight: '2px'}}>

                                </div>
                                }
                            </li>
                            <li className={drillDownBy === 'businessHouse' ? 'active' : ''} style={{margin: '0 12px', minWidth:'max-content'}}>
                                <div className="custom-underlined-text cursor"
                                     style={drillDownBy === 'businessHouse' ? activeStyle : {}}
                                     onClick={() => {
                                         handleViewChange('businessHouse')
                                     }}>
                                    Business Houses
                                </div>

                                {drillDownBy === 'businessHouse' &&
                                <div className='bg-dark mt-1' style={{minHeight: '2px'}}>

                                </div>
                                }
                            </li>
                        </ul>
                    </nav>
                </div>
            );

    };

    const checkExplorerDataAvailable = () =>{
       let isFilterEmpty =  Object.values(explorerFilter).every(value => {

            return value === '' || value === null || isEmpty(value);
        });

       if (isFilterEmpty && isEmpty(searchResult) && drillDownBy.length <= 1){
           return true
       }else {
           return false
       }
    };

    return (
        <div className=' bg-white pb-3' style={{minHeight: isMobileView ?  'calc(100vh - 164px)' : 'calc(100vh - 106px)'}}>
            <div className='fixedHeader bg-white px-3 d-flex justify-content-between align-items-center'>
                <div className={'d-flex pb-2 w-100'}>
                    <i className="fa-solid fa-chevron-left cursor pe-2"
                       style={{marginTop: 8}}
                       onClick={() => props.history.goBack()}/>
                    <div className='d-flex justify-content-between align-items-center w-100'>
                        <div>
                        <DLText marginBottom={'none'}
                                text={'Explorer'}
                                fontWeight={'semi-bold'}
                                fontSize={'lg'}/>
                        </div>
                        {(isXSView || isSMView) &&
                            <div>
                        <i className="fa fa-times cursor ms-3"
                           style={{paddingTop: 6}}
                           onClick={() => resetListingData()}
                        />
                            </div>
                        }
                    </div>
                </div>
            </div>

                <div className='pb-2'>
                    {renderChipsFilterUI()}
                    {checkExplorerDataAvailable() ?
                        <div className='d-flex align-items-center justify-content-center' style={{minHeight: 300}}>
                            <DLText marginBottom={'none'}
                                    isInline={true}
                                    text={'Apply a filter'}
                                    fontSize={'sm'}/>
                        </div>
                        :
                    <div className='mx-sm-3  panel '>
                        <div className='d-flex justify-content-between'>
                        {renderDrillDownByUI()}
                        <div className='text-end p-3'>

                            <DLDropDown id={''}
                                        label={""}
                                        noOptionsMessage={'No options found'}
                                        placeholder={''}
                                        value={props.maxValuesOnChart}
                                        onSelect={(value) => onChangeValue('maxValuesOnChart' ,value)}
                                        t={props.t}
                                        marginBottom={"sm"}
                                        options={[
                                            {
                                                label:'Top 5',
                                                value: 5
                                            },
                                            {
                                                label:'Top 10',
                                                value: 10
                                            }
                                        ]}
                                        minWidth={"100%"}
                            />
                        </div>
                        </div>
                        {renderGraphsUI()}
                    </div>
                    }
                </div>
            {renderAdvanceSearchModal()}
        </div>
    )
}

ExplorerView.propTypes = {};

export default ExplorerView;
