import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import {rootReducer} from './rootReducer';

const initialState = {};

const middleware = [thunk];


function createStoreForApp(){

      return createStore(
          rootReducer,
          initialState,
          compose(
              applyMiddleware(
                  ...middleware
              )
          )
      )

}

const store = createStoreForApp();
export default store;

