import React, {useEffect, useState} from 'react'
import DLLoader from "../../core/components/DLLoader/DLLoader";
import DLText from "dlcomponent/components/DLText/DLText";
import DLButton from "dlcomponent/components/DLButton/DLButton";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FundsChart from "./FundsChart";
import FundsTable from "./FundsTable";
import {
    downloadData,
    downloadDataByObject,
    getAllFunds,
    getFundSummary, resetFundPage, resetFundRowsPerPage,
    resetFundTableByOrder, resetFundTableByOrderBy, resetFundTabValue,
    setFundKeywordFilter, setFundTabValue,
} from "../FundAction";
import ChartRadioGroup from "../../component-utils/ChartRadioGroup/ChartRadioGroup";
import CustomTabPanel from "../../component-utils/CustomTabPanel/CustomTabPanel";
import {
    getChartData,
    getFixedValuesAfterDecimal,
    getIndexArr,
    getTrimVal,
    searchOnWordBoundary
} from "../../utils/helper";
import {isEmpty} from "../../utils/validations";
import {connect} from "react-redux";
import DLInputTextField from "dlcomponent/components/DLInputTextField/DLInputTextField";
import moment from "moment";
import {getFundsTrimVal} from "../../utils/stocksHelper";
import appTheme from "../../assets/appTheme";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function FundListing(props) {

    const [fundListingState, setFundListingState] = useState({
        allFundListingData : [],
        percentage: false,
        isSearchApply: false,
        searchResultNotAvailable: false,
        sortedData: [],
        chartType:'marketValue',
    });
    const [chartLabelsArray, setChartLabelsArray] = useState([]);
    const [chartDataArray, setChartDataArray] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tableHeader, setTableHeader] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isTableLoading, setIsTableLoading] = useState(true);

    const {tabValue}  = props;

    const getData = (data) =>{
        let dataObj = [];
        if (isEmpty(data)){
            return dataObj
        }

        data.forEach((item)=>{
            dataObj.push({...item, label:item?.fund_name, value:item?.fund_id})
        });
        if (isEmpty(dataObj)){
            return []
        }
        return dataObj.sort((a, b) => {
            let A = a?.label?.trim();
            let B = b?.label?.trim();

           return A?.localeCompare(B)
        });
    };

    useEffect(()=>{
        if (props.history.action === "POP" || props.history.action === "REPLACE"){
            //backward navigation
        }else {
            resetFundTableByOrder();
            resetFundTableByOrderBy();
            resetFundPage();
            resetFundRowsPerPage();
            resetFundTabValue();
        }

        getFundSummary(VALUATION_DATE).then((res) => {
            if (res.success && !isEmpty(res.data?.data)) {
                const copiedArray = res.data.data.slice(1, res.data.data.length);
                setTableData(copiedArray);
                setFundsGraph(copiedArray);
                setTableHeader(res.data.data[0]);
                setIsLoading(false);
                setIsTableLoading(false)
            }
        })
    },[]);

    useEffect(()=>{
        setIsLoading(true);
        setTimeout(()=>{
            setIsLoading(false)
        },100)
    },[props.isLGView, props.isMDView, props.isXLView, props.isSMView, props.isXSView]);

    useEffect(()=>{
        const {isLGView, isXLView, keyword, isMobileView, isXSView} = props;
        const {chartType} = fundListingState;

        if (isEmpty(tableData)){
            return
        }

        if (isEmpty(keyword)){
            let newChartData = [];
            let newLabelData = [];

            setFundListingState((prev)=>({
                ...prev,
                isSearchApply: true,
                sortedData: []
            }));

            let chartObj = getChartData(chartType, tableData, 'fund_name', isLGView, isXLView,isMobileView, isXSView);

            setChartLabelsArray(chartObj?.newLabelData);
            setChartDataArray(chartObj?.newChartData);

            setTimeout(()=>{
                setFundListingState((prev)=>({
                    ...prev,
                    isSearchApply: false,
                    searchResultNotAvailable: false
                }))
            },300)
        }else {
            let sortedData = [];
            setFundListingState((prev)=>({
                ...prev,
                isSearchApply: true
            }));

            let options =  tableData.map((item)=>{
                return {...item, label:item.fund_name, value: item.fund_name}
            });

            sortedData = searchOnWordBoundary(props.keyword, JSON.parse(JSON.stringify(options)));

            if (!isEmpty(sortedData)){
                let data = searchOnWordBoundary(props.keyword, options);
                let chartObj = getChartData(chartType, data, 'fund_name', isLGView, isXLView,isMobileView, isXSView);

                setFundListingState((prev)=>({
                    ...prev,
                    sortedData: sortedData,
                    searchResultNotAvailable: false
                }));
                setChartLabelsArray(chartObj?.newLabelData);
                setChartDataArray(chartObj?.newChartData);
            }else {
                setFundListingState((prev)=>({
                    ...prev,
                    sortedData: [],
                    searchResultNotAvailable: true
                }));
            }

            setTimeout(()=>{
                setFundListingState((prev)=>({
                    ...prev,
                    isSearchApply: false
                }))
            },300)

        }

    },[props.keyword, tableData]);

    const getFileName = (blockName) =>{
        let date = new Date();
        return 'Funds-'+blockName+moment(date).format('YYYY-MM-DD')
    };

    const setFundsGraph = (data) => {
        const {isLGView, isXLView, isMobileView, isXSView} = props;

        let length = 10;
        if (data?.length < 10){
            length = data?.length
        }
        for (let i = 0; i < length; i++) {

            let chartLabel = getFundsTrimVal(data[i].fund_name, isLGView, isXLView, isMobileView, isXSView);
            let chartData = getFixedValuesAfterDecimal(data[i].total_market_value,2);

            setChartLabelsArray(fundHouseNames => [...fundHouseNames, chartLabel]);
            setChartDataArray(marketValueInCrores => [...marketValueInCrores, chartData]);

        }
    };

    const handleTabChange = (event, newValue) => {
        setFundTabValue(newValue);
    };

    const handleClick = (elementIndex) => {
        const {chartType, sortedData} = fundListingState;
        let tableDataArr = !isEmpty(sortedData) ? sortedData :  tableData;
        let indexArr = getIndexArr(chartType, tableDataArr);

            let clickedFund = indexArr[elementIndex];
            props.history.push('/funds/'+clickedFund.fund_house_id+"/"+clickedFund.fund_id)

    };

    const deepCopy = (obj) => {
        return JSON.parse(JSON.stringify(obj));
    };

    //order by descending
    const compareByMarketValueChangeMoM = (a, b) => {
        if (a.net_buying_selling < b.net_buying_selling) {
            return 1;
        }
        if (a.net_buying_selling > b.net_buying_selling) {
            return -1;
        }
        return 0;
    };

    const compareByMarketValueChangePercent = (a, b) => {
        if (a.net_buying_selling_percentage < b.net_buying_selling_percentage) {
            return 1;
        }
        if (a.net_buying_selling_percentage > b.net_buying_selling_percentage) {
            return -1;
        }
        return 0;
    };


    const handleRadioChange = (event) => {
        const {isLGView, isXLView,isMobileView, isXSView} = props;
        let chartType =  event ;
        const {sortedData} = fundListingState;

        let tableDataArr = !isEmpty(sortedData) ? sortedData :  tableData;
        let chartObj = getChartData(chartType, tableDataArr, 'fund_name', isLGView, isXLView,isMobileView, isXSView);


        setFundListingState((prevState)=>({
            ...prevState,
            percentage: chartType === 'changePercent' || chartType ===  'changeSellingPercent',
            chartType: chartType
        }));

        setChartLabelsArray(chartObj?.newLabelData);
        setChartDataArray(chartObj?.newChartData);
    };


    const onChangeValue = (name, value) =>{
        setFundListingState((prevState)=>({
            ...prevState,
            name: value
        }))
    };

    const onChangeKeywordFilter = (keyword) =>{
        setFundKeywordFilter(keyword)
    };

    if (isLoading) {
        return (
            <div>
                <DLLoader  type={'inline'} isVisible={true}/>
            </div>
        )
    }

    const getTableBarUI = (ui) =>{
        const {searchResultNotAvailable, percentage, sortedData, chartType} = fundListingState;
        let tableDataArr = !isEmpty(sortedData) ? sortedData :  tableData;

        if (searchResultNotAvailable){
            if(ui === 'table'){
                return (
                    <div style={{minHeight: 'calc(100vh - 400px)'}}>
                        <div className= {ui === "table"? "p-3" : "" }>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={'No matching fund found.'}
                                fontSize={'lg'}/>
                        </div>
                    </div>
                )
            }else {
                return (
                    <div style={{minHeight: 'calc(100vh - 400px)'}}>
                        <ChartRadioGroup handleRadioChange={handleRadioChange}
                                         chartType={chartType}
                                         isXSView={props.isXSView}
                                         isSMView={props.isSMView}/>
                        <div className='px-3'>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={'No matching fund found.'}
                                fontSize={'lg'}/>
                        </div>
                    </div>
                )
            }
        }

        if(ui === 'table'){
            return(
                <FundsTable data={tableDataArr} {...props} tableHeader={tableHeader}/>
            )
        }else {
            return (
                <>
                    <ChartRadioGroup handleRadioChange={handleRadioChange}
                                     isXSView={props.isXSView}
                                     chartType={chartType}
                                     isSMView={props.isSMView}/>
                        {getResult(chartDataArray)}
                    <FundsChart tableData={tableData}
                                yAxis={chartLabelsArray}
                                xAxis={chartDataArray}
                                chartType={chartType}
                                handleClick={handleClick}
                                percentage={percentage}
                                isMobileView={props.isMobileView}/>
                </>
            )
        }
    };

    const getResult = (data) =>{
        if (isEmpty(data)){
            return
        }

        if (data?.length >= 10){
            return(
                <div className='py-2 mx-3'>
                    Showing top 10 results
                </div>
            )
        }else {
            if (data.length === 1){
                return (
                    <div className='py-2 mx-3'>
                        Showing {data?.length} result
                    </div>
                )
            }else {
                return (
                    <div className='py-2 mx-3'>
                        Showing {data?.length} results
                    </div>
                )

            }
        }
    };

    const renderDataListingUI = () =>{
        const {percentage, isSearchApply} = fundListingState;
        const {isXSView} = props;

        if (isTableLoading){
            return
        }
        return(
            <div className='panel mx-sm-3'
                 id='fund-listing'>
                <Box sx={{ width: '100%' }}>
                <Box className={isXSView ? 'd-flex justify-content-between mx-3' : 'd-flex justify-content-between mx-4 pt-3'} sx={{ borderBottom: 1, borderColor: 'divider'  }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab sx={{paddingLeft: 1, textTransform:'capitalize'}} label="Chart" {...a11yProps(0)} />
                        <Tab sx={{ textTransform:'capitalize'}} label="Table" {...a11yProps(1)} />
                    </Tabs>
                    <div style={{width:36, height:36, minWidth:'max-content', paddingTop:5}}>
                        <div className={'downloadButtonCss cursor'}>
                            <i className='fas fa-download fs-6'
                               onClick={() => {
                                   downloadData('/service/media/home/fundSummary/download?date='+VALUATION_DATE,'', 'Download', getFileName('Summary-'))}}
                               style={{fontSize: 12}}/>
                        </div>
                    </div>
                </Box>
                <CustomTabPanel id={'tableTabPanel'}  value={tabValue} index={0}>
                    {(isEmpty(chartDataArray) || isEmpty(chartLabelsArray)) ?
                        <div>

                            <ChartRadioGroup handleRadioChange={handleRadioChange}
                                             chartType={fundListingState.chartType}
                                             isXSView={props.isXSView}
                                             isSMView={props.isSMView}/>
                    <div className='d-flex align-items-center justify-content-center mx-3' style={{minHeight:300}}>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={'No data found'}
                            fontSize={'md'}/>
                    </div>
                        </div>
                    :
                        getTableBarUI('chart')
                }
                     </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1} id={'tableTabPanel'}>
                    {isSearchApply ? <></>
                        :
                        getTableBarUI('table')
                    }
                </CustomTabPanel>
            </Box>
            </div>
        )
    };

    const renderFilterUI = () =>{
        const {keyword, isXSView} = props;

        return(
            <div className='px-3' style={{marginBottom:  isXSView? 8 :14}}>
                <div style={{width: isXSView ? '100%' : '50%'}}>
                    <DLInputTextField
                        id={'businessList-keywordSearch'}
                        label={''}
                        rows={1}
                        fullWidth
                        styleType={"search"}
                        placeholder={'Search...'}
                        value={keyword}
                        onChangeValue={(e) => onChangeKeywordFilter(e)}
                        isClearable={true}
                        inputHeight={'sm'}
                        marginBottom={"none"}
                        InputProps={{
                            endAdornment: (
                                keyword?
                                <i className="fa fa-remove m-2 cursor" onClick={()=>{onChangeKeywordFilter("")}} style={{color: appTheme.appColor.black}}/>
                                :
                                <i className={'fas fa-search pr-1'} style={{color: appTheme.appColor.black}}/>)
                        }}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {

                                document?.activeElement?.blur()
                            }
                        }}
                        disableHelperMessageHeight={true}
                    />
                </div>
            </div>
        )
    };

    return (
        <div className={'pb-2'}>
            <div className='fixedHeader bg-white px-3 py-2'>
                <div>
                    <DLText
                        id={'summary-by-country'}
                        type={'normal'}
                        text={'Funds'}
                        marginBottom={"none"}
                        fontWeight={'semi-bold'}
                        fontSize={'xl'}/>
                </div>
            </div>
            <div className='pb-2'>
            {renderFilterUI()}
            {renderDataListingUI()}
            </div>
        </div>
    )

}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    keyword: state.fundState.keyword,
    tabValue: state.fundState.tabValue,
    order: state.fundState.order,
    orderBy: state.fundState.orderBy,
    page: state.fundState.page,
    rowsPerPage: state.fundState.rowsPerPage
});

export default connect(mapStateToProps, {})(FundListing);
