import React from 'react';
import {InputAdornment, IconButton} from '@mui/material';
import {useTranslation} from "react-i18next";
import {CircularProgress} from "@mui/material";
import { GoogleLogin } from '@react-oauth/google';
import DLInputTextField from 'dlcomponent/components/DLInputTextField/DLInputTextField'
import DLText from 'dlcomponent/components/DLText/DLText'
import DLCheckbox from 'dlcomponent/components/DLCheckbox/DLCheckbox'
import DLButton from 'dlcomponent/components/DLButton/DLButton'
import appTheme from "../../../assets/appTheme";
import logo from "../../../assets/img/simplicity_logo.jpg";

const LoginView = (props) => {
    const {isLoginInProgress, formData, onChangeValue, onBlurInputText, submitForm, isMobileView, processGoogleLogin, isXSView} = props;
    const {email, emailError, password, passwordError, showPassword, rememberMe} = formData;
    const {t} = useTranslation();

    const getLabel = (apiProgress, label, color) => {
        if (apiProgress) {
            return (
                <>
                    {label + ' '}
                    <CircularProgress thickness={5}
                                      style={{
                                          height: "12px",
                                          width: "12px",
                                          marginLeft: "10px",
                                          color: color
                                      }}/>
                </>
            )
        }
        return label;
    };

    const onSubmitForm = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (!isLoginInProgress) {
            submitForm();
        }
    };

    const responseMessage = (response) => {
        // if (!isEmpty(response)) {
        //     console.log('responseGoogle response.tokenId inside if ', response);
        //
        // }
        processGoogleLogin(response);
    };
    const errorMessage = (error) => {
    };

    return (
        <div className= {isXSView ?'w-100 d-flex flex-column justify-content-center'
            :'w-100 d-flex flex-column align-items-center justify-content-center'}
             style={{backgroundColor: "#F0F0F0", height: '100vh'}}>

            <div className={isXSView ? "bg-white w-100 p-3 d-flex flex-column justify-content-center  align-items-center" :
                "panel bg-white p-3 d-flex flex-column justify-content-center align-items-center"}
                 style={{
                     minHeight:isXSView ? 502 : 600,
                     width: isXSView ? '100%' : "453px",
                 }}>
                <div className='mb-5 d-flex align-items-center'>
                    <img className='rounded-2 me-1'
                         src={logo}
                         alt={'logo'}
                         width={isMobileView ? '28px' : '38px'}
                         height={isMobileView ? '28px' : '35px'}/>
                    <div style={{paddingTop: 2}}>
                        <DLText id={'user-login-new-here'}
                                fontSize={"xxl"}
                                fontWeight={"bold"}
                                marginBottom={'none'}
                                customTextStyle={{lineHeight: 1}}
                                text={'Research'}/>
                    </div>
                </div>
                <div className='flex-column justify-content-between align-items-center d-flex w-100' style={{
                    padding: "34px 27px 34px 27px",
                }}>
                    <div style={{marginBottom:20}}>
                    <DLText id={'user-login-new-here'}
                            fontSize={"xl"}
                            fontWeight={"bold"}
                            customTextStyle={{lineHeight:1}}
                            text={'Sign In'}/>
                    </div>
                    <div id={'sign-in-div'}>
                        <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default LoginView;
