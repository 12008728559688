import React from 'react';
import SearchUi from "./SearchUi";


function SearchModalView(props) {
    const {onCloseModal, isModalOpen, isMobileView} = props;

    if (!isModalOpen){
        return <></>
    }

    return (
        <>
        <div className="d-flex justify-content-end w-100 mt-5">
            <div onClick={()=>{onCloseModal()}} style={{marginRight : (!isMobileView? "100px" : "20px")}}>
                <i className="fa fa-remove m-2 cursor"/>
            </div>
        </div>
    <SearchUi {...props}/>
    </>
    );
}

export default SearchModalView;