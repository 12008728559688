import React, {useEffect, useState} from 'react'
import DLLoader from "../../core/components/DLLoader/DLLoader";
import DLText from "dlcomponent/components/DLText/DLText";
import DLButton from "dlcomponent/components/DLButton/DLButton";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FundHouseChart from "./FundHouseChart";
import FundHouseTable from "./FundHouseTable";
import {getFundHouseSummary} from "../HomepageAction";
import ChartRadioGroup from "../../component-utils/ChartRadioGroup/ChartRadioGroup";
import CustomTabPanel from "../../component-utils/CustomTabPanel/CustomTabPanel";
import {downloadExcel} from "../../utils/downloadExcel";
import {withRouter} from "react-router-dom";
import {isEmpty} from "../../utils/validations";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



function FundHouse(props) {

    useEffect(() => {

        getFundHouseSummary(VALUATION_DATE).then((res) => {
            setIsLoading(false);
            if (res.success && !isEmpty(res.data?.data)) {
                const copiedArray = res.data.data.slice(0, 50);
                // setTableData(res.data.data)
                setTableData(copiedArray)
                setFundsGraph(copiedArray)
                // setFundsGraph(res.data.data);
            }
        })

    }, []);



    const [chartLabelsArray, setChartLabelsArray] = useState([]);
    const [chartDataArray, setChartDataArray] = useState([]);
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [tabValue, setTabValue] = useState(0);



    const setFundsGraph = (data) => {

        for (let i = 0; i < 20; i++) {

            let chartLabel = data[i].fund_house_name;
            let chartData = data[i].total_market_value;

            setChartLabelsArray(fundHouseNames => [...fundHouseNames, chartLabel]);
            setChartDataArray(marketValueInCrores => [...marketValueInCrores, chartData]);

        }
    };

    const handleClick = (evt, elements) => {
        if (elements.length > 0) {
            let clickedFundHouse = tableData[elements[0].index]
            props.history.push('/fund-houses/'+clickedFundHouse.fund_house_id)
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };


    const deepCopy = (obj) => {
        return JSON.parse(JSON.stringify(obj));
    }

    //order by descending
    const compareByMarketValueChangeMoM = (a, b) => {
        if (a.net_buying_selling < b.net_buying_selling) {
            return 1;
        }
        if (a.net_buying_selling > b.net_buying_selling) {
            return -1;
        }
        return 0;
    }

    const compareByMarketValueChangePercent = (a, b) => {
        if (a.net_buying_selling_percentage < b.net_buying_selling_percentage) {
            return 1;
        }
        if (a.net_buying_selling_percentage > b.net_buying_selling_percentage) {
            return -1;
        }
        return 0;
    }


    const handleRadioChange = (event) => {
        let chartType = event.target.value
        let newChartData = [];
        let newLabelData = [];
        if(chartType === 'marketValue'){
            for (let i = 0; i < 20; i++) {
                let labelData = tableData[i].fund_house_name;
                let chartData = tableData[i].total_market_value ;
                newChartData.push(chartData);
                newLabelData.push(labelData);
            }
        }else if(chartType === 'changeMoM'){
            let changeMoMData = deepCopy(tableData);
            changeMoMData.sort(compareByMarketValueChangeMoM);
            for (let i = 0; i < 20; i++) {
                let labelData = changeMoMData[i].fund_house_name;
                let chartData = changeMoMData[i].net_buying_selling;
                newChartData.push(chartData);
                newLabelData.push(labelData);
            }
        }else if(chartType === 'changePercent'){
            for (let i = 0; i < 20; i++) {
                let changeMoMPercentage = deepCopy(tableData);
                changeMoMPercentage.sort(compareByMarketValueChangePercent);
                let labelData = changeMoMPercentage[i].fund_house_name;
                let chartData = changeMoMPercentage[i].net_buying_selling_percentage;
                newChartData.push(chartData);
                newLabelData.push(labelData);
            }
        }
        setChartLabelsArray(newLabelData);
        setChartDataArray(newChartData);
    };




    if (isLoading) {
        return (
            <div className={'row px-2 h-100'}>
                <div className={"col-12 d-flex flex-column justify-content-center align-items-center mb-1"}>
                    <DLLoader type={'inline'} isVisible={true}/>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className='d-flex justify-content-between px-3 py-2'>
                <div>
                    <DLText
                        id={'summary-by-country'}
                        type={'normal'}
                        text={'Fund Houses'}
                        fontWeight={'semi-bold'}
                        fontSize={'md'}/>
                </div>
                {/*<DLButton*/}
                {/*    id={'download-excel'}*/}
                {/*    styleType={"outlined"}*/}
                {/*    borderType={'rounded'} buttonSize={'sm'}*/}
                {/*    sideMargin={"none"}*/}
                {/*    reduceWidth={true}*/}
                {/*    type={'primary'}*/}
                {/*    onClick={()=>downloadExcel(tableData)}*/}
                {/*    label={<i className='fas fa-download fs-6'/>}*/}
                {/*/>*/}
                <div className='d-flex align-items-center justify-content-center downloadButtonCss cursor'>
                    <i className='fas fa-download fs-6'
                       onClick={()=>downloadExcel(tableData)}
                       style={{fontSize: 12}}/>
                </div>

            </div>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Chart" {...a11yProps(0)} sx={{ textTransform:'capitalize'}} />
                        <Tab label="Table" {...a11yProps(1)} sx={{ textTransform:'capitalize'}}/>
                    </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={0}>
                    <ChartRadioGroup handleRadioChange={handleRadioChange}/>
                    <FundHouseChart yAxis={chartLabelsArray} xAxis={chartDataArray} handleClick={handleClick}/>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                    <FundHouseTable data={tableData}/>
                </CustomTabPanel>
            </Box>

        </div>
    )

}

export default withRouter(FundHouse);
