import React from 'react';
import {isEmpty} from "../../../utils/validations";
import {getDataArray, getTrimmedValue, numberWithCommas} from "../../../utils/helper";
import {fundConst} from "./fundConst";
import DLText from "dlcomponent/components/DLText/DLText";
import EChartDougnute from "../../../component-utils/EChart/EChartDougnute";
import EChartPieChart from "../../../component-utils/EChart/EChartPieChart";
import Sector from "../Funds/Sector/Sector"
import {filterAumValue, getColor} from "../../../utils/stocksHelper";
import moment from "moment";
import PopComponent from "../../../../src/component-utils/BarChartPopUp/PopComponent";
import LineBarChartMixed from "../../../component-utils/charts/LineBarChartMixed";

// TODO use lazy load for each component which is firing the api
function FundView(props) {

    const {
        fundIds,
        selectedFund,
        isSMView,
        isXSView,
        UIState,
        isXLView,
        isLGView,
        isMDView,
        handleOpenModal,
    } = props;


    const handleScroll = () => {
        const myDiv = document.getElementById('myDiv');
        const pageYOffset = myDiv?.scrollTop + 64;
        let sectionsHeight = 600;

        let section1Height; // to active nextsections when previous section is at end
        let section2Height;//mobile -400

        let section3Height; //mobile -400
        let section4Height;//mobile -400

        if (isXLView) {
            section1Height = sectionsHeight; // to active nextsections when previous section is at end
            section2Height = section1Height + 500;//mobile -400
            section3Height = section2Height + 650; //mobile -400
            section4Height = section3Height + 500;//mobile -400
        }
        if (isLGView) {
            section1Height = sectionsHeight; // to active nextsections when previous section is at end
            section2Height = section1Height + 600;//mobile -400
            section3Height = section2Height + 600; //mobile -400
            section4Height = section3Height + 500;//mobile -400
        }

        if (isMDView) {
            section1Height = 800; // to active nextsections when previous section is at end
            section2Height = section1Height + 600;//mobile -400
            section3Height = section2Height + 600; //mobile -400
            section4Height = section3Height + 500;//mobile -400
        }

        if (isSMView || isXSView) {
            section1Height = 1100; // to active nextsections when previous section is at end
            section2Height = section1Height + 600;//mobile -400
            section3Height = section2Height + 300; //mobile -400
            section4Height = section3Height + 500;//mobile -400
        }
        if (pageYOffset < section1Height) {
          //  setActiveSection('section1');
        } else if (pageYOffset < section2Height) {
            //setActiveSection('section2');
        } else if (pageYOffset < section3Height) {
           // setActiveSection('section3');
        } else if (pageYOffset < section4Height) {
           // setActiveSection('section4');
        }
    };


    const getFileName = (blockName) => {
        let date = new Date();
        return selectedFund?.fund_name + '-' + blockName + moment(date).format('YYYY-MM-DD')
    };

    const getPieData = (data) => {
        let listingData = data.slice(0, 9);
        let isListingIncludeOther = listingData.find((item) => item.name === 'Others');
        if (isEmpty(isListingIncludeOther)) {
            let others = data.find(item => item?.name === 'Others');
            listingData.push(others);
        }
        return listingData
    };

    const getLegendsForPieChart = (data, sliceMin, sliceMax) => {
        let listingData = getPieData(data);

        return listingData.slice(sliceMin, sliceMax).map((item, index) => {
            return (
                <div className='d-flex align-items-center' style={{minWidth: 'max-content'}}>
                    <div style={{paddingTop: 3}}>
                        <div style={{
                            width: '8px',
                            height: '8px',
                            borderRadius: 7,
                            backgroundColor: getColor(sliceMin + index), // You can set the background color as needed
                        }}/>
                    </div>
                    <div className='ps-1'>
                        <DLText text={getTrimmedValue(item?.name, 12)}
                                marginBottom={"none"}
                                id={'page-not-found-oops'}
                                type={"normal"}
                                fontSize={"xxs"}/>
                    </div>
                </div>
            )
        })
    };

    const getLegends = (data) => {
        return data.map((item, index) => {
            return (
                <div className=' d-flex align-items-center'>
                    <div style={{paddingTop: 3}}>
                        <div style={{
                            width: '8px',
                            height: '8px',
                            borderRadius: 7,
                            backgroundColor: getColor(index), // You can set the background color as needed
                        }}/>
                    </div>
                    <div className='ps-1' style={{minWidth: 'max-content'}}>
                        <DLText text={item.name}
                                marginBottom={"none"}
                                id={'page-not-found-oops'}
                                type={"normal"}
                                fontSize={"xxs"}/>
                    </div>
                </div>
            )
        })
    };

    const renderPieChartUI = () => {
        if (isSMView || isXSView) {
            return (
                <div className='d-flex flex-column align-items-center mb-3'>
                    <EChartPieChart {...props}
                                    data={getPieData(getDataArray(props.businessHouse, 'business_house', 'market_value'))}/>
                    <div className='d-flex flex-wrap text-wrap gap-2 justify-content-center'>
                        {getLegendsForPieChart(getDataArray(props.businessHouse, 'business_house', 'market_value'), 0, 10)}
                    </div>
                </div>
            )
        }

        return (
            <div className='d-flex flex-column align-items-center'>
                <EChartPieChart {...props}
                                data={getPieData(getDataArray(props.businessHouse, 'business_house', 'market_value'))}/>
                <div className='d-flex flex-wrap text-wrap gap-2 justify-content-center'>
                    {getLegendsForPieChart(getDataArray(props.businessHouse, 'business_house', 'market_value'), 0, 10)}
                </div>
            </div>
        )
    };

    const renderMobileTreemapView = () => {
        return (
            <div className='d-flex flex-column justify-content-between'
                 style={{flex: 5, alignItems: isXSView && 'center'}}>

                {/*<div className='panel px-3 pt-sm-3 pb-3 w-100 mb-sm-3' >*/}
                {/*    <DLText text={'Sectors'}*/}
                {/*            fontSize={'md'}*/}
                {/*            fontWeight={'semi-bold'}*/}
                {/*            marginBottom={'md'}/>*/}
                {/*    <EChartTreemap {...props} data={getDataArray(props.industry, 'industry', 'market_value')}/>*/}
                {/*</div>*/}
                <div className={isMDView ? 'd-flex flex-md-row flex-column w-100 px-3' : 'd-flex flex-md-row flex-column w-100'}>
                    <div className={(isXSView) ? 'p-3 w-100' : 'panel p-3 w-100 me-3 mb-3 mb-md-0'}>
                        <div className="d-flex flex-column h-100">
                            <DLText text={'Market Cap'}
                                    fontSize={'lg'}
                                    fontWeight={'semi-bold'}
                                    marginBottom={'sm'}/>
                            <div className="flex-grow-1">
                                {
                                    isEmpty(props.category) ?
                                        <div className='d-flex justify-content-center align-items-center h-100'>
                                            No data found
                                        </div>
                                        :
                                        <div className='d-flex  flex-column align-items-center'>

                                            <div className='d-flex justify-content-center'>
                                                <EChartDougnute {...props}
                                                                data={getDataArray(props.category, 'category', 'market_value')}/>
                                            </div>

                                            <div className='d-flex justify-content-center gap-2'>
                                                {getLegends(getDataArray(props.category, 'category', 'market_value'))}
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    {isXSView ? <div style={{height: '10px', backgroundColor: '#C5C5C5'}}/> : <></>}
                    <div className={(isXSView) ? 'p-3 w-100' : 'panel p-3 w-100'}
                         style={{paddingLeft: (isXSView) ? 0 : 26}}>
                        <div className="d-flex flex-column h-100">
                            <DLText text={'Business Houses'}
                                    fontSize={'lg'}
                                    fontWeight={'semi-bold'}
                                    marginBottom={'sm'}/>
                            <div className="flex-grow-1">
                                {
                                    isEmpty(props.businessHouse) ?
                                        <div className='d-flex justify-content-center align-items-center h-100'>
                                            No data found
                                        </div>
                                        :
                                        renderPieChartUI()
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderDesktopTreemapView = () => {

        return (
            <div className="d-flex" style={{gap:20, marginTop:20, marginBottom:20}}>
                    <div className="panel p-3" style={{flex:1}}>
                        <div className="d-flex flex-column h-100">
                            <DLText text={'Market Cap'} fontSize={'lg'} fontWeight={'semi-bold'} marginBottom={'sm'}/>

                            <div className="flex-grow-1 d-flex justify-content-center align-items-center">
                                {isEmpty(props.category) ? (
                                    'No data found'
                                ) : (
                                    <div className="d-flex flex-column align-items-center">
                                        <EChartDougnute {...props}
                                                        data={getDataArray(props.category, 'category', 'market_value')}/>

                                        <div className="d-flex justify-content-center flex-wrap gap-2">
                                            {getLegends(getDataArray(props.category, 'category', 'market_value'))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="panel p-3" style={{flex:1}}>
                        <div className="d-flex flex-column h-100">
                            <DLText text={'Business Houses'} fontSize={'lg'} fontWeight={'semi-bold'}
                                    marginBottom={'sm'}/>
                            <div className="flex-grow-1 d-flex justify-content-center align-items-center">
                                {isEmpty(props.businessHouse) ? (
                                    'No data found'
                                ) : (
                                    renderPieChartUI()
                                )}
                            </div>
                        </div>
                    </div>

            </div>

        );

    };

    const renderSummaryUI = () => {
        const {isMobileView, stocksCount, marketValue} = props;

        return (
            <div className={isMobileView ? 'd-flex flex-column w-100 pt-2' : 'd-flex flex-column w-100'} id={'section1'}>
                <div className={isXLView || isLGView ? "d-flex" : "px-3"}>
                    <div className='panel px-3 pt-sm-3 pt-0 w-100' style={{width: '60%'}}>
                        <div className='flex-shrink-1 w-100'>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={'Overview'}
                                fontWeight={'semi-bold'}
                                fontSize={'lg'}
                                marginBottom={'md'}
                            />
                            <div className='d-flex flex-column w-100 '>
                                <div className={isXLView ? 'd-flex flex-row' : 'd-flex flex-column'}
                                     style={{width: '100%'}}>
                                    <div className={isXLView ? 'd-flex flex-column mb-3' : 'd-flex flex-row mb-3' } style={{width:(isLGView || isMobileView) ? '100%' : '15%'}}>
                                        <div className={(isLGView || isMobileView) ? 'border-end py-1 h-100' : 'border-bottom py-3 pb-md-0'}
                                             style={{flex: (isLGView || isMobileView) ? 1 : '', height: isMobileView ? '100%' : '30%'}}>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={'AUM'}
                                                fontSize={'sm'}/>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={numberWithCommas(filterAumValue(marketValue))}
                                                fontWeight={'bold'}
                                                fontSize={'md'}/>
                                        </div>
                                        <div className={(isLGView || isMobileView) ? 'd-flex flex-column justify-content-end py-1 ps-3' : 'd-flex flex-column justify-content-end pt-3 pt-md-0'}
                                             style={{flex: (isLGView || isMobileView) ? 1 : '', height: "30%"}}>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={'Stocks'}
                                                fontSize={'sm'}/>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={numberWithCommas(stocksCount)}
                                                fontWeight={'bold'}
                                                fontSize={'md'}/>
                                        </div>
                                    </div>

                                    <div  style={{
                                        marginBottom:"20px",
                                        width: (isLGView || isMobileView) ? '100%' : '95%',
                                        paddingTop: (isLGView || isMobileView) ? '10px' : '0px'
                                    }}>
                                        <LineBarChartMixed {...props} isBarChartClickable={false}
                                                           hideMoneyflowExplorer={true}
                                                       getBarClick={getBarClick} isFromFund={true} hideCSS={true}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {isXSView ? <div style={{height: '10px', backgroundColor: '#C5C5C5'}}/> : <></>}
                <div className={isXLView ? "pt-0" : 'pt-3'}
                     style={{width: (isLGView || isMobileView) ? '100%' : '100%'}}>
                    {(isMobileView) ? renderMobileTreemapView() : renderDesktopTreemapView()}
                </div>



            </div>
        )
    };
    const getBarClick = (e) => {
        const {xAxis} = props;
        if (isEmpty(e)) {
            return;
        }
        let graphMonth = xAxis.find((item, index) => {
            return index === e;
        });

        handleOpenModal(graphMonth);

    };

    const renderDesktopView = () => {
        const {isMobileView} = props;
        return (
            <div>
                {renderSummaryUI()}

                {isXSView ? <div style={{height: '10px', backgroundColor: '#C5C5C5'}}/> : <></>}

                <div  id={'section3'} className={isMDView ? 'pb-4 pt-3 px-3' : 'pb-3'} style={{marginBottom: isMobileView ? '100px' : 0}} >
                    <Sector fundIds={fundIds} {...props}
                            fileName={getFileName('Sectors Summary-')}/>
                </div>
            </div>

        );
    };

    const renderUI = () => {
        return renderDesktopView();
    };

    if (UIState === fundConst.CONTENT_UNAVAILABLE) {
        return
    }


    return (<>

        <div>
            <div id={'myDiv'}
                 onScroll={() => {
                     handleScroll()
                 }}
            >

                <div>
                    {renderUI()}
                    {
                        <PopComponent {...props}/>
                    }
                </div>
            </div>
        </div>
    </>);
}

FundView.propTypes = {};

export default FundView;
