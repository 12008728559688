import { showSystemErrorBlock , showToastMessage} from "../core/actions/appActions";
import store from "../store";
import {getAuthorization, getAuthorizationToken, httpGet} from "../utils/httpHelper";
import {setFundHouseKeyword} from "../fundHouse/FundHouseReducer";
import { resetTabValue, setTabValue, resetFundHouseData, resetFundsData, setFundHouseListData, setFundKeyword, setFunds, setFundsList, resetOrder, resetOrderBy, resetPage, resetRowsPerPage, setOrder, setOrderBy, setPage, setRowsPerPage } from "./FundReducer";
import {isEmpty} from "../utils/validations";

// let base_url = 'http://localhost:10217'
let base_url = ''

export const getSummaryByStocks= (date,fundHouseId,fundId) => {
    let url = base_url+ '/service/fund/stocksSummary?date='+date+'&fundHouseId='+fundHouseId+"&fundId="+fundId;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getFundSummary= (date, applySkipCountLimitCount, skipCount, limitCount) => {
    let url = '/service/home/fundSummary?date='+date;

    return httpGet(url)
        .then(res=>{

            return res;

        })
};

export const getFundSummaryData= (date,fundHouseId, fundId) => {
    let url = base_url + '/service/fund/fundSummary?date='+date+'&fundHouseId='+fundHouseId+'&fundId='+fundId ;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getAllFunds= () => {
    let url = base_url+ '/service/fund/all' ;
    return httpGet(url)
        .then(res=>{
            return res;

        })
};

export const downloadData = (url, filename, action, fullFileName) => {

    let API_URL = process.env.REACT_APP_API_URL + url;

    let cloneData, flag;
    fetch(API_URL, {
        method: "GET",
        headers: {
            Accept: "application/*",
            Authorization: "Bearer " + getAuthorizationToken().token
        },
        responseType: 'arraybuffer',
        dataType: 'blob'
    }).then(async (response) => {
            cloneData = response.clone();
            await response.json()
                .then(res => {
                    // store.dispatch(setAppLoader(false));
                    if (res?.message) {
                        store.dispatch(showToastMessage('warning', res?.message))
                    }
                    if (res?.__error) {
                        store.dispatch(showToastMessage('warning', res?.__error))
                    }
                    flag = false;
                })
                .catch(() => {
                    flag = true;
                });
        }).then(result => {
        if (flag) {
            cloneData.blob().then(blob => {
                if (blob) {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    if (action === 'Download') {
                        a.download = fullFileName + ".xlsx";
                        a.click();
                    }
                    if (action === 'View') {
                        window.open(url,'SingleSecondaryWindowName');
                    }
                } else {
                    store.dispatch(showToastMessage('warning', result.message));
                }
            });
        }
    });
};

export const downloadDataByObject = (url, filename, action, fullFileName,data) => {

    let API_URL = process.env.REACT_APP_API_URL + url;
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...getAuthorization(),
    }

    let cloneData, flag;
    fetch(API_URL, {
        method: "POST",
        headers: headers,
        responseType: 'arraybuffer',
        dataType: 'blob',
        body: isEmpty(data) ? '' : JSON.stringify(data),
    }).then(async (response) => {
        cloneData = response.clone();
        await response.json()
            .then(res => {
                // store.dispatch(setAppLoader(false));
                if (res?.message) {
                    store.dispatch(showToastMessage('warning', res?.message))
                }
                if (res?.__error) {
                    store.dispatch(showToastMessage('warning', res?.__error))
                }
                if (res?.__error?.message) {
                    store.dispatch(showToastMessage('warning', res?.__error?.message))
                }
                flag = false;
            })
            .catch(() => {
                flag = true;
            });
    }).then(result => {
        if (flag) {
            cloneData.blob().then(blob => {
                if (blob) {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    if (action === 'Download') {
                        a.download = fullFileName + ".xlsx";
                        a.click();
                    }
                    if (action === 'View') {
                        window.open(url,'SingleSecondaryWindowName');
                    }
                } else {
                    store.dispatch(showToastMessage('warning', result.message));
                }
            });
        }
    });
};

export const setFundKeywordFilter = (keyword) =>{
    store.dispatch(setFundKeyword(keyword))
};

export const getFundDimension= (date,fundHouseId, fundId) => {
    let url = base_url+ '/service/fund/dimension/summary?fundHouseId='+fundHouseId+'&date='+date+ '&fundId='+fundId;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getSummaryByIndustry= (date,fundHouseId,fundId) => {
    let url = base_url+ '/service/fund/sectorSummary?date='+date+'&fundHouseId='+fundHouseId+"&fundId="+fundId ;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getNetBuyingSellingPopUpData= (date,fundHouseId,fundId) => {
    let url = base_url+ '/service/fund/lastMonthSummary?date='+date+'&fundHouseId='+fundHouseId+'&fundId='+fundId;
    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const setFundHouseList = (data) =>{
    console.log('data',data)
    store.dispatch(setFundHouseListData(data))
};

export const setFundList = (data) =>{
    store.dispatch(setFunds(data))
};

export const resetFund = (data) =>{
    store.dispatch(resetFundsData(data))
};

export const resetFundHouse = (data) =>{
    store.dispatch(resetFundHouseData(data))
};

export const setFundTableByOrder = (data) =>{
    store.dispatch(setOrder(data))
};

export const setFundTableByOrderBy = (data) =>{
    store.dispatch(setOrderBy(data))
};

export const setFundPage = (data) =>{
    store.dispatch(setPage(data))
};

export const setFundRowsPerPage = (data) =>{
    store.dispatch(setRowsPerPage(data))
};

export const setFundTabValue = (data) =>{
    store.dispatch(setTabValue(data))
};

export const resetFundTabValue = (data) =>{
    store.dispatch(resetTabValue(data))
};

export const resetFundPage = (data) =>{
    store.dispatch(resetPage(data))
};

export const resetFundRowsPerPage = (data) =>{
    store.dispatch(resetRowsPerPage(data))
};

export const resetFundTableByOrder = (name, value) =>{
    store.dispatch(resetOrder({name, value}))
};

export const resetFundTableByOrderBy = (name, value) =>{
    store.dispatch(resetOrderBy({name, value}))
};