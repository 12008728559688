import React, {Component} from 'react';
import PageNotAccessibleView from "./PageNotAccessibleView";
import {connect} from "react-redux";
import {setPrevLocation, setPrevRoutes} from "../../actions/appActions";
import {withRouter} from "react-router-dom";
import {userConst} from "../../../user/userConsts";
import {isEmpty} from "../../../utils/helperCommon";
import Redirect from "react-router-dom/es/Redirect";

class PageNotAccessible extends Component {

  handleClick = () =>{
    const {profile, userAuthStatus} = this.props;

    if (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED && isEmpty(profile?.SystemUser)){
       window.location.href= process.env.REACT_APP_API_URL+'/dashboard';
      return;
    }

    this.props.history.push('/dashboard');
  };

  render() {

    return (
      <PageNotAccessibleView
        {...this.props}
        handleClick={this.handleClick}
      />
    );
  }
}


PageNotAccessible.propTypes = {};
const mapStateToProps = (state) => ({
  userAuthStatus: state.userState.userAuthStatus,
  profile: state.userState.profile,
});
export default connect(mapStateToProps, {
  setPrevLocation,
  setPrevRoutes
})(withRouter(PageNotAccessible));
