import {createSlice} from '@reduxjs/toolkit'

const appSlice = createSlice({
    name: 'StockHoldingReducer',
    initialState: {
        keyword: "",
        orderBy : '',
        order : '' ,
        page:0,
        rowsPerPage:50,
        // viewBy: "HOLDING"
    },
    reducers: {
        setSearchKeywordValue(state, action) {
            state.keyword = action.payload
        },
        resetSearchKeywordValue(state, action) {
            state.keyword = {}
        },
        setOrderBy (state,action) {
            state.orderBy = action.payload;
        },
        setOrder (state,action) {
            state.order = action.payload;
        },
        setPage (state,action) {
            state.page = action.payload;
        },
        setRowsPerPage (state,action) {
            state.rowsPerPage = action.payload;
        },
        resetOrderBy(state,action){
            state.orderBy = ''
        },
        resetOrder(state,action){
            state.order = ''
        },
        resetPage(state,action){
            state.page = 0
        },
        resetRowsPerPage(state,action){
            state.rowsPerPage = 50
        },

        // setViewBy(state, action) {
        //     state.viewBy = action.payload
        // }
    }

});

export const {setSearchKeywordValue, resetSearchKeywordValue, setViewBy,setOrder,setOrderBy,resetOrder,resetOrderBy,setPage,setRowsPerPage,resetPage,resetRowsPerPage} = appSlice.actions;
export default appSlice.reducer