import {createSlice} from '@reduxjs/toolkit'

const appSlice = createSlice({
    name: 'WatchlistReducer',
    initialState: {
        watchlist:{
            id:'',
            name:'',
            type:''
        },
        selectedFundList : [],
        selectedStockList : []
    },
    reducers: {
        setWatchlistDataToStore(state,action){
            state.watchlist.id= action.payload.id,
            state.watchlist.name = action.payload.name,
            state.watchlist.type = action.payload.type
         },
        resetWatchlistObjData (state,action){
            state.watchlist = {}
        },
        setSelectedFundList(state,action){
            state.selectedFundList = action.payload.fundList
        },
        setSelectedStockList(state,action){
            state.selectedStockList = action.payload.stockList
        }
    }
});

export const {setWatchlistDataToStore,resetWatchlistObjData,setSelectedFundList,setSelectedStockList} = appSlice.actions;
export default appSlice.reducer