import React from 'react';
import DLModal from "dlcomponent/components/DLModal/DLModal";
import DLModalTitle from "dlcomponent/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "dlcomponent/components/DLModal/DLModalContainer/DLModalContainer";
import DLText from "dlcomponent/components/DLText/DLText";
import DLModalActions from "dlcomponent/components/DLModal/DLModalActions/DLModalActions";
import DLButton from "dlcomponent/components/DLButton/DLButton";

function ConfirmationModalView(props) {
    const {
        isConfirmationModalOpen, onChangeValue, handleDeleteWatchlist ,selectedWatchListObj
    } = props;

    return (
        <div>
            <DLModal // id={'clear-canvas'}
                     history={props.history}
                     onClose={() => {
                         onChangeValue('isConfirmationModalOpen', false)
                     }}
                     maxWidth={'xs'}
                     open={isConfirmationModalOpen}
            >
                <DLModalTitle id={'campaignDetail-sendTestModalTitle'}
                              onClose={() => {
                                  onChangeValue('isConfirmationModalOpen', false)
                              }}
                              title={"Confirmation"}
                              fontColor="var(--black)"

                />
                <DLModalContainer padding={'none'}>

                    <div className={'px-3 d-flex justify-content-center'} style={{marginTop: "20px",textAlign:"center"}}>
                        <DLText
                            text={`Are you sure you want to delete ${ selectedWatchListObj.name} Watchlist?`} />

                    </div>
                    <DLModalActions>
                        <div className={"d-flex justify-content-center mt-4 gap-3"}>

                                <DLButton
                                    id={'cancel-button'}
                                    styleType={"outlined"}
                                    borderType={'square'}
                                    buttonSize={'sm'}
                                    fontSize={'sm'}
                                    sideMargin={'none'}
                                    style={{
                                        border: "1px solid var(--black)",
                                        background: "var(--white)",
                                        color: "var(--black)",
                                        fontWeight:'500'
                                    }}
                                    onClick={() => {
                                        onChangeValue('isConfirmationModalOpen', false)
                                    }}
                                    label={"Cancel"}
                                />
                                <DLButton id={'delete-button'}
                                          buttonSize={'sm'}
                                          fontSize={'sm'}
                                          sideMargin={'none'}
                                          style={{
                                              background: "var(--error)",
                                              color: "var(--white)",
                                              fontWeight:'500'
                                          }}
                                          onClick={() => {
                                              setTimeout(() => {
                                                  handleDeleteWatchlist();
                                                  onChangeValue('isConfirmationModalOpen', false)

                                              }, 50)
                                             }}
                                          label={"Delete"}/>

                        </div>

                    </DLModalActions>
                </DLModalContainer>


            </DLModal>
        </div>
    );
}

export default ConfirmationModalView;


