import { configureStore } from '@reduxjs/toolkit'
import appReducer from "../src/core/reducers/appReducer";
import userReducer from "./user/userReducer";
import stockReducer from "./stocks/stockReducer";
import FundHouseReducer from "./fundHouse/FundHouseReducer";
import FundReducer from "./fund/FundReducer";
import IndustryReducer from "./industry/IndustryReducer";
import AdvanceSearchReducer from "./AdvanceSearch/AdvanceSearchReducer";
import AdvanceFundSearchReducer from "./advanceFundSearch/AdvanceFundSearchReducer";
import ExplorerReducer from "./explorer/ExplorerReducer"
import MoneyExplorerReducer from "./moneyFIleExplorer/MoneyExplorerReducer"
import WatchlistReducer from "./watchList/WatchlistReducer"
import StockHoldingReducer from "./stockHolding/StockHoldingReducer"


const store = configureStore({
  reducer: {
    appState: appReducer,
    userState: userReducer,
    stockState: stockReducer,
    fundHouseState: FundHouseReducer,
    fundState: FundReducer,
    IndustryState: IndustryReducer,
    AdvanceSearchState: AdvanceSearchReducer,
    AdvanceFundSearchState: AdvanceFundSearchReducer,
    ExplorerState: ExplorerReducer,
    MoneyExplorerState: MoneyExplorerReducer,
    WatchlistReducer: WatchlistReducer,
    StockHoldingReducer:StockHoldingReducer
  }
});
export default store;
