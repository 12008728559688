import React from 'react';
import DLModalTitle from "dlcomponent/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "dlcomponent/components/DLModal/DLModalContainer/DLModalContainer";
import DLModal from "dlcomponent/components/DLModal/DLModal";
import DLText from "dlcomponent/components/DLText/DLText";
import DLLoader from "../../core/components/DLLoader/DLLoader";
import {isEmpty} from "../../utils/validations";
import {getFixedValuesAfterDecimal, getNextRoundedNumber, numberWithCommas} from "../../utils/helper";
import EchartBarChart from "../../component-utils/EChart/EchartBarChart";
import {filterAumValue, getMarketValue, getMaxForBar, getMinForBar} from "../../utils/stocksHelper";

function MoneyFlowExplorerActionModalView(props) {
    const {onChangeValue,getRoundedNumber, isFilterAPIInProgress, isActionModalOpen, selectedLine, addLineFilter, drillDownBy,
        netBuyingSelingResult, selectedLineObj, createLineChartDataset, getDataOnly, getBarChartValues, UIState} = props;

    const renderUrl = () =>{

        switch (drillDownBy) {
            case 'fundHouse':
                props.history.push('/fund-houses/'+selectedLineObj.fund_house_id);
                break;
            case 'fund':
                props.history.push('/funds/'+selectedLineObj.fund_house_id+"/"+selectedLineObj.fund_id);
                break;
            case 'industry':
                props.history.push('/sectors/'+selectedLineObj.industry);
                break;
            case 'stock':
                props.history.push('/stocks/'+selectedLineObj.isin);
                break;
            case 'businessHouse':
                break;
            case 'marketCap':
                break;
            default:
        }
    };

    const renderBarChartUi = () =>{

        if (isFilterAPIInProgress){
            return (
                <div style={{minHeight:180, maxHeight:180}}>
                    <DLLoader type={"inline"} isVisible={true}/>
                </div>
            );
        }

        if (isEmpty(netBuyingSelingResult)){
            return (
                <div className='d-flex align-items-center justify-content-center' style={{minHeight: 180}}>
                    <DLText marginBottom={'none'}
                            isInline={true}
                            text={'No data found'}
                            fontSize={'sm'}/>
                </div>
            )
        }

        let formatedData = createLineChartDataset(netBuyingSelingResult);
        let values = Object.values(netBuyingSelingResult);
        let yAxisBar= formatedData.map((_, i) => {
            return getDataOnly(values.map(innerArray => innerArray[i]), "total_market_value_change_due_to_activity")
        });

        yAxisBar = yAxisBar.reduce((acc, currentArray) => acc.concat(currentArray), []);

        let minValue = getMinForBar(yAxisBar);

        let maxValue = getMaxForBar(yAxisBar);
        maxValue = getRoundedNumber(maxValue);
        if (minValue <= 0 && maxValue <= 0){
            minValue= -getRoundedNumber(-minValue)
        }else {
            minValue = minValue < 0 ? -maxValue : getNextRoundedNumber(minValue);
        }

        let netBuyingSellingValue = selectedLineObj?.net_buying_selling;

        const labels = isEmpty(netBuyingSelingResult) ? [] :Object.keys(netBuyingSelingResult);
        return (
            <div className=''>
                <div className='d-flex'>
                    <DLText id={'revenue-value'}
                            type={'normal'}
                            text={'Monthly Buying/Selling:'}
                            marginBottom={'none'}
                            fontWeight={'semi-bold'}
                            fontSize={'md'}/>
                            <div className='ps-1'>
                    <DLText id={'revenue-value'}
                            type={'normal'}
                            text={selectedLineObj?.net_buying_selling < 0 ? "("+numberWithCommas(getFixedValuesAfterDecimal(-netBuyingSellingValue, 0)) + ")": numberWithCommas(filterAumValue(netBuyingSellingValue))}
                            marginBottom={'none'}
                            fontColor={selectedLineObj?.net_buying_selling < 0 ? 'danger' : 'success'}
                            fontWeight={'semi-bold'}
                            fontSize={'md'}/>
                            </div>
                </div>
                <EchartBarChart {...props}
                                series={getBarChartValues(values, formatedData)}
                                yAxisMaxValue={maxValue}
                                yAxisMinValue={minValue}
                                height={'180px'}
                                showDownloadIcon={false}
                                gridLeft={50}
                                splitNumber={2}
                                xAxislabels={labels}/>

            </div>
        )
    };

    const renderAddAsFilter = () =>{
        const {fundHouses, funds, industries, stocks, businessHouses, marketCaps} = props.explorerFilter;

        switch (drillDownBy) {
            case 'fundHouse':
                if (!isEmpty(fundHouses)){
                    return
                }
                break;
            case 'fund':
                if (!isEmpty(funds)) {
                    return
                }
                break;
            case 'industry':
                if (!isEmpty(industries)) {
                    return
                }
                break;
            case 'stock':
                if (!isEmpty(stocks)) {
                    return
                }
                break;
            case 'businessHouse':
                if (!isEmpty(businessHouses)) {
                    return
                }
                break;
            case 'marketCap':
                if (!isEmpty(marketCaps)) {
                    return
                }
                break;
            default:
        }
        return(
            <div className='pt-2'>
                <DLText marginBottom={'none'}
                        text={'Add '+selectedLine+' as a filter'}
                        isClickable={true}
                        onClick={()=>addLineFilter()}
                        fontSize={'md'}/>

            </div>
        )
    }

    const renderModalUI = () =>{
        if (isEmpty(UIState)){
            return (
                <div  style={{minHeight:"300px"}}>
                    <DLLoader isVisible={true} type={'inline'}/>
                </div>
            )
        }

        return (
            <div className="pb-2" style={{minHeight:"300px"}}>
                <div className='pb-3' style={{borderBottom: '1px solid #c5c5c5'}}>
                    {renderBarChartUi()}
                </div>
                {(drillDownBy !== 'businessHouse' && drillDownBy !== 'marketCap') &&
                <div className='pt-3'>
                    <DLText marginBottom={'none'}
                            text={'View ' + selectedLine}
                            isClickable={true}
                            onClick={() => renderUrl()}
                            fontSize={'md'}/>
                </div>
                }
                {renderAddAsFilter()}
            </div>
        )
    }

    return (
        <div>
            <DLModal id={'clear-canvas'}
                     history={props.history}
                     maxWidth={"sm"}
                     onClose={() => {onChangeValue('isActionModalOpen', false)}}
                     open={isActionModalOpen}
            >
                <DLModalTitle id={'campaignDetail-sendTestModalTitle'}
                              onClose={() => {onChangeValue('isActionModalOpen', false)}}
                              title={selectedLine}
                              fontColor={"#333333"}
                />
                <DLModalContainer padding={'md'} id={'clear-canvas-container'} >

                    {renderModalUI()}
                </DLModalContainer>

            </DLModal>
        </div>
    );
}

MoneyFlowExplorerActionModalView.propTypes = {

};
export default MoneyFlowExplorerActionModalView;