import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {isEmpty} from "../../../utils/validations";
import {
    numberWithCommas
} from "../../../utils/helper";
import {stocksConst} from "../../../stocks/stocksConst";
import DLText from "dlcomponent/components/DLText/DLText";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
// TODO use lazy load for each component which is firing the api
function CountsUiView(props) {

    const {selectedStock, UIState, sceenerCode, handleOpenModal, requiredIsin} = props;

    const renderMobileView = () =>{
        const {fundHouseCount, fundSchemeCount, marketValue, stockName} = props;

        return (
            <div className=''>
                <div className="d-flex p-2" style={{minHeight:"40px", backgroundColor: ""}}>
                    <div className='' style={{flex:3}}>
                        <DLText marginBottom={'none'}
                                isInline={true}
                                text={!isEmpty(stockName)? stockName : ""}
                                onClick={() => {
                                    props.history.push("/stocks/" + requiredIsin)
                                }}
                                fontWeight={'semi-bold'}
                                isClickable={true}
                                fontColor={"#0073FF"}
                                fontSize={'xs'}/>
                    </div>
                    <div className='w-100' style={{flex:1}}>
                        <DLText marginBottom={'none'}
                                isInline={true}
                                text={numberWithCommas(fundHouseCount)}
                                fontWeight={'semi-bold'}
                                fontSize={'sm'}/>
                    </div>
                    <div className='w-100' style={{flex:1}}>
                        <DLText marginBottom={'none'}
                                isInline={true}
                                text={numberWithCommas(fundSchemeCount)}
                                fontWeight={'semi-bold'}
                                fontSize={'sm'}/>
                    </div>
                </div>
            </div>
        )
    };

    const getBarClick =(e)=>{
        const {xAxis,yAxisBar} = props;
        if(isEmpty(e)){
            return;
        }
        let graphMonth = xAxis.find((item, index)=>{
            return index === e;
        })
        handleOpenModal(graphMonth)
        // console.log("in bar click",e,xAxis, graphMonth,props)

    }

    const renderUI = () => {
            return renderMobileView();
    };

    if (UIState === stocksConst.CONTENT_UNAVAILABLE) {
        return
    }
    // if (isEmpty(selectedStock?.company_name)) {
    //     return <DLLoader type={'screen'} isVisible={true}/>
    // }

    const activeStyle = {
        fontWeight: 500,
        color: '#282828',
        textDecoration: 'underline',
        fontFamily: 'Poppins, sans-serif',

    };
    props.handleChange();

    return (
        <>
            <div className="bg-white ">
                {/*<div className="text-end">    <i className="fa fa-remove m-2 cursor" onClick={() => {*/}
                {/*    clearOption(buttonIdReference)*/}
                {/*}} style={{color: appTheme.appColor.black}}/>*/}
                {/*</div>*/}

                <div id={'myDiv'}>
                    <div className=''>
                        {renderUI()}
                    </div>
                </div>
            </div>
        </>
    );
}

CountsUiView.propTypes = {};

export default CountsUiView;
