import React, {useEffect, useState} from 'react'
import DLLoader from "../../../../core/components/DLLoader/DLLoader";
import DLText from "dlcomponent/components/DLText/DLText";
import DLButton from "dlcomponent/components/DLButton/DLButton";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SectorChart from "./SectorChart";
import SectorTable from "./SectorTable";
import CustomTabPanel from "../../../../component-utils/CustomTabPanel/CustomTabPanel"
import { downloadExcel} from  "../../../../utils/downloadExcel"
import ChartRadioGroup from "../../../../component-utils/ChartRadioGroup/ChartRadioGroup";
import {withRouter} from "react-router-dom";
import {getChartData, getDataArray, getFixedValuesAfterDecimal, getIndexArr, getTrimVal} from "../../../../utils/helper";
import {isEmpty} from "../../../../utils/validations";
import {downloadData} from "../../../../fund/FundAction";
import {getSectorSummary} from "../../../WatchListAction";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function Sector(props) {
    const {selectedWatchListObj} = props;
    const {fundHouseId,fundId,fundIds,selectedFundList} = props;
    const [chartLabelsArray, setChartLabelsArray] = useState([]);
    const [chartDataArray, setChartDataArray] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tabValue, setTabValue] = React.useState(0);
    const [percentage, setPercentage] = React.useState(0);
    const [tableHeader, setTableHeader] = useState([]);
    const [chartType, setChartType] = useState('marketValue');

    useEffect(() => {

        // getSummaryByIndustry(VALUATION_DATE, fundHouseId, encodeURIComponent(fundId)).then((res) => {
        //     setIsLoading(false);
        //     if (res.success && !isEmpty(res.data?.data)) {
        //         setTableData(res.data.data.slice(1, res.data.data.length));
        //         setTableHeader(res.data.data[0]);
        //         setIndustryGraph(res.data.data.slice(1, res.data.data.length));
        //     }
        // })
        // if(selectedWatchListObj.fundList){
        //     return
        // }

        getSectorSummary(fundIds).then((res) => {
            setIsLoading(false);
            if (res.success && !isEmpty(res.data?.data)) {
                setTableData(res.data.data.slice(1, res.data.data.length));
                setTableHeader(res.data.data[0]);
                setIndustryGraph(res.data.data.slice(1, res.data.data.length));
            }
        })

    }, [selectedWatchListObj.fundList]);

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleRadioChange = (event) => {
        const {isXSView, isLGView, isXLView,isMobileView} = props;
        let chartType =  event ;

        let newChartData = [];
        let newLabelData = [];
        let chartObj = getChartData(chartType, tableData, 'industry', isLGView, isXLView,isMobileView);
        setChartType(chartType);
        setPercentage(chartType === 'changePercent' || chartType === 'changeSellingPercent' );
        setChartLabelsArray(chartObj?.newLabelData);
        setChartDataArray(chartObj?.newChartData);
    };

    const handleClick = (evt, elements) => {
        let indexArr = getIndexArr(chartType, tableData);

        if (elements.length > 0) {
            let clickedIndustry = indexArr[elements[0].index];
            props.history.push('/sectors/'+clickedIndustry.industry)
        }

    };

    const setIndustryGraph = (data) => {
        const {isLGView, isXLView, isMobileView} = props;

        let length = data.length;
        if(data.length> 10){
            length= 10;
        }

        for (let i = 0; i < length; i++) {

            let industryName = getTrimVal(data[i].industry, isLGView, isXLView, isMobileView);
            let marketValue = getFixedValuesAfterDecimal(data[i].total_market_value, 2);

            setChartLabelsArray(industryNames => [...industryNames, industryName]);
            setChartDataArray(marketValueInCrores => [...marketValueInCrores, marketValue]);

        }
    };

    const getResult = (data) =>{
        if (isEmpty(data) || chartType === "marketValue"){
            return
        }

        if (data?.length >= 10){
            return(
                <div className='py-2 mx-3'>
                    Showing top 10 results
                </div>
            )
        }else {
            if (data.length === 1){
                return (
                    <div className='py-2 mx-3'>
                        Showing {data?.length} result
                    </div>
                )
            }else {
                return (
                    <div className='py-2 mx-3'>
                        Showing {data?.length} results
                    </div>
                )

            }
        }
    };

    const [isLoading, setIsLoading] = useState(true);

    const {fileName,isMobileView} = props;

    if (isLoading) {
        return (
            <div className={'row px-2 h-100'}>
                <div className={"col-12 d-flex flex-column justify-content-center align-items-center mb-1"}>
                    <DLLoader type={'inline'} isVisible={true}/>
                </div>
            </div>
        )
    }
    return (
        <div className='panel'  id='fund-sectors' style={{marginBottom : isMobileView ? '120px' : '10px'}}>
            <div className='d-flex justify-content-between px-3 pt-3'>
                <DLText
                    id={'summary-by-country'}
                    type={'normal'}
                    text={'Sectors'}
                    fontWeight={'semi-bold'}
                    fontSize={'lg'}/>
            </div>
            <Box sx={{ width: '100%' }}>
                <Box className='d-flex justify-content-between'
                     sx={{ borderBottom: 1, borderColor: 'divider', marginLeft: '20px' ,marginRight:'20px' }}>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Chart" {...a11yProps(0)} sx={{ textTransform:'capitalize'}}/>
                    </Tabs>

                </Box>
                <CustomTabPanel value={tabValue} index={0} id="tableTabPanel">
                    {(isEmpty(chartDataArray) || isEmpty(chartLabelsArray)) ?
                        <div className='d-flex flex-column'>

                            <ChartRadioGroup handleRadioChange={handleRadioChange}
                                             chartType={chartType}
                                             isXSView={props.isXSView}
                                             isSMView={props.isSMView}/>
                            <div className='d-flex align-items-center justify-content-center mx-3' style={{minHeight: 500}}>
                                <DLText
                                    id={'revenue-value'}
                                    type={'normal'}
                                    text={'No data found'}
                                    fontSize={'md'}/>
                            </div>
                        </div>
                        :
                        <>
                            <ChartRadioGroup handleRadioChange={handleRadioChange}
                                             chartType={chartType}
                                             isXSView={props.isXSView}
                                             isSMView={props.isSMView}/>
                            {getResult(chartDataArray)}
                            <SectorChart {...props}
                                           yAxis={chartLabelsArray}
                                           xAxis={chartDataArray}
                                           handleClick = {handleClick}
                                           chartType={chartType}
                                           percentage={percentage}
                                           isMobileView={props.isMobileView}/>

                        </>
                    }
                </CustomTabPanel>

            </Box>

        </div>
    )

}

export default withRouter(Sector);
