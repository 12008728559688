import {createSlice} from '@reduxjs/toolkit'

const appSlice = createSlice({
    name: 'FundReducer',
    initialState: {
        keyword: '',
        fundsData: [],
        fundHouseListingData:[],
        fundsLoaded: false,
        fundListingData: [],
        fundListingDataLoaded: true,
        allFundsData: [],
        orderBy : '',
        order : '' ,
        page:0,
        rowsPerPage:50,
        tabValue:0
    },
    reducers: {
        setFundKeyword(state, action) {
            state.keyword = action.payload;
        },
        setFunds(state, action) {
            state.fundListingData = action.payload;
            state.fundListingDataLoaded = true
        },
        setFundHouseListData(state, action) {
            state.fundHouseListingData = action.payload;
            state.fundHouseLoaded = true
        },
        setOrderBy (state,action) {
            state.orderBy = action.payload;
        },
        setOrder (state,action) {
            state.order = action.payload;
        },
        setPage (state,action) {
            state.page = action.payload;
        },
        setRowsPerPage (state,action) {
            state.rowsPerPage = action.payload;
        },
        setTabValue(state,action){
            state.tabValue = action.payload;
        },
        resetTabValue(state,action){
            state.tabValue = 0;
        },
        resetFundsData(state, action) {
            state.fundListingData = [];
        },
        resetFundHouseData(state, action) {
            state.fundHouseListingData = [];
        },
        setAllFundsData(state, action) {
            state.allFundsData = action.payload;
        },
        resetOrderBy(state,action){
            state.orderBy = ''
        },
        resetOrder(state,action){
            state.order = ''
        },
        resetPage(state,action){
            state.page = 0
        },
        resetRowsPerPage(state,action){
            state.rowsPerPage = 50
        },

    }
});

export const {resetTabValue, setTabValue,resetFundsData, resetFundHouseData, setFundHouseListData, setFunds, setFundsList, setFundKeyword, setAllFundsData,setOrder,setOrderBy,setPage,setRowsPerPage,resetOrder,resetOrderBy,resetPage,resetRowsPerPage} = appSlice.actions;
export default appSlice.reducer
