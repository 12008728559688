import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {resetAllFilterData, setAdvanceSearchFilter} from "../../AdvanceSearch/AdvanceSeachAction";
import {resetFund} from "../../fund/FundAction";
import UserWatchListView from "./UserWatchListView";
import {
    getWatchlistData,
    getWatchLists,
    saveCreatedWatchList,
    saveWatchlistData,
    setWatchlistData,
    resetWatchlistObj,
    setSelectedFunds,
    setSelectedStocks

} from "../WatchListAction";
import {isEmpty} from "../../utils/validations";
import {resetStocks} from "../../AdvanceSearch/AdvanceSeachAction";
import DLLoader from "../../core/components/DLLoader/DLLoader";
import {dataConst} from "./UserWatchListConstant";
import {showToastMessage} from "../../core/actions/appActions";
import store from "../../store";

function UserWatchList(props) {
    // const {watchlist } = props.watchlist;
    const [userWatchListState, setUserWatchListState] = useState({
        isFundModalOpen: false,
        isStockModalOpen: false,
        isConfirmationModalOpen: false,
        isActionModalOpen: false,
        isExpanded: false,
        watchlists: [],
        selectedWatchListObj: {
            createModalName: null,
            id: null,
            createModalType: 'FUNDS',
            fundCount: null,
            stockCount: null,
            stockList: [],
            fundList: [],
            fundIds: [],
            stockIds: [],
            copyModalName: null,
            copyModalType: null,
            popupAction: '',
            name: null,
            type: null
        },
        dropDownList: [],
        UIState: '',
        previousWatchlistFundIds: [],
        previousWatchlistStockIds: [],
        isDataLoading: '',
        nameErrorMeassage: '',
        isCopyButtonClicked: false,
        isDeleteButtonClicked: false,
        isCreateButtonClicked: false,
        isWatchlistCreated: false,
        isCreating: false,
    });

    const getWatchList = (id, type) => {
        getWatchlistData(id).then((res) => {
            if (res.success && !isEmpty(res.data?.data)) {
                const elementIds = res.data.data.map(item => item.element_id);
                if (type === 'FUNDS') {
                    setUserWatchListState((prev) => ({
                        ...prev,
                        previousWatchlistFundIds: elementIds,
                        UIState: dataConst.CONTENT_AVAILABLE
                    }));
                }
                if (type === 'STOCKS') {
                    setUserWatchListState((prev) => ({
                        ...prev,
                        previousWatchlistStockIds: elementIds,
                        UIState: dataConst.CONTENT_AVAILABLE
                    }));
                }
            } else {
                setUserWatchListState((prev) => ({
                    ...prev,
                    selectedWatchListObj: {
                        ...prev.selectedWatchListObj,
                        UIState: dataConst.CONTENT_UNAVAILABLE,
                        fundList: [],
                        stockList: [],
                        stockCount: null,
                        fundCount: null
                    },
                    previousWatchlistFundIds: [],
                    previousWatchlistStockIds: [],
                }));
            }
        });
    };


    useEffect( () => {
        // const {watchlist} = props;
        const action = props.history.action;
        getDropDownData(action);
        // if (props.history.action === "POP") {
        //     getWatchList(watchlist.id, watchlist.type)
        // }
    }, []);

    const getDropDownData = (action) => {
        const {watchlist} = props;
        let watchlistObj;

        getWatchLists()
            .then((res) => {
                if (res?.success && !isEmpty(res.data?.data)) {
                    resetFund();
                    resetStocks();
                    setAdvanceSearchFilter('funds', []);
                    const transformedData = res.data.data.map(item => ({
                        label: item.name,
                        value: item.watchlist_id,
                        type: item.type
                    }));

                    if (action === "POP" && (!isEmpty(watchlist.id))) {
                        setUserWatchListState((prev) => ({
                            ...prev,
                            watchlists: res.data.data,
                            selectedWatchListObj: {                             //picking last created watchlist as selected
                                ...prev.selectedWatchListObj,
                                name: watchlist.name,
                                id: watchlist.id,
                                type: watchlist.type,
                               // copyModalName: watchlist.name,
                                copyModalType: watchlist.type,
                                isCopyButtonClicked: false,
                                fundList: [],
                                stockList: []
                            },
                            dropDownList: transformedData,
                            // UIState: dataConst.CONTENT_AVAILABLE
                        }));
                        getWatchList(watchlist.id, watchlist.type)
                    } else {
                        watchlistObj = {
                            id: res.data.data[res.data.data.length - 1].watchlist_id,
                            name: res.data.data[res.data.data.length - 1].name,
                            type: res.data.data[res.data.data.length - 1].type
                        };

                        setUserWatchListState((prev) => ({
                            ...prev,
                            watchlists: res.data.data,
                            selectedWatchListObj: {                             //picking last created watchlist as selected
                                ...prev.selectedWatchListObj,
                                name: res.data.data[res.data.data.length - 1].name,
                                id: res.data.data[res.data.data.length - 1].watchlist_id,
                                type: res.data.data[res.data.data.length - 1].type,
                              //  copyModalName: res.data.data[res.data.data.length - 1].name,
                                copyModalType: res.data.data[res.data.data.length - 1].type,
                                isCopyButtonClicked: false,
                                fundList: [],
                                stockList: []
                            },
                            dropDownList: transformedData,
                            UIState: dataConst.CONTENT_AVAILABLE
                        }));
                        setWatchlistData(watchlistObj);
                        getWatchList(res.data.data[res.data.data.length - 1].watchlist_id, res.data.data[res.data.data.length - 1].type);
                    }

                }
                else {
                    setUserWatchListState((prev) => ({
                        ...prev,
                        UIState: dataConst.CONTENT_UNAVAILABLE
                    }));
                }
            })
    };


    const onChangeValue = (name, value) => {
        setUserWatchListState((prev) => ({
            ...prev,
            [name]: value,
            isCreateButtonClicked: true
        }));
    };

    const handleOpenConfirmationModal = (name, value) => {
        setUserWatchListState((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const AddFundsClick = (action, type) => {
        if (action === 'ADD_FUNDS') {
            setUserWatchListState((prev) => ({
                ...prev,
                [type]: true
            }));
        }
    };

    const handleExpandClick = () => {
        setUserWatchListState((prev) => ({
            ...prev,
            isExpanded: !prev.isExpanded,
        }));
    };

    const handleCreateOpenModal = (name, value) => {
        setUserWatchListState((prev) => ({
            ...prev,
            [name]: value,
            //  isWatchlistCreated: true,
            selectedWatchListObj: {
                ...prev.selectedWatchListObj,
                createdName: '',
                createModaltype: 'FUNDS'
            }

        }));
    };

    const handleOnSelect = (selectedWatchlist) => {

        if (selectedWatchlist?.value !== userWatchListState?.selectedWatchListObj?.id) {
            resetFund();
            setAdvanceSearchFilter('funds', []);
            resetStocks('stocks', []);
            resetWatchlistObj();
            let watchlistObj = {
                id: selectedWatchlist.value,
                name: selectedWatchlist.label,
                type: selectedWatchlist.type
            };
            setUserWatchListState((prev) => ({
                ...prev,
                selectedWatchListObj: {
                    ...prev.selectedWatchListObj,
                    name: selectedWatchlist.label,
                    id: selectedWatchlist.value,
                    type: selectedWatchlist.type,
                //    copyModalName: selectedWatchlist.label,
                    copyModalType: selectedWatchlist.type,
                    fundList: [],
                    stockList: [],
                },
                previousWatchlistFundIds: [],
                previousWatchlistStockIds: [],
                isWatchlistCreated: true,

                // previousWatchlistElementIds: [],
            }));
            getWatchList(selectedWatchlist.value, selectedWatchlist.type);
            setWatchlistData(watchlistObj);
        }
    };

    const closeModal = () => {
        setUserWatchListState((prev) => ({
            ...prev,
            isActionModalOpen: false,
            selectedWatchListObj: {
                ...prev.selectedWatchListObj,
                popupAction: ''
            },
        }));
    };

    const handleMenuOptionsSelect = (selectedOption) => {
        const selectedFunds = userWatchListState.selectedWatchListObj.fundList && userWatchListState.selectedWatchListObj.fundList.length > 0
            ? userWatchListState.selectedWatchListObj.fundList
                .filter((fund) => fund.isSelected)
                .map(({fund_id, fund_house_id, fund_name}) => ({
                    fund_id,
                    fund_house_id,
                    fund_name,
                }))
            : [];

        const selectedStocks = userWatchListState.selectedWatchListObj.stockList && userWatchListState.selectedWatchListObj.stockList.length > 0
            ? userWatchListState.selectedWatchListObj.stockList
                .filter((stock) => stock.isSelected)
                .map(({company_name, isin_code}) => ({
                    company_name,
                    isin_code,
                }))
            : [];

        if (selectedOption === 'Create') {
            setUserWatchListState((prev) => ({
                ...prev,
                isWatchlistCreated: true,
                selectedWatchListObj: {
                    ...prev.selectedWatchListObj,
                    createdName: '',
                    createModaltype: 'FUNDS'
                }
            }));
            handleCreateOpenModal('isActionModalOpen', true)
        }
        if (selectedOption === 'Copy') {
            setUserWatchListState((prev) => ({
                ...prev,
                isWatchlistSelected: true,
                isCopyButtonClicked: true,
                selectedWatchListObj: {
                    ...prev.selectedWatchListObj,
                    popupAction: 'COPY'
                },
            }));
            onChangeValue('isActionModalOpen', true)
        }
        if (selectedOption === 'Delete') {
            setUserWatchListState((prev) => ({
                ...prev,
                isWatchlistSelected: true,
                isDeleteButtonClicked: true
            }));
            handleOpenConfirmationModal('isConfirmationModalOpen', true)
        }
        if (selectedOption === 'MFE') {
            props.history.push("/money-flow-explorer", {
                selectedFund: userWatchListState.selectedWatchListObj.type === "FUNDS" ? selectedFunds : "",
                selectedStock: userWatchListState.selectedWatchListObj.type === "FUNDS" ? "" : selectedStocks,
                isFrom: "watchlist"
            });
            // props.history.push("/money-flow-explorer", userWatchListState.selectedWatchListObj.type === "FUNDS" ? {selectedFund: selectedFunds,filename:"watchlist"} : {selectedStock: selectedStocks,filename:"watchlist"});
        }
        if (selectedOption === 'AFS') {
            props.history.push("/advanced-fund-search", userWatchListState.selectedWatchListObj.type === "FUNDS" ? {selectedFund: selectedFunds} : {selectedStock: selectedStocks});
        }
        if (selectedOption === 'ASS') {
            props.history.push("/advanced-search", userWatchListState.selectedWatchListObj.type === "FUNDS" ? {selectedFund: selectedFunds} : {selectedStock: selectedStocks});
        }
    };

    if (isEmpty(userWatchListState.UIState)) {
        return <DLLoader type={"screen"} isVisible={true}/>
    }
    const resetWatchlistData = async () => {

        console.log("before---watchlist--", props.advanceSearchFilter);
        resetAllFilterData();
        resetWatchlistObj();
        resetFund();
        resetStocks('stocks', []);
        setAdvanceSearchFilter('funds', []);

    };

    const handleCreateWatchList = async (name, type) => {
        if (userWatchListState.dropDownList.length === 50) {
            const message = {message: "Maximum 50 watchlists are allowed"};
            store.dispatch(showToastMessage('warning', message.message))
        } else {
            if (isEmpty(name)) {
                setUserWatchListState((prev) => ({
                    ...prev,
                    nameErrorMeassage: "Enter Name"
                }))
            } else {
                setUserWatchListState((prev) => ({
                    ...prev,
                    isCreating:true
                }));
                await saveCreatedWatchList(name, type)
                    .then((res) => {
                        if (res.success) {
                            resetWatchlistData();
                            getDropDownData();
                            onChangeValue('isActionModalOpen', false);

                            setUserWatchListState((prev) => ({
                                ...prev,
                                selectedWatchListObj: {
                                    ...prev.selectedWatchListObj,
                                    UIState : dataConst.CONTENT_AVAILABLE,
                                    isWatchlistCreated: true,
                                    fundList: [],
                                    stockList: [],
                                    createModalName: '',
                                    previousWatchlistFundIds: [],
                                    previousWatchlistStockIds: [],
                                    isCopyButtonClicked: false,
                                },
                                isCreating:false
                            }));
                        }
                        else{
                            setUserWatchListState((prev) => ({
                                ...prev,
                                UIState: dataConst.CONTENT_AVAILABLE,
                                isCreating:false
                            }))
                        }
                    })
            }
        }
    };

    const getIds = (type, selectedValues) => {
        if (type === 'stocks') {
            return selectedValues.map(item => item.isin_code);
        } else {
            return selectedValues.map(item => item.fund_id);
        }
    };

    const setFilter = (type, selectedValues, idLabel, saveChanges) => {

        const {id} = userWatchListState.selectedWatchListObj;
        let selectedIds = getIds(type, selectedValues);
        if (selectedValues.length > 30) {
            let message;
            if (type === 'funds') {
                message = {message: "Maximum 30 funds allowed"}
            } else {
                message = {message: "Maximum 30 stocks allowed"}
            }

            store.dispatch(showToastMessage('warning', message.message))

        } else {
            if (!saveChanges) {
                if (type === 'stocks') {
                    let values = [];
                    selectedValues.forEach((item) => {
                        values.push(item[idLabel])
                    });
                    setUserWatchListState((prev) => ({
                        ...prev,
                        selectedWatchListObj: {
                            ...prev.selectedWatchListObj,
                            stockCount: selectedValues.length,
                            stockList: selectedValues,
                        },
                        previousWatchlistElementIds: []
                    }));
                    setAdvanceSearchFilter(type, values);
                }

                if (type === 'funds') {
                    let fundsValues = [];
                    selectedValues.forEach((item) => {
                        fundsValues.push(item[idLabel]);
                    });
                    setUserWatchListState((prev) => ({
                        ...prev,
                        selectedWatchListObj: {
                            ...prev.selectedWatchListObj,
                            fundCount: selectedValues.length,
                            // stockList:[],
                            fundList: selectedValues,
                        },
                        previousWatchlistElementIds: []
                    }));

                    setAdvanceSearchFilter(type, fundsValues);

                }
                return
            }

            saveWatchlistData(id, selectedIds).then((res) => {
                if (res.success) {
                    if (type === 'stocks') {
                        let values = [];
                        selectedValues.forEach((item) => {
                            values.push(item[idLabel])
                        });
                        setUserWatchListState((prev) => ({
                            ...prev,
                            selectedWatchListObj: {
                                ...prev.selectedWatchListObj,
                                stockCount: selectedValues.length,
                                fundList: [],
                                stockList: selectedValues,
                            },
                        }));
                        setAdvanceSearchFilter(type, values);
                    }

                    if (type === 'funds') {
                        let fundsValues = [];
                        selectedValues.forEach((item) => {
                            fundsValues.push(item[idLabel]);
                        });
                        setUserWatchListState((prev) => ({
                            ...prev,
                            selectedWatchListObj: {
                                ...prev.selectedWatchListObj,
                                fundCount: selectedValues.length,
                                stockList: [],
                                fundList: selectedValues,
                            },
                        }));
                        setAdvanceSearchFilter(type, fundsValues);
                    }
                }
            });
        }
    };

    const checkboxChange = (value, label) => {
        //  const {fundListingData, stockListingData} = props;

        if (label === 'funds') {
            const updatedItem = userWatchListState.selectedWatchListObj.fundList.find(item => (item.fund_house_id === value.fund_house_id && item.fund_id === value.fund_id));
            return updatedItem?.isSelected
        }
        if (label === 'stocks') {
            const updatedItem = userWatchListState.selectedWatchListObj.stockList.find(item => (item.isin_code === value.isin_code));
            return updatedItem?.isSelected
        }
    };

    const handleCheckboxChange = (value, label) => {
        if (label === 'funds') {
            const updatedFundArray = userWatchListState.selectedWatchListObj && userWatchListState.selectedWatchListObj.fundList
                ? userWatchListState.selectedWatchListObj.fundList.map(item =>
                    item.fund_house_id === value.fund_house_id && item.fund_id === value.fund_id
                        ? {...item, isSelected: !item.isSelected} // Toggle isSelected
                        : item
                )
                : [];
            setUserWatchListState((prev) => ({
                ...prev,
                selectedWatchListObj: {
                    ...prev.selectedWatchListObj,
                    fundList: updatedFundArray,
                    fundCount: updatedFundArray.length
                }
            }));

            setSelectedFunds(updatedFundArray);
        }

        if (label === 'stocks') {
            const updatedStockArray = userWatchListState.selectedWatchListObj.stockList.map(item =>
                item.isin_code === value.isin_code
                    ? {...item, isSelected: !item.isSelected} // Toggle isSelected
                    : item
            );
            setUserWatchListState((prev) => ({
                ...prev,
                selectedWatchListObj: {
                    ...prev.selectedWatchListObj,
                    stockList: updatedStockArray,
                    stockCount: updatedStockArray.length
                }
            }));
            setSelectedStocks(updatedStockArray);
        }
    };

    return (<UserWatchListView {...props}
                               {...userWatchListState}
                               setUserWatchListState={setUserWatchListState}
                               onChangeValue={onChangeValue}
                               handleOpenConfirmationModal={handleOpenConfirmationModal}
                               AddFundsClick={AddFundsClick}
                               handleCreateOpenModal={handleCreateOpenModal}
                               handleOnSelect={handleOnSelect}
                               handleMenuOptionsSelect={handleMenuOptionsSelect}
                               getDropDownData={getDropDownData}
                               handleCreateWatchList={handleCreateWatchList}
                               setFilter={setFilter}
                               closeModal={closeModal}
                               handleCheckboxChange={handleCheckboxChange}
                               checkboxChange={checkboxChange}
                               handleExpandClick={handleExpandClick}
        />
    );
}


UserWatchList.propTypes = {};
const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    fundHouseListingData: state.fundState.fundHouseListingData,
    fundListingData: state.fundState.fundListingData,
    stockListingData: state.AdvanceSearchState.stockListingData,
    advanceSearchFilter: state.AdvanceSearchState.advanceSearchFilter,
    watchlist: state.WatchlistReducer.watchlist,
});

export default connect(mapStateToProps, {resetAllFilterData})(UserWatchList);