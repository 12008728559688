import {httpPost} from "../utils/httpHelper";
import store from "../store";
import {
    resetExplorerAllFilter,
    resetExplorerBusinessHousesData,
    resetExplorerIndustryData, resetExplorerMarketCapData, resetExplorerStocksData,
    setDrillDownBy, setExplorerBusinessHouseKeyword, setExplorerBusinessHousesData, setExplorerFilterData,
    setExplorerFundHouseKeyword,
    setExplorerFundsKeyword, setExplorerIndustryData,
    setExplorerIndustryKeyword, setExplorerMarketCapData, setExplorerStockKeyword, setExplorerStocksData,
    setExplorerFilterDataObj
} from "./ExplorerReducer";
import {
    resetAllFilter,
    resetBusinessHousesData,
    resetIndustryData,
    resetMarketCapData, resetStocksData,
    setAdvanceBusinessHouseKeyword, setAdvanceFilter,
    setAdvanceFundHouseKeyword,
    setAdvanceIndustryKeyword, setAdvanceStockKeyword, setBusinessHouses,
    setFundsKeyword, setIndustry, setMarketCap, setStocks
} from "../AdvanceSearch/AdvanceSearchReducer";

export const getExplorerDrillDown = (data, date) => {
    const apiUrl = '/service/stocks/drilldown';

    return httpPost(apiUrl, data)
        .then(res=>{
            return res;
        })
};

export const setDrillDownValue = (value) =>{
    store.dispatch(setDrillDownBy(value))
};

export const setFundHouseKeywordFilter = (fundsKeyword) =>{
    store.dispatch(setExplorerFundHouseKeyword(fundsKeyword))
};

export const setFundsKeywordFilter = (fundsKeyword) =>{
    store.dispatch(setExplorerFundsKeyword(fundsKeyword))
};

export const setIndustryKeywordFilter = (fundsKeyword) =>{
    store.dispatch(setExplorerIndustryKeyword(fundsKeyword))
};

export const setBusinessHouseKeywordFilter = (fundsKeyword) =>{
    store.dispatch(setExplorerBusinessHouseKeyword(fundsKeyword))
};


export const setStocksKeywordFilter = (stockKeyword) =>{
    store.dispatch(setExplorerStockKeyword(stockKeyword))
};

export const setIndustryList = (data) =>{
    store.dispatch(setExplorerIndustryData(data))
};

export const setStocksList = (data) =>{
    store.dispatch(setExplorerStocksData(data))
};

export const setMarketCapList = (data) =>{
    store.dispatch(setExplorerMarketCapData(data))
};

export const setBusinessHousesList = (data) =>{
    store.dispatch(setExplorerBusinessHousesData(data))
};

export const setExplorerFilter = (name, value) =>{
    store.dispatch(setExplorerFilterData({name, value}))
};

export const setExplorerFilterObj = (value) =>{
    store.dispatch(setExplorerFilterDataObj(value))
};

export const resetMarketCap = (name, value) =>{
    store.dispatch(resetExplorerMarketCapData({name, value}))
};

export const resetIndustry = (name, value) =>{
    store.dispatch(resetExplorerIndustryData({name, value}))
};

export const resetStocks = (name, value) =>{
    store.dispatch(resetExplorerStocksData({name, value}))
};

export const resetBusinessHouses = (name, value) =>{
    store.dispatch(resetExplorerBusinessHousesData({name, value}))
};

export const resetAllFilterData = (name, value) =>{
    store.dispatch(resetExplorerAllFilter({name, value}))
};
