import {httpPost} from "../utils/httpHelper";
import store from "../store";
import {setAdvanceStockTablePage,resetOrder, resetOrderBy, resetPage, resetRowsPerPage, setOrder, setOrderBy, setPage, setRowsPerPage} from "./AdvanceFundSearchReducer";

export const getFundSearchResult= (data, date) => {
    const apiUrl = '/service/fund/search';

    return httpPost(apiUrl, data)
        .then(res=>{
            return res;
        })
};

export const setStocksTablePage = (stockKeyword) =>{
    store.dispatch(setAdvanceStockTablePage(stockKeyword))
};

export const setAdvanceFundTableByOrder = (data) =>{
    store.dispatch(setOrder(data))
};

export const setAdvanceFundTableByOrderBy = (data) =>{
    store.dispatch(setOrderBy(data))
};

export const setAdvanceFundPage = (data) =>{
    store.dispatch(setPage(data))
};

export const setAdvanceFundRowsPerPage = (data) =>{
    store.dispatch(setRowsPerPage(data))
};

export const resetAdvanceFundPage = (data) =>{
    store.dispatch(resetPage(data))
};

export const resetAdvanceFundRowsPerPage = (data) =>{
    store.dispatch(resetRowsPerPage(data))
};

export const resetAdvanceFundTableByOrder = (name, value) =>{
    store.dispatch(resetOrder({name, value}))
};

export const resetAdvanceFundTableByOrderBy = (name, value) =>{
    store.dispatch(resetOrderBy({name, value}))
};
