import {createSlice} from '@reduxjs/toolkit'

const appSlice = createSlice({
    name: 'AdvanceSearchReducer',
    initialState: {
        keyword: '',
        fundsKeyword: '',
        industryKeyword: '',
        fundHouseKeyword: '',
        businessHouseKeyword: '',
        stockKeyword: '',
        marketCapData:[],
        stockListingData:[],
        industryListingData:[],
        fundHouseListingData:[],
        fundListingData: [],
        fundListingDataLoaded:true,
        businessHouseListingData:[],
        allFundsData: [],
        marketCapDataLoaded: false,
        industryListingDataLoaded: false,
        advanceSearchFilter:{
            businessHouses: [],
            fundHouses: [],
            funds: [],
            marketCaps: [],
            industries: [],
            stocks: [],
            fundHouseForFund: [],
            fundsData:[],
            fundHousesData:[],
            stocksData:[],
            sectorsData:[]
        },
        orderBy : '',
        order : '' ,
        page:0,
        rowsPerPage:50,
        storedPage : 0
    },
    reducers: {
        setAdvanceKeyword(state, action) {
            state.keyword = action.payload;
        },
        setFundsKeyword(state, action) {
            state.fundsKeyword = action.payload;
        },
        setAdvanceIndustryKeyword(state, action) {
            state.industryKeyword = action.payload;
        },
        setAdvanceFundHouseKeyword(state, action) {
            state.fundHouseKeyword = action.payload;
        },
        setAdvanceBusinessHouseKeyword(state, action) {
            state.businessHouseKeyword = action.payload;
        },
        setAdvanceStockKeyword(state, action) {
            state.stockKeyword = action.payload;
        },
        setAdvanceFilter(state, action) {
            state.advanceSearchFilter = {
                ...state.advanceSearchFilter,
                [action.payload.name] : action.payload.value
            }
        },
        setMarketCap(state, action) {
            state.marketCapData = action.payload;
            state.marketCapDataLoaded = true
        },
        setIndustry(state, action) {
            state.industryListingData = action.payload;
            state.industryListingDataLoaded = true
        },
        setStocks(state, action) {
            state.stockListingData = action.payload;
            state.stockListingDataLoaded = true
        },
        setBusinessHouses(state, action) {
            state.businessHouseListingData = action.payload;
            state.businessHouseListingDataLoaded = true
        },
        setAdvanceStockTablePage(state, action) {
            state.storedPage = action.payload;
        },
        setOrderBy (state,action) {
           state.orderBy = action.payload;
        },
        setOrder (state,action) {
            state.order = action.payload;
        },
        setPage (state,action) {
            state.page = action.payload;
        },
        setRowsPerPage (state,action) {
            state.rowsPerPage = action.payload;
        },
        setAllFundsData(state, action) {
            state.allFundsData = action.payload;
        },
        setFunds(state, action) {
            console.log("action.payload",action.payload);
            state.fundListingData = action.payload;
            state.fundListingDataLoaded = true
        },
        setFundHouseListData(state, action) {
            state.fundHouseListingData = action.payload;
            state.fundHouseLoaded = true
        },
        resetMarketCapData(state, action) {
            state.marketCapData = [];
            state.advanceSearchFilter = {
                ...state.advanceSearchFilter,
                marketCaps : []
            };
        },
        resetIndustryData(state, action) {
            state.industryListingData = [];
            state.advanceSearchFilter = {
                ...state.advanceSearchFilter,
                industries : [],
                sectorsData: []
            };
        },
        resetStocksData(state, action) {
            state.stockListingData = [];
            state.advanceSearchFilter = {
                ...state.advanceSearchFilter,
                stocks : [],
                stocksData : []
            };
        },
        resetBusinessHousesData(state, action) {
            state.businessHouseListingData = [];
            state.advanceSearchFilter = {
                ...state.advanceSearchFilter,
                businessHouses : []
            };
        },
        resetFundsData(state, action) {
            state.fundListingData = [];
            state.advanceSearchFilter = {
                ...state.advanceSearchFilter,
                fundsData : []
            };
        },
        resetFundHouseData(state, action) {
            state.fundHouseListingData = [];
            state.advanceSearchFilter = {
                ...state.advanceSearchFilter,
                fundHousesData : []
            };
        },
        resetOrderBy(state,action){
            state.orderBy = ''
        },
        resetOrder(state,action){
            state.order = ''
        },
        resetPage(state,action){
            state.page = 0
        },
        resetRowsPerPage(state,action){
            state.rowsPerPage = 50
        },
        resetAllFilter(state, action) {
            state.keyword = '';
            state.businessHouseListingData = [];
            state.marketCapData = [],
            state.stockListingData = [],
            state.industryListingData = [],
            state.advanceSearchFilter = {
                ...state.advanceSearchFilter,
                businessHouses: [],
                fundHouses: [],
                funds: [],
                marketCaps: [],
                industries: [],
                stocks: [],
                fundHouseForFund: [],
                order:'',
                orderBy:'',
                fundHousesData:[],
                fundsData:[],
                stocksData:[],
                sectorsData:[]
            };
        },
    }
});

export const {resetAllFilter, setBusinessHouses, resetBusinessHousesData, setStocks,resetStocksData, resetIndustryData,
    resetMarketCapData, setIndustry, setMarketCap, setAdvanceFilter, setAdvanceKeyword, setFundsKeyword, setAdvanceFundHouseKeyword,
    setAdvanceIndustryKeyword, setAdvanceBusinessHouseKeyword, setAdvanceStockKeyword, setAdvanceStockTablePage,setOrder,setOrderBy,setFundHouseListData,
   setPage,setRowsPerPage, resetOrder,resetOrderBy,resetPage,resetRowsPerPage,resetFundsData,resetFundHouseData,setAllFundsData,setFunds} = appSlice.actions;
export default appSlice.reducer
