import store from "../store";
import {setKeyword} from "../stocks/stockReducer";
import {httpGet, httpPost} from "../utils/httpHelper";
import {
    resetAllFilter,
    resetBusinessHousesData,
    resetFilterData,
    resetIndustryData,
    resetMarketCapData,
    resetStocksData,
    setAdvanceBusinessHouseKeyword,
    setAdvanceFilter,
    setAdvanceFundHouseKeyword,
    setAdvanceIndustryKeyword,
    setAdvanceKeyword,
    setAdvanceStockKeyword,
    setAdvanceStockTablePage,
    setBusinessHouses,
    setAllFundsData,
    setFundsKeyword,
    setIndustry,
    setMarketCap,
    setOrder,
    setOrderBy,
    resetOrder,
    resetOrderBy,
    setPage,
    setRowsPerPage,
    resetPage,
    resetRowsPerPage,
    setStocks,
    resetFundsData,
    resetFundHouseData,
    setFundHouseListData,
    setFunds
} from "./AdvanceSearchReducer";
// import { setFunds} from "../fund/FundReducer";

export const getSearchResult= (data, date) => {
    const apiUrl = '/service/stocks/search';

    return httpPost(apiUrl, data)
        .then(res=>{
            return res;
        })
};

export const getBusinessHouses= (data, date) => {
    const apiUrl = '/service/stocks/all/businessHouses';

    return httpGet(apiUrl)
        .then(res=>{
            return res;
        })
};

export const setKeywordFilter = (keyword) =>{
    store.dispatch(setKeyword(keyword))
};

export const setFundsKeywordFilter = (fundsKeyword) =>{
    store.dispatch(setFundsKeyword(fundsKeyword))
};

export const setFundHouseKeywordFilter = (fundsKeyword) =>{
    store.dispatch(setAdvanceFundHouseKeyword(fundsKeyword))
};

export const setIndustryKeywordFilter = (fundsKeyword) =>{
    store.dispatch(setAdvanceIndustryKeyword(fundsKeyword))
};

export const setBusinessHouseKeywordFilter = (fundsKeyword) =>{
    store.dispatch(setAdvanceBusinessHouseKeyword(fundsKeyword))
};

export const setStocksKeywordFilter = (stockKeyword) =>{
    store.dispatch(setAdvanceStockKeyword(stockKeyword))
};

export const setStocksTablePage = (stockKeyword) =>{
    store.dispatch(setAdvanceStockTablePage(stockKeyword))
};

export const setAdvanceKeywordFilter = (keyword) =>{
    store.dispatch(setAdvanceKeyword(keyword))
};

export const setAdvanceSearchFilter = (name, value) =>{
    store.dispatch(setAdvanceFilter({name, value}))
};

export const setMarketCapList = (data) =>{
    store.dispatch(setMarketCap(data))
};

export const setIndustryList = (data) =>{
    store.dispatch(setIndustry(data))
};

export const setStocksList = (data) =>{
    store.dispatch(setStocks(data))
};

export const setAllFundData = (data) =>{
    store.dispatch(setAllFundsData(data))
};

export const setFundList = (data) =>{
    store.dispatch(setFunds(data))
};

export const setFundHouseList = (data) =>{
    console.log('data',data);
    store.dispatch(setFundHouseListData(data))
};

export const setBusinessHousesList = (data) =>{
    store.dispatch(setBusinessHouses(data))
};

export const setAdvanceStockTableByOrder = (data) =>{
    store.dispatch(setOrder(data))
};

export const setAdvanceStockTableByOrderBy = (data) =>{
    store.dispatch(setOrderBy(data))
};

export const setAdvanceStockPage = (data) =>{
    store.dispatch(setPage(data))
};

export const setAdvanceStockRowsPerPage = (data) =>{
    store.dispatch(setRowsPerPage(data))
};
export const resetAdvanceStockPage = (data) =>{
    store.dispatch(resetPage(data))
};

export const resetAdvanceStockRowsPerPage = (data) =>{
    store.dispatch(resetRowsPerPage(data))
};

export const resetAdvanceStockTableByOrder = (name, value) =>{
    store.dispatch(resetOrder({name, value}))
};

export const resetAdvanceStockTableByOrderBy = (name, value) =>{
    store.dispatch(resetOrderBy({name, value}))
};

export const resetMarketCap = (name, value) =>{
    store.dispatch(resetMarketCapData({name, value}))
};

export const resetIndustry = (name, value) =>{
    store.dispatch(resetIndustryData({name, value}))
};

export const resetStocks = (name, value) =>{
    store.dispatch(resetStocksData({name, value}))
};

export const resetBusinessHouses = (name, value) =>{
    store.dispatch(resetBusinessHousesData({name, value}))
};

export const resetAllFilterData = (name, value) =>{
    store.dispatch(resetAllFilter({name, value}))
};

export const resetFund = (data) =>{
    store.dispatch(resetFundsData(data))
};

export const resetFundHouse = (data) =>{
    store.dispatch(resetFundHouseData(data))
};
