import React, {useEffect, useState} from 'react';
import {isEmpty} from "../utils/validations";
import {advanceSearchConst} from "../AdvanceSearch/AdvanceSearchConst";
import {connect} from "react-redux";
import {
    resetAllFilterData,
    resetBusinessHouses,
    resetIndustry,
    resetMarketCap,
    resetStocks,
    setFundHouseKeywordFilter,
    setIndustryKeywordFilter,
    setExplorerFilter, setBusinessHouseKeywordFilter,
    setFundsKeywordFilter, setStocksKeywordFilter
} from "./ExplorerAction";
import {debounce} from "../utils/helper";
import {resetFund, resetFundHouse, setFundList} from "../fund/FundAction";
import DLLoader from "../core/components/DLLoader/DLLoader";
import ExplorerView from "./ExplorerView";
import {getExplorerDrillDown, setDrillDownValue} from "./ExplorerAction";
const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function Explorer(props) {
    const [advanceSearchState, setAdvanceSearchState] = useState({
        UIState: '',
        searchResult: [],
        tableHeader: [],
        businessHouses: [],
        funds: [],
        selectedFund: [],
        fund_fundHouse : [],
        industries: [],
        stocks: [],
        marketCaps: [],
        keyword: '',
        urlEndPoint: '',
        resultCount: 0,
        isDataUpdated:false,
        isFundModalOpen:false,
        isMarketCapModalOpen:false,
        isFundHouseModalOpen:false,
        isBusinessHouseModalOpen:false,
        isIndustryModalOpen:false,
        isStockFundModalOpen:false,
        selectedLine:{},
        chartType: 'Fund_Houses',
        isExternalFundHouseSelected: !!props.location?.state?.selectedFundHouse,
        externalFundHouseData: props.location?.state?.selectedFundHouse,

        isExternalFundSelected: !!props.location?.state?.selectedFund,
        externalFundData: props.location?.state?.selectedFund,

        isExternalSectorSelected: !!props.location?.state?.selectedSector,
        externalSectorData: props.location?.state?.selectedSector,

        isExternalClick :false,
        maxValuesOnChart: 5
    });

    const [tabValue, setTabValue] = useState(0);
    const [selectedDatasetIndex, setSelectedDatasetIndex] = useState(null);

    useEffect(()=>{
        if (props.history.action === "POP" || props.history.action === "REPLACE"){
            //backward navigation
        }else {
            resetListingData()
        }

        setTimeout(()=>{
            setAdvanceSearchState((prev) => ({
                ...prev,
                isDataUpdated: true,
                isExternalClick : checkIsExternalClick()
            }));

        },0);



        return () => {
            setFundsKeywordFilter('');
            setFundHouseKeywordFilter('');
            setIndustryKeywordFilter('');
            setBusinessHouseKeywordFilter('');
            setStocksKeywordFilter('')
        };
    },[]);

    useEffect(()=>{
        const {fundHouses, businessHouses, funds, marketCaps, industries, stocks, fundHouseForFund} = props.explorerFilter;
        const {drillDownBy} = props;
        if (advanceSearchState.isDataUpdated) {
            setAdvanceSearchState((prev) => ({
                ...prev,
                isFilterAPIInProgress: true,
            }));
            let data = {
                viewBy: drillDownBy,
                businessHouse: businessHouses,
                fundHouse: fundHouses,
                fund: funds,
                marketCap: marketCaps,
                industry: industries,
                stock: stocks,
                fundHouseForFund: fundHouseForFund
            };


            getResult(data);
        }
    },[props.explorerFilter, props?.keyword, props.drillDownBy, advanceSearchState.isDataUpdated]);

    const changePopUpState = (name) =>{
        setAdvanceSearchState((prev) => ({
            ...prev,
            [name]: false,
        }));
    };

    const setSelectedLineForPopUpComponent = (lastDrillDown, selectedLine) =>{

        if (lastDrillDown === 'fund' || isEmpty(lastDrillDown)){
            setAdvanceSearchState((prev)=>({
                ...prev,
                selectedFund: selectedLine,
                isExternalFundSelected: true
            }));
            setTimeout(()=>{
                setAdvanceSearchState((prev)=>({
                    ...prev,
                    selectedFund: [],
                    isExternalFundSelected: false
                }));
            },200)
        }
        if (lastDrillDown === 'industry'){
            setAdvanceSearchState((prev)=>({
                ...prev,
                selectedIndustry: selectedLine,
                isExternalSectorSelected: true
            }));
            setTimeout(()=>{
                setAdvanceSearchState((prev)=>({
                    ...prev,
                    selectedIndustry: [],
                    isExternalSectorSelected: false
                }));
            },200)
        }
        if (lastDrillDown === 'stock'){
            setAdvanceSearchState((prev)=>({
                ...prev,
                selectedStock: selectedLine,
                isExternalStockSelected: true
            }));
            setTimeout(()=>{
                setAdvanceSearchState((prev)=>({
                    ...prev,
                    selectedStock: [],
                    isExternalStockSelected: false
                }));
            },200)
        }
        if (lastDrillDown === 'businessHouse'){
            setAdvanceSearchState((prev)=>({
                ...prev,
                selectedBusinessHouse: selectedLine,
                isExternalBusinessHouseSelected: true
            }));
            setTimeout(()=>{
                setAdvanceSearchState((prev)=>({
                    ...prev,
                    selectedBusinessHouse: [],
                    isExternalBusinessHouseSelected: false
                }));
            },200)
        }
        if (lastDrillDown === 'marketCap'){
            setAdvanceSearchState((prev)=>({
                ...prev,
                selectedMarketCap: selectedLine,
                isExternalMarketCapSelected: true
            }));
            setTimeout(()=>{
                setAdvanceSearchState((prev)=>({
                    ...prev,
                    selectedMarketCap: [],
                    isExternalMarketCapSelected: false
                }));
            },100)
        }
    };

    const getData = (searchResult, drillDownBy) =>{
        if (isEmpty(searchResult) || isEmpty(drillDownBy)){
            return
        }
        let values = Object.values(searchResult);
        let selectedLine = values[0];
        let lastDrillDown = drillDownBy;
        setSelectedLineForPopUpComponent(lastDrillDown, selectedLine)

    };

    const getResult =debounce( (data, drillDownBy) =>{
        getExplorerDrillDown(data, VALUATION_DATE).then((res)=>{
            if (res.success){
                if (!isEmpty(res.data?.data)){
                    setAdvanceSearchState((prev)=>({
                        ...prev,
                        searchResult: res.data?.data,
                        isFilterAPIInProgress: false,
                        resultCount: checkLengthOfResult(res.data?.data)
                    }));
                    getData(res.data?.data, drillDownBy);
                }else {
                    setAdvanceSearchState((prev)=>({
                        ...prev,
                        searchResult: [],
                        tableHeader: [],
                        isFilterAPIInProgress: false
                    }));
                }
            }

            setAdvanceSearchState((prev)=>({
                ...prev,
                UIState: advanceSearchConst.CONTENT_AVAILABLE,
                isFilterAPIInProgress: false
            }));
        })
    },300);

    const onChangeKeywordFilter = (keyword) => {
     };

    const checkIsExternalClick = ()=>{
        const {isExternalSectorSelected, isExternalFundHouseSelected, isExternalFundSelected}= advanceSearchState;
        return isExternalSectorSelected || isExternalFundHouseSelected || isExternalFundSelected;
    };

    const handleChipClick = (action, type, listingArr) =>{
        const {isXSView, isSMView,  stockListingData, fundHouseListingData, businessHouseListingData, handleChipClick} = props;

        if (action === 'apply_filter'){
            setAdvanceSearchState((prev)=>({
                ...prev,
                [type]: true,
                isLineSelected: false,
                selectedLine: {},
            }));
            setSelectedDatasetIndex(null)
        }

        if (action === 'remove_filter'){
            switch (type) {
                case 'funds':
                    setSelectedLineForPopUpComponent('fund', []);
                    resetFund();
                    setExplorerFilter('funds', []);
                    break;
                case 'fundHouses':
                    resetFundHouse();
                    setExplorerFilter('fundHouses', []);
                    setSelectedLineForPopUpComponent('', []);
                    break;
                case 'marketCaps':
                    resetMarketCap();
                    removeDrillDownValue();
                    setSelectedLineForPopUpComponent('marketCap', []);
                    break;
                case 'industries':
                    resetIndustry();
                    removeDrillDownValue();
                    setSelectedLineForPopUpComponent('industry', []);
                    break;
                case 'stocks':
                    resetStocks();
                    removeDrillDownValue();
                    setSelectedLineForPopUpComponent('stock', []);
                    break;
                case 'businessHouses':
                    resetBusinessHouses();
                    removeDrillDownValue();
                    setSelectedLineForPopUpComponent('businessHouse', []);
                    break;
                default:
            }
            setAdvanceSearchState((prev)=>({
                ...prev,
                [type]: [],
                [listingArr]: []
            }));
        }
    };

    const onChangeValue = (name, value) =>{
        setAdvanceSearchState((prev)=>({
            ...prev,
            [name]: value
        }));
    };

    const getIdVariable = (drillDownBy) =>{

        if (isEmpty(drillDownBy)){
            return 'fund_id'
        }
        switch (drillDownBy) {
            case 'industry':
                return 'industry';
            case 'fund':
                return 'fund_id';
            case 'stock':
                return 'isin_code';
            case 'marketCap':
                return 'category';
            case 'businessHouse':
                return 'business_house'

        }
    };

    const setDrillDownBy = (drillDownName) =>{
         let {selectedLine}= advanceSearchState;
        const {searchResult}= advanceSearchState;
        setDrillDownValue(drillDownName);

        if (!isEmpty(selectedLine)){
            if (drillDownName==="fund"){
                setAdvanceSearchState((prev)=>({
                    ...prev,
                    selectedFund:  selectedLine,
                    isExternalFundSelected: true
                }));
                setFilter('funds', selectedLine, 'fund_id')
            }else {
                let lastDrillDown = drillDownName;
                if (lastDrillDown === 'industry'){
                    setFilter('industries', selectedLine, 'industry');
                    setAdvanceSearchState((prev)=>({
                        ...prev,
                        selectedIndustry: selectedLine,
                        isExternalSectorSelected: true
                    }));
                }
                if (lastDrillDown === 'stock'){
                    // setFilter('stocks', selectedLine, 'isin_code');
                    setAdvanceSearchState((prev)=>({
                        ...prev,
                        selectedStock: selectedLine,
                        isExternalStockSelected: true
                    }));
                }
                if (lastDrillDown === 'businessHouse'){
                    // setFilter('businessHouses', selectedLine, 'business_house');
                    setAdvanceSearchState((prev)=>({
                        ...prev,
                        selectedBusinessHouse: selectedLine,
                        isExternalBusinessHouseSelected: true
                    }));
                }
                if (lastDrillDown === 'marketCap'){
                    // setFilter('marketCaps', selectedLine, 'category');
                    setAdvanceSearchState((prev)=>({
                        ...prev,
                        selectedMarketCap: selectedLine,
                        isExternalMarketCapSelected: true
                    }));
                }
            }

            setAdvanceSearchState((prev)=>({
                ...prev,
                selectedLine: [],
                isLineSelected: false
            }));
        }
        setSelectedDatasetIndex(null);
    };

    const setFilter = (name, selectedValues, idVar) =>{
        const {isExternalClick}= advanceSearchState;
        let values = [];
        if (name === 'funds'){
            let fundsValues= [];
            let fundHouseForFund= [];

            selectedValues.forEach((item)=>{
                fundsValues.push(item[idVar]);
                fundHouseForFund.push(item['fund_house_id'])
            });

            setExplorerFilter(name, fundsValues);
            setExplorerFilter('fundHouseForFund', fundHouseForFund);

        }else {

            selectedValues.forEach((item)=>{
                values.push(item[idVar])
            });
            setExplorerFilter(name, values);
         }

        if(isExternalClick){
            setAdvanceSearchState((prev) => ({
                ...prev,
                externalSectorData: [],
                externalFundData: [],
                externalFundHouseData: []
            }));
        }
    };

    const removeDrillDownValue = (allDrillDownValuesRemove) =>{
        const {drillDownBy} = props;
    };

    const resetListingData = () =>{
        resetAllFilterData();
        resetFund();
        resetFundHouse();
        setDrillDownBy('fundHouse')
    };


    const checkIsDisableChip = (chipName) =>{
        const {searchResult} = advanceSearchState;

        if (isEmpty(searchResult)){
            return true
        }

        return true
    };

    const checkLengthOfResult = (searchResult) =>{

        const valuesFromObject = Object.values(searchResult) ;
        return valuesFromObject[valuesFromObject.length - 1]?.length
    };


    const getDataOnly = (dataObj, valueAttribute) =>{
        if (isEmpty(dataObj) || isEmpty(valueAttribute)){
            return []
        }

        return  dataObj.map((item)=>isEmpty(item) ? 0 : item[valueAttribute].toFixed(2))
    };

    const getNameOnly = (dataObj) =>{
        const {drillDownBy} = props;

        if (isEmpty(dataObj)){
            return
        }
        let name;

        switch (drillDownBy) {
            case '':
            case 'fundHouse':
                name = dataObj.find((item)=> item?.fund_house_name);
                return name?.fund_house_name;
            case 'fund':
                name = dataObj.find((item)=> item?.fund_name );
                return name?.fund_name;
            case 'industry':
                name = dataObj.find((item)=> item?.industry);
                return name?.industry;
            case 'stock':
                name = dataObj.find((item)=> item?.company_name);
                return name?.company_name;
            case 'businessHouse':
                name = dataObj.find((item)=> item?.business_house);
                return name?.business_house;
            case 'marketCap':
                name = dataObj.find((item)=> item?.category);
                return name?.category
        }

        return dataObj.find((item)=> item?.fund_name )
    };

    const getIdLabelForDrillDown = (viewBy) =>{
        let name;
        switch (viewBy) {
            case 'fundHouse':
                name = 'fund_house_name';
                break;
            case 'fund':
                name = 'fund_name';
                break;
            case 'industry':
                name = 'industry';
                break;
            case 'stock':
                name = 'company_name';
                break;
            case 'businessHouse':
                name = 'business_house';
                break;
            case 'marketCap':
                name = 'category';
                break;
            default:
        }
        return name
    };

    const arrangeOthersMonthData = (monthsArray, lastMonthArray, viewBy) =>{
        viewBy = getIdLabelForDrillDown(viewBy);
        for (let i=0 ;i < monthsArray.length - 1; i++){
            monthsArray[i].sort((a, b) => {
                let idA = a[viewBy];
                let idB = b[viewBy];
                // Find the index of the id in array 'c'
                let indexA = lastMonthArray.findIndex(item => item[viewBy] === idA);
                let indexB = lastMonthArray.findIndex(item => item[viewBy] === idB);

                return indexA - indexB;
            });
        }
        return monthsArray

    };

    const sortByAum = (monthsArray, drillDownBy) =>{
        //sort last month array by AUM
        let lastMonthArray = monthsArray[monthsArray.length - 1];
        lastMonthArray.sort((a, b) => b.total_market_value - a.total_market_value);

        //arrange other months data as per last month
         return arrangeOthersMonthData(monthsArray, lastMonthArray, drillDownBy)
    };

    const getMaxIterationValue = (viewBy, resultLength) =>{
        const {maxValuesOnChart} = advanceSearchState;
        const {fundHouses, funds, industries, stocks, businessHouses, marketCaps} = props.explorerFilter;
        let length = resultLength >= 5 ?  maxValuesOnChart : resultLength;
        switch (viewBy) {
            case 'fundHouse':
                if (!isEmpty(fundHouses)){
                    length = fundHouses.length;
                }
                break;
            case 'fund':
                if (!isEmpty(funds)) {
                    length = funds.length;
                }
                break;
            case 'industry':
                if (!isEmpty(industries)) {
                    length = industries.length;
                }
                break;
            case 'stock':
                if (!isEmpty(stocks)) {
                    length = stocks.length;
                }
                break;
            case 'businessHouse':
                if (!isEmpty(businessHouses)) {
                    length = businessHouses.length;
                }
                break;
            case 'marketCap':
                if (!isEmpty(marketCaps)) {
                    length = marketCaps.length;
                }
                break;
            default:
                length = 5
        }
        return length
    };

    const createLineChartDataset = (data) =>{
        const {drillDownBy} = props;

        if (isEmpty(data)){
            return []
        }

        Object.keys(data);
        let values = Object.values(data);

        const maxElementIndex = values.reduce((maxIndex, currentArray, currentIndex, array) => {
            if (currentArray.length > array[maxIndex].length) {
                return currentIndex;
            } else {
                return maxIndex;
            }
        }, 0);

        let array = [];
        values = sortByAum(values, drillDownBy);

        let maxIteration = getMaxIterationValue(drillDownBy, values[0].length);
        //Todo - remove bottom part once fixe in backend
        maxIteration = Object.values(data)[0].length < maxIteration ? Object.values(data)[0].length : maxIteration;

        for (let i=0 ;i < maxIteration; i++){
            let filteredValues = values.map(item =>  item[i]);
            array.push(filteredValues)
        }

        return array

    };

    const getLineChartValues= (initialData) =>{
        let formatedData = createLineChartDataset(initialData);
        const borderColor = ['#24FF00','#0073FF','#00E0FF','#FFC700','#DB00FF'];
        let values = Object.values(initialData);

        return formatedData.map((_, i) => ({
            name: getNameOnly(formatedData[i]),
            data: getDataOnly(values.map(innerArray => innerArray[i]), 'total_market_value'),
            backgroundColor: borderColor[i],
            borderWidth: (i=== selectedDatasetIndex) ? 6 : 2,
            pointRadius:(i=== selectedDatasetIndex) ? 8 : 4,
            lineTension: 0.3,
            type:'line',
            pointHoverRadius: (i=== selectedDatasetIndex) ? 10 : 6,
        }));
    };

    const handleLineClick = (params) => {
        const clickedLine = params.seriesName;
        const { offsetX, offsetY } = params.event;
        const {fundHouses, funds, industries, stocks, businessHouses, marketCaps} = props.explorerFilter;
        const {drillDownBy} = props;
        //check view and check filter related to that view is empty or not
        //if filter is empty then show add as a filter option
        switch (drillDownBy) {
            case 'fundHouse':
                if (!isEmpty(fundHouses)){
                    return
                }
                break;
            case 'fund':
                if (!isEmpty(funds)) {
                    return
                }
                break;
            case 'industry':
                if (!isEmpty(industries)) {
                    return
                }
                break;
            case 'stock':
                if (!isEmpty(stocks)) {
                    return
                }
                break;
            case 'businessHouse':
                if (!isEmpty(businessHouses)) {
                    return
                }
                break;
            case 'marketCap':
                if (!isEmpty(marketCaps)) {
                    return
                }
                break;
            default:
        }

        setAdvanceSearchState((prev)=>({
            ...prev,
            selectedLine : clickedLine,
            menuVisible: true,
            menuPosition: { x: offsetX, y:  250 + offsetY }
        }));
     };

    const handleLegendSelectChanged = (params) => {
        const selectedLine = params.name;
        const {drillDownBy} = props;
        const {fundHouses, funds, industries, stocks, businessHouses, marketCaps} = props.explorerFilter;
        //check view and check filter related to that view is empty or not
        //if filter is empty then show add as a filter option

        switch (drillDownBy) {
            case 'fundHouse':
                if (!isEmpty(fundHouses)){
                    return
                }
                break;
            case 'fund':
                if (!isEmpty(funds)) {
                    return
                }
                break;
            case 'industry':
                if (!isEmpty(industries)) {
                    return
                }
                break;
            case 'stock':
                if (!isEmpty(stocks)) {
                    return
                }
                break;
            case 'businessHouse':
                if (!!isEmpty(businessHouses)) {
                    return
                }
                break;
            case 'marketCap':
                if (!isEmpty(marketCaps)) {
                    return
                }
                break;
            default:
        }
        setAdvanceSearchState((prev)=>({
            ...prev,
            selectedLine : selectedLine,
            menuVisible: true,
            menuPosition: { x: 300, y:  400 }
        }));
    };
    const closeLineSelectedModal = (name) => {
        setAdvanceSearchState((prev)=>({
            ...prev,
            selectedLine : {},
            [name]: false
        }));
    };

    const handleViewChange = (drillDownName) => {
        if(drillDownName === props.drillDownBy){
            // setDrillDownValue("");
        }else {
            setAdvanceSearchState((prev) => ({
                ...prev,
                isFilterAPIInProgress: true,
            }));
            setDrillDownValue(drillDownName);
        }
    };

    const addLineFilter = () =>{
        let {selectedLine, searchResult} = advanceSearchState;
        const {drillDownBy} = props;
        let values;
        switch (drillDownBy) {
            case 'fundHouse':
                values = Object.values(searchResult);
                selectedLine = values[0].find(item=> item.fund_house_name === selectedLine);
                setFilter('fundHouses', [selectedLine], 'fund_house_id');
                setAdvanceSearchState((prev) => ({
                    ...prev,
                    isExternalFundHouseSelected: true,
                    externalFundHouseData: [selectedLine],
                    selectedLine : [],
                    menuVisible: false,
                    menuPosition: {}
                }));

                setTimeout(()=>{
                    setAdvanceSearchState((prev) => ({
                        ...prev,
                        isExternalFundHouseSelected: false,
                        externalFundHouseData: [],
                    }));
                },100);
                break;
            case 'fund':
                values = Object.values(searchResult);
                selectedLine = values[0].find(item=> item.fund_name === selectedLine);
                setFilter('funds', [selectedLine], 'fund_id');
                setAdvanceSearchState((prev) => ({
                    ...prev,
                    isExternalFundSelected: true,
                    selectedFund: [selectedLine],
                    selectedLine : [],
                    menuVisible: false,
                    menuPosition: {}
                }));
                setTimeout(()=>{
                    setAdvanceSearchState((prev) => ({
                        ...prev,
                        isExternalFundSelected: false,
                        selectedFund: [],
                    }));
                },100);
                break;
            case 'industry':
                values = Object.values(searchResult);
                selectedLine = values[0].find(item=> item.industry === selectedLine);
                setFilter('industries', [selectedLine], 'industry');
                setAdvanceSearchState((prev) => ({
                    ...prev,
                    isExternalSectorSelected: true,
                    selectedIndustry: [selectedLine],
                    selectedLine : [],
                    menuVisible: false,
                    menuPosition: {}
                }));
                setTimeout(()=>{
                    setAdvanceSearchState((prev) => ({
                        ...prev,
                        isExternalSectorSelected: false,
                        selectedIndustry: [],
                    }));
                },100);
                break;
            case 'stock':
                values = Object.values(searchResult);
                selectedLine = values[0].find(item=> item.company_name === selectedLine);
                setFilter('stocks', [selectedLine], 'isin');
                setAdvanceSearchState((prev) => ({
                    ...prev,
                    isExternalStockSelected: true,
                    selectedStock: [selectedLine],
                    selectedLine : [],
                    menuVisible: false,
                    menuPosition: {}
                }));
                setTimeout(()=>{
                    setAdvanceSearchState((prev) => ({
                        ...prev,
                        isExternalStockSelected: false,
                        selectedStock: [],
                    }));
                },100);
                break;
            case 'businessHouse':
                values = Object.values(searchResult);
                selectedLine = values[0].find(item=> item.business_house === selectedLine);
                setFilter('businessHouses', [selectedLine], 'business_house');
                setAdvanceSearchState((prev) => ({
                    ...prev,
                    isExternalBusinessHouseSelected: true,
                    selectedBusinessHouse: [selectedLine],
                    selectedLine : [],
                    menuVisible: false,
                    menuPosition: {}
                }));
                setTimeout(()=>{
                    setAdvanceSearchState((prev) => ({
                        ...prev,
                        isExternalBusinessHouseSelected: false,
                        selectedBusinessHouse: [],
                    }));
                },100);
                break;
            case 'marketCap':
                values = Object.values(searchResult);
                selectedLine = values[0].find(item=> item.category === selectedLine);
                setFilter('marketCaps', [selectedLine], 'category');
                setAdvanceSearchState((prev) => ({
                    ...prev,
                    isExternalMarketCapSelected: true,
                    selectedMarketCap: selectedLine,
                    selectedLine : [],
                    menuVisible: false,
                    menuPosition: {}
                }));
                setTimeout(()=>{
                    setAdvanceSearchState((prev) => ({
                        ...prev,
                        isExternalMarketCapSelected: false,
                        selectedMarketCap: [],
                    }));
                },100);
                break;
            default:
        }

    };

    if (isEmpty(advanceSearchState?.UIState)){
        return <DLLoader type={"screen"} isVisible={true}/>
    }

    return (
        <ExplorerView {...advanceSearchState}
                      {...props}
                      selectedDatasetIndex={selectedDatasetIndex}
                      handleChipClick={handleChipClick}
                      onChangeValue={onChangeValue}
                      setFilter={setFilter}
                      resetListingData={resetListingData}
                      checkIsDisableChip={checkIsDisableChip}
                      onChangeKeywordFilter={onChangeKeywordFilter}
                      removeDrillDownValue={removeDrillDownValue}
                      setDrillDownBy={setDrillDownBy}
                      handleLineClick={handleLineClick}
                      closeLineSelectedModal={closeLineSelectedModal}
                      handleLegendSelectChanged={handleLegendSelectChanged}
                      getDataOnly={getDataOnly}
                      getNameOnly={getNameOnly}
                      getLineChartValues={getLineChartValues}
                      createLineChartDataset={createLineChartDataset}
                      changePopUpState={changePopUpState}
                      handleViewChange={handleViewChange}
                      getMaxIterationValue={getMaxIterationValue}
                      addLineFilter={addLineFilter}
        />
    );
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    explorerFilter: state.ExplorerState.explorerFilter,
    fundHouseListingData: state.fundState.fundHouseListingData,
    fundListingData: state.fundState.fundListingData,
    marketCapData: state.ExplorerState.marketCapData,
    industryListingData: state.ExplorerState.industryListingData,
    stockListingData: state.ExplorerState.stockListingData,
    businessHouseListingData: state.ExplorerState.businessHouseListingData,
    drillDownBy: state.ExplorerState.drillDownBy,
});

export default connect(mapStateToProps, {})(Explorer);
