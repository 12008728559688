import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {homepageConst} from "./homepageConst";
import DLLoader from "../core/components/DLLoader/DLLoader";
import HomepageView from "./HomepageView";
import {getIndustryMarketCapData, getMonthlyTrendData, getSummaryData} from "./HomepageAction";
import {isEmpty} from "../utils/validations";
import appTheme from "../assets/appTheme";
import {filterNetBuyingSellingValue, getIndexArr} from "../utils/helper";


function HomePage(props) {
    const [UIState, setUIState] = useState();
    const [homePageState, setHomePageState] = useState({
        aum:"",
        fundHouseCount: "",
        fundcount: "",
        stockCount: "",

        xAxis: [],
        yAxisBar: [],
        yAxisLine: [],
        barColor: [],

        category: [],
        businessHouse: [],
        industry: [],
    })

    useEffect(()=>{

        getSummaryData().then((res)=>{

            if(res?.success){
                if(isEmpty(res?.data?.data)){
                    return;
                }
                setHomePageState((prevState)=>({
                    ...prevState,
                    fundHouseCount : res?.data?.data[0]?.fundhousecount ,
                    fundcount: res?.data?.data[0]?.fundcount,
                    stockCount: res?.data?.data[0]?.stockcount
                }))
            }
        })

        getMonthlyTrendData().then((res)=>{

            if(res?.success){
                if(isEmpty(res?.data?.data)){
                    return;
                }
                setBarLineChartData(res?.data)
                let resLastIndex = res?.data?.data?.length - 1 ;

                setHomePageState((prevState)=>({
                    ...prevState,
                    aum : res?.data?.data[resLastIndex]?.total_market_value.toFixed(0) ,
                    // stockCount: res.data.data[0].stockcount
                }))
            }
        })

        getIndustryMarketCapData().then((res)=>{

            if(res?.success){
                if(isEmpty(res?.data?.data)){
                    return;
                }
                setHomePageState((prevState)=>({
                    ...prevState,
                    category: res?.data?.data?.category,
                    businessHouse: res?.data?.data?.businessHouse,
                    industry: res?.data?.data?.industry,
                }))
            }
        })
    },[])

    const setBarLineChartData = (chartData) =>{
        //console.log("chart data function----",chartData)
        let xAxis = [];
        let yAxisBar = [];
        let yAxisLine = [];
        let barColor = [];
        if (isEmpty(chartData)){
            return
        }

        chartData?.data.forEach((item)=>{
            xAxis.push(item?.month);
            yAxisBar.push(filterNetBuyingSellingValue(item?.total_market_value_change_due_to_activity));
            yAxisLine.push((item?.total_market_value)?.toFixed(2));
            if (item?.total_market_value_change_due_to_activity >=0){
                barColor.push(appTheme.appColor.success)
            }else {
                barColor.push(appTheme.appColor.danger)
            }

        });

        setHomePageState((prevState)=>({
            ...prevState,
            xAxis: xAxis,
            yAxisBar: yAxisBar,
            barColor: barColor,
            yAxisLine: yAxisLine
        }))
    };

    const handleChartClick = (params) => {
        const clickedLine = params.name;

        if (clickedLine === 'Others'){
            return;
        }

        props.history.push('/sectors/' + clickedLine)

    };

    const refresh = () => {
        window.location.reload(true);
    };

    if (UIState === '') {
        return (
            <DLLoader type={'screen'} isVisible={true}/>
        );
    }

    return (
        <HomepageView
            {...props}
            handleChartClick = {handleChartClick}
            {...homePageState}
            refresh={refresh}
        />
    );
}

HomePage.propTypes = {};
const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView
});

export default connect(mapStateToProps, {})(HomePage);
