import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import DLLoader from "../core/components/DLLoader/DLLoader";
import StockHoldingView from "./StockHoldingView";
import {resetFund, resetFundHouse} from "../fund/FundAction";
import {
    resetAllFilterData,
    resetBusinessHouses,
    resetIndustry,
    resetMarketCap,
    resetStocks,
    setBusinessHouseKeywordFilter,
    setDrillDownValue,
    setExplorerFilter,
    setFundHouseKeywordFilter,
    setFundsKeywordFilter,
    setIndustryKeywordFilter,
    setStocksKeywordFilter
} from "../explorer/ExplorerAction";
import {getFilteredData, resetSearchKeyword, setSearchKeyword} from "./StockAction";
import {isEmpty} from "../utils/validations";
import {debounce} from "../utils/helper";
import {advanceSearchConst} from "../AdvanceSearch/AdvanceSearchConst";
import moment from "moment";

function StockHolding(props) {
    const [stockHoldingState, setStockHoldingState] = useState({
        UIState: '',
        isLineSelected: false,
        selectedLine: {},
        stocks: [],
        funds: [],
        fundHouses: [],
        sectors: [],
        marketCaps: [],
        businessHouses: [],
        viewBy: [],
        keyword: '',
        searchResult: [],
        isDataUpdated: false,
        isFilterAPIInProgress: false,
        isExternalFundHouseSelected:!!props.location?.state?.selectedFundHouse,
        externalFundHouseData: props.location?.state?.selectedFundHouse,
        selectedFund: [],
        isExternalFundSelected:!!props.location?.state?.selectedFund,
        externalFundData: props.location?.state?.selectedFund,
        selectedIndustry: [],
        isExternalSectorSelected: !!props.location?.state?.selectedSector,
        externalSectorData: props.location?.state?.selectedSector,
        selectedStock: [],
        isExternalStockSelected: !!props.location?.state?.selectedStock,
        selectedBusinessHouse: [],
        isExternalBusinessHouseSelected: true,
        selectedMarketCap: [],
        isExternalMarketCapSelected: true,
        isFundModalOpen: false,
        isMarketCapModalOpen: false,
        isFundHouseModalOpen: false,
        isBusinessHouseModalOpen: false,
        isIndustryModalOpen: false,
        isStockFundModalOpen: false,
        isExternalClick :false,
        storedDataForDownload: []
    });

    useEffect(() => {
        if (props.history.action === "POP" || props.history.action === "REPLACE") {
            //backward navigation
        } else {
            resetListingData();
        }
        setDrillDownValue("HOLDING");
        setTimeout(() => {
            setStockHoldingState((prev) => ({
                ...prev,
                isDataUpdated: true,
                isExternalClick: checkIsExternalClick()
            }));
        },0);

        return () => {
            setFundsKeywordFilter('');
            setFundHouseKeywordFilter('');
            setIndustryKeywordFilter('');
            setBusinessHouseKeywordFilter('');
            setStocksKeywordFilter('')
        };

    }, []);

    useEffect(() => {
        const {fundHouses, businessHouses, funds, marketCaps, industries, stocks, fundHouseForFund, fundHousesName, fundsName, stocksName} = props.explorerFilter;
          const {drillDownBy, searchKeyword} = props;

        if (stockHoldingState.isDataUpdated) {

            let data = {
                view: drillDownBy,
                keyword: searchKeyword ,
                businessHouse: businessHouses,
                stock: stocks,
                fund: funds,
                fundHouse: fundHouses,
                marketCap: marketCaps,
                industry: industries,
                fundHouseForFund: fundHouseForFund,
                fundHouseNames: fundHousesName,
                fundNames: fundsName,
                industryNames: industries,
                stockNames: stocksName,
                businessHouseNames: businessHouses
            };
            setStockHoldingState((prev) => ({
                ...prev,
                isFilterAPIInProgress: true,
                keyword:searchKeyword,
                storedDataForDownload: data
            }));

            getResult(data);
        }
    }, [props.explorerFilter, stockHoldingState.keyword, props.drillDownBy, stockHoldingState.isDataUpdated]);

    const removeDrillDownValue = () => {
    };

    const setSelectedLineForPopUpComponent = (lastDrillDown, selectedLine) => {

        if (lastDrillDown === 'fund' || isEmpty(lastDrillDown)) {
            setStockHoldingState((prev) => ({
                ...prev,
                selectedFund: selectedLine,
                isExternalFundSelected: true
            }));
            setTimeout(() => {
                setStockHoldingState((prev) => ({
                    ...prev,
                    selectedFund: [],
                    isExternalFundSelected: false
                }));
            }, 200)
        }
        if (lastDrillDown === 'fundHouse') {
            setStockHoldingState((prev) => ({
                ...prev,
                selectedFundHouse: selectedLine,
                isExternalFundHouseSelected: true
            }));
            setTimeout(() => {
                setStockHoldingState((prev) => ({
                    ...prev,
                    selectedFundHouse: [],
                    isExternalFundHouseSelected: false
                }));
            }, 200)
        }
        if (lastDrillDown === 'industry') {
            setStockHoldingState((prev) => ({
                ...prev,
                selectedIndustry: selectedLine,
                isExternalSectorSelected: true
            }));
            setTimeout(() => {
                setStockHoldingState((prev) => ({
                    ...prev,
                    selectedIndustry: [],
                    isExternalSectorSelected: false
                }));
            }, 200)
        }
        if (lastDrillDown === 'stock') {
            setStockHoldingState((prev) => ({
                ...prev,
                selectedStock: selectedLine,
                isExternalStockSelected: true
            }));
            setTimeout(() => {
                setStockHoldingState((prev) => ({
                    ...prev,
                    selectedStock: [],
                    isExternalStockSelected: false
                }));
            }, 200)
        }
        if (lastDrillDown === 'businessHouse') {
            setStockHoldingState((prev) => ({
                ...prev,
                selectedBusinessHouse: selectedLine,
                isExternalBusinessHouseSelected: true
            }));
            setTimeout(() => {
                setStockHoldingState((prev) => ({
                    ...prev,
                    selectedBusinessHouse: [],
                    isExternalBusinessHouseSelected: false
                }));
            }, 200)
        }
        if (lastDrillDown === 'marketCap') {
            setStockHoldingState((prev) => ({
                ...prev,
                selectedMarketCap: selectedLine,
                isExternalMarketCapSelected: true
            }));
            setTimeout(() => {
                setStockHoldingState((prev) => ({
                    ...prev,
                    selectedMarketCap: [],
                    isExternalMarketCapSelected: false
                }));
            }, 100)
        }
    };

    const closeLineSelectedModal = (name) => {
        setStockHoldingState((prev)=>({
            ...prev,
            selectedLine : {},
            [name]: false
        }));
    };

    const getFileName = (blockName) =>{
        let date = new Date();
        return blockName+moment(date).format('YYYY-MM-DD')
    };

    const checkIsExternalClick = ()=>{
        const {isExternalSectorSelected, isExternalFundHouseSelected, isExternalFundSelected}= stockHoldingState;
        return isExternalSectorSelected || isExternalFundHouseSelected || isExternalFundSelected;
    };

    const handleChipClick = (action, type, listingArr) => {

        if (action === 'apply_filter') {
            setStockHoldingState((prev) => ({
                ...prev,
                [type]: true,
                isLineSelected: false,
                selectedLine: {},
            }));
        }

        if (action === 'remove_filter') {
            switch (type) {
                case 'funds':
                    setSelectedLineForPopUpComponent('fund', []);
                    resetFund();
                    setExplorerFilter('funds', []);
                    setExplorerFilter('fundsName', []);
                    break;
                case 'fundHouses':
                    resetFundHouse();
                    setExplorerFilter('fundHouses', []);
                    setExplorerFilter('fundHousesName', []);
                    setSelectedLineForPopUpComponent('fundHouse', []);
                    break;
                case 'marketCaps':
                    resetMarketCap();
                    removeDrillDownValue();
                    setSelectedLineForPopUpComponent('marketCap', []);
                    break;
                case 'industries':
                    resetIndustry();
                    removeDrillDownValue();
                    setSelectedLineForPopUpComponent('industry', []);
                    break;
                case 'stocks':
                    resetStocks();
                    removeDrillDownValue();
                    setSelectedLineForPopUpComponent('stock', []);
                    break;
                case 'businessHouses':
                    resetBusinessHouses();
                    removeDrillDownValue();
                    setSelectedLineForPopUpComponent('businessHouse', []);
                    break;
                default:
            }
            setStockHoldingState((prev) => ({
                ...prev,
                [type]: [],
                [listingArr]: [],
                isDataUpdated: true,
            }));
        }
    };

    const setName = (name, selectedValues) => {
        let values = [];
        switch (name) {
            case 'fundHouses':
                selectedValues.forEach((item) => {
                    values.push(item['fund_house_name'])
                });
                setExplorerFilter('fundHousesName', values);
                break;
            case 'funds':
                selectedValues.forEach((item) => {
                    values.push(item['fund_name'])
                });
                setExplorerFilter('fundsName', values);
                break;
            case 'stocks':
                selectedValues.forEach((item) => {
                    values.push(item['company_name'])
                });
                setExplorerFilter('stocksName', values);
                break;
            default:
        }
    };

    const setFilter = (name, selectedValues, idVar) => {
         const {isExternalClick}= stockHoldingState;
        let values = [];
        if (name === 'funds') {
            let fundsValues = [];
            let fundHouseForFund = [];

            selectedValues.forEach((item) => {
                fundsValues.push(item[idVar]);
                fundHouseForFund.push(item['fund_house_id'])
            });
            setName(name, selectedValues);
            setTimeout(() => {
                setExplorerFilter(name, fundsValues);
                setExplorerFilter('fundHouseForFund', fundHouseForFund);
                setStockHoldingState((prev) => ({
                    ...prev,
                    isDataUpdated: true
                }));
            }, 300)

        }
        if (name === 'stocks') {
            selectedValues.forEach((item) => {
                values.push(item[idVar])
            });

            setName(name, selectedValues);
            setTimeout(() => {
            setExplorerFilter(name, values);
                setStockHoldingState((prev) => ({
                    ...prev,
                    isDataUpdated: true
                }));
            }, 300)
        } else {

            selectedValues.forEach((item) => {
                values.push(item[idVar])
            });

            setName(name, selectedValues);
            setTimeout(() => {
                setExplorerFilter(name, values);
                setStockHoldingState((prev) => ({
                    ...prev,
                    isDataUpdated: true
                }));
            }, 300)
        }

        if(isExternalClick){
            setStockHoldingState((prev) => ({
                ...prev,
                externalSectorData: [],
                externalFundData: [],
                externalFundHouseData: [],
                externalStockData: []
            }));
        }
    };

    const onChangeValue = (name, value) => {
        setStockHoldingState((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const onChangeKeywordFilter = (e) => {
        setStockHoldingState((prev) => ({
            ...prev,
            keyword : e,
            isDataUpdated : true
        }));
        setSearchKeyword(e);
    };

    const handleViewChange = (drillDownName) => {
        if (drillDownName === props.drillDownBy) {

        } else {
            setTimeout(() => {
                setDrillDownValue(drillDownName);
                setStockHoldingState((prev) => ({
                    ...prev,
                    isFilterAPIInProgress: true,
                    isDataUpdated: true
                }));
            }, 300)
        }
    };

    const setDrillDownBy = (drillDownName) => {
        setDrillDownValue(drillDownName);
    };

    const getResult = debounce((data) => {

        getFilteredData(data).then((res) => {
            if (res.success) {
                if (!isEmpty(res.data?.data)) {
                    // getBuyingSelling(res.data.data);
                    setStockHoldingState((prev) => ({
                        ...prev,
                        searchResult: res.data?.data,
                        isFilterAPIInProgress: false,
                        isDataUpdated :false
                        // resultCount: checkLengthOfResult(res.data?.data),
                        // storedDataForDownload: {...data, purpose: 'download'}
                    }));
                    //  getData(res.data?.data, drillDownBy);
                } else {
                    setStockHoldingState((prev) => ({
                        ...prev,
                        searchResult: [],
                        tableHeader: [],
                        buying: [],
                        selling: [],
                        isFilterAPIInProgress: false
                    }));
                }
            }

            setStockHoldingState((prev) => ({
                ...prev,
                UIState: advanceSearchConst.CONTENT_AVAILABLE,
                isFilterAPIInProgress: false
            }));

        })
    }, 300);

    const resetListingData = () => {
        resetAllFilterData();
        resetFund();
        resetFundHouse();
        resetSearchKeyword();
        setDrillDownBy('HOLDING');
        onChangeKeywordFilter('')
    };

    const addLineFilter = () =>{
        let {selectedLine, searchResult} = stockHoldingState;
        const {drillDownBy} = props;
        switch (drillDownBy) {
            case 'fundHouse':
                selectedLine = searchResult.find(item=> item.fund_house_name === selectedLine);
                setFilter('fundHouses', [selectedLine], 'fund_house_id');
                setStockHoldingState((prev) => ({
                    ...prev,
                    isActionModalOpen: false,
                    isExternalFundHouseSelected: true,
                    externalFundHouseData: [selectedLine],
                    selectedLine : [],
                    menuVisible: false,
                    menuPosition: {},
                }));

                setTimeout(()=>{
                    setStockHoldingState((prev) => ({
                        ...prev,
                        isExternalFundHouseSelected: false,
                        externalFundHouseData: []
                    }));
                },100);
                break;
            case 'fund':

                selectedLine = searchResult.find(item=> item.fund_name === selectedLine);
                setFilter('funds', [selectedLine], 'fund_id');
                setStockHoldingState((prev) => ({
                    ...prev,
                    isActionModalOpen: false,
                    isExternalFundSelected: true,
                    externalFundData: selectedLine,
                    selectedLine : [],
                    menuVisible: false,
                    menuPosition: {},
                }));
                setTimeout(()=>{
                    setStockHoldingState((prev) => ({
                        ...prev,
                        isExternalFundSelected: false,
                        externalFundData:[],
                        selectedFund: []
                    }));
                },100);
                break;
            case 'industry':

                selectedLine = searchResult.find(item=> item.industry === selectedLine);
                setFilter('industries', [selectedLine], 'industry');
                setStockHoldingState((prev) => ({
                    ...prev,
                    isActionModalOpen: false,
                    isExternalSectorSelected: true,
                    selectedIndustry: [selectedLine],
                    selectedLine : [],
                    menuVisible: false,
                    menuPosition: {},
                }));
                setTimeout(()=>{
                    setStockHoldingState((prev) => ({
                        ...prev,
                        isExternalSectorSelected: false,
                        selectedIndustry: []
                    }));
                },100);
                break;
            case 'stock':

                selectedLine = searchResult.find(item=> item.company_name === selectedLine);
                setFilter('stocks', [selectedLine], 'isin');
                setStockHoldingState((prev) => ({
                    ...prev,
                    isExternalStockSelected: true,
                    externalStockData: selectedLine,
                    selectedStock: [selectedLine],
                    selectedLine : [],
                    menuVisible: false,
                    menuPosition: {},
                    isActionModalOpen: false
                }));
                setTimeout(()=>{
                    setStockHoldingState((prev) => ({
                        ...prev,
                        isExternalStockSelected: false,
                        externalStockData: [],
                        selectedStock: []
                    }));
                },100);
                break;
            case 'businessHouse':
                selectedLine = searchResult.find(item=> item.business_house === selectedLine);
                setFilter('businessHouses', [selectedLine], 'business_house');
                setStockHoldingState((prev) => ({
                    ...prev,
                    isExternalBusinessHouseSelected: true,
                    selectedBusinessHouse: [selectedLine],
                    selectedLine : [],
                    menuVisible: false,
                    menuPosition: {},
                    isActionModalOpen: false
                }));
                setTimeout(()=>{
                    setStockHoldingState((prev) => ({
                        ...prev,
                        isExternalBusinessHouseSelected: false,
                        selectedBusinessHouse: []
                    }));
                },100);
                break;
            case 'marketCap':

                selectedLine = searchResult.find(item=> item.category === selectedLine);
                setFilter('marketCaps', [selectedLine], 'category');
                setStockHoldingState((prev) => ({
                    ...prev,
                    isExternalMarketCapSelected: true,
                    selectedMarketCap: selectedLine,
                    selectedLine : [],
                    menuVisible: false,
                    menuPosition: {},
                    isActionModalOpen: false
                }));
                setTimeout(()=>{
                    setStockHoldingState((prev) => ({
                        ...prev,
                        isExternalMarketCapSelected: false,
                        selectedMarketCap: []
                    }));
                },100);
                break;
            default:
        }

    };

    if (isEmpty(stockHoldingState.UIState)) {
        return <DLLoader type={"screen"} isVisible={true}/>
    }

    return (
        <StockHoldingView
            {...stockHoldingState}
            {...props}
            handleChipClick={handleChipClick}
            onChangeValue={onChangeValue}
            onChangeKeywordFilter={onChangeKeywordFilter}
            setFilter={setFilter}
            handleViewChange={handleViewChange}
            addLineFilter={addLineFilter}
            closeLineSelectedModal={closeLineSelectedModal}
            resetListingData={resetListingData}
            getFileName={getFileName}
        />
    );

}


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    explorerFilter: state.ExplorerState.explorerFilter,
    fundHouseListingData: state.fundState.fundHouseListingData,
    fundListingData: state.fundState.fundListingData,
    marketCapData: state.ExplorerState.marketCapData,
    industryListingData: state.ExplorerState.industryListingData,
    stockListingData: state.ExplorerState.stockListingData,
    businessHouseListingData: state.ExplorerState.businessHouseListingData,
    drillDownBy: state.ExplorerState.drillDownBy,
    fromDate: state.MoneyExplorerState.fromDate,
    toDate: state.MoneyExplorerState.toDate,
    searchKeyword: state.StockHoldingReducer.keyword
});

export default connect(mapStateToProps, {})(StockHolding);