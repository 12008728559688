import { showSystemErrorBlock , showToastMessage} from "../core/actions/appActions";
import store from "../store";
import {httpGet} from "../utils/httpHelper";
import {setFundKeyword} from "../fund/FundReducer";
import {resetTabValue, setTabValue, setIndustryKeyword, resetOrder, resetOrderBy, resetPage, resetRowsPerPage, setOrder, setOrderBy, setPage, setRowsPerPage} from "./IndustryReducer";

// let base_url = 'http://localhost:10217'
let base_url = ''
export const getFundHouseSummary= (date, industry) => {
    let url = base_url + '/service/sector/fundHouseSummary?date='+date+'&industry='+industry;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getFundSummary= (date, industry) => {
    let url = base_url + '/service/sector/fundSummary?date='+date+'&industry='+industry ;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};


export const getStocksSummary= (date, industry) => {
    let url = base_url + '/service/sector/stocksSummary?date='+date+'&industry='+industry;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getAllIndustries= () => {
    let url = base_url+ '/service/sector/all' ;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getIndustrySummary= (date) => {
    let url = base_url + '/service/home/sectorSummary?date='+date ;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};


export const getIndustrySummaryData= (date, industry) => {
    let url = base_url + '/service/sector/sectorSummary?date='+date+'&industry='+industry;
    return httpGet(url)
        .then(res=>{

            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const getNetBuyingSellingPopUpData= (date,industry) => {
    let url = base_url+ '/service/sector/lastMonthSummary?date='+date+'&industry='+industry;

    return httpGet(url)
        .then(res=>{
            return res;

        })
        .catch((err)=>{
            store.dispatch(showSystemErrorBlock());
            return null;
        })
};

export const setIndustryKeywordFilter = (keyword) =>{
    store.dispatch(setIndustryKeyword(keyword))
};

export const setIndustryTableByOrder = (data) =>{
    store.dispatch(setOrder(data))
};

export const setIndustryTableByOrderBy = (data) =>{
    store.dispatch(setOrderBy(data))
};

export const setIndustryPage = (data) =>{
    store.dispatch(setPage(data))
};

export const setIndustryRowsPerPage = (data) =>{
    store.dispatch(setRowsPerPage(data))
};

export const setIndustryTabValue = (data) =>{
    store.dispatch(setTabValue(data))
};

export const resetIndustryTabValue = (data) =>{
    store.dispatch(resetTabValue(data))
};

export const resetIndustryPage = (data) =>{
    store.dispatch(resetPage(data))
};

export const resetIndustryRowsPerPage = (data) =>{
    store.dispatch(resetRowsPerPage(data))
};

export const resetIndustryTableByOrder = (name, value) =>{
    store.dispatch(resetOrder({name, value}))
};

export const resetIndustryTableByOrderBy = (name, value) =>{
    store.dispatch(resetOrderBy({name, value}))
};
