import React, {useEffect, useState} from 'react'
import DLLoader from "../../core/components/DLLoader/DLLoader";
import DLText from "dlcomponent/components/DLText/DLText";
import DLButton from "dlcomponent/components/DLButton/DLButton";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IndustryChart from "./IndustryChart";
import IndustryTable from "./IndustryTable";
import {
    getAllIndustries,
    getIndustrySummary, resetIndustryPage, resetIndustryRowsPerPage,
    resetIndustryTableByOrder, resetIndustryTableByOrderBy,setIndustryTabValue, resetIndustryTabValue,
    setIndustryKeywordFilter
} from "../IndustryAction";
import CustomTabPanel from "../../component-utils/CustomTabPanel/CustomTabPanel";
import {downloadExcel} from "../../utils/downloadExcel";
import ChartRadioGroup from "../../component-utils/ChartRadioGroup/ChartRadioGroup";
import {withRouter} from "react-router-dom";
import DLSearchAutoComplete from "dlcomponent/components/DLSearchAutoComplete/DLSearchAutoComplete";
import SummaryCardFundHouse from "../../stocks/SummaryCardFundHouse/SummaryCardFundHouse";
import {
    getChartData,
    getFixedValuesAfterDecimal,
    getIndexArr,
    getTrimVal,
    searchOnWordBoundary
} from "../../utils/helper";
import {isEmpty} from "../../utils/validations";
import FundsChart from "../../fund/FundListing/FundsChart";
import FundHouseChart from "../../fundHouse/FundHouseListing/FundHouseChart";
import {connect} from "react-redux";
import FundHouseTable from "../../fundHouse/FundHouseListing/FundHouseTable";
import DLInputTextField from "dlcomponent/components/DLInputTextField/DLInputTextField";
import {setFundHouseKeywordFilter} from "../../fundHouse/FundHouseAction";
import {downloadData} from "../../fund/FundAction";
import moment from "moment";
import appTheme from "../../assets/appTheme";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function IndustryListing(props) {

    const [industryListing, setIndustryListing] = useState({
        allIndustryData: [],
        percentage: false,
        isSearchApply: false,
        sortedData: [],
        chartType:'marketValue',
        searchResultNotAvailable: false
    });

    const [chartLabelsArray, setChartLabelsArray] = useState([]);
    const [chartDataArray, setChartDataArray] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tableHeader, setTableHeader] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isTableLoading, setIsTableLoading] = useState(true);

    const{tabValue} = props;

    const getData = (data) => {
        let dataObj = [];
        if (isEmpty(data)) {
            return dataObj
        }

        data.forEach((item) => {
            dataObj.push({...item, label: item?.industry, value: item?.industry})
        });
        if (isEmpty(dataObj)) {
            return []
        }
        return dataObj.sort((a, b) => {
            let A = a?.label?.trim();
            let B = b?.label?.trim();

            return A?.localeCompare(B)
        });
    };

    useEffect(()=>{
        if (props.history.action === "POP" || props.history.action === "REPLACE"){
            //backward navigation
        }else {
            // resetListingData()
            resetIndustryTableByOrder();
            resetIndustryTableByOrderBy();
            resetIndustryPage();
            resetIndustryRowsPerPage();
            resetIndustryTabValue();
        }

        getIndustrySummary(VALUATION_DATE).then((res) => {
            if (res.success && !isEmpty(res.data?.data)) {
                const copiedArray = res.data.data.slice(1, res.data.data.length);
                setTableData(copiedArray);
                setIndustryGraph(copiedArray);
                setTableHeader(res.data.data[0]);
                setIsLoading(false);
                setIsTableLoading(false)
            }
        })
    },[]);


    useEffect(()=>{
        setIsLoading(true);
        setTimeout(()=>{
            setIsLoading(false)
        },100)
    },[props.isLGView, props.isMDView, props.isXLView, props.isSMView, props.isXSView]);

    useEffect(()=>{
        const {isLGView, isXLView, keyword, isMobileView} = props;
        const {chartType} = industryListing;

        if (isEmpty(tableData)){
            return
        }

        if (isEmpty(keyword)){
            setIndustryListing((prev)=>({
                ...prev,
                isSearchApply: true,
                sortedData: []
            }));
            let chartObj = getChartData(chartType, tableData, 'industry', isLGView, isXLView,isMobileView);

            setChartLabelsArray(chartObj?.newLabelData);
            setChartDataArray(chartObj?.newChartData);

            setTimeout(()=>{
                setIndustryListing((prev)=>({
                    ...prev,
                    isSearchApply: false,
                    searchResultNotAvailable: false
                }))
            },300)
        }else {
            let sortedData = [];
            setIndustryListing((prev)=>({
                ...prev,
                isSearchApply: true
            }));

            let options = tableData.map((item)=>{
                return {...item, label:item.industry, value: item.industry}
            });

            sortedData = searchOnWordBoundary(props.keyword, JSON.parse(JSON.stringify(options)));

            if (!isEmpty(sortedData)){
                let data = searchOnWordBoundary(props.keyword, options);
                let chartObj = getChartData(chartType, data, 'industry', isLGView, isXLView,isMobileView);

                setIndustryListing((prev)=>({
                    ...prev,
                    sortedData: sortedData,
                    searchResultNotAvailable: false
                }));
                setChartLabelsArray(chartObj?.newLabelData);
                setChartDataArray(chartObj?.newChartData);
            }else {
                setIndustryListing((prev)=>({
                    ...prev,
                    sortedData: [],
                    searchResultNotAvailable: true
                }));
            }

            setTimeout(()=>{
                setIndustryListing((prev)=>({
                    ...prev,
                    isSearchApply: false
                }))
            },300)

        }

    },[props.keyword, tableData]);

    const getFileName = (blockName) =>{
        let date = new Date();
        return 'Sectors-'+blockName+moment(date).format('YYYY-MM-DD')
    };


    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setIndustryTabValue(newValue);
    };

    const deepCopy = (obj) => {
        return JSON.parse(JSON.stringify(obj));
    };

    //order by descending
    const compareByMarketValueChangeMoM = (a, b) => {
        if (a.market_value_change_mom < b.market_value_change_mom) {
            return 1;
        }
        if (a.market_value_change_mom > b.market_value_change_mom) {
            return -1;
        }
        return 0;
    };

    const compareByMarketValueChangePercent = (a, b) => {
        if (a.percentage_market_value_change < b.percentage_market_value_change) {
            return 1;
        }
        if (a.percentage_market_value_change > b.percentage_market_value_change) {
            return -1;
        }
        return 0;
    };


    const handleRadioChange = (event) => {
        const {isLGView, isXLView,isMobileView} = props;
        let chartType = event ;
        const {sortedData} = industryListing;
        let tableDataArr = !isEmpty(sortedData) ? sortedData : tableData;

        let chartObj = getChartData(chartType, tableDataArr, 'industry', isLGView, isXLView,isMobileView);

        setIndustryListing((prevState)=>({
            ...prevState,
            percentage: (chartType === 'changePercent' || chartType ===  'changeSellingPercent'),
            chartType: chartType
        }));
        setChartLabelsArray(chartObj?.newLabelData);
        setChartDataArray(chartObj?.newChartData);
    };


    const handleClick = (elementIndex) => {
        const {chartType, sortedData} = industryListing;
        let tableDataArr = !isEmpty(sortedData) ? sortedData : tableData;
        let indexArr = getIndexArr(chartType, tableDataArr);

            let clickedIndustry = indexArr[elementIndex];
            props.history.push('/sectors/' + clickedIndustry.industry)

    };

    const setIndustryGraph = (data) => {
        const {isLGView, isXLView, isMobileView} = props;

        let length = 10;
        if (data?.length < 10){
            length = data?.length
        }

        for (let i = 0; i < length; i++) {

            let industryName = getTrimVal(data[i].industry, isLGView, isXLView, isMobileView);
            let marketValue = getFixedValuesAfterDecimal(data[i].total_market_value,2);

            setChartLabelsArray(industryNames => [...industryNames, industryName]);
            setChartDataArray(marketValueInCrores => [...marketValueInCrores, marketValue]);

        }
    };

    const onChangeValue = (name, value) => {
        setIndustryListing((prevState) => ({
            ...prevState,
            name: value
        }))
    };
    const onChangeKeywordFilter = (keyword) =>{
        setIndustryKeywordFilter(keyword)
    };

    if (isLoading) {
        return (
            <div className={''}>
                <DLLoader type={'inline'} isVisible={true}/>
            </div>
        )
    }

    const getResult = (data) =>{
        if (isEmpty(data)){
            return
        }

        if (data?.length >= 10){
            return(
                <div className='py-2 mx-3'>
                    Showing top 10 results
                </div>
            )
        }else {
            if (data.length === 1){
                return (
                    <div className='py-2 mx-3'>
                        Showing {data?.length} result
                    </div>
                )
            }else {
                return (
                    <div className='py-2 mx-3'>
                        Showing {data?.length} results
                    </div>
                )

            }
        }
    }

    const getTableBarUI = (ui) =>{
        const {searchResultNotAvailable, percentage, sortedData, chartType} = industryListing;
        let tableDataArr = !isEmpty(sortedData) ? sortedData : tableData;

        if (searchResultNotAvailable){
            return (
                <div className={ui === 'table' ? '' : 'm-3'} style={{minHeight: 'calc(100vh - 400px)'}}>
                    <div className= {ui === "table"? "p-4" : "" }>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={'No matching sector found.'}
                            fontSize={'lg'}/>
                    </div>
                </div>
            )
        }
        if(ui === 'table'){
            return(
                <IndustryTable data={tableDataArr} {...props} tableHeader={tableHeader}/>
            )
        }else {
            return (
                <>
                    <ChartRadioGroup handleRadioChange={handleRadioChange}
                                     isXSView={props.isXSView}
                                     chartType={chartType}
                                     isSMView={props.isSMView}/>
                        {getResult(chartDataArray)}
                    <IndustryChart yAxis={chartLabelsArray}
                                   xAxis={chartDataArray}
                                   percentage={percentage}
                                   chartType={chartType}
                                   isMobileView={props.isMobileView}
                                   handleClick={handleClick}/>
                </>
            )
        }
    };

    const renderFilterUI = () => {
        const {allIndustryData} = industryListing;
        const {keyword, isXSView} = props;

        return (
            <div className='px-3' style={{marginBottom: isXSView ? 8 : 14}}>
                <div style={{width: isXSView ? '100%' : '50%'}}>
                    <DLInputTextField
                        id={'businessList-keywordSearch'}
                        label={''}
                        rows={1}
                        fullWidth
                        styleType={"search"}
                        placeholder={'Search...'}
                        value={keyword}
                        onChangeValue={(e) => onChangeKeywordFilter(e)}
                        isClearable={true}
                        inputHeight={'sm'}
                        marginBottom={"none"}
                        InputProps={{
                            endAdornment: (
                                keyword?
                                    <i className="fa fa-remove m-2 cursor" onClick={()=>{onChangeKeywordFilter("")}} style={{color: appTheme.appColor.black}}/>
                                    :
                                    <i className={'fas fa-search pr-1'} style={{color: appTheme.appColor.black}}/>)
                        }}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {

                                document?.activeElement?.blur()
                            }
                        }}
                        disableHelperMessageHeight={true}
                    />
                </div>
            </div>
        )
    };

    const renderDataListingUI = () => {
        const {percentage, isSearchApply, chartType} = industryListing;
        const {isXSView} = props;

        if (isTableLoading) {
            return
        }
        return (
            <div className='panel mx-sm-3'
                 id='sector-listing'>

                <Box  sx={{width: '100%'}}>
                    <Box className={isXSView ? 'd-flex justify-content-between mx-3' : 'd-flex justify-content-between mx-4 pt-3'} sx={{borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                            <Tab sx={{paddingLeft: 1}} label="Chart" {...a11yProps(0)} sx={{ textTransform:'capitalize'}}/>
                            <Tab label="Table" {...a11yProps(1)} sx={{ textTransform:'capitalize'}}/>
                        </Tabs>
                        <div style={{width:36, height:36, paddingTop:5}}>
                            {/*<DLButton*/}
                            {/*    id={'download-excel'}*/}
                            {/*    styleType={"outlined"}*/}
                            {/*    borderType={'square'} buttonSize={'sm'}*/}
                            {/*    sideMargin={"none"}*/}
                            {/*    reduceWidth={true}*/}
                            {/*    type={'primary'}*/}
                            {/*    style={{width: 36}}*/}
                            {/*    onClick={() =>{*/}
                            {/*        downloadData('/service/media/home/sectorSummary/download?date='+VALUATION_DATE,'', 'Download', getFileName('Summary-'))*/}
                            {/*    }}*/}
                            {/*    label={<i className='fas fa-download fs-6'/>}*/}
                            {/*/>*/}
                            <div className='d-flex align-items-center justify-content-center downloadButtonCss cursor'>
                                <i className='fas fa-download fs-6'
                                   onClick={() =>{
                                       downloadData('/service/media/home/sectorSummary/download?date='+VALUATION_DATE,'', 'Download', getFileName('Summary-'))
                                   }}
                                   style={{fontSize: 12}}/>
                            </div>
                        </div>
                    </Box>
                    <CustomTabPanel id={'tableTabPanel'}  value={tabValue} index={0}>
                        {(isEmpty(chartDataArray) || isEmpty(chartLabelsArray)) ?
                            <div>

                                <ChartRadioGroup handleRadioChange={handleRadioChange}
                                                 isXSView={props.isXSView}
                                                 chartType={chartType}
                                                 isSMView={props.isSMView}/>
                            <div className='d-flex align-items-center justify-content-center mx-3' style={{minHeight: 300}}>
                                <DLText
                                    id={'revenue-value'}
                                    type={'normal'}
                                    text={'No data found'}
                                    fontSize={'md'}/>
                            </div>
                            </div>
                            :
                            getTableBarUI('chart')
                        }
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1} id={'tableTabPanel'}>
                        {isSearchApply ? <></>
                            :
                            getTableBarUI('table')
                        }
                    </CustomTabPanel>
                </Box>
            </div>
        )
    };

    return (
        <div className='pb-2'>
            <div className='fixedHeader bg-white px-3 py-2'>
                <div>
                    <DLText
                        id={'summary-by-country'}
                        type={'normal'}
                        text={'Sectors'}
                        fontWeight={'semi-bold'}
                        marginBottom={"none"}
                        fontSize={'xl'}/>
                </div>
            </div>
            <div className='pb-2'>
                {renderFilterUI()}
                {renderDataListingUI()}
            </div>
        </div>
    )

}


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    keyword: state.IndustryState.keyword,
    order: state.IndustryState.order,
    orderBy: state.IndustryState.orderBy,
    page: state.IndustryState.page,
    rowsPerPage: state.IndustryState.rowsPerPage,
    tabValue: state.IndustryState.tabValue
});

export default connect(mapStateToProps, {})(IndustryListing);
