import React, {useEffect, useState} from 'react';
import AdvanceSearchView from "./AdvanceSearchView";
import {isEmpty} from "../utils/validations";
import DLLoader from "../core/components/DLLoader/DLLoader";
import {advanceSearchConst} from "./AdvanceSearchConst";
import {connect} from "react-redux";
import {
    getSearchResult,
    resetAllFilterData,
    resetBusinessHouses,
    resetIndustry,
    resetMarketCap,
    resetStocks,
    resetFund,
    resetFundHouse,
    setAdvanceKeywordFilter,
    setFundHouseKeywordFilter,
    setIndustryKeywordFilter,
    setAdvanceSearchFilter, setBusinessHouseKeywordFilter,
    setFundsKeywordFilter, setStocksKeywordFilter
} from "./AdvanceSeachAction";
import {debounce} from "../utils/helper";
// import {resetFund, resetFundHouse} from "../fund/FundAction";
import {setExplorerFilter} from "../explorer/ExplorerAction";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function AdvanceSearch(props) {
    const [advanceSearchState, setAdvanceSearchState] = useState({
        UIState: '',
        searchResult: [],
        tableHeader: [],
        businessHouses: [],
        funds: [],
        fund_fundHouse: [],
        industries: [],
        stocks: [],
        marketCaps: [],
        keyword: '',
        urlEndPoint: '',
        isDataUpdated: false,
        isFundModalOpen: false,
        isMarketCapModalOpen: false,
        isFundHouseModalOpen: false,
        isBusinessHouseModalOpen: false,
        isIndustryModalOpen: false,
        isStockFundModalOpen: false,

        isExternalFundHouseSelected: props.location?.state?.selectedFundHouse ? true : false,
        externalFundHouseData: props.location?.state?.selectedFundHouse,
        externalFundHouseStocksKeyword: props.location?.state?.stockFilter,

        isExternalFundSelected: props.location?.state?.selectedFund ? true : false,
        externalFundData: props.location?.state?.selectedFund,
        externalFundStocksKeyword: props.location?.state?.stockFilter,

        isExternalSectorSelected: props.location?.state?.selectedSector ? true : false,
        externalSectorData: props.location?.state?.selectedSector,
        externalSectorStocksKeyword: props.location?.state?.stockFilter,

        isExternalStockSelected: props.location?.state?.selectedStock ? true : false,
        externalStockData: props.location?.state?.selectedStock,

        isExternalClick: false,
        storedDataForDownload: [],
        fundHouseData:[],
        fundData:[],
        stockData:[],
        sectorData:[]
    });

    console.log("externalFundData", advanceSearchState.externalFundData);
    const generateURLEndPoint = (searchFilter) => {
        let urlBuilder = [];
        if (isEmpty(searchFilter.keyword)) {
            // urlBuilder.push('customerType='+'CUSTOMER')
        } else {
            urlBuilder.push('keyword=' + searchFilter.keyword);
        }
        if (isEmpty(searchFilter.businessHouses)) {
            // urlBuilder.push('status='+'CUSTOMER')
        } else {
            urlBuilder.push('businessHouses=' + JSON.stringify(searchFilter.businessHouses));
        }
        if (isEmpty(searchFilter.fundHouses)) {
            // urlBuilder.push('status='+'CUSTOMER')
        } else {
            urlBuilder.push('fundHouses=' + JSON.stringify(searchFilter.fundHouses));
        }
        if (isEmpty(searchFilter.funds)) {
            // urlBuilder.push('status='+'CUSTOMER')
        } else {
            urlBuilder.push('funds=' + JSON.stringify(searchFilter.funds));
        }

        if (isEmpty(searchFilter.industries)) {
            // urlBuilder.push('status='+'CUSTOMER')
        } else {
            urlBuilder.push('industries=' + JSON.stringify(searchFilter.industries));
        }

        if (isEmpty(searchFilter.stocks)) {
            // urlBuilder.push('status='+'CUSTOMER')
        } else {
            urlBuilder.push('stocks=' + JSON.stringify(searchFilter.stocks));
        }

        if (isEmpty(searchFilter.marketCaps)) {
            // urlBuilder.push('status='+'CUSTOMER')
        } else {
            urlBuilder.push('marketCaps=' + JSON.stringify(searchFilter.marketCaps));
        }

        if (isEmpty(searchFilter.date)) {
            // urlBuilder.push('status='+'CUSTOMER')
        } else {
            urlBuilder.push('date=' + searchFilter.date);
        }

        return urlBuilder.join('&')
    };

    useEffect(() => {
        if (props.history.action === "POP" || props.history.action === "REPLACE") {
            //backward navigation
        } else {
            resetListingData()
        }

        setTimeout(() => {
            setAdvanceSearchState((prev) => ({
                ...prev,
                isDataUpdated: true,
                isExternalClick: checkIsExternalClick()
            }));

        }, 0);


        return () => {
            setFundsKeywordFilter('');
            setFundHouseKeywordFilter('');
            setIndustryKeywordFilter('');
            setBusinessHouseKeywordFilter('');
            setStocksKeywordFilter('')
        };
    }, []);


    const extractData = (data,type) =>{
        if(isEmpty(data)){
            return [];
        }
        return data.map(item => item[type])
    };

    useEffect(() => {
        const {businessHouses, marketCaps,fundsData, fundHousesData, stocksData, sectorsData} = props.advanceSearchFilter;

        if (advanceSearchState.isDataUpdated) {
            let data = {
                keyword: props.keyword,
                businessHouse: businessHouses,
                fundHouse: extractData(fundHousesData,"fund_house_id"),
                fund: extractData(fundsData,"fund_id"),
                marketCap: marketCaps,
                industry: extractData(sectorsData,"industry"),
                stock: extractData(stocksData,"isin_code"),
                fundHouseForFund: extractData(fundsData,"fund_house_id"),
                date: VALUATION_DATE
            };

            setAdvanceSearchState((prev) => ({
                ...prev,
                urlEndPoint: generateURLEndPoint(data),
                isFilterAPIInProgress: true,
                storedDataForDownload: data
            }));
            getResult(data);
        }
    }, [props.advanceSearchFilter,
        props?.keyword, advanceSearchState.isDataUpdated]);

    const getResult = (data) => {
        getSearchResult(data, VALUATION_DATE).then((res) => {
            if (res.success) {
                if (!isEmpty(res.data?.data)) {
                    setAdvanceSearchState((prev) => ({
                        ...prev,
                        searchResult: res.data.data.slice(1, res.data.data.length),
                        tableHeader: res.data.data[0],
                        isFilterAPIInProgress: false
                    }));
                } else {
                    setAdvanceSearchState((prev) => ({
                        ...prev,
                        searchResult: [],
                        tableHeader: [],
                        isFilterAPIInProgress: false
                    }));
                }
            }

            setAdvanceSearchState((prev) => ({
                ...prev,
                UIState: advanceSearchConst.CONTENT_AVAILABLE,
                isFilterAPIInProgress: false
            }));
        })
    };

    const onChangeKeywordFilter = (keyword) => {
        setAdvanceKeywordFilter(keyword)
    };

    const checkIsExternalClick = () => {
        const {isExternalSectorSelected, isExternalFundHouseSelected, isExternalFundSelected} = advanceSearchState;
        if (isExternalSectorSelected || isExternalFundHouseSelected || isExternalFundSelected) {
            return true;
        } else {
            return false;
        }
    };

    const handleChipClick = (action, type, listingArr) => {
        if (action === 'apply_filter') {
            setAdvanceSearchState((prev) => ({
                ...prev,
                [type]: true
            }));
        }

        if (action === 'remove_filter') {
            switch (type) {
                case 'funds':
                    resetFund();
                    break;
                case 'fundHouses':
                    resetFundHouse();
                    break;
                case 'marketCaps':
                    resetMarketCap();
                    break;
                case 'industries':
                    resetIndustry();
                    break;
                case 'stocks':
                    resetStocks();
                    break;
                case 'businessHouses':
                    resetBusinessHouses();
                    break;
                default:
            }
            setAdvanceSearchState((prev) => ({
                ...prev,
                [type]: [],
                [listingArr]: []
            }));
        }
    };

    const onChangeValue = (name, value) => {
        setAdvanceSearchState((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const setFilter = (name, selectedValues, idVar) => {
        const {isExternalClick} = advanceSearchState;
        let values = [];

        switch(name){
            case 'funds' :
                values = selectedValues.map(item => ({
                    fund_house_id: item.fund_house_id,
                    fund_id: item.fund_id,
                    fund_name: item.fund_name
                }));
                setAdvanceSearchFilter('fundsData', values);
                break;
            case 'stocks' :
                values = selectedValues.map(item => ({
                    isin_code: item.isin_code,
                    company_name: item.company_name
                }));
                setAdvanceSearchFilter('stocksData', values);
                break;
            case 'fundHouses' :
                values = selectedValues.map(item => ({
                    fund_house_id: item.fund_house_id,
                    fund_house_name: item.fund_house_name
                }));

                setAdvanceSearchFilter('fundHousesData', values);
                break;
            case 'industries' :
                values = selectedValues.map(item => ({
                    industry: item.industry,
                }));

                setAdvanceSearchFilter('sectorsData', values);
                break;
            case 'businessHouses' :
                selectedValues.forEach((item) => {
                    values.push(item[idVar])
                });
                setAdvanceSearchFilter(name, values);
                break;
            case 'marketCaps' :
                selectedValues.forEach((item) => {
                    values.push(item[idVar])
                });
                setAdvanceSearchFilter(name, values);
                break;
            default:
                break;
        }

        if (isExternalClick) {
            setAdvanceSearchState((prev) => ({
                ...prev,
                //isExternalSectorSelected: false,
                externalSectorData: [],
                externalFundData: [],
                externalFundHouseData: []
            }));
        }
    };

    const resetListingData = () => {
        resetAllFilterData();
        resetFund();
        resetFundHouse()
    };

    if (isEmpty(advanceSearchState?.UIState)) {
        return <DLLoader type={"screen"} isVisible={true}/>
    }

    return (
        <AdvanceSearchView {...advanceSearchState}
                           {...props}
                           handleChipClick={handleChipClick}
                           onChangeValue={onChangeValue}
                           setFilter={setFilter}
                           resetListingData={resetListingData}
                           onChangeKeywordFilter={onChangeKeywordFilter}
                           extractData={extractData}
        />
    );
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    keyword: state.AdvanceSearchState.keyword,
    advanceSearchFilter: state.AdvanceSearchState.advanceSearchFilter,
    fundHouseListingData: state.AdvanceSearchState.fundHouseListingData,
    fundListingData: state.AdvanceSearchState.fundListingData,
    marketCapData: state.AdvanceSearchState.marketCapData,
    industryListingData: state.AdvanceSearchState.industryListingData,
    stockListingData: state.AdvanceSearchState.stockListingData,
    businessHouseListingData: state.AdvanceSearchState.businessHouseListingData,
    storedPage: state.AdvanceSearchState.storedPage
});

export default connect(mapStateToProps, {})(AdvanceSearch);