import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {advanceSearchConst} from "../../AdvanceSearch/AdvanceSearchConst";
import {setStocksKeywordFilter, setStocksList} from "../../AdvanceSearch/AdvanceSeachAction";
import {isEmpty} from "../../utils/validations";
import {searchOnWordBoundary} from "../../utils/helper";
import {getListingData} from "../../utils/stocksHelper";
import StocksSearchModalView from "./StockSearchModalView";
import {getStocksSummary} from "../../stocks/StocksActions";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function AdvanceStocksSearchModal(props) {
    const{  setFilter,previousWatchlistStockIds}=props;
    const [advanceStockListingModalState, setAdvanceStockListingModalState] = useState({
        modalUIState: '',
        sortedData: [],
        previousStockListing: [],
        skipCount: 0,
        limitCount: 60,
        isSelectedPanelExpanded :false
    });

    useEffect(()=>{
        const {skipCount, limitCount} = advanceStockListingModalState;
        if (props.isStockModalOpen || (!isEmpty(previousWatchlistStockIds))){
            if (!isEmpty(props.stockListingData)){
                setAdvanceStockListingModalState((prev)=>({
                    ...prev,
                    modalUIState: advanceSearchConst.CONTENT_AVAILABLE,
                    previousStockListing: props.stockListingData
                }));
            }else {
                getStocksSummary(VALUATION_DATE, skipCount, limitCount).then((res) => {
                    if (res.success && !isEmpty(res.data?.data)) {
                        let data = getListingData(res.data.data, 'company_name');
                        // store.dispatch(setFunds(data));
                        data = handleExternalCheckChange(previousWatchlistStockIds, data);
                        setStocksList(data);
                        applyExternalFilter(data);

                        setAdvanceStockListingModalState((prev)=>({
                            ...prev,
                            modalUIState: advanceSearchConst.CONTENT_AVAILABLE
                        }));

                    }else {
                        setAdvanceStockListingModalState((prev)=>({
                            ...prev,
                            modalUIState: advanceSearchConst.CONTENT_UNAVAILABLE
                        }));
                    }
                })
            }
        }
    },[props.isStockModalOpen, previousWatchlistStockIds]);

    // useEffect(()=>{
    //     if(isWatchlistCreated){
    //         clearSelectedFunds()
    //     }
    // },[isWatchlistCreated]);

    useEffect(()=>{
        const {stockKeyword} = props;
        const {stockListingData} = props;

        if (isEmpty(stockListingData)){
            return
        }

        if (isEmpty(stockKeyword)){
            setStocksList(stockListingData);

            setAdvanceStockListingModalState((prev)=>({
                ...prev,
                sortedData: [],
                searchResultNotAvailable: false
            }));
        }else {
            let sortedData = [];
            setAdvanceStockListingModalState((prev)=>({
                ...prev,
                isSearchApply: true
            }));

            let options =  stockListingData.map((item)=>{
                return {...item, label:item.company_name, value: item.company_name}
            });

            sortedData = searchOnWordBoundary(props.stockKeyword, JSON.parse(JSON.stringify(options)));

            if (!isEmpty(sortedData)){

                setAdvanceStockListingModalState((prev)=>({
                    ...prev,
                    sortedData: sortedData,
                    searchResultNotAvailable: false
                }));
            }else {
                setAdvanceStockListingModalState((prev)=>({
                    ...prev,
                    sortedData: [],
                    searchResultNotAvailable: true
                }));
            }

            setTimeout(()=>{
                setAdvanceStockListingModalState((prev)=>({
                    ...prev,
                    isSearchApply: false
                }))
            },300)

        }

    },[props.stockKeyword]);

    const handleCheckboxChange = (value) => {
        const {stockListingData} = props;

        const updatedArray = stockListingData.map(item => {
            if (item.isin_code === value.isin_code) {
                return { ...item, isSelected: !item.isSelected };
            }

            // Return the original object if the id doesn't match
            return item;
        });
        setStocksList(updatedArray)

    };

    const clearSelectedFunds = () => {
        const {stockListingData} = props;

        const updatedArray = stockListingData.map(item => {
            return { ...item, isSelected: false };
        });
        setStocksList(updatedArray)
        setAdvanceStockListingModalState((prev) => ({
            ...prev,
            isSelectedPanelExpanded:false,
        }));
    };

    const handleExternalCheckChange = (value, data) => {
        //const {fundHouses} = props.advanceSearchFilter;
        let result;
        if (isEmpty(value)) {
            return data;
        }
        // if(!isEmpty(externalFundStocksKeyword)){
        //     setAdvanceKeywordFilter(externalFundStocksKeyword)
        // }

         result = data.map(item => {
            if (value.includes(item.isin_code)) {
                return { ...item, isSelected: !item.isSelected };
            }
            return item;
        });
        return result;
        //setFundList(updatedArray);
    };

    const checkExternalSelectedValues = (data) => {
        return  data.filter((item)=>item.isSelected);
    };

    const applyExternalFilter =(data)=>{
        let selectedValues = checkExternalSelectedValues(data);
        setFilter('stocks', selectedValues, 'isin_code', false);
        props.history.replace();
    };

    const checkboxChange = (value) => {
        const {stockListingData} = props;


        // Make a copy of the object at the specified index
        const updatedItem = stockListingData.find(item =>  (item.isin_code === value.isin_code));

        return updatedItem?.isSelected
    };

    const onChangeKeywordFilter = (stockKeyword) => {
        setStocksKeywordFilter(stockKeyword)
    };

    const checkSelectedValues = () => {
        const {stockListingData} = props;
        return  stockListingData.filter((item)=>item.isSelected);
    };

    const handleSelectedPanelExpandClick = () =>{
        setAdvanceStockListingModalState((prev) => ({
            ...prev,
            isSelectedPanelExpanded: !prev.isSelectedPanelExpanded,
        }));
    };

    return (
        <StocksSearchModalView {...advanceStockListingModalState}
                                      {...props}
                                      handleCheckboxChange={handleCheckboxChange}
                                      checkboxChange={checkboxChange}
                                      checkSelectedValues={checkSelectedValues}
                                      clearSelectedFunds={clearSelectedFunds}
                                      onChangeKeywordFilter={onChangeKeywordFilter}
                               handleSelectedPanelExpandClick = {handleSelectedPanelExpandClick}/>
    )
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    fundsData: state.fundState.fundsData,
    fundsLoaded: state.fundState.fundsLoaded,
    stockKeyword: state.AdvanceSearchState.stockKeyword
});

export default connect(mapStateToProps, {})(AdvanceStocksSearchModal);
