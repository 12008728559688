import React from 'react';
import DLModalTitle from "dlcomponent/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "dlcomponent/components/DLModal/DLModalContainer/DLModalContainer";
import DLModal from "dlcomponent/components/DLModal/DLModal";
import DLText from "dlcomponent/components/DLText/DLText";
import DLInputTextField from "dlcomponent/components/DLInputTextField/DLInputTextField";
import DLRadioGroup from 'dlcomponent/components/DLRadioGroup/DLRadioGroup';
import DLButton from 'dlcomponent/components/DLButton/DLButton';
import DLModalActions from "dlcomponent/components/DLModal/DLModalActions/DLModalActions";
import DLLoader from "../../core/components/DLLoader/DLLoader";


function CreateWatchListModalView(props) {

    const {
        isActionModalOpen, onChangeValue, handleCreateWatchList, handleRadioButtonChange, onChangeKeywordFilter, isCopyButtonClicked, selectedWatchListObj
        , copyWatchlistData, nameErrorMeassage,isMobileView , isCreating

    } = props;


    const renderDesktopUI = () => {
        return (
            <div>
                <DLLoader type={"screen"} isVisible={isCreating}/>
                <DLModal id={'clear-canvas'}
                         history={props.history}
                         maxWidth={'xs'}
                         open={isActionModalOpen}
                         onClose={() => {
                             props.closeModal();
                             onChangeKeywordFilter('')
                         }}
                >
                    <DLModalTitle id={'campaignDetail-sendTestModalTitle'}
                                  onClose={() => {
                                      onChangeValue('isActionModalOpen', false)
                                  }}
                                  title={"Create your watchlist"}
                                  fontColor="var(--black)"
                                  crossIconFontSize={'24px'}
                    />
                    <DLModalContainer padding={'none'}
                                      id={'clear-canvas-container'}>
                        <div className={'px-3'} style={{marginTop: "24px"}}>
                            {/*<DLText text={'Name*'}*/}
                            {/*        fontWeight={'semi-bold'}*/}
                            {/*        fontColor={'#282828'}*/}
                            {/*        fontSize={'sm'}/>*/}
                            <DLInputTextField label={"Name"}
                                              labelFontColor={'#282828'}
                                              labelFontSize={'md'}
                                              value={selectedWatchListObj.createModalName}
                                              maxCharLength={61}
                                              isClearable={true}
                                              error={nameErrorMeassage !== ""}
                                              isRequired={true}
                                              helperMessage={nameErrorMeassage}
                                              disableHelperMessageHeight={'none'}
                                              marginBottom={'none'}
                                              onChangeValue={(e) => {
                                                  onChangeKeywordFilter(e)
                                              }}
                                              onKeyPress={(event) => {
                                                  if (event.key === 'Enter') {

                                                      document?.activeElement?.blur()
                                                  }
                                              }}
                            />
                            <div className={'mt-4'}>
                                <DLText text={'Contains'}
                                        fontColor={'#282828'}
                                        fontSize={"md"}
                                />
                            </div>
                            <div className={'d-flex'}>
                                <div>
                                    <DLRadioGroup
                                        id={''}
                                        style={{
                                            marginTop: "10px",
                                            fontSize: '14px !important',
                                            fontcolor: '#282828',
                                            marginLeft: ""
                                        }}
                                        marginBottom={'none'}
                                        disableHelperMessageHeight={true}
                                        isDisabled={selectedWatchListObj.popupAction === 'COPY'}
                                        onChangeValue={handleRadioButtonChange}
                                        labelFontSize={'sm'}
                                        value={selectedWatchListObj.popupAction === 'COPY' ? selectedWatchListObj.copyModalType : selectedWatchListObj.createModalType}
                                        options={[
                                            {
                                                label: 'Funds',
                                                value: 'FUNDS'
                                            }]}/>
                                </div>
                                <div style={{
                                 marginLeft: isMobileView ?  "104px" :"130px"
                                }}>
                                    <DLRadioGroup
                                        id={''}
                                        style={{
                                            marginTop: "10px",
                                            fontSize: '14px !important',
                                            fontcolor: '#282828',
                                            marginLeft: "130px"
                                        }}
                                        marginBottom={'none'}
                                        disableHelperMessageHeight={true}
                                        isDisabled={selectedWatchListObj.popupAction === 'COPY'}
                                        onChangeValue={handleRadioButtonChange}
                                        labelFontSize={'sm'}
                                        value={selectedWatchListObj.popupAction === 'COPY' ? selectedWatchListObj.copyModalType : selectedWatchListObj.createModalType}
                                        options={[
                                            {
                                                label: 'Stocks',
                                                value: 'STOCKS'
                                            }]}/>
                                </div>
                            </div>
                        </div>
                        <DLModalActions>
                            <div className={"d-flex justify-content-center gap-3 mb-4 mt-4"}>

                                <DLButton
                                    id={'download-excel'}
                                    styleType={"outlined"}
                                    borderType={'square'}
                                    buttonSize={'sm'}
                                    sideMargin={"none"}
                                    style={{
                                        border: "1px solid #282828",
                                        background: "#fff",
                                        color: "#282828"
                                    }}
                                    onClick={() => {
                                        props.closeModal();
                                        onChangeKeywordFilter('')
                                    }}
                                    label={"Cancel"}
                                />
                                <DLButton id={'download-excel'}
                                          styleType={"outlined"}
                                          borderType={'square'}
                                          buttonSize={'sm'}
                                          sideMargin={"none"}
                                          reduceWidth={true}
                                          style={{
                                              border: "1px solid #282828",
                                              background: "#282828",
                                              color: "#fff"
                                          }}
                                          onClick={() => {
                                              if (isCopyButtonClicked === true) {
                                                  copyWatchlistData(selectedWatchListObj.id, selectedWatchListObj.createModalName);
                                              } else {
                                                  handleCreateWatchList(selectedWatchListObj.createModalName, selectedWatchListObj.createModalType);
                                              }
                                          }}
                                          label={"Create"}/>

                            </div>

                        </DLModalActions>
                    </DLModalContainer>


                </DLModal>
            </div>
        )
    };

    return (
        renderDesktopUI()
    );
}

export default CreateWatchListModalView;