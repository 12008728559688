import React from 'react';
import PropTypes from 'prop-types';
import Chip from "@mui/material/Chip";
import {isEmpty} from "../../utils/validations";
import appTheme from "../../assets/appTheme";
import DLText from "dlcomponent/components/DLText/DLText";
import {getTrimmedValue} from "../../utils/helper";

function AdvanceSearchChipsView(props) {
    const {fundListingData, industryListingData, isXSView, isSMView,  stockListingData, fundHouseListingData, businessHouseListingData, handleChipClick, isMobileView} = props;
    const {fundHouses, businessHouses, funds, marketCaps, industries, stocks, fundHouseForFund} = props.advanceSearchFilter;


    const getNamesFromListing = (listArr, idName, selectedId, valueAttribute) =>{
        let selectedObject = '';
        if (isEmpty(listArr) || isEmpty(selectedId)){
            return
        }
        selectedObject = listArr.find((item)=>item[idName] === selectedId );

       return selectedObject[valueAttribute]
    };

    const checkSelectedValues = () => {
        const {fundListingData} = props;
        let data =   fundListingData.filter((item)=>item.isSelected);
        if (!isEmpty(data)){
            data[0]['fund_id'];
            return isEmpty(data[0]['fund_id']) ? '': data[0]['fund_id']
        }
        return ''
    };

    const getList = (type) =>{

        switch (type) {
            case 'fund_houses':
                if (isMobileView){
                    return 'Fund Houses (' + fundHouses?.length + ')'
                }
                let fundHousesName = getNamesFromListing(fundHouseListingData, 'fund_house_id', fundHouses[0], 'fund_house_name');
                if (isEmpty(fundHouses)){
                    return ''
                }else if (fundHouses.length > 1){
                    return getTrimmedValue(fundHousesName, 12) + ' +' +(fundHouses.length - 1)
                }else {
                    return getTrimmedValue(fundHousesName, 12)
                }
            case 'business_houses':
                if (isMobileView){
                    return 'Business Houses (' +  businessHouses?.length + ')'
                }
                if (isEmpty(businessHouses)){
                    return ''
                }else if (businessHouses.length > 1){
                    return businessHouses[0] + ' +' +(businessHouses.length - 1)
                }else {
                    return businessHouses[0]
                }

            case 'market_cap':
                if (isMobileView){
                    return 'Market Cap (' +  marketCaps?.length + ')'
                }
                if (isEmpty(marketCaps)){
                    return ''
                }else if (marketCaps.length > 1){
                    return marketCaps[0] + ' +' +(marketCaps.length - 1)
                }else {
                    return marketCaps[0]
                }

            case 'stocks':
                if (isMobileView){
                    return 'Stocks (' +  stocks?.length + ')'
                }
                let stocksName = getNamesFromListing(stockListingData, 'isin_code', stocks[0], 'company_name');

                if (isEmpty(stocks)){
                    return ''
                }else if (stocks.length > 1){
                    return getTrimmedValue(stocksName, 12) + ' +' +(stocks.length - 1)
                }else {
                    return getTrimmedValue(stocksName, 12)
                }

            case 'industries':
                if (isMobileView){
                    return 'Sector (' +  industries?.length + ')'
                }
                let industryName = getNamesFromListing(industryListingData, 'industry', industries[0], 'industry');

                if (isEmpty(industries)){
                    return ''
                }else if (industries.length > 1){
                    return getTrimmedValue(industryName, 12) + ' +' +(industries.length - 1)
                }else {
                    return getTrimmedValue(industryName, 12)
                }

            case 'funds':
                if (isMobileView){
                    return 'Funds (' + funds?.length + ')'
                }
                let fundsName = getNamesFromListing(fundListingData, 'fund_id', checkSelectedValues(), 'fund_name');

                if (isEmpty(funds)){
                    return ''
                }else if (funds.length > 1){
                    return getTrimmedValue(fundsName, 12) + ' +' +(funds.length - 1)
                }else {
                    return getTrimmedValue(fundsName, 12)
                }
            default:
                return ''
        }
    };

    return (
        <div className='d-flex ' style={{overflowX: 'auto'}}>
            <div style={{
                marginRight: 4,
                marginBottom: (isXSView || isSMView) ? 4 : 14
            }}>
            <Chip label={
                    <div className='d-flex align-items-center justify-content-between text-black cursor my-2'>
                        {isEmpty(fundHouses)
                            ?
                            <div  onClick={() => handleChipClick('apply_filter', 'isFundHouseModalOpen')}>
                                <DLText
                                    id={'clear'}
                                    marginBottom={'none'}
                                    text={'Fund Houses'}
                                    fontSize={'xs'}/>
                            </div>
                            :
                            <>
                                <DLText
                                    id={'clear'}
                                    marginBottom={'none'}
                                    isClickable={true}
                                    onClick={() => handleChipClick('apply_filter', 'isFundHouseModalOpen')}
                                    customTextStyle={{
                                        color: appTheme.appColor.black
                                    }}
                                    text={isMobileView ? getList('fund_houses') :'Fund Houses: '+getList('fund_houses')}
                                    fontSize={'xs'}/>
                                <i className="fa fa-times cursor ms-2 ms-sm-3"
                                   onClick={() => handleChipClick('remove_filter', 'fundHouses', 'fundHouseListingData')
                                   }/>
                            </>
                        }
                    </div>
                }
                style={{
                    backgroundColor: isEmpty(fundHouses) ? appTheme.appColor.white : '#D2E7FF',
                    width: 'max-content',
                    height: '28px',
                    paddingLeft:'4px',
                    borderRadius: '7px',
                    fontWeight: 400,
                    border:'1px solid #C5C5C5'
                }}
                aria-hidden='true'
                aria-expanded='false'
            />
            </div>
            <div style={{
                marginRight: 4,
                marginBottom: (isXSView || isSMView) ? 4 : 14
            }}>
            <Chip
                label={
                    <div className='d-flex align-items-center justify-content-between text-black cursor my-2'>
                        {isEmpty(funds)
                            ?
                            <div onClick={() => handleChipClick('apply_filter', 'isFundModalOpen')}>
                                <DLText
                                    id={'clear'}
                                    marginBottom={'none'}
                                    text={'Funds'}
                                    fontSize={'xs'}/>
                            </div>
                            :
                            <>
                                <DLText
                                    id={'clear'}
                                    marginBottom={'none'}
                                    isClickable={true}
                                    customTextStyle={{
                                        color: appTheme.appColor.black
                                    }}
                                    onClick={() => handleChipClick('apply_filter', 'isFundModalOpen')}
                                    text={isMobileView ? getList('funds') :'Funds: '+getList('funds')}
                                    fontSize={'xs'}/>
                                <i className="fa fa-times cursor  ms-2 ms-sm-3"
                                   onClick={() =>  handleChipClick('remove_filter', 'funds', 'fundListingData')}/>
                            </>
                        }
                    </div>
                }
                style={{
                    backgroundColor: isEmpty(funds) ? appTheme.appColor.white : '#D2E7FF',
                    width: 'max-content',
                    height: '28px',
                    paddingLeft:'4px',
                    borderRadius: '7px',
                    fontWeight: 400,
                    border:'1px solid #C5C5C5'
                }}
                aria-hidden='true'
                aria-expanded='false'
            />
            </div>
        </div>
    );
}

AdvanceSearchChipsView.propTypes = {

};

export default AdvanceSearchChipsView;