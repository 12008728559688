
import {
     getFundsSummary,
    getStockSummary,
    getStockTrendForFund
} from "../../WatchListAction";
import React, {useEffect, useState} from 'react';
    import {connect} from "react-redux";
    import FundsView from "./FundsView";
    import {useParams} from 'react-router-dom'
    import {isEmpty} from "../../../utils/validations";
    import appTheme from "../../../assets/appTheme";
    import {getNetBuyingSellingPopUpData} from "../../WatchListAction";
    import moment from "moment";
    import {getFixedValuesAfterDecimal} from "../../../utils/helper";


    function Funds(props) {
        const{selectedFundListData,selectedWatchListObj} = props;
        const fundIds = selectedFundListData.map(item => item.fund_id);

        const [fundState,setFundState] = useState({
            activitiesStateLoaded: false,
            activitiesData: {},
            fundHouseCount : '',
            fundSchemeCount : '',
            marketValueChangeMoM: '',
            xAxis: [],
            yAxisBar: [],
            yAxisLine: [],
            barColor: [],
            tabValue: 0,
            stocksCount : 0
        });
        const {fundHouseId, fundId} = useParams();
        const [selectedFund, setSelectedFund] = useState();
        const [tableData, setTableData] = useState([]);

        const [isModalOpen, setIsModalOpen] = useState(false);
        const [popUpModalData,setPopUpModalData] = useState({
            requiredMonth : '',
            entries:[],
            exits:[],
            netBuying:0,
            netSelling:0,
            buyingStocks:0,
            sellingStocks:0,
            netBuyingSellingDifference:0,
            isModalApiInProgress: true
        });

        const handleOpenModal =(graphMonth)=>{
            setIsModalOpen(true);
            getDataForPopUp(graphMonth);
        };
        const handleCloseModal =()=>{
            setIsModalOpen(false);
            setTimeout(()=>{},200);
            initializeZeroCondition()
        };

        const initializeZeroCondition =()=>{

            setPopUpModalData((prevState)=>({
                ...prevState,
                requiredMonth : "",
                exits: "",
                entries: "",
                buyingStocks: 0,
                sellingStocks: 0,
                netBuying: 0,
                netSelling: 0,
                netBuyingSellingDifference: 0,
                isModalApiInProgress: true
            }))
        };

        const getDataForPopUp =(date)=>{
            let formattedDate=moment(date,"MMM-YYYY").endOf('month').format("YYYY-MM-DD");
            getNetBuyingSellingPopUpData(formattedDate,encodeURIComponent(fundHouseId),encodeURIComponent(fundId)).then((res) => {
                if (res?.success) {
                    if (!isEmpty(res?.data?.data)){
                        setPopUpModalData((prevState)=>({
                            ...prevState,
                            requiredMonth : date,
                            exits: res?.data?.data?.exitedStocks,
                            entries: res?.data?.data?.addedStocks,
                            buyingStocks: res?.data?.data?.buyingStocksCount,
                            sellingStocks: res?.data?.data?.sellingStocksCount,
                            netBuying: getFixedValuesAfterDecimal(res?.data?.data?.buyingStocksMarketValue,2),
                            netSelling: getFixedValuesAfterDecimal(res?.data?.data?.sellingStocksMarketValue,2),
                            netBuyingSellingDifference: getFixedValuesAfterDecimal(res?.data?.data?.marketValueChange,2),
                            isModalApiInProgress: false
                        }))
                    }
                }
            });
        };


        useEffect(() => {
            // if (selectedWatchListObj.fundList){
            //     return
            // }

            getStockTrendForFund(fundIds).then((res) => {
                if (res.success && !isEmpty(res.data?.data)) {

                     setBarLineChartData(res.data);
                    setFundState((prevState)=>({
                        ...prevState,
                        marketValue : !isEmpty(res.data?.data) && getFixedValuesAfterDecimal(res.data?.data[res.data?.data.length - 1]?.total_market_value, 2)
                    }));
                }
            });

            getStockSummary(fundIds).then((res) => {
                if (res.success && !isEmpty(res.data?.data)) {
                    setFundState((prevState) => ({
                        ...prevState,
                        // fundSchemeCount: res.data.data.length,
                        stocksCount: !isEmpty(res.data?.data) ? res.data.data.length : [],
                    }));
                    setTableData(res.data.data);
                }
            });

            getFundsSummary(fundIds).then((res) => {
                if (res.success && !isEmpty(res.data?.data)) {
                    setFundState((prev)=>({
                        ...prev,
                        category: res.data?.data?.category,
                        businessHouse: res.data?.data?.businessHouse,
                        industry: res.data?.data?.industry,
                    }));
                }
            })
        }, [selectedWatchListObj.fundList]);

        useEffect(() => {
            setTimeout(()=>{
            },200)
        }, [props.isLGView,props.isXLView, props.isMDView, props.isXSView, props.isSMView]);

        const setBarLineChartData = (chartData) =>{
            let xAxis = [];
            let yAxisBar = [];
            let yAxisLine = [];
            let barColor = [];
            if (isEmpty(chartData)){
                return
            }

            chartData?.data.forEach((item)=>{
                xAxis.push(item?.month);
               yAxisBar.push(getFixedValuesAfterDecimal(item?.total_market_value_change_due_to_activity,2));
                yAxisLine.push(getFixedValuesAfterDecimal(item?.total_market_value,2));
                if (item?.total_market_value_change_due_to_activity >=0){
                    barColor.push(appTheme.appColor.success)
                }else {
                    barColor.push(appTheme.appColor.danger)
                }

            });

            setFundState((prevState)=>({
                ...prevState,
                xAxis: xAxis,
                yAxisBar: yAxisBar,
                barColor: barColor,
                yAxisLine: yAxisLine
            }))
        };

        const onChangeValue = (name, value) => {
            setSelectedFund(value);
        };

        const refresh = () => {
            window.location.reload(true);
        };

        const handleTabChange = (name, value) => {
            setFundState((prev)=>({
                ...prev,
                tabValue: value
            }));
        };


        return (
            <FundsView
                {...props}
                fundIds = {fundIds}
                fundHouseId={fundHouseId}
                fundId ={fundId}
                selectedFund={selectedFund}
                refresh={refresh}
                onChangeValue={onChangeValue}
                handleTabChange={handleTabChange}
                tableData={tableData}
                {...fundState}
                {...popUpModalData}
                isModalOpen={isModalOpen}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
                getDataForPopUp={getDataForPopUp}
            />
        );
    }


    Funds.propTypes = {};
    const mapStateToProps = (state) => ({
        isMobileView: state.appState.deviceInfo.isMobileView,
        profile: state.userState.profile,
        isMDView: state.appState.deviceInfo.isMDView,
        isLGView: state.appState.deviceInfo.isLGView,
        isXLView: state.appState.deviceInfo.isXLView,
        isSMView: state.appState.deviceInfo.isSMView,
        isXSView: state.appState.deviceInfo.isXSView
    });

    export default connect(mapStateToProps, {})(Funds);







