import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import CustomTabPanel from "../CustomTabPanel/CustomTabPanel";
import Button from "@mui/material/Button";

function ChartRadioGroup(props) {

    const {handleRadioChange, isSMView, isXSView, isMobileView, chartType} = props;
    return (
                <div className="m-3">
                    <ButtonGroup variant="contained" aria-label="outlined button group"
                                 style={{
                                     border:'1px solid #d2d2d2',
                                     boxShadow:'none'
                                 }}>
                        <Button style={{fontSize:10,
                            textTransform:'Capitalize',
                            border:'0.5px solid #d2d2d2',
                            padding: '6px 10px',
                            color:(chartType === 'marketValue')?'var(--white)':'var(--black)',
                            backgroundColor:(chartType === 'marketValue')?'var(--black)': 'var(--white)'}}
                                onClick={()=>{handleRadioChange('marketValue')}}>
                            Market Value
                        </Button>
                        <Button style={{fontSize:10,
                            textTransform:'Capitalize',
                            border:'0.5px solid #d2d2d2',
                            padding: '6px 10px',
                            color:(chartType === 'changeMoM')?'var(--white)':'var(--black)',
                            backgroundColor:(chartType === 'changeMoM')?'var(--black)': 'var(--white)'}}
                                onClick={()=>{handleRadioChange('changeMoM')}}>
                            Net Buying
                        </Button>
                        <Button style={{fontSize:10,
                            textTransform:'Capitalize',
                            border:'0.5px solid #d2d2d2',
                            padding: '6px 10px',
                            color:(chartType === 'changeSellingMoM')?'var(--white)':'var(--black)',
                            backgroundColor:(chartType === 'changeSellingMoM') ? 'var(--black)': 'var(--white)'}}
                                onClick={()=>{handleRadioChange('changeSellingMoM')}}>
                            Net Selling
                        </Button>
                        <Button style={{fontSize:10,
                            textTransform:'Capitalize',
                            border:'0.5px solid #d2d2d2',
                            padding: '6px 10px',
                            color:(chartType === 'changePercent')?'var(--white)':'var(--black)',
                            backgroundColor:(chartType === 'changePercent') ? 'var(--black)': 'var(--white)'}}
                                onClick={()=>{handleRadioChange('changePercent')}}>
                            % Net Buying
                        </Button>
                        <Button style={{fontSize:10,
                            textTransform:'Capitalize',
                            border:'0.5px solid #d2d2d2',
                            padding: '6px 10px',
                            color:(chartType === 'changeSellingPercent')?'var(--white)':'var(--black)',
                            backgroundColor:(chartType === 'changeSellingPercent') ? 'var(--black)': 'var(--white)'}}
                                onClick={()=>{handleRadioChange('changeSellingPercent')}}>
                            % Net Selling
                        </Button>
                    </ButtonGroup>
                </div>
    );
}

export default ChartRadioGroup;
