import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {explorerConst} from "../ExplorerConsts";
import {setIndustryKeywordFilter, setIndustryList} from "../ExplorerAction";
import {isEmpty} from "../../utils/validations";
import {searchOnWordBoundary} from "../../utils/helper";
import {getListingData} from "../../utils/stocksHelper";
import ExplorerIndustrySearchModalView from "./ExplorerIndustrySearchModalView";
import {getIndustrySummary} from "../../industry/IndustryAction";
const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function ExplorerIndustrySearchModal(props) {

    const{isExternalSectorSelected, externalSectorData, setFilter,isIndustryModalOpen} = props;
    const [industryListingModalState, setInsuatryListingModalState] = useState({
        modalUIState: '',
        sortedData: [],
        previousIndustryListingData: []
    });

    useEffect(() => {

        if (isExternalSectorSelected || isIndustryModalOpen) {
            if (!isEmpty(props.industryListingData)) {
                setInsuatryListingModalState((prev) => ({
                    ...prev,
                    modalUIState: explorerConst.CONTENT_AVAILABLE,
                    previousIndustryListingData: props.industryListingData
                }));
            } else {
                getIndustrySummary(VALUATION_DATE).then((res) => {
                    if (res?.statusCode === 200 || res?.statusCode === 201) {
                        let data = getListingData(res.data.data, 'industry');
                        data = handleExternalCheckChange(externalSectorData, data);
                        setIndustryList(data);
                        applyExternalFilter(data);

                        setInsuatryListingModalState((prev) => ({
                            ...prev,
                            modalUIState: explorerConst.CONTENT_AVAILABLE
                        }));

                    } else {
                        setInsuatryListingModalState((prev) => ({
                            ...prev,
                            modalUIState: explorerConst.CONTENT_UNAVAILABLE
                        }));
                    }
                })
            }
        }
    }, [isIndustryModalOpen, isExternalSectorSelected]);

    useEffect(()=>{
        const {industryKeyword, isMobileView, isXSView} = props;
        const {industryListingData} = props;

        if (isEmpty(industryListingData)){
            return
        }

        if (isEmpty(industryKeyword)){
            setIndustryList(industryListingData)
            // props.onChangeValue('industryListingData', industryListingData);

            setInsuatryListingModalState((prev)=>({
                ...prev,
                sortedData: [],
                searchResultNotAvailable: false
            }));
        }else {
            let sortedData = [];
            setInsuatryListingModalState((prev)=>({
                ...prev,
                isSearchApply: true
            }));

            let options =  industryListingData.map((item)=>{
                return {...item, label:item.industry, value: item.industry}
            });

            sortedData = searchOnWordBoundary(props.industryKeyword, JSON.parse(JSON.stringify(options)));

            if (!isEmpty(sortedData)){
                setInsuatryListingModalState((prev)=>({
                    ...prev,
                    sortedData: sortedData,
                    searchResultNotAvailable: false
                }));
            }else {
                setInsuatryListingModalState((prev)=>({
                    ...prev,
                    sortedData: [],
                    searchResultNotAvailable: true
                }));
            }

            setTimeout(()=>{
                setInsuatryListingModalState((prev)=>({
                    ...prev,
                    isSearchApply: false
                }))
            },300)

        }

    },[props.industryKeyword]);

    const handleExternalCheckChange = (value, data) => {
        const{isFrom} = props;
        if (isEmpty(value)){
            return data
        }

        let updatedData = [];
        let newArray = data.map(item => ({
            ...item,
            isSelected: value.some(obj2 => obj2.industry === item.industry),
        }));
        return  newArray
    };

    const updateIndustryData = (data) => {
        const {industries} = props.explorerFilter;

        let updatedArray = data.map(item => {
            if (industries.includes(item.industry)) {
                return { ...item, isSelected: true };
            }
            return item;
        });
        return updatedArray

    };

    const applyExternalFilter =(data)=>{
        let selectedValues = checkExternalSelectedValues(data);
        //console.log("in external final stage----",selectedValues)
        setFilter('industries', selectedValues, 'industry');
        props.history.replace();
    };

    const handleCheckboxChange = (value) => {
        const {industryListingData} = props;

        const updatedArray = industryListingData.map(item => {
            if (item.industry === value.industry) {
                return { ...item, isSelected: !item.isSelected };
            }

            // Return the original object if the id doesn't match
            return item;
        });

        setIndustryList(updatedArray)
        // props.onChangeValue('industryListingData', updatedArray);

    };

    const clearSelectedFunds = (value) => {
        const {industryListingData} = props;

        const updatedArray = industryListingData.map(item => {
            return { ...item, isSelected: false };
        });

        setIndustryList(updatedArray)
        // props.onChangeValue('industryListingData', updatedArray);

    };

    const checkboxChange = (value) => {
        const {industryListingData} = props;
        // console.log('updatedArray[index]', !value)

        // Make a copy of the object at the specified index
        const updatedItem = industryListingData.find(item =>  (item.industry === value.industry));

        return updatedItem?.isSelected
    };

    const onChangeKeywordFilter = (industryKeyword) => {
        setIndustryKeywordFilter(industryKeyword)
    };

    const checkExternalSelectedValues = (data) => {
        const {industryListingData} = props;
        return  data.filter((item)=>item.isSelected);
    };

    const checkSelectedValues = () => {
        const {industryListingData} = props;
        return  industryListingData.filter((item)=>item.isSelected);
    };

    return (
        <ExplorerIndustrySearchModalView {...industryListingModalState}
                                         {...props}
                                         handleCheckboxChange={handleCheckboxChange}
                                         checkboxChange={checkboxChange}
                                         checkSelectedValues={checkSelectedValues}
                                         clearSelectedFunds={clearSelectedFunds}
                                         onChangeKeywordFilter={onChangeKeywordFilter}/>
    )
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    industryKeyword: state.ExplorerState.industryKeyword
});

export default connect(mapStateToProps, {})(ExplorerIndustrySearchModal);

