import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {getFundSummary} from "../../fund/FundAction";
import {advanceSearchConst} from "../AdvanceSearchConst";
import {getBusinessHouses, setBusinessHouseKeywordFilter, setBusinessHousesList} from "../AdvanceSeachAction";
import {isEmpty} from "../../utils/validations";
import {getChartData, searchOnWordBoundary} from "../../utils/helper";
import {getListingData} from "../../utils/stocksHelper";
import AdvanceIndustrySearchModalView from "./AdvanceIndustrySearchModalView";
import {getIndustrySummary} from "../../industry/IndustryAction";
import AdvanceBusinessHouseSearchModalView from "./AdvanceBusinessHouseSearchModalView";
const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function AdvanceBusinessHouseSearchModal(props) {
    const [businessHouseListingModalState, setBusinessHouseListingModalState] = useState({
        modalUIState: '',
        sortedData: [],
        previousBusinessHouseListingData: []
    });
    const getListingData = (lisitingData, sortingValues) =>{
        let listing = lisitingData.slice(0, lisitingData.length);

        listing.sort((a, b) => {
            const nameA = a[sortingValues].toLowerCase();
            const nameB = b[sortingValues].toLowerCase();

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        return listing.map((item)=>{
            return {...item, isSelected: false}
        })
    };

    useEffect(()=>{
        if (props.isBusinessHouseModalOpen){
            if (!isEmpty(props.businessHouseListingData)){
                setBusinessHouseListingModalState((prev)=>({
                    ...prev,
                    modalUIState: advanceSearchConst.CONTENT_AVAILABLE,
                    previousBusinessHouseListingData: props.businessHouseListingData
                }));
            }else {
                getBusinessHouses(VALUATION_DATE).then((res) => {
                    if (res.success && !isEmpty(res.data?.data)) {
                        let data = getListingData(res.data.data, 'business_house');
                        // store.dispatch(setFunds(data));
                        setBusinessHousesList(data)
                        // props.onChangeValue('businessHouseListingData', data);

                        setBusinessHouseListingModalState((prev)=>({
                            ...prev,
                            modalUIState: advanceSearchConst.CONTENT_AVAILABLE
                        }));

                    }else {
                        setBusinessHouseListingModalState((prev)=>({
                            ...prev,
                            modalUIState: advanceSearchConst.CONTENT_UNAVAILABLE
                        }));
                    }
                })
            }
        }
    },[props.isBusinessHouseModalOpen]);

    useEffect(()=>{
        const {businessHouseKeyword, isMobileView, isXSView} = props;
        const {businessHouseListingData} = props;

        if (isEmpty(businessHouseListingData)){
            return
        }

        if (isEmpty(businessHouseKeyword)){
            props.onChangeValue('businessHouseListingData', businessHouseListingData);

            setBusinessHouseListingModalState((prev)=>({
                ...prev,
                sortedData: [],
                searchResultNotAvailable: false
            }));
        }else {
            let sortedData = [];
            setBusinessHouseListingModalState((prev)=>({
                ...prev,
                isSearchApply: true
            }));

            let options =  businessHouseListingData.map((item)=>{
                return {...item, label:item.business_house, value: item.business_house}
            });

            sortedData = searchOnWordBoundary(props.businessHouseKeyword, JSON.parse(JSON.stringify(options)));

            if (!isEmpty(sortedData)){

                setBusinessHouseListingModalState((prev)=>({
                    ...prev,
                    sortedData: sortedData,
                    searchResultNotAvailable: false
                }));
            }else {
                setBusinessHouseListingModalState((prev)=>({
                    ...prev,
                    sortedData: [],
                    searchResultNotAvailable: true
                }));
            }

            setTimeout(()=>{
                setBusinessHouseListingModalState((prev)=>({
                    ...prev,
                    isSearchApply: false
                }))
            },300)

        }

    },[props.businessHouseKeyword]);

    const handleCheckboxChange = (value) => {
        const {businessHouseListingData} = props;
        const updatedArray = businessHouseListingData.map((item, index) => {
            if (item.business_house === value.business_house) {
                console.log('values',item.business_house, value.business_house, index)

                return { ...item, isSelected: !item.isSelected };
            }

            // Return the original object if the id doesn't match
            return item;
        });
        setBusinessHousesList(updatedArray)

        // props.onChangeValue('businessHouseListingData', updatedArray);

    };

    const clearSelectedFunds = (value) => {
        const {businessHouseListingData} = props;

        const updatedArray = businessHouseListingData.map(item => {
            return { ...item, isSelected: false };
        });
        setBusinessHousesList(updatedArray)

        // props.onChangeValue('businessHouseListingData', updatedArray);

    };

    const checkboxChange = (value) => {
        const {businessHouseListingData} = props;
        // console.log('updatedArray[index]', !value)

        // Make a copy of the object at the specified index
        const updatedItem = businessHouseListingData.find(item =>  (item.id === value.id));

        return updatedItem?.isSelected
    };

    const onChangeKeywordFilter = (businessHouseKeyword) => {
        setBusinessHouseKeywordFilter(businessHouseKeyword)
    };

    const checkSelectedValues = () => {
        const {businessHouseListingData} = props;
        return  businessHouseListingData?.filter((item)=>item.isSelected);
    };

    return (
        <AdvanceBusinessHouseSearchModalView {...businessHouseListingModalState}
                                        {...props}
                                        handleCheckboxChange={handleCheckboxChange}
                                        checkboxChange={checkboxChange}
                                        checkSelectedValues={checkSelectedValues}
                                        clearSelectedFunds={clearSelectedFunds}
                                        onChangeKeywordFilter={onChangeKeywordFilter}/>
    )
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    businessHouseKeyword: state.AdvanceSearchState.businessHouseKeyword
});

export default connect(mapStateToProps, {})(AdvanceBusinessHouseSearchModal);