import React, {useEffect, useState} from 'react';
import {isEmpty} from "../utils/validations";
import {advanceSearchConst} from "../AdvanceSearch/AdvanceSearchConst";
import {connect} from "react-redux";
import {
    resetAllFilterData,
    resetBusinessHouses,
    resetIndustry,
    resetMarketCap,
    resetStocks,
    setFundHouseKeywordFilter,
    setIndustryKeywordFilter,setExplorerFilterObj,
    setExplorerFilter, setBusinessHouseKeywordFilter,
    setFundsKeywordFilter, setStocksKeywordFilter
} from "../explorer/ExplorerAction";
import {compareByMarketValueChangeMoM, debounce, getFixedValuesAfterDecimal} from "../utils/helper";
import {resetFund, resetFundHouse} from "../fund/FundAction";
import DLLoader from "../core/components/DLLoader/DLLoader";
import {setDrillDownValue} from "../explorer/ExplorerAction";
import MoneyFlowExplorerView from "./MoneyFlowExplorerView";
import {
    getMoneyExplorerDrillDown,
    resetExternalFromAndToDateFilter,
    resetFromAndToDateFilter,
    setFromDate,
    setToDate
} from "./MoneyFlowExplorerAction";
import moment from 'moment'
import {filterAumValue} from "../utils/stocksHelper";

function MoneyFlowExplorer(props) {
    const [moneyFIleExplorerState, setMoneyFIleExplorerState] = useState({
        UIState: '',
        searchResult: [],
        tableHeader: [],
        businessHouses: [],
        funds: [],
        selectedFund: [],
        fund_fundHouse: [],
        industries: [],
        stocks: [],
        marketCaps: [],
        keyword: '',
        urlEndPoint: '',
        resultCount: 0,
        isDataUpdated: false,
        isFundModalOpen: false,
        isMarketCapModalOpen: false,
        isFundHouseModalOpen: false,
        isBusinessHouseModalOpen: false,
        isIndustryModalOpen: false,
        isStockFundModalOpen: false,
        isActionModalOpen: false,
        selectedLine: {},
        chartType: 'Fund_Houses',
        isExternalFundHouseSelected: !!props.location?.state?.selectedFundHouse,
        externalFundHouseData: props.location?.state?.selectedFundHouse,

        isExternalFundSelected: !!props.location?.state?.selectedFund,
        externalFundData: props.location?.state?.selectedFund,

        isExternalSectorSelected: !!props.location?.state?.selectedSector,
        externalSectorData: props.location?.state?.selectedSector,

        isExternalStockSelected: props.location?.state?.selectedStock ? true : false,
        externalStockData: props.location?.state?.selectedStock,

        isFrom: props.location?.state?.isFrom,

        isExternalClick: false,
        maxValuesOnChart: 5,
        fromDateOptions: [
            {
                label: 'Jul-2023',
                value: 'Jul-2023'
            },
            {
                label: 'Aug-2023',
                value: 'Aug-2023'

            },
            {
                label: 'Sep-2023',
                value: 'Sep-2023'

            },
            {
                label: 'Oct-2023',
                value: 'Oct-2023'

            },
            {
                label: 'Nov-2023',
                value: 'Nov-2023'
            },
            {
                label: 'Dec-2023',
                value: 'Dec-2023'
            },
            {
                label: 'Jan-2024',
                value: 'Jan-2024'
            },
            {
                label: 'Feb-2024',
                value: 'Feb-2024'
            },
            {
                label: 'Mar-2024',
                value: 'Mar-2024'
            },
            {
                label: 'Apr-2024',
                value: 'Apr-2024'
            },
            {
                label: 'May-2024',
                value: 'May-2024'
            },
            {
                label: 'Jun-2024',
                value: 'Jun-2024'
            },
            {
                label: 'Jul-2024',
                value: 'Jul-2024'
            },
            {
                label: 'Aug-2024',
                value: 'Aug-2024'
            }
        ],
        toDateOptions: [
            {
                label: 'Jul-2023',
                value: 'Jul-2023'
            },
            {
                label: 'Aug-2023',
                value: 'Aug-2023'

            },
            {
                label: 'Sep-2023',
                value: 'Sep-2023'

            },
            {
                label: 'Oct-2023',
                value: 'Oct-2023'

            },
            {
                label: 'Nov-2023',
                value: 'Nov-2023'
            },
            {
                label: 'Dec-2023',
                value: 'Dec-2023'
            },
            {
                label: 'Jan-2024',
                value: 'Jan-2024'
            },
            {
                label: 'Feb-2024',
                value: 'Feb-2024'
            },
            {
                label: 'Mar-2024',
                value: 'Mar-2024'
            },
            {
                label: 'Apr-2024',
                value: 'Apr-2024'
            },
            {
                label: 'May-2024',
                value: 'May-2024'
            },
            {
                label: 'Jun-2024',
                value: 'Jun-2024'
            },
            {
                label: 'Jul-2024',
                value: 'Jul-2024'
            },
            {
                label: 'Aug-2024',
                value: 'Aug-2024'
            }
        ],
        buying: [],
        selling: [],
        storedDataForDownload: [],
        totalBuying: 0,
        totalSelling: 0,
        isExternalDate: !!props.location?.state?.selectedDate,
        externalDate: props.location?.state?.selectedDate,
        isexternalFilterSelected: !!props.location?.state?.selectedFilters,
        externalFilter: props.location?.state?.selectedFilters,
        isPageFunctionalityBlocked : false,
        isAdvanceSearchExecuted:false
    });

    const [selectedDatasetIndex, setSelectedDatasetIndex] = useState(null);

    useEffect(() => {
        const months = [];
        if (props.history.action === "POP" || props.history.action === "REPLACE") {
            //backward navigation
        }
        else {
            resetListingData()
        }

            setTimeout(() => {
                setMoneyFIleExplorerState((prev) => ({
                    ...prev,
                    isDataUpdated: true,
                    isExternalClick: checkIsExternalClick()
                }));

            }, 0);

        return () => {
            setFundsKeywordFilter('');
            setFundHouseKeywordFilter('');
            setIndustryKeywordFilter('');
            setBusinessHouseKeywordFilter('');
            setStocksKeywordFilter('')
        };
    }, []);

    useEffect(() => {
        if (moneyFIleExplorerState.isexternalFilterSelected) {
            const {funds, fundHouses, stocks, sectors, businessHouses, marketCaps, fundHouseForFund,fundHousesName, fundsName, stocksName, fundHousesData, stocksData, fundsData, sectorsData} = moneyFIleExplorerState.externalFilter;
            const {drillDownBy, toDate, fromDate} = props;

            let data = {
                fromDate: fromDate,
                toDate: toDate,
                viewBy: drillDownBy,
                businessHouses: businessHouses,
                fundHouses: fundHouses,
                funds: funds,
                fundHousesName: fundHousesName,
                fundsName: fundsName,
                marketCaps: marketCaps,
                sectors: sectors,
                stocks: stocks,
                stocksName: stocksName,
                fundHousesForFunds: fundHouseForFund,
            };

            setExplorerFilterObj(data);
            getResult(data);
            setMoneyFIleExplorerState((prev) => ({
                ...prev,
                isPageFunctionalityBlocked : true,
                isexternalFilterSelected : false,
                externalStockData:stocksData,
                externalSectorData:sectorsData,
                externalFundData:fundsData,
                externalFundHouseData: fundHousesData
            }));
        }
        else{
            if (moneyFIleExplorerState.isDataUpdated || moneyFIleExplorerState.isPageFunctionalityBlocked) {
                const {fundHouses, businessHouses, stocksName, funds, fundsName, fundHousesName, marketCaps, industries, stocks, fundHouseForFund} = props.explorerFilter;
                const {drillDownBy, toDate, fromDate} = props;

                setMoneyFIleExplorerState((prev) => ({
                    ...prev,
                    isFilterAPIInProgress: true,
                }));
                let data = {
                    fromDate: fromDate,
                    toDate: toDate,
                    viewBy: drillDownBy,
                    businessHouses: businessHouses,
                    fundHouses: fundHouses,
                    funds: funds,
                    fundHousesName: fundHousesName,
                    fundsName: fundsName,
                    marketCaps: marketCaps,
                    sectors: industries,
                    stocks: stocks,
                    stocksName: stocksName,
                    fundHousesForFunds: fundHouseForFund
                };

                getResult(data);
            }
        }
    }, [props.explorerFilter, props.toDate, props.fromDate, props?.keyword, props.drillDownBy, moneyFIleExplorerState.isDataUpdated]);

    const changePopUpState = (name) => {
        setMoneyFIleExplorerState((prev) => ({
            ...prev,
            [name]: false,
        }));
    };

    const setSelectedLineForPopUpComponent = (lastDrillDown, selectedLine) => {
        console.log("industry1",moneyFIleExplorerState.selectedIndustry);
        if (lastDrillDown === 'fund' || isEmpty(lastDrillDown)) {
            setMoneyFIleExplorerState((prev) => ({
                ...prev,
                selectedFund: selectedLine,
                isExternalFundSelected: true
            }));
            setTimeout(() => {
                setMoneyFIleExplorerState((prev) => ({
                    ...prev,
                    selectedFund: [],
                    isExternalFundSelected: false
                }));
            }, 200)
        }
        if (lastDrillDown === 'fundHouse') {
            setMoneyFIleExplorerState((prev) => ({
                ...prev,
                selectedFundHouse: selectedLine,
                isExternalFundHouseSelected: true
            }));
            setTimeout(() => {
                setMoneyFIleExplorerState((prev) => ({
                    ...prev,
                    selectedFundHouse: [],
                    isExternalFundHouseSelected: false
                }));
            }, 200)
        }
        if (lastDrillDown === 'industry') {
            setMoneyFIleExplorerState((prev) => ({
                ...prev,
                selectedIndustry: selectedLine,
                isExternalSectorSelected: true
            }));
            setTimeout(() => {
                setMoneyFIleExplorerState((prev) => ({
                    ...prev,
                    selectedIndustry: [],
                    isExternalSectorSelected: false
                }));
            }, 200)
        }
        if (lastDrillDown === 'stock') {
            setMoneyFIleExplorerState((prev) => ({
                ...prev,
                selectedStock: selectedLine,
                isExternalStockSelected: true
            }));
            setTimeout(() => {
                setMoneyFIleExplorerState((prev) => ({
                    ...prev,
                    selectedStock: [],
                    isExternalStockSelected: false
                }));
            }, 200)
        }
        if (lastDrillDown === 'businessHouse') {
            setMoneyFIleExplorerState((prev) => ({
                ...prev,
                selectedBusinessHouse: selectedLine,
                isExternalBusinessHouseSelected: true
            }));
            setTimeout(() => {
                setMoneyFIleExplorerState((prev) => ({
                    ...prev,
                    selectedBusinessHouse: [],
                    isExternalBusinessHouseSelected: false
                }));
            }, 200)
        }
        if (lastDrillDown === 'marketCap') {
            setMoneyFIleExplorerState((prev) => ({
                ...prev,
                selectedMarketCap: selectedLine,
                isExternalMarketCapSelected: true
            }));
            setTimeout(() => {
                setMoneyFIleExplorerState((prev) => ({
                    ...prev,
                    selectedMarketCap: [],
                    isExternalMarketCapSelected: false
                }));
            }, 100)
        }
    };

    const getData = (searchResult, drillDownBy) => {
        if (isEmpty(searchResult) || isEmpty(drillDownBy)) {
            return
        }
        let values = Object.values(searchResult);
        let selectedLine = values[0];
        let lastDrillDown = drillDownBy;
        setSelectedLineForPopUpComponent(lastDrillDown, selectedLine)

    };

    const getBuyingSelling = (netBuyingSellingArr) => {

        if (isEmpty(netBuyingSellingArr)) {
            return
        }

        let positiveNumbers = netBuyingSellingArr.filter(number => number?.net_buying_selling > 0.001);
        positiveNumbers.sort(compareByMarketValueChangeMoM);
        let totalBuying = 0;
        positiveNumbers.forEach(item => totalBuying = totalBuying + item.net_buying_selling);

        let negativeNumbers = netBuyingSellingArr.filter(number => number?.net_buying_selling < -0.001);
        negativeNumbers.sort((a, b) => {
            if (Math.abs(a.net_buying_selling) < Math.abs(b.net_buying_selling)) {
                return 1;
            }
            if (Math.abs(a.net_buying_selling) > Math.abs(b.net_buying_selling)) {
                return -1;
            }
        });
        let totalSelling = 0;
        negativeNumbers.forEach(item => totalSelling = totalSelling + item.net_buying_selling);

        setMoneyFIleExplorerState((prev) => ({
            ...prev,
            buying: positiveNumbers,
            totalBuying: filterAumValue(totalBuying),
            totalSelling: getFixedValuesAfterDecimal(totalSelling, 0),
            selling: negativeNumbers,
        }));
    };

    const getResult = debounce((data, drillDownBy) => {

        getMoneyExplorerDrillDown(data).then((res) => {
            if (res.success) {
                if (!isEmpty(res.data?.data)) {
                    getBuyingSelling(res.data.data);
                    setMoneyFIleExplorerState((prev) => ({
                        ...prev,
                        searchResult: res.data?.data,
                        isFilterAPIInProgress: false,
                        resultCount: checkLengthOfResult(res.data?.data),
                        storedDataForDownload: {...data, purpose: 'download'}
                    }));
                    getData(res.data?.data, drillDownBy);
                } else {
                    setMoneyFIleExplorerState((prev) => ({
                        ...prev,
                        searchResult: [],
                        tableHeader: [],
                        buying: [],
                        selling: [],
                        isFilterAPIInProgress: false
                    }));
                }
            }

            setMoneyFIleExplorerState((prev) => ({
                ...prev,
                UIState: advanceSearchConst.CONTENT_AVAILABLE,
                isFilterAPIInProgress: false
            }));
        })
    }, 300);

    const onChangeKeywordFilter = () => {
    };

    const checkIsExternalClick = () => {
        const {isExternalSectorSelected, isExternalFundHouseSelected, isExternalFundSelected} = moneyFIleExplorerState;
        return isExternalSectorSelected || isExternalFundHouseSelected || isExternalFundSelected;
    };

    const handleChipClick = (action, type, listingArr) => {
        if (action === 'apply_filter') {
            setMoneyFIleExplorerState((prev) => ({
                ...prev,
                [type]: true,
                isLineSelected: false,
                selectedLine: {},
            }));
            setSelectedDatasetIndex(null)
        }

        if (action === 'remove_filter') {
            switch (type) {
                case 'funds':
                    setMoneyFIleExplorerState((prev) => ({
                        ...prev,
                        externalFundData:[]
                    }));
                    setSelectedLineForPopUpComponent('fund', []);
                    resetFund();
                    setExplorerFilter('funds', []);
                    setExplorerFilter('fundsName', []);
                    setExplorerFilter('fundsData', []);
                    break;
                case 'fundHouses':
                    setMoneyFIleExplorerState((prev) => ({
                        ...prev,
                        externalFundHouseData:[]
                    }));
                    resetFundHouse();
                    setExplorerFilter('fundHouses', []);
                    setExplorerFilter('fundHousesName', []);
                    setExplorerFilter('fundHousesData', []);
                    setSelectedLineForPopUpComponent('fundHouse', []);
                    break;
                case 'marketCaps':
                    resetMarketCap();
                    removeDrillDownValue();
                    setSelectedLineForPopUpComponent('marketCap', []);
                    break;
                case 'industries':
                    setMoneyFIleExplorerState((prev) => ({
                        ...prev,
                        externalSectorData:[]
                    }));
                    resetIndustry();
                    removeDrillDownValue();
                    setExplorerFilter('sectorsData', []);
                    setSelectedLineForPopUpComponent('industry', []);
                    break;
                case 'stocks':
                    setMoneyFIleExplorerState((prev) => ({
                        ...prev,
                        externalStockData:[]
                    }));
                    resetStocks();
                    removeDrillDownValue();
                    setExplorerFilter('stocksData', []);
                    setSelectedLineForPopUpComponent('stock', []);
                    break;
                case 'businessHouses':
                    resetBusinessHouses();
                    removeDrillDownValue();
                    setSelectedLineForPopUpComponent('businessHouse', []);
                    break;
                default:
            }
            setMoneyFIleExplorerState((prev) => ({
                ...prev,
                [type]: [],
                [listingArr]: []
            }));
        }
    };

    const onChangeValue = (name, value) => {
        setMoneyFIleExplorerState((prev) => ({
            ...prev,
            [name]: value
        }));
    };
    const setDrillDownBy = (drillDownName) => {
        let {selectedLine} = moneyFIleExplorerState;
        console.log("indstry2",moneyFIleExplorerState.selectedIndustry);
        setDrillDownValue(drillDownName);

        if (!isEmpty(selectedLine)) {
            if (drillDownName === "fund") {
                setMoneyFIleExplorerState((prev) => ({
                    ...prev,
                    selectedFund: selectedLine,
                    isExternalFundSelected: true
                }));
                setFilter('funds', selectedLine, 'fund_id')
            } else {
                let lastDrillDown = drillDownName;
                if (lastDrillDown === 'industry') {
                    setFilter('industries', selectedLine, 'industry');
                    setMoneyFIleExplorerState((prev) => ({
                        ...prev,
                        selectedIndustry: selectedLine,
                        isExternalSectorSelected: true
                    }));
                }
                if (lastDrillDown === 'stock') {
                    // setFilter('stocks', selectedLine, 'isin_code');
                    setMoneyFIleExplorerState((prev) => ({
                        ...prev,
                        selectedStock: selectedLine,
                        isExternalStockSelected: true
                    }));
                }
                if (lastDrillDown === 'businessHouse') {
                    // setFilter('businessHouses', selectedLine, 'business_house');
                    setMoneyFIleExplorerState((prev) => ({
                        ...prev,
                        selectedBusinessHouse: selectedLine,
                        isExternalBusinessHouseSelected: true
                    }));
                }
                if (lastDrillDown === 'marketCap') {
                    // setFilter('marketCaps', selectedLine, 'category');
                    setMoneyFIleExplorerState((prev) => ({
                        ...prev,
                        selectedMarketCap: selectedLine,
                        isExternalMarketCapSelected: true
                    }));
                }
            }

            setMoneyFIleExplorerState((prev) => ({
                ...prev,
                selectedLine: [],
                isLineSelected: false
            }));
        }
        setSelectedDatasetIndex(null);
    };

    const setName = (name, selectedValues) => {
        let values = [];
        switch (name) {
            case 'fundHouses':
                selectedValues.forEach((item) => {
                    values.push(item['fund_house_name'])
                });
                setExplorerFilter('fundHousesName', values);
                break;
            case 'funds':
                selectedValues.forEach((item) => {
                    values.push(item['fund_name'])
                });
                setExplorerFilter('fundsName', values);
                break;
            case 'stocks':
                selectedValues.forEach((item) => {
                    values.push(item['company_name'])
                });
                setExplorerFilter('stocksName', values);
                break;
            default:
        }
    };

    const setFilter = (name, selectedValues, idVar) => {
        const {isExternalClick} = moneyFIleExplorerState;
        let values = [];
        if (name === 'funds') {
            let fundsValues = [];
            let fundHouseForFund = [];

            selectedValues.forEach((item) => {
                fundsValues.push(item[idVar]);
                fundHouseForFund.push(item['fund_house_id'])
            });
            setName(name, selectedValues);
            setTimeout(() => {
                setExplorerFilter(name, fundsValues);
                setExplorerFilter('fundHouseForFund', fundHouseForFund);

            }, 300)

        }
        if (name === 'stocks') {
            selectedValues.forEach((item) => {
                values.push(item[idVar])
            });

            setName(name, selectedValues);
            setExplorerFilter(name, values);

        } else {

            selectedValues.forEach((item) => {
                values.push(item[idVar])
            });

            setName(name, selectedValues);
            setTimeout(() => {
                setExplorerFilter(name, values);

            }, 300)
        }

        if (isExternalClick) {
            setMoneyFIleExplorerState((prev) => ({
                ...prev,
                externalSectorData: [],
                externalFundData: [],
                externalFundHouseData: [],
                externalStockData: []
            }));
        }
    };

    const removeDrillDownValue = () => {
    };

    const resetDates = () => {
        if (moneyFIleExplorerState.isExternalDate) {
            resetExternalFromAndToDateFilter(moneyFIleExplorerState.externalDate);
        } else {
            resetFromAndToDateFilter();
        }
    };

    const resetListingData = () => {
        resetAllFilterData();
        resetFund();
        resetFundHouse();
        resetDates();
        setDrillDownBy('fundHouse')
    };


    const checkIsDisableChip = () => {
        const {searchResult} = moneyFIleExplorerState;

        if (isEmpty(searchResult)) {
            return true
        }

        return true
    };

    const checkLengthOfResult = (searchResult) => {

        const valuesFromObject = Object.values(searchResult);
        return valuesFromObject[valuesFromObject.length - 1]?.length
    };


    const getDataOnly = (dataObj, valueAttribute) => {
        if (isEmpty(dataObj) || isEmpty(valueAttribute)) {
            return []
        }

        return dataObj.map((item) => isEmpty(item) ? 0 : item[valueAttribute].toFixed(2))
    };

    const getNameOnly = (dataObj) => {
        const {drillDownBy} = props;

        if (isEmpty(dataObj)) {
            return
        }
        let name;

        switch (drillDownBy) {
            case '':
            case 'fundHouse':
                name = dataObj.find((item) => item?.fund_house_name);
                return name?.fund_house_name;
            case 'fund':
                name = dataObj.find((item) => item?.fund_name);
                return name?.fund_name;
            case 'industry':
                name = dataObj.find((item) => item?.industry);
                return name?.industry;
            case 'stock':
                name = dataObj.find((item) => item?.company_name);
                return name?.company_name;
            case 'businessHouse':
                name = dataObj.find((item) => item?.business_house);
                return name?.business_house;
            case 'marketCap':
                name = dataObj.find((item) => item?.category);
                return name?.category
        }

        return dataObj.find((item) => item?.fund_name)
    };

    const getIdLabelForDrillDown = (viewBy) => {
        let name;
        switch (viewBy) {
            case 'fundHouse':
                name = 'fund_house_name';
                break;
            case 'fund':
                name = 'fund_name';
                break;
            case 'industry':
                name = 'industry';
                break;
            case 'stock':
                name = 'company_name';
                break;
            case 'businessHouse':
                name = 'business_house';
                break;
            case 'marketCap':
                name = 'category';
                break;
            default:
        }
        return name
    };
    const getMaxIterationValue = (viewBy, resultLength) => {
        const {maxValuesOnChart} = moneyFIleExplorerState;
        const {fundHouses, funds, industries, stocks, businessHouses, marketCaps} = props.explorerFilter;
        let length = resultLength >= 5 ? maxValuesOnChart : resultLength;
        switch (viewBy) {
            case 'fundHouse':
                if (!isEmpty(fundHouses)) {
                    length = fundHouses.length;
                }
                break;
            case 'fund':
                if (!isEmpty(funds)) {
                    length = funds.length;
                }
                break;
            case 'industry':
                if (!isEmpty(industries)) {
                    length = industries.length;
                }
                break;
            case 'stock':
                if (!isEmpty(stocks)) {
                    length = stocks.length;
                }
                break;
            case 'businessHouse':
                if (!isEmpty(businessHouses)) {
                    length = businessHouses.length;
                }
                break;
            case 'marketCap':
                if (!isEmpty(marketCaps)) {
                    length = marketCaps.length;
                }
                break;
            default:
                length = 5
        }
        return length
    };

    const closeLineSelectedModal = (name) => {
        setMoneyFIleExplorerState((prev) => ({
            ...prev,
            selectedLine: {},
            [name]: false
        }));
    };

    const handleViewChange = (drillDownName) => {
        if (drillDownName === props.drillDownBy) {
            // setDrillDownValue("");
        } else {
            setMoneyFIleExplorerState((prev) => ({
                ...prev,
                isFilterAPIInProgress: true,
            }));
            setDrillDownValue(drillDownName);
        }
    };

    const handleChartClick = (params) => {
        const clickedLine = params.name;

        if (clickedLine === 'Others') {
            return;
        }
        //check view and check filter related to that view is empty or not
        //if filter is empty then show add as a filter option

        setMoneyFIleExplorerState((prev) => ({
            ...prev,
            selectedLine: clickedLine,
            // menuVisible: true,
            isActionModalOpen: true,
            // menuPosition: { x: offsetX, y:  250 + offsetY }
        }));
    };

    const addLineFilter = () => {
        let {selectedLine, searchResult} = moneyFIleExplorerState;
        const {drillDownBy} = props;
        console.log("selectedIndustry",moneyFIleExplorerState.selectedIndustry);
        switch (drillDownBy) {
            case 'fundHouse':
                selectedLine = searchResult.find(item => item.fund_house_name === selectedLine);
                setFilter('fundHouses', [selectedLine], 'fund_house_id');
                setMoneyFIleExplorerState((prev) => ({
                    ...prev,
                    isActionModalOpen: false,
                    isExternalFundHouseSelected: true,
                    externalFundHouseData: [selectedLine],
                    selectedLine: [],
                    menuVisible: false,
                    menuPosition: {},
                }));

                setTimeout(() => {
                    setMoneyFIleExplorerState((prev) => ({
                        ...prev,
                        isExternalFundHouseSelected: false,
                        externalFundHouseData: []
                    }));
                }, 100);
                break;
            case 'fund':

                selectedLine = searchResult.find(item => item.fund_name === selectedLine);
                setFilter('funds', [selectedLine], 'fund_id');
                setMoneyFIleExplorerState((prev) => ({
                    ...prev,
                    isActionModalOpen: false,
                    isExternalFundSelected: true,
                    externalFundData: selectedLine,
                    selectedLine: [],
                    menuVisible: false,
                    menuPosition: {},
                }));
                setTimeout(() => {
                    setMoneyFIleExplorerState((prev) => ({
                        ...prev,
                        isExternalFundSelected: false,
                        externalFundData: [],
                        selectedFund: []
                    }));
                }, 100);
                break;
            case 'industry':

                selectedLine = searchResult.find(item => item.industry === selectedLine);
                setFilter('industries', [selectedLine], 'industry');
                setMoneyFIleExplorerState((prev) => ({
                    ...prev,
                    isActionModalOpen: false,
                    isExternalSectorSelected: true,
                    externalSectorData:[selectedLine],
                    // selectedIndustry: [selectedLine],
                    selectedLine: [],
                    menuVisible: false,
                    menuPosition: {},
                }));
                setTimeout(() => {
                    setMoneyFIleExplorerState((prev) => ({
                        ...prev,
                        isExternalSectorSelected: false,
                        externalSectorData:[],
                        selectedIndustry: []
                    }));
                }, 100);
                break;
            case 'stock':

                selectedLine = searchResult.find(item => item.company_name === selectedLine);
                setFilter('stocks', [selectedLine], 'isin');
                setMoneyFIleExplorerState((prev) => ({
                    ...prev,
                    isExternalStockSelected: true,
                    externalStockData: [selectedLine],
                    // selectedStock: [selectedLine],
                    selectedLine: [],
                    menuVisible: false,
                    menuPosition: {},
                    isActionModalOpen: false
                }));
                setTimeout(() => {
                    setMoneyFIleExplorerState((prev) => ({
                        ...prev,
                        isExternalStockSelected: false,
                        externalStockData: [],
                        // selectedStock: []
                    }));
                }, 100);
                break;
            case 'businessHouse':
                selectedLine = searchResult.find(item => item.business_house === selectedLine);
                setFilter('businessHouses', [selectedLine], 'business_house');
                setMoneyFIleExplorerState((prev) => ({
                    ...prev,
                    isExternalBusinessHouseSelected: true,
                    selectedBusinessHouse: [selectedLine],
                    selectedLine: [],
                    menuVisible: false,
                    menuPosition: {},
                    isActionModalOpen: false
                }));
                setTimeout(() => {
                    setMoneyFIleExplorerState((prev) => ({
                        ...prev,
                        isExternalBusinessHouseSelected: false,
                        selectedBusinessHouse: []
                    }));
                }, 100);
                break;
            case 'marketCap':

                selectedLine = searchResult.find(item => item.category === selectedLine);
                setFilter('marketCaps', [selectedLine], 'category');
                setMoneyFIleExplorerState((prev) => ({
                    ...prev,
                    isExternalMarketCapSelected: true,
                    selectedMarketCap: selectedLine,
                    selectedLine: [],
                    menuVisible: false,
                    menuPosition: {},
                    isActionModalOpen: false
                }));
                setTimeout(() => {
                    setMoneyFIleExplorerState((prev) => ({
                        ...prev,
                        isExternalMarketCapSelected: false,
                        selectedMarketCap: []
                    }));
                }, 100);
                break;
            default:
        }

    };
    const getFileName = (blockName) => {
        let date = new Date();
        return blockName + moment(date).format('YYYY-MM-DD')
    };

    if (isEmpty(moneyFIleExplorerState.UIState)) {
        return <DLLoader type={"screen"} isVisible={true}/>
    }

    return (
        <MoneyFlowExplorerView {...moneyFIleExplorerState}

                               {...props}
                               selectedDatasetIndex={selectedDatasetIndex}
                               handleChipClick={handleChipClick}
                               onChangeValue={onChangeValue}
                               setFilter={setFilter}
                               resetListingData={resetListingData}
                               checkIsDisableChip={checkIsDisableChip}
                               onChangeKeywordFilter={onChangeKeywordFilter}
                               removeDrillDownValue={removeDrillDownValue}
                               setDrillDownBy={setDrillDownBy}
                               closeLineSelectedModal={closeLineSelectedModal}
                               getDataOnly={getDataOnly}
                               getNameOnly={getNameOnly}
                               changePopUpState={changePopUpState}
                               handleViewChange={handleViewChange}
                               getMaxIterationValue={getMaxIterationValue}
                               addLineFilter={addLineFilter}
                               getIdLabelForDrillDown={getIdLabelForDrillDown}
                               setFromDate={setFromDate}
                               setToDate={setToDate}
                               handleChartClick={handleChartClick}
                               getFileName={getFileName}
                               setMoneyFIleExplorerState={setMoneyFIleExplorerState}
        />
    );
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    explorerFilter: state.ExplorerState.explorerFilter,
    fundHouseListingData: state.fundState.fundHouseListingData,
    fundListingData: state.fundState.fundListingData,
    marketCapData: state.ExplorerState.marketCapData,
    industryListingData: state.ExplorerState.industryListingData,
    stockListingData: state.ExplorerState.stockListingData,
    businessHouseListingData: state.ExplorerState.businessHouseListingData,
    drillDownBy: state.ExplorerState.drillDownBy,
    fromDate: state.MoneyExplorerState.fromDate,
    toDate: state.MoneyExplorerState.toDate,
});

export default connect(mapStateToProps, {})(MoneyFlowExplorer);

