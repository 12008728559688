import React, {useEffect, useState} from "react";
import {
    getMonthlyActivitySummaryForFundHouses,
    getNetActivityByFund,
    getNetActivityByFundHouse,
    getSummaryByFundHouse
} from "../StocksActions";
import SummaryCard from "../../component-utils/SummaryCard/SummaryCard";
import SummaryByFundTable from "../SummaryByFund/SummaryByFundTable";
import CustomTabPanel from "../../component-utils/CustomTabPanel/CustomTabPanel";
import MonthlySummaryByFundHouseTable from "./MonthlySummaryByFundHouseTable";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function SummaryCardFundHouse(props) {

    const {isin} = props;

    const [fundSummary, setFundSummary] = useState({
        buyingFundsSummary: {marketValue: 0, count: 0},
        sellingFundsSummary: {marketValue: 0, count: 0},
        netFundsSummary: {marketValue: 0, count: 0},
        count:0
    });
    const [fundHouseSummary, setFundHouseSummary] = useState(
        {
            buyingFundHousesSummary: {marketValue: 0, count: 0},
            sellingFundHousesSummary: {marketValue: 0, count: 0},
            netFundHousesSummary: {marketValue: 0, count: 0},
            count:0
        }
    );
    const [stockSummary, setStockSummary] = useState({
        marketValue: 0,
        marketValueChangeMoM: 0,
        marketValueChangePercentage: 0
    });

    const [monthlyActivity, setMonthlyActivity] = useState([]);

    useEffect(() => {

        getSummaryByFundHouse(VALUATION_DATE, isin).then((res) => {
            if (res?.success) {
                setAUMData(res.data.data);
                setFundHouseSummary((prevState)=>({
                    ...prevState,
                    count: res.data.data.length
                }));
                setFundSummary((prevState)=>({
                    ...prevState,
                    count: res.data.data.length
                }))
            }
        })

    }, []);

    useEffect(() => {

        getMonthlyActivitySummaryForFundHouses( isin,VALUATION_DATE).then((res) => {
            if (res?.success) {
                setMonthlyActivity(res.data.data);
            }
        })

    }, []);

    const setAUMData = (data) => {

        let totalAUM = 0;
        let totalChangeMoM = 0;
        let percentChange = 0;

        for (let i = 0; i < data.length; i++) {

            let marketValue = data[i].total_market_value;
            let marketValueChangeMoM = data[i].market_value_change_mom;

            totalAUM += marketValue;
            totalChangeMoM += marketValueChangeMoM;

        }
        percentChange = totalChangeMoM/ totalAUM * 100;


        setStockSummary({
            marketValue: totalAUM.toFixed(2),
            marketValueChangeMoM: totalChangeMoM.toFixed(2),
            marketValueChangePercentage: percentChange.toFixed(2)
        })
    };

    const renderMonthlyActivitySummaryTable = () => {

        if (monthlyActivity.length === 0) {
            return (<div/>);
        }
        return (
            <div className="row m-3">
                <div className='col-12'>
                    <MonthlySummaryByFundHouseTable data={monthlyActivity}/>
                </div>
            </div>
        );
    };


    return (
        <div>
            <div className="row m-3">
                <div className='col-3'>
                    <SummaryCard primaryValue={'Rs.' + stockSummary.marketValue}
                                 primaryValueTitle={'Total AUM'}
                                 secondaryValue={'MoM Change Rs.' + stockSummary.marketValueChangeMoM}
                                 secondaryValueTitle={'(' + stockSummary.marketValueChangePercentage + '%)'}
                    />
                </div>
                <div className='col-3'>
                    <SummaryCard primaryValue={fundHouseSummary.count}
                                 primaryValueTitle={'Number of Fund Houses'}
                    />
                </div>
                <div className='col-3'>
                    <SummaryCard primaryValue={fundSummary.count}
                                 primaryValueTitle={'Number of Fund Schemes'}
                    />
                </div>
            </div>

            {renderMonthlyActivitySummaryTable()}
        </div>
    )
}

export default SummaryCardFundHouse;