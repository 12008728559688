import {createSlice} from '@reduxjs/toolkit'

const appSlice = createSlice({
    name: 'AdvanceFundSearchReducer',
    initialState: {
        storedPage : 0,
        orderBy : '',
        order : '' ,
        page:0,
        rowsPerPage:50,
    },
    reducers: {
        setAdvanceStockTablePage(state, action) {
            state.storedPage = action.payload;
        },
        setOrderBy (state,action) {
            state.orderBy = action.payload;
        },
        setOrder (state,action) {
            state.order = action.payload;
        },
        setPage (state,action) {
            state.page = action.payload;
        },
        setRowsPerPage (state,action) {
            state.rowsPerPage = action.payload;
        },
        resetOrderBy(state,action){
            state.orderBy = ''
        },
        resetOrder(state,action){
            state.order = ''
        },
        resetPage(state,action){
            state.page = 0
        },
        resetRowsPerPage(state,action){
            state.rowsPerPage = 50
        },
    }
});

export const {setAdvanceStockTablePage,setOrder,setOrderBy,setPage,setRowsPerPage,resetOrder,resetOrderBy,resetPage,resetRowsPerPage} = appSlice.actions;
export default appSlice.reducer
