import React from 'react';
import {useHistory} from 'react-router-dom';
import DLText from 'dlcomponent/components/DLText/DLText';
import DLButton from 'dlcomponent/components/DLButton/DLButton';
import notfound from "../../assets/img/notfound.jpg";

const PageNotFoundView = () => {
    const history = useHistory();

    return (<div className={"h-100"}>
            <div className={" py-md-5 py-5"}>
                <div className={"m-md-5 p-3"}>
                    <div className={"pt-3"}>
                        <DLText text={'Oops!'}
                                customWrapperStyle={{textAlign: 'center'}}
                                marginBottom={"sm"}
                                id={'page-not-found-oops'}
                                type={"normal"}
                                fontSize={"xl"}
                                fontWeight={"bold"}/>
                        <DLText text={'Page Not Found!'}
                                customWrapperStyle={{textAlign: 'center'}}
                                marginBottom={"sm"}
                                id={'page-not-found'}
                                type={"normal"}
                                fontSize={"lg"}
                                fontWeight={"semi-bold"}
                                fontColor={'gray'}/>
                        <DLText text={'The link you have followed probably broken or the page has been removed.'}
                                type={"normal"}
                                id={'broken-link'}
                                marginBottom={"sm"}
                                customWrapperStyle={{textAlign: 'center'}}
                                fontSize={"sm"}
                                fontWeight={"semi-bold"}
                                fontColor={'gray'}/>
                    </div>
                    <div className={"pb-2 justify-content-center d-flex align-item-center"}>
                        <img src={notfound}
                             alt={'img'}
                             className={'img-fluid'}
                             width={'300px'}
                             height={'200px'}/>
                    </div>
                    <div className='text-center'>
                        <DLButton id={'home-button'}
                                  history={history}
                                  buttonSize={'sm'}
                                  type={'primary'}
                                  reduceWidth={true}
                                  onClick={() => history.push('/')}
                                  label={'Home'}/>
                    </div>
                </div>
            </div>
        </div>);
};

PageNotFoundView.propTypes = {};

export default PageNotFoundView;
