import BarChart from "../../component-utils/charts/BarChart";
import React from "react";

const IndustryChart = (props) => {
    const {xAxis, yAxis, handleClick} = props;
    return(
        <BarChart data={[{
            label: '',
            data: xAxis,
            backgroundColor: '#0073ff',
            borderColor: '#0073ff',
            barThickness: 15,
            maxBarThickness: 80
        }]}
                  direction={'y'}
                  xLabels={yAxis}
                  onClick={handleClick}
        />

    )
}

export default IndustryChart;