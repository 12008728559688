import i18n from "i18next";
import {isEmpty} from "./utils/helperCommon";

export const userContentKeys = [
  {
    "key": "LABEL_SIGN_IN",
    "areaOfUsage": "User",
    "value": {
      en: "Sign in",
      hi: "साइन इन करें"
    }
  },
  {
    "key": "LABEL_SIGN_IN_HERE",
    "areaOfUsage": "User",
    "value": {
      en: "Sign in here",
      hi: "यहां साइन इन करो"
    }
  },
  {
    "key": "LABEL_SIGN_UP",
    "areaOfUsage": "User",
    "value": {
      en: "Sign up",
      hi: "साइन अप करें"
    }
  },
  {
    "key": "LABEL_ALREADY_REGISTERED",
    "areaOfUsage": "User",
    "value": {
      en: "Already registered?",
      hi: "पहले से पंजीकृत"
    }
  },
  {
    "key": "LABEL_WELCOME_MESSAGE",
    "areaOfUsage": "User",
    "value": {
      en: "Welcome to Unikom",
      hi: "यूनिकोम में आपका स्वागत है"
    }
  },
  {
    "key": "LABEL_FIRST_NAME",
    "areaOfUsage": "User",
    "value": {
      en: "First Name",
      hi: "पहला नाम"
    }
  },
  {
    "key": "LABEL_LAST_NAME",
    "areaOfUsage": "User",
    "value": {
      en: "Last Name",
      hi: "अंतिम नाम"
    }
  },
  {
    "key": "LABEL_EMAIL",
    "areaOfUsage": "User",
    "value": {
      en: "Email",
      hi: "ईमेल"
    }
  },
  {
    "key": "LABEL_PASSWORD",
    "areaOfUsage": "User",
    "value": {
      en: "Password",
      hi: "पासवर्ड"
    }
  },
  {
    "key": "LABEL_CONFIRM_PASSWORD",
    "areaOfUsage": "User",
    "value": {
      en: "Confirm Password",
      hi: "पासवर्ड की पुष्टि कीजिये"
    }
  },
  {
    "key": "LABEL_FORGOT_PASSWORD",
    "areaOfUsage": "User",
    "value": {
      en: "Forgot Password",
      hi: "पासवर्ड भूल गए"
    }
  },
  {
    "key": "LABEL_REMEMBER_ME",
    "areaOfUsage": "User",
    "value": {
      en: "Remember me",
      hi: "मुझे याद रखें"
    }
  },
  {
    "key": "LABEL_SUBMIT",
    "areaOfUsage": "User",
    "value": {
      en: "Submit",
      hi: "प्रस्तुत करें"
    }
  },
  {
    "key": "LABEL_USER_NOT_EXISTS",
    "areaOfUsage": "User",
    "value": {
      en: "User does not exist",
      hi: "उपयोगकर्ता मौजूद नहीं है"
    }
  },
  {
    "key": "LABEL_SIGN_IN_FAILURE",
    "areaOfUsage": "User",
    "value": {
      en: "Sign in failure",
      hi: "साइन इन विफलता"
    }
  },
  {
    "key": "LABEL_RESET_PASSWORD",
    "areaOfUsage": "User",
    "value": {
      en: "Reset password",
      hi: "पासवर्ड रीसेट"
    }
  },
  {
    "key": "LABEL_USER_ALREADY_EXISTS",
    "areaOfUsage": "User",
    "value": {
      en: "User already exists",
      hi: "उपयोगकर्ता पहले से मौजूद है"
    }
  },
  {
    "key": "LABEL_USER_ALREADY_EXISTS_IN_SYSTEM",
    "areaOfUsage": "User",
    "value": {
      en: "This user already exists in the system.",
      hi: "हा वापरकर्ता आधीपासून सिस्टममध्ये अस्तित्वात आहे."
    }
  },
  {
    "key": "LABEL_RESEND",
    "areaOfUsage": "User",
    "value": {
      en: "Resend",
      hi: "पुन: भेजें"
    }
  },
  {
    "key": "LABEL_DIDNT_GET_CODE",
    "areaOfUsage": "User",
    "value": {
      en: "Didnt get the code?",
      hi: "कोड नहीं मिला?"
    }
  },
  {
    "key": "LABEL_CODE_EXPIRY_TEXT",
    "areaOfUsage": "User",
    "value": {
      en: "The verification code will expire in 30 minutes",
      hi: "सत्यापन कोड 30 मिनट में समाप्त हो जाएगा"
    }
  },
  {
    "key": "LABEL_VERIFICATION_FAILED",
    "areaOfUsage": "User",
    "value": {
      en: "Verification failed",
      hi: "सत्यापन असफल"
    }
  },
  {
    "key": "LABEL_SEND_VERIFICATION_AGAIN",
    "areaOfUsage": "User",
    "value": {
      en: "Send verification code again",
      hi: "सत्यापन कोड दोबारा भेजें"
    }
  },
  {
    "key": "LABEL_GENERATE_VERIFICATION_AGAIN",
    "areaOfUsage": "User",
    "value": {
      en: "You may want to generate verification code again.",
      hi: "आप फिर से सत्यापन कोड जनरेट करना चाह सकते हैं"
    }
  },
  {
    "key": "LABEL_ERROR_PASSWORD_LIMIT",
    "areaOfUsage": "User",
    "value": {
      en: "Password should not be less than 8 characters",
      hi: "पासवर्ड 8 अक्षरों से कम का नहीं होना चाहिए"
    }
  },
  {
    "key": "LABEL_ERROR_LIMIT_50",
    "areaOfUsage": "User",
    "value": {
      en: "Please enter upto 50 characters",
      hi: "कृपया 50 वर्ण तक दर्ज करें"
    }
  },
  {
    "key": "LABEL_ERROR_LIMIT_30",
    "areaOfUsage": "User",
    "value": {
      en: "Please enter upto 30 characters",
      hi: "कृपया 30 वर्ण तक दर्ज करें"
    }
  },
  {
    "key": "LABEL_ERROR_FIRST_NAME",
    "areaOfUsage": "User",
    "value": {
      en: "Enter First Name",
      hi: "प्रथम नाम दर्ज करें"
    }
  },
  {
    "key": "LABEL_ERROR_EMAIL",
    "areaOfUsage": "User",
    "value": {
      en: "Enter Email Address",
      hi: "ईमेल पता दर्ज करें"
    }
  },
  {
    "key": "LABEL_ERROR_EMAIL_VALIDATION",
    "areaOfUsage": "User",
    "value": {
      en: "Enter valid email or remove special characters other than @, -, _ or period",
      hi: "मान्य ईमेल दर्ज करें या @, -, _ या अवधि . के अलावा अन्य विशेष वर्ण हटा दें"
    }
  },
  {
    "key": "LABEL_ERROR_PASSWORD",
    "areaOfUsage": "User",
    "value": {
      en: "Enter a password",
      hi: "एक पासवर्ड दर्ज करें"
    }
  },
  {
    "key": "LABEL_ERROR_ENTER_PASSWORD",
    "areaOfUsage": "User",
    "value": {
      en: "Please enter your password",
      hi: "अपना पासवर्ड दर्ज करें"
    }
  },
  {
    "key": "LABEL_ERROR_ENTER_CONFIRM_PASSWORD",
    "areaOfUsage": "User",
    "value": {
      en: "Please re-enter your password",
      hi: "अपना पासवर्ड पुनः दर्ज करें"
    }
  },
  {
    "key": "LABEL_ERROR_CONFIRM_PASSWORD",
    "areaOfUsage": "User",
    "value": {
      en: "Enter a confirmation password",
      hi: "एक पुष्टिकरण पासवर्ड दर्ज करें"
    }
  },
  {
    "key": "LABEL_ERROR_PASSWORD_LENGTH",
    "areaOfUsage": "User",
    "value": {
      en: "Password should not be less than 8 characters",
      hi: "पासवर्ड 8 अक्षरों से कम का नहीं होना चाहिए"
    }
  },
  {
    "key": "LABEL_ERROR_PASSWORD_VALIDATION",
    "areaOfUsage": "User",
    "value": {
      en: "At least one character and one digit and one special character",
      hi: "कम से कम एक वर्ण और एक अंक और एक विशेष वर्ण"
    }
  },
  {
    "key": "LABEL_ERROR_PASSWORD_2",
    "areaOfUsage": "User",
    "value": {
      en: "Please enter your password first",
      hi: "कृपया पहले अपना पासवर्ड दर्ज करें"
    }
  },
  {
    "key": "LABEL_TERMS_AND_CONDITIONS",
    "areaOfUsage": "User",
    "value": {
      en: "By continuing, you agree to our Terms, Data Policy and Cookie Policy.",
      hi: "जारी रखकर, आप हमारी शर्तों, डेटा नीति और कुकी नीति से सहमत होते हैं।"
    }
  },
  {
    "key": "LABEL_OR",
    "areaOfUsage": "User",
    "value": {
      en: "Or",
      hi: "या"
    }
  },
  {
    "key": "LABEL_PASSWORD_DO_NOT_MATCHED",
    "areaOfUsage": "User",
    "value": {
      en: "Password is not matching",
      hi: "पासवर्ड मेल नहीं खाता"
    }
  },
  {
    "key": "LABEL_NEW_HERE",
    "areaOfUsage": "User",
    "value": {
      en: "New here? ",
      hi: "अब यहां?"
    }
  },
  {
    "key": "LABEL_SIGN_IN_FAILURE",
    "areaOfUsage": "User",
    "value": {
      en: "Sign In Failure",
      hi: "साइन इन विफलता"
    }
  },
  {
    "key": "LABEL_SIGN_IN_FAILURE_TEXT",
    "areaOfUsage": "User",
    "value": {
      en: "Either user doesn\'t exist or email password combination is incorrect.",
      hi: "या तो उपयोगकर्ता मौजूद नहीं है या ईमेल पासवर्ड संयोजन गलत है"
    }
  },
  {
    "key": "LABEL_USER_DOES_NOT_EXIST",
    "areaOfUsage": "User",
    "value": {
      en: "Email <0></0> does not exist. Please check your email address again.",
      hi: "ईमेल <0></0> मौजूद नहीं है"
    }
  },
  {
    "key": "LABEL_SIGN_UP_TO_REGISTER",
    "areaOfUsage": "User",
    "value": {
      en: "Please <0></0> to register.",
      hi: "रजिस्टर करने के लिए कृपया <0></0> करें"
    }
  },
  {
    "key": "LABEL_CREATE_NEW_PASSWORD",
    "areaOfUsage": "User",
    "value": {
      en: "Create a new password",
      hi: "एक नया पासवर्ड बनाएँ"
    }
  },
  {
    "key": "LABEL_ERROR_VALIDATION_PASSWORD",
    "areaOfUsage": "User",
    "value": {
      en: "Password is case sensitive and must be atleast 8 characters.",
      hi: "पासवर्ड केस संवेदी है और कम से कम 8 वर्णों का होना चाहिए|"
    }
  },
  {
    "key": "LABEL_NEW_PASSWORD",
    "areaOfUsage": "User",
    "value": {
      en: "Type new password",
      hi: "नया पासवर्ड टाइप करें"
    }
  },
  {
    "key": "LABEL_CONFIRM_NEW_PASSWORD",
    "areaOfUsage": "User",
    "value": {
      en: 'Confirm new password',
      hi: 'नए पासवर्ड की पुष्टि करें'
    }
  },
  {
    "key": "LABEL_SET_NEW_PASSWORD",
    "areaOfUsage": "User",
    "value": {
      en: 'Set new password',
      hi: 'नया पासवर्ड सेट करें'
    }
  },
  {
    "key": "LABEL_PASSWORD_SET_SUCCESSFULLY",
    "areaOfUsage": "User",
    "value": {
      en: 'Password set successfully!',
      hi: 'पासवर्ड सफलतापूर्वक सेट किया गया!'
    }
  },
  {
    "key": "LABEL_LINK_EXPIRED",
    "areaOfUsage": "User",
    "value": {
      en: 'Expired link',
      hi: 'समय सीमा समाप्त लिंक'
    }
  },
  {
    "key": "LABEL_PASSWORD_RESET_FAILED",
    "areaOfUsage": "User",
    "value": {
      en: 'Password reset failed',
      hi: 'पासवर्ड रीसेट विफल'
    }
  },
  {
    "key": "LABEL_PASSWORD_RESET_UNSUCCESSFUL",
    "areaOfUsage": "User",
    "value": {
      en: 'Your Password reset is unsuccessful. Please try again or contact us.',
      hi: 'आपका पासवर्ड रीसेट असफल है। कृपया पुन: प्रयास करें या हमसे संपर्क करें.'
    }
  },
  {
    "key": "LABEL_TRY_AGAIN",
    "areaOfUsage": "User",
    "value": {
      en: 'Try Again',
      hi: 'पुनः प्रयास करें'
    }
  },
  {
    "key": "LABEL_CONTACT_US",
    "areaOfUsage": "User",
    "value": {
      en: 'Contact us',
      hi: 'संपर्क करें'
    }
  },
  {
    "key": "LABEL_PASSWORD_LINK_SENT",
    "areaOfUsage": "User",
    "value": {
      en: 'Password reset link sent',
      hi: 'पासवर्ड रीसेट लिंक भेजा गया'
    }
  },
  {
    "key": "LABEL_VERIFY_YOUR_EMAIL",
    "areaOfUsage": "User",
    "value": {
      en: 'Verify Your Email',
      hi: 'अपना ई मेल सत्यापित करें'
    }
  },
  {
    "key": "LABEL_VERIFY",
    "areaOfUsage": "User",
    "value": {
      en: 'Verify',
      hi: 'सत्यापित करें'
    }
  },
  {
    "key": "LABEL_TYPE_SECURITY_CODE",
    "areaOfUsage": "User",
    "value": {
      en: 'Type your 6 digit security code',
      hi: 'अपना 6 अंकों का सुरक्षा कोड टाइप करें'
    }
  },
  {
    "key": "LABEL_ERROR_VERIFICATION_CODE",
    "areaOfUsage": "User",
    "value": {
      en: 'Please enter a correct verification code',
      hi: 'कृपया सही सत्यापन कोड दर्ज करें'
    }
  },
  {
    "key": "LABEL_ERROR_ENTER_VERIFICATION_CODE",
    "areaOfUsage": "User",
    "value": {
      en: 'Please provide a verification code',
      hi: 'कृपया एक सत्यापन कोड प्रदान करें'
    }
  },
  {
    "key": "LABEL_CODE_EXPIRY",
    "areaOfUsage": "User",
    "value": {
      en: 'The verification code will expire in next 60 minutes',
      hi: 'सत्यापन कोड अगले 60 मिनट में समाप्त हो जाएगा'
    }
  },
  {
    "key": "LABEL_DIDNT_GET_CODE",
    "areaOfUsage": "User",
    "value": {
      en: 'Didn’t get the code? ',
      hi: 'कोड नहीं मिला?'
    }
  },
  {
    "key": "LABEL_RESET_PASSWORD_EMAIL",
    "areaOfUsage": "User",
    "value": {
      en: 'Please enter your email address to reset the password ',
      hi: 'पासवर्ड रीसेट करने के लिए कृपया अपना ईमेल पता दर्ज करें'
    }
  },
  {
    "key": "LABEL_RESET",
    "areaOfUsage": "User",
    "value": {
      en: 'Reset',
      hi: 'रीसेट'
    }
  },
  {
    "key": "LABEL_VERIFICATION_THANK_YOU",
    "areaOfUsage": "User",
    "value": {
      en: 'Thank you for the verification',
      hi: 'सत्यापन के लिए धन्यवाद'
    }
  },
  {
    "key": "LABEL_PROMOTIONS",
    "areaOfUsage": "User",
    "value": {
      en: 'promotions',
      hi: 'प्रोन्नति'
    }
  },
  {
    "key": "LABEL_SPAM",
    "areaOfUsage": "User",
    "value": {
      en: 'spam',
      hi: 'अवधि'
    }
  },
  {
    "key": "LABEL_SIGN_IN_TO_USE_SERVICES",
    "areaOfUsage": "User",
    "value": {
      en: 'To use the services, please sign in.',
      hi: 'सेवाओं का उपयोग करने के लिए, कृपया साइन इन करें'
    }
  },
  {
    "key": "LABEL_PASSWORD_LINK_EXPIRED_TEXT",
    "areaOfUsage": "User",
    "value": {
      en: 'The password reset link has either expired or has been used already.',
      hi: 'पासवर्ड रीसेट लिंक या तो समाप्त हो गया है या पहले ही उपयोग किया जा चुका है'
    }
  },
  {
    "key": "LABEL_VERIFICATION_CODE_TEXT",
    "areaOfUsage": "User",
    "value": {
      en: 'Please check the <0><0></0></0> tab for the verification code',
      hi: 'सत्यापन कोड के लिए कृपया <0><0></0></0> टैब देखें'
    }
  },
  {
    "key": "LABEL_VERIFICATION_CODE_TEXT_1",
    "areaOfUsage": "User",
    "value": {
      en: 'We have <0></0> verification code to <1></1>',
      hi: 'हमने <1></1> पर एक सत्यापन कोड <0></0> दिया है'
    }
  },
  {
    "key": "LABEL_SENT",
    "areaOfUsage": "User",
    "value": {
      en: 'sent',
      hi: ' भेज '
    }
  },
  {
    "key": "LABEL_RESENT",
    "areaOfUsage": "User",
    "value": {
      en: 're-sent',
      hi: ' फिर से भेज '
    }
  },{
    "key": "LABEL_THIS_USER_DOES_NOT_EXISTS",
    "areaOfUsage": "User",
    "value": {
      en: 'This user does not exist in the system. Please Sign up to start using the services',
      hi: 'यह उपयोगकर्ता सिस्टम में मौजूद नहीं है। सेवाओं का उपयोग शुरू करने के लिए कृपया साइन अप करें'
    }
  },
];


function getTransValues() {

  let activeLanguage = 'en';
  let contentKeys = [
    ...userContentKeys,
  ];
  if (isEmpty(contentKeys)) {
    return {};
  }
  let data = {};
  contentKeys.forEach(item => {
    data[item.key] = item.value[activeLanguage]
  });
  return data;

}


// the translations
const resources = {
  en: {
    translations: getTransValues()
  }
};


i18n.init({
  compatibilityJSON: 'v3',
  debug: false,
  lng: 'en',
  fallbackLng: 'en', // use en if detected lng is not available
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false // react already safes from xss
  },
  resources,
  ns: ["translations"],
  defaultNS: "translations",
});

export default i18n;
