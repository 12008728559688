import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {stocksConst} from "../../../stocks/stocksConst";
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import {useParams} from 'react-router-dom'
import {
    getAllStocks, getStockDetails,
    getSummaryByFundHouse
} from "../../Stock/StocksActions";
import {isEmpty} from "../../../utils/validations";
import appTheme from "../../../assets/appTheme";
import {getFixedValuesAfterDecimal} from "../../../utils/helper";
import MarketCapView from "./MarketCapView";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;
function MarketCap(props) {
    const{requiredIsin,buttonId,advanceSearchFilter}= props;
    const [StocksState,setStocksState] = useState({
        activitiesStateLoaded: false,
        activitiesData: {},
        fundHouseCount : '',
        fundSchemeCount : '',
        marketValueChangeMoM: '',
        xAxis: [],
        yAxisBar: [],
        yAxisLine: [],
        barColor: [],
        sceenerCode:'',
        tabValue:0,
        isDomLoaded: false,

        holdings: "",
        marketCapCategory:"",
        businessHouse:"",
        industry:"",
        isInternationalStock: true,
        buttonIdReference: buttonId
    });
    const isin = requiredIsin;
    const [UIState, setUIState] = useState();
    const [stocks, setStocks] = useState([]);
    const [selectedStock, setSelectedStock] = useState();
    const [tableData, setTableData] = useState([]);
    const [fundsData, setFundsData] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [popUpModalData,setPopUpModalData] = useState({
        requiredMonth : '',
        entries:[],
        exits:[],
        netBuying:0,
        netSelling:0,
        buyingFunds:0,
        sellingFunds:0,
        netBuyingSellingDifference:0,
        isModalApiInProgress: true
    });

    useEffect(() => {
        if(isEmpty(isin)){
            return;
        }

        setUIState('');

        const date = process.env.REACT_APP_VALUATION_DATE;

        getSummaryByFundHouse(date, isin, advanceSearchFilter.fundHouses, advanceSearchFilter.funds, advanceSearchFilter.fundHouseForFund).then((res) => {
            if (res?.success) {
                if (isEmpty(res.data?.data)){
                    setStocksState((prevState)=>({
                        ...prevState,
                        fundHouseCount:  [],
                        marketValue : ""
                    }));
                    setTableData([]);

                }else {
                    setStocksState((prevState)=>({
                        ...prevState,
                        fundHouseCount:  res.data.data.length -1,
                        marketValue : setAUMData(res.data?.data.slice(1, res.data?.data.length))
                    }));
                    setTableData(res.data?.data.slice(1, res.data?.data.length));

                }
            }
        });

        getStockDetails(isin).then((res) => {
            if (res?.success) {
                if(!isEmpty(res.data?.data)){
                    setStocksState((prevState)=>({
                        ...prevState,
                        holdings: getFixedValuesAfterDecimal(res.data?.data[0]?.market_cap, 0),
                        marketCapCategory: res.data?.data[0]?.category,
                        businessHouse: res.data?.data[0]?.business_house,
                        industry: res.data?.data[0]?.industry,
                        isInternationalStock: res.data?.data[0]?.category === "International"
                    }))
                }
                // setBarLineChartData(res.data)
            }
        })

    }, [isin,advanceSearchFilter]);

    const setBarLineChartData = (chartData) =>{
        let xAxis = [];
        let yAxisBar = [];
        let yAxisLine = [];
        let barColor = [];
        if (isEmpty(chartData)){
            return
        }

        chartData?.data.forEach((item)=>{
            xAxis.push(item?.month);
            yAxisBar.push(getFixedValuesAfterDecimal(item?.total_market_value_change_due_to_activity,2));
            yAxisLine.push(getFixedValuesAfterDecimal(item?.total_market_value,2));
            if (item?.total_market_value_change_due_to_activity >=0){
                barColor.push(appTheme.appColor.success)
            }else {
                barColor.push(appTheme.appColor.danger)
            }

        });

        setStocksState((prevState)=>({
            ...prevState,
            xAxis: xAxis,
            yAxisBar: yAxisBar,
            barColor: barColor,
            yAxisLine: yAxisLine
        }))
    };

    useEffect(() => {
        setUIState('');

        setTimeout(()=>{
            setUIState(stocksConst.CONTENT_AVAILABLE);
        },200)
    }, [props.isLGView,props.isXLView, props.isMDView]);

    const setAUMData = (data) => {

        let totalAUM = 0;
        let totalChangeMoM = 0;
        let percentChange = 0;

        for (let i = 0; i < data.length; i++) {

            let marketValue = data[i].total_market_value ;
            let marketValueChangeMoM = data[i].market_value_change_mom ;

            totalAUM += marketValue;
            totalChangeMoM += marketValueChangeMoM;

        }

        return  getFixedValuesAfterDecimal(totalAUM,2)

    };

    const getStockName = (isin, stocks) => {
        let stockFound = stocks.find((stock) => stock.isin_code === isin);
        setSelectedStock(stockFound);
    };

    const setStockNames = (data) => {

        for (let i = 0; i < data.length; i++) {

            let stockName = data[i].isin_code;

            setStocks(stockNames => [...stockNames, stockName]);

        }
    };

    const onChangeValue = (name, value) => {
        setSelectedStock(value);
    };

    const handleTabChange = (name, value) => {
        setStocksState((prev)=>({
            ...prev,
            tabValue: value
        }));
    };

    const handleChange = () => {
        if (!StocksState?.isDomLoaded){
            setStocksState((prev)=>({
                ...prev,
                isDomLoaded:true
            }))

        }
    };
    const refresh = () => {
        window.location.reload(true);
    };

    if (isEmpty(isin)) {
        return (
            // <DLLoader type={'screen'} isVisible={true}/>
            <div className="h-100"></div>
        );
    }

    return (
        <MarketCapView
            {...props}
            isin={isin}
            selectedStocks={stocks}
            selectedStock={selectedStock}
            refresh={refresh}
            onChangeValue={onChangeValue}
            handleTabChange={handleTabChange}
            handleChange={handleChange}
            {...StocksState}
        />
    );
}


MarketCap.propTypes = {};
const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView
});

export default connect(mapStateToProps, {})(MarketCap);