import {httpPost} from "../utils/httpHelper";
import store from "../store";
import {setSearchKeywordValue} from "../../src/stockHolding/StockHoldingReducer";
import {resetSearchKeywordValue} from "../../src/stockHolding/StockHoldingReducer";
import {setDrillDownBy} from "../explorer/ExplorerReducer";
import {setViewBy} from "../../src/stockHolding/StockHoldingReducer";
import { resetOrder, resetOrderBy, resetPage, resetRowsPerPage, setOrder, setOrderBy, setPage, setRowsPerPage } from "./StockHoldingReducer";


export const getFilteredData = (data) => {
    return httpPost('/service/stocks/getMonthwiseHoldings', data).then(res => {
        return res;
    })
};

export const setSearchKeyword = (data) => {
    store.dispatch(setSearchKeywordValue(data))
};

export const resetSearchKeyword = (data) => {
    store.dispatch(resetSearchKeywordValue(data))
};
// export const setViewByValue = (value) =>{
//     store.dispatch(setViewBy(value))
// };
export const setStockHoldingTableByOrder = (data) =>{
    console.log("data",data)
    store.dispatch(setOrder(data))
};

export const setStockHoldingTableByOrderBy = (data) =>{
    store.dispatch(setOrderBy(data))
};

export const setStockHoldingPage = (data) =>{
    store.dispatch(setPage(data))
};

export const setStockHoldingRowsPerPage = (data) =>{
    store.dispatch(setRowsPerPage(data))
};

export const resetStockHoldingPage = (data) =>{
    store.dispatch(resetPage(data))
};

export const resetStockHoldingRowsPerPage = (data) =>{
    store.dispatch(resetRowsPerPage(data))
};

export const resetStockHoldingTableByOrder = (name, value) =>{
    store.dispatch(resetOrder({name, value}))
};

export const resetStockHoldingTableByOrderBy = (name, value) =>{
    store.dispatch(resetOrderBy({name, value}))
};