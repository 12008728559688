import React from 'react'
import {withRouter} from "react-router-dom";
import {Bar} from "react-chartjs-2";
import {isEmpty} from "../../utils/validations";
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import appTheme from "../../assets/appTheme";
import {getFixedValuesAfterDecimal, getNextRoundedNumber, getPreviousRoundedNumber} from "../../utils/helper";
import LineChart from "dlcomponent/components/D3Charts/LineChart";
import DLText from "dlcomponent/components/DLText/DLText";
import {
    filterAumValue,
    getMaxRoundedNumber,
    getMinRoundedNumber,
    getRoundedStepSize,
    getRoundedStepSizeForLineChart
} from "../../utils/stocksHelper";
import EchartBarChart from "../EChart/EchartBarChart";

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);

function LineBarChartMixed(props) {
    const {yAxisBar, yAxisLine, xAxis, barColor, isFromFund, isMobileView, hideCSS, isLGView, isXLView, isBarChartClickable,getBarClick, id, isXSView, selectedData, isFrom} = props;
    const includesNegative = yAxisBar.some(number => number < 0);


    const removeDecimalPoints = (inputArray) =>{
        // Convert each string to a number and round it to remove decimal points
        const resultArray = inputArray.map(str =>  filterAumValue(str) );
        return resultArray;
    };


    let maxValueBar = Math.max(...yAxisBar) * 2;
    let maxValueLine = Math.max(...yAxisLine);
    let maxChartValueBar = getNextRoundedNumber(maxValueBar);
    let maxChartValueLine = getNextRoundedNumber(maxValueLine);

    const options = {
        animation: {
            duration: 0,
        },
        responsive: true,
        plugins: {
            legend: {
                position: '',
            },
            title: {
                display: false,
                text: '',
            },
            tooltip: {
                backgroundColor: appTheme.appColor.white,
                bodyColor: appTheme.appColor.black,
                borderColor: '#D2D2D2',
                borderWidth: 1,
                padding: 20,
                titleColor: 'black',
                titleFont: 'bold',
                titleFontSize: 30,
                yAlign: 'bottom',
                boxPadding: 5
            }

        },
        scales: {
            x: {
                ticks: {
                    precision: 0,
                },
                grid: {
                    display: false
                }
            },

            "left-y-axis": {
                type: "linear",
                position: "left",
                grid: {
                    display: false,
                    // borderDash: [8, 6],
                    lineWidth: 4
                },
                title: {
                    display: true,
                    text: 'AUM ',
                },
                ticks: {
                    precision: 0,
                    stepSize: maxChartValueLine / 10 // stepSize :  max + 10 /10 and round value
                },
                grace: '5%',
                min: includesNegative ? -maxChartValueLine : 0,
                max: maxChartValueLine,
            },
            "right-y-axis": {
                type: "linear",
                position: "right",
                title: {
                    display: true,
                    text: 'Net Buying/Selling',
                },
                ticks: {
                    precision: 0,
                    stepSize: maxChartValueBar / 10 // stepSize :  max + 10 /10 and round value
                },
                grace: '5%',
                min: includesNegative ? -maxChartValueBar : 0,
                max: maxChartValueBar,
            }
        }
    };

    const data = {
        labels: xAxis,
        datasets: [
            {
                yAxisID: "left-y-axis",
                type: "line",
                label: "AUM",
                borderColor: appTheme.appColor.black,
                fill: true,
                backgroundColor: appTheme.appColor.black,
                data: yAxisLine
            },
            {
                yAxisID: "right-y-axis",
                type: "bar",
                label: "Net Buying/Selling",
                // borderWidth: 0,
                // borderRadius: 6,
                backgroundColor: barColor,
                data: yAxisBar,
                barThickness: 20,
                borderSkipped: ["bottom"]
            }
        ]
    };

    const getMinValue = () => {
        const {xAxis} = props;
        const minValue = Math.min(...yAxisLine);
        return minValue * 0.90
    };


    const getMaxValue = () => {
        const {xAxis} = props;
        const maxValue = Math.max(...yAxisLine);
        return maxValue * 1.1
    };

    const getBaseLog = (x, y) => {
        return Math.log(y) / Math.log(x);
    };


    const renderLineChartUI = () => {
        const {xAxis, yAxisLine, yAxisBar} = props;

        let minValue = getMinValue();

        if (!isFinite(minValue)) {
            return "";
        }
        minValue = Math.trunc(minValue);//floor
        minValue = getMinRoundedNumber(minValue);
        let maxNumber = getMaxValue();
        maxNumber = getMaxRoundedNumber(Math.trunc(maxNumber));//ceil
        if (!isFinite(maxNumber)) {
            return "";
        }

        maxNumber = maxNumber <= 5 ? maxNumber + 2 : maxNumber;

        let stepSize = (maxNumber - minValue) / 3;
        stepSize = getRoundedStepSizeForLineChart(stepSize);

        let maxValue = minValue +  (stepSize * 3);

        return (
            <div className=''>
                <LineChart className={""}
                           id={"aum-line-chart"}
                           externalOptions={{
                               indexAxis: 'x',
                               responsive: true,
                               maintainAspectRatio: false,
                               plugins:
                                   {
                                       legend: {
                                           position: '',
                                           display: false
                                       },
                                       title: {
                                           display: false,

                                       },
                                       tooltip: {
                                           backgroundColor: appTheme.appColor.white,
                                           bodyColor: appTheme.appColor.black,
                                           borderColor: '#D2D2D2',
                                           borderWidth: 1,
                                           padding: 20,
                                           titleColor: 'black',
                                           titleFont: 'bold',
                                           titleFontSize: 30,
                                           boxPadding: 5
                                       }
                                   },
                               onClick: '',
                               scales: {
                                   x: {
                                       stacked: false,
                                       grid: {
                                           display: false
                                       },
                                   },
                                   y: {
                                       position: "right",
                                       min: minValue,
                                       max: maxValue,
                                       ticks: {
                                           precision: 0,
                                           stepSize: stepSize// stepSize :  max + 10 /10 and round value
                                       },
                                       title: {
                                           display: false,
                                           text: ''
                                       },
                                   },
                               }
                           }}
                           data={[
                               {
                                   label: 'AUM',
                                   data: removeDecimalPoints(yAxisLine),
                                   backgroundColor: ["#0073ff"],
                                   lineTension: 0.3,
                                   fill: false,
                                   pointRadius: isMobileView ? 7 : 10
                               }
                           ]}
                           height={'180px'}
                           direction={'x'}
                           isLegendsVisible={false}
                           xLabels={xAxis}
                />
            </div>
        )
    };

    const getMinForBar = (yAxisBar) => {

        let numericNumbers = yAxisBar.map(Number);
        let minValue = Math.min(...numericNumbers);
        let maxValue = Math.max(...numericNumbers);

        if (minValue >= 0) {
            return 0
        }

        if (minValue <= 0 && maxValue >= 0) {
            let min = Math.max(maxValue, Math.abs(minValue));
            return '-' + Math.trunc(min)
        }
        return Math.trunc(minValue)
    };

    const getMaxForBar = (yAxisBar) => {
        let numericNumbers = yAxisBar.map(Number);
        let minValue = Math.min(...numericNumbers);
        let maxValue = Math.max(...numericNumbers);
        if ((maxValue < 1 && maxValue > -1) && (minValue < 1 && minValue > -1)) {
            return 4
        }

        if (maxValue <= 0) {
            return 0
        }

        if (minValue <= 0 && maxValue >= 0) {
            let max = Math.max(maxValue, Math.abs(minValue));
            return Math.trunc(max)
        }
        return Math.trunc(maxValue)
    };

    const getStepSize = (maxValue, minValue) => {
        if (minValue >= 0) {
            // All positive or zero
            return maxValue / 2;
        }

        if (maxValue <= 0) {
            // All negative
            return -minValue / 2;
        }

        // Mixed positive and negative

        return Math.max(maxValue, Math.abs(minValue));


    };

    const getBarChartValues = (initialData) =>{
        let data={
            name: xAxis,
            data: yAxisBar,
            emphasis: {
                focus: 'series'
            },
            barGap: 0.2,
            barWidth: '15%',
            itemStyle: {
                color: (params) => (params.value >= 0 ? '#029C48' : '#D72F2F'),
            },
            // color: getBorderColorForBar(getDataOnly(values.map(innerArray => innerArray[i]), 'total_market_value_change_due_to_activity')),
            type: 'bar',
        };
        return data;
    };

    const renderBarChart = () =>{

        let minValue = getMinForBar(yAxisBar);
        let maxValue = getMaxForBar(yAxisBar);
        maxValue = getNextRoundedNumber(maxValue);
        minValue = minValue <= 0 ? -getNextRoundedNumber(Math.abs(minValue)) : getNextRoundedNumber(minValue);
        let stepSize = getStepSize(maxValue, minValue);

        const labels = isEmpty(xAxis) ? [] : xAxis;

        return (
            <div className=''>
                <EchartBarChart {...props}
                                series={getBarChartValues(yAxisBar)}
                                id={id}
                                yAxisMaxValue={maxValue}
                                yAxisMinValue={minValue}
                                showDownloadIcon={false}
                                stepSize={stepSize}
                                height={"200px"}
                                yAxisPosition={"right"}
                                gridLeft={10}
                                gridRight={isXSView? 50 : 70}
                                xAxislabels={xAxis}/>

            </div>
        )
    };

    const getWidth = () =>{
        if (hideCSS){
            if (isLGView){
                return '50%'
            }
            return '100%'
        }
        return '100%'
    };


    const redirectToMoneyFlowExplorer = () => {
        if (isFrom === "fundHouse") {
            return props.history.push("/money-flow-explorer", {selectedFundHouse: selectedData, isFrom:'fundHouse'})
        }
        if (isFrom === "fund") {
            return props.history.push("/money-flow-explorer", {selectedFund: selectedData, isFrom:'fund'})
        }
        if (isFrom === "stock") {
            return props.history.push("/money-flow-explorer", {selectedStock: selectedData, isFrom:'stock'})
        }
        if (isFrom === "industry") {
            return props.history.push("/money-flow-explorer", {selectedSector: selectedData,isFrom:'industry'})
        }
    };

    const renderMoneyflowExplorerLink = () => {
        const {hideMoneyflowExplorer} = props;
        if(!hideMoneyflowExplorer){
            return (
                <DLText text={<u>{'Money Flow Explorer'}</u>}
                        fontSize={'sm'}
                        customWrapperStyle={{
                            paddingTop: hideCSS ? !isLGView && '36px' : '36px',
                        }}
                        isClickable={true}
                        marginBottom={isXLView ? 'md' : 'sm'}
                        onClick={() => {
                            redirectToMoneyFlowExplorer()
                        }}
                />
            )
        }

    };

    return (
        <div className={hideCSS ? isLGView ?  'd-flex ' : '' : 'd-flex flex-column'}
             style={{marginLeft: (isMobileView || hideCSS) ? 0 : 80}}>

            <div className="d-flex flex-column" style={{width: getWidth(),minHeight : "190px"}}>

                <div style={{marginBottom: isXLView ? '11px' : '6px'}}>
                    <DLText text={'AUM'}
                            fontSize={'sm'}
                            marginBottom={'none'}/>
                </div>

                <div className={isEmpty(yAxisLine) ? "flex-grow-1 h-100 d-flex justify-content-center align-items-center" : "flex-grow-1"}>
                    {
                        isEmpty(yAxisLine)?
                            <div className="h-100">
                                    No data found
                            </div>
                            :
                            renderLineChartUI()
                    }
                </div>

            </div>

            <div  className="d-flex flex-column" style={{width: getWidth(),minHeight : "190px"}}>
                <div className={'d-flex justify-content-between'}>
                <div>
                    <DLText text={'Net Buying/Selling'}
                            fontSize={'sm'}
                            customWrapperStyle={{
                                paddingTop: hideCSS ? !isLGView && '36px' : '36px',
                            }}
                            marginBottom={isXLView ? 'md' : 'sm'}/>
                </div>
                <div>
                    {renderMoneyflowExplorerLink()}
                </div>
</div>
                <div className={isEmpty(yAxisBar) ? "flex-grow-1 h-100 d-flex justify-content-center align-items-center" : "flex-grow-1"}>
                    {
                        isEmpty(yAxisBar)?
                            <div className="h-100">
                                No data found
                            </div>
                            :
                            renderBarChart()
                    }
                </div>

            </div>
        </div>
    )

}

LineBarChartMixed.propTypes = {
    hideMoneyflowExplorer: false
};
export default withRouter(LineBarChartMixed);

