import React from 'react';
import ConfirmationModalView from "./ConfirmationModalView";
import {deleteSelectedWatchlist} from "../../WatchListAction";
import {resetFund} from "../../../fund/FundAction";
import {resetStocks, setAdvanceSearchFilter} from "../../../AdvanceSearch/AdvanceSeachAction";


function ConfirmationModal(props){

    const {selectedWatchListObj,getDropDownData,} = props;

    const handleDeleteWatchlist = () => {
        deleteSelectedWatchlist(selectedWatchListObj.id).then((res) => {
            if (res.success ) {
                resetFund();
                setAdvanceSearchFilter('funds', []);
                resetStocks('stocks',[]);
                getDropDownData()
            }
        });
    };
    return (
        <div>
            <ConfirmationModalView {...props}
            handleDeleteWatchlist={handleDeleteWatchlist}/>
        </div>
    );
}

export default ConfirmationModal;





