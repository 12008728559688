import BarChart from "../../../../component-utils/charts/BarChart";
import React from "react";
import DLText from "dlcomponent/components/DLText/DLText";
import {isEmpty} from "../../../../utils/validations";
import appTheme from "../../../../assets/appTheme";
import EChartTreemap from "../../../../component-utils/EChart/EChartTreemap";
import {getDataArray} from "../../../../utils/helper";

const SectorChart = (props) => {
    const {xAxis, yAxis, handleClick, percentage, isMobileView, chartType} = props;
    const checkALLDataPresent = () =>{
        if (isEmpty(xAxis) || isEmpty(yAxis)){
            return false
        }
        return  xAxis.every((item)=> item !== 0);
    };

    const handleChartClick = (params) => {
        const clickedLine = params.name;
        if (clickedLine === 'Others'){
            return;
        }
        props.history.push('/sectors/' + clickedLine)
    };

    if(chartType === "marketValue"){
        return(
            <div className='mx-3 mx-0 mb-3' style={{maxWidth: window.innerWidth > 900 ? '900px' : '100%'}}>
                <EChartTreemap {...props}
                               handleChartClick={handleChartClick}
                               requestedHeight={"350px"} data={getDataArray(props.industry, 'industry', 'market_value')}/>
            </div>
        );
    }

    return (
        <div className='mx-sm-3 mx-0' style={{maxWidth: window.innerWidth > 800 ? '800px' : '100%'}}>
            <BarChart data={[{
                label: '',
                data: xAxis,
                backgroundColor: (chartType === 'changeSellingPercent' || chartType === 'changeSellingMoM') ? appTheme.appColor.danger : appTheme.appColor.success,
                borderColor: (chartType === 'changeSellingPercent' || chartType === 'changeSellingMoM') ? appTheme.appColor.danger : appTheme.appColor.success,
                barThickness: 15,
                maxBarThickness: 80
            }]}
                      direction={'y'}
                      percentage={percentage}
                      xLabels={yAxis}
                      height={isMobileView && 350}
                      onClick={handleClick}
                      isMobileView={isMobileView}
            />
        </div>

    )
}

export default SectorChart;