import React, {useState} from 'react';
import {getRandomId, getTrimmedValue, numberWithCommas, searchData} from "../utils/helper";
import DLText from "dlcomponent/components/DLText/DLText";
import LineBarChartMixed from "../component-utils/charts/LineBarChartMixed";
import appTheme from "../assets/appTheme";
import moment from "moment";
import {isEmpty} from "../utils/helperCommon";
import Dropdown from "react-bootstrap/Dropdown";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import {fundHouseConst} from "../fundHouse/fundHouseConst";


// TODO use lazy load for each component which is firing the api
function SearchUiView(props) {

    const {
        handleClick, onChangeValue, isMobileView, isXSView, changeTab, activeTab, funds, industry, stocks, fundHouses,
        isSearchInProgress, isKeywordPresent, keyword, isMDView, onCloseModal, searchOptions, searchOptionsFound, isSMView,smallView
    } = props;

    const getTrimmedName = (name) => {
        if (isSMView || isXSView) {
            return getTrimmedValue(name, 50);
        } else {
            return getTrimmedValue(name, 70);
        }
    };

    const getBackground = (tab) => {
        if ((activeTab === tab)) {
            return "#F4F4F4";
        }
    };

    const getStocksUi = (stocks) => {
        if (isEmpty(stocks)) {
            return (
                <div className="w-100" style={{height: 'calc(100vh - 335px)'}}>
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <DLText id={""}
                                text={"No matching stocks found."}
                                fontColor={"lightGray"}
                                marginBottom={"none"}
                        />
                    </div>
                </div>
            );
        }
        return (
            <div>
                {!isEmpty(stocks) && <div>
                    {stocks.map((item, index) => {
                        return (
                            <Dropdown.Item onClick={() => handleClick(item)} key={getRandomId()}>
                                <div className={index === 0 ? "p-2 " : "p-2 border-top"}>
                                    <DLText id={""}
                                            text={getTrimmedName(item?.name)}
                                            customTextStyle={{textWrap: "wrap"}}
                                            fontWeight={"normal"}
                                            fontSize={smallView? "xs" : "sm"}
                                            marginBottom={"none"}
                                    />
                                    <DLText id={""}
                                            text={item?.type}
                                            fontColor={"lightGray"}
                                            marginBottom={"none"}
                                            fontSize={smallView? "xxs" : "xs"}
                                    />
                                </div>
                            </Dropdown.Item>
                        )
                    })}
                </div>}
            </div>
        );
    }

    const getIndustryUi = (industry) => {
        if (isEmpty(industry)) {
            return (
                <div className="w-100" style={{height: 'calc(100vh - 335px)'}}>
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <DLText id={""}
                                text={"No matching sectors found."}
                                fontColor={"lightGray"}
                                marginBottom={"none"}
                        />
                    </div>
                </div>
            );
        }
        return (
            <div>
                {!isEmpty(industry) && <div>
                    {industry.map((item, index) => {
                        return (
                            <Dropdown.Item onClick={() => handleClick(item)} key={getRandomId()}>
                                <div className={index === 0 ? "p-2 " : "p-2 border-top"}>
                                    <DLText id={""}
                                            text={getTrimmedName(item?.name)}
                                            customTextStyle={{textWrap: "wrap"}}
                                            fontWeight={"normal"}
                                            fontSize={smallView? "xs" : "sm"}
                                            marginBottom={"none"}
                                    />
                                    <DLText id={""}
                                            text={item?.type}
                                            fontColor={"lightGray"}
                                            marginBottom={"none"}
                                            fontSize={smallView? "xxs" : "xs"}
                                    />
                                </div>
                            </Dropdown.Item>
                        )
                    })}
                </div>}
            </div>
        );
    }

    const getFundsUi = (funds) => {
        if (isEmpty(funds)) {
            return (
                <div className="w-100" style={{height: 'calc(100vh - 335px)'}}>
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <DLText id={""}
                                text={"No matching funds found."}
                                fontColor={"lightGray"}
                                marginBottom={"none"}
                        />
                    </div>
                </div>
            );
        }
        return (
            <div>
                {!isEmpty(funds) && <div>
                    {funds.map((item, index) => {
                        return (
                            <Dropdown.Item onClick={() => handleClick(item)} key={getRandomId()}>
                                <div className={index === 0 ? "p-2 " : "p-2 border-top"}>
                                    <DLText id={""}
                                            text={getTrimmedName(item?.name)}
                                            customTextStyle={{textWrap: "wrap"}}
                                            fontWeight={"normal"}
                                            fontSize={smallView? "xs" : "sm"}
                                            marginBottom={"none"}
                                    />
                                    <DLText id={""}
                                            text={item?.type}
                                            fontColor={"lightGray"}
                                            marginBottom={"none"}
                                            fontSize={smallView? "xxs" : "xs"}
                                    />
                                </div>
                            </Dropdown.Item>
                        )
                    })}
                </div>}
            </div>
        );
    }

    const getFundHousesUi = (fundHouses) => {
        if (isEmpty(fundHouses)) {
            return (
                <div className="w-100" style={{height: 'calc(100vh - 335px)'}}>
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <DLText id={""}
                                text={"No matching fund houses found."}
                                fontColor={"lightGray"}
                                marginBottom={"none"}
                        />
                    </div>
                </div>
            );
        }
        return (
            <div>
                {!isEmpty(fundHouses) && <div>
                    {fundHouses.map((item, index) => {
                        return (
                            <Dropdown.Item onClick={() => handleClick(item)} key={getRandomId()}>
                                <div className={index === 0 ? "p-2 " : "p-2 border-top"}>
                                    <DLText id={""}
                                            text={getTrimmedName(item?.name)}
                                            fontWeight={"normal"}
                                            customTextStyle={{textWrap: "wrap"}}
                                            fontSize={smallView? "xs" : "sm"}
                                            marginBottom={"none"}
                                    />
                                    <DLText id={""}
                                            text={item?.type}
                                            fontColor={"lightGray"}
                                            marginBottom={"none"}
                                            fontSize={smallView? "xxs" : "xs"}
                                    />
                                </div>
                            </Dropdown.Item>
                        )
                    })}
                </div>}
            </div>
        );
    }

    const getAllUi = (searchOptions) => {
        if (isEmpty(searchOptions)) {
            return (
                <div className="w-100" style={{height: 'calc(100vh - 335px)'}}>
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <DLText id={""}
                                text={"No match found."}
                                fontColor={"lightGray"}
                                marginBottom={"none"}
                        />
                    </div>
                </div>
            );
        }
        return (
            <div>
                {!isEmpty(searchOptions) && <div>
                    {searchOptions.map((item, index) => {
                        return (
                            <Dropdown.Item onClick={() => handleClick(item)} key={getRandomId()}>
                                <div className={index === 0 ? "p-2 " : "p-2 border-top"}>
                                    <DLText id={""}
                                            text={getTrimmedName(item?.name)}
                                            fontWeight={"normal"}
                                            customTextStyle={{textWrap: "wrap"}}
                                            fontSize={smallView? "xs" : "sm"}
                                            marginBottom={"none"}
                                    />
                                    <DLText id={""}
                                            text={item?.type}
                                            fontColor={"lightGray"}
                                            fontSize={smallView? "xxs" : "xs"}
                                            marginBottom={"none"}
                                    />
                                </div>
                            </Dropdown.Item>
                        )
                    })}
                </div>}
            </div>
        );
    }

    const getText = (text) => {

        return (
            <DLText id={""}
                    text={text}
                    fontWeight={"normal"}
                    customTextStyle={{textWrap: "wrap"}}
                    fontSize={smallView ? "xs" : "md"}
                    marginBottom={"none"}
            />
        );
    }

    const getOptionsItem = () => {
        const {searchOptions} = props;
        //console.log("funds", funds, "fundHouses", fundHouses, "industry", industry, "stocks", stocks)
        if (isEmpty(searchOptions)) {
            return (
                <div className="w-100" style={{height: 'calc(100vh - 250px)'}}>
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <DLText id={""}
                                text={"No matching result found."}
                                fontColor={"lightGray"}
                                marginBottom={"none"}
                        />
                    </div>
                </div>
            )
        }
        return (
            <table className="w-100 px-0">
                <tr className="row m-2">
                    {!isEmpty(fundHouses) && <td className="col-3">{getFundHousesUi(fundHouses)}</td>}

                    {!isEmpty(funds) && <td className="col-3">{getFundsUi(funds)}</td>}


                    {!isEmpty(stocks) && <td className="col-3">{getStocksUi(stocks)}</td>}


                    {!isEmpty(industry) && <td className="col-3">{getIndustryUi(industry)}</td>}

                </tr>
            </table>
        )
    };

    const getTabData = (activeTab) => {
        switch (activeTab) {
            case "all" :
                return getAllUi(searchOptions);
            case "fundHouses" :
                return getFundHousesUi(fundHouses);
            case "funds" :
                return getFundsUi(funds);
            case "stocks" :
                return getStocksUi(stocks);
            case "industries" :
                return getIndustryUi(industry);

        }
    }

    const getTabUi = () => {
        return (
            <div className="">
                <Box className={isMobileView && !isMDView ? "ps-0" : "ps-2"} sx={{width: '100%', typography: 'body1'}}>
                    <TabContext value={activeTab}>
                        <Box
                            // sx={{borderBottom: 1, borderColor: 'divider'}}
                        >
                            <TabList indicatorColor={""} textColor={""}  variant={isMobileView && !isMDView ? "scrollable" : ""} scrollButtons={isMobileView && !isMDView ? true : false} allowScrollButtonsMobile={isMobileView && !isMDView ? true : false} onChange={(e, v) => {
                                changeTab(v)
                            }} aria-label="lab API tabs example">
                                <Tab label={getText("All")} value="all" sx={{textTransform: "none"}} className="search-tab"
                                     style={{backgroundColor: getBackground("all")}}/>
                                <Tab label={getText('Fund Houses')} value="fundHouses" sx={{textTransform: "none"}}  className="search-tab"
                                     style={{backgroundColor: getBackground("fundHouses")}}/>
                                <Tab label={getText('Funds')} value="funds" sx={{textTransform: "none"}}  className="search-tab"
                                     style={{backgroundColor: getBackground("funds")}}/>
                                <Tab label={getText('Stocks')} value="stocks" sx={{textTransform: "none"}}  className="search-tab"
                                     style={{backgroundColor: getBackground("stocks")}}/>
                                <Tab label={getText('Sectors')} value="industries" sx={{textTransform: "none"}} className="search-tab"
                                     style={{backgroundColor: getBackground("industries")}}/>
                            </TabList>
                        </Box>
                    </TabContext>
                </Box>
                <div className= {"flex-grow-1 pt-3"}>
                    {getTabData(activeTab)}
                </div>
            </div>
        );
    }


    const renderDesktopUi = () => {
        const {searchOptionsFound} = props;

        return (
            <div className='d-flex justify-content-center align-items-center'>
                <div className={isMDView ? "w-100" : "w-75"}>
                    <div className="resappbar-search-box-container" style={{flex: 1}}>
                        <Dropdown className='w-100 p-0'>
                            <Dropdown.Toggle id="dropdown-basic" className='py-1 '>
                                <div className="w-100" style={{color: "var(--light-gray)"}}>
                                    <Paper
                                        className="res-app-paper"
                                        component=""
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            boxShadow: "none",
                                            p: 0
                                        }}
                                    >
                                        <InputBase
                                            sx={{flex: 1, p: 0}}
                                            fullWidth
                                            placeholder="Search Stocks, Funds and Sectors"
                                            onChange={(e) => onChangeValue(e.target.value)}
                                            value={keyword}
                                            autoFocus={true}

                                        />
                                        {
                                            isKeywordPresent && !isSearchInProgress &&
                                            <div onClick={() => onChangeValue('')}>

                                                <i className="fa fa-remove m-2"/>
                                            </div>
                                        }
                                        {
                                            isSearchInProgress &&
                                            <div className='mx-2' style={{minWidth: 'max-content'}}>
                                                <div className="btn-loader"
                                                     style={{
                                                         borderTop: '2px solid ' + appTheme.appColor.black,
                                                         borderRight: '2px solid ' + appTheme.appColor.black,
                                                         borderBottom: '2px solid transparent',
                                                         borderLeft: '2px solid ' + appTheme.appColor.black,
                                                     }}
                                                />
                                            </div>
                                        }
                                        {!isKeywordPresent &&
                                            <SearchIcon/>
                                        }
                                    </Paper>
                                </div>
                            </Dropdown.Toggle>
                        </Dropdown>
                    </div>
                    {(searchOptionsFound && isKeywordPresent) ?
                        <div className='resappbar-search-box-container-options p-2'>
                            {getTabUi()}
                        </div>
                        :
                        <div className='d-flex flex-column justify-content-center align-items-center' style={{minHeight: 200, gap: "20px"}}>
                            <div style={{borderBottom: '1px solid ' + appTheme.linkStyle.color, width: 'max-content'}}>
                                <DLText id={""}
                                        isClickable={true}
                                        text={"Advanced Stock Search"}
                                        onClick={() => props.history.push('/advanced-search')}
                                        fontColor={"lightGray"}
                                        marginBottom={"none"}
                                />
                            </div>
                            <div style={{borderBottom: '1px solid ' + appTheme.linkStyle.color, width: 'max-content'}}>
                                <DLText id={""}
                                        isClickable={true}
                                        text={"Advanced Fund Search"}
                                        onClick={() => props.history.push('/advanced-fund-search')}
                                        fontColor={"lightGray"}
                                        marginBottom={"none"}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    };

    const renderMobileUi = () => {

        return (
            <div>
                <div className='d-flex justify-content-center align-items-center'>
                    <div className="w-100">
                        <div className="resappbar-search-box-container px-2" style={{flex: 1}}>
                            <Dropdown className='w-100 px-2'>
                                <Dropdown.Toggle id="dropdown-basic" className='py-1 '>
                                    <div className="w-100" style={{color: "var(--light-gray)"}}>
                                        <Paper
                                            className="res-app-paper px-2"
                                            component=""
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                                boxShadow: "none",
                                                p: 0
                                            }}
                                        >
                                            <InputBase
                                                sx={{flex: 1, p: 0}}
                                                fullWidth
                                                placeholder="Search Stocks, Funds and Sectors"
                                                onChange={(e) => onChangeValue(e.target.value)}
                                                value={keyword}
                                                autoFocus={true}
                                            />

                                            {
                                                isKeywordPresent && !isSearchInProgress &&
                                                <div onClick={() => onChangeValue('')}>

                                                    <i className="fa fa-remove m-2"/>
                                                </div>
                                            }
                                            {isSearchInProgress &&
                                            <div className='mx-2' style={{minWidth: 'max-content'}}>
                                                <div className="btn-loader"
                                                     style={{
                                                         borderTop: '2px solid ' + appTheme.appColor.black,
                                                         borderRight: '2px solid ' + appTheme.appColor.black,
                                                         borderBottom: '2px solid transparent',
                                                         borderLeft: '2px solid ' + appTheme.appColor.black,
                                                     }}
                                                />
                                            </div>
                                            }
                                            {!isKeywordPresent &&
                                            <IconButton type="button" sx={{p: '0px'}} aria-label="search">
                                                <SearchIcon/>
                                            </IconButton>
                                            }
                                        </Paper>
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </div>

                    </div>
                </div>
                {(searchOptionsFound && isKeywordPresent) ?  <div className="pt-4">{getTabUi()}</div> :
                    <div className='d-flex flex-column justify-content-center align-items-center' style={{minHeight: 200, gap:"20px"}}>
                        <div style={{borderBottom: '1px solid ' + appTheme.linkStyle.color, width: 'max-content'}}>
                            <DLText id={""}
                                    isClickable={true}
                                    text={"Advanced Stock Search"}
                                    onClick={() => props.history.push('/advanced-search')}
                                    fontColor={"lightGray"}
                                    marginBottom={"none"}
                            />
                        </div>
                        <div style={{borderBottom: '1px solid ' + appTheme.linkStyle.color, width: 'max-content'}}>
                            <DLText id={""}
                                    isClickable={true}
                                    text={"Advanced Fund Search"}
                                    onClick={() => props.history.push('/advanced-fund-search')}
                                    fontColor={"lightGray"}
                                    marginBottom={"none"}
                            />
                        </div>
                    </div>
                }
            </div>
        )
    };

    return (<>
        <div className="bg-white ">
            <div className={'px-3 d-flex justify-content-between fixedHeader bg-white py-3'}>
                <div className={'d-flex align-items-center'}
                     style={{color: appTheme.appColor.black, fontSize: 24, fontWeight: 500}}>
                </div>
            </div>
            <div className='pageContainer'>
                {isMobileView && !isMDView ? renderMobileUi() : renderDesktopUi()}
            </div>
        </div>
    </>);
}

SearchUiView.propTypes = {};

export default SearchUiView;
