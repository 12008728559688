import React, {useEffect, useState} from 'react';
import FundListingModalView from "./FundListingModalView";
import {connect} from "react-redux";
import {getFundSummary, setFundList} from "../../fund/FundAction";
import {advanceSearchConst} from "../../AdvanceSearch/AdvanceSearchConst";
import {setAllFundsData} from "../../fund/FundReducer";
import { setFundsKeywordFilter} from "../../AdvanceSearch/AdvanceSeachAction";
import {isEmpty} from "../../utils/validations";
import {searchOnWordBoundary} from "../../utils/helper";
import {getListingData} from "../../utils/stocksHelper";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function FundListingModal(props) {

    const {setFilter, isFundModalOpen,previousWatchlistFundIds} = props;
    const [FundListingModalState, setAdvanceFundListingModalState] = useState({
        modalUIState: '',
        sortedData: [],
        previousFundListing: [],
        skipCount: 0,
        limitCount: 60,
        isSelectedPanelExpanded:false
    });

    // useEffect(()=>{
    //     if(isWatchlistCreated){
    //         console.log("isWatchlistCreated--",isWatchlistCreated)
    //         clearSelectedFunds()
    //     }
    // },[isWatchlistCreated]);

    useEffect(() => {
        const {skipCount, limitCount} = FundListingModalState;
        if (isFundModalOpen || (!isEmpty(previousWatchlistFundIds)) ) {
                if (!isEmpty(props.fundListingData)) {
                    handleListingOptions();
                    setAdvanceFundListingModalState((prev) => ({
                        ...prev,
                        modalUIState: advanceSearchConst.CONTENT_AVAILABLE,
                        previousFundListing: props.fundListingData
                    }));
                } else {
                getFundSummary(VALUATION_DATE, skipCount, limitCount).then((res) => {
                    if (res.success && !isEmpty(res.data?.data)) {
                        let data = getListingData(res.data.data, 'fund_name');
                        //store.dispatch(setFunds(data));

                        // props.onChangeValue('fundListingData', data);
                        props.setAllFundsData(data);
                        data = handleExternalCheckChange(previousWatchlistFundIds, data);
                        setFundList(data);
                        applyExternalFilter(data);
                        setAdvanceFundListingModalState((prev) => ({
                            ...prev,
                            modalUIState: advanceSearchConst.CONTENT_AVAILABLE
                        }));
                    } else {
                        setAdvanceFundListingModalState((prev) => ({
                            ...prev,
                            modalUIState: advanceSearchConst.CONTENT_UNAVAILABLE
                        }));
                    }
                })
            }
        }

    }, [isFundModalOpen,previousWatchlistFundIds]);

    useEffect(() => {
        const {fundsKeyword} = props;
        const {fundListingData} = props;

        if (isEmpty(fundListingData)) {
            return
        }

        if (isEmpty(fundsKeyword)) {
            setFundList(fundListingData);

            // props.onChangeValue('fundListingData', fundListingData);

            setAdvanceFundListingModalState((prev) => ({
                ...prev,
                sortedData: [],
                searchResultNotAvailable: false
            }));
        } else {
            let sortedData = [];
            setAdvanceFundListingModalState((prev) => ({
                ...prev,
                isSearchApply: true
            }));

            let options = fundListingData.map((item) => {
                return {...item, label: item.fund_name, value: item.fund_name}
            });

            sortedData = searchOnWordBoundary(props.fundsKeyword, JSON.parse(JSON.stringify(options)));

            if (!isEmpty(sortedData)) {

                setAdvanceFundListingModalState((prev) => ({
                    ...prev,
                    sortedData: sortedData,
                    searchResultNotAvailable: false
                }));
            } else {
                setAdvanceFundListingModalState((prev) => ({
                    ...prev,
                    sortedData: [],
                    searchResultNotAvailable: true
                }));
            }

            setTimeout(() => {
                setAdvanceFundListingModalState((prev) => ({
                    ...prev,
                    isSearchApply: false
                }))
            }, 300)

        }

    }, [props.fundsKeyword]);

    const handleListingOptions = () => {
        const {fundHouses} = props.advanceSearchFilter;
        const {allFundsData} = props;
        let selectedValues = checkSelectedValues();

        let filteredFunds = [];
        if (!isEmpty(fundHouses)) {
            fundHouses.forEach((item) => {
                allFundsData.forEach((obj) => {
                    if (item === obj?.fund_house_id) {
                        filteredFunds.push(obj)
                    }
                })
            })
        } else {
            filteredFunds = allFundsData
        }
        let addedArray = [...filteredFunds, ...selectedValues];

        selectedValues.forEach((item) => {
            addedArray.forEach((obj, index) => {
                if ((item?.fund_id === obj?.fund_id) && (item?.fund_house_id === obj?.fund_house_id) && (!obj?.isSelected)) {
                    addedArray.splice(index, 1)
                }
            })
        });

        setFundList(addedArray);
        applyExternalFilterfund(addedArray)
    };

    const handleExternalCheckChange = (value, data) => {
        let result;
        if (isEmpty(value)) {
            return data
        }
        result = data.map(item => {
            if (value.includes(item.fund_id)) {
                return { ...item, isSelected: !item.isSelected };
            }
            return item;
        });
        return result;
        //setFundList(updatedArray);
    };

    const applyExternalFilter = (data) => {
        let selectedValues = checkExternalSelectedValues(data);
        setFilter('funds', selectedValues, 'fund_id',false);
        props.history.replace();
    };

    const applyExternalFilterfund = (data) => {
        let selectedValues = checkExternalSelectedValues(data);
       // setFilter('funds', selectedValues, 'fund_id',false);
        props.history.replace();
    };

    const handleCheckboxChange = (value) => {
        const {fundListingData} = props;

        const updatedArray = fundListingData.map(item => {
            if (item.fund_house_id === value.fund_house_id && item.fund_id === value.fund_id) {
                return {...item, isSelected: !item.isSelected};
            }

            // Return the original object if the id doesn't match
            return item;
        });
        setFundList(updatedArray);

        // props.onChangeValue('fundListingData', updatedArray);

    };

    const clearSelectedFunds = () => {
        const {fundListingData} = props;
        console.log("fundListingData////////",fundListingData);

        const updatedArray = fundListingData.map(item => {
            return {...item, isSelected: false};
        });
        setFundList(updatedArray);
        setAdvanceFundListingModalState((prev) => ({
            ...prev,
            isSelectedPanelExpanded: false,
        }));
    };

    const checkboxChange = (value) => {
        const {fundListingData} = props;

        // Make a copy of the object at the specified index
        const updatedItem = fundListingData.find(item => (item.fund_house_id === value.fund_house_id && item.fund_id === value.fund_id));

        return updatedItem?.isSelected
    };

    const onChangeKeywordFilter = (fundsKeyword) => {
        setFundsKeywordFilter(fundsKeyword)
    };

    const checkExternalSelectedValues = (data) => {
       // const {fundListingData} = props;
        return data.filter((item) => item.isSelected);
    };

    const checkSelectedValues = () => {
        const {fundListingData} = props;
        return fundListingData.filter((item) => item.isSelected);
    };

    const handleSelectedPanelExpandClick = () => {
        setAdvanceFundListingModalState((prev) => ({
            ...prev,
            isSelectedPanelExpanded: !prev.isSelectedPanelExpanded,
        }));
    };

    return (
        <FundListingModalView {...FundListingModalState}
                              {...props}
                              handleCheckboxChange={handleCheckboxChange}
                              checkboxChange={checkboxChange}
                              checkSelectedValues={checkSelectedValues}
                              clearSelectedFunds={clearSelectedFunds}
                              onChangeKeywordFilter={onChangeKeywordFilter}
                              handleSelectedPanelExpandClick = {handleSelectedPanelExpandClick}/>
    )
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    fundsData: state.fundState.fundsData,
    fundsLoaded: state.fundState.fundsLoaded,
    fundsKeyword: state.AdvanceSearchState.fundsKeyword,
    allFundsData: state.fundState.allFundsData,
    fundListingData: state.fundState.fundListingData,
});

export default connect(mapStateToProps, {setAllFundsData})(FundListingModal);