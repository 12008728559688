import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {isEmpty} from "../utils/validations";
import {
    getFixedValuesAfterDecimal,
    getMobileHeight,
    getNextRoundedNumber,
    getTrimVal,
    numberWithCommas,
    searchData
} from "../utils/helper";
import SummaryByFundHouse from "./SummaryByFundHouse/SummaryByFundHouse";
import SummaryByFund from "./SummaryByFund/SummaryByFund";
import {stocksConst} from "./stocksConst";
import DLText from "dlcomponent/components/DLText/DLText";
import LineBarChartMixed from "../component-utils/charts/LineBarChartMixed";
import appTheme from "../assets/appTheme";
import LineChart from "dlcomponent/components/D3Charts/LineChart";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CustomTabPanel from "../component-utils/CustomTabPanel/CustomTabPanel";
import DLLoader from "../core/components/DLLoader/DLLoader";
import moment from 'moment'
import PopComponent from "./PopComponent";
import {filterAumValue} from "../utils/stocksHelper";
import DLButton from "dlcomponent/components/DLButton/DLButton";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
// TODO use lazy load for each component which is firing the api
function StocksView(props) {

    const {isin, refresh, isMDView, isLGView, isMobileView, stocks, isXSView, selectedStock, UIState, sceenerCode, handleOpenModal,
        holdings, marketCapCategory, businessHouse, industry, isSMView, marketValue, isInternationalStock} = props;
    const [activeSection, setActiveSection] = useState('section1');

   const addScroll = (id)=>{
       const element = document.getElementById(id);
       if (!isEmpty(element)) {
           element.scrollIntoView({behavior: "smooth", block: "start"});
       }
   };

    const selectedStockArr = [selectedStock];

   const getHoldingPercentage =()=>{
       if(isEmpty(holdings) || isEmpty(marketValue)){
           return;
       }
       let percentage = (marketValue/holdings)*100;
       if(percentage < 10){
           return getFixedValuesAfterDecimal(percentage,2);
       }
       return getFixedValuesAfterDecimal(percentage,0);
   }

    const handleScroll = () => {
        const myDiv = document.getElementById('myDiv');
        const pageYOffset = myDiv?.scrollTop + 64;
        let sectionsHeight = 600;

        let section1Height; // to active nextsections when previous section is at end
        let section2Height;//mobile -400
        let section3Height; //mobile -400
        let section4Height; //mobile -400
        if (!isMobileView){
            section1Height = sectionsHeight - 100; // to active nextsections when previous section is at end
            section2Height = section1Height + 500;//mobile -400
            section3Height = section2Height + 650; //mobile -400
            if (pageYOffset < section1Height) {
                setActiveSection('section1');
            } else if (pageYOffset < section2Height) {
                setActiveSection('section2');
            } else if (pageYOffset < section3Height) {
                setActiveSection('section3');
            }
        }else {
            section1Height = sectionsHeight; // to active nextsections when previous section is at end
            section2Height = section1Height + 250 ;//mobile -400
            section3Height = section2Height + 600; //mobile -400
            section4Height =  section3Height + 600; //mobile -400

            if (pageYOffset < section1Height) {
                setActiveSection('section1');
            } else if (pageYOffset < section2Height) {
                setActiveSection('section2');
            } else if (pageYOffset < section3Height) {
                setActiveSection('section3');
            } else if (pageYOffset < section4Height) {
                setActiveSection('section4');
            }
        }
    };

    const getFormattedData = (data, type) => {
        if (!isEmpty(data)) {
            if (data !== 0 && data !== '0' && type === 'revenue') {
                return "INR " + data;
            } else if (data !== 0 && data !== '0') {
                return data;
            }
        }
        return '';
    };

    const renderMobileUI = () => {
        const {tabValue, handleTabChange} = props;

        return (
            <div>
                {renderSummaryUI()}

                <div className="summaryTabs">
                    <Box sx={{width: '100%'}}>
                        <Box sx={{width: '100%'}}>
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example" centered>
                                <Tab label="Fund Houses" {...a11yProps(0)} style={{ textTransform:'capitalize',fontSize:16,borderBottom: "0.5px solid  #E1E1E1" }}/>
                                <Tab label="Funds" {...a11yProps(1)} style={{ textTransform:'capitalize',fontSize:16,borderBottom: "0.5px solid  #E1E1E1" }} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={tabValue} index={0}  id="summaryTabs">
                            <div style={{minHeight:500}}>
                                <SummaryByFundHouse isin={isin} {...props}/>
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={1} id="summaryTabs">
                            <div style={{minHeight:500}}>
                                <SummaryByFund isin={isin} {...props}/>
                            </div>
                        </CustomTabPanel>
                    </Box>
                </div>
                {/*<SummaryCardFundHouse isin={isin}/>*/}
                {/*<SummaryByFundHouse isin={isin} {...props}/>*/}
                {/*<SummaryByFund isin={isin} {...props}/>*/}
            </div>
        );
    };

    const renderDesktopCountUi = () =>{
        const {activitiesStateLoaded, isXSView, yAxisBar, barColor, yAxisLine, xAxis, isLGView, activitiesData, fundHouseCount, fundSchemeCount, marketValue} = props;

        return (
            <div className='d-flex flex-lg-column flex-row mb-3'
                 style={{minWidth:'max-content',marginBottom:20}}>
                <div className={isMobileView ? 'border-end py-1 h-100':'border-bottom py-3 pb-md-0'}
                     style={{flex:isMobileView ? 1: '',height:isMobileView ?'100%':'30%'}}>
                    <DLText
                        id={'revenue-value'}
                        type={'normal'}
                        text={'AUM'}
                        fontSize={'sm'}/>
                    <DLText
                        id={'revenue-value'}
                        type={'normal'}
                        text={numberWithCommas(filterAumValue(marketValue))}
                        fontWeight={'bold'}
                        fontSize={'md'}/>
                </div>
                <div className={isMobileView ?'border-end d-flex flex-column justify-content-center py-1 ps-3':'border-bottom d-flex flex-column justify-content-center py-3 py-md-0'}
                     style={{flex:isMobileView ? 1: '',height:"40%"}}>
                    <DLText
                        id={'revenue-value'}
                        type={'normal'}
                        text={'Fund Houses'}
                        fontSize={'sm'}/>
                    <DLText
                        id={'revenue-value'}
                        type={'normal'}
                        text={fundHouseCount}
                        fontWeight={'bold'}
                        fontSize={'md'}/>
                </div>
                <div className={isMobileView ?'border-end d-flex flex-column justify-content-center py-1 ps-3':'border-bottom d-flex flex-column justify-content-center py-3 py-md-0'}
                     style={{flex:isMobileView ? 1: '',height:"40%"}}>
                    <DLText
                        id={'revenue-value'}
                        type={'normal'}
                        text={'Funds'}
                        fontSize={'sm'}/>
                    <DLText
                        id={'revenue-value'}
                        type={'normal'}
                        text={fundSchemeCount}
                        fontWeight={'bold'}
                        fontSize={'md'}/>
                </div>
                <div className={isMobileView ?'d-flex flex-column justify-content-end py-1 ps-3':'d-flex flex-column justify-content-end pt-3 pt-md-0'}
                     style={{flex:isMobileView ? 1: '',height:"30%"}}>
                    <DLText
                        id={'revenue-value'}
                        type={'normal'}
                        text={<div className="d-flex"> Holding
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={"(Market Cap)"}
                                marginBottom={"none"}
                                fontSize={'xs'}/>
                        </div>
                        }
                        fontSize={'sm'}/>
                    <DLText
                        id={'revenue-value'}
                        type={'normal'}
                        text={
                            (holdings === "NaN")?
                                <DLText
                                    id={'revenue-value'}
                                    type={'normal'}
                                    text={""}
                                    fontWeight={"bold"}
                                    marginBottom={"none"}
                                    fontSize={'md'}/>
                                :
                                <div className="d-flex">
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={getHoldingPercentage()+"%"}
                                        fontWeight={"bold"}
                                        marginBottom={"none"}
                                        fontSize={'md'}/>
                                    <div className={"mx-1"}>
                                        <DLText
                                            id={'revenue-value'}
                                            type={'normal'}
                                            text={"of"}
                                            fontWeight={"normal"}
                                            marginBottom={"none"}
                                            fontSize={'md'}/>
                                    </div>
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={" "+numberWithCommas(holdings)}
                                        fontWeight={"bold"}
                                        marginBottom={"none"}
                                        fontSize={'md'}/>
                                </div>
                        }
                        fontSize={'md'}/>
                </div>
            </div>
        )
    };

    const renderMobileCountUi = () =>{
        const {activitiesStateLoaded, isXSView, yAxisBar, barColor, yAxisLine, xAxis, isLGView, activitiesData, fundHouseCount, fundSchemeCount, marketValue} = props;

        return (
            <div>
                <div className='d-flex flex-row mb-3'
                     style={{minWidth:'max-content',marginBottom:20}}>
                    <div className='border-end py-1 h-100'
                         style={{flex:1,height:'100%'}}>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={'AUM'}
                            fontSize={'sm'}/>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={numberWithCommas(filterAumValue(marketValue))}
                            fontWeight={'bold'}
                            fontSize={'md'}/>
                    </div>
                    <div className='d-flex flex-column justify-content-center py-1 ps-3'
                         style={{flex:1,height:"40%"}}>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={'Fund Houses'}
                            fontSize={'sm'}/>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={fundHouseCount}
                            fontWeight={'bold'}
                            fontSize={'md'}/>
                    </div>
                </div>
                <div className='d-flex flex-row mb-3'
                     style={{minWidth:'max-content',marginBottom:20}}>
                    <div className='border-end d-flex flex-column justify-content-center py-1'
                         style={{flex:1,height:"40%"}}>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={' Funds'}
                            fontSize={'sm'}/>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={fundSchemeCount}
                            fontWeight={'bold'}
                            fontSize={'md'}/>
                    </div>
                    <div className='d-flex flex-column justify-content-end py-1 ps-3'
                         style={{flex:1,height:"30%"}}>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={<div className="d-flex"> Holding
                                <DLText
                                    id={'revenue-value'}
                                    type={'normal'}
                                    text={"(Market Cap)"}
                                    marginBottom={"none"}
                                    fontSize={'xs'}/>
                            </div>
                            }
                            fontSize={'sm'}/>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={
                                (holdings === "NaN")?
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={""}
                                        fontWeight={"bold"}
                                        marginBottom={"none"}
                                        fontSize={'md'}/>
                                    :
                                <div className="d-flex">
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={getHoldingPercentage()+"%"}
                                        fontWeight={"bold"}
                                        marginBottom={"none"}
                                        fontSize={'md'}/>
                                    <div className={"mx-1"}>
                                        <DLText
                                            id={'revenue-value'}
                                            type={'normal'}
                                            text={"of"}
                                            fontWeight={"normal"}
                                            marginBottom={"none"}
                                            fontSize={'md'}/>
                                    </div>
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={" "+numberWithCommas(holdings)}
                                        fontWeight={"bold"}
                                        marginBottom={"none"}
                                        fontSize={'md'}/>
                                </div>
                            }
                            fontSize={'md'}/>
                    </div>
                </div>
            </div>
        )
    };

    const renderLineChartUI = () =>{
        const {xAxis, yAxisLine, yAxisBar} = props;

        let maxValueBar = Math.max(...yAxisBar) * 2;
        let maxValueLine = Math.max(...yAxisLine);
        let maxChartValueBar = getNextRoundedNumber(maxValueBar);
        let maxChartValueLine = getNextRoundedNumber(maxValueLine);

        return(
            <div className='panel p-2'>
              <LineChart className={""}
                       externalOptions={{
                           indexAxis: 'x',
                           responsive: true,
                           plugins:
                               {
                                   legend: {
                                       position: '',
                                       display: false
                                   },
                                   title: {
                                       display: false,
                                       text: '',
                                   },
                                   tooltip:{
                                       backgroundColor: appTheme.appColor.white,
                                       bodyColor: appTheme.appColor.black,
                                       borderColor:'#D2D2D2',
                                       borderWidth:1,
                                       padding: 20,
                                       titleColor:'black',
                                       titleFont:'bold',
                                       titleFontSize: 30,
                                       boxPadding: 5
                                   }
                               },
                           onClick: '',
                           scales: {
                               x: {
                                   stacked: false,
                               },
                               y: {
                                   min: 0,
                                   ticks: {
                                       precision: 0,
                                       stepSize: maxChartValueLine / 5 // stepSize :  max + 10 /10 and round value

                                   },
                                   title: {
                                       display: true,
                                       text: 'AUM'
                                   },
                               },
                               grace:'5%',
                           }
                       }}
                       data={[
                           {
                               label: 'AUM',
                               data: yAxisLine,
                               backgroundColor: ["#084E9E"],
                               borderColor: ["#084E9E"],
                               barThickness: 15,
                           }
                           ]}
                       height={'80px'}
                       direction={'x'}
                       isLegendsVisible={false}
                       xLabels={xAxis}
            />
            </div>
        )
    };

    const renderDesktopActivities = () =>{
        const {activitiesStateLoaded, isMobileView, yAxisBar, barColor, yAxisLine, xAxis, isLGView, activitiesData, fundHouseCount, fundSchemeCount, marketValue} = props;

        return (
            <div className='panel p-3 mt-3' style={{flex: (isMDView) ? 5 : isLGView ? 3 :  2.5}}>
                <DLText
                    id={'revenue-value'}
                    type={'normal'}
                    text={'Activities in '+ moment(VALUATION_DATE).format('MMM-yyyy')}
                    fontWeight={'semi-bold'}
                    marginBottom={'none'}
                    fontSize={'lg'}/>
                <div className='border-bottom py-4'>
                    <DLText
                        id={'revenue-value'}
                        type={'normal'}
                        text={'Buying'}
                        fontWeight={'semi-bold'}
                        fontColor={'success'}
                        fontSize={'md'}/>
                    <div className='d-flex'>
                        <div style={{flex: 1}}>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={'Net Addition'}
                                fontSize={'sm'}/>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={activitiesData?.buyingFundsMarketValue === 0 ? 0 : numberWithCommas((activitiesData?.buyingFundsMarketValue)?.toFixed(2))}
                                fontWeight={'bold'}
                                fontSize={'sm'}/>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={activitiesData?.buyingFundsCount === 0 ? activitiesData?.buyingFundsCount  + ' Funds': numberWithCommas(activitiesData?.buyingFundsCount) + ' Funds'}
                                fontSize={'sm'}/>
                        </div>
                        <div style={{flex: 1}}>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={'New Entries'}
                                fontSize={'sm'}/>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={activitiesData?.newAdditionsMarketValue === 0 ? activitiesData?.newAdditionsMarketValue : numberWithCommas((activitiesData?.newAdditionsMarketValue)?.toFixed(2))}
                                fontWeight={'bold'}
                                fontSize={'sm'}/>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={activitiesData?.newAdditionsCount === 0 ? activitiesData?.newAdditionsCount + ' Funds' :numberWithCommas(activitiesData?.newAdditionsCount) + ' Funds'}
                                fontSize={'sm'}/>
                        </div>
                    </div>
                </div>
                <div className='pt-4'>
                    <DLText
                        id={'revenue-value'}
                        type={'normal'}
                        text={'Selling'}
                        fontWeight={'semi-bold'}
                        fontColor={'danger'}
                        fontSize={'md'}/>
                    <div className='d-flex'>
                        <div style={{flex: 1}}>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={'Net Reduction'}
                                fontSize={'sm'}/>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                fontWeight={'bold'}
                                text={(activitiesData?.sellingFundsMarketValue === 0) ? activitiesData?.sellingFundsMarketValue : numberWithCommas(Math.abs(activitiesData?.sellingFundsMarketValue)?.toFixed(2))}
                                fontSize={'sm'}/>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={activitiesData?.sellingFundsCount === 0 ? activitiesData?.sellingFundsCount + ' Funds': numberWithCommas(activitiesData?.sellingFundsCount) + ' Funds'}
                                marginBottom={'none'}
                                fontSize={'sm'}/>
                        </div>
                        <div style={{flex: 1}}>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={'Exits'}
                                fontSize={'sm'}/>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={activitiesData?.totalExitedMarketValue === 0 ? activitiesData?.totalExitedMarketValue : numberWithCommas((activitiesData?.totalExitedMarketValue)?.toFixed(2))}
                                fontWeight={'bold'}
                                fontSize={'sm'}/>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={activitiesData?.totalExitedFunds === 0 ? activitiesData?.totalExitedFunds + ' Funds': numberWithCommas(activitiesData?.totalExitedFunds) + ' Funds'}
                                marginBottom={'none'}
                                fontSize={'sm'}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderMobileActivities = () =>{
        const {activitiesStateLoaded, isXSView, yAxisBar, barColor, yAxisLine, xAxis, isLGView, activitiesData, fundHouseCount, fundSchemeCount, marketValue} = props;

        return (
            <div className='pt-sm-3' data-section >
                <div className='panel p-3' style={{flex: (isMDView) ? 5 : isLGView ? 3 :  2.5}}>
                    <DLText
                        id={'revenue-value'}
                        type={'normal'}
                        text={'Activities in '+ moment(VALUATION_DATE).format('MMM-yyyy')}
                        fontWeight={'semi-bold'}
                        marginBottom={'none'}
                        fontSize={'lg'}/>
                    <div className='d-flex flex-sm-row flex-column pt-2'>
                        <div className='' style={{flex:1}}>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={'Buying'}
                                fontWeight={'semi-bold'}
                                fontColor={'success'}
                                fontSize={'md'}/>
                            <div className='d-flex'>
                                <div style={{flex: 1}}>
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={'Net Addition'}
                                        fontSize={'sm'}/>
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={numberWithCommas((activitiesData?.buyingFundsMarketValue)?.toFixed(2))}
                                        fontWeight={'bold'}
                                        fontSize={'sm'}/>
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={numberWithCommas(activitiesData?.buyingFundsCount) + ' Funds'}
                                        fontSize={'sm'}/>
                                </div>
                                <div style={{flex: 1}}>
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={'New Entries'}
                                        fontSize={'sm'}/>
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={numberWithCommas((activitiesData?.newAdditionsMarketValue)?.toFixed(2))}
                                        fontWeight={'bold'}
                                        fontSize={'sm'}/>
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={numberWithCommas(activitiesData?.newAdditionsCount) + ' Funds'}
                                        fontSize={'sm'}/>
                                </div>
                            </div>
                        </div>
                        <div className='border me-sm-3 my-sm-0 my-3'>
                        </div>
                        <div className='' style={{flex:1}}>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={'Selling'}
                                fontWeight={'semi-bold'}
                                fontColor={'danger'}
                                fontSize={'md'}/>
                            <div className='d-flex'>
                                <div style={{flex: 1}}>
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={'Net Reduction'}
                                        fontSize={'sm'}/>
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        fontWeight={'bold'}
                                        text={isEmpty(activitiesData?.sellingFundsMarketValue) ? '' : numberWithCommas(Math.abs(activitiesData?.sellingFundsMarketValue)?.toFixed(2))}
                                        fontSize={'sm'}/>
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={numberWithCommas(activitiesData?.sellingFundsCount) + ' Funds'}
                                        marginBottom={'none'}
                                        fontSize={'sm'}/>
                                </div>
                                <div style={{flex: 1}}>
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={'Exits'}
                                        fontSize={'sm'}/>
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={numberWithCommas((activitiesData?.totalExitedMarketValue)?.toFixed(2))}
                                        fontWeight={'bold'}
                                        fontSize={'sm'}/>
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={numberWithCommas(activitiesData?.totalExitedFunds) + ' Funds'}
                                        marginBottom={'none'}
                                        fontSize={'sm'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isXSView ? <div style={{height: '10px', backgroundColor: '#C5C5C5'}}/> : <></>}
            </div>



        )
    };

    const renderSummaryUI = () => {
        const {activitiesStateLoaded, isMobileView, isXSView, yAxisBar, barColor, yAxisLine, xAxis, activitiesData, fundHouseCount, fundSchemeCount, marketValue} = props;
        const includesNegative = yAxisBar.some(number => number < 0);

        let maxValueBar = Math.max(...yAxisBar) ;
        let maxValueLine = Math.max(...yAxisLine);
        let maxChartValueBar = getNextRoundedNumber(maxValueBar);
        let maxChartValueLine = getNextRoundedNumber(maxValueLine);


        return (
            <div className='d-flex flex-lg-row flex-column' style={{flex: 1}}
                 id='stocks-summary'>

                <div className='pt-3' data-section id={'section1'}  style={{flex: (isMDView) ? 5 : isLGView ? 7 : 7.5}}>
                   <div className='panel px-3 pt-sm-3 pt-0'>
                    {/*<div style={{position:'absolute'}}>*/}
                    {/*    <DLLoader isVisible={true} type={'inline'}/>*/}
                    {/*</div>*/}
                    <div className='flex-shrink-1' style={{flex: 1}}>

                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={'Overview'}
                            marginBottom={'md'}
                            fontWeight={'semi-bold'}
                            fontSize={'lg'}/>
                        <div className='d-flex flex-lg-row flex-column w-100'>
                            {isSMView || isXSView? renderMobileCountUi() : renderDesktopCountUi()}
                            <div className='w-100'>
                            <LineBarChartMixed {...props} id={"stock-overview"} isBarChartClickable={true} getBarClick={getBarClick} selectedData={selectedStockArr} isFrom={'stock'} />
                            </div>
                        </div>
                        <div className="d-md-flex my-2">
                            <div className={isSMView || isXSView ? "" : "border-end"}>
                            <div className="d-flex me-md-2">
                                <div className="me-2">
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={'Marketcap Category: '}
                                        marginBottom={'none'}
                                        fontSize={'xs'}/>
                                </div>
                                <DLText
                                    id={'revenue-value'}
                                    type={'normal'}
                                    text={marketCapCategory}
                                    fontWeight={'bold'}
                                    marginBottom={'none'}
                                    fontSize={'xs'}/>
                            </div>
                            </div>
                            <div className={isSMView || isXSView ? "" : "border-end"}>
                            <div className="d-flex mx-md-2">
                                <div className="me-2">
                                <DLText
                                    id={'revenue-value'}
                                    type={'normal'}
                                    text={'Business House: '}
                                    marginBottom={'none'}
                                    fontSize={'xs'}/>
                                </div>
                                <DLText
                                    id={'revenue-value'}
                                    type={'normal'}
                                    text={businessHouse}
                                    marginBottom={'none'}
                                    fontWeight={'bold'}
                                    fontSize={'xs'}/>
                            </div>
                            </div>
                            <div className="d-flex mx-md-2 ">
                                <div className="me-2">
                                <DLText
                                    id={'revenue-value'}
                                    type={'normal'}
                                    text={'Sector: '}
                                    marginBottom={'none'}
                                    fontSize={'xs'}/>
                                </div>
                                <DLText
                                    id={'revenue-value'}
                                    type={'normal'}
                                    text={industry}
                                    marginBottom={'none'}
                                    fontWeight={'bold'}
                                    fontSize={'xs'}/>
                            </div>
                        </div>
                    </div>

                </div>
                </div>
                {isXSView ? <div style={{height: '10px', backgroundColor: '#C5C5C5'}}/> : <></>}


                {/*{!isMobileView ?*/}
                {/*    renderDesktopActivities()*/}
                {/*    :*/}
                {/*    renderMobileActivities()*/}
                {/*}*/}

            </div>
        )
    };

    const getBarClick =(e)=>{
        const {xAxis,yAxisBar} = props;
        if(isEmpty(e)){
            return;
        }
        let graphMonth = xAxis.find((item, index)=>{
            return index === e;
        })
        handleOpenModal(graphMonth)
        // console.log("in bar click",e,xAxis, graphMonth,props)

    }

    const renderDesktopView = () => {
        const {tabValue, handleTabChange} = props;

        return (
            <div>
                <div className=''>
                {renderSummaryUI()}
                </div>

                <div className={isXSView ? 'pt-0' : 'pt-3'} data-section id={'section2'}>
                    <SummaryByFundHouse isin={isin} {...props}/>
                </div>
                {isXSView ? <div style={{height: '10px', backgroundColor: '#C5C5C5'}}/> : <></>}

                <div className={isXSView ? 'pt-0' : 'pt-3 mb-2'} data-section id={'section3'}>
                    <SummaryByFund isin={isin} {...props}/>
                </div>
            </div>

        );
    };

    const renderUI = () => {
        return renderDesktopView();
    };

    if (UIState === stocksConst.CONTENT_UNAVAILABLE) {
        return
    }
    if (isEmpty(selectedStock?.company_name)) {
        return <DLLoader type={'screen'} isVisible={true}/>
    }

    const activeStyle = {
        fontWeight: 500,
        color: '#282828',
        textDecoration: 'underline',
        fontFamily: 'Poppins, sans-serif',

    };
    props.handleChange();

    return (
        <>
            <div className="bg-white ">
                <div className='bg-white ' style={{
                    position: 'sticky',
                    top: 0,
                    boxShadow: " 0px 1px 1px 0px rgba(0, 0, 0, 0.20)",
                    zIndex: 999
                }}>

                    <div className='px-3 py-2 w-100 d-flex justify-content-between'>
                        <div className={'d-flex'}>
                            <i className="fa-solid fa-chevron-left cursor pe-2"
                               style={{marginTop: 8}}
                               onClick={() => props.history.goBack()}/>
                            <div className=''>
                                <DLText marginBottom={'none'}
                                        isInline={true}
                                        text={
                                            <span className='pe-2'>{selectedStock?.company_name + ' '}
                                                <span style={{paddingBottom: 2, minWidth: 'max-content', fontSize: 12}}>
                                            {'Stock'}
                                        </span>
                                    </span>
                                        }
                                        fontWeight={'semi-bold'}
                                        fontSize={'lg'}/>
                            </div>
                        </div>

                        <div className='d-flex align-items-center justify-content-end'>

                            { (!props.isXSView )?


                                <div className="mx-2" style={{height:"36px"}}>
                                    <DLButton id={'somethingWentWrong-refresh-button'}
                                              label={'Add To Compare'}
                                              fontSize={'sm'}
                                              buttonSize={'sm'}
                                              style={{border: "0.5px solid var(--black)", color: "var(--black)"}}
                                              styleType={'outlined'}
                                              onClick={() =>{props.history.push("/compare-stocks/",{selectedIsin: isin, stockName: selectedStock?.company_name})}}
                                    />
                            </div>
                                :
                                <div className="mx-2" style={{height:"36px"}}>
                                    <DLButton
                                        id={'download-excel'}
                                        styleType={"outlined"}
                                        borderType={'square'} buttonSize={'sm'}
                                        sideMargin={"none"}
                                        reduceWidth={true}
                                        type={'primary'}
                                        fontSize={"sm"}
                                        style={{marginBottom:"4px"}}
                                        onClick={() =>{props.history.push("/compare-stocks/",{selectedIsin: isin, stockName: selectedStock?.company_name})}}
                                        label={<i class="fa-solid fa-scale-unbalanced-flip"></i>}
                                    />
                                </div>
                            }

                            {(!isEmpty(selectedStock?.company_name) && (!props.isXSView )) && !isInternationalStock && <>
                            <a href={'https://www.screener.in/company/' + sceenerCode[0]?.nse_code} target="_blank" rel="noopener noreferrer">

                                    {
                                        <DLText
                                            id={'revenue-value'}
                                            type={'normal'}
                                            text={'Screener '}
                                            fontWeight={'semi-bold'}
                                            marginBottom={'none'}
                                            isClickable={true}
                                            icon={<i className="fa-solid fa-arrow-up-right-from-square"/>}
                                            iconPosition={"end"}
                                            fontSize={'md'}/>
                                    }
                                </a>

                            </>
                            }

                            {(!isEmpty(selectedStock?.company_name) && (props.isXSView )) && !isInternationalStock &&
                            <a href={'https://www.screener.in/company/' + sceenerCode[0]?.nse_code} target="_blank" rel="noopener noreferrer">

                            <div className="d-flex justify-content-end align-items-end h-100">
                                <i className="fa-solid fa-arrow-up-right-from-square cursor ps-2"
                                                 style={{color:"#0073FF", fontSize: "20px", paddingBottom:"5px"}}/>
                            </div>
                            </a>
                            }

                        </div>
                    </div>
                    <nav className='w-100 pt-1 '>
                        <ul className='d-flex ulLine' style={{listStyle: 'none', overflowX: 'auto'}}>
                            <li className={activeSection === 'section1' ? 'active' : ''} style={{margin: '0 10px', paddingLeft: 6, minWidth:'max-content'}}>
                                <div className="custom-underlined-text cursor"
                                     style={activeSection === 'section1' ? activeStyle : {}}
                                     onClick={()=>{addScroll('section1')}}>
                                    Overview
                                </div>
                                {activeSection === 'section1' &&
                                <div className='bg-dark mt-1' style={{minHeight:'2px'}}>

                                </div>
                                }
                            </li>
                            {/*{isMobileView &&*/}
                            {/*<li className={activeSection === 'section2' ? 'active' : ''}*/}
                            {/*    style={{margin: '0 12px', minWidth: 'max-content'}}>*/}
                            {/*    <div className="custom-underlined-text cursor"*/}
                            {/*         style={activeSection === 'section2' ? activeStyle : {}}*/}
                            {/*         onClick={()=>{addScroll('section2')}}>*/}
                            {/*        Activities*/}
                            {/*    </div>*/}

                            {/*    {activeSection === 'section2' &&*/}
                            {/*    <div className='bg-dark mt-1' style={{minHeight: '2px'}}>*/}

                            {/*    </div>*/}
                            {/*    }*/}
                            {/*</li>*/}
                            {/*}*/}
                            <li className={activeSection === 'section2' ? 'active' : ''}
                                style={{margin: '0 12px', minWidth:'max-content'}}>
                                <div className="custom-underlined-text cursor"
                                     style={activeSection === 'section2' ? activeStyle : {}}
                                     onClick={()=>{addScroll('section2')}}>
                                    Fund Houses
                                </div>
                                {activeSection === 'section2' &&
                                <div className='bg-dark mt-1' style={{minHeight:'2px'}}>

                                </div>
                                }
                            </li>
                            <li className={activeSection === 'section3' ? 'active' : ''}
                                style={{margin: '0 12px', minWidth:'max-content'}}>
                                <div className="custom-underlined-text cursor"
                                     style={activeSection === 'section3' ? activeStyle : {}}
                                     onClick={()=>{addScroll('section3')}}>
                                    Funds
                                </div>
                                {activeSection === 'section3' &&
                                <div className='bg-dark mt-1' style={{minHeight:'2px'}}>

                                </div>
                                }
                            </li>
                        </ul>
                    </nav>
                </div>
                <div id={'myDiv'}
                     onScroll={()=>{handleScroll()}}
                     style={{
                        maxHeight: isMobileView ? getMobileHeight(selectedStock?.company_name, isMobileView, isXSView) : 'calc(100vh - 165px)',
                        overflowY: 'auto',
                        scrollBehavior: 'smooth'
                    }}>
                    <div className='px-sm-3'>
                        {renderUI()}
                        {
                            <PopComponent {...props} selectedData={selectedStockArr}/>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

StocksView.propTypes = {};

export default StocksView;
