import React, {useEffect, useRef} from 'react';
import { render } from 'react-dom';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { TreemapChart } from 'echarts/charts';
import {
    GridComponent,
    LegendComponent,
    TooltipComponent,
    TitleComponent,

} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import '../EChart/eChart.css';
import withRouter from "react-router-dom/es/withRouter";
import {isEmpty} from "../../utils/validations";
import {getMaxRoundedNumber, getRoundedStepSize} from "../../utils/stocksHelper";
import {numberWithCommas} from "../../utils/helper";
import {connect} from "react-redux";

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    TreemapChart,
    CanvasRenderer,
]);

interface AppProps {}
function HorizontalBarChart(props) {
    const {series, yAxislabels, xAxisMaxValue, isXSView, id, xAxisMinValue, leftGap, onClick,rightGap, yAxisPosition, showDownloadIcon,
        splitNumber,isBarChartClickable, getBarClick, percentage, gridLeft,gridRight, gridTop, gridBottom} = props;

    const getLeftGrid = () =>{
        let longest = yAxislabels.reduce(
            function (a, b) {
                return a.length > b.length ? a : b;
            }
        );
        if(longest.length < 25){
            return 160;
        }else if(longest.length < 30){
            return 190;
        }else if(longest.length < 35){
            return 220;
        }else if(longest.length < 40){
            return 250;
        }else if(longest.length < 45){
            return 280;
        }else if(longest.length < 50){
            return 310;
        }else if(longest.length < 55){
            return 340;
        }else{
            return 370;
        }
    }

    const numericNumbers = series?.data.map(Number);
    let maxValue = Math.max(...numericNumbers);
    let minValue = Math.min(...numericNumbers);
    let stepSize;
    let steps = isXSView? 2 : 4;

    // For all positive values in the array

    if (minValue >= 0 && maxValue > 0) {
        minValue = 0;
        if (minValue >= 0 && maxValue > 0 && maxValue < 10) {
            maxValue = 10;
        } else {
            maxValue = getMaxRoundedNumber(Math.trunc(maxValue));
        }

        stepSize = maxValue / steps;
        stepSize = getRoundedStepSize(stepSize);
        let difference = maxValue - (stepSize * steps);
        maxValue = stepSize * (steps + Math.ceil(difference / stepSize));
        stepSize = maxValue / steps;
    }

    // For all negative values in the array
    if (minValue < 0 && maxValue <= 0) {

        let newMaxValue = minValue * (-1)
        let newMinValue = Math.round(maxValue * (-1))

        if (newMinValue >= 0 && newMaxValue > 0 && newMaxValue < 10) {
            newMaxValue = 10;
        } else {
            newMaxValue = Math.ceil(getMaxRoundedNumber(newMaxValue));
        }

        stepSize = newMaxValue / steps;
        stepSize = getRoundedStepSize(stepSize);
        let difference = newMaxValue - (stepSize * steps);
        newMaxValue = stepSize * (steps + Math.ceil(difference / stepSize));
        stepSize = newMaxValue / steps;
        minValue = newMaxValue * (-1);
        maxValue = 0

    }

    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            formatter : function (params) {

                let label = params[0].name || '';

                if (label) {
                    label += ': ';
                }
                if (percentage){
                    label += params[0].value + '%';
                }else {
                    label += params[0].value ;

                }
                return numberWithCommas(label);
            }
        },
        legend: {
            show: false
        },
        toolbox: {
            show: true,
            orient: 'vertical',
            right: 0,
            top: 0,
            feature: {
                // mark: { show: true },
                // dataView: { show: true, readOnly: false },
                // magicType: { show: true, type: ['line', 'bar', 'stack'] },
                // restore: { show: true },
                saveAsImage: { show: showDownloadIcon }
            }
        },
        xAxis: [
            {
                min: minValue,
                max: maxValue,
                type: 'value',
                splitNumber: splitNumber,
                boundaryGap: false,
                interval: stepSize,
                position: isEmpty(yAxisPosition)? 'left' : yAxisPosition,
            }
        ],
        yAxis: [
            {
                type: 'category',
                axisTick: { show: false },
                data: yAxislabels
            }
        ],
        grid:{
            top: isEmpty(gridTop)? (showDownloadIcon ? 40 : 20) : gridTop,
            left: isEmpty(gridLeft)? getLeftGrid() : gridLeft,
            right: isEmpty(gridRight)? 50 : gridRight,
            bottom: isEmpty(gridBottom)? 50 : gridBottom
        },
        series: series,
        roam: false,
        zoom: null,
    };

    const getHeight = () =>{
        const {isMobileView, isSMView, isXSView, height} = props;

        if (height){
            return height
        }

        return '350px'
    };

    useEffect(()=>{
        var myChart = echarts.init(document.getElementById(id));

        myChart.setOption(option);

        myChart.on('click', function (params) {
            if(isBarChartClickable){
                let element = params.dataIndex;
                let index =0;
                for(let i = yAxislabels.length-1; i >= 0 ; i--){
                    if(i === element){
                       break;
                    }
                    index++;
                }

                onClick(index);
            }
        });
        return () => {
            if (myChart) {
                myChart.dispose();
            }
        };
    },[series])



    return (
        <div id={id} className='' style={{width:'100%', height : getHeight()}}>
            <ReactEChartsCore
                echarts={echarts}
                option={option}
                theme={'theme_name'}
                opts={{}}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isSMView: state.appState.deviceInfo.isSMView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXSView: state.appState.deviceInfo.isXSView
});
export default connect(mapStateToProps, {})(HorizontalBarChart);