import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {stocksConst} from "../../stocks/stocksConst";
import DLLoader from "../../core/components/DLLoader/DLLoader";
import {useParams} from 'react-router-dom'
import {isEmpty} from "../../utils/validations";
import appTheme from "../../assets/appTheme";
import moment from "moment";
import {getFixedValuesAfterDecimal, getTrimmedValue, searchOnWordBoundaryOverview} from "../../utils/helper";
import CompareStocksView from "./CompareStocksView";
import {getStocksSummary, resetCompareStock, setCompareStock} from "../../stocks/StocksActions";
import {resetFund, resetFundHouse} from "../../fund/FundAction";
import {
    resetAllFilterData,
    resetBusinessHouses,
    resetIndustry,
    resetMarketCap,
    resetStocks,
    setAdvanceSearchFilter, setKeywordFilter
} from "../../AdvanceSearch/AdvanceSeachAction";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;


function CompareStocks(props) {

    const {firstStock, secondStock, thirdStock, firstStockName, secondStockName, thirdStockName} = props;

    const [stocksState, setStocksState] = useState({
        stockListing: [],
        firstOption: "",
        firstOptionName: "",
        firstSearched: "",
        secondOption: "",
        secondOptionName: "",
        secondSearched: "",
        thirdOption: "",
        thirdOptionName: "",
        thirdSearched: "",
        filteredOption: [],
        buttonId: "",
        selectedStocks: [],
        isDataSelected: false,
    });

    const [advanceSearchState, setAdvanceSearchState] = useState({
        UIState: '',
        searchResult: [],
        tableHeader: [],
        businessHouses: [],
        funds: [],
        fundHouseForFund:[],
        fundHouses:[],
        fund_fundHouse: [],
        industries: [],
        stocks: [],
        marketCaps: [],
        keyword: '',
        urlEndPoint: '',
        isDataUpdated: false,
        isFundModalOpen: false,
        isFundHouseModalOpen: false,
        isExternalClick: false,
    });


    const [UIState, setUIState] = useState();

    const [anchorEl, setAnchorEl] = useState(null);
    const [openFirst, setOpenFirst] = useState(false);
    const [openSecond, setOpenSecond] = useState(false);
    const [openThird, setOpenThird] = useState(false);

    useEffect(() => {
        getStocksSummary(VALUATION_DATE).then((res) => {
            if (res.success && !isEmpty(res.data?.data)) {
                const copiedArray = res.data.data.slice(1, res.data.data.length);
                setStocksState((prevState) => ({
                    ...prevState,
                    stockListing: getSortedOptions(copiedArray),
                    filteredOption: getSortedOptions(copiedArray),

                }))
                setUIState(stocksConst.CONTENT_AVAILABLE)
            }
        });
        if (props.history.action === "POP" || props.history.action === "REPLACE") {
            //backward navigation
            setStocksState((prevState) => ({
                ...prevState,
                selectedStocks: [firstStock, secondStock, thirdStock]
            }))
        } else {
            resetCompareStock();
            resetListingData();
        }
        if (!isEmpty(props.location?.state?.selectedIsin)) {
            setCompareStock({name: "firstStock", value: props.location?.state?.selectedIsin});
            setCompareStock({name: "firstStockName", value: props.location?.state?.stockName});
            props.history.replace();
        }

    }, []);

    useEffect(() => {
        if (stocksState.isDataSelected) {
            setStocksState((prev) => ({
                ...prev,
                selectedStocks: [stocksState.firstOption, stocksState.secondOption, stocksState.thirdOption],
                isDataSelected: false
            }));
        }
    }, [stocksState.isDataSelected]);


    const resetListingData = () => {
        resetAllFilterData();
        resetFund();
        resetFundHouse();
    };

    const clearAllFilters = () => {
        resetCompareStock();
        resetListingData();
        setOpenFirst(false);
        setOpenSecond(false);
        setOpenThird(false);
    };

    const getSortedOptions = (data) => {
        if (isEmpty(data)) {
            return;
        }
        data.sort((a, b) => {
            let fa = a.company_name.toLowerCase(),
                fb = b.company_name.toLowerCase();

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
        return data;
    };

    const getDropDownOptions = (data) => {
        const {firstOption, secondOption, thirdOption, stock} = stocksState;
        let selectedValues = [firstOption, secondOption, thirdOption];
        selectedValues.map((item) => {
            data.map((obj, index) => {
                if (item === obj?.isin_code) {
                    data.splice(index, 1)
                }
            })
        });
        return data;
    };

    const handleClick = (event, buttonId) => {
        setAnchorEl(event.currentTarget);
        switch (buttonId) {
            case "First_Button":
                setOpenFirst(true);
                setOpenSecond(false);
                setOpenThird(false);
                setStocksState((prevState) => ({
                    ...prevState,
                    secondSearched: "",
                    thirdSearched: ""
                }));
                break;
            case "Second_Button":
                setOpenFirst(false);
                setOpenSecond(true);
                setOpenThird(false);
                setStocksState((prevState) => ({
                    ...prevState,
                    firstSearched: "",
                    thirdSearched: ""
                }));
                break;
            case "Third_Button":
                setOpenFirst(false);
                setOpenSecond(false);
                setOpenThird(true);
                setStocksState((prevState) => ({
                    ...prevState,
                    firstSearched: "",
                    secondSearched: ""
                }));
                break;
        }

    };

    const closeDropDown = (popUpId) => {
        const {firstStock, secondStock, thirdStock} = props;
        switch (popUpId) {
            case "First_PopUp" :
                setOpenFirst(false);
                setStocksState((prevState) => ({
                    ...prevState,
                    firstSearched: ""
                }));
                break;
            case "Second_PopUp" :
                setOpenSecond(false);
                setStocksState((prevState) => ({
                    ...prevState,
                    secondSearched: ""
                }));
                break;
            case "Third_PopUp" :
                setOpenThird(false);
                setStocksState((prevState) => ({
                    ...prevState,
                    thirdSearched: ""
                }));
                break;
        }
    };

    const clearOption = (popUpId) => {
        switch (popUpId) {
            case "First_Button" :
                setStocksState((prevState) => {
                    const updatedSelectedStocks = [...prevState.selectedStocks];
                    updatedSelectedStocks[0] = '';
                    return {
                        ...prevState,
                        firstOption: "",
                        firstOptionName: "",
                        selectedStocks: updatedSelectedStocks
                    };
                });
                setCompareStock({name: "firstStock", value: ''});
                setCompareStock({name: "firstStockName", value: ''});
                break;
            case "Second_Button" :
                setStocksState((prevState) => {
                const updatedSelectedStocks = [...prevState.selectedStocks];
                updatedSelectedStocks[1] = '';
                return {
                    ...prevState,
                    secondOption: "",
                    secondOptionName: "",
                    selectedStocks: updatedSelectedStocks
                }
                });
                setCompareStock({name: "secondStock", value: ''});
                setCompareStock({name: "secondStockName", value: ''});
                break;
            case "Third_Button" :
                setStocksState((prevState) => {
                const updatedSelectedStocks = [...prevState.selectedStocks];
                updatedSelectedStocks[2] = '';
                return {
                    ...prevState,
                    thirdOption: "",
                    thirdOptionName: "",
                    selectedStocks: updatedSelectedStocks
                }
                });
                setCompareStock({name: "thirdStock", value: ''});
                setCompareStock({name: "thirdStockName", value: ''});
                break;

        }
    };

    const requestSearch = (searchedVal, searchId) => {
        const {stockListing} = stocksState;

        switch (searchId) {
            case "First_Search":
                if (isEmpty(searchedVal)) {
                    setStocksState((prevState) => ({
                        ...prevState,
                        firstSearched: "",
                        filteredOption: stockListing
                    }))
                } else {
                    let data = searchOnWordBoundaryOverview(searchedVal, stockListing, "company_name");
                    setStocksState((prevState) => ({
                        ...prevState,
                        firstSearched: searchedVal,
                        filteredOption: data
                    }))
                    // setFundsGraph(data);
                }
                break;
            case "Second_Search":
                if (isEmpty(searchedVal)) {
                    setStocksState((prevState) => ({
                        ...prevState,
                        secondSearched: "",
                        filteredOption: stockListing
                    }))
                } else {
                    let data = searchOnWordBoundaryOverview(searchedVal, stockListing, "company_name");
                    setStocksState((prevState) => ({
                        ...prevState,
                        secondSearched: searchedVal,
                        filteredOption: data
                    }))
                    // setFundsGraph(data);
                }
                break;
            case "Third_Search":
                if (isEmpty(searchedVal)) {
                    setStocksState((prevState) => ({
                        ...prevState,
                        thirdSearched: "",
                        filteredOption: stockListing
                    }))
                } else {
                    let data = searchOnWordBoundaryOverview(searchedVal, stockListing, "company_name");
                    setStocksState((prevState) => ({
                        ...prevState,
                        thirdSearched: searchedVal,
                        filteredOption: data
                    }))
                    // setFundsGraph(data);
                }


        }

    };

    const areAllValuesNotEmpty = () => {
        const {advanceSearchFilter} = props;
        return isEmpty(advanceSearchFilter.fundHouses) && isEmpty(advanceSearchFilter.funds)
            && isEmpty(firstStock) && isEmpty(secondStock) && isEmpty(thirdStock);
    };

    const setDropDownOption = (popUpId, isin, name) => {
        const {isMobileView} = props;
        switch (popUpId) {
            case "First_PopUp" :
                setStocksState((prevState) => ({
                    ...prevState,
                    firstOption: isin,
                    buttonId: "First_Button",
                    firstOptionName: name
                }));
                setCompareStock({name: "firstStock", value: isin});
                setCompareStock({name: "firstStockName", value: name});
                closeDropDown(popUpId);
                break;
            case "Second_PopUp" :
                setStocksState((prevState) => ({
                    ...prevState,
                    secondOption: isin,
                    buttonId: "Second_Button",
                    secondOptionName: name,
                }));
                setCompareStock({name: "secondStock", value: isin});
                setCompareStock({name: "secondStockName", value: name});
                closeDropDown(popUpId);
                break;
            case "Third_PopUp" :
                setStocksState((prevState) => ({
                    ...prevState,
                    thirdOption: isin,
                    buttonId: "Third_Button",
                    thirdOptionName: name
                }));
                setCompareStock({name: "thirdStock", value: isin});
                setCompareStock({name: "thirdStockName", value: name});
                closeDropDown(popUpId);
                break;
        }
        setStocksState((prev) => ({
            ...prev,
            isDataSelected: true
        }));
    };

    const handleChipClick = (action, type, listingArr) => {
        if (action === 'apply_filter') {
            setAdvanceSearchState((prev) => ({
                ...prev,
                [type]: true
            }));
        }

        if (action === 'remove_filter') {
            switch (type) {
                case 'funds':
                    resetFund();
                    setAdvanceSearchFilter('funds', []);
                    break;
                case 'fundHouses':
                    resetFundHouse();
                    setAdvanceSearchFilter('fundHouses', []);
                    break;
                default:
            }
            setAdvanceSearchState((prev) => ({
                ...prev,
                [type]: [],
                [listingArr]: []
            }));
        }
    };

    const setFilter = (name, selectedValues, idVar) => {
        const {isExternalClick} = advanceSearchState;
        let values = [];

        if (name === 'funds') {
            let fundsValues = [];
            let fundHouseValues = [];

            selectedValues.forEach((item) => {
                fundsValues.push(item[idVar]);
                fundHouseValues.push(item['fund_house_id'])
            });

            setAdvanceSearchState((prev) => ({
                ...prev,
                funds:fundsValues,
                fundHouseForFund:fundHouseValues
            }));

            setAdvanceSearchFilter(name, fundsValues);
            setAdvanceSearchFilter('fundHouseForFund', fundHouseValues);
        } else {

            selectedValues.forEach((item) => {
                values.push(item[idVar])
            });

            setAdvanceSearchState((prev) => ({
                ...prev,
                fundHouses:values
            }));

            setAdvanceSearchFilter(name, values);
        }

        if (isExternalClick) {
            setAdvanceSearchState((prev) => ({
                ...prev,
                //isExternalSectorSelected: false,
                externalSectorData: [],
                externalFundData: [],
                externalFundHouseData: []
            }));
        }
    };

    const getFileName = (blockName) => {
        let date = new Date();
        return blockName + moment(date).format('YYYY-MM-DD')
    };

    const onChangeValue = (name, value) => {
        setAdvanceSearchState((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const refresh = () => {
        window.location.reload(true);
    };

    if (isEmpty(UIState)) {
        return (
            <DLLoader type={'screen'} isVisible={true}/>
        );
    }

    return (
        <CompareStocksView
            {...props}
            refresh={refresh}
            {...stocksState}
            {...advanceSearchState}
            handleClick={handleClick}
            anchorEl={anchorEl}
            openFirst={openFirst}
            openSecond={openSecond}
            openThird={openThird}
            setDropDownOption={setDropDownOption}
            closeDropDown={closeDropDown}
            clearOption={clearOption}
            requestSearch={requestSearch}
            handleChipClick={handleChipClick}
            setFilter={setFilter}
            onChangeValue={onChangeValue}
            getDropDownOptions={getDropDownOptions}
            clearAllFilters={clearAllFilters}
            areAllValuesNotEmpty={areAllValuesNotEmpty}
            getFileName={getFileName}
        />
    );
}


CompareStocks.propTypes = {};
const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    fundHouseListingData: state.fundState.fundHouseListingData,
    fundListingData: state.fundState.fundListingData,
    advanceSearchFilter: state.AdvanceSearchState.advanceSearchFilter,
    firstStock: state.stockState.firstStock,
    secondStock: state.stockState.secondStock,
    thirdStock: state.stockState.thirdStock,
    firstStockName: state.stockState.firstStockName,
    secondStockName: state.stockState.secondStockName,
    thirdStockName: state.stockState.thirdStockName
});

export default connect(mapStateToProps, {})(CompareStocks);
