import React, {useEffect, useState} from 'react'
import DLLoader from "../../core/components/DLLoader/DLLoader";
import DLText from "dlcomponent/components/DLText/DLText";
import DLButton from "dlcomponent/components/DLButton/DLButton";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FundHouseChart from "./FundHouseChart";
import FundHouseTable from "./FundHouseTable";
import {
    getAllFundHouses,
    getFundHouseSummary, resetFundHousePage, resetFundHouseRowsPerPage,
    resetFundHouseTableByOrder, resetFundHouseTableByOrderBy,
    setFundHouseKeywordFilter , resetFundHouseTabValue, setFundHouseTabValue,
} from "../FundHouseAction";
import ChartRadioGroup from "../../component-utils/ChartRadioGroup/ChartRadioGroup";
import CustomTabPanel from "../../component-utils/CustomTabPanel/CustomTabPanel";
import DLSearchAutoComplete from 'dlcomponent/components/DLSearchAutoComplete/DLSearchAutoComplete'
import {
    getChartData,
    getFixedValuesAfterDecimal,
    getIndexArr,
    getTrimVal,
    searchOnWordBoundary
} from "../../utils/helper";
import {isEmpty} from "../../utils/validations";
import {downloadData, downloadDataByObject} from "../../fund/FundAction";
import moment from "moment";
import {connect} from "react-redux";
import DLInputTextField from "dlcomponent/components/DLInputTextField/DLInputTextField";
import {setKeywordFilter} from "../../stocks/StocksActions";
import appTheme from "../../assets/appTheme";

const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function FundHouse(props) {

    const [fundHouseListingState, setFundHouseListingState] = useState({
        allFundHouseData : [],
        fundHouseName:'',
        percentage: false,
        isSearchApply: false,
        searchResultNotAvailable: false,
        chartType:'marketValue',
        sortedData: []
    });
    const [chartLabelsArray, setChartLabelsArray] = useState([]);
    const [chartDataArray, setChartDataArray] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tableHeader, setTableHeader] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isTableLoading, setIsTableLoading] = useState(true);

const{tabValue} = props;

    const getData = (data) =>{
        let dataObj = [];
        if (isEmpty(data)){
            return dataObj
        }

        data.forEach((item)=>{
            dataObj.push({...item, label:item?.fund_house_name, value:item?.fund_house_id})
        });
        if (isEmpty(dataObj)){
            return []
        }
        return dataObj.sort((a, b) => {
            let A = a?.label?.trim();
            let B = b?.label?.trim();

            return A?.localeCompare(B)
        });
    };

    useEffect(()=>{
        if (props.history.action === "POP" || props.history.action === "REPLACE"){
            //backward navigation
        }else {
            // resetListingData()
            resetFundHouseTableByOrder();
            resetFundHouseTableByOrderBy();
            resetFundHousePage();
            resetFundHouseRowsPerPage();
            resetFundHouseTabValue();
        }

        getFundHouseSummary(VALUATION_DATE).then((res) => {
            if (res.success && !isEmpty(res.data?.data)) {
                const copiedArray = res.data.data.slice(1, res.data.data.length);
                setTableData(copiedArray);
                setFundsGraph(copiedArray);
                setTableHeader(res.data.data[0]);
                setIsLoading(false);
                setIsTableLoading(false)
            }
        })

    },[]);

    useEffect(()=>{
        setIsLoading(true);
        setTimeout(()=>{
            setIsLoading(false)
        },100)
    },[props.isLGView, props.isMDView, props.isXLView, props.isSMView, props.isXSView]);


    useEffect(()=>{
        const {isLGView, isXLView, keyword, isMobileView} = props;
        const {chartType} = fundHouseListingState;

        if (isEmpty(tableData)){
            return
        }

        if (isEmpty(keyword)){

            setFundHouseListingState((prev)=>({
                ...prev,
                isSearchApply: true,
                sortedData: []
            }));

            let chartObj = getChartData(chartType, tableData, 'fund_house_name', isLGView, isXLView,isMobileView);

            setChartLabelsArray(chartObj?.newLabelData);
            setChartDataArray(chartObj?.newChartData);

            setTimeout(()=>{
                setFundHouseListingState((prev)=>({
                    ...prev,
                    isSearchApply: false,
                    searchResultNotAvailable: false
                }))
            },300)
        }else {
            let sortedData = [];
            setFundHouseListingState((prev)=>({
                ...prev,
                isSearchApply: true
            }));

            let options = tableData.map((item)=>{
                return {...item, label:item.fund_house_name, value: item.fund_house_name}
            });

            sortedData = searchOnWordBoundary(props.keyword, JSON.parse(JSON.stringify(options)));

            if (!isEmpty(sortedData)){
                let data = searchOnWordBoundary(props.keyword, options);
                let chartObj = getChartData(chartType, data, 'fund_house_name', isLGView, isXLView,isMobileView);
                console.log("}}}}}}}}}}",data,chartObj)
                setFundHouseListingState((prev)=>({
                    ...prev,
                    sortedData: sortedData,
                    searchResultNotAvailable: false
                }));
                setChartLabelsArray(chartObj?.newLabelData);
                setChartDataArray(chartObj?.newChartData);
            }else {
                setFundHouseListingState((prev)=>({
                    ...prev,
                    sortedData: [],
                    searchResultNotAvailable: true
                }));
            }

            setTimeout(()=>{
                setFundHouseListingState((prev)=>({
                    ...prev,
                    isSearchApply: false
                }))
            },300)

        }

    },[props.keyword, tableData]);
    const setFundsGraph = (data) => {
        const {isLGView, isXLView, isMobileView} = props;
        let length = data?.length;
        if (data?.length < 10){
            length = data?.length
        }

        for (let i = 0; i < length; i++) {

            let chartLabel = getTrimVal(data[i].fund_house_name, isLGView, isXLView, isMobileView);
            let chartData = getFixedValuesAfterDecimal(data[i].total_market_value,2);

            setChartLabelsArray(fundHouseNames => [...fundHouseNames, chartLabel]);
            setChartDataArray(marketValueInCrores => [...marketValueInCrores, chartData]);

        }
    };

    const handleClick = (elementIndex) => {
        const {chartType, sortedData} = fundHouseListingState;
        let tableDataArr = !isEmpty(sortedData) ? sortedData : tableData;
        let indexArr = getIndexArr(chartType, tableDataArr);
        let clickedFundHouse = indexArr[elementIndex];
        props.history.push('/fund-houses/'+clickedFundHouse.fund_house_id)

    };

    const handleTabChange = (event, newValue) => {
        setFundHouseTabValue(newValue);
    };


    const deepCopy = (obj) => {
        return JSON.parse(JSON.stringify(obj));
    };

    //order by descending
    const compareByMarketValueChangeMoM = (a, b) => {
        if (a.net_buying_selling < b.net_buying_selling) {
            return 1;
        }
        if (a.net_buying_selling > b.net_buying_selling) {
            return -1;
        }
        return 0;
    };

    const compareByMarketValueChangePercent = (a, b) => {
        if (a.net_buying_selling_percentage < b.net_buying_selling_percentage) {
            return 1;
        }
        if (a.net_buying_selling_percentage > b.net_buying_selling_percentage) {
            return -1;
        }
        return 0;
    };

    const handleRadioChange = (event) => {
        const {isLGView, isXLView,isMobileView} = props;
        let chartType =  event ;
        const {sortedData} = fundHouseListingState;
        let tableDataArr = !isEmpty(sortedData) ? sortedData : tableData;

        let chartObj = getChartData(chartType, tableDataArr, 'fund_house_name', isLGView, isXLView,isMobileView);

        setFundHouseListingState((prevState)=>({
            ...prevState,
            percentage: (chartType === 'changePercent' || chartType ===  'changeSellingPercent'),
            chartType: chartType
        }));
        setChartLabelsArray(chartObj?.newLabelData);
        setChartDataArray(chartObj?.newChartData);
    };

    const onChangeKeywordFilter = (keyword) =>{
        setFundHouseKeywordFilter(keyword)
    };

    if (isLoading) {
        return (
            <div className={''}>
                <DLLoader type={'inline'} isVisible={true}/>
            </div>
        )
    }

    const getFileName = (blockName) =>{
        let date = new Date();
        return 'Fund-Houses-'+blockName+moment(date).format('YYYY-MM-DD')
    };

    const getTableBarUI = (ui) =>{
        const {searchResultNotAvailable, percentage, sortedData, chartType} = fundHouseListingState;
        let tableDataArr = !isEmpty(sortedData) ? sortedData : tableData;

        if (searchResultNotAvailable){
            if(ui === 'table'){
                return (
                    <div style={{minHeight: 'calc(100vh - 400px)'}}>
                        <div className= {ui === "table"? "p-3" : "" }>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={'No matching fund house found.'}
                                fontSize={'lg'}/>
                        </div>
                    </div>
                )
            }else {
                return (
                    <div style={{minHeight: 'calc(100vh - 400px)'}}>
                        <ChartRadioGroup handleRadioChange={handleRadioChange}
                                         chartType={chartType}
                                         isXSView={props.isXSView}
                                         isSMView={props.isSMView}/>
                        <div className='px-3'>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={'No matching fund house found.'}
                                fontSize={'lg'}/>
                        </div>
                    </div>
                )
            }
        }

        if(ui === 'table'){
            return(
                <FundHouseTable data={tableDataArr} {...props} tableHeader={tableHeader}/>)
        }else {
            return (
                <>
                    <ChartRadioGroup handleRadioChange={handleRadioChange}
                                     chartType={chartType}
                                     isXSView={props.isXSView}
                                     isSMView={props.isSMView}/>
                        {getResult(chartDataArray)}
                    <FundHouseChart yAxis={chartLabelsArray}
                                    xAxis={chartDataArray}
                                    handleClick={handleClick}
                                    chartType={chartType}
                                    percentage={percentage}
                                    isMobileView={props.isMobileView}/>
                </>
            )
        }
    };

    const getResult = (data) =>{
        if (isEmpty(data)){
            return
        }

        if (data?.length >= 10){
            return(
                <div className='py-2 px-3'>
                    Showing top 10 results
                </div>
            )
        }else {
            if (data.length === 1){
                return (
                    <div className='py-2 px-3'>
                        Showing {data?.length} result
                    </div>
                )
            }else {
                return (
                    <div className='py-2 px-3'>
                        Showing {data?.length} results
                    </div>
                )

            }
        }
    };

    const renderDataListingUI = () =>{
        const {percentage, sortedData, isSearchApply} = fundHouseListingState;
        const {isXSView} = props;

        if (isTableLoading){
            return
        }

        return(
            <div className='panel mx-sm-3'
                 id='fund-house-listing'>
            <Box sx={{ width: '100%'  }}>
                <Box className={isXSView ? 'd-flex justify-content-between mx-3' :'d-flex justify-content-between mx-4 pt-3'}
                     sx={{ borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab sx={{paddingLeft: 1, textTransform:'capitalize'}} label="Chart" {...a11yProps(0)} />
                        <Tab sx={{ textTransform:'capitalize'}} label="Table" {...a11yProps(1)} />
                    </Tabs>
                    <div  style={{width:36, height:36, minWidth:'max-content', paddingTop:5}}>
                        {/*<DLButton*/}
                        {/*    id={'download-excel'}*/}
                        {/*    styleType={"outlined"}*/}
                        {/*    borderType={'square'} buttonSize={'sm'}*/}
                        {/*    sideMargin={"none"}*/}
                        {/*    reduceWidth={true}*/}
                        {/*    type={'primary'}*/}
                        {/*    style={{width: 36}}*/}
                        {/*    onClick={() =>{*/}
                        {/*        downloadData('/service/media/home/fundHouseSummary/download?date=2023-09-30','', 'Download', getFileName('Summary-'))*/}
                        {/*    }}*/}
                        {/*    label={<i className='fas fa-download fs-6'/>}*/}
                        {/*/>*/}
                        <div className='d-flex align-items-center justify-content-center downloadButtonCss cursor'>
                            <i className='fas fa-download fs-6'
                               onClick={() =>{
                                   downloadData('/service/media/home/fundHouseSummary/download?date=2023-09-30','', 'Download', getFileName('Summary-'))
                               }}
                               style={{fontSize: 12}}/>
                        </div>
                    </div>
                </Box>
                <CustomTabPanel id={'tableTabPanel'} value={tabValue} index={0}>
                    {(isEmpty(chartDataArray) || isEmpty(chartLabelsArray)) ?
                        <div>
                            <ChartRadioGroup handleRadioChange={handleRadioChange}
                                             chartType={fundHouseListingState.chartType}
                                             isXSView={props.isXSView}
                                             isSMView={props.isSMView}/>
                        <div className='d-flex align-items-center justify-content-center m-3' style={{minHeight:300}}>
                            <DLText
                                id={'revenue-value'}
                                type={'normal'}
                                text={'No data found'}
                                fontSize={'md'}/>
                        </div>
                        </div>
                        :
                        getTableBarUI('chart')
                    }
                     </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1} id={'tableTabPanel'}>
                    {isSearchApply ? <></>
                        :
                        getTableBarUI('table')
                    }
                </CustomTabPanel>
            </Box>
            </div>
        )
    };

    const renderFilterUI = () =>{
        const {keyword, isXSView} = props;

        return(
            <div className='px-3' style={{marginBottom: isXSView ? 8 : 14}}>
                <div className={isXSView ? ' w-100' : 'w-50'} >
                    <DLInputTextField
                        id={'businessList-keywordSearch'}
                        label={''}
                        rows={1}
                        fullWidth
                        styleType={"search"}
                        placeholder={'Search...'}
                        value={keyword}
                        onChangeValue={(e) => onChangeKeywordFilter(e)}
                        isClearable={true}
                        inputHeight={'sm'}
                        marginBottom={"none"}
                        InputProps={{
                            endAdornment: (

                                    keyword ?
                            <i className="fa fa-remove m-2 cursor" onClick={()=>{onChangeKeywordFilter("")}} style={{color: appTheme.appColor.black}}/>
                            :
                            <i className={'fas fa-search pr-1'} style={{color: appTheme.appColor.black}}/>
                        )
                        }}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {

                                document?.activeElement?.blur()
                            }
                        }}
                        disableHelperMessageHeight={true}
                    />
                </div>
            </div>
        )
    };

    return (
        <div className='pb-2'>
            <div>
            </div>
            <div className='fixedHeader bg-white px-3 py-2'>
                    <DLText
                        id={'summary-by-country'}
                        type={'normal'}
                        text={'Fund Houses'}
                        marginBottom={"none"}
                        fontWeight={'semi-bold'}
                        fontSize={'xl'}/>
            </div>
            <div className='pb-2'>
            {renderFilterUI()}
            {renderDataListingUI()}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    keyword: state.fundHouseState.keyword,
    order: state.fundHouseState.order,
    orderBy: state.fundHouseState.orderBy,
    page: state.fundHouseState.page,
    rowsPerPage: state.fundHouseState.rowsPerPage,
    tabValue: state.fundHouseState.tabValue
});

export default connect(mapStateToProps, {})(FundHouse);
