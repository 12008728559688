import * as React from 'react';
import PropTypes from 'prop-types';
import {alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import {visuallyHidden} from '@mui/utils';
import {Link} from "react-router-dom";
import {filterNetBuyingSellingValue, getTrimmedValue, getTrimVal, numberWithCommas} from "../../utils/helper";
import {isEmpty} from "../../utils/validations";
import {filterAumValue, getFundsTrimVal, getMarketValue} from "../../utils/stocksHelper";
import {connect} from "react-redux";
import {useEffect} from "react";
import { resetFundPage, resetFundRowsPerPage, resetFundTableByOrder, resetFundTableByOrderBy, setFundPage, setFundRowsPerPage, setFundTableByOrder, setFundTableByOrderBy } from "../FundAction";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
    const {headingData, order, orderBy, onRequestSort, isMobileView, isXSView, tableHeader} = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const getLabel = (tableHeader, key) =>{
        const keys = Object.keys(tableHeader); // Get an array of keys
        const values = Object.values(tableHeader); // Get an array of values
        let keyIndex = keys.indexOf(key);

        if (!isEmpty(keyIndex)){
            return values[keyIndex]
        }
    };

    let minimumWidth;

    if (isMobileView){
        minimumWidth = 250
    }else {
        minimumWidth = 450
    }


    return (
        <TableHead>
            <TableRow>
                {headingData.map((heading, index) => (
                    <TableCell
                        key={heading.id}
                        sx={{width: 10, minWidth: index === 0 ? minimumWidth : 'max-content'}}
                        align={heading.numeric ? 'right' : 'left'}
                        padding={'none'}
                        style={{position:index === 0 ? 'sticky' : '', left:0, zIndex:index === 0 ? 101 : 100}}
                        sortDirection={orderBy === heading.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === heading.id}
                            direction={orderBy === heading.id ? order : 'asc'}
                            style={{paddingTop: 10}}
                            onClick={createSortHandler(heading.id)}>

                            <div className='flex-nowrap text-nowrap'
                                 style={{width:'max-content', textTransform: 'capitalize', paddingLeft:(index === 0)? 16 : 0, paddingRight: (index ===  headingData.length - 1) ? 16 : 0 }}>
                                {getLabel(tableHeader, heading.label)}
                            </div>

                            {orderBy === heading.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    headingData: PropTypes.array.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const extractColumnHeadings = (rowData) => {
    let headingData = [];
    Object.keys(rowData).forEach(function (key, index) {
        if (key === 'label' || key === 'value'){

        }else {
            let numeric = true;
            if (isNaN(rowData[key])) {
                numeric = false
            }
            if (key !== 'fund_house_id' && key !== 'fund_id') {
                let head = {
                    id: key,
                    label: key,
                    numeric: numeric
                };
                headingData.push(head);
            }
        }
    });
    return headingData;
};


const FundsTable = (props) => {
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(true);

    const {data , page, rowsPerPage, order , orderBy}   = props;
    let headingData = extractColumnHeadings(data[0]);
    let rows = data;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        const sortOrder = isAsc ? 'desc' : 'asc';
        setFundTableByOrder(sortOrder);
        setFundTableByOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setFundPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setFundPage(0);
        setFundRowsPerPage(parseInt(event.target.value, 10));
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    const renderRows = (row) => {
        Object.keys(row).forEach(function (key, index) {
            return (
                <div>
                    hello
                    <TableCell align="left">{row[key]}</TableCell>
                </div>
            );
        })
    };


    const {history, isLGView, isXLView, isMobileView, isXSView, tableHeader} = props;

    return (
        <div className="w-100 pagination-container" style={{paddingLeft:isXSView ? '' :  24, paddingRight: isXSView ? '' : 24}}>
            <Box sx={{width: '100%'}}
                 style={{ padding:'0px !important'}}>
                <Paper sx={{width: '100%', mb: 0, boxShadow: "none"}}>
                    <TableContainer style={{maxHeight: 400}}>
                        <Table className=""
                            sx={{minWidth: 750}}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                            stickyHeader>
                            <EnhancedTableHead
                                headingData={headingData}
                                order={order}
                                tableHeader={tableHeader}
                                orderBy={orderBy}
                                isXLView={isXLView}
                                isMobileView={props.isMobileView}
                                isXSView={props.isXSView}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.fund_house_id + row.fund_id}
                                            selected={isItemSelected}
                                            sx={{cursor: 'pointer', border:'none', backgroundColor: (index % 2) === 0 ? '#F8F8FC': 'white'}}
                                            onClick={() => history.push('/funds/' + row.fund_house_id + '/' + row.fund_id)}>
                                            <TableCell  align="left" sx={{ border:'none', width: isXSView ? '150px' : '', fontSize: '12px !important', color: '#0073ff'}} style={{position: 'sticky', left:0, backgroundColor:(index % 2) === 0 ? '#F8F8FC': 'white'}}>
                                                <a href={'/funds/' + row.fund_house_id + '/' + row.fund_id} target="_self" rel="noopener noreferrer" onClick={()=>{}}>
                                                    {getTrimmedValue(row?.fund_name, 70)}
                                                </a>
                                            </TableCell>
                                            <TableCell  sx={{ border:'none', fontSize: '12px !important'}}
                                                align="right">{numberWithCommas(filterAumValue(row.total_market_value))}</TableCell>
                                            <TableCell  sx={{ border:'none', fontSize: '12px !important'}}
                                                align="right">{numberWithCommas((row.market_value_change_mom)?.toFixed(2))}</TableCell>
                                            <TableCell  sx={{ border:'none', fontSize: '12px !important'}}
                                                align="right">{row.percentage_market_value_change?.toFixed(2) + "%"}</TableCell>
                                            <TableCell  sx={{ border:'none', fontSize: '12px !important'}}
                                                align="right">{numberWithCommas(filterNetBuyingSellingValue(row.net_buying_selling))}</TableCell>
                                            <TableCell  sx={{ border:'none', fontSize: '12px !important'}}
                                                align="right">{row.net_buying_selling_percentage?.toFixed(2) + "%"}</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows,
                                        }}>
                                        <TableCell colSpan={6}/>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        </div>
    );
};

export default FundsTable;