import React, {useEffect, useState} from 'react';
import withRouter from "react-router-dom/es/withRouter";
import {searchAllData} from "../core/actions/appActions";
import {connect} from "react-redux";
import {isEmpty} from "../utils/validations";
import SearchUiViewView from "./SearchUiView";
import {debounce} from "../utils/helper";
import SearchModalView from "./SearchModalView";

function SearchModal(props) {

    const [searchModalState, setSearchModalState] = useState({
        isModalOpen: true
    })



    const onOpenModal = () => {
        setSearchModalState(() => ({
            isModalOpen: true
        }))
    }
    const onCloseModal = () => {
        // setSearchModalState(() => ({
        //     isModalOpen: false
        // }))
        props.history.goBack();
    }


    const handleClick = (item) => {
        setSearchModalState(() => ({
            isModalOpen: false
        }))
        setTimeout(()=>{
            switch (item.type) {
                case 'Fund House':
                    props.history.replace('/fund-houses/'+item?.fund_house_id);
                    break;

                case 'Fund':
                    props.history.replace('/funds/' + item.fund_house_id + '/' + item.fund_id);
                    break;

                case 'Stock':
                    props.history.replace('/stocks/' + item.isin);
                    break;

                case 'Industry':
                    props.history.replace('/sectors/' + item.name);
                    break;

            }

        },200)
    };
    return (
        <SearchModalView
            {...props}
            {...searchModalState}
            onOpenModal={onOpenModal}
            onCloseModal={onCloseModal}
            handleClick={handleClick}

        />
    );
}

const mapStateToProps = (state) => ({
    userAuthStatus: state.userState.userAuthStatus,
    profile: state.userState.profile,
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMDView: state.appState.deviceInfo.isMDView,
    isDrawerOpen: state.appState.isDrawerOpen,
    currentPathname: state.appState.currentPathname,
    prevLocation: state.appState.prevLocation
});


export default connect(mapStateToProps, {})(withRouter(SearchModal))