import React from 'react';
import {Link} from "react-router-dom";
import appTheme from "../assets/appTheme";
import DLButton from "dlcomponent/components/DLButton/DLButton";
import {isEmpty} from "../utils/validations";
import FundHouse from "./FundHouse/FundHouse";
import Stocks from "./StocksSummary/Stocks";
import Funds from "./Fund/Funds";
import Industry from "./Industry/Industry";
import DLText from "dlcomponent/components/DLText/DLText";
import {getDataArray, getRandomId, getTrimmedValue, numberWithCommas} from "../utils/helper";
import LineBarChartMixed from "./homePageChart/LineBarChartMixed";
import EChartTreemap from "../component-utils/EChart/EChartTreemap";
import EChartDougnute from "../component-utils/EChart/EChartDougnute";
import {getColor} from "../utils/stocksHelper";
import EChartPieChart from "../component-utils/EChart/EChartPieChart";
import fundHouses from "../assets/img/fundHousesNotSelected.png";
import explore from "../assets/img/explore.png";
import funds from "../assets/img/fundsNotSelected.png";
import stocks from "../assets/img/stocksNotSelected.png";
import industries from "../assets/img/industriesNotSelected.png";
import moneyFlow from "../assets/img/money flow.png";

// TODO use lazy load for each component which is firing the api
function HomepageView(props) {

    const {
        refresh, isMDView,
        isLGView, isXLView, isMobileView, category, businessHouse, industry, isSMView, isXSView, handleChartClick
    } = props;
    const {
        activitiesStateLoaded, stocksCount, marketValue, fundHouseCount,
        fundcount, stockCount, aum, xAxis, yAxisBar, yAxisLine
    } = props;

    const getFormattedData = (data, type) => {
        if (!isEmpty(data)) {
            if (data !== 0 && data !== '0' && type === 'revenue') {
                return "INR " + data;
            } else if (data !== 0 && data !== '0') {
                return data;
            }
        }
        return '';
    };

    const getFontColor = (data) => {
        if (data > 0) {
            return (
                <span style={{color: appTheme.appColor.success}}>({data}%)</span>
            )
        }
        return (
            <span style={{color: appTheme.appColor.danger}}>({data}%)</span>
        )
    };

    const renderMobileUI = () => {
        return (
            <div>
                <div className='px-2 mb-xl-0 mb-3'>
                    <FundHouse/>
                </div>
                <Funds/>
                <Stocks/>
                <Industry/>
            </div>
        );
    };


    const renderDesktopView = () => {
        return (
            <div>
                <div className='px-2 mb-xl-0 mb-3'>
                    <FundHouse/>
                </div>
                <Funds/>
                <Stocks/>
                <Industry/>
            </div>
        );
    };

    const renderUI = () => {
        if (isMDView || isLGView || isXLView) {
            return renderDesktopView();
        } else {
            return renderMobileUI();
        }
    };

    const getPieData = (data) => {
        let listingData = data.slice(0, 9);
        let isListingIncludeOther = listingData.find((item) => item.name === 'Others');
        if (isEmpty(isListingIncludeOther)) {
            let others = data.find(item => item?.name === 'Others');
            listingData.push(others);
        }
        return listingData
    };

    const getLegends = (data) => {
        return data.map((item, index) => {
            return (
                <div className=' d-flex align-items-center' key={getRandomId()}>
                    <div style={{paddingTop: 3}}>
                        <div style={{
                            width: '8px',
                            height: '8px',
                            borderRadius: 7,
                            backgroundColor: getColor(index), // You can set the background color as needed
                        }}/>
                    </div>
                    <div className='ps-1' style={{minWidth: 'max-content'}}>
                        <DLText text={item.name}
                                marginBottom={"none"}
                                id={'page-not-found-oops'}
                                type={"normal"}
                                fontSize={"xxs"}/>
                    </div>
                </div>
            )
        })
    };

    const getLegendsForPieChart = (data, sliceMin, sliceMax) => {
        let listingData = getPieData(data);

        return listingData.slice(sliceMin, sliceMax).map((item, index) => {
            return (
                <div className='d-flex align-items-center' style={{minWidth: 'max-content'}} key={getRandomId()}>
                    <div style={{paddingTop: 3}}>
                        <div style={{
                            width: '8px',
                            height: '8px',
                            borderRadius: 7,
                            backgroundColor: getColor(sliceMin + index), // You can set the background color as needed
                        }}/>
                    </div>
                    <div className='ps-1'>
                        <DLText text={getTrimmedValue(item?.name, 12)}
                                marginBottom={"none"}
                                id={'page-not-found-oops'}
                                type={"normal"}
                                fontSize={"xxs"}/>
                    </div>
                </div>
            )
        })
    };

    const renderPieChartUI = () => {
        if (isSMView || isXSView) {
            return (
                <div className='d-flex flex-column align-items-center mb-3'>
                    <EChartPieChart {...props}
                                    data={getPieData(getDataArray(props.businessHouse, 'business_house', 'market_value'))}/>
                    <div className='d-flex flex-wrap text-wrap gap-2 justify-content-center'>
                        {getLegendsForPieChart(getDataArray(props.businessHouse, 'business_house', 'market_value'), 0, 10)}
                    </div>
                </div>
            )
        }

        return (
            <div className='d-flex flex-column align-items-center'>
                <EChartPieChart {...props}
                                data={getPieData(getDataArray(props.businessHouse, 'business_house', 'market_value'))}/>
                <div className='d-flex flex-wrap text-wrap gap-2 justify-content-center'>
                    {getLegendsForPieChart(getDataArray(props.businessHouse, 'business_house', 'market_value'), 0, 10)}
                </div>
            </div>
        )
    }

    const renderOverViewMobileView = () => {
        return (
            <div className='d-flex flex-column justify-content-between'
                 id='home-summary-mobile'
                 style={{flex: 5, alignItems: isXSView && 'center'}}>

                <div className='panel pt-sm-3 pb-3 w-100 mb-sm-3 px-3'>
                    <div className='flex-shrink-1 w-100'>

                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={'Overview'}
                            fontWeight={'semi-bold'}
                            fontSize={'lg'}
                            marginBottom={'md'}
                        />
                        <div className='d-flex flex-column w-100 '>
                            <div className='d-flex flex-column flex-xl-row' style={{width: '100%'}}>

                                {isXSView ?
                                    renderMobileBlockUi()
                                    :
                                    <div className='d-flex flex-row mb-3'
                                         style={{width: '100%'}}>
                                        <div
                                            className={'px-2 ps-0 py-1 border-end'}
                                            style={{
                                                flex:1
                                            }}>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={'AUM'}
                                                fontSize={'sm'}/>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={numberWithCommas(aum)}
                                                fontWeight={'bold'}
                                                fontSize={'md'}/>
                                        </div>
                                        <div
                                            className={' px-2 py-1 border-end'}
                                            style={{
                                                flex: 1
                                            }}>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={'Fund Houses'}
                                                fontSize={'sm'}/>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={numberWithCommas(fundHouseCount)}
                                                fontWeight={'bold'}
                                                fontSize={'md'}/>
                                        </div>
                                        <div
                                            className={'px-2 py-1 border-end'}
                                            style={{
                                                flex: 1
                                            }}>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={'Funds'}
                                                fontSize={'sm'}/>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={numberWithCommas(fundcount)}
                                                fontWeight={'bold'}
                                                fontSize={'md'}/>
                                        </div>
                                        <div className={'px-2 py-1'}
                                             style={{
                                                 flex: 1
                                             }}>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={'Stocks'}
                                                fontSize={'sm'}/>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={numberWithCommas(stockCount)}
                                                fontWeight={'bold'}
                                                fontSize={'md'}/>
                                        </div>
                                    </div>
                                }
                                <div className='ps-xl-3' style={{
                                    width: (isLGView || isMobileView) ? '100%' : '95%',
                                    paddingTop: (isLGView || isMobileView) ? '10px' : '0px'
                                }}>
                                    <LineBarChartMixed {...props} id={"home-netbuyingselling-mobile-barchart"} isFromFund={true} hideCSS={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-100">
                    {isXSView ? <div style={{height: '10px', backgroundColor: '#C5C5C5'}}/> : <></>}
                </div>
                <div className='panel w-100 mb-sm-3 p-3'>
                    {getExploreLinks()}
                </div>
                <div className="w-100">
                    {isXSView ? <div style={{height: '10px', backgroundColor: '#C5C5C5'}}/> : <></>}
                </div>
            </div>
        );
    };

    const renderOverViewDesktopView = () => {
        return (
            <div className=' d-flex flex-row w-100'
                 id='home-summary-desktop'>
                <div className='panel p-3 mb-3' style={{width: '70%'}}>
                    <div className='flex-shrink-1 w-100'>

                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={'Overview'}
                            fontWeight={'semi-bold'}
                            fontSize={'lg'}
                            marginBottom={'md'}
                        />
                        <div className='d-flex flex-column w-100 '>
                            <div className='d-flex flex-column' style={{width: '100%'}}>
                                {isXSView ?
                                    renderMobileBlockUi()
                                    :
                                    <div className='d-flex flex-row mb-3'
                                         style={{width: '100%'}}>
                                        <div
                                            className={'px-2 ps-0 py-1 border-end'}
                                            style={{
                                                flex:1
                                            }}>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={'AUM'}
                                                fontSize={'sm'}/>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={numberWithCommas(aum)}
                                                fontWeight={'bold'}
                                                fontSize={'md'}/>
                                        </div>
                                        <div
                                            className={' px-2 py-1 border-end'}
                                            style={{
                                                flex: 1
                                            }}>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={'Fund Houses'}
                                                fontSize={'sm'}/>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={numberWithCommas(fundHouseCount)}
                                                fontWeight={'bold'}
                                                fontSize={'md'}/>
                                        </div>
                                        <div
                                            className={'px-2 py-1 border-end'}
                                            style={{
                                                flex: 1
                                            }}>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={'Funds'}
                                                fontSize={'sm'}/>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={numberWithCommas(fundcount)}
                                                fontWeight={'bold'}
                                                fontSize={'md'}/>
                                        </div>
                                        <div className={'px-2 py-1'}
                                             style={{
                                                 flex: 1
                                             }}>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={'Stocks'}
                                                fontSize={'sm'}/>
                                            <DLText
                                                id={'revenue-value'}
                                                type={'normal'}
                                                text={numberWithCommas(stockCount)}
                                                fontWeight={'bold'}
                                                fontSize={'md'}/>
                                        </div>
                                    </div>
                                }

                                <div className='ps-xl-3' style={{
                                    width: (isLGView || isMobileView) ? '100%' : '95%',
                                    paddingTop: (isLGView || isMobileView) ? '10px' : '0px'
                                }}>
                                    <LineBarChartMixed {...props} id={"home-netbuyingselling-desktop-barchart"} isFromFund={true} hideCSS={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='panel ms-3 mb-3 p-3' style={{width: '30%'}}>
                    {getExploreLinks()}
                </div>
            </div>
        );
    };

    const renderOverviewUi =()=>{
        if(isMobileView){
           return renderOverViewMobileView()
        }else{
            return renderOverViewDesktopView()
        }
    }

    const renderMobileTreemapView = () => {
        return (
            <div className='d-flex flex-column justify-content-between'
                 id='home-mobile-tree-map'
                 style={{flex: 5, alignItems: isXSView && 'center'}}>

                <div className='panel px-3 pt-sm-3 pb-3 w-100 mb-sm-3 p-3'>
                    <DLText id={''}
                            text={'Sectors'}
                            fontSize={'lg'}
                            fontWeight={'semi-bold'}
                            marginBottom={'md'}/>
                    <EChartTreemap {...props}
                                   handleChartClick={handleChartClick}
                                   data={getDataArray(props.industry, 'industry', 'market_value')}/>
                </div>
                <div className="w-100">
                    {isXSView ? <div style={{height: '10px', backgroundColor: '#C5C5C5'}}/> : <></>}
                </div>
                <div className='d-flex flex-md-row flex-column w-100'>
                    <div className={(isXSView) ? 'p-3 w-100' : 'panel p-3 w-100 me-3 mb-3 mb-md-0'}>
                        <DLText id={''}
                                text={'Market Cap'}
                                fontSize={'lg'}
                                fontWeight={'semi-bold'}
                                marginBottom={'sm'}/>
                        <div className='d-flex  flex-column align-items-center'>

                            <div className='d-flex justify-content-center'>
                                <EChartDougnute {...props}
                                                data={getDataArray(props.category, 'category', 'market_value')}/>
                            </div>

                            <div className='d-flex justify-content-center gap-2'>
                                {getLegends(getDataArray(props.category, 'category', 'market_value'))}
                            </div>
                        </div>
                    </div>
                    <div className={isXSView  ? "w-100" : ""}>
                        {isXSView ? <div style={{height: '10px', backgroundColor: appTheme.appColor.grey}}/> : <></>}
                    </div>
                    <div className={(isXSView) ? 'p-3 w-100' : 'panel p-3 w-100'}
                         style={{paddingLeft: (isXSView) ? 0 : 26}}>
                        <DLText id={''}
                                text={'Business Houses'}
                                fontSize={'lg'}
                                fontWeight={'semi-bold'}
                                marginBottom={'sm'}/>

                        {renderPieChartUI()}
                    </div>
                </div>
            </div>
        );
    };

    const renderDesktopTreemapView = () => {
        return (
            <div className=' d-flex flex-row w-100'
                 id='home-desktop-tree-map'>

                <div className='panel p-3' style={{width: '70%'}}>
                    <DLText id={''}
                            text={'Sectors'}
                            fontSize={'lg'}
                            fontWeight={'semi-bold'}
                            marginBottom={'sm'}/>
                    <EChartTreemap  {...props}
                                    handleChartClick={handleChartClick}
                                    data={getDataArray(props.industry, 'industry', 'market_value')}/>
                </div>
                <div className='d-flex flex-column justify-content-between ms-3 ' style={{width: '30%'}}>
                    <div className='panel p-3 mb-2'>
                        <DLText id={''}
                                text={'Market Cap'}
                                fontSize={'md'}
                                fontWeight={'semi-bold'}
                                marginBottom={'sm'}/>

                        <div className='d-flex flex-column align-items-center'>
                            <EChartDougnute {...props} data={getDataArray(props.category, 'category', 'market_value')}/>


                            <div className='d-flex justify-content-center flex-wrap gap-2'>
                                {getLegends(getDataArray(props.category, 'category', 'market_value'))}
                            </div>
                        </div>
                    </div>
                    <div className='panel p-3 '>
                        <DLText id={''}
                                text={'Business Houses'}
                                fontSize={'lg'}
                                fontWeight={'semi-bold'}
                                marginBottom={'sm'}/>
                        {renderPieChartUI()}
                    </div>
                </div>
            </div>
        );
    };

    const renderMobileBlockUi = () => {
        const {
            activitiesStateLoaded, isXSView, isMobileView, stocksCount, marketValue, fundHouseCount,
            fundcount, stockCount, aum, xAxis, yAxisBar, yAxisLine
        } = props;
        return (
            <>
                <div className='d-flex flex-row flex-xl-column mb-2'
                     style={{width: (isLGView || isMobileView) ? '100%' : '15%'}}>
                    <div
                        className={(isLGView || isMobileView) ? 'pe-4 py-1 border-end' : 'border-bottom'}
                        style={{
                            flex: (isLGView || isMobileView) ? 1 : '',
                            paddingBottom: !(isLGView || isMobileView) && 30,
                            paddingTop: !(isLGView || isMobileView) && 30
                        }}>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={'AUM'}
                            fontSize={'sm'}/>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={numberWithCommas(aum)}
                            fontWeight={'bold'}
                            fontSize={'md'}/>
                    </div>
                    <div
                        className={(isLGView || isMobileView) ? ' px-4 py-1' : 'border-bottom'}
                        style={{
                            flex: (isLGView || isMobileView) ? 1 : '',
                            paddingBottom: !(isLGView || isMobileView) && 30,
                            paddingTop: !(isLGView || isMobileView) && 30
                        }}>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={'Fund Houses'}
                            fontSize={'sm'}/>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={numberWithCommas(fundHouseCount)}
                            fontWeight={'bold'}
                            fontSize={'md'}/>
                    </div>
                </div>
                <div className='d-flex flex-row flex-xl-column mb-3'
                     style={{width: (isLGView || isMobileView) ? '100%' : '15%'}}>
                    <div
                        className={(isLGView || isMobileView) ? 'pe-4 py-1 border-end' : 'border-bottom'}
                        style={{
                            flex: (isLGView || isMobileView) ? 1 : '',
                            paddingBottom: !(isLGView || isMobileView) && 30,
                            paddingTop: !(isLGView || isMobileView) && 30
                        }}>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={'Funds'}
                            fontSize={'sm'}/>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={numberWithCommas(fundcount)}
                            fontWeight={'bold'}
                            fontSize={'md'}/>
                    </div>
                    <div className={(isLGView || isMobileView) ? 'px-4 py-1' : ''}
                         style={{
                             flex: (isLGView || isMobileView) ? 1 : '',
                             paddingBottom: !(isLGView || isMobileView) && 30,
                             paddingTop: !(isLGView || isMobileView) && 30
                         }}>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={'Stocks'}
                            fontSize={'sm'}/>
                        <DLText
                            id={'revenue-value'}
                            type={'normal'}
                            text={numberWithCommas(stockCount)}
                            fontWeight={'bold'}
                            fontSize={'md'}/>
                    </div>
                </div>
            </>
        );
    }

    const getExploreLinks = () => {
        return (
            <div>
                <div style={{
                    marginBottom: isMobileView ? 40 : 50
                }}>
                    <DLText
                        id={'revenue-value'}
                        type={'normal'}
                        text={'Links'}
                        fontWeight={'semi-bold'}
                        fontSize={'lg'}
                        marginBottom={'md'}
                    />
                </div>
                <div className="">
                    <div className="px-0 border-bottom">
                        <div>
                            {/*<img src={FundHouseImg}/>*/}

                            <div className="my-4">
                                <img src={moneyFlow}
                                     height={31.83}
                                     width={27.99}
                                     style={{paddingBottom: 7}}
                                />
                                <DLText
                                    id={'revenue-value'}
                                    type={'normal'}
                                    isClickable={true}
                                    onClick={()=>{props.history.push("/money-flow-explorer")}}
                                    text={'Explore Capital Flows >'}
                                    fontWeight={'semi-bold'}
                                    fontSize={'md'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="px-0 border-bottom">

                            <div>
                                {/*<img src={FundHouseImg}/>*/}
                                <div className="my-4">
                                    <img src={fundHouses}
                                         height={31.83}
                                         width={26}
                                         style={{paddingBottom: 7}}
                                    />
                                <DLText
                                    id={'revenue-value'}
                                    type={'normal'}
                                    isClickable={true}
                                    onClick={()=>{props.history.push("/fund-houses")}}
                                    text={'Fund Houses >'}
                                    fontWeight={'semi-bold'}
                                    fontSize={'md'}
                                />
                                </div>
                            </div>
                    </div>
                    <div className="px-0 border-bottom">

                            <div>
                                {/*<img src={FundsImg}/>*/}
                                <div className="my-4">
                                    <img src={funds}
                                         height={31}
                                         width={27}
                                         style={{paddingBottom: 7}}
                                    />
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        text={'Funds >'}
                                        isClickable={true}
                                        onClick={()=>{props.history.push("/funds")}}
                                        fontWeight={'semi-bold'}
                                        fontSize={'md'}
                                    />
                                </div>
                            </div>
                    </div>
                    <div className="px-0 border-bottom">
                            <div>
                                {/*<img src={StocksImg}/>*/}
                                <div className="my-4">
                                    <img src={stocks}
                                         height={27}
                                         width={28}
                                         style={{paddingBottom: 7}}
                                    />
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        isClickable={true}
                                        text={'Stocks >'}
                                        onClick={()=>{props.history.push("/stocks")}}
                                        fontWeight={'semi-bold'}
                                        fontSize={'md'}
                                    />
                                </div>
                            </div>
                    </div>
                    <div className="px-0">
                            <div>
                                {/*<img src={IndustriesImg}/>*/}
                                <div className="my-4">
                                    <img src={industries}
                                         height={28}
                                         width={25}
                                         style={{paddingBottom: 7}}
                                    />
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        isClickable={true}
                                        text={'Sectors >'}
                                        fontWeight={'semi-bold'}
                                        onClick={()=>{props.history.push("/sectors")}}
                                        fontSize={'md'}
                                    />
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        );
    }

    const renderSummaryUI = () => {

        return (
            <div className='d-flex flex-column w-100 mt-2'
                 style={{ paddingBottom: isMobileView ? 40 : 50}}>

                {renderOverviewUi()}
                {(isLGView || isMobileView) ? renderMobileTreemapView() : renderDesktopTreemapView()}

            </div>
        )
    };

    return (<>
        <div className={isXSView? "" : "page-container"} style={{height: 'calc(100vh-74px)', overflow: 'hidden'}}>
            {renderSummaryUI()}

        </div>
    </>);
}

HomepageView.propTypes = {};

export default HomepageView;
