import React from 'react';
import DLText from "dlcomponent/components/DLText/DLText";
import DLButton from "dlcomponent/components/DLButton/DLButton";
import {isEmpty} from "../../utils/validations";
import DLLoader from "../../core/components/DLLoader/DLLoader";
import {advanceSearchConst} from "../../AdvanceSearch/AdvanceSearchConst";
import {getTrimmedValue, numberWithCommas} from "../../utils/helper";
import DLInputTextField from "dlcomponent/components/DLInputTextField/DLInputTextField";
import DLCheckbox from "dlcomponent/components/DLCheckbox/DLCheckbox";
import appTheme from "../../assets/appTheme";
import {setStocksList} from "../../AdvanceSearch/AdvanceSeachAction";
import {getShowingResultCountText} from "../../utils/stocksHelper";

function AdvanceStocksSearchModalView(props) {
    const { setFilter, stockKeyword, previousStockListing, isSMView, isMDView, searchResultNotAvailable, isStockModalOpen, clearSelectedFunds, checkSelectedValues, sortedData, checkboxChange, onChangeValue, handleCheckboxChange, modalUIState, stockListingData, isXLView, isMobileView, isXSView, onChangeKeywordFilter,isSelectedPanelExpanded,handleSelectedPanelExpandClick} = props;
    let data = isEmpty(sortedData) ? stockListingData : sortedData;

    const renderStockListingUI = () => {
        if (searchResultNotAvailable){
            return(
                <div className='d-flex align-items-center px-3' style={{paddingTop: 35}}>
                    <DLText
                        id={'revenue'}
                        type={'normal'}
                        text={'No data found.'}
                        fontColor={'lightGray'}
                        fontSize={'xs'}/>
                </div>
            )
        }
        return data.slice(0, 30).map((item, index) => {
            return (
                <div className='col-xl-4 col-6 col-12 py-1 customCheckBoxStyle'>
                    <DLCheckbox id={'scheduleUpload-weeklyUi-dayCheck-' + index}
                                label={getTrimmedValue(item?.company_name, isXLView ? 25 : 45)}
                                isChecked={checkboxChange(item)}
                                disableHelperMessageHeight={true}
                                labelColor={appTheme.appColor.black}
                                onChangeValue={() => handleCheckboxChange(item)}
                                labelPlacement={'end'}
                                marginBottom={'none'}
                    />
                </div>
            )

        })
    };

    const renderSelectedValues = (selectedValues) =>{
        const {} = props;

        if (isEmpty(selectedValues)){
            return (
                <div className='d-flex align-items-center px-3' style={{paddingTop: 6}}>
                    <DLText
                        id={'revenue'}
                        type={'normal'}
                        fontColor={'lightGray'}
                        text={'Nothing is selected yet.'}
                        fontSize={'xs'}/>
                </div>
            )
        }

        return selectedValues.map((item)=>{
            return (

                <div className='px-3 py-1 d-flex justify-content-between align-items-center'>
                    <div className=''>
                        <DLText
                            id={'revenue'}
                            type={'normal'}
                            text={getTrimmedValue(item?.company_name, 25)}
                            fontSize={'xs'}/>
                    </div>
                    <i className="fa fa-times cursor ms-3"
                       onClick={() => {
                           handleCheckboxChange(item)
                       }}/>
                </div>
            )
        })
    };

    const renderValuesSelectedUI = () => {
        let selectedValues = checkSelectedValues();

        return (
            <div>
                <div className='px-3 d-flex justify-content-between'>
                    <DLText
                        id={'revenue'}
                        type={'normal'}
                        text={'Selected'}
                        fontColor={'lightGray'}
                        fontWeight={'semi-bold'}
                        fontSize={'xs'}/>
                    {!isEmpty(selectedValues) &&

                        <DLText
                            id={'revenue'}
                            type={'normal'}
                            isClickable={true}
                            text={'Clear'}
                            customTextStyle={{
                                color: appTheme.appColor.lightGray
                            }}
                            onClick={()=> clearSelectedFunds()}
                            fontSize={'xs'}/>
                    }

                </div>
                <div style={{
                    maxHeight: isMobileView ? 'calc(100vh - 338px)' : 'calc(100vh - 240px)',
                    minHeight: isMobileView ? 'calc(100vh - 338px)' :  'calc(100vh - 240px)',
                    overflowY: 'auto'
                }}>
                    {renderSelectedValues(selectedValues)}
                </div>
            </div>
        )
    };

    const renderAngleicon = (selectedValues) => {

        if (isEmpty(selectedValues)) {
            return <></>
        }

        if (isSelectedPanelExpanded) {
            return <i className="fa-solid fa-angle-down"/>
        } else {
            return <i className="fa-solid fa-angle-up"/>
        }
    };

    const renderSelectedValuesMobileView = () => {
        let selectedValues = checkSelectedValues();
        const visibleItems = isSelectedPanelExpanded ? selectedValues : selectedValues.slice(0, 2);
        return(
            <div className={'panel p-3 w-100'} style={{border: "1px solid #D2D2D2",borderRadius: "20px 20px 0px 0px",}}>
                <div className={'d-flex justify-content-between'}  style={{ borderBottom: "1px solid #C5C5C5"}}>
                    <div>
                        <DLText id={'revenue-value'}
                                type={'normal'}
                                isInline={true}
                                text={'Selected Values'}
                                marginBottom={'20px'}
                                fontWeight={'semi-bold'}
                                fontSize={'md'}/>
                    </div>
                    <div onClick={() => handleSelectedPanelExpandClick()}>
                        {renderAngleicon(selectedValues)}
                    </div>
                </div>
                <div>
                    <div>
                        <div className='d-flex justify-content-between'>
                            <DLText
                                id={'revenue'}
                                type={'normal'}
                                text={'Selected'}
                                fontColor={'lightGray'}
                                fontWeight={'semi-bold'}
                                fontSize={'xs'}/>
                            {!isEmpty(selectedValues) &&

                            <DLText
                                id={'revenue'}
                                type={'normal'}
                                isClickable={true}
                                text={'Clear'}
                                customTextStyle={{
                                    color: appTheme.appColor.lightGray
                                }}
                                onClick={()=> clearSelectedFunds()}
                                fontSize={'xs'}/>
                            }

                        </div>
                        <div style={{overflowY:'auto', maxHeight:'200px',minHeight:isSelectedPanelExpanded?'200px':''}}>
                          <div className={'me-3'}>
                            {isEmpty(selectedValues) ? (
                                <div className='d-flex align-items-center ' style={{ paddingTop: 6 }}>
                                    <DLText
                                        id={'revenue'}
                                        type={'normal'}
                                        text={'Nothing is selected yet.'}
                                        fontColor={'lightGray'}
                                        fontSize={'xs'}
                                    />
                                </div>
                            ) : (
                                visibleItems.map((item) => (
                                    <div className='py-1 d-flex justify-content-between align-items-center'>
                                        <div className=''>
                                            <DLText
                                                id={'revenue'}
                                                type={'normal'}
                                                text={getTrimmedValue(item?.company_name, 25)}
                                                fontSize={'xs'}/>
                                        </div>
                                        <i className="fa fa-times cursor ms-3"
                                           onClick={() => {
                                               handleCheckboxChange(item)
                                           }}/>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                    </div>
                </div>
            </div>

        )
    };

    const renderStocksModalMobileUI = () => {
        let selectedValues = checkSelectedValues();

        if (isEmpty(modalUIState)) {
            return (
                <div style={{minHeight: 300}}>
                    <DLLoader type={"inline"} isVisible={true}/>
                </div>
            )
        }

        if (modalUIState === advanceSearchConst.CONTENT_UNAVAILABLE) {
            return (
                <div>
                    <DLText
                        id={'revenue'}
                        type={'normal'}
                        fontColor={'lightGray'}
                        text={'No data found.'}
                        fontSize={'sm'}/>
                </div>
            )
        }

        const handleHeightofStocklist  = () => {
            if(isSelectedPanelExpanded){
                return 'calc(100vh - 480px)'
            }else{
                if(isEmpty(selectedValues)){
                    return 'calc(100vh - 310px)'
                }
                return 'calc(100vh - 335px)'
            }
        };

        return (
            <div>
                <div className='w-100'>
                    <div className='px-3'>
                        <DLInputTextField
                            id={'businessList-keywordSearch'}
                            label={''}
                            rows={1}
                            fullWidth
                            styleType={"search"}
                            placeholder={'Search...'}
                            value={stockKeyword}
                            onChangeValue={(e) => onChangeKeywordFilter(e)}
                            isClearable={true}
                            inputHeight={'sm'}
                            autoFocus={true}
                            marginBottom={"none"}
                            InputProps={{
                                endAdornment: (
                                    stockKeyword ?
                                        <i className="fa fa-remove m-2 cursor" onClick={() => {
                                            onChangeKeywordFilter("")
                                        }} style={{color: appTheme.appColor.black}}/>
                                        :
                                        <i className={'fas fa-search pr-1'} style={{color: appTheme.appColor.black}}/>
                                )
                            }}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                 //   document?.activeElement?.blur()
                                }
                            }}
                            disableHelperMessageHeight={true}
                        />
                    </div>
                    <div className=''>
                        <div className='px-3 d-flex justify-content-between' style={{paddingTop: 4}}>
                            {!searchResultNotAvailable &&
                                <DLText
                                    id={'revenue-value'}
                                    type={'normal'}
                                    fontColor={'lightGray'}
                                    text={getShowingResultCountText(data.slice(0, data.length))}
                                    fontSize={'xs'}/>
                            }
                            {!isEmpty(selectedValues) &&
                                <i className="fa fa-times cursor ms-3"
                                   onClick={() => {
                                       clearSelectedFunds()
                                   }}/>
                            }
                        </div>
                        <div className='row mx-0 d-block'  style={{
                            maxHeight: handleHeightofStocklist(),
                            minHeight: handleHeightofStocklist(),
                            overflowY: 'auto'
                        }}>
                            {renderStockListingUI()}
                        </div>
                        <div  className='d-flex w-100 bg-white'
                              style={{position: 'fixed', bottom:0, marginBottom: '54px'}}>
                            {renderSelectedValuesMobileView()}
                        </div>
                        <div  className='d-flex w-100 bg-white'
                              style={{position: 'fixed', bottom:0}}>
                            <div className='w-100 d-flex align-items-center'
                                 style={{ boxShadow: '1px 0px 4px 0px rgba(0, 0, 0, 0.20)', height: 54}}>
                                <div className='d-flex justify-content-center border-end' style={{flex:1}}>
                                    <DLText
                                        id={'revenue'}
                                        type={'normal'}
                                        text={'Close'}
                                        isClickable={true}
                                        customTextStyle={{
                                            color: appTheme.appColor.black
                                        }}
                                        onClick={() => {
                                            onChangeKeywordFilter("");
                                            setTimeout(() => {
                                                onChangeValue("isStockModalOpen", false);
                                                setStocksList(previousStockListing);
                                            }, 50)
                                        }}
                                        marginBottom={'none'}
                                        fontSize={'sm'}/>
                                </div>
                                <div className='d-flex justify-content-center' style={{flex:1}}>
                                    <DLText
                                        id={'revenue'}
                                        type={'normal'}
                                        text={'Apply'}
                                        isClickable={true}
                                        customTextStyle={{
                                            color: appTheme.appColor.black
                                        }}
                                        onClick={() => {
                                            onChangeKeywordFilter("");
                                            setTimeout(() => {
                                                if(selectedValues.length <= 30){
                                                    onChangeValue("isStockModalOpen", false);
                                                }
                                                setFilter('stocks', selectedValues, 'isin_code',true)
                                            }, 50)
                                        }}
                                        marginBottom={'none'}
                                        fontSize={'sm'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderStocksModalDesktopUI = () => {
        const {stockKeyword} = props;

        if (isEmpty(modalUIState)) {
            return (
                <div style={{minHeight: 300}}>
                    <DLLoader type={"inline"} isVisible={true}/>
                </div>
            )
        }

        if (modalUIState === advanceSearchConst.CONTENT_UNAVAILABLE) {
            return (
                <div>
                    <DLText
                        id={'revenue'}
                        type={'normal'}
                        text={'No data found.'}
                        fontSize={'sm'}/>
                </div>
            )
        }

        return (
            <div>
                <div className='d-flex'>
                    <div className=''
                         style={{
                             minWidth: 250,
                             maxWidth: 250,
                             maxHeight: isMobileView ? 'calc(100vh - 316px)' : 'calc(100vh - 220px)',
                             minHeight: isMobileView ? 'calc(100vh - 316px)' :  'calc(100vh - 220px)',
                             borderRight: '1px dashed ',
                             marginTop: 44,
                             marginBottom: 16,
                         }}>
                        {renderValuesSelectedUI()}
                    </div>
                    <div className='w-100'>
                        <div className='px-3'>
                            <DLInputTextField
                                id={'businessList-keywordSearch'}
                                label={''}
                                rows={1}
                                fullWidth
                                styleType={"search"}
                                placeholder={'Search...'}
                                value={stockKeyword}
                                onChangeValue={(e) => onChangeKeywordFilter(e)}
                                isClearable={true}
                                inputHeight={'sm'}
                                autoFocus={true}
                                marginBottom={"none"}
                                InputProps={{
                                    endAdornment: (
                                        stockKeyword ?
                                            <i className="fa fa-remove m-2 cursor" onClick={() => {
                                                onChangeKeywordFilter("")
                                            }} style={{color: appTheme.appColor.black}}/>
                                            :
                                            <i className={'fas fa-search pr-1'} style={{color: appTheme.appColor.black}}/>
                                    )
                                }}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                     //   document?.activeElement?.blur()
                                    }
                                }}
                                disableHelperMessageHeight={true}
                            />
                        </div>
                        <div className=''>
                            {!searchResultNotAvailable &&
                                <div className='px-3' style={{paddingTop: 4}}>
                                    <DLText
                                        id={'revenue-value'}
                                        type={'normal'}
                                        fontColor={'lightGray'}
                                        text={getShowingResultCountText(data.slice(0, data.length))}
                                        fontSize={'xs'}/>
                                </div>
                            }
                            <div className='row mx-0'  style={{
                                maxHeight: isMobileView ? 'calc(100vh - 340px)' :  'calc(100vh - 240px)',
                                overflowY: 'auto'
                            }}>
                                {renderStockListingUI()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    if (isStockModalOpen){
        let selectedValues = checkSelectedValues();

        return (
            <div className='advanceSearchContainer w-100' style={{
                position: (isXSView || isSMView || isMDView) ? 'fixed' :  'absolute',
                top:(isXSView || isSMView || isMDView) ? 0 :isMobileView ? 110 : 70,
                zIndex:9999
            }}>
                <div className='bg-white advanceSearchModal' style={{
                    width: (isXSView || isSMView || isMDView) ? '100%' : 'calc(100vw - 180px)',
                    borderRadius: '7px',
                    minHeight: (isXSView || isSMView || isMDView) ? '100vh' : isMobileView ? 'calc(100vh - 200px)' : 'calc(100vh - 100px)',
                    maxHeight: (isXSView || isSMView || isMDView) ? '100vh' : isMobileView ? 'calc(100vh - 200px)' :  'calc(100vh - 100px)',
                    boxShadow: '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)'
                }}>
                    <div className='d-flex justify-content-between p-3 align-items-center'>
                        <div className='d-flex align-items-center'>
                            <DLText
                                id={'revenue'}
                                type={'normal'}
                                text={'Stocks'}
                                marginBottom={'none'}
                                fontSize={'lg'}/>
                            <div className='ps-2'>
                                <DLText
                                    id={'revenue'}
                                    type={'normal'}
                                    marginBottom={'none'}
                                    text={"("+numberWithCommas(selectedValues?.length)+'/'+numberWithCommas(stockListingData.length)+")"}
                                    fontSize={'xxs'}/>
                            </div>
                        </div>

                        {(isXSView || isSMView || isMDView) ?
                            <></>
                            :
                            <div className=''>
                                <DLButton id={'dataImport-automaticImport-modalActions-save'}
                                          label={'Apply'}
                                          buttonSize={"sm"}
                                          fontSize={"sm"}
                                          styleType={'outlined'}
                                          sideMargin={"sm"}
                                          onClick={() => {
                                              onChangeKeywordFilter("");
                                              setTimeout(() => {
                                                  if(selectedValues.length <= 30){
                                                      onChangeValue("isStockModalOpen", false);
                                                  }
                                                  setFilter('stocks', selectedValues, 'isin_code',true)
                                              }, 50)
                                          }}/>
                                <i className="fa fa-times cursor ms-3"
                                   onClick={() => {
                                       onChangeKeywordFilter("");
                                       setTimeout(() => {
                                           onChangeValue("isStockModalOpen", false);
                                           setStocksList(previousStockListing);
                                       }, 50)
                                   }}/>
                            </div>
                        }
                    </div>
                    {(isXSView || isSMView || isMDView) ? renderStocksModalMobileUI() : renderStocksModalDesktopUI()}
                </div>
            </div>
        );
    }

}

AdvanceStocksSearchModalView.propTypes = {};

export default AdvanceStocksSearchModalView;
