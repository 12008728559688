// V2 functions are aligned with react query

import * as log from "loglevel";
import {isEmpty} from "./validations";
import {APP_CONST} from "../core/AppConstants";
import {showSystemErrorBlock, showToastMessage} from "../core/actions/appActions";
import store from '../store';
import {setNotAccessible, userLoggedOut} from "../user/userReducer";

const API_URL = process.env.REACT_APP_API_URL;

export function getAuthorizationToken() {
    let token = null;
    try {
        let fromStorage = '';
        if (localStorage.hasOwnProperty('jwtToken')) {
            token = localStorage.getItem('jwtToken');
            fromStorage = 'localStorage';
        } else if (sessionStorage.hasOwnProperty('jwtSessionToken')) {
            token = sessionStorage.getItem('jwtSessionToken');
            fromStorage = 'sessionStorage';
        } else {
            token = null;
        }
        if (token) {
            return {
                status: true,
                token: token,
                fromStorage: fromStorage
            };
        } else {
            return {
                status: false,
                err: 'no token available',
            };
        }
    } catch (err) {
        return {
            status: false,
            err: err,
        };
    }
}

export function setAuthorizationToken(token, toRemember) {
    try {

        if (toRemember) {
            localStorage.setItem('jwtToken', token);
            // if there is any session stored by mistake remove it
            if (sessionStorage.hasOwnProperty('jwtSessionToken')) {
                sessionStorage.removeItem('jwtSessionToken');
            }

        } else {
            sessionStorage.setItem('jwtSessionToken', token);
// if there is any local key stored by mistake remove it
            if (localStorage.hasOwnProperty('jwtToken')) {
                localStorage.removeItem('jwtToken');
            }
        }
        return {
            status: true,
        };
    } catch (err) {
        return {
            status: false,
            err: err,
        };
    }
}

export function removeAuthorizationToken() {
    try {
        if (localStorage.hasOwnProperty('jwtToken')) {
            localStorage.removeItem('jwtToken');
        }
        if (sessionStorage.hasOwnProperty('jwtSessionToken')) {
            sessionStorage.removeItem('jwtSessionToken');
        }
        // for safari push notification
        if (localStorage.hasOwnProperty('pushNotificationSubscribed')) {
            localStorage.removeItem('pushNotificationSubscribed');
        }

        // if user logout remove the date limit will reset
        if (localStorage.hasOwnProperty('push_notification_date_limit')) {
            localStorage.removeItem('push_notification_date_limit');
        }

        return {
            status: true,
        };
    } catch (err) {
        return {
            status: false,
            err: err,
        };
    }

}

export const httpGet = (url, accountId, handleErrorInComponent) => {
    let statusCode;
    let headers;
    if (isEmpty(accountId)) {
        headers = {
            'Accept': 'application/json',
            ...getAuthorization(),
        }
    } else {
        headers = {
            'Accept': 'application/json',
            'x-account-id': accountId,
            ...getAuthorization(),
        }
    }
    let api_url = (url.startsWith('http')) ? url : API_URL + url;
    // let api_url = API_URL + url;
    return fetch(api_url, {
        method: 'GET',
        headers: headers,
    })
        .then(response => {
            // capture the status code if the response to json conversion call breaks
            statusCode = response.status;

            if (response.status === 204) {
                return response;
            } else {
                return response.json();
            }

        })
        .then(data => {

            if (statusCode === 200 || statusCode === 201) {
                if (Array.isArray(data)) {
                    return {data: data, statusCode: statusCode, success: true}
                }
                if (typeof data === 'object') {
                    return {data: data, statusCode: statusCode, success: true}
                }
                return {data: data, statusCode: statusCode, success: true}
            }

            if (statusCode === 204) {
                return {data: null, statusCode: statusCode, success: true}
            }

            if (statusCode >= 400 && statusCode < 500) {
                if (statusCode === 401) {
                    store.dispatch(userLoggedOut())
                } else {
                    if (handleErrorInComponent) {

                    } else {
                        if (statusCode === 403) {
                            store.dispatch(setNotAccessible())
                        } else {
                            store.dispatch(showToastMessage('warning', data.__error))
                        }
                    }
                }
                return {...data, statusCode: statusCode, success: false}
            }

            if (statusCode >= 500) {
                store.dispatch(showSystemErrorBlock());
                return {data: null, statusCode: statusCode, success: false}
            }
        })
        .catch((error) => {
            logAPIError({statusCode: statusCode, error: error, api: url, apiMethod: 'GET'});
            store.dispatch(showSystemErrorBlock());
            return {statusCode: statusCode, error: error, success: false}
        });
};

export const httpPost = (url, data, accountId, handleErrorInComponent) => {
    let statusCode;
    let headers;
    if (isEmpty(accountId)) {
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...getAuthorization(),
        }
    } else {
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-account-id': accountId,
            ...getAuthorization(),
        }
    }
    let api_url = (url.startsWith('http')) ? url : API_URL + url;
    return fetch(api_url, {
        method: 'POST',
        headers: headers,
        body: isEmpty(data) ? '' : JSON.stringify(data),
    })
        .then(response => {
            // capture the status code if the response to json conversion call breaks
            statusCode = response.status;
            if (response.status === 204) {
                return response
            } else {
                return response.json()
            }
        })
        .then(data => {
            if (statusCode === 200 || statusCode === 201) {
                if (Array.isArray(data)) {
                    return {data: data, statusCode: statusCode, success: true}
                }
                if (typeof data === 'object') {
                    return {data: data, statusCode: statusCode, success: true}
                }
                return {data: data, statusCode: statusCode, success: true}
            }

            if (statusCode === 204) {
                return {data: null, statusCode: statusCode, success: true}
            }

            if (statusCode >= 400 && statusCode < 500) {
                if (statusCode === 401) {
                    store.dispatch(userLoggedOut())
                } else {
                    if (handleErrorInComponent) {

                    } else {
                        if (statusCode === 403) {
                            store.dispatch(setNotAccessible())
                        } else {
                            store.dispatch(showToastMessage('warning', data.__error.message))
                        }
                    }
                }
                return {...data, statusCode: statusCode, success: false}
            }

            if (statusCode >= 500) {
                store.dispatch(showSystemErrorBlock());
                return {data: null, statusCode: statusCode, success: false}
            }
        })
        .catch((error) => {
            logAPIError({statusCode: statusCode, error: error, api: url, payload: data, apiMethod: 'POST'});
            // controller = new AbortController();  // reset abort controller to continue further requests
            // setSignal();
            store.dispatch(showSystemErrorBlock());
            return {statusCode: statusCode, error: error, success: false}
        });
};

export const httpPut = (url, data, accountId) => {
    let statusCode;
    return fetch(API_URL + url, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-account-id': accountId,
            ...getAuthorization(),
        },
        body: JSON.stringify(data),
    })
        .then(response => {
            statusCode = response.status;
            // return response
            // return response.json()

            if (response.status === 204) {
                return response
            } else {
                return response.json()
            }
        })
        .then(data => {
            if (statusCode === 200 || statusCode === 201) {
                if (Array.isArray(data)) {
                    return {data: data, statusCode: statusCode, success: true}
                }
                if (typeof data === 'object') {
                    return {data: data, statusCode: statusCode, success: true}
                }
                return {data: data, statusCode: statusCode, success: true}
            }

            if (statusCode === 204) {
                return {data: null, statusCode: statusCode, success: true}
            }

            if (statusCode >= 400 && statusCode < 500) {
                if (statusCode === 401) {
                    store.dispatch(userLoggedOut())
                } else {
                    if (statusCode === 403) {
                        store.dispatch(setNotAccessible())
                    } else {
                        store.dispatch(showToastMessage('warning', data.__error.message))
                    }
                }
                return {...data, statusCode: statusCode, success: false}
            }

            if (statusCode >= 500) {
                store.dispatch(showSystemErrorBlock());
                return {data: null, statusCode: statusCode, success: false}
            }
        })
        .catch((error) => {
            logAPIError({statusCode: statusCode, error: error, api: url, payload: data, apiMethod: 'PUT'});
            store.dispatch(showSystemErrorBlock());
            return {statusCode: statusCode, error: error, success: false}
        });
};

export const httpDelete = async (url, data, accountId) => {

    let statusCode, headers;
    if (isEmpty(accountId)) {
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...getAuthorization(),
        }
    } else {
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-account-id': accountId,
            ...getAuthorization(),
        }
    }

    return fetch(API_URL + url, {
        method: 'DELETE',
        headers: headers,

    })
        .then(response => {
            // capture the status code if the response to json conversion call breaks
            statusCode = response.status;

            if (response.status === 204) {
                return response
            } else {
                return response.json()
            }
        })
        .then(data => {
            if (statusCode === 200 || statusCode === 201) {
                if (Array.isArray(data)) {
                    return {data: data, statusCode: statusCode, success: true}
                }
                if (typeof data === 'object') {
                    return {data: data, statusCode: statusCode, success: true}
                }
                return {data: data, statusCode: statusCode, success: true}
            }

            if (statusCode === 204) {
                return {data: null, statusCode: statusCode, success: true}
            }

            if (statusCode >= 400 && statusCode < 500) {
                if (statusCode === 401) {
                    store.dispatch(userLoggedOut())
                } else {
                    if (statusCode === 403) {
                        store.dispatch(setNotAccessible())
                    } else {
                        store.dispatch(showToastMessage('warning', data.__error))
                    }
                }
                return {...data, statusCode: statusCode, success: false}
            }

            if (statusCode >= 500) {
                store.dispatch(showSystemErrorBlock());
                return {data: null, statusCode: statusCode, success: false}
            }
        })
        .catch((error) => {
            logAPIError({statusCode: statusCode, error: error, api: url, payload: data, apiMethod: 'DELETE'});
            store.dispatch(showSystemErrorBlock());
            return {statusCode: statusCode, error: error, success: false}
        });

};

export const getAppEnvironmentVariables = () => {
    return process.env;
};

export const getAppPlatform = () => {
    return APP_CONST.PLATFORM_WEB;
};

export const getAuthorization = () => {
    if (getAuthorizationToken().token) {
        return {
            'Authorization': 'Bearer ' + getAuthorizationToken().token,
        }
    }
    return {}
};

export const logAPIError = (errorData) => {
    // add the api status codes for which error logging is not required
    switch (errorData?.statusCode) {
        case 409:
            break;
        default:
            log.error(errorData);
    }

};

export const numberWithCommas = (x) => {
    if (!x) {
        return "";
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const handleApiResponseV2 = (rawResponse) => {
    if (rawResponse.status >= 500) {
        // show global level error
        return rawResponse.json()
            .then(obj => {
                let errorObj = {
                    ...obj,
                    errorCode: rawResponse.status
                };
                showSystemErrorBlock(errorObj);
                throw new Error(`${rawResponse.status} level error`, {
                    cause: errorObj
                });
            });
    }

    if (rawResponse.status >= 400) {
        return rawResponse.json()
            .then(obj => {
                let errorObj = {
                    ...obj,
                    errorCode: rawResponse.status
                };
                store.dispatch(showToastMessage('danger', obj.__error));
                throw new Error(`${rawResponse.status} level error`, {
                    cause: errorObj
                });
            });
    }

    if (rawResponse.status >= 300) {
        return rawResponse.json();
    }

    if (rawResponse.status >= 200) {
        return rawResponse.json();
    }

    return rawResponse.json();
};

export const httpPostV2 = (url, payload) => {
    return fetch(API_URL + url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...getAuthorization(),
        },
        body: JSON.stringify(payload)
    }).then((rawResponse) => {
        return handleApiResponseV2(rawResponse);
    });
};

export const httpGetV2 = (url) => {
    return fetch(API_URL + url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...getAuthorization(),
        }
    }).then((rawResponse) => {
        return handleApiResponseV2(rawResponse);
    });
};
