import React, {useEffect, useState} from 'react';
import {setFundsKeywordFilter, setMarketCapList} from "../ExplorerAction";
import {isEmpty} from "../../utils/validations";
import {explorerConst} from "../ExplorerConsts";
import ExplorerMarketCapView from "./ExplorerMarketCapView";
const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function ExplorerMarketCap(props) {
    const [advanceMarkeCapState, setAdvanceMarkeCapState] = useState({
        modalUIState: '',
        sortedData: [],
        previousMarketCapData: []
    });

    const{ setFilter} = props ;

    useEffect(()=>{
        if (props.isExternalMarketCapSelected){
            let data = [
                {
                    name: 'Large Cap',
                    value: 'Large Cap',
                    id: 1,
                    isSelected: props.selectedMarketCap?.category === 'Large Cap'
                },
                {
                    name: 'Mid Cap',
                    value: 'Mid Cap',
                    id: 2,
                    isSelected: props.selectedMarketCap?.category === 'Mid Cap'
                },
                {
                    name: 'Small Cap',
                    value: 'Small Cap',
                    id: 3,
                    isSelected: props.selectedMarketCap?.category === 'Small Cap'
                },
                {
                    name: 'International',
                    value: 'International',
                    id: 4,
                    isSelected: props.selectedMarketCap?.category === 'International'
                },
                {
                    name: 'Other',
                    value: 'Others',
                    id: 5,
                    isSelected: props.selectedMarketCap?.category === 'Other'
                },
            ];
            setMarketCapList(data);
        }

        if (props.isMarketCapModalOpen){
            if (!isEmpty(props.marketCapData)){
                setAdvanceMarkeCapState((prev)=>({
                    ...prev,
                    modalUIState: explorerConst.CONTENT_AVAILABLE,
                    previousMarketCapData: props.marketCapData
                }));
            }else {
                let data = [
                    {
                    name: 'Large Cap',
                    value: 'Large Cap',
                    id: 1,
                    isSelected: props.selectedMarketCap?.category === 'Large Cap'
                    },
                    {
                        name: 'Mid Cap',
                        value: 'Mid Cap',
                        id: 2,
                        isSelected: props.selectedMarketCap?.category === 'Mid Cap'
                    },
                    {
                        name: 'Small Cap',
                        value: 'Small Cap',
                        id: 3,
                        isSelected: props.selectedMarketCap?.category === 'Small Cap'
                    },
                    {
                        name: 'International',
                        value: 'International',
                        id: 4,
                        isSelected: props.selectedMarketCap?.category === 'International'
                    },
                    {
                        name: 'Other',
                        value: 'Others',
                        id: 5,
                        isSelected: props.selectedMarketCap?.category === 'Other'
                    },
                ];

                data =  updateMarketCapData(data);
                setMarketCapList(data);
                applyExternalFilter(data);
                // props.onChangeValue('marketCapData', data);

                setAdvanceMarkeCapState((prev) => ({
                    ...prev,
                    modalUIState: explorerConst.CONTENT_AVAILABLE
                }));
            }

        }
    },[props.isMarketCapModalOpen, props.isExternalMarketCapSelected]);


    const updateMarketCapData = (data) => {

        const {marketCaps} = props.explorerFilter;

        let updatedArray = data.map(item => {
            if (marketCaps.includes(item.name)) {
                return { ...item, isSelected: true };
            }
            return item;
        });
        return updatedArray

    };

    const applyExternalFilter =(data)=>{
        let selectedValues = checkExternalSelectedValues(data);
        setFilter('marketCaps', selectedValues, 'name');
        props.history.replace();
    };

    const checkExternalSelectedValues = (data) => {
        return  data.filter((item)=>item.isSelected);
    };

    const handleCheckboxChange = (value) => {
        const {marketCapData} = props;

        const updatedArray = marketCapData.map(item => {
            if (item.id === value.id) {
                return { ...item, isSelected: !item.isSelected };
            }

            // Return the original object if the id doesn't match
            return item;
        });

        setMarketCapList(updatedArray);
        // props.onChangeValue('marketCapData', updatedArray);

    };

    const clearSelectedFunds = (value) => {
        const {marketCapData} = props;

        const updatedArray = marketCapData.map(item => {
            return { ...item, isSelected: false };
        });
        setMarketCapList(updatedArray);

        // props.onChangeValue('marketCapData', updatedArray);

    };

    const checkboxChange = (value) => {
        const {marketCapData} = props;
        // console.log('updatedArray[index]', !value)

        // Make a copy of the object at the specified index
        const updatedItem = marketCapData.find(item =>  (item.id === value.id));

        return updatedItem?.isSelected
    };

    const onChangeKeywordFilter = (fundsKeyword) => {
        setFundsKeywordFilter(fundsKeyword)
    };

    const checkSelectedValues = () => {
        const {marketCapData} = props;
        return  marketCapData.filter((item)=>item.isSelected);
    };

    return (
        <ExplorerMarketCapView {...props}
                                     {...advanceMarkeCapState}
                                     onChangeKeywordFilter={onChangeKeywordFilter}
                                     checkboxChange={checkboxChange}
                                     clearSelectedFunds={clearSelectedFunds}
                                     handleCheckboxChange={handleCheckboxChange}
                                     checkSelectedValues={checkSelectedValues}/>
    );
}

ExplorerMarketCap.propTypes = {

};


export default ExplorerMarketCap;