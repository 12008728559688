import React, {useEffect, useState} from 'react';
import ExplorerFundHouseListingModalView from "./ExplorerFundHouseListingModalView";
import {connect} from "react-redux";
import {setFundHouseList, setFundList} from "../../fund/FundAction";
import {explorerConst} from "../ExplorerConsts";
import {setFundHouseKeywordFilter} from "../ExplorerAction";
import {isEmpty} from "../../utils/validations";
import {searchOnWordBoundary} from "../../utils/helper";
import {getListingData} from "../../utils/stocksHelper";
import {getFundHouseSummary} from "../../fundHouse/FundHouseAction";
const VALUATION_DATE = process.env.REACT_APP_VALUATION_DATE;

function ExplorerFundHouseListingModal(props) {

    const{isExternalFundHouseSelected, externalFundHouseData, setFilter,isFundHouseModalOpen} = props;
    const [advanceFundHouseListingModalState, setAdvanceFundHouseListingModalState] = useState({
        modalUIState: '',
        sortedData: [],
        previousFundHouseListingData: [],
        skipCount: 0,
        searchResultNotAvailable: false,
        limitCount : 60
    });

    useEffect(() => {
        const {skipCount, limitCount} = advanceFundHouseListingModalState;
        const {isFrom} = props;

        if (isExternalFundHouseSelected || isFundHouseModalOpen) {
            if (!isEmpty(props.fundHouseListingData)) {
                setAdvanceFundHouseListingModalState((prev) => ({
                    ...prev,
                    modalUIState: explorerConst.CONTENT_AVAILABLE,
                    previousFundHouseListingData: props.fundHouseListingData
                }));
            } else {
                getFundHouseSummary(VALUATION_DATE, skipCount, limitCount).then((res) => {
                    if (res?.statusCode === 200 || res?.statusCode === 201) {
                        let data = getListingData(res.data.data, 'fund_house_name');
                        props.onChangeValue('fundHouseListingData', data);
                        data = handleExternalCheckChange(externalFundHouseData, data);
                        setFundHouseList(data);
                        applyExternalFilter(data);

                        setAdvanceFundHouseListingModalState((prev) => ({
                            ...prev,
                            modalUIState: explorerConst.CONTENT_AVAILABLE
                        }));

                    } else {
                        setAdvanceFundHouseListingModalState((prev) => ({
                            ...prev,
                            modalUIState: explorerConst.CONTENT_UNAVAILABLE
                        }));
                    }
                })
            }
        }
    }, [isFundHouseModalOpen, isExternalFundHouseSelected]);

    useEffect(()=>{
        const {fundHouseKeyword, isMobileView, isXSView} = props;
        const {fundHouseListingData} = props;

        if (isEmpty(fundHouseListingData)){
            return
        }

        if (isEmpty(fundHouseKeyword)){

            setAdvanceFundHouseListingModalState((prev)=>({
                ...prev,
                sortedData: [],
                searchResultNotAvailable: false
            }));
        }else {
            let sortedData = [];
            setAdvanceFundHouseListingModalState((prev)=>({
                ...prev,
                isSearchApply: true
            }));

            let options =  fundHouseListingData.map((item)=>{
                return {...item, label:item.fund_house_name, value: item.fund_house_name}
            });

            sortedData = searchOnWordBoundary(props.fundHouseKeyword, JSON.parse(JSON.stringify(options)));

            if (!isEmpty(sortedData)){
                setAdvanceFundHouseListingModalState((prev)=>({
                    ...prev,
                    sortedData: sortedData,
                    searchResultNotAvailable: false
                }));
            }else {
                setAdvanceFundHouseListingModalState((prev)=>({
                    ...prev,
                    sortedData: [],
                    searchResultNotAvailable: true
                }));
            }

            setTimeout(()=>{
                setAdvanceFundHouseListingModalState((prev)=>({
                    ...prev,
                    isSearchApply: false
                }))
            },300)

        }

    },[props.fundHouseKeyword]);

    const handlePreviousDataChange = (previousData, selectedFund) => {

        if (isEmpty(selectedFund)){
            return previousData
        }

        return previousData.map(item => ({
            ...item,
            isSelected: selectedFund.some(obj2 => (obj2.fund_house_id === item.fund_house_id)),
        }));
    };

    const updateFundHouseData = (data) => {
        const {fundHouses} = props.explorerFilter;

        let updatedArray = data.map(item => {
            if (fundHouses.includes(item.fund_house_id)) {
                return { ...item, isSelected: true};
            }
            return item;
        });
        return updatedArray
    };

    const handleExternalCheckChange = (value, data) => {
     const{isFrom} = props;
        if (isEmpty(value)){
            return data
        }

        return data.map(item => ({
            ...item,
            isSelected: value.some(obj2 => (obj2.fund_house_id === item.fund_house_id)),
        }));

    };

    const applyExternalFilter =(data)=>{
        let selectedValues = checkExternalSelectedValues(data);
        //console.log("selected values-----",selectedValues)
        setFilter('fundHouses', selectedValues, 'fund_house_id');
        props.history.replace();
    };


    const handleCheckboxChange = (value) => {
        const {fundHouseListingData} = props;

        const updatedArray = fundHouseListingData.map(item => {
            if (item.fund_house_id === value.fund_house_id) {
                return { ...item, isSelected: !item.isSelected };
            }

            // Return the original object if the id doesn't match
            return item;
        });
        setFundHouseList(updatedArray);

    };

    const clearSelectedFunds = (value) => {
        const {fundHouseListingData} = props;

        const updatedArray = fundHouseListingData.map(item => {
            return { ...item, isSelected: false };
        });

        setFundHouseList(updatedArray);
    };

    const checkboxChange = (value) => {
        const {fundHouseListingData} = props;
        // console.log('updatedArray[index]', !value)

        // Make a copy of the object at the specified index
        const updatedItem = fundHouseListingData.find(item =>  (item.fund_house_id === value.fund_house_id));

        return updatedItem?.isSelected
    };

    const onChangeKeywordFilter = (fundHouseKeyword) => {
        setFundHouseKeywordFilter(fundHouseKeyword)
    };

    const checkExternalSelectedValues = (data) => {
        const {fundHouseListingData} = props;
        return  data.filter((item)=>item.isSelected);
    };

    const checkSelectedValues = () => {
        const {fundHouseListingData} = props;
        return  fundHouseListingData.filter((item)=>item.isSelected);
    };

    return (
        <ExplorerFundHouseListingModalView {...advanceFundHouseListingModalState}
                                           {...props}
                                           handleCheckboxChange={handleCheckboxChange}
                                           checkboxChange={checkboxChange}
                                           checkSelectedValues={checkSelectedValues}
                                           clearSelectedFunds={clearSelectedFunds}
                                           onChangeKeywordFilter={onChangeKeywordFilter}/>
    )
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    fundsData: state.fundState.fundsData,
    fundsLoaded: state.fundState.fundsLoaded,
    fundHouseKeyword: state.ExplorerState.fundHouseKeyword
});

export default connect(mapStateToProps, {})(ExplorerFundHouseListingModal);