import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import StocksView from "./StocksView";
import {useParams} from 'react-router-dom'
import {
    getFundcount,
    getStockDetails,
    getStockTrend
} from "../../WatchListAction";
import {isEmpty} from "../../../utils/validations"
import appTheme from "../../../assets/appTheme"
import moment from "moment";
import {filterNetBuyingSellingValue, getFixedValuesAfterDecimal} from "../../../utils/helper";


function Stocks(props) {
    const {selectedWatchListObj  ,selectedStockListData }= props;
    const isinList = selectedStockListData.map(item => item.isin_code);

    const [StocksState,setStocksState] = useState({
        activitiesStateLoaded: false,
        activitiesData: {},
        fundHouseCount : '',
        fundSchemeCount : '',
        marketValueChangeMoM: '',
        xAxis: [],
        yAxisBar: [],
        yAxisLine: [],
        barColor: [],
        sceenerCode:'',
        tabValue:0,
        isDomLoaded: false,
        marketValue:'',

        holdings: "",
        marketCapCategory:"",
        businessHouse:"",
        industry:"",
        isInternationalStock: true
    });
    const {isin} = useParams();


    const [selectedStock, setSelectedStock] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [popUpModalData,setPopUpModalData] = useState({
        requiredMonth : '',
        entries:[],
        exits:[],
        netBuying:0,
        netSelling:0,
        buyingFunds:0,
        sellingFunds:0,
        netBuyingSellingDifference:0,
        isModalApiInProgress: true
    });

    const handleOpenModal =(graphMonth)=>{
        setIsModalOpen(true);
        getDataForPopUp(graphMonth);
    };
    const handleCloseModal =()=>{
        setIsModalOpen(false);
        setTimeout(()=>{},200);
        initializeZeroCondition()
    };

    const initializeZeroCondition =()=>{

        setPopUpModalData((prevState)=>({
            ...prevState,
            requiredMonth : "",
            exits: "",
            entries: "",
            buyingFunds: 0,
            sellingFunds: 0,
            netBuying: 0,
            netSelling: 0,
            netBuyingSellingDifference: 0,
            isModalApiInProgress: true
        }))
    };

    const getDataForPopUp =(date)=>{
        let formattedDate=moment(date,"MMM-YYYY").endOf('month').format("YYYY-MM-DD");
        getNetBuyingSellingPopUpData(formattedDate,isin).then((res) => {
            if (res.success) {
                if (!isEmpty(res?.data?.data)){
                    setPopUpModalData((prevState)=>({
                        ...prevState,
                        requiredMonth : date,
                        exits: res?.data?.data?.exitedFunds,
                        entries: res?.data?.data?.addedFunds,
                        buyingFunds: res?.data?.data?.buyingFundsCount,
                        sellingFunds: res?.data?.data?.sellingFundsCount,
                        netBuying: getFixedValuesAfterDecimal(res?.data?.data?.buyingFundsMarketValue,2),
                        netSelling: getFixedValuesAfterDecimal(res?.data?.data?.sellingFundsMarketValue,2),
                        netBuyingSellingDifference: getFixedValuesAfterDecimal(res?.data?.data?.marketValueChange,2),
                        isModalApiInProgress: false
                    }))
                }
            }
        });
    };
    const setCategories = (data) => {
        const result = data.reduce((acc, curr) => {
            const category = curr.category;
            const existingCategory = acc.find(item => item.category === category);
            if (existingCategory) {
                existingCategory.count++;
                if (curr.market_cap) {
                    existingCategory.market_value += curr.market_cap;
                }
            } else {
                acc.push({
                    category: category,
                    count: 1,
                    market_value: curr.market_cap || 0
                });
            }
            return acc;
        }, []);

        setStocksState((prevState)=>({
            ...prevState,
            marketCapCategory: result,
        }));
    };

    const setIndustries = (data) => {
        const result = data.reduce((acc, curr) => {
            const industry = curr.industry;
            const existingIndustry = acc.find(item => item.industry === industry);
            if (existingIndustry) {
                existingIndustry.count++;
                if (curr.market_cap) {
                    existingIndustry.market_value += curr.market_cap;
                }
            } else {
                acc.push({
                    industry: industry,
                    count: 1,
                    market_value: curr.market_cap || 0
                });
            }
            return acc;
        }, []);

        setStocksState((prevState)=>({
            ...prevState,
            industry: result,
        }));
    };

    const setBusinessHouse = (data) => {
        const result = data.reduce((acc, curr) => {
            const business_house = curr.business_house;
            const existingBusinessHouse = acc.find(item => item.business_house === business_house);
            if (existingBusinessHouse) {
                existingBusinessHouse.count++;
                if (curr.market_cap) {
                    existingBusinessHouse.market_value += curr.market_cap;
                }
            } else {
                acc.push({
                    business_house: business_house,
                    count: 1,
                    market_value: curr.market_cap || 0
                });
            }
            return acc;
        }, []);

        setStocksState((prevState)=>({
            ...prevState,
            businessHouse: result,
        }));
    };

    useEffect(() => {

        // getSceenrCode(date, isin).then((res)=>{
        //     if (res.success){
        //         setStocksState((prevState)=>({
        //             ...prevState,
        //             sceenerCode: res.data?.data
        //         }))
        //     }
        // });

        getFundcount(isinList).then((res) => {
            if (res?.success) {
                setStocksState((prevState)=>({
                    ...prevState,
                    fundSchemeCount: res.data.data.length - 1
                }))

            }
        });

        getStockTrend(isinList).then((res) => {
            if (res?.success) {
                setBarLineChartData(res.data);
                setStocksState((prevState)=>({
                    ...prevState,
                     marketValue :!isEmpty(res.data?.data) && getFixedValuesAfterDecimal(res.data?.data[res.data?.data.length - 1]?.total_market_value, 2)
                }))

            }
        });

        getStockDetails(isinList).then((res) => {
            if (res?.success) {
                if(!isEmpty(res.data?.data)){

                    const arr = res.data?.data.map(item => {
                        return item.market_cap !== null ? item.market_cap : 0;
                    });

                    setCategories(res?.data.data);
                    setIndustries(res?.data.data);
                    setBusinessHouse(res?.data.data);

                    setStocksState((prevState)=>({
                        ...prevState,
                         marketValue :setAUMData(arr),
                    }))
                }
                // setBarLineChartData(res.data)
            }
        })

    }, [selectedWatchListObj.stockList]);

    const setBarLineChartData = (chartData) =>{
        let xAxis = [];
        let yAxisBar = [];
        let yAxisLine = [];
        let barColor = [];
        if (isEmpty(chartData)){
            return
        }

        chartData?.data.forEach((item)=>{
            xAxis.push(item?.month);
            yAxisBar.push(filterNetBuyingSellingValue(item?.total_market_value_change_due_to_activity));
            yAxisLine.push(getFixedValuesAfterDecimal(item?.total_market_value,2));
            if (item?.total_market_value_change_due_to_activity >=0){
                barColor.push(appTheme.appColor.success)
            }else {
                barColor.push(appTheme.appColor.danger)
            }

        });

        setStocksState((prevState)=>({
            ...prevState,
            xAxis: xAxis,
            yAxisBar: yAxisBar,
            barColor: barColor,
            yAxisLine: yAxisLine
        }))
    };

    // useEffect(() => {
    //     setUIState('');
    //
    //     setTimeout(()=>{
    //         setUIState(stocksConst.CONTENT_AVAILABLE);
    //     },200)
    // }, [props.isLGView,props.isXLView, props.isMDView]);

    const setAUMData = (data) => {

        let totalAUM = 0;
      //  let totalChangeMoM = 0;


        for (let i = 0; i < data.length; i++) {

            let marketValue = data[i];
           // let marketValueChangeMoM = data[i].market_value_change_mom ;

            totalAUM += marketValue;
           // totalChangeMoM += marketValueChangeMoM;

        }

        return  getFixedValuesAfterDecimal(totalAUM,2)

    };




    const onChangeValue = (name, value) => {
        setSelectedStock(value);
    };

    const handleTabChange = (name, value) => {
        setStocksState((prev)=>({
            ...prev,
            tabValue: value
        }));
    };

    const handleChange = () => {
        if (!StocksState?.isDomLoaded){
            setStocksState((prev)=>({
                ...prev,
                isDomLoaded:true
            }))

        }
    };
    const refresh = () => {
        window.location.reload(true);
    };


    return (
        <StocksView
            {...props}
            isin={isin}
            selectedStock={selectedStock}
            refresh={refresh}
            onChangeValue={onChangeValue}
            handleTabChange={handleTabChange}
            handleChange={handleChange}
            {...StocksState}
            {...popUpModalData}
            isModalOpen={isModalOpen}
            handleOpenModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
            getDataForPopUp={getDataForPopUp}
            isinList ={isinList}
        />
    );
}


Stocks.propTypes = {};
const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView
});

export default connect(mapStateToProps, {})(Stocks);
